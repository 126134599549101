import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { Mask } from 'antd-mobile';

const ScratchModal = ({onClose, leftCB, rightCB, prizeInfo}) => {
  const [visible, setVisible] = useState(true);

  const cancel = () => {
    onClose && onClose();
    setVisible(false);
    leftCB && leftCB();
  }

  const confirm = () => {
    onClose && onClose();
    setVisible(false);
    rightCB && rightCB();
  }

  const time = `${prizeInfo?.editTime?.slice(6,7)}月${prizeInfo?.editTime?.slice(8,10)}日`

  return <Mask className='acratch-success-mask' visible={visible} onMaskClick={() => cancel()}>
    <div className='mask-content'>
      <div className={`text-box ${prizeInfo.type === '3' ? '': 'points-type'}`}>
        <div className='text-box-item'>恭喜您！</div>
        <div 
          className={`text-box-item ${prizeInfo?.type === '3' ? '': 'text-center'}`}
        >
          {prizeInfo?.type === '3' ? `${prizeInfo.value}积分+一箱申花啤酒`: `中奖${prizeInfo.value}积分`}
        </div>
        {prizeInfo.type === '3' ? <div className='text-box-item'>请于{time}前填写收货地址，查看“我的奖品”填写地址，<i>逾期视为自动放弃</i></div>: null}
      </div>
      <div className={`btn-box ${prizeInfo.type === '3' ? '': 'left-btn'}`}>
        <div className="confirm-btn" onClick={() => cancel()}>知道啦</div>
        {prizeInfo.type === '3' ? <div className="address-btn" onClick={() => confirm()}>填写收货地址</div>: null}
      </div>
    </div>
  </Mask>
}

ScratchModal.open = ({leftCB, rightCB, prizeInfo}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<ScratchModal onClose={close} leftCB={leftCB} rightCB={rightCB} prizeInfo={prizeInfo}/>, div);
}

export default ScratchModal;