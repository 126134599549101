import React, {useEffect, useRef, useState} from "react"
import "./eggHatchSuccess.scss"
import {useNavigate, useLocation} from "react-router-dom"
import {Button, Mask, Toast} from "antd-mobile"
import qs from 'query-string';
import JsBridgeNew from "@/utils/jsbridgeNew";
import {isEmptyString, isIOSNew} from "@/utils/publicFun";

export default function EggHatchSuccess() {
    const navigate = useNavigate()
    const [hash, setHash] = useState()
    const [activeIndex, setActiveIndex] = useState(0)
    const [videoSrc, setVideoSrc] = useState('');
    const [imgSrc, setImgSrc] = useState('');
    const [maskVisible, setMaskVisible] = useState(false)
    const bridge = window.JsBridge

    let {
        collectionGroup,
        seriesName = '',
        nftImage = '',
        nftHash = '',
        nftNo = ''
    } = qs.parse(window.location.search)

    useEffect(() => {
        bridge.setTitle('合成成功');
        loadData();
    }, [])

    useEffect(() => {
        console.log('isiOS--',isIOSNew());
        let timer = setTimeout(() => {
            clearTimeout(timer);
            addVideoListener();
        }, 1000)
    }, [videoSrc])

    const loadData = () => { //加载数据
        let collectionGroupInt = parseInt(collectionGroup) || 9;
        let videoTem = '';
        let imgTem = '';
        switch (collectionGroupInt) {
            case 1:
                videoTem = require('@/assets/img/egg/fu_zhiwu_dan.mp4')
                imgTem = require('@/assets/img/egg/fu_zhiwu_dan_img.gif')
                break;
            case 2:
                videoTem = require('@/assets/img/egg/fu_zhiwu_shu.mp4')
                imgTem = require('@/assets/img/egg/fu_zhiwu_shu_img.gif')
                break;
            case 3:
                videoTem = require('@/assets/img/egg/fu_shuijin_qiu.mp4')
                imgTem = require('@/assets/img/egg/fu_shuijin_qiu_img.gif')
                break;
            case 4:
                videoTem = require('@/assets/img/egg/yu_zhiwu_dan.mp4')
                imgTem = require('@/assets/img/egg/yu_zhiwu_dan_img.gif')
                break;
            case 5:
                videoTem = require('@/assets/img/egg/yu_zhiwu_shu.mp4')
                imgTem = require('@/assets/img/egg/yu_zhiwu_shu_img.gif')
                break;
            case 6:
                videoTem = require('@/assets/img/egg/yu_shuijin_qiu.mp4')
                imgTem = require('@/assets/img/egg/yu_shuijin_qiu_img.gif')
                break;
            case 7:
                videoTem = require('@/assets/img/egg/lu_zhiwu_dan.mp4')
                imgTem = require('@/assets/img/egg/lu_zhiwu_dan_img.gif')
                break;
            case 8:
                videoTem = require('@/assets/img/egg/lu_zhiwu_shu.mp4')
                imgTem = require('@/assets/img/egg/lu_zhiwu_shu_img.gif')
                break;
            case 9:
                videoTem = require('@/assets/img/egg/lu_shuijin_qiu.mp4')
                imgTem = require('@/assets/img/egg/lu_shuijin_qiu_img.gif')
                break;
        }
        setVideoSrc(videoTem);
        setImgSrc(imgTem);
        setMaskVisible(true)
    }

    const addVideoListener = () => {
        let elevideo = document.getElementById("myVideo");
        if (elevideo) {
            console.log('elevideo的值', elevideo);
            elevideo.addEventListener('ended', function () { //结束
                console.log('监听video')
                // elevideo.pause();
                elevideo.remove();
                console.log('销毁video')
                setTimeout(() => {
                    setMaskVisible(!maskVisible);
                }, 100)
            });

            elevideo.addEventListener('play', function () {
                console.log('提示该视频正在播放中')
            });

            elevideo.addEventListener('waiting', function () {
                console.log('视频加载等待')
            });

            elevideo.addEventListener('error', function (e) {
                console.log('视频出错了')
                console.log(e)
            });

            elevideo.addEventListener('pause', function (e) {
                console.log('暂停播放')
                console.log(e)
            })

            let timer = setTimeout(() => {
                clearTimeout(timer)
                if (maskVisible) {
                    setMaskVisible(!maskVisible)
                }
            }, 5000);
        } else {
            let timer = setTimeout(() => {
                clearTimeout(timer)
                if (maskVisible) {
                    setMaskVisible(!maskVisible)
                }
            }, 2000);
        }

    }

    const gotoDetail = () => {
        const uri = `/myCollectionDetail?nft_hash=${nftHash}`
        if (JsBridgeNew.hasWebViewBridge()) {
             JsBridgeNew.replace("CustomWeb", { uri: `${window.location.origin}${uri}`});
          } else {
            navigate(uri, {replace: true});
          }
        
    }

    const returnVideoOrImg = () => {
        // let collectionGroupInt = parseInt(collectionGroup) || 1;
        if (isIOSNew()) {
            return (
                <video id='myVideo'
                       width="100%"
                       height="100%"
                       src={videoSrc}
                       autoPlay="autoplay"
                       playsInline={true}
                       muted
                />
            )
        } else {
            return (
                <img
                    src={imgSrc}
                    className={'img_dh'}/>
            )
        }
    }

    return (
        <div className="egg_paysuccess">
            <div className={'egg_pay_content'} style={{display: maskVisible ? 'none' : 'flex'}}>
                <img src={nftImage} className={'egg_pay_img'}/>
                <div className={'egg_pay_title'}>{seriesName}</div>
                <div className={'egg_pay_number'}>藏品编号：{decodeURIComponent(nftNo)}</div>
            </div>
            <div size="large" className="egg_view_btn" onClick={gotoDetail}>
                查看藏品详情
            </div>
            <Mask visible={maskVisible} opacity={0.8}>
                <div className={'video_player_father'}>
                    {returnVideoOrImg()}
                </div>
            </Mask>
        </div>
    )
}
