/**
 * @description 土地游戏首页
 * @param {mode} 土地模式 1: 农业默认 2: 工业工地
 * @returns { /landGame?mode=1 }
 */
import React, { useState, useRef, useEffect, useMemo } from "react";
import classNames from "classnames";
import Scenes from "../components/scenes";
import {
  MyLandBtn,
  LandModeBtn,
  TeleportBtn,
  SpeedBtn,
  ScoreBtn,
  HomeBackBtn,
  SpaceId,
  TaskBtn,
  ExchangeLandBtn,
  LandLease,
  SpaceExtend,
  SpaceNum,
  PropBtn,
} from "../components/button";
import LandView from "../components/landview";
import LandDetail from "../landDetail";
import PlaceLand from "../components/placeLand";
import MyLands from "../components/myLands";
import MyGyLands from "../components/myGyLands";
import ReceiveLand from "../components/receiveLand";
import RealName from "../components/realName";
import { ReceiveTip, ReceiveTipMode2 } from "../components/landview";
import { Toast } from "antd-mobile";
import {
  gulandInit,
  confirmReceiveLands,
  confirmPlaceLand,
  cancelPlacement,
  getUserEnergy,
} from "@/api/guland";

import { hideLoading, showLoading } from "@/components/loading";
import DailyTask from "../dailyTask";
import { usePurchaseCard } from "../acceleratorCard/purchaseCard";
import { usePropCard } from "../acceleratorCard/propCard";

import { usePortal } from "../components/portal";
import { useNavigate, useLocation } from "react-router-dom";
import ExchangeLand from "../components/exchangeLand";
import MyModal from "../jewelLand/modal";
import ConfirmLandOrder from "../components/landOrder";
import SpaceExtendModal from "../components/spaceExtend";
import qs from "query-string";

import "./index.scss";

let level = 1;
const Lands = [
  { mode: 1, label: "农业用地" },
  { mode: 2, label: "工业用地" },
  { mode: -1, label: "敬请期待。。。" },
];
const LandGame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const mode = qs.parse(location.search).mode || 1;

  const [placeVisible, setPlaceVisible] = useState(false);
  const [exchangeVisible, setExchangeVisible] = useState(false);
  const [myLandsVisible, setMyLandsVisible] = useState(false);
  const [taskVisible, setTaskVisible] = useState(false);
  const [spaceExtendVisible, setSpaceExtendVisible] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [initData, setInitData] = useState({});
  const [upgradeData, setUpgradeData] = useState(null);
  const [isCanExchangeLand, setIsCanExchangeLand] = useState(false);
  const [limit, setLimit] = useState({});

  const chooseLandId = useRef(null);
  const teleportRef = useRef();
  const timeId = useRef(null);
  const pageRef = useRef(null);
  const currentClickLand = useRef(null);
  const spaceId = useRef(null);
  const spaceLevel = useRef(1);

  const { isUnityHome } = qs.parse(window.location.search);

  const [onOpen, PurchaseCard] = usePurchaseCard();
  const [onPropOpen, PropCard] = usePropCard({ mode });

  const [onPortalOpen, PortalView] = usePortal({
    initData,
    confirmCB: (id) => {
      spaceId.current = id;
      spaceLevel.current = 1;
      pageInit(id);
    },
  });

  useEffect(() => {
    window.JsBridgeNew.hiddenStatusBar(true);
    window.JsBridgeNew.screenRotate(false);
  }, []);

  useEffect(() => {
    getUserInfo();
    onDidFocus();
    pageInit();
    return () => {
      clearInterval(timeId.current);
    };
  }, [mode]);

  useEffect(() => {
    !myLandsVisible && +mode === 2 && getUserEnergyHandle();
  }, [myLandsVisible,mode]);

  // 获取工业用地额度
  const getUserEnergyHandle = async () => {
    try {
      const res = await getUserEnergy();
      if (res.code !== "000000") throw res.msg;
      setLimit(res.data || {});
    } catch (error) {
      console.log(error);
      Toast.show(error);
      hideLoading();
    }
  };
  const limitAmount = useMemo(() => {
    const a = limit?.ta || 0
    const b = limit?.tb || 0
    const c = limit?.tc || 0

    return a+b+c
  }, [limit]);
  const onLandClick = (type, selectLand) => {
    if (initData?.isSelf === 0) return;
    currentClickLand.current = selectLand;
    chooseLandId.current = selectLand?.separateNo;
    if (type === "place") {
      console.log("place");
      setPlaceVisible(true);
    } else if (type === "check") {
      console.log("check");
      if (isCanExchangeLand) {
        setExchangeVisible(true);
        return;
      }
      console.log(selectLand)
      teleportRef.current?.show({ ...selectLand,landType:+mode,from: 1,mode:mode });
    } else if (type === "receive") {
      console.log("receive");
      // ReceiveLand.open({
      //   onConfirmReceive: () => {
      //     if (!userInfo.ID) {
      //       RealName.open({
      //         confirmCB: () => {
      //           window.JsBridge.navigate('Verify')
      //           window.JsBridgeNew.hiddenStatusBar(false)
      //           window.JsBridgeNew.screenRotate(true)
      //         }
      //       })
      //       return
      //     }
      //     receiveGiveLands()
      //   }
      // })
      if (!userInfo.ID) {
        RealName.open({
          confirmCB: () => {
            window.JsBridge.navigate("Verify");
            window.JsBridgeNew.hiddenStatusBar(false);
            window.JsBridgeNew.screenRotate(true);
          },
        });
        return;
      }
      const ModalInstance = MyModal.show({
        content: <ConfirmLandOrder onClose={() => ModalInstance?.close()} />,
        getContainer: pageRef.current,
      });
    }
  };

  //获取用户信息
  const getUserInfo = () => {
    window.JsBridge.getDetailCurrentUserInfo().then((res) => {
      setUserInfo(res);
    });
  };

  // 监听页面返回
  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      window.JsBridgeNew.hiddenStatusBar(true);
      window.JsBridgeNew.screenRotate(false);
      spaceLevel.current = level;
      getUserInfo();
      onDidFocus();
      pageInit();
      getUserEnergyHandle();
    });
  };

  // 页面初始化数据
  const pageInit = async (spaceNo, loading = true) => {
    try {
      loading && showLoading();
      const res = await gulandInit({
        spaceNo: spaceNo || spaceId.current,
        spaceLevel: spaceLevel.current,
        spaceType: +mode === 2 ? 1 : 0,//空间类型 0农业  1工业
      });
      loading && hideLoading();
      if (res.code !== "000000") throw res.msg;
      setInitData(res.data || {});
      setIsCanExchangeLand(false);
    } catch (error) {
      console.log(error);
      loading && hideLoading();
      Toast.show(error);
    }
  };

  // 领取土地
  const receiveGiveLands = async () => {
    try {
      showLoading();
      const res = await confirmReceiveLands();
      if (res.code !== "000000") throw res.msg;
      hideLoading();
      await pageInit();
      Toast.show("领取成功");
    } catch (error) {
      console.log(error);
      Toast.show(error);
      hideLoading();
    }
  };

  // 放置土地
  const placeLands = async (params) => {
    try {
      showLoading();
      const res = await confirmPlaceLand({
        ...params,
        spaceLevel: spaceLevel.current,
        spaceType: +mode === 2 ? 1 : 0,//空间类型 0农业  1工业

      });
      if (res.code !== "000000") throw res.msg;
      hideLoading();
      await pageInit();
    } catch (error) {
      console.log(error);
      hideLoading();
      Toast.show(error);
    }
  };

  // 取消放置土地
  const cancelPlaceLands = async () => {
    try {
      showLoading();
      const res = await cancelPlacement({
        landNftNo: currentClickLand.current?.nftNo,
        spaceType: +mode === 2 ? 1 : 0,//空间类型 0农业  1工业
      });
      hideLoading();
      if (res.code !== "000000") throw res.msg;
      setExchangeVisible(false);
      await pageInit();
    } catch (error) {
      console.log(error);
      hideLoading();
      Toast.show(error);
    }
  };

  const upgradeCallBack = async (upData) => {
    if (timeId.current) {
      clearInterval(timeId.current);
    }
    try {
      const res = await gulandInit({
        spaceNo: null,
        spaceLevel: spaceLevel.current,
      });
      if (res.code !== "000000") throw res.msg;
      setUpgradeData(upData);
      timeId.current = setTimeout(() => {
        setUpgradeData(null);
        setInitData(res.data || {});
      }, 2000);
    } catch (error) {
      console.log(error);
      Toast.show(error);
    }
  };

  const onLandLease = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", {
        uri: `${window.location.origin}/landGame/leaseCenter`,
      });
    } else {
      navigate(`/landGame/leaseCenter`);
    }
  };

  const onLeftArrow = () => {
    if (spaceLevel.current <= 1) return;
    spaceLevel.current = spaceLevel.current - 1;
    level = spaceLevel.current;
    pageInit();
  };

  const onRightArrow = () => {
    if (spaceLevel.current >= initData.hasSpaceLevel) return;
    spaceLevel.current = spaceLevel.current + 1;
    level = spaceLevel.current;
    pageInit();
  };

  const onSpaceExtent = () => {
    setSpaceExtendVisible(true);
  };
  const clickHandle = () => {
    const nextMode = mode == 2 ? 1 : 2;
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.replace("CustomWeb", {
        uri: `${window.location.origin}/landGame?isUnityHome=${isUnityHome}&mode=${nextMode}`,
      });
    } else {
      navigate(`/landGame?isUnityHome=${isUnityHome}&mode=${nextMode}`, { replace: true });
    }

  };
  return (
    <div
      className="land-game"
      style={{ width: "100%", height: "100%", position: "relative" }}
      ref={pageRef}
    >
      <Scenes mode={mode}></Scenes>
      <SpaceNum spaceNum={spaceLevel.current} />
      <HomeBackBtn
        onClick={() => {
          if (initData?.isSelf === 0) {
            spaceId.current = null;
            spaceLevel.current = 1;
            pageInit();
            return;
          }
          if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.back();
            if (!isUnityHome) {
              window.JsBridgeNew.hiddenStatusBar(false);
              window.JsBridgeNew.screenRotate(true);
            }
          } else {
            window.history.go(-1);
          }
        }}
      />
      <div
        className={`land-game-leftarrow ${
          spaceLevel.current <= 1 ? "" : "activity"
        }`}
        onClick={onLeftArrow}
      ></div>
      <div
        className={`land-game-rightarrow ${
          spaceLevel.current >= initData.hasSpaceLevel ? "disabled" : ""
        }`}
        onClick={onRightArrow}
      ></div>
      <SpaceId
        spaceId={initData?.spaceNo}
        isCustom={initData?.customNiceNo}
        spaceNiceNo={initData?.spaceNiceNo}
      />
      {initData?.isSelf === 0 ? null : (
        <div>
          <TaskBtn onClick={() => setTaskVisible(true)} />
          <ScoreBtn
            onClick={() => {
              if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.push("CustomWeb", {
                  uri: `${window.location.origin}/landGame/jewelLand?mode=${mode}`,
                });
              } else {
                navigate(`/landGame/jewelLand?mode=${mode}`);
              }
            }}
            storeCounts={initData?.gemstonesCount}
          ></ScoreBtn>
          {mode == 2 && initData?.landBlocksDTOList?.length <= 0 ? (
            <ReceiveTipMode2 onClick={() => setMyLandsVisible(true)} num={limitAmount} />
          ) : null}
          {mode != 2 && initData?.landBlocksDTOList?.length <= 0 ? (
            <ReceiveTip
              onClick={onLandClick}
              receiveNum={initData?.receiveNum}
            />
          ) : null}
          {mode == 2 ? (
            <PropBtn mode={mode} onClick={() => onPropOpen()} />
          ) : (
            <SpeedBtn
              className={classNames({ mode2: +mode === 2 })}
              onClick={() => onOpen()}
            ></SpeedBtn>
          )}
          <LandModeBtn mode={mode} clickHandle={clickHandle} />
          <MyLandBtn
            mode={mode}
            onClick={() => setMyLandsVisible(true)}
          ></MyLandBtn>
          <TeleportBtn onClick={() => onPortalOpen()}></TeleportBtn>
          <LandLease onClick={onLandLease} />
          <SpaceExtend onClick={onSpaceExtent} />
          {initData?.landBlocksDTOList?.length > 0 ? (
            <ExchangeLandBtn
              onClick={() => setIsCanExchangeLand((pre) => !pre)}
              isCanExchangeLand={isCanExchangeLand}
            />
          ) : null}
        </div>
      )}
      <LandView
        onClick={onLandClick}
        mode={mode}
        landData={{ ...initData }}
        upgradeData={upgradeData}
        isCanExchangeLand={isCanExchangeLand}
      ></LandView>
      <PlaceLand
        mode={mode}
        visible={placeVisible}
        onClose={() => setPlaceVisible(false)}
        onConfirm={(item) => placeLands(item)}
        landId={chooseLandId.current}
      />
      <ExchangeLand
        mode={mode}
        visible={exchangeVisible}
        onClose={() => setExchangeVisible(false)}
        onConfirm={(item) => placeLands(item)}
        landId={chooseLandId.current}
        workApeData={initData}
        cancelPlace={cancelPlaceLands}
      />
      {mode == 2 ? (
        <MyGyLands
          visible={myLandsVisible}
          onclose={() => {
            setMyLandsVisible(false);
          }}
          mode={mode}
          checkMode={clickHandle}
          userInfo={{ ...userInfo, receiveNum: initData.receiveNum }}
          upgradeLand={(upLand) => {
            teleportRef.current?.show({ ...upLand, from: 2 });
            setMyLandsVisible(false);
          }}
          container={pageRef.current}
        />
      ) : (
        <MyLands
          visible={myLandsVisible}
          onclose={() => {
            setMyLandsVisible(false);
          }}
          userInfo={{ ...userInfo, receiveNum: initData.receiveNum }}
          upgradeLand={(upLand) => {
            console.log(upLand);
            teleportRef.current?.show({ ...upLand, from: 2 });
            setMyLandsVisible(false);
          }}
          container={pageRef.current}
        />
      )}

      <LandDetail
        mode={mode}
        ctrl={teleportRef}
        upgradeCallBack={upgradeCallBack}
        pageInit={pageInit}
        container={pageRef.current}
      />
      <DailyTask visible={taskVisible} onClose={() => setTaskVisible(false)} mode={mode}/>
      <SpaceExtendModal
        mode={mode}
        container={pageRef.current}
        visible={spaceExtendVisible}
        onClose={() => setSpaceExtendVisible(false)}
        refresh={() => pageInit()}
      />
      {PurchaseCard}
      {PropCard}
      {PortalView}
    </div>
  );
};

export default LandGame;
