/***
 * 在微信 webview 中 展示 打开app
 * ***/
import {useEffect, useState} from 'react'
import {Popup, Toast} from 'antd-mobile'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Platform} from "@/utils/publicFun";
import styles from './styles.module.scss'

export default (props) => {
    // const isMiniprogram = Platform.isMiniprogram();
    // if (!isMiniprogram) return null;
    console.log('props', props)
    const [visible, setVisible] = useState(false);
    const JumpUrl = process.env.REACT_APP_GKT_LINK + `/front/inviter/guessingActy`;


    useEffect(() => {
        setVisible(props.visible)
    }, [props])
    return (
        <Popup
            visible={visible}
            onMaskClick={() => props.onClose && props.onClose()}
            className={styles.popup}
            // bodyStyle={{ height: '40vh' }}
        >
            <div className={styles.descText}>可使用场景</div>
            <div className={styles.content}>
                <img src={require('./app_logo.png')}/>
                <div className={styles.center}>
                    <div className={styles.strong}>绿地G优</div>
                    <div className={styles.labelList}>
                        <span>链上查询</span>
                        <span>藏品购买</span>
                        <span>藏品转赠</span>
                    </div>
                </div>
                <div>App内使用</div>
            </div>
            <div className={styles.row}>
                <div>复制链接至浏览器打开/下载绿地G优App</div>
                <CopyToClipboard text={JumpUrl}
                                 onCopy={() => Toast.show('复制成功')}>
                    <a className={styles.btn}>复制</a>
                </CopyToClipboard>
            </div>
        </Popup>
    )
}
