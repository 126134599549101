import React, {useState, useEffect} from 'react';
import './index.scss';
import CountDown from "@/view/guessingActy/components/countDown";
import {convertToChineseNumeral} from "@/utils/publicFun";

const SessionNotStartCpt = (props) => { //活动未开始
    const [startTime, setStartTime] = useState('');
    const [currentTime, setCurrentTime] = useState('')


    useEffect(() => { //initData
        let startTimeTem = props.initData.startTime;
        let currentTimeTem = props.initData.currentTime;
        setStartTime(startTimeTem);
        setCurrentTime(currentTimeTem);
    }, [props])

    const reloadData = () => {
        props.reloadData && props.reloadData();
    }

    // 第一轮没有开始
    const OneRoundingNotStartGuess = () => {
        return (
            <>
                <p className={'not_start_title'}>活动暂未开始,敬请期待!</p>
            </>
        )
    }


    const sessionCountTitle = () => {//第某某场
        let sessionString = '第' + convertToChineseNumeral(1) + '场';
        return sessionString;
    }


    return (
        <div className='sessioncpt-not-start-component'>
            <div className={'session_container'}>
                <div className={`pk_msg_container ${sessionCountTitle().length >= 4 ? 'long' : ''}`}>
                    <span className={`session_count`}>{sessionCountTitle()}</span>
                    <span className={'session_count_0'}>第{1}/3轮</span>
                    <div className={'countDown'}>
                        {
                            <CountDown type={1} endTitle={'开始'} sysTime={currentTime}
                                       endTime={startTime} refreshData={() => reloadData()}/>
                        }
                    </div>
                </div>
                <div className={'session_info'}>
                    <div className={'session_info_container'}>
                        <OneRoundingNotStartGuess/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SessionNotStartCpt;
