import React, {useState, useEffect, useRef} from "react";
import './index.scss'
import {Mask, Input, Toast} from "antd-mobile";
import {isEmptyString} from "@/utils/publicFun";
import {hideLoading, showLoading} from "@/components/loading";
import {checkName} from "@/api/projectManager_self";
import clickThrottle from "@/utils/clickThrottle";
import JsBridgeNew from "@/utils/jsbridgeNew";

const PwdModal = (props) => {
    const {visible} = props;
    const [pwdError, setPwdError] = useState(false);
    const [values, setValues] = useState([]);

    useEffect(() => {
        if (visible && values.length > 0) {
            setValues([]);
        }
        setPwdError(false)
    }, [visible])

    const itemClick = (item) => {
        if (item.id === 11) { //删除
            if (values.length === 0) { //删除完
                return;
            }
            values.pop();
            setValues([...values]);
        } else { //添加
            if (values.length >= 6) {
                return;
            }
            values.push(item.name)
            setValues([...values]);
            if (values.length === 6) { //输入完毕
                confirmIdentity()
            }
        }
    }

    const confirmIdentity = async () => {
        try {
            showLoading();
            let pwd = values.join('');
            const res = await checkName({uuid: props.uuid, tradePasswd: pwd,nftNo:props.nftNo});
            const {code, msg} = res;
            hideLoading();
            console.log('checkName=====>', res)
            if (code === '000000') {
                window.JsBridge.replace("Web", {uri: `${window.location.origin}/donationSuccess`});
                // navigate(`/donationSuccess?name=${encodeURIComponent(surname.current)}`, {replace: true});
                // setModalVisible(false);
            } else if (code === '200004') {
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    setPwdError(true);
                    setValues([]);
                }, 1000)
            } else {
                throw msg;
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const wrapString = (path) => {
        const prefix = path.startsWith('content://') ? 'RNFetchBlob-content://' : 'RNFetchBlob-file://';
        return prefix + path
    };

    const inVivoIdentification = async (livenessResult) => { //活体识别
        let realName = ''
        let userInfo0 = await window.JsBridge.getCurrentUserInfo();
        if (userInfo0 && userInfo0.clientId) { //登录了
            let userInfo1 = await window.JsBridge.getDetailCurrentUserInfo();
            if (!isEmptyString(userInfo1.ID)) { //实名过了
                realName = userInfo1.realName;
            }
        }

        let param = {
            file: {
                name: 'motionLivenessResult',
                filename: 'motionLivenessResult',
                data: wrapString(livenessResult.livenessFile.replace('file:///', '')),
                type: 'multipart/form-data'
            },
            supplier: '1',
            name: realName,
        };
        JsBridgeNew.jKTServicesRequest({
            moduleName: 'UserLogin',
            code: '101162',
            method: 'postFile',
            params: param
        }).then((res) => {
            if (res.status > 0) {
                window.JsBridge.push("Web", {uri: `${window.location.origin}/pwdSetting?type=${1}`});
            } else {
                // Toast.show('认证失败')
            }
        })
    }



    const faceShiBie = async () => {
        if (!clickThrottle()) return;
        const res = await window.JsBridge.startLiveness();
        if (res.livenessId !== '-1') {
            inVivoIdentification(res);
        } else {
            window.JsBridge.runAction('alert', 'error', [res.msg]);
        }
    }

    let array = [
        {name: '1', type: 'num', id: 0},
        {name: '2', type: 'num', id: 1},
        {name: '3', type: 'num', id: 2},
        {name: '4', type: 'num', id: 3},
        {name: '5', type: 'num', id: 4},
        {name: '6', type: 'num', id: 5},
        {name: '7', type: 'num', id: 6},
        {name: '8', type: 'num', id: 7},
        {name: '9', type: 'num', id: 8},
        {name: '111', type: 'hidden', id: 9},
        {name: '0', type: 'num', id: 10},
        {name: '111', type: 'num', id: 11},
    ];
    const keyBoards = () => {
        return array.map((item, index) => {
            return <div className={'key_board'} key={index.toString()}>
                {
                    <div className={'key_board_item'}
                         onClick={() => itemClick(item)}
                         style={{
                             visibility: item.type === 'num' ? 'visible' : 'hidden',
                             background: item.id !== 11 ? 'white' : 'transparent'
                         }}>
                        {
                            item.id !== 11 ? <p>{item.name}</p> : null
                        }
                        {
                            item.id === 11 ?
                                <img src={require('@/assets/img/key_del.png')} className={'del_img'}/> : null
                        }
                    </div>
                }
            </div>
        })
    }


    const arr = [1, 2, 3, 4, 5, 6,];
    const inputViews = () => {
        return arr.map((item, index) => {
            return <Input key={item.toString()}
                          type={'password'}
                          value={!isEmptyString(values[index]) ? values[index] : ''}
                          max={1}
                          maxLength={1}
                          style={{'--text-align': 'center'}}
                          className={'input'}
                          readOnly
            />
        })
    }
    if (!visible) return null;
    return (
        <Mask visible={visible} opacity={0.3}>
            <div className="pwd_modal_box">
                <div className={'pwd_modal_container'}>
                    <div className={'pwd_modal_top'}>
                        <p className={'pwd_title'}>请输入密码</p>
                        <img src={require('@/assets/img/icon/black_close.png')} alt=""
                             onClick={() => props.onClose && props.onClose()}/>
                    </div>
                    <div className={'pwd_line'}/>
                    <div className={'div_inputs'}>
                        {inputViews()}
                    </div>
                    {
                        pwdError ? <p className={'pwd_error'}>密码不正确</p> : null
                    }
                    <p className={'pwd_face_shibie'} onClick={() => faceShiBie()}>忘记密码？使用人脸识别认证</p>
                </div>
                <div className={'key_board_container'}>
                    {keyBoards()}
                </div>
            </div>
        </Mask>
    );
}

export default PwdModal;
