import React, { useEffect, useLayoutEffect, useState } from "react"
import "./index.scss"
import { Button } from "antd-mobile"
import { useNavigate } from "react-router-dom"
import { getUserCenter } from "@/api/projectManager"
import moment from "moment"

function BlindBox() {
  const bridge = window.JsBridge
  const [userBox, setUserBox] = useState({})
  const [lottery, setLottery] = useState(true)
  const [outdate, setOutdate] = useState(false)
  const navigate = useNavigate()
  bridge.setTitle("盲盒抽奖")

  useLayoutEffect(() => {
    let end = new Date("2022/07/15 24:00:00").getTime()
    setOutdate(new Date().getTime() >= end)
    getUserCenter().then((res) => {
      setUserBox(res)
      let start = new Date(res?.prize_time.replace(/-/g, "/")).getTime()
      let time = new Date().getTime()
      if (time < start) {
        setLottery(true)
      } else {
        setLottery(false)
      }
    })
  }, [])

  const gotoCollection = () => {
    if (bridge.hasWebViewBridge()) {
      bridge.push("CustomWeb", {
        uri: `${window.location.origin}/saledetail?collection_id=${userBox.blind_collection_id}&sale_batch_id=${userBox.blind_sale_batch_id}`,
      })
    } else {
      navigate(`/saledetail?collection_id=${userBox.blind_collection_id}&sale_batch_id=${userBox.blind_sale_batch_id}`)
    }
  }

  const goBack = () => {
    if (bridge.hasWebViewBridge()) {
      bridge.back()
    } else {
      navigate(-1)
    }
  }

  return (
    <div className="blindbox">
      <div className="back_btn" onClick={goBack}></div>
      <h1>盲盒抽签活动</h1>
      <div className="mhbg">
        {lottery === true ? (
          <>
            <div className="drawbg">
              <div className="mydraw">
                盲盒购买资格抽签券：
                <div className="right">
                  {userBox.lucky_draw_num}&nbsp;<span></span>
                </div>
              </div>
            </div>
            <div className="toppic"></div>
          </>
        ) : (
          <>
            <div className="tip">{outdate ? "活动已结束" : userBox.buy_num === 0 ? "很遗憾，你未中奖" : "恭喜您已中奖"}</div>
            {userBox.buy_num === 0 || !userBox.buy_num || outdate ? (
              <div className="toppic"></div>
            ) : (
              <>
                <div className="toppic1"></div>
                {userBox.blind_buy_num > 0 && (
                  <div className="gopurchase">
                    <Button block color="primary" size="middle" onClick={gotoCollection}>
                      立即购买
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}

        <div className="award">盲盒内容</div>
        <div className="ycpic commonpic">
          <img src={require("@/assets/img/mh/ycpic.png")} alt="" />
          <div>隐藏款</div>
        </div>
        <div className="dcpic commonpic">
          <img src={require("@/assets/img/mh/dcpic.png")} alt="" />
          <div>典藏款</div>
        </div>
        <div className="process">盲盒购买流程</div>
        <div className="processstrip">
          <img src={require("@/assets/img/mh/lct.png")} alt="" />
        </div>
        <div className="pro_text pro_left">
          <p>6.20-7.3</p>
          <div>
            购数字藏品得
            <br />
            盲盒购买抽签券
          </div>
        </div>
        <div className="pro_text pro_middle">
          <p>7.4 9:00</p>
          <div>公布中签结果</div>
        </div>
        <div className="pro_text pro_right">
          <p>7.4-7.15</p>
          <div>
            中签用户购买
            <br />
            盲盒
          </div>
        </div>
        <div className="regular">抽签规则</div>
        <div className="regulartext">
          · 中签结果公布时间：2022年7月4日 9:00，您可在此页面查看中签结果。
          <br />
          · 抽签券越多，中签几率越大。
          <br />
          · 中签用户仅可限量购买一份盲盒。
          <br />
          · 用户如果出现违规行为，一经发现，绿地G优有权取消该用户的参与资格。
          <br />
        </div>
      </div>
    </div>
  )
}

export default BlindBox
