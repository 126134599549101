import React, {useEffect, useState} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import "./index.scss"
import {round, getUrlAllParams, throttle} from "@/utils/publicFun"
import {Button, Toast, Checkbox} from "antd-mobile"
import qs from 'query-string';
import descEgg from '@/assets/img/ape-world/egg_img_xiangqing.png';
import xqEgg from '@/assets/img/ape-world/egg_img_xiangqing1.png';
import ShareMask from "@/components/ShareMask/index"
import {getCollection, getGoodsDetail} from "@/api/projectManager_self";


function AllSaintsDaySaleDetail() {
    const navigate = useNavigate();
    const [userAgree, setUserAgree] = useState(false);
    const [data, setData] = useState({});
    const {search} = useLocation();
    const [maskVisible, setMaskVisible] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [btnText, setBtnText] = useState('');
    const bridge = window.JsBridge;
    window.history.scrollRestoration = "manual";

    const {collectionId=''} = getUrlAllParams(search.slice(1));

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        getGoodsDetail({collectionId: collectionId}).then((res) => {
            if (res && res.data && res.data.info) {
                setData(res.data.info)
                setBtnStatus(res.data.info);
            }
        });
    }

    const setBtnStatus = (dataTem) => {
        if (dataTem.saleBatchInfo && Object.values(dataTem).length > 0) {
            if (dataTem.saleBatchInfo.stock > 0) {
                setIsDisabled(false);
                setBtnText('立即购买');
            } else {
                setIsDisabled(true);
                setBtnText('已售罄');
            }
        } else {
            // alert(JSON.stringify(data))
            setIsDisabled(true);
            setBtnText('已售罄');
        }
    }

    const gotoSalePay = () => {
        const params = {collectionId:collectionId,saleBatchId:data.saleBatchInfo?.saleBatchId || ''}
        if (bridge.hasWebViewBridge()) {
            bridge.push("Web", {uri: `${window.location.origin}/apeWorldSalePay?${qs.stringify(params)}`});
        } else {
            // navigate(`/apeWorldSalePay?${qs.stringify(params)}`);
        }
    }
    const goAgreement = () => {
        if (bridge.hasWebViewBridge()) {
            bridge.push("Web", {uri: `${window.location.origin}/MyAgreement`})
        } else {
            navigate(`/MyAgreement`)
        }
    }

    const snapUp = () => {
        if (isDisabled) return;
        if (userAgree === true) {
            if (bridge.hasWebViewBridge()) {
                bridge.verifyRealName("请先实名认证").then(function (res) {
                    if (res?.result) {
                        gotoSalePay();
                    }
                })
            } else {
                Toast.show("请至APP内购买")
            }
        } else {
            Toast.show("请先勾选《绿地数字藏品许可及服务协议》")
        }
    }

    const goBack = () => {
        if (bridge.hasWebViewBridge()) {
            bridge.back()
        } else {
            navigate(-1)
        }
    }

    const share = async () => {
        setMaskVisible(true);
    }

    const throttleShare = throttle(share, 3000)

    const returnDiv = () => {
        return (
            <>
                <img src={data.imageBg} className='ape_world_image'/>
            </>
        )
    }
    let subTitle0 = '暂无介绍';
    let title0 = '藏品介绍';
    if (data && data.collectModuleList && data.collectModuleList.length) {
        if (data.collectModuleList[0].subTitle) {
            subTitle0 = data.collectModuleList[0].subTitle;
        }
        if (data.collectModuleList[0].title) {
            title0 = data.collectModuleList[0].title;
        }
    }
    let subTitle1 = '暂无介绍';
    let title1 = '藏品介绍';
    if (data && data.collectModuleList && data.collectModuleList.length > 1) {
        if (data.collectModuleList[1].subTitle) {
            subTitle1 = data.collectModuleList[1].subTitle;
        }
        if (data.collectModuleList[1].title) {
            title1 = data.collectModuleList[1].title;
        }
    }


    return (
        <div className="detail_b">
            <ShareMask
                maskVisible={maskVisible}
                closeMask={() => setMaskVisible(false)}
                shareCode=''
                title=''
                context=''
                icon=''
                type='9'
            />
            <div className="detail_bc"></div>
            <div className="back_btn" onClick={goBack}></div>
            {/*<div className="right_btn" onClick={throttleShare}></div>*/}
            <div className="detail_banner">
                <div className="detail_main">
                    <div className="content content_opcity">
                        <div className="content_title">{data.title}</div>
                        <div className="content_banner">
                            <div className="stage"></div>
                            {returnDiv()}
                            <div className="content_series"></div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="content_title">发售详情</div>
                        <div className="content_spacebt">
                            <span>发行价格</span>
                            <span>¥{data.price}</span>
                        </div>
                        <p className="content_radio">不可使用积分抵扣</p>
                        <div className="content_spacebt">
                            <span>发售时间</span>
                            <span>{data.subtitle}</span>
                        </div>
                        <div className="content_spacebt">
                            <span>发行数量</span>
                            <span>{data.supply}份</span>
                        </div>

                        {data?.rarity && <div className="content_spacebt">
                            <span >稀有度</span>
                            <span style={{color:'#CEBF7E'}}>{data?.rarity}</span>
                        </div>}

                        <div className="content_spacebt">
                            <span>发行方</span>
                            <div className="content_home">
                                <div></div>
                                {data.owner}
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="content_title">{title0}</div>
                        <p>{subTitle0}</p>
                        {/*<img className="desc-img" src={xqEgg} alt=""/>*/}
                    </div>
                    <div className="content">
                        <div className="content_title">{title1}</div>
                        {/*<div className="content_title">Ape World社群数字藏品</div>*/}
                        <p>{subTitle1}</p>
                        <div className="content_img_center">
                            {/*<img className="desc-img" src={descEgg} alt=""/>*/}
                        </div>
                    </div>


                    <div className="content ape_content">
                        <div className="content_img_center">
                            <li>
                                <img src={require("@/assets/img/homedetail/Group2.png")} alt=""/>
                                <p>唯一编号</p>
                            </li>
                            <li>
                                <img src={require("@/assets/img/homedetail/Group1.png")} alt=""/>
                                <p>可信纪录</p>
                            </li>
                            <li>
                                <img src={require("@/assets/img/homedetail/Group3.png")} alt=""/>
                                <p>永久存证</p>
                            </li>
                            <li>
                                <img src={require("@/assets/img/homedetail/Group4.png")} alt=""/>
                                <p>不可修改</p>
                            </li>
                        </div>
                    </div>
                    <div className="content">
                        <div className="content_title">购买须知</div>
                        <p>
                            1.数字藏品为虚拟数字商品，而非实物，仅限实名认证为年满18周岁的中国大陆用户购买。本商品一经售出，不支持退换。本商品源文件不支持本地下载。请勿对数字藏品进行炒作、场外交易、欺诈、与虚拟货币或金融资产关联，或以任何其他非法方式进行使用。
                            <br/>
                            <br/>
                            2.数字藏品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字藏品用于任何非法商业用途。对于数字藏品及其衍生品，如用户需要对该数字艺术品做出复制、出租、改编、放映、广播或进行网络传播等涉及作品著作权授权的行为，应获得著作权人的相应授权，并订立相关的协议。
                            <br/>
                            <br/>
                            3.购买数字藏品行为即同意本平台《绿地数字藏品许可及服务协议》等规则，同意平台进行相关实名认证，用户应妥善保护好交易账号和密码，不得进行洗钱等违法违规活动，警惕和远离非法集资等相关非法金融活动，切实维护自身财产安全。
                            <br/>
                            <br/>
                            4.请注意：请您于5分钟内完成订单支付，超时后订单将被自动取消。
                            <br/>
                        </p>
                        <br/>
                    </div>
                    <div className="content_space"></div>
                </div>
            </div>
            <div className="detail_bottom">
                <div className="detail_bottom_top">
                    <Checkbox
                        defaultChecked={userAgree}
                        onChange={(checked) => setUserAgree(checked)}
                        style={{
                            "--icon-size": "14px",
                            "--font-size": "14px",
                            "--gap": "6px",
                        }}
                    ></Checkbox>
                    <p>
                        我已阅读并同意<span onClick={goAgreement}>《绿地数字藏品许可及服务协议》</span>
                    </p>
                </div>
                <div className="detail_bottom_bt">
                    <div className="left">
                        <div className="price-detail">
                            <div>
                                <span>¥</span>
                                <span className="price">{data?.price}</span>
                            </div>
                        </div>
                    </div>
                    <div className={`sale ${isDisabled ? 'disabled' : ''}`} onClick={snapUp}>
                        <Button block color="primary" size="large">{btnText}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllSaintsDaySaleDetail;
