/**
 * @description 土地藏品回收记录
 * @returns {route: /collectionRecoveryLand/recoveryRecord}
*/

import React, {useEffect, useState, useRef} from 'react';
import { Toast } from 'antd-mobile';
import NFTHeader from '@/components/NFTHeader';
import {hideLoading, showLoading} from "@/components/loading";
import ListView from '@/components/AntListView';
import qs from 'query-string';
import { getRecoveryRecord } from '@/api/projectManager_self';
import './index.scss';

const mock = {
    code: '000000',
    msg: 'success',
    data: {
      record: [
        {
          recoveryList: [
            {
              nftNo: '123455',
              name: '绿地30周年',
            },
            {
              nftNo: '123455',
              name: '绿地30周年',
            },
            {
              nftNo: '123455',
              name: '绿地30周年',
            },
          ],
          recoveryTime: '2023-03-28 09:30:50',
          prizeType: '2',
          prizeName: '500'
        },
      ]
    }
  }

const RecoveryRecord = () => {
    const { activityCode } = qs.parse(window.location.search);
    const [dataSource, setDataSource] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);

    useEffect(()=>{
        loadData(true)
    },[])

    const loadData = async (isRefesh=false) => {
        const param = {
            activityCode: activityCode,
            pageSize: pageSize.current,
            currentPage: currentPage.current
        }
        try {
            isRefesh && showLoading();
            //await Promise.resolve(mock) // 测试数据
            const res = await getRecoveryRecord(param);
            isRefesh && hideLoading();
            if (res.code !== '000000') throw res.msg;
            const newList = res.data.record || [];
            if(currentPage.current === 1) {
                setDataSource(newList);
            } else {
                setDataSource([...dataSource, ...newList]);
            }
            setHasMore(newList.length >= pageSize.current);
        } catch (error) {
            isRefesh && hideLoading();
            Toast.show(error);
        }
    }

    const loadMore = async () => {
        currentPage.current += 1;
        await loadData()
    }

    const renderItem = (item) => {
        return (
            <div className='recovery-record-item'>
                <div className='card-header'>
                    <span>回收时间</span>
                    <span>{item.recoveryTime?.replace(/-/g, ".")}</span>
                </div>
                {
                    item.recoveryList.map((subItem, index)=>{
                        return (
                            <div className='card-body' key={index}>{`${subItem.name} #${subItem.nftNo}`}</div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div className="recovery-record">
            <NFTHeader midText={`回收记录`}/>
            <div className='recovery-record-warper'>
                <ListView 
                    dataSource={dataSource}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    isLoad={true}
                    style={{paddingTop: 50}}
                    onRefresh={()=>{
                        currentPage.current = 1;
                        loadData()
                    }}
                    onEndReached={loadMore}
                />
            </div>
        </div>
    )
}

export default RecoveryRecord;