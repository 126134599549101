/**
 * 订单商品数量
 * **/
import { useState, useEffect } from 'react'
import { round } from "@/utils/publicFun"
import styles from './styles.module.scss'

export default ({
  defaultCount = 1,
  labels = [],
  maxCount = undefined,
  name = '',
  price = 0,
  goodsPicture = '',
  onChange = () => {},
}) => {
  const [count, setCount] = useState(defaultCount)
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(count)
    }
  }, [count])

  const onReduce = () => {
    if (count > 1) {
      setCount(count - 1)
    }
  }

  const onAdd = () => {
    if (!maxCount || maxCount > count) {
      setCount(count + 1)
    }
  }

  return (
    <div className={styles.box}>
      <img src={goodsPicture || require('./img_thumbnail.png')} alt="商品图片" />
      <div className={styles.righter}>
        <div>
          <div className={styles.title}>{name}</div>
          {
            (Array.isArray(labels) && labels.length > 0) && (
              <div className={styles.labelWrap}>
                {
                  labels.map((text, index) => <span key={index} className={styles.label}><span>{text}</span></span>)
                }
              </div>
            )
          }
        </div>
        <div className={styles.footer}>
          <div className={styles.title}>¥{round(price,2)}</div>
          <div className={styles.countWrp}>
            {
             maxCount > 0 && <div className={styles.tip}>最多可购买{maxCount}个</div>
            }
            <div className={styles.countBox}>
              <img src={require('@/assets/img/icon_reduce_6.png')} alt='' onClick={onReduce}/>
              <span>{count}</span>
              <img src={require('@/assets/img/icon_add_6.png')} alt='' onClick={onAdd}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}