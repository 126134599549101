import React, { useEffect, useState, useRef, useLayoutEffect } from "react"
import { Tabs, PullToRefresh } from "antd-mobile"
import "./index.scss"
import { getCollectionList } from "@/api/projectManager"
import withLoadList from "@/components/withLoadList/index"
import CollectionItem from "./component/CollectionItem"
import KeepAlive from "react-activation"

const tabItems = [
  { title: "数字藏品", key: "active" },
  { title: "往期系列", key: "over_sale" },
]

function collectionFun(activeIndex) {
  const sale_status = activeIndex === 0 ? "active" : "over_sale"
  function collectionList(params) {
    return { params: { sale_status }, api: getCollectionList }
  }
  return collectionList
}

const CollectionActiveItem = withLoadList(CollectionItem, collectionFun(0))
const CollectionOverItem = withLoadList(CollectionItem, collectionFun(1))

function Home(props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const bridge = window.JsBridge
  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    window.JsBridge.setTitle("数字藏品")
  }, [])

  return (
    <div className="home-page">
      <PullToRefresh onRefresh={() => {}}>
        <div className="home_bg">
          <img src={require("@/assets/img/homedetail/bg0.png")} alt="" />
        </div>
        <div className="tab_sticky">
          <Tabs
            activeKey={tabItems[activeIndex].key}
            stretch={false}
            onChange={(key) => {
              const index = tabItems.findIndex((item) => item.key === key)
              setActiveIndex(index)
            }}
          >
            <Tabs.Tab title="数字藏品" key="active">
              <CollectionActiveItem />
            </Tabs.Tab>
            <Tabs.Tab title="往期系列" key="over_sale">
              <CollectionOverItem />
            </Tabs.Tab>
          </Tabs>
        </div>
        {/* <Swiper
          direction="horizontal"
          indicator={() => null}
          ref={swiperRef}
          defaultIndex={activeIndex}
          onIndexChange={(index) => setActiveIndex(index)}
        >
          <Swiper.Item>
          </Swiper.Item>
          <Swiper.Item>
          </Swiper.Item>
        </Swiper> */}
      </PullToRefresh>
    </div>
  )
}

export default Home
