import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import './index.scss';
import CountDown from "@/view/guessingActy/components/countDown";
import {hideLoading, showLoading} from "@/components/loading";
import {getGuessingQuestions, getSignNum} from "@/api/projectManager_self";
import {clearAllTimer, convertToChineseNumeral, isEmptyArray} from "@/utils/publicFun";
import {Popup, Toast} from "antd-mobile";
import PutGuessBondDialog from "@/view/guessingActy/components/dialog/putGuessBondDialog";
import PrizeRecord from "@/view/guessingActy/components/prizeRecord";
import GetGuessBondDialog from "@/view/guessingActy/components/dialog/getGuessBondDialog";

let btn_white_a = require('../../imgs/btn_white_a.png');//未竞猜a
let btn_white_b = require('../../imgs/btn_white_b.png');//未竞猜b
let btn_jc_a = require('../../imgs/btn_jc_a.png');//已竞猜a
let btn_jc_b = require('../../imgs/btn_jc_b.png');//已竞猜b
let btn_grey_a = require('../../imgs/btn_grey_a.png');//不可竞猜a
let btn_grey_b = require('../../imgs/btn_grey_b.png');//不可竞猜b


const SessionCpt = (props) => { //各个场次
    const navigate = useNavigate();
    const [session, setSession] = useState(0);//场次
    const [round, setRound] = useState(0);//轮次
    const [quizCouponCount, setQuizCouponCount] = useState(0);//竞猜卷的数量
    const [sessionTitle, setSessionTitle] = useState('');//题目
    const [answerId, setAnswerId] = useState('');//正确答案的id 空未出结果 -2没人投 -1 平局排除（0：0）
    const [questionA, setQuestionA] = useState({});//问题A
    const [questionB, setQuestionB] = useState({});//问题B
    const [lastResult, setLastResult] = useState(0);//上一场 1赢，0非赢
    const [lastAnswerId, setLastAnswerId] = useState(0);//上一场空未出结果 -2没人投 -1 平局排除（0：0）
    const [currentTime,setCurrentTime] = useState();//当前时间
    const [endTime,setEndTime] = useState();//结束时间
    const guessInfoRef = useRef({});//竞猜信息
    const [curRoundStatus, setCurRoundStatus] = useState(0);//当前轮的状态 0=未开始 1=进行中 2=已结束
    const [prizeRecordVisible, setPrizeRecordVisible] = useState(false);
    const [winRound, setWinRound] = useState(0);//上一场赢的轮次
    const [roundPrize, setRoundPrize] = useState([]);//上一场赢的奖品集合 和winRound应该同时有值

    useEffect(() => {
        loadData();
    }, [])

    const reloadData = () => {
        let timer = setTimeout(() => {
            clearTimeout(timer);
            loadData();
        }, 1500);
    }

    // const getTime=()=>{
    //     let date = new Date();
    //     let year = date.getFullYear();      //年
    //     let month = date.getMonth() + 1;    //月
    //     let strDate = date.getDate();       //日
    //     let hours = date.getHours();        //时
    //     let minutes = date.getMinutes();    //分
    //     let seconds = date.getSeconds();    //秒
    //     let weekDay = date.getDay(); //星期
    //
    //     let time = year + "年" + month + "月" + strDate + "日 " + hours + "时" + minutes + "分" + seconds + "秒" ;
    //   return time
    //
    // }

    const loadData = async () => {
        try {
            clearAllTimer();
            // currentTime.current = '';
            guessInfoRef.current = {};
            showLoading()
            const res = await getGuessingQuestions();
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            setQuizCouponCount(res.data?.quizCouponCount);
            setCurrentTime(res.data?.currentTime)
            setWinRound(res.data?.round || 0);
            setRoundPrize(res.data?.roundPrize || [])
            if (res.data && res.data && res.data.guessingQuestionList && !isEmptyArray(res.data.guessingQuestionList)) {
                let guessInfo = res.data.guessingQuestionList[0];
                guessInfo.currentTime = res.data.currentTime;
                // console.log('currentTime---',res.data.currentTime)
                // console.log('sysTime---',getTime())

                guessInfoRef.current = guessInfo;
                setEndTime(guessInfo.endTime)
                setSession(guessInfo.session);
                setRound(guessInfo.round);
                setSessionTitle(guessInfo.title);
                setAnswerId(guessInfo.answerId);
                setLastAnswerId(guessInfo.lastAnswerId);
                setLastResult(guessInfo.lastResult);
                getSessionType();
                let exerciseProblemsList = guessInfo.exerciseProblemsList;
                if (!isEmptyArray(exerciseProblemsList)) {
                    let itemA = exerciseProblemsList[0];
                    itemA.question = itemA.answerOption + '.' + itemA.answerContent;
                    setQuestionA(itemA);
                    if (exerciseProblemsList.length >= 1) {
                        let itemB = exerciseProblemsList[1];
                        itemB.question = itemB.answerOption + '.' + itemB.answerContent;
                        setQuestionB(itemB);
                    }
                }
            }
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const getSessionType = () => {//获取当前的时间段
        let currentTime1 = guessInfoRef.current.currentTime;
        let startTime1 = guessInfoRef.current.startTime;
        let endTime1 = guessInfoRef.current.endTime;
        // console.log('currentTime----',currentTime1)
        // console.log('startTime----',startTime1)
        // console.log('endTime----',endTime1)
        if (!currentTime1 || !startTime1 || !endTime1) {
            return
        }


        const currentTimeStamp = Date.parse(currentTime1.replace(/-/g, '/'))
        const startTimeStamp = Date.parse(startTime1.replace(/-/g, '/'));
        const endTimeStamp = Date.parse(endTime1.replace(/-/g, '/'))

        let status = 0;//0=未开始 1=进行中 2=已结束
        if (currentTimeStamp > endTimeStamp) { //已结束
            status = 2;
        } else if (currentTimeStamp < endTimeStamp && currentTimeStamp > startTimeStamp) { //进行中
            status = 1;
        } else {
            status = 0;
        }
        console.log('status----',status)
        setCurRoundStatus(status);
    }

    const getCongratulationText = () => {//获得恭喜的文字 roundPrize
        if (isEmptyArray(roundPrize)) {
            return '';
        }
        let des = `恭喜您！第${convertToChineseNumeral(session)}场第${winRound}轮`
        for (const item of roundPrize) {
            let prize_type = item.prizeType || '0';
            let amount = item.amount;
            if (prize_type === '1') {
                let t1 = `30元变异无聊猿Lite优惠券x${amount}、`;
                des += t1;
            } else if (prize_type === '2') {
                let t1 = `60元变异无聊猿Lite优惠券x${amount}、`;
                des += t1;
            } else if (prize_type === '3') {
                let t1 = `100元变异无聊猿Lite优惠券x${amount}、`;
                des += t1;
            } else if (prize_type === '4') {//碎片
                let t1 = `变异无聊猿Lite碎片x${amount}、`;
                des += t1;
            } else if (prize_type === '5') {//变异无聊猿兑换券
                let t1 = `变异无聊猿Lite兑换券x${amount}、`;
                des += t1;
            } else if (prize_type === '6') {//史诗款兑换券
                let t1 = `史诗款兑换券x${amount}、`;
                des += t1;
            }
        }
        if (des.endsWith('、')) {
            des = des.substring(0, des.length - 1);
            return des;
        }
        return '';
    }

    const getProgress = () => { //获取进度
        let totalAmountA = questionA.totalAmount || 0;
        let totalAmountB = questionB.totalAmount || 0;
        if (totalAmountA === totalAmountB) {
            return 50;
        }
        let total = totalAmountA + totalAmountB;
        return (totalAmountA / total) * 100;
    }

    const round1View = () => { //第一轮的view
        if (curRoundStatus === 1) {//进行中
            if (!questionA.amount && !questionB.amount) {//用户没选中
                return <OneRoundingNotGuess/>
            } else {//选择了A或B
                return <OneRoundingHasGuess/>
            }
        }
    }
    // const [lastResult, setLastResult] = useState(0);//上一场 1赢，0非赢
    // const [lastAnswerId, setLastAnswerId] = useState(0);//上一场空未出结果 -2没人投 -1 平局排除（0：0）
    const round2View = () => { //第二轮的view
        if (curRoundStatus === 1) {//进行中
            if (lastResult === 1) { //上一场 1赢，0非赢
                if (!questionA.amount && !questionB.amount) {//用户没选中
                    return <TwoRoundingNotGuessWithOneRoundingSuccess/>
                } else {//选择了A或B
                    return <TwoRoundingHasGuessWithOneRoundingSuccess/>
                }
            } else {//非赢
                if (lastAnswerId === -2 || lastAnswerId === -1) {//平局
                    return <TwoRoundingWithOneRoundDraw/>
                } else {
                    return <TwoRoundingWithOneRoundingFailOrNotGuess/>
                }
            }
        }
    }

    const round3View = () => {//第三轮的view
        if (curRoundStatus === 1) {//进行中
            if (lastResult === 1) { //上一轮 1赢，0非赢
                if (!questionA.amount && !questionB.amount) {//用户没选中
                    return <ThreeRoundingNotGuessWithTwoRoundingSuccess/>
                } else {//选择了A或B
                    return <ThreeRoundingHasGuessWithTwoRoundingSuccess/>
                }
            } else {//0非赢 包括平局
                // return <ThreeRoundingWithTwoRoundingFailOrNotGuess/>
                return <ThreeRoundingWithTwoRoundingFailOrNotGuessOrDraw/>
            }
        } else if (curRoundStatus === 2) {//已结束
            if (lastResult !== 1 && (lastAnswerId === -2 || lastAnswerId === -1)) { //0非赢 第二轮平局
                return <ThreeRoundedWithTwoRoundDraw/>
            }
            if (answerId === questionA.answerId) {
                if (questionA.amount > 0) { //用户选择了A是正确答案
                    return <ThreeRoundedHasSuccess/>
                } else { //回答错误
                    return <ThreeRoundedHasFail/>
                }
            }

            if (answerId === questionB.answerId) {
                if (questionB.amount > 0) { //用户选择了B是正确答案
                    return <ThreeRoundedHasSuccess/>
                } else { //回答错误
                    return <ThreeRoundedHasFail/>
                }
            }
            return <ThreeRoundedHasFail/>;
        }
    }

    const contentView = () => {//0=未开始 1=进行中 2=已结束
        // if (curRoundStatus === 0) {
        //     console.log('当前curRoundStatus---', curRoundStatus, '---', '未开始')
        // } else if (curRoundStatus === 1) {
        //     console.log('当前curRoundStatus---', curRoundStatus, '---', '进行中')
        // } else if (curRoundStatus === 2) {
        //     console.log('当前curRoundStatus---', curRoundStatus, '---', '已结束')
        // }
        // console.log('当前轮次-round---', round)
        // return <ThreeRoundedWithTwoRoundDraw/>
        if (round === 1) {
            return round1View();
        } else if (round === 2) {
            return round2View();
        } else if (round === 3) {
            return round3View();
        }
    }

    const choiceAorB = (type) => { //选择A或者B
        calcuSignNum();
    }

    const calcuSignNum = async () => {//计算票数
        try {
            showLoading()
            const res = await getSignNum({session: session, round: round});
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            let allowNum = res.data?.allowNum || 0;
            if (allowNum > 0) {
                PutGuessBondDialog.open(guessInfoRef.current, (type) => {
                    if (type === 1) {//已经投票
                        loadData();
                    } else {
                        navigate(`/guessingActy/getCoupon?session=${session}`)
                    }
                })
            } else {
                if (round !== 1) {
                    Toast.show('您本场可用入场劵已使用完,当前不可竞猜');
                    return;
                }

                GetGuessBondDialog.open((type) => {//0=去兑换 1=去购买
                    // console.log('type---',type)
                    if (type === 0) {
                        navigate(`/guessingActy/getCoupon?session=${session}`);
                    } else {
                        navigate(`/guessingActy/getCoupon?currentIndex=1&session=${session}`);
                    }
                });
            }
        } catch (error) {
            Toast.show(error);
        }
    }

    // 第一轮进行中 没竞猜
    const OneRoundingNotGuess = () => {
        return (
            <>
                <p className={'title'}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={btn_white_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('A')}>
                            <p className={'p1 blue0'}>{questionA.question}</p>
                        </div>

                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={btn_white_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('B')}>
                            <p className={'p1 blue0'}>{questionB.question}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    // 第一轮进行中 已竞猜
    const OneRoundingHasGuess = () => {
        return (
            <>
                <p className={'title'}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={questionA.amount > 0 ? btn_jc_a : btn_white_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('A')}>
                            <p className={`p1 ${questionA.amount > 0 ? 'white0' : 'blue0'}`}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}
                        </div>

                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={questionB.amount > 0 ? btn_jc_b : btn_white_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('B')}>
                            <p className={`p1 ${questionB.amount > 0 ? 'white0' : 'blue0'}`}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }
    // 第二轮进行中  第一轮输了/没参与
    const TwoRoundingWithOneRoundingFailOrNotGuess = () => {
        return (
            <>
                {sessionZhongJiangView()}
                <p className={`title ${getCongratulationText().length > 0 ? 'title2' : ''}`}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={btn_grey_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'}>
                            <p className={'p1 white0'}>{questionA.question}</p>
                        </div>

                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={btn_grey_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'}>
                            <p className={'p1 white0'}>{questionB.question}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    //第二轮进行中,还没竞猜,上一轮赢了,需要展示奖品
    const TwoRoundingNotGuessWithOneRoundingSuccess = () => {

        return (
            <>
                {sessionZhongJiangView()}
                <p className={`title ${getCongratulationText().length > 0 ? 'title2' : ''}`}>{sessionTitle}</p>

                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={btn_white_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('A')}>
                            <p className={'p1 blue0'}>{questionA.question}</p>
                        </div>
                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={btn_white_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('B')}>
                            <p className={'p1 blue0'}>{questionB.question}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    //第二轮进行中,已经竞猜,上一轮赢了,需要展示奖品
    const TwoRoundingHasGuessWithOneRoundingSuccess = () => {

        return (
            <>
                {sessionZhongJiangView()}
                <p className={`title ${getCongratulationText().length > 0 ? 'title2' : ''}`}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={questionA.amount > 0 ? btn_jc_a : btn_white_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('A')}>
                            <p className={`p1 ${questionA.amount > 0 ? 'white0' : 'blue0'}`}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}
                        </div>
                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={questionB.amount > 0 ? btn_jc_b : btn_white_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('B')}>
                            <p className={`p1 ${questionB.amount > 0 ? 'white0' : 'blue0'}`}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }
    // 第二轮进行中   第一轮平局 需要展示奖品
    const TwoRoundingWithOneRoundDraw = () => {

        return (
            <>
                {sessionZhongJiangView()}
                <p className={`title ${getCongratulationText().length > 0 ? 'title2' : ''}`}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={btn_grey_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'}>
                            <p className={'p1 white0'}>{questionA.question}</p>
                        </div>

                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={btn_grey_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'}>
                            <p className={'p1 white0'}>{questionB.question}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }


    //第三轮进行中,还没竞猜,上一轮赢了,需要展示奖品
    const ThreeRoundingNotGuessWithTwoRoundingSuccess = () => {

        return (
            <>
                {sessionZhongJiangView()}
                <p className={`title ${getCongratulationText().length > 0 ? 'title2' : ''}`}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={btn_white_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('A')}>
                            <p className={'p1 blue0'}>{questionA.question}</p>
                        </div>
                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={btn_white_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('B')}>
                            <p className={'p1 blue0'}>{questionB.question}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    //第三轮进行中,已竞猜,上一轮赢了,需要展示奖品
    const ThreeRoundingHasGuessWithTwoRoundingSuccess = () => {

        return (
            <>
                {sessionZhongJiangView()}
                <p className={`title ${getCongratulationText().length > 0 ? 'title2' : ''}`}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={questionA.amount > 0 ? btn_jc_a : btn_white_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('A')}>
                            <p className={`p1 ${questionA.amount > 0 ? 'white0' : 'blue0'}`}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}
                        </div>
                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={questionB.amount > 0 ? btn_jc_b : btn_white_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB('B')}>
                            <p className={`p1 ${questionB.amount > 0 ? 'white0' : 'blue0'}`}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }
    // 第三轮进行中   第二轮输了/没参与竞猜/平局
    const ThreeRoundingWithTwoRoundingFailOrNotGuessOrDraw = () => {
        return (
            <>
                {sessionZhongJiangView()}
                <p className={`title ${getCongratulationText().length > 0 ? 'title2' : ''}`}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={btn_grey_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'}>
                            <p className={'p1 white0'}>{questionA.question}</p>
                        </div>

                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={btn_grey_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'}>
                            <p className={'p1 white0'}>{questionB.question}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // 第三轮已结束   中了
    const ThreeRoundedHasSuccess = () => {
        return (
            <>
                {sessionZhongJiangView()}
                <p className={`title ${getCongratulationText().length > 0 ? 'title2' : ''}`}>{sessionTitle}</p>
                <div className={'question_container'} style={{width: '90%'}}>
                    <div className={`question_a ${questionA.amount > 0 ? 'bg_div_red' : 'bg_div_white'}`}>
                        <div className={'question_ab_container '}>
                            <p className={'p1 white0'}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}
                        </div>
                    </div>
                    <div className={`question_a ${questionB.amount > 0 ? 'bg_div_red' : 'bg_div_white'}`}>
                        <div className={'question_ab_container '}>
                            <p className={'p1 white0'}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
                <div className={'proportion_container'}>
                    <img src={require('../../imgs/Ibl_a.png')} alt=""/>
                    <img src={require('../../imgs/Ibl_b.png')} alt=""/>
                </div>
                <div className={'proportion_press'}>
                    <div className={`proportion_press_left ${getProgress() > 99 ? 'all_round' : ''}`}
                         style={{width: `${getProgress()}%`}}/>
                </div>
                <div className={'proportion_press_count'}>
                    <p>{questionA.totalAmount || 0}劵</p>
                    <p>{questionB.totalAmount || 0}劵</p>
                </div>
            </>
        )
    }
    // 第三轮已结束   第三轮(没中/中 由后台决定)/或者平局 要展示进度条
    const ThreeRoundedHasFail = () => {

        return (
            <>
                {sessionZhongJiangView()}
                <p className={`title ${getCongratulationText().length > 0 ? 'title2' : ''}`}>{sessionTitle}</p>
                <div className={'question_container '} style={{width: '90%'}}>
                    <div className={'question_a bg_div_gray'}>
                        <div className={'question_ab_container '}>
                            <p className={'p1 white0'}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}

                        </div>
                    </div>
                    <div className={'question_a bg_div_gray'}>
                        <div className={'question_ab_container '}>
                            <p className={'p1 white0'}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
                <div className={'proportion_container'}>
                    <img src={require('../../imgs/Ibl_a.png')} alt=""/>
                    <img src={require('../../imgs/Ibl_b.png')} alt=""/>
                </div>


                <div className={'proportion_press'}>
                    <div className={`proportion_press_left ${getProgress() > 99 ? 'all_round' : ''}`}
                         style={{width: `${getProgress()}%`}}/>
                </div>
                <div className={'proportion_press_count'}>
                    <p>{questionA.totalAmount || 0}劵</p>
                    <p>{questionB.totalAmount || 0}劵</p>
                </div>
            </>
        )
    }
    // 第三轮已结束   第二轮平局 不要进度条
    const ThreeRoundedWithTwoRoundDraw = () => {

        return (
            <>
                {sessionZhongJiangView()}
                <p className={`title ${getCongratulationText().length > 0 ? 'title2' : ''}`}>{sessionTitle}</p>
                <div className={'question_container '} style={{width: '90%'}}>
                    <div className={'question_a bg_div_gray'}>
                        <div className={'question_ab_container '}>
                            <p className={'p1 white0'}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}
                        </div>
                    </div>
                    <div className={'question_a bg_div_gray'}>
                        <div className={'question_ab_container '}>
                            <p className={'p1 white0'}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }


    const sessionZhongJiangView = () => {//中奖展示
        if (getCongratulationText().length > 0) {
            return (
                <>
                    <div className={'congratulation_container'}>
                        <div className={'con_title'}>{getCongratulationText()}</div>
                        <img src={require('../../imgs/icon_red_sjx.png')} alt=""/>
                    </div>
                    <p className={'congratulation_title'}>{`第${session}场 第${winRound}轮 结果`}</p>
                </>
            )
        }
        return null;
    }

    const sessionCountTitle = () => {//第某某场
        let sessionString = '第' + convertToChineseNumeral(session || 0) + '场';
        return sessionString;
    }

    return (
        <div className='sessionCpt-component'>
            <div className={'session_container'}>
                <div className={`pk_msg_container ${sessionCountTitle().length >= 4 ? 'long' : ''}`}>
                    <span className={`session_count`}>{sessionCountTitle()}</span>
                    <span className={'session_count_0'}>第{round}/3轮</span>
                    <div className={'countDown'}>
                        {
                            (round === 3 && curRoundStatus === 2) ? null :
                                <CountDown type={1} endTitle={'结束'}
                                           sysTime={currentTime}
                                           // sysTime={new Date()}
                                           endTime={endTime} refreshData={() => reloadData()}/>
                        }
                    </div>
                </div>
                <p className={'more_session'} onClick={() => navigate(`/guessingActy/allSession`)}>更多场次&gt;</p>
                <div className={'session_info'}>
                    <div className={'session_info_container'}>
                        <p className={'juan_count'}>入场券：{quizCouponCount}</p>
                        {contentView()}
                        <p className={'award_record'}
                           onClick={() => setPrizeRecordVisible(!prizeRecordVisible)}>竞猜/奖品记录&gt;</p>
                    </div>
                    <Popup
                        visible={prizeRecordVisible}
                        onMaskClick={() => {
                            setPrizeRecordVisible(false);
                        }}
                        position='right'
                        bodyStyle={{width: '100vw'}}
                    >
                        <div
                            style={{height: '100vh', overflow: 'hidden'}}>
                            <PrizeRecord leftClick={() => setPrizeRecordVisible(false)}
                                         prizeRecordVisible={prizeRecordVisible}/>
                        </div>
                    </Popup>

                </div>
            </div>
        </div>
    )
}

export default SessionCpt;
