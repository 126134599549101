import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Mask } from 'antd-mobile'
import styles from './recallApe.module.scss'
import close from '@/assets/img/landfi/icon_del.png'

const RecallApe = ({onClose, confirmCB}) => {
  const [visible, setVisible] = useState(true)

  const closeMask = () => {
    setVisible(false)
    onClose()
  }

  const onConfirm = () => {
    confirmCB && confirmCB()
    closeMask()
  }

  return <Mask
    className={styles.recallape_mask}
    visible={visible}
  >
    <div className={styles.recallape_container}>
      <div className={styles.header}>
        <span className={styles.close_recallape_mask}></span>
        <span className={styles.title}>召回确认</span>
        <img className={styles.close_recallape_mask} src={close} alt="" onClick={closeMask} />
      </div>
      <div className={styles.recallape_tip}>
        无聊猿正在打工，还未完成今天任务，召回后没有经验奖励，确认召回？
      </div>
      <div className={styles.btn_box}>
        <div className={`${styles.cancel_btn} ${styles.common_btn}`} onClick={closeMask}>取消</div>
        <div className={`${styles.confirm_btn} ${styles.common_btn}`} onClick={onConfirm}>确认</div>
      </div>
    </div>
  </Mask>
}

RecallApe.open = ({confirmCB}) => {
  let div = null;
  if (!div) {
      div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
      div.remove();
  }
  ReactDOM.render(<RecallApe onClose={close} confirmCB={confirmCB}/>, div);
}

export default RecallApe