import React, {useEffect, useState, useRef} from 'react'
import styles from './styles.module.scss'
import {Mask, Toast} from 'antd-mobile'
import ReactDOM from "react-dom";
import CommonView from "@/view/eleCompound/components/commonView";
import {hideLoading, showLoading} from "@/components/loading";
import {recoveryActivityExec} from "@/api/projectManager_self";
import {metaRecovery24_0116} from "@/constant";


const HuiShouColModal = ({onClose, data, refshData}) => {
    const [type, setType] = useState(0)//0=回收藏品 1=回收成功
    const [list, setList] = useState([])

    const sure = async () => {
        let nftNoList = [];
        for (const item of data) {
            nftNoList.push(item.nftNo);
        }
        try {
            showLoading()
            const res = await recoveryActivityExec({
                activityCode: metaRecovery24_0116,
                nftNoList: nftNoList,
            });
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            setType(1);
            setList(res.data || []);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const getImgs = () => {
        return data.map((item, index) => {
            return <div className={styles.item} key={index.toString()}>
                <img src={item.nftImage} alt="" className={styles.img}/>
                <span className={styles.nftNo}>{item.nftNo}</span>
            </div>
        })
    }

    const getImgs1 = () => {
        return list.map((item, index) => {
            return <img src={item} alt="" key={index.toString()} className={styles.img1}/>
        })
    }

    return <Mask className={styles.recycling_mask} destroyOnClose={true}>
        <CommonView onClose={onClose} title={type === 0 ? '回收藏品' : '回收成功'} hideBack={true}>
            <div className={styles.container}>
                {
                    type === 0 ? <>
                        <p className={styles.title}>确定将以下藏品回收并兑换为元素吗？确认后以下藏品将被回收，
                            获得{data.length}张元素，本次操作不可逆。</p>
                        <div className={styles.list}>
                            {getImgs()}
                        </div>
                        <div className={styles.btns}>
                            <p onClick={onClose}>取消</p>
                            <p onClick={sure}>确认回收</p>
                        </div>
                    </> : <>
                        <p className={styles.title} style={{textAlign: 'center'}}>恭喜获得元素</p>
                        <div className={styles.list}>
                            {getImgs1()}
                        </div>
                        <div className={styles.btn}>
                            <p onClick={() => {
                                onClose && onClose();
                                refshData && refshData();
                            }}>确认</p>
                        </div>
                    </>
                }
            </div>
        </CommonView>
    </Mask>
}

HuiShouColModal.open = (data, refshData) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => div.remove();
    ReactDOM.render(<HuiShouColModal
        data={data}
        refshData={refshData}
        onClose={close}
        visible
    />, div);
}

export default HuiShouColModal
