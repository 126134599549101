/**
 * @description: 稀有度
*/
import React from "react";
import { isEmptyString } from "@/utils/publicFun";
import './index.scss';

const RarityView = (props) => {

    const { rarityVOS } = props

    const lists = () => {
        return rarityVOS.map((item, index) => {
            let rarity = item.rarity || '';
            let isGlod = false;
            if (!isEmptyString(rarity)) {
                let rarityFloat = parseFloat(rarity) || 0
                if (rarityFloat > 5) {
                    isGlod = false;
                } else {
                    isGlod = true;
                }
            }
            return ( //  margin-left: 4vw;
                <div className={'collection-num_list_item'} style={{ marginLeft: (index % 4 === 0) ? '4vw' : '1vw' }}
                    key={index.toString()}>
                    <div className={'item_name_des'}>{item.classifyName || ''}</div>
                    <div className={'item_name'}>{item.eigName || ''}</div>
                    <div className={`item_name_rarity ${isGlod ? 'rarity-num_glod' : ''}`}>{rarity}</div>
                </div>

            )
        })
    }


    const rarityListView = () => { //稀有度
        if (rarityVOS.length === 1) {
            const item = rarityVOS[0] ?? {};
            const rarity = item.rarity || '';
            let isGlod = false;
            if (!isEmptyString(rarity)) {
                const rarityFloat = parseFloat(rarity) || 0
                if (rarityFloat > 5) {
                    isGlod = false;
                } else {
                    isGlod = true;
                }
            }
            return <div className="number rarity">
                <span className="collection-num">稀有度</span>
                <span className={`num rarity-num ${isGlod ? 'rarity-num_glod' : ''}`}>{rarity}</span>
            </div>
        } else {
            return (
                <div className="rarity_list_father_view">
                    <span className="collection-num_list_title">稀有度</span>
                    <div>{lists()}</div>
                </div>
            )
        }
    }
    return (
        <div className="rarity-view">
            {rarityListView()}
        </div>
    )
}

export default RarityView;