import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useMemo,
  useEffect,
} from "react";
import styles from "./styles.module.scss";
import { Input } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { getFeesRate, getAbleRentalPeriod } from "@/api/guland";
import DropdownSelect from "./../dropdownSelect";
import MyModal from "@/view/landGame/jewelLand/modal";
import TipModal from "./../tipsModal";
import { ApeCateOptions, getLandLevel } from "@/view/landGame/utils";
import { Toast } from "antd-mobile";
import _ from "lodash";
const SelectInfo = forwardRef(({ selectData = [], type, refresh }, ref) => {
  const navigate = useNavigate();
  const [price, setPrice] = useState("");
  const [daysOptions, setDaysOptions] = useState([]);
  const [selectList, setSelectList] = useState({});
  const [feesRate, setFeesRate] = useState("");
  const [filterData, setFilterData] = useState({});
  const apeCateOption = _.keyBy(ApeCateOptions, "value");

  const getDaysOptions = async () => {
    try {
      const res = await getAbleRentalPeriod();
      if (res.code !== "000000") throw res.msg;
      const arr = res.data?.split(",");
      const listTemp = [];
      arr.forEach((item) => {
        if (item === '-1') {
          listTemp.push({ label: "永久", value: Number(item) });
        } else {
          listTemp.push({ label: item + "天", value: Number(item) });
        }
      });
      setDaysOptions(listTemp);
    } catch (error) {
      console.log(error);
      Toast.show(error);
    }
  };

  const getFeesRateData = async () => {
    try {
      const res = await getFeesRate();
      if (res.code !== "000000") throw res.msg;
      setFeesRate(res?.data);
    } catch (error) {
      console.log(error);
      Toast.show(error);
    }
  };
  const levelChange = (data) => {
    setFilterData(data);
  };

  useEffect(() => {
    getDaysOptions();
    getFeesRateData();
  }, []);
  useEffect(() => {
    const selectDataTemp = {};
    selectData &&
      selectData.forEach((item) => {
        // if (type === 1) {
        //   item.collectionName =
        //     apeCateOption[String(item?.collectionId)]?.label;
        // } else {
        //   item.collectionName =
        //     getLandLevel(item?.collectionId).toUpperCase() + "级";
        // }
        if (!selectDataTemp[item.collectionName]) {
          selectDataTemp[item.collectionName] = [];
        }
        selectDataTemp[item.collectionName].push(item);
      });
    setSelectList(selectDataTemp);
  }, [selectData]);

  const onConfirm = () => {
    if (selectData?.length === 0) {
      Toast.show(`尚未选择${type === 1 ? "无聊猿" : "土地"}`);
      return;
    }
    if (!price) {
      Toast.show(`请输入租赁价格`);
      return;
    }
    const ModalInstance = MyModal.show({
      content: (
        <TipModal
          type={type}
          selectData={selectData}
          selectList={selectList}
          rentalPeriod={filterData}
          rentalFee={price}
          onClose={(flag) => {
            ModalInstance?.close();
            console.log("refresh", flag);
            if (flag) {
              refresh();
            }
          }}
        />
      ),
    });
  };
  useImperativeHandle(ref, () => ({
    refresh: () => {
      setPrice("");
      setSelectList({});
      getDaysOptions();
      getFeesRateData();
    },
  }));
  const priceChange = (val) => {
    if (val === price) {
      return;
    }
    const reg =
    /^\+?[1-9][0-9]*$/;
    if (!reg.test(val)) {
      setPrice("");
    } else {
      setPrice(val);
    }
  };
  const count = useMemo(() => {
    return selectData?.length * price;
  }, [selectData, price, rateCount]);

  const rateCount = useMemo(() => {
    const gemCount = (price * feesRate) / 100
    if(gemCount>=1){
      return Math.floor(gemCount)
    }else if(gemCount>0){
      return 1
    }else{
      return 0
    }
  }, [selectData, price, feesRate]);
  return (
    <div className={styles.info_out}>
      <div className={styles.input_out}>
        <div className={styles.input_box}>
          <div className={styles.label}>价格：</div>
          <div className={styles.input}>
            <Input
              value={price}
              type="number"
              onChange={priceChange}
              placeholder="请输入租赁价格"
            ></Input>
            <div className={styles.icon}></div>
          </div>
        </div>
        <div className={styles.select_box}>
          <div className={styles.label}>租期：</div>
          <div className={styles.select}>
            <DropdownSelect
              title=""
              options={daysOptions}
              onChange={levelChange}
            />
          </div>
        </div>
      </div>

      <div className={styles.checked_box}>
        <div className={styles.checked_info}>{`已选：${Object.entries(
          selectList
        ).map((item) => {
          const name = item[0];
          const count = item[1]?.length || 0;
          return `${count}个${name}${type === 1 ? "无聊猿" : ""}`;
        })}`}</div>
        <div className={styles.checked_info}>{`预计收入：${
          count - (selectData?.length * rateCount)
        }宝石${(selectData?.length * rateCount) === 0?'':`（消耗${ (selectData?.length * rateCount)}宝石手续费）`}`}</div>
      </div>

      <div className={styles.btn_box}>
        <div
          className={styles.btn_cancle}
          onClick={() => {
            navigate(-1);
          }}
        ></div>
        <div className={styles.btn_publish} onClick={onConfirm}></div>
      </div>
    </div>
  );
});

export default SelectInfo;
