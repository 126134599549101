/**
 * @description 藏品名称及3D图片展示卡片
*/

import React, { useMemo, useEffect, useRef } from "react";
import Rotate3D from "@/components/Rotate3D";
import "./index.scss";

const sources3d = [
    {
        source:require("@/assets/img/ape-world/cartoon_front.png"),
        direction: 'front'
    },
    {
        source:require("@/assets/img/ape-world/cartoon_front.png"),
        direction: 'back'
    },
    {
        source:require("@/assets/img/ape-world/cartoon_left.png"),
        direction: 'left'
    },
    {
        source:require("@/assets/img/ape-world/cartoon_left.png"),
        direction: 'right'
    },
    {
        source:require("@/assets/img/ape-world/cartoon_top.png"),
        direction: 'up'
    },
    {
        source:require("@/assets/img/ape-world/cartoon_top.png"),
        direction: 'down'
    }
]

const WordWhatCard = (props)=> {

    const {
        dataSouce={}, 
        sourceBg, //3d背景图或背景色
        sources=sources3d  //3d资源图片
    } = props

    const isColor = useMemo(()=>isValidColor(sourceBg),[sourceBg]);

    return (
        <div className="wordWhatCard">
            <span>{ dataSouce.title || '---'}</span>
            <div className="ape_world_content">
                <div className="content_display" 
                    style={{background: isColor ? sourceBg : 'transparent'}}
                >
                    {
                        typeof sources === 'string' ?
                        <img src={sources}/>
                        : 
                        sourceBg && !isColor ?
                        <img src={sourceBg}/>: null
                    }
                    {
                        Array.isArray(sources) ?
                        <Rotate3D sources={sources} />
                        : null
                    }
                    
                </div>
            </div>
        </div>
    )
}

const isValidColor = (str)=> {
    // 16进制颜色 (# + 6位或3位字符)
    const hexColorPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

    // 颜色关键词 (如 red, blue等)
    const namedColorPattern = /^(?:aqua|black|blue|fuchsia|gray|green|lime|maroon|navy|olive|purple|red|silver|teal|white|yellow)$/i;

    // RGB或RGBA格式 (rgb(r,g,b) 或 rgba(r,g,b,a))
    const rgbColorPattern = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(,\s*(\d+(\.\d+)?))?\)$/;

    // 检查是否符合任一格式
    return hexColorPattern.test(str) || namedColorPattern.test(str) || rgbColorPattern.test(str);

}

export default WordWhatCard;