import NFTHeader from "@/components/NFTHeader"
import styles from './styles.module.scss';
import image from './poster-rule.png'
import RulesModal from "@/view/guessingActy/components/ruleModal";

export default () => {

    const btnClick=()=>{
        RulesModal.open();
    }

  return (
    <div className={styles.container}>
      <NFTHeader backType={2}/>
        <div className={styles.rule_btn_container}>
            <img src={require('../imgs/btn_rule.png')} alt="" className={styles.rule_btn} onClick={()=>btnClick()}/>
        </div>
      <img src={image} />
    </div>
  )
}
