/**
 * @description 共创漫画数字藏品- 发行详情页
 * @returns { route: /apeWorld/cartoonSaleDetail }
*/

import React, { useState, useEffect, useRef } from "react";
import {useNavigate, useLocation} from "react-router-dom";
import { Toast } from "antd-mobile"
import {showLoading, hideLoading} from '@/components/loading';
import { getUrlAllParams } from "@/utils/publicFun";
import { getGoodsDetail} from "@/api/projectManager_self";
import NFTHeader from '@/components/NFTHeader';
import ShareMask from "@/components/ShareMask/index";
import WordWhatCard from '../components/WordWhatCard'
import DetailCard from '../components/DetailCard'
import IntroduceCard from '../components/IntroduceCard'
import MarkingCard from '../components/MarkingCard'
import NoteBuyCard from '../components/NoteBuyCard'
import BottomBuy from '../components/BottomBuy'

import "./index.scss";

const CartoonSaleDetail = (props)=> {
    const {search} = useLocation();
    const {collectionId='19'} = getUrlAllParams(search.slice(1));

    const [maskVisible,setMaskVisible] = useState(false)

    const [detailData, setDetailData] = useState({})

    useEffect(()=>{
        const getData = async () => {
            try {
                showLoading()
                const res = await getGoodsDetail({collectionId: collectionId})
                hideLoading();
                if (res.code !== '000000') throw res.msg;
                if (res.data && res.data.info) {
                    setDetailData(res.data.info)
                }
            } catch (error) {
                Toast.show(error);
            }
           
        }
        getData()
    },[])

    const onShareClick = ()=> {
        // Toast.show("分享操作")
        setMaskVisible(true)
    }

    return (
        <div className="cartoonSaleDetail_page">
            <NFTHeader leftImg={require('@/assets/img/icon/go_back.png')} 
                rightNode={()=><img style={{height: 20}} src={require('@/assets/img/ape-world/egg_bt_fenxiang.png')}/>}
                rightClick={onShareClick}
            />
            <div className="page-body">
                <div className="page-body--content">
                    <div className="top-bg">
                        <img src={require('@/assets/img/ape-world/cartoon_bg.png')}/>
                    </div>
                    <div className="body-content">
                        <WordWhatCard dataSouce={detailData}/>
                        <DetailCard dataSouce={detailData}/>
                        <IntroduceCard dataSouce={detailData}/>
                        <MarkingCard dataSouce={detailData}/>
                        <NoteBuyCard dataSouce={detailData}/>
                    </div>
                </div>
                <div className="bottom">
                    <BottomBuy dataSouce={detailData}/>
                </div>
            </div>
            <ShareMask
                maskVisible={maskVisible}
                closeMask={() => setMaskVisible(false)}
                shareCode=''
                title=''
                context=''
                icon=''
                type='10'
            />
        </div>
    )
}

export default CartoonSaleDetail;