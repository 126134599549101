
import React, { useEffect, useState, useRef } from 'react';
import './index.scss';

const RecyclingModal = (props) => {

    const {selectArray=[], onConfirm, onCancel} = props

    return (
        <div className='recycling__modal-body'>
            <div className='content'>
                <p>{'确定回收'}</p>
                <span>确定将以下藏品回收吗？确认后以下藏品将被回收，且操作不可逆</span>
                <div className='img-group'>
                    {
                        selectArray.map((item, i) => {
                            return (
                                <div className='preview-item' key={i}>
                                    <img className='preview-img' src={item.nftImage} alt="" />
                                    <span>#{item.nftNo}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='btn-group'>
                    <div className='cancel' onClick={onCancel}>取消</div>
                    <div className='confirm' onClick={onConfirm}>确认回收</div>
                </div>
                <img src={require('@/assets/img/collectionRecoveryLand/close_x.png')}
                    alt=""
                    onClick={onCancel}
                />
            </div>
        </div>
    )
}

export default RecyclingModal;