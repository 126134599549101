import React, {useState} from "react";
import styles from './styles.module.scss';

const SimpleDialog = (props) => {
  const { visible, title, leftBtnText="取消", leftBtnCB, rightBtnText="确定", rightBtnCB, onClose, errorShow, name } = props;
  const [surname, setSurname] = useState('');

  const leftBtnClick = () => {
    leftBtnCB && leftBtnCB();
    setSurname('');
    onClose();
  }

  const rightBtnClick = () => {
    rightBtnCB && rightBtnCB(surname);
  }

  if(!visible) return null;
  return (
    <div className={styles.modal}>
      <div className={styles.modal_container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.btn_box}>
          <div className={styles.left_btn} onClick={leftBtnClick}>{leftBtnText}</div>
          <div className={styles.line}/>
          <div className={`${styles.right_btn} ${surname ? styles.right_btn_activity: ''}`} onClick={rightBtnClick}>{rightBtnText}</div>
        </div>
      </div>
    </div>
  );
};
export default SimpleDialog;
