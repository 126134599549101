import React, {useState, useEffect, useRef} from 'react';
import './style.scss';

const CountDown = ({sysTime, endTime, refreshData}) => {
  const initalTime = {day: '00', hour: '00', minute: '00', second: '00'};
  const [countTime, setCountTime] = useState(initalTime);
  const timeStampCurrent = useRef(null);
  const timerCurrent = useRef(null);

  const clearAllTimer = () => { //清除所有的定时器
    let end = setInterval(function () {
    }, 3000);
    for (let i = 1; i <= end; i++) {
        i && clearInterval(i);
    }
  }

  const countDown = (currentTime, endTime, callBack) => {
    if (!currentTime || !endTime) {
      return initalTime;
    }
    const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))
    if (timeStampCurrent.current == null) {
      timeStampCurrent.current = currentTimeStamp
    }
    const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
    const distanceStamp = endTimeStamp - timeStampCurrent.current;
    if (distanceStamp <= 0) {
      callBack && callBack(distanceStamp);
      return initalTime;
    }
    let remianAllSeconds = Math.floor(distanceStamp / 1000);
    let day = Math.floor(remianAllSeconds / (60 * 60 * 24));
    let hour = Math.floor(remianAllSeconds / (60 * 60) % 24);
    let minute = Math.floor(remianAllSeconds / 60 % 60);
    let second = Math.floor(remianAllSeconds % 60);

    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;
    return {day: day, hour: hour, minute: minute, second: second};
  }

  const timerStart = () => {
    timerCurrent.current && clearInterval(timerCurrent.current);
    timerCurrent.current = setInterval(() => {
      if (timeStampCurrent.current) {
        timeStampCurrent.current = timeStampCurrent.current + 1000;
      }
      let obj = countDown(sysTime, endTime, (distanceStamp) => {
        timerCurrent.current && clearInterval(timerCurrent.current);
        if(distanceStamp === 0) {
          refreshData && refreshData();
        }
      });
      setCountTime(obj);
    }, 1000)
  }

  const visibilitychange = () => {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        clearAllTimer();
      } else {
        refreshData && refreshData();
      }
    });
  }

  useEffect(() => {
    visibilitychange();
  }, [])

  useEffect(() => {
    timerStart();
    return () => {
      timerCurrent.current && clearInterval(timerCurrent.current); //先清空之前的定时器
    }
  }, [sysTime, endTime])
  const {day, hour, minute, second} = countTime;
  return (
    <div className='count-down-component'>
      <span className='text'>开奖倒计时</span>
      <span className='count'>{day}</span>
      <span className='text'>天</span>
      <span className='count'>{hour}</span>
      <span className='text'>时</span>
      <span className='count'>{minute}</span>
      <span className='text'>分</span>
      <span className='count'>{second}</span>
      <span className='text'>秒</span>
    </div>
  )
}

export default CountDown;