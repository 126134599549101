import React, { useRef, useState, useEffect } from 'react'
import styles from './style.module.scss'
import close from '@/assets/img/landfi/close-circle-fill.png'
import ListView from "@/components/AntListView"
import { GoodsDetail } from './goodsDetail'
import OrderConfirm from './orderConfirm'
import MyModal from './modal'
import dayjs from 'dayjs'
import appService from '@/utils/appService'

const handleText = (item) => {
  let text = ''
  const isFillin = item.address && item.province && item.city && item.area && item.street
  if (item.prizeType === 3){
    if (isFillin) {
      text = '查看详情'
    } else {
      text = '填写地址'
    }
  } else if (item.prizeType === 11){
    if (isFillin) {
      text = '已兑换茅台'
    } else {
      text = '积分兑换茅台'
    }
  }else if (item.prizeType === 13){
    // 茅台兑换资格券
    text = `查看`
  } else {
    text = '无'
  }
  return text
}

const RewardRecord = ({onClose, container}) => {
  const [recordsData, setRecordsData] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const currentPage = useRef(1);
  const pageSize = useRef(20);

  useEffect(() => {
    getRecordsList()
  }, [])

  const getRecordsList = async (isRefresh=true, loading=true) => {
    if(isRefresh) {
      currentPage.current = 1
    } else {
      currentPage.current = currentPage.current + 1
    }
    const params = {
      pageNum: currentPage.current,
      pageSize: pageSize.current,
      activityCode: 'gemstoneLottery2403',
    }
    const res = await appService('300021', params, loading)
    console.log("🚀 ~ getRecordsList ~ res:", res)
    if (res?.status > 0) {
      const { list = [] } = res?.data || {}
      if(currentPage.current === 1) {
        setRecordsData(list);
      } else {
        setRecordsData([...recordsData, ...list])
      }
      isRefresh && setIsLoad(true);
      setHasMore(list.length >= pageSize.current)
    }
  }

  const clickRecord = (item) => {
    if (item.prizeType === 3) {
      openOrder(item)
      onClose()
    } 
    else if (item.prizeType === 13) {
      //跳转到g-member抽奖券列表
      onClose();
      window.JsBridgeNew.hiddenStatusBar(false);
      window.JsBridgeNew.screenRotate(true);
      window.JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_GKT_LINK}/front/activities/activityShare/ticket-record`})
    }
    else if (item.prizeType === 11) {
      if (item.address && item.province && item.city && item.area && item.street) {
        openOrder(item)
      } else {
        openGoodsDetail(item)
      }
      onClose()
    } else {
      return
    }
  }

  const openOrder = (item) => {
    const ModalInstance = MyModal.show({
      content: <OrderConfirm onClose={() => ModalInstance?.close()} recordInfo={item} container={container}/>,
      getContainer: container,
    })
  }

  const openGoodsDetail = (item) => {
    const ModalInstance = MyModal.show({
      content: <GoodsDetail onClose={() => ModalInstance?.close()} recordInfo={item} container={container}/>,
      getContainer: container,
    })
  }

  const ListItem = (item) => {
    return <div className={styles.record_table_item}>
      <div>{item?.prizeName}</div>
      <div>{item.createTime ? dayjs(item.createTime).format('YYYY.MM.DD HH:mm:ss'): ''}</div>
      <div className={[3, 11].includes(item.prizeType) ? styles.fillin_address: ''} onClick={() => clickRecord(item)}>
        {handleText(item)}
      </div>
    </div>
  }

  return <div className={styles.reward_record}>
    <img className={styles.close} src={close} onClick={onClose} alt="" />
    <div className={styles.reward_title}>奖品历史记录</div>
    <div className={styles.record_table_title}>
      <div>奖励清单</div>
      <div>抽奖时间</div>
      <div>操作</div>
    </div>
    <div className={styles.record_content}>
      <div className={styles.record_table}>
        <ListView
          dataSource={recordsData}
          hasMore={hasMore}
          style={{paddingTop: 30}}
          renderItem={ListItem}
          onEndReached={async () => {
            await getRecordsList(false, false);
          }}
          isLoad={isLoad}
          customStyle={{
            imgStyle: {width: 120, height: 120},
            textStyle: {color: '#999', fontSize: 12},
          }}
        />
      </div>
    </div>
  </div>
}

export default RewardRecord