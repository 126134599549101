import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import arrow from '@/assets/img/landfi/select-arrow.png';

const DropdownSelect = ({title = '等级', onChange, options = []}) => {
  const [showContent, setShowContent] = useState(false);
  const [currentSelect, setCurrentSelect] = useState({});

  const showSelect = () => {
    setShowContent(!showContent);
  }

  const onSelect = (item) => {
    setCurrentSelect(item);
    setShowContent(false);
    onChange && onChange(item);
  }
  useEffect(()=>{
    setCurrentSelect(options[0])
    onChange && onChange(options[0]);

  },[options])

  return <div className={styles.dropdown_select}>
    <div className={styles.dropdown_title} onClick={showSelect}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content_box}>
        <div className={styles.content_top}>
          <div className={styles.content}>{currentSelect?.label}</div>
          <div className={showContent ? styles.rotate: styles.icon}><img src={arrow} alt="" /></div>
        </div>
        <div className={`${styles.dropdown_content} ${showContent ? styles.show: styles.hidden}`}>
          {
            options.length > 0 && options.map((item, index) => {
              return <div className={styles.dropdown_content_item} key={index} onClick={() => onSelect(item)}>{item.label}</div>
            })
          }
        </div>
      </div>
    </div>
  </div>
}

export default DropdownSelect;