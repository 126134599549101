/**
 * @description 通用api集结地，此处API需与业务无关
*/
import requests from "@/utils/requests_self";
//支付类型配置
const PayTypeKey = {
    WeChat: 'WXPAY',
    WeChaticon: require('@/assets/img/orderpay/wx.png'),
    Alipay: 'ALIPAY',
    Alipayicon: require('@/assets/img/orderpay/zfb.png'),
    // 以下两种接口暂不支持
    iosIAP: 'IOS_IAP',
    iosIAPicon: require("@/assets/img/orderpay/apple_pay.png"),
    Point: 'POINT',
    Pointicon: require("@/assets/img/orderpay/point.png"),
    
};
/**
 * @description 获取支付方式
 * @param { * }
 * @returns { payName:微信支付, payCode: WeChat, tradeType: APP }
 */ 
export const getPayType = async (params = {}) => {
    return await requests.get('/customer/base/v1/payItems').then((res) => {
        res.data.result = res.data.result?.map((item) => {
            item.icon = PayTypeKey[item.payCode + 'icon']
            item.payType = PayTypeKey[item.payCode]
            return item
        })
        return res;
    }).catch((err)=>{
        return err;
    });
}