import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import {Platform, getUrlAllParams} from "@/utils/publicFun";
import './style.scss';

import gotoBrowserImg from '@/assets/img/goto-browser.png';
import activeImg from '@/assets/img/variationApe/xiazai_bg2.png';

const appSchema = 'gktapp://com.gkewang';
const downloadUrl = 'https://app.gkewang.com/app';

const VariationApeInviteSuccess = ()=> {
    const [maskShow, setMaskShow] = useState(false);
  
    const url = '/variationApe/home';
    const targetUrl = `${window.location.origin}${url}`;
    const luanchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(`${targetUrl}`)}`;
  
    const openApp = () => {
      if (Platform.isWechat()) {
        setMaskShow(true);
      } else {
        window.location.href = luanchUrl;
        setTimeout(() => {
            window.location.href = downloadUrl;
        }, 3000);
      }
    }
  
    return <div className="variation-ape-share">
      <img src={activeImg} alt="" />
      <div className='load-btn' onClick={() => openApp()}>
        {'下载/打开绿地G优APP'}
      </div>
      {
        maskShow && 
        <div className='mask' onClick={() => setMaskShow(false)}>
          <img src={gotoBrowserImg} alt=""/>
        </div>
      }
    </div>
}

export default VariationApeInviteSuccess;