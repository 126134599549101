import React, { useLayoutEffect } from "react"
import "./index.scss"

function MyAgreement() {
  const bridge = window.JsBridge
  bridge.setTitle("详情")
  window.scroll(0, 0)

  return (
    <div className="agreement">
      <p style={{ textAlign: "center" }}>绿地数字藏品许可及服务协议</p>
      <br />
      欢迎您使用绿地G优APP及数字藏品服务！
      <br />
      <br />
      为使用绿地数字藏品服务（以下统称“本服务”），您应当阅读并遵守《绿地数字藏品许可及服务协议》（以下简称“本协议”），以及《绿地G优用户注册协议》、《绿地G优隐私政策》。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制绿地G优责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款等，以及开通或使用某项服务的单独协议。限制、免责条款或者其他涉及您重大权益的条款可能以加粗、加下划线等形式提示您重点注意。如果您对本协议内容或页面提示信息有任何疑问，请先联系客服咨询。
      除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用本软件和/或相关服务。您点击“同意”或您的下载、安装、使用、登录等行为或者您以其他任何明示或者默示方式表示接受本协议的，即视为您已阅读并同意本协议的约束。本协议即在您与绿地G优之间产生法律效力，成为对双方均具有约束力的法律文件。
      任何未满18周岁的未成年人均不得注册帐号或使用本服务。绿地G优将依赖您提供的个人信息判断用户是否为未成年人。此外，如果您不具有完全民事行为能力，请在法定监护人（以下简称“监护人”）的陪同下阅读和判断是否同意本协议。
      <br />
      <br />
      一、【协议的范围】
      <br />
      1.1 本协议是您通过绿地G优（在协议中，指上海绿地吉客智能科技有限公司及其关联方）使用绿地数字藏品服务所订立的协议。
      <br />
      1.2
      本协议的内容，包括但不限于以下与本软件和/或本服务相关的协议、规则、规范以及绿地G优可能不断发布的关于本软件和/或本服务的相关协议、规则、规范等内容。前述内容一经正式发布，即为本协议不可分割的组成部分，与其构成统一整体，您同样应当遵守：
      <br />
      （1）《绿地G优用户注册协议》；
      <br />
      （2）《绿地G优隐私政策》。
      <br />
      1.3
      上海绿地吉客智能科技有限公司（以下简称“吉客智能”）作为本软件的运营主体以及服务提供方，有权不经另行通知变更软件运营和/或服务提供主体。变更后的主体将继续按照本协议约定并向您提供服务，上述主体的变更不会影响您本协议项下的权益。
      <br />
      <br />
      二、【本服务说明】
      <br />
      2.1 绿地G优依托“Trust.lnk
      BaaS服务平台”或后续新增的第三方合作方（具体以页面实际展示为准）提供的技术服务，通过本软件向您提供访问、分享、购买数字藏品（下称“藏品”）的入口。数字藏品具有加密性和不可篡改性，即数字藏品元数据中的创作者、创作和上链时间、购买者等信息，一经确认则不可篡改。您购买数字藏品后，作为购买者，您的相关信息将写入该数字藏品的元数据中，作为您拥有该数字藏品所有权的凭证。
      <br />
      2.2
      为了获得数字藏品，您可能需要支付一定费用，具体收费标准以页面实际展示为准。您应该通过绿地G优指定的现有方式包括但不限于苹果支付、微信支付以及各网银支付等或今后绿地G优指定的方式支付费用。支付完成后，除法定情形外，不可进行转让或要求退费。
      <br />
      2.3
      您成功购买数字藏品后，绿地G优将会提供数字藏品展示的服务，供您进行学习、研究、欣赏、收藏以及其它绿地G优明确允许的个人非商业性质使用权。除上述使用目的外，您不得用于任何其他目的。
      <br />
      2.4您知晓并同意，鉴于网络服务的特殊性，如出现技术升级、服务体系升级、经营策略调整或配合国家重大技术、法律法规、政策等变化，绿地G优可能会根据需要更新或调整本软件和/或本服务的内容，甚至中止、终止向您提供全部或部分服务。上述行为不视为绿地G优违约，您无权要求绿地G优承担任何责任。
      <br />
      <br />
      三、【关于本软件】
      <br />
      3.1
      本软件是指绿地G优开发并按照本协议之约定，授权用户下载、安装、登录、使用的数字藏品软件。您可以通过手机终端以APP的形式使用本服务，具体以绿地G优提供的为准。同时，绿地G优会不断丰富您使用本服务的终端、形式等。当您使用本服务时，您应选择与您的终端、系统等相匹配的本软件版本，否则，您可能无法正常使用本服务。
      <br />
      3.2
      绿地G优给予您一项个人的、不可转让及非排他性的许可，以按照本协议及绿地G优其他协议、规则限定的范围和方式使用本软件及相关服务。您且仅限于您个人，可为非商业目的使用。
      <br />
      3.2.1 您可以为非商业目的在单一台终端设备上下载、安装、使用、登录本软件。
      <br />
      3.2.2本协议未明示授权的其他一切权利仍由绿地G优保留，您在行使这些权利时须另外取得绿地G优的书面许可。绿地G优如果未行使前述任何权利，并不构成对该权利的放弃。
      <br />
      3.3 您可以直接从本软件的官方网站、官方应用分发平台以及绿地G优授权的第三方网站、应用分发平台等绿地G优官方或绿地G优授权的渠道获取本软件。
      <br />
      3.4 如果您从未经绿地G优授权的第三方获取本软件或与本软件名称相同的安装程序，绿地G优无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
      <br />
      3.5 绿地G优可能为不同的终端、系统等开发不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。
      <br />
      3.6
      下载安装程序后，您需要按照该程序提示的步骤正确安装。为提供更加优质、安全的服务，在本软件安装时，绿地G优可能推荐您安装其他软件，您可以选择安装或不安装。
      <br />
      3.7 为了增进用户体验、完善服务内容，绿地G优可能不断努力开发新的服务，并为您不时提供软件更新。
      <br />
      3.8 为了改善用户体验或提高服务安全性、保证功能的一致性等目的，绿地G优有权对本软件进行更新，或者对本软件的部分功能效果、服务内容进行改变。
      <br />
      3.10
      本软件新版本发布后，旧版本的软件可能无法使用。绿地G优不保证旧版本软件继续可用，也不保证继续对旧版本软件提供相应的客服和维护服务，请您随时核对并下载最新版本。
      <br />
      <br />
      四、【服务帐号】
      <br />
      4.1
      您在使用本服务前需要使用手机号码注册绿地G优帐号（以下或称“帐号”），并自行设置登录密码。绿地G优有权变更本软件帐号申请、使用、找回的规则，具体以绿地G优公布为准。
      <br />
      4.2
      您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规（本协议中的“法律法规”指用户所属/所处地区、国家现行有效的法律、行政法规、司法解释、地方法规、地方规章、部门规章及其他规范性文件以及对于该等法律法规的不时修改和补充，以及相关政策规定等，下同）有特殊规定的，您需要填写真实、准确、合法、有效的身份信息（包括但不限于手机号码、身份证件等信息）。若您填写的信息不真实、不准确、不合法、不规范，我们有理由怀疑为错误、不实或不合法的资料，则我们有权拒绝为您提供相关服务或您可能无法使用本软件及相关服务或在使用过程中部分功能受到限制。如果您提供的注册资料不合法、不真实、不准确、不详尽的，您需承担因此引起的相应责任及后果，并且绿地G优保留终止您使用本服务的权利。
      <br />
      4.3
      用户有义务妥善保管其帐号及密码，并正确、安全地使用其帐号及密码，并对您以服务帐号名义所从事的活动承担全部法律责任，包括但不限于您在本软件进行的任何言论发表、购买、款项支付（如有）等操作行为。用户未尽上述义务导致帐号密码遗失、帐号被盗等情形而给用户和他人的民事权利造成损害的，应当自行承担由此产生的法律责任。
      <br />
      4.4
      服务帐号的所有权归绿地G优公司所有，用户完成申请、注册手续后，仅获得该帐号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖帐号或者以其他方式许可非初始申请注册人使用帐号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用帐号。
      <br />
      4.5
      您理解并承诺，您所设置的帐号不得违反国家法律法规及本软件使用的相关规则。您设置的帐号名称、头像等信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设帐号。您在帐号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益或有害社会道德风尚的行为。
      <br />
      <br />
      五、【用户个人信息保护】
      <br />
      5.1
      保护用户个人信息是绿地G优的一项基本原则，绿地G优将按照本协议及《绿地G优隐私政策》的约定收集、使用、储存和分享您的个人信息。本协议对个人信息保护相关内容未作明确规定的，均应以《绿地G优隐私政策》的内容为准。
      <br />
      5.2 绿地G优不会将您的个人信息转移或披露给任何第三方，除非：
      <br />
      （1）相关法律法规或司法机关、行政机关要求；
      <br />
      （2）为完成合并、分立、收购或资产转让而转移；
      <br />
      （3）为提供您要求的服务所必需。
      <br />
      （4）依据《绿地G优隐私政策》或其他相关协议规则可以转移或披露给任何第三方的情形。
      <br />
      5.3
      为了更好地向您提供本服务，在获得您的明确同意后，绿地G优可对您服务帐号中的昵称、头像以及在本软件中的相关操作信息（以下称“该等信息”）进行使用，并可在本软件中向您本人或其他用户展示该等信息。
      <br />
      5.4
      您在使用本服务某一特定功能时，绿地G优在获得您明确同意的前提下可以使用您终端设备的相关权限、接口及相关信息等以实现相应的功能。您可以选择不向绿地G优提供您的相关权限、接口、信息，或者根据产品设置取消绿地G优使用相关权限、接口或收集某些信息的权利，但因此可能会导致相关服务功能无法实现。
      <br />
      5.5
      您应对通过本软件和/或本服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。
      <br />
      <br />
      六、【用户行为规范】
      <br />
      6.1 【用户禁止行为】
      <br />
      您在使用本软件和/或本服务的过程中，应遵守相关法律法规、本协议、规则、规范等，不得从事包括但不限于以下任何行为，也不得为以下任何行为提供便利：
      <br />
      6.1.1 法律法规禁止的行为
      <br />
      您在使用本服务时须遵守法律法规，不得制作、复制、发布、传播含有下列内容的信息或从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利：
      <br />
      （1）反对宪法所确定的基本原则的；
      <br />
      （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      <br />
      （3）损害国家荣誉和利益的；
      <br />
      （4）煽动民族仇恨、民族歧视，破坏民族团结的；
      <br />
      （5）破坏国家宗教政策，宣扬邪教和封建迷信的；
      <br />
      （6）散布谣言，扰乱社会秩序，破坏社会稳定的；
      <br />
      （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      <br />
      （8）侮辱或者诽谤他人，侵害他人合法权益的；
      <br />
      （9）违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；
      <br />
      （10）法律法规、本协议或使用本软件的其他规则等禁止的其他行为。
      <br />
      6.1.2 软件使用
      <br />
      除非法律法规允许或绿地G优书面许可，您不得从事下列行为：
      <br />
      （1）删除本软件及其副本上关于著作权的信息；
      <br />
      （2）对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；
      <br />
      （3）对绿地G优拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
      <br />
      （4）对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非绿地G优经授权的第三方工具/服务接入本软件和相关系统；
      <br />
      （5）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
      <br />
      （6）通过非绿地G优开发、授权的第三方软件、插件、外挂、系统，登录或使用本软件和/或本服务，或制作、发布、传播上述工具；
      <br />
      （7）自行、授权他人或利用第三方软件对本软件和/或本服务及其组件、模块、数据等进行干扰；
      <br />
      （8）其他可能影响、干扰本软件和/或本服务正常运行的行为。
      <br />
      6.1.3 数据获取使用
      <br />
      为了保护数据安全，您不得从事包括但不限于以下行为，也不得为其提供便利：
      <br />
      （1）未经其他用户明确同意，或在未向其他用户如实披露数据用途、使用范围等相关信息的情形下收集、复制、存储、使用或传输其他用户数据，侵害其他用户合法权益；
      <br />
      （2）将其他用户的帐号、昵称等个人信息用于任何未经用户及绿地G优授权的用途；
      <br />
      （3）企图进行反射查找、跟踪、关联、挖掘、获取用户帐号、手机号和出生日期等个人信息；
      <br />
      （4）通过各种程序、软件等抓取任何用户的信息或与本软件和/或本服务相关的任何信息、数据；
      （5）未经绿地G优授权使用任何第三方软件、插件、外挂、系统等查看、获取本软件和/或本服务中所包含的绿地G优、绿地G优合作伙伴或用户的任何相关信息、数据等内容。
      <br />
      （6）其他危害数据安全的行为。
      <br />
      6.2 【法律责任】
      <br />
      6.2.1
      您理解并同意：若您违反法律法规、本协议和/或绿地G优其他协议、规则的，绿地G优有权随时单方根据相应情形采取以下一项或多项措施（具体措施以及采取措施的时间长短由绿地G优根据您的违法、违约情节相应情况确定）：
      <br />
      （1）对您进行警告；
      <br />
      （2）删除违法违规信息；
      <br />
      （3）限制您使用本软件和/或本服务部分或全部功能；
      <br />
      （4）中止、终止您对相应服务帐号的使用(简称“封号”) ；<br />
      （5）采取其他合理、合法的措施；
      <br />
      （6）依法移交有关行政管理机关给予行政处罚，或者依法向有关机关报案追究刑事责任。
      <br />
      6.2.2
      如果您违反法律法规、本协议和/或绿地G优其他协议、规则，绿地G优对您或您使用的本软件和/或本服务采取任何行为或措施后，导致您暂时或永久无法使用服务帐号，您应自行承担相应后果（包括但不限于通信中断、用户资料及相关数据的清空或无法使用等）。由此造成您损失的，您应自行承担。
      <br />
      6.2.3 您导致任何第三方损害的，您应当独立承担责任；绿地G优因此遭受损失的，您也应当一并赔偿。
      <br />
      6.3 【对自己行为负责】
      <br />
      您充分了解并同意，您必须为自己使用本软件和/或本服务的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对使用本软件和/或本服务时接触到的内容自行加以判断，包括因对内容的正确性、完整性或实用性的依赖而产生的风险，并承担由此引起的相应风险。绿地G优在法律允许的范围内不对您因前述风险而导致的任何损失或损害承担责任。
      <br />
      <br />
      七、【知识产权】
      <br />
      7.1
      绿地G优是绿地数字藏品的知识产权权利人。绿地数字藏品的著作权、商标权、专利权、商业秘密等知识产权，以及相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受法律法规和相应的国际条约保护，绿地G优依法享有上述相关知识产权，但相关权利人依照法律规定应享有的权利除外。
      <br />
      7.2
      您理解并同意，数字藏品的知识产权由发行方或其他权利人拥有。上述权利并不因您购买数字藏品的行为而发生任何转移或共享。除另行取得拥有数字藏品知识产权的权利人书面同意外，您不得将数字藏品用于任何商业用途。
      <br />
      未经绿地G优或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
      <br />
      7.3
      如您发现本软件及相关网站内，或数字藏品存在任何侵犯您权利的内容，请立即联系绿地G优客服，提供您有关权利的初步证据，绿地G优会根据法律法规要求及时处理您的投诉。
      <br />
      <br />
      八、【第三方软件或技术】
      <br />
      8.1 本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
      <br />
      8.2
      本软件如果使用了第三方的软件或技术，绿地G优将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包等形式进行展示，它们可能会以“软件使用许可协议”“授权协议”“开源代码许可证”或其他形式来表达。
      <br />
      8.3
      前述相关协议或其他文件均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求绿地G优给予协助，您应当自行承担法律责任。
      <br />
      <br />
      九、【不可抗力及其他免责事由】
      <br />
      9.1
      您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务受到影响。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，绿地G优将努力在第一时间与相关单位配合，争取及时进行处理，但是由此给您造成的损失，绿地G优在法律允许的范围内免责。
      <br />
      9.2 在法律允许的范围内，绿地G优对以下情形导致的服务中断或受阻不承担责任：
      <br />
      （1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏。
      <br />
      （2）用户或绿地G优的电脑软件、系统、硬件和通信线路出现故障。
      <br />
      （3）用户操作不当或用户通过非绿地G优授权的方式使用本服务。
      <br />
      （4）程序版本过时、设备的老化和/或其兼容性问题。
      <br />
      （5）其他绿地G优无法控制或合理预见的情形。
      <br />
      9.3 绿地G优将会尽其商业上的合理努力保障您在本服务中的数据存储安全，但是，绿地G优并不能就此提供完全保证，包括但不限于以下情形：
      <br />
      （1）绿地G优不对由于非绿地G优原因造成的您在本软件和/或本服务中相关数据的删除或储存失败负责。
      <br />
      （2）您应自行备份存储在本软件和/或本服务中的数据、信息或与本软件和/或本服务相关的数据、信息，双方另有约定的按相应约定执行。
      <br />
      （3）如果您停止使用本软件和/或本服务，或因您违反法律法规或本协议而被取消或终止使用本服务，绿地G优有权从服务器上永久地删除您的数据。您的服务停止、终止或取消后，绿地G优没有义务向您返还任何数据。
      <br />
      9.4
      您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，绿地G优不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
      <br />
      （1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息。
      <br />
      （2）遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失。
      <br />
      （3）其他因网络信息或用户行为引起的风险。
      <br />
      9.5
      您注意勿在使用本软件和/或本服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由您自行承担。您亦不应将其他个人信息通过本平台发表、上传或扩散。
      <br />
      9.6
      您理解并同意：为了向您提供有效的服务，本软件会利用您终端设备的处理器和带宽等资源。本软件使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。
      <br />
      9.7
      基于用户体验、本软件或相关服务的运营安全、规则要求及平台健康发展等综合因素，绿地G优有权选择提供服务的对象，决定功能设置，决定功能开放、数据接口和相关数据披露的对象和范围。针对以下情形，绿地G优有权视具体情况中止或终止提供相关服务，包括但不限于：
      <br />
      （1）违反法律法规或本协议的；
      <br />
      （2）影响服务体验的；
      <br />
      （3）存在安全隐患的；
      <br />
      （4）违背本软件或本服务运营原则、规范，或不符合绿地G优其他管理要求的。
      <br />
      <br />
      十、【其他】
      <br />
      10.1
      绿地G优有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。本协议条款变更后，如果您继续使用绿地G优提供的软件或服务，即视为您已接受变更后的协议。
      <br />
      10.2 本协议签订地为中华人民共和国上海市黄浦区。
      <br />
      10.3 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
      <br />
      10.4
      若您和绿地G优之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地（即上海市黄浦区）有管辖权的人民法院管辖。
      <br />
      10.5 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
      <br />
      10.6 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
      <br />
    </div>
  )
}

export default MyAgreement
