import React from "react";
import './index.scss';
import qs from 'query-string';
import a from '@/assets/img/collectionRecoveryLand/a.png';
import b from '@/assets/img/collectionRecoveryLand/b.png';
import c from '@/assets/img/collectionRecoveryLand/c.png';

const Data = [
  {
    name: 'A级藏品',
    img: a,
    points: 150,
    times: 10,
    count: 15,
    level: 'A',
  },
  {
    name: 'B级藏品',
    img: b,
    points: 40,
    times: 3,
    count: 20,
    level: 'B',
  },
  {
    name: 'C级藏品',
    img: c,
    points: 10,
    times: 1,
    count: 50,
    level: 'C',
  }
]

const RecyclingType = ({ onClose, type, activityCode, navigate }) => {
  
  const chooseGameType = (level) => {
    const params = qs.stringify({level, type, activityCode})
    const url = `/collectionRecoveryLand/recycling?${params}`;
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${url}`});
    } else {
      navigate(url);
    }
    onClose();
  }

  return <div className="landape_recycling_type">
    <div className="type_inner">
      <div className="close" onClick={onClose}></div>
      <div className="title">{type === 1 ? '多人平分对应奖池': '随机抽取指定人数瓜分对应奖池'}</div>
      <div className="choose_tip">选择回收藏品类型</div>
      <div className="type_box">
        {
          Data.map((item, index) => {
            const { name, img, points, times, count, level } = item;
            return <div key={index} onClick={() => chooseGameType(level)}>
              <img src={img} alt="" />
              <span className="ape_type">{name}</span>
              <div className="deuce_points">
                {type === 2 ? <span>随机{count}个藏品</span> : null}
                <span>可平分</span>
                <span>{points}w积分</span>
              </div>
              <div className="deuce_times">
                <span>消耗{times}次</span>
                <span>回收次数</span>
              </div>
            </div>
          })
        }
      </div>
    </div>
  </div>
}

export default RecyclingType;