/*
    共创漫画数字藏品- 发行详情页
    route: /apeWorld/cartoonSaleDetail
*/

import React, { useState, useEffect } from "react";
import {useLocation} from "react-router-dom";
import { Toast } from "antd-mobile"
import {showLoading, hideLoading} from '@/components/loading';
import {commonGoBack, getUrlAllParams} from "@/utils/publicFun";
import { getGoodsDetail} from "@/api/projectManager_self";
import NFTHeader from '@/components/NFTHeader';
import WordWhatCard from './components/WordWhatCard'
import DetailCard from './components/DetailCard'
import IntroduceCard from './components/IntroduceCard'
import MarkingCard from './components/MarkingCard'
import NoteBuyCard from './components/NoteBuyCard'
import BottomBuy from './components/BottomBuy'

import "./index.scss";

const VariationApeSaleDetail = (props)=> {
  const {search} = useLocation();
  const {collectionId=''} = getUrlAllParams(search.slice(1));
  const [detailData, setDetailData] = useState({});

  useEffect(()=>{
    const getData = async () => {
      try {
        showLoading()
        const res = await getGoodsDetail({collectionId: collectionId})
        hideLoading();
        if (res.code !== '000000') throw res.msg;
        if (res.data && res.data.info) {
          setDetailData(res.data.info)
        }
      } catch (error) {
        Toast.show(error);
      }
    }
    getData()
  },[])

  return (
    <div className="variation-ape-sale-detail">
      <NFTHeader leftImg={require('@/assets/img/icon/go_back.png')} backType={2}/>
      <div className="page-body">
        <div className="page-body--content">
          <div className="top-bg">
            <img src={require('@/assets/img/variationApe/img_bg_detail1.png')}/>
          </div>
          <div className="body-content">
            <WordWhatCard dataSouce={detailData}/>
            <DetailCard dataSouce={detailData}/>
            <IntroduceCard dataSouce={detailData}/>
            <MarkingCard dataSouce={detailData}/>
            <NoteBuyCard dataSouce={detailData}/>
          </div>
        </div>
        <div className="bottom">
          <BottomBuy dataSouce={detailData}/>
        </div>
      </div>
    </div>
  )
}

export default VariationApeSaleDetail;
