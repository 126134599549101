import mylandbtn from "@/assets/img/landfi/icon_land.png";
import mylandbtn2 from "@/assets/img/landfi/landgy/icon_land.png";
import teleportbtn from "@/assets/img/landfi/icon_door.png";
import classnames from "classnames";
import replaceLand from "@/assets/img/landfi/exchange-fill.png";
import landLease from "@/assets/img/landfi/land-lease-btn.png";
import spaceExtendImg from "@/assets/img/landfi/space-extend-btn.png";
import qs from "query-string";
import "./index.scss";

const Button = ({ img, text, onClick, className, ...props }) => {
  return (
    <div
      className={classnames("land-btn", "land-btn-floatbtn", className)}
      onClick={onClick}
      {...props}
    >
      <img className="land-btn-floatbtn__img" src={img} alt="" />
      <p className="land-btn-floatbtn__text">{text}</p>
    </div>
  );
};

export default Button;

export const MyLandBtn = (props) => {
  return (
    <Button
      className={classnames("land-btn-floatbtn__myland")}
      img={+props.mode === 2 ? mylandbtn2 : mylandbtn}
      text="我的土地"
      {...props}
    ></Button>
  );
};

export const LandModeBtn = (props) => {
  const { mode = 1,clickHandle=()=>{} } = props;
  return (
    <Button
      className={classnames("land-btn-floatbtn__landmode")}
      img={require("@/assets/img/landfi/landgy/landmode.png")}
      text={mode == 2 ? "农业用地" : "工业用地"}
      onClick={clickHandle}
      {...props}
    ></Button>
  );
};

export const LandLease = (props) => {
  return (
    <Button
      className="land-btn-floatbtn__landlease"
      img={landLease}
      text="土地租赁"
      {...props}
    ></Button>
  );
};

export const SpaceExtend = (props) => {
  return (
    <Button
      className="land-btn-floatbtn__spaceextend"
      img={spaceExtendImg}
      text="空间扩容"
      {...props}
    ></Button>
  );
};

export const TeleportBtn = (props) => {
  return (
    <Button
      className="land-btn-floatbtn__door"
      img={teleportbtn}
      text="传送门"
      {...props}
    ></Button>
  );
};

// 加速卡/ 道具
export const SpeedBtn = ({ onClick, className }) => {
  return (
    <div
      className={classnames("land-btn land-btn-speedbtn", className)}
      onClick={onClick}
    ></div>
  );
};

export const ScoreBtn = ({ onClick, storeCounts }) => {
  return (
    <div className="land-btn land-btn-scorebtn" onClick={onClick}>
      <div>{storeCounts || 0}</div>
    </div>
  );
};

export const ScoreXBtn = ({ onClick, gemCount, style = {} }) => {
  return (
    <div
      className="land-btn land-btn-scorexbtn"
      onClick={onClick}
      style={{ ...style }}
    >
      <div>{gemCount || 0}</div>
    </div>
  );
};

export const BaseBtn = ({ classNamme, active, ...props }) => {
  return (
    <div
      className={classnames("land-btn land-btn-basebtn", {
        active,
      })}
      {...props}
    ></div>
  );
};

export const CloseBtn = (props) => {
  return (
    <div
      className={classnames("land-btn land-btn-closebtn", {
        mode2: props.mode == 2,
      })}
      {...props}
    ></div>
  );
};

export const BackBtn = (props) => {
  return <div className="land-btn land-btn-backbtn" {...props}></div>;
};
export const BackBtnMode2 = (props) => {
  return <div className="land-btn land-btn-backbtn-mode2" {...props}></div>;
};

export const GreenBtn = ({
  classNamme,
  active,
  large,
  disabled = false,
  ...props
}) => {
  return (
    <div
      className={classnames("land-btn land-btn-greenbtn", {
        "land-btn-greenbtn__landup": large,
        "land-btn-greenbtn__disabled": disabled,
        "land-btn-greenbtn__large-disabled": disabled && large,
        active,
      })}
      {...props}
    ></div>
  );
};

export const OrangeBtn = ({
  classNamme,
  active,
  large,
  disabled = false,
  ...props
}) => {
  return (
    <div
      className={classnames("land-btn land-btn-orangebtn", {
        "land-btn-orangebtn__disabled": disabled,
      })}
      {...props}
    ></div>
  );
};

export const HomeBackBtn = (props) => {
  return (
    <div
      className={classnames("land-btn", {
        "land-btn-homeBack": !props.noAbs,
        "land-btn-backIcon": props.noAbs,
      })}
      {...props}
    ></div>
  );
};

export const SpaceNum = ({ spaceNum }) => {
  return <div className="land-text-spaceNum">{spaceNum || ""}</div>;
};

// 工业用地额度
export const LandGyCredit = ({ level, credit }) => {
  const levelMap = {
    A: require("@/assets/img/landfi/landgy/img_land_a.png"),
    B: require("@/assets/img/landfi/landgy/img_land_b.png"),
    C: require("@/assets/img/landfi/landgy/img_land_c1.png"),
  };
  return (
    <div className={"land-text-credit"}>
      <div className="land-text-credit-bg">
        <img src={levelMap[level]} className={classnames("img_icon", level)} />
        <div className="level">{level}</div>
        <span className={"text"}>{credit}</span>
      </div>
    </div>
  );
};

export const SpaceId = ({ spaceId, isCustom, spaceNiceNo }) => {
  if (spaceNiceNo) {
    return (
      <div
        className={`land-text-spaceBeautifulId ${
          isCustom ? "spaceBeautifulId-1" : "spaceBeautifulId-2"
        }`}
      >
        {spaceNiceNo || ""}
      </div>
    );
  } else {
    return <div className="land-text-spaceId">空间ID: {spaceId || ""}</div>;
  }
};

export const TaskBtn = (props) => {
  return <div className="land-btn land-btn-taskBtn" {...props}></div>;
};

export const ExchangeLandBtn = ({ isCanExchangeLand, ...props }) => {
  return (
    <div className="land-btn land-btn-exchangeLandBtn" {...props}>
      <img src={replaceLand} alt="" />
      <span>{isCanExchangeLand ? "取消更换" : "更换土地"}</span>
    </div>
  );
};

export const MyPackageBtn = (props) => {
  return (
    <Button
      className={classnames("land-btn-floatbtn__package")}
      img={require("@/assets/img/landfi/landgy/buy_prop_pack.png")}
      text="我的背包"
      {...props}
    ></Button>
  );
};

export const EnergyXBtn = ({ onClick, gemCount, style = {} }) => {
  return (
    <div
      className="land-btn land-btn-energyxbtn"
      onClick={onClick}
      style={{ ...style }}
    >
      <div>{gemCount || 0}</div>
    </div>
  );
};

// 道具
export const PropBtn = (props) => {
  return <div className="land-btn land-btn-propBtn" {...props}></div>;
};
