import React, {useEffect, useState, useRef} from "react"
import {
    getNavigationSerialCollectionList,
} from "@/api/projectManager_self";
import {Toast, Tabs} from 'antd-mobile';
import ListView from "@/components/AntListView";
import {showLoading, hideLoading} from '@/components/loading';
import NFTSearchHeader from "@/view/myCollectionSearch/component/searchHeader";
import {useNavigate, useLocation} from "react-router-dom";
import {getUrlAllParams, isEmptyString} from "@/utils/publicFun"
import CollectionComp from "../component/collectionComp";
import "./index.scss"
import { mockTab1 } from "../mock";


let timeStamp = ""
const MyCollectionSearchNavigation = () => {

    // 获取传递过来的参数
    const {search} = useLocation()
    const {code = '', subCode = 0} = getUrlAllParams(search?.slice(1));
    const {params = ''}  = getUrlAllParams(search.slice(1));
    const  {collectionIds='',parentCodeName='吞吞吐吐拖',childCodeName='三生三世',seriesId=""} = JSON.parse(decodeURIComponent(params))

    // 藏品列表
    const [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [total, setTotal] = useState(0);
    const currentPage = useRef(1);
    const searchCodeRef = useRef(code)
    const subCodeRef = useRef(subCode);

    const [isAll,setIsAll] = useState(true);

    // const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    const [tabs, setTabs] = useState([]);
    const [childCode, setChildCode] = useState('');
    const [isLoadUser, setIsLoadUser] = useState(false);
    const [maskVisible, setMaskVisible] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [isLoad, setIsLoad] = useState(false);
    const isLoadingFinished = useRef(false)

    useEffect(() => {
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
        window.JsBridge.print('userInfo'+JSON.stringify(userInfo))
        setUserInfo(userInfo);
        // getUserMsg()
        getDataList()
    }, []);

    useEffect(()=>{
        currentPage.current = 1
        getDataList()
    },[isAll])

    const getUserMsg = async () => {
        let userInfo0 = await window.JsBridge.getCurrentUserInfo();
        if (userInfo0 && userInfo0.clientId) { //登录了
            let userInfo1 = await window.JsBridge.getDetailCurrentUserInfo();
            setIsLoadUser(true)
            if (!isEmptyString(userInfo1.ID)) { //实名过了
                setMaskVisible(false);
                // getTabData();
            } else { //没有实名
                setMaskVisible(true);
            }
        } else { //没有登录 去登录
            window.JsBridge.goLogin().then();
            window.JsBridge.addListener().then((result) => {
                getUserMsg();
                // reloadUserInfo();
            });
        }
    }


    const getDataList = async () => {
        try {
            showLoading();
            timeStamp = Date.now().toString();
            const res = await getNavigationSerialCollectionList({current: currentPage.current, size: 10, collectionIds, seriesId ,clientId:isAll?"":userInfo.clientId});//clientId
            // const res = await mockTab1()
            hideLoading();
            isLoadingFinished.current = true
            const {code, msg, data} = res;
            if (code !== '000000') throw msg;
            // currentPage.current = 1;
            setList([...data.list]);
            setIsLoad(true);
            setTotal(data.total);
            setHasMore(data.total > data.list.length);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const loadMore = async () => {
        try {
            currentPage.current = currentPage.current + 1;
            const params = {
                current: currentPage.current,
                size: 10,
                collectionIds ,
                seriesId,
                clientId:isAll?"":userInfo.clientId
            }
            const timeM = Date.now().toString();
            timeStamp = timeM;
            const {code, msg, data} = await getNavigationSerialCollectionList(params);
            // const res = await mockTab1()
            if (timeStamp != timeM){
                return
            }
            if (code !== '000000') throw msg;
            setList([...list, ...data.list]);
            setIsLoad(true);
            setHasMore(data.total > [...list, ...data.list].length);
        } catch (error) {
            window.JsBridge.print(error);
        }
    }

    const renderListItem = (item, index) => {
        return <CollectionComp isAll={isAll} collection={item} />
    }

    const onSwitchOnClick = (isAll)=>{
        setIsAll(isAll)
    }

    const searchVal = useRef('')
    const valueChanged = (val)=>{
        searchVal.current = val
    }

    return (
        <div className="nft-search-page-navigation">
                <div className="top">
                    <NFTSearchHeader valueChanged={(val)=>valueChanged(val)} onSwitchOnClick={(isAll)=>onSwitchOnClick(isAll)} firstTitle={parentCodeName} secondTitle={childCodeName} currentTitle1={1} totalCount={total} onSearchClick={()=>{
                        if (!isEmptyString(searchVal.current)){
                            window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/myCollectionSearchResult?searchText=${encodeURIComponent(searchVal.current)}`});
                        }
                    }}/>
                </div>
                {
                    list.length>0 && <div className='list'>
                        <ListView
                        dataSource={list}
                        renderItem={renderListItem}
                        hasMore={hasMore}
                        onEndReached={loadMore}
                        isLoad={isLoad}
                        style={{paddingTop: 30}}
                        />
                    </div>
                }
            {
              isLoadingFinished.current && list.length==0 && <div className="no-data-container">
                  <div className="no-data"></div>
              </div>
            }

        </div>
    )
}

export default MyCollectionSearchNavigation
