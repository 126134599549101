/**
 * 升级漫画藏品弹框
*/

import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import {Toast} from 'antd-mobile'
import ModalAlert from "@/components/ModalAlert";

import "./index.scss";

const UpgradeModal = (props)=> {

    const {visible, onClose, title='升级藏品', onAction, dataSource={}} = props
    const baseCount = 6
    const maxCount = 36

    const [count,setCount] = useState(1)

    useEffect(()=>{
        if(!visible) {
            setTimeout(()=>{
                setCount(1)
            },500)
        }
    },[visible])

    const onReduce = ()=> {
        if(count > 1) {
            setCount(count - 1)
        }
    }

    const onAdd = () => {
        if((count + 1) * baseCount <= maxCount){
            if((count + 1) * baseCount <= dataSource.total) {
                setCount(count + 1)
            }else {
                Toast.show(`您当前仅持有${dataSource.total}张`);
            }
        }else {
             Toast.show(`每次最多可升级${maxCount}张`);
            // window.JsBridge.runAction('alert', 'error', ['感谢您的参与,已收到投票!']);
        }
    }

    const content = ()=> {

        return (
            <div className="upgradeModal-content">
                <span className="subtitle">{`当前持有${dataSource.level}级${dataSource.total}张`}</span>
                <div className="action-box">
                    <img src={require('@/assets/img/icon_reduce_6.png')} alt="" onClick={onReduce}/>
                    <span>{`${baseCount * count}张`}</span>
                    <img src={require('@/assets/img/icon_add_6.png')} alt="" onClick={onAdd}/>
                </div>
                <div className="desc-info">
                    <div>{`可得${dataSource.nextLevel}级共创藏品`}<span>{count}</span>{'张'}</div>
                    <div>{'将随机'}<span>{`销毁${baseCount * count}张`}</span>{'当前等级藏品'}</div>
                </div>
            </div>
        )
    }

    return (<ModalAlert
            visible={visible}
            title={<div style={{fontSize: 16, color: '#333'}}>{title}</div>}
            content={content()}
            closeOnAction
            onClose={onClose}
            onAction={(action) => {
                if(action.key == 'confirm'){
                    onAction && onAction(baseCount * count)
                }
            }}
            actions={[
                {
                    key: 'cancal',
                    text: '取消',
                    style: {
                        color: '#999',
                        fontSize: 15
                    }
                },
                {
                    key: 'confirm',
                    text: '确认升级',
                    style: {
                        color:'#CAA846'
                    }
                },
            ]}
        />)
}

export default UpgradeModal;