import React, {useEffect, useState, useRef} from 'react';
import styles from './styles.module.scss'
import clickThrottle from "@/utils/clickThrottle";
import {Toast} from "antd-mobile";
import {useNavigate,} from "react-router-dom"
import CountDown from "@/view/partsRecyclingUnity/components/countDown";
import RecycleCol from "@/view/partsRecyclingUnity/components/recycleCol";
import ScratchCardGift from "@/view/partsRecyclingUnity/components/scratchCardGift";
import RecycleEpicApeOrBet from "@/view/partsRecyclingUnity/components/recycleEpicApeOrBet";
import ActivityRule from '../components/activityRule';
import RecycleRecord from "@/view/partsRecyclingUnity/components/recycleRecord";
import {compareTime, isEmptyObject, notifyYYZToCloseWeb} from "@/utils/publicFun";
import {hideLoading, showLoading} from "@/components/loading";
import {recoveryActivityInit} from "@/api/projectManager_self";
import RecycleSuccessModal from "@/view/partsRecyclingUnity/components/recycleSuccessModal";

export const activityCode = 'metaRecovery1027';
//配件回收元宇宙
const PartsRecyclingHomeUnity = () => {
    const [type, setType] = useState(0);//0=回收新年限定藏品 1=刮奖拿豪礼 2=活动规则 3=回收得史诗款 4=押注
    const [maskVisible, setMaskVisible] = useState(false);
    const [data, setData] = useState();
    const [initalIndex, setInitalIndex] = useState(0)
    useEffect(() => {
        pageInit();
    }, [type]);

    // 活动初始信息
    const pageInit = async () => {
        try {
            showLoading();
            const res = await recoveryActivityInit({activityCode: activityCode});
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            setData(res.data || {});
            const currentTime = new Date(res.data?.systemTime?.replace(/-/g, "/")).getTime();
            const drawTime = new Date(res.data?.drawTime?.replace(/-/g, "/")).getTime();
            const drawEndTime = new Date(res.data?.drawEndTime?.replace(/-/g, "/")).getTime();
            if ((currentTime >= drawTime) && currentTime <= drawEndTime) {
                setInitalIndex(1);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const clickClose = () => {
        notifyYYZToCloseWeb('');
    }

    const back = () => {//返回
        setType(0);
    }

    const switchItem = (id) => {
        console.log('切换栏目---', id);
        setType(id);
    }

    const clickRecord = () => {//回收记录
        setMaskVisible(!maskVisible);
    }

    const getContentView = () => {
        if (type === 0) {
            // return  <RecycleCol switchItem={(id) => switchItem(id)} data={data}/>
            return !isEmptyObject(data) ? <RecycleCol switchItem={(id) => switchItem(id)} data={data}/> : null;
        } else if (type === 1) {
            return <ScratchCardGift/>;
        } else if (type === 2) {
            return <ActivityRule/>;
        } else if (type === 3) {
            return <RecycleEpicApeOrBet type={0}/>
        } else if (type === 4) {
            return <RecycleEpicApeOrBet type={1}/>
        }
    }

    const getCountDownView = () => {
        // return <CountDown sysTime={'2023-10-10 12:12:12'} endTime={'2023-10-18 17:12:12'} refreshData={() => {
        //     pageInit();
        // }}/>
        let time = compareTime(data?.systemTime, data?.drawTime);
        if (time === -1) {
            return <CountDown sysTime={data?.systemTime} endTime={data?.drawTime} refreshData={() => {
                pageInit();
            }}/>
        }
        return <p/>;
    }


    return <div className={styles.parts_recycling_home_unity} id={'part_container_id'}>
        <ItemSwitch switchItem={(id) => switchItem(id)} initalIndex={initalIndex}/>
        <div className={styles.img_container}>
            <img src={require('../imgs/bg.png')}/>
            <div className={styles.content}>
                {getContentView()}
            </div>
            <div className={styles.time_record_content}>
                {getCountDownView()}
                <p className={styles.record}
                   onClick={() => clickRecord()}>{`回收记录>`}</p>
            </div>
            <img src={require('../imgs/icon_delete.png')} className={styles.close}
                 onClick={() => clickClose()}/>
        </div>
        {(type === 3 || type === 4) ? <img src={require('../imgs/btn_return.png')} className={styles.back}
                                           onClick={() => back()}/> : null}
        <RecycleRecord maskVisible={maskVisible} setMaskVisible={setMaskVisible}/>
    </div>
}

const ItemSwitch = (props) => {
    const [data, setData] = useState([
        {title: '回收纪念款藏品', isChoice: true, id: 0},
        {title: '刮奖拿豪礼', isChoice: false, id: 1},
        {title: '活动规则', isChoice: false, id: 2},
    ]);

    useEffect(() => {
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if (i === props.initalIndex) {
                item.isChoice = true;
            } else {
                item.isChoice = false;
            }
        }
        setData([...data]);
        setTimeout(() => {
            props.switchItem && props.switchItem(props.initalIndex);
        }, 500)
    }, [props.initalIndex])


    const itemClick = (item) => {
        if (item.isChoice || !clickThrottle(2000)) {
            return;
        }
        for (const item0 of data) {
            if (item0.id === item.id) {
                item0.isChoice = true;
            } else {
                item0.isChoice = false;
            }
        }
        setData([...data]);
        setTimeout(() => {
            props.switchItem && props.switchItem(item.id);
        }, 500)
    }

    const items = () => {
        return data.map((item, index) => {
            return <div key={index.toString()} className={`${styles.item} ${item.isChoice ? styles.isChoiceItem : ''}`}
                        onClick={() => itemClick(item)}>
                <p className={`${item.isChoice ? styles.isChoice : ''}`}>{item.title}</p>
            </div>
        })
    }

    return (
        <div className={styles.switch_container}>
            {items()}
        </div>
    )
}


export default PartsRecyclingHomeUnity;
