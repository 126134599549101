import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import ListView from "@/components/AntListView";
import {hideLoading, showLoading} from "@/components/loading";
import {getQuizCouponList,} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";
import {useNavigate} from "react-router-dom";


const HuiShouCollection = (props) => {
    const navigate = useNavigate();
    const [dataList, setDataList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const currentPage = useRef(1);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            showLoading()
            const res = await getQuizCouponList({currentPage: currentPage.current, pageSize: 10, type: 1})
            hideLoading();
            setIsLoad(true);
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let dataTem = res.data;
                let record = dataTem.record || [];
                if (currentPage.current > 1) {
                    setDataList([...dataList, ...record]);
                } else {
                    setDataList(record);
                }
                setHasMore(record.length >= 10);
            }
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const loadMore = async () => {
        currentPage.current = currentPage.current + 1
        await loadData()
    }

    const renderItem = (item, index) => (
        <div key={index.toString()} className={styles.item}>
            <div className={styles.rowSup}>
                <div>回收时间</div>
                <div className={styles.strong}>{item.recoveryTime}</div>
            </div>
            {
                item.recoveryList?.map(v => (
                    (v.name && v.nftNo)? <div className={styles.row}>
                        <div className={styles.strong}>{v.name} #{v.nftNo}</div>
                    </div>:<div className={styles.row}>
                        <div className={styles.strong}>{v.name} {v.nftNo}</div>
                    </div>
                ))
            }
            <div className={styles.rowSub}>
                <div></div>
                <div className={styles.red}>{item.prizeName}</div>
            </div>
        </div>
    )


    return (
        <div className={styles.epic_boring_ape_container}>
            <ListView
                dataSource={dataList}
                renderItem={renderItem}
                onRefresh={() => {
                    currentPage.current = 1;
                    loadData()
                }}
                hasMore={hasMore}
                onEndReached={loadMore}
                style={{paddingTop: 50}}
                isLoad={isLoad}
            />
        </div>
    )
}


export default HuiShouCollection;
