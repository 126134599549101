import React, {useState} from "react";
import './duiHuan.scss';

const DuiHuanModal = (props) => {
  const { visible, title, content, leftBtnText="取消", leftBtnCB, rightBtnText="确定", rightBtnCB, onClose, errorShow, name } = props;
  const [surname, setSurname] = useState('');

  const leftBtnClick = () => {
    leftBtnCB && leftBtnCB();
    setSurname('');
    onClose();
  }

  const rightBtnClick = () => {
    rightBtnCB && rightBtnCB(surname);
  }

  if(!visible) return null;
  return (
    <div className="modal">
      <div className="modal-container">
        <div className="title">{title}</div>
        <div className="btn-box">
          <div className="left-btn" onClick={leftBtnClick}>{leftBtnText}</div>
          <div className={'line'}/>
          <div className={`right-btn ${surname ? 'right-btn-activity': ''}`} onClick={rightBtnClick}>{rightBtnText}</div>
        </div>
      </div>
    </div>
  );
};
export default DuiHuanModal;
