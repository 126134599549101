import React, { useState, useCallback, useRef, useEffect } from "react";
import styles from "./style.module.scss";
import { Mask, Toast } from "antd-mobile";
import close from "@/assets/img/landfi/close-circle-fill.png";
import ListView from "@/components/AntListView";
import dayjs from "dayjs";
import appService from "@/utils/appService";
import MyModal from "../modal";
import OrderConfirm from "./orderConfirm";

const ExchangeRecords = ({ visible, onClose, container }) => {
  const [recordsData, setRecordsData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(20);

  useEffect(() => {
    if (visible) {
      getRecordsList();
    }
  }, [visible]);

  // 查看订单详情
  const openOrder = (item) => {
    const ModalInstance = MyModal.show({
      content: (
        <OrderConfirm
          onClose={() => ModalInstance?.close()}
          recordInfo={item}
          container={container}
        />
      ),
      getContainer: container,
    });
  };
  const getRecordsList = async (isRefresh = true, loading = true) => {
    if (isRefresh) {
      currentPage.current = 1;
    } else {
      currentPage.current = currentPage.current + 1;
    }
    const params = {
      pageNum: currentPage.current,
      pageSize: pageSize.current,
      activityCode: "indLand",
    };
    const res = await appService("300021", params, loading);
    console.log("🚀 ~ getRecordsList ~ res:", res);
    if (res?.status > 0) {
      const { list = [] } = res?.data || {};
      if (currentPage.current === 1) {
        setRecordsData(list);
      } else {
        setRecordsData([...recordsData, ...list]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(list.length >= pageSize.current);
    }
  };

  const RecordsItem = (item) => {
    const { prizeName, createTime, prizeType,prizeImgUrl } = item || {};
    return (
      <div className={styles.records_item}>
        <div className={styles.item_left}>
        <img src={prizeImgUrl} alt="" />
        </div>
        <div className={styles.item_middle}>
          <div className={styles.item_title}>{prizeName}</div>
          <div className={styles.item_time}>
            兑换时间 <span>{createTime}</span>
          </div>
        </div>
        {prizeType === 3 && <div
          className={styles.item_right}
          onClick={() => {
            openOrder(item);
          }}
        >
          查看详情
        </div>}
      </div>
    );
  };

  return (
    <Mask
      visible={visible}
      destroyOnClose
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, .8)",
      }}
    >
      <div className={styles.exchange_records_container}>
        <img className={styles.close} src={close} onClick={onClose} alt="" />
        <div className={styles.records_title}>兑换记录</div>
        <div className={styles.records_list}>
          <ListView
            dataSource={recordsData}
            hasMore={hasMore}
            style={{ paddingTop: 5 }}
            renderItem={RecordsItem}
            onEndReached={async () => {
              await getRecordsList(false, false);
            }}
            isLoad={isLoad}
          />
        </div>
      </div>
    </Mask>
  );
};

export const useExchangeRecords = ({ container }) => {
  const [visible, setVisible] = useState(false);

  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  const onOpen = useCallback(() => {
    setVisible(true);
  }, []);

  const exchangeRecordView = (
    <ExchangeRecords
      visible={visible}
      onClose={onClose}
      container={container}
    />
  );

  return [onOpen, exchangeRecordView];
};
