/**
 * 我的藏品- 共创漫画 item
*/

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./index.scss";

const CartoonItem = (props) => {
    const navigate = useNavigate();

    const {
        item = {
            "imageBg": require('@/assets/img/mh/pic_b.png'),
            "collectionName": "共创漫画D级产品",
            "collectionId": 'all',
            "comicsId": 20,
            "num": 10,
            "level": "D",
            "nextLevel": "C",
            "isUpgrade": 0,
            "seriesId": '04'//"05"
           }         
    } = props

    const onCartoonCollection = ()=> {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.addListener().then(res=> {
                // window.JsBridge.print(res)
                props.onRefresh && props.onRefresh()
            })
            window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/cartoonCollection?data=${encodeURIComponent(JSON.stringify(item))}`});
        } else {
            navigate(`/cartoonCollection?data=${encodeURIComponent(JSON.stringify(item))}`, {})
        }
    }

    return (
        <div className="cartoon-item" onClick={onCartoonCollection}>
            <div className="left-img">
                <img src={item.imageBg} alt="" />
            </div>
            <div className="right-layout">
                <div className="right-layout--top">
                    <span>{item.collectionName}</span>
                    <div>{item.level}级</div>
                </div>
                <div className="right-layout--bottom">
                    <span>已持有{item.num}张</span>
                    {
                        item.level != 'SS' &&
                        <div className={item.isUpgrade == 0 ? 'enable': undefined}>可升级</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CartoonItem;