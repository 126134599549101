import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { Mask } from 'antd-mobile';

const TipModal = ({onClose, onRealName}) => {
  const [visible, setVisible] = useState(true);

  const handleClick = () => {
    setVisible(false);
    onClose && onClose();
  }

  const realNameAuthen = () => {
    onRealName && onRealName();
    handleClick();
  }

  return <Mask className='tip-modal-mask' visible={visible} onMaskClick={() => handleClick()}>
    <div className="filter-box"></div>
    <div className="content-box">
      <div className="title">友情提示</div>
      <div className="tip-content">
        您还未实名认证，无法完整使用数字藏品相关功能。
      </div>
      <div className="btn-box">
        <div className="cancel-btn" onClick={() => handleClick()}>取消</div>
        <div className="confirm-btn" onClick={realNameAuthen}>去实名</div>
      </div>
    </div>
  </Mask>
}

TipModal.open = (onRealName) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<TipModal onClose={close} onRealName={onRealName}/>, div);
}

export default TipModal;