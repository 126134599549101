import React, {useEffect, useState, useRef, createContext, useContext} from "react"
import {useNavigate} from 'react-router-dom'
import guess_bg from "../imgs/guess_bg.png";
import "./index.scss"
import {isEmptyArray, isEmptyString, Platform} from "@/utils/publicFun";
import SessionCpt from "@/view/guessingActy/components/sessionCpt";
import ShareModal from "../components/shareModal";
import RulesModal from "../components/ruleModal";
import InviteRecordModal from '../components/inviteRecordModal';
import {hideLoading, showLoading} from "@/components/loading";
import {getGoodslist, getGuessingQuestions, getWealth, secondaryVariantApeInit} from "@/api/projectManager_self";
import {loadWxJSSDK} from '@/utils/wxJsBridge'
import {Popup, Toast} from "antd-mobile";
import img from "@/view/guessingActy/imgs/img_bg_fx.png";
import clickThrottle from "@/utils/clickThrottle";
import JsBridgeNew from "@/utils/jsbridgeNew";
import SessionNotStartCpt from "@/view/guessingActy/components/sessionNotStartCpt";
import RankingRecord from "@/view/guessingActy/components/rankingRecord";
import ScannerModal from "@/view/guessingActy/components/scannerModal";

const activityCode = 'secondaryVariantApe';
const isMiniprogram = Platform.isMiniprogram();
const Context = createContext(null);
const GuessingActyHome = () => {
    const [initData, setInitData] = useState({state: 0});
    const isShimingRef = useRef(false);
    useEffect(() => {
        if (!isMiniprogram) {
            getUserInfo();
        } else {
            homeInit();
        }
        backHandler();
    }, []);

    const homeInit = async () => {
        try {
            showLoading()
            const res = await secondaryVariantApeInit({})
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            let data = res.data || {};
            // data.state = -1;
            data.isShiming = isShimingRef.current;
            setInitData(res.data || {})
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const backHandler = async () => {
        await JsBridgeNew.backHandler();
        backHandler();
    }

    const getUserInfo = async () => {
        let userInfo0 = await window.JsBridge.getCurrentUserInfo();
        if (userInfo0 && userInfo0.clientId) { //登录了
            let userInfo1 = await window.JsBridge.getDetailCurrentUserInfo();
            if (!isEmptyString(userInfo1.ID)) { //实名过了
                isShimingRef.current = true;
            } else { //没有实名
                isShimingRef.current = false;
            }
            homeInit();
        } else { //没有登录 去登录
            window.JsBridge.goLogin().then();
            window.JsBridge.addListener().then((result) => {
                getUserInfo();
            });
        }
    }

    const yaoxinClick = (type) => { //0=顶部 1=中部
        if (!clickThrottle()) {
            return
        }
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
        let inviteCode = userInfo.inviteCode;
        if (!isMiniprogram) {
            console.log('inviteCode---', inviteCode)
            const param = {
                ic: inviteCode,
                ac: activityCode,
            }
            const inviteUrl = `${process.env.REACT_APP_GKT_LINK}/front/inviter/registers/${btoa(JSON.stringify(param))}`;

            // const inviteUrl = `${process.env.REACT_APP_GKT_LINK}/front/inviter/registers/${Buffer.from(JSON.stringify(param)).toString("base64")}`;
            const img = require('../imgs/img_bg_fx.png')
            ShareModal.open({shareUrl: inviteUrl, posterImg: img, inviteCode: inviteCode});
        } else { //小程序里面的分享
            loadWxJSSDK().then(wx => {
                wx.miniProgram.navigateTo({
                    url: `/pages/activitys/guessingActy/share?inviteCode=${inviteCode}`
                })
            })
        }
    }

    return (
        <Context.Provider value={{initData}}>
            <div className="guessing_acty_container">
                {/*<div style={{flex:1,overflow:'scroll'}}>*/}
                <GuessingActyHeader yaoxinClick={yaoxinClick} reloadData={() => getUserInfo()}/>
                <GuessingActyContent yaoxinClick={yaoxinClick} reloadData={() => getUserInfo()}/>
                <GuessingActyBottom/>
                {/*</div>*/}

            </div>
        </Context.Provider>
    )
}

const GuessingActyHeader = (props) => {
    const {initData} = useContext(Context);
    const [rankingRecordVisible, setRankingRecordVisible] = useState(false);
    const [scannerVisible, setScannerVisible] = useState(false)

    const btnClick = (type) => {
        if (type === 0) {
            if (Platform.isApp()) {
                window.JsBridge.back();
            } else {
                window.history.go(-1);
            }
        } else if (type === 1) {//邀新
            if (initData.state === 0) {
                props.yaoxinClick && props.yaoxinClick(0);
            }
        } else if (type === 2) { //规则
            RulesModal.open();
        } else if (type === 3) { //实名
            window.JsBridge.navigate('Verify')
            window.JsBridge.addListener().then(() => {
                props.reloadData && props.reloadData();
            })
        } else if (type === 4) {
            document.getElementById('buy_container_id')?.scrollIntoView()
            window.scrollTo({
                top: window.scrollY,
                behavior: 'smooth'
            })
        } else if (type === 5) {
            document.getElementById('buy_container_time')?.scrollIntoView()
            window.scrollTo({
                top: window.scrollY,
                behavior: 'smooth'
            })
        } else if (type === 6) {//排行榜
            setRankingRecordVisible(!rankingRecordVisible);
        }else if (type === 7) {//扫码进群
            setScannerVisible(!scannerVisible);
        }
    }

    return (
        <>
            <img src={guess_bg} className={'guessing_acty_img_bg'}/>
            {
                isMiniprogram ? null : <div className={'guessing_acty_back_share_father'}>
                    <div className={'back_father'}>
                        <div className={'back_btn'} onClick={() => btnClick(0)}/>
                    </div>
                    <div className={'share_father'}>
                        {initData.state === 0 ? <div className={'share'} onClick={() => btnClick(1)}/> : <div/>}

                    </div>
                </div>
            }
            <div className={'rule_shiming_father'}>
                <p onClick={() => btnClick(2)}>{`规\n则`}</p>
                {
                    isMiniprogram ? null : (initData.isShiming ? null :
                        <p onClick={() => btnClick(3)} className={'martop'}>{`实\n名`}</p>)
                }
                <p onClick={() => btnClick(6)} className={'martop paihangbang'}>{`排\n行\n榜`}</p>
            </div>

            <div className={'rule_scanner_father'}>
                <p onClick={() => btnClick(7)}>{`扫\n码\n进\n群`}</p>
            </div>

            <div className={'guessing_buycoll_container'}>
                <img src={require('../imgs/tab_jc_nor.png')} alt="" onClick={() => btnClick(5)}/>
                <img src={require('../imgs/pic_btn_logo.png')} alt=""/>
                <img src={require('../imgs/btn_buy.png')} alt="" onClick={() => btnClick(4)}/>
            </div>

            <Popup
                visible={rankingRecordVisible}
                destroyOnClose={true}
                onMaskClick={() => {setRankingRecordVisible(false);}}
                position='right'
                bodyStyle={{width: '100vw'}}
            >
                <div
                    style={{height: '100vh', overflow: 'hidden'}}>
                    <RankingRecord leftClick={() => setRankingRecordVisible(false)}
                                 prizeRecordVisible={rankingRecordVisible}/>
                </div>
            </Popup>

            <Popup
                visible={scannerVisible}
                destroyOnClose={true}
                onMaskClick={() => {setScannerVisible(false);}}
                position='right'
                bodyStyle={{width: '100vw'}}
            >
                <div
                    style={{height: '100vh', overflow: 'hidden'}}>
                    <ScannerModal leftClick={() => setScannerVisible(false)}
                                  scannerVisible={scannerVisible}/>
                </div>
            </Popup>

        </>
    )
}

const GuessingActyContent = (props) => {
    const navigate = useNavigate();
    const {initData} = useContext(Context);
    const [isStartActy, setIsStartActy] = useState(false);//活动是否开始
    const [isLoad, setIsLoad] = useState(false);//是否加载了

    useEffect(() => {
        // currentTime startTime
        let startTime = initData.startTime;
        let currentTime = initData.currentTime;
        if (startTime && currentTime) {
            const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))
            const startTimeStamp = Date.parse(startTime.replace(/-/g, '/'));
            setIsLoad(true)
            if (currentTimeStamp > startTimeStamp) { //已开始/已结束
                setIsStartActy(true);
            } else { //未开始
                setIsStartActy(false);
            }
        }
    }, [initData]);

    const yaoxinClick = () => { //邀新
        if (initData.state === 0) {
            props.yaoxinClick && props.yaoxinClick(1)
        }
    }

    const reloadData = () => {
        props.reloadData && props.reloadData()
    }

    const huishouOrBuyJuan = async (type) => { //获取场次
        try {
            showLoading()
            const res = await getGuessingQuestions();
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data && res.data.guessingQuestionList && !isEmptyArray(res.data.guessingQuestionList)) {
                let guessInfo = res.data.guessingQuestionList[0];
                let session = guessInfo.session;
                if (type === 0) {
                    navigate(`/guessingActy/getCoupon?session=${session}`)
                } else if (type === 1) {
                    navigate(`/guessingActy/getCoupon?currentIndex=1&session=${session}`)
                }
            }
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    return (
        <div className={'guessing_middle_container'}>
            <div className={'guessing_middle_top_container'}>
                <img src={require('../imgs/panel_yxfl.png')} alt="" className={'guessing_middle_img'}/>
                <div className={'yx_des_container'}>
                    <span className={'yx_btn_record'}
                          onClick={() => InviteRecordModal.open(activityCode)}>邀新记录&gt;</span>
                    <div className={'yx_des'}>
                        <span>邀请新用户并购买入场券,即送<span>{initData.point || 0}</span>积分</span>
                    </div>
                    <div className={'yx_des yx_des1'}>
                        <span className={'span1'}>(被邀请人购买入场券邀请人+300积分,下载进入app+200积分)</span>
                    </div>
                    <div className={'yx_des yx_des1'}>
                        <span>积分可兑换积分商城商品</span>
                    </div>

                    <div className={'yx_btn'} onClick={() => yaoxinClick()}>
                        <img
                            src={initData.state === 0 ? require('../imgs/yaoxin.gif') : require('../imgs/btn_yx_dis.png')}
                            className={`${initData.state === 0 ? '' : 'img_dis'}`}/>
                    </div>
                    <div className={'fuli_container'}>
                        <div className={'fuli_container_0'}>
                            <div className={'fuli_container_left'}>
                                <span className={'txt'}>新用户福利</span>
                                <span className={'txt_bg'}/>
                            </div>
                            <span className={'des'}>首次购买入场券，买一送一，首次淘汰可得史诗款无聊猿，在“兑换商城”领取</span>
                        </div>
                        <div className={'fuli_container_0'} style={{marginTop: '5px'}}>
                            <div className={'fuli_container_left'}>
                                <span className={'txt'}>老用户福利</span>
                                <span className={'txt_bg'}/>
                            </div>
                            <span className={'des'}>购买入场券，买五送一，不限次数</span>
                        </div>
                    </div>
                </div>
            </div>
            <img src={require('../imgs/img_time.png')} alt="" className={'start_time'} id={'buy_container_time'}/>
            {isStartActy ? <SessionCpt/> : (isLoad ?
                <SessionNotStartCpt initData={initData} reloadData={() => reloadData()}/> : null)}
            <div className={'lianji_container'}>
                <img src={require('../imgs/pic_lj.png')} alt=""/>
                <img src={require('../imgs/pic_lj.png')} alt=""/>
            </div>
            <div className={'guessing_middle_middle_container'}>
                <div className={'guess_middle_top'}>
                    <img src={require('../imgs/pic_jp.png')} alt=""/>
                    <p onClick={() => navigate('/guessingActy/rule')}>查看攻略 &gt;</p>
                </div>
                <div className={'award_list'}>
                    <img src={require('../imgs/pic_jp3.png')} alt=""/>
                </div>

                <img src={require('../imgs/img_title_rcq.png')} alt=""
                     className={'pic_fs'}/>

                <div className={'guess_get_container'}>
                    <div className={'item'}>
                        <img src={require('../imgs/img_hs.png')} alt="" className={'item_bg'}/>
                        <div className={'item_title'}>
                            <span>回收</span>
                            <span>1款史诗款</span>
                            <span>无聊猿可</span>
                            <span>换取1张入场券</span>
                        </div>
                        <img
                            src={initData.state === 0 ? require('../imgs/btn_hs.png') : require('../imgs/btn_hs_dis.png')}
                            alt="" className={'btn'}
                            onClick={() => initData.state === 0 ? huishouOrBuyJuan(0) : null}/>
                        <p className={'item_des'}>拥有史诗款{initData.count || 0}个</p>
                    </div>
                    <div className={'item'}>
                        <img src={require('../imgs/img_rcq.png')} alt="" className={'item_bg'}/>
                        <div className={'item_title'}>
                            <span>变异无聊猿Lite入场券1张</span>
                        </div>
                        <div className={'item_des_money'}>
                            <span>30</span>
                            <span>元</span>
                        </div>
                        <img
                            src={initData.state === 0 ? require('../imgs/btn_gm_nor.png') : require('../imgs/btn_gm_dis.png')}
                            alt="" className={'btn'}
                            onClick={() => initData.state === 0 ? huishouOrBuyJuan(1) : null}/>
                        <p className={'item_des right'}>积分最高可抵扣20元！</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const GuessingActyBottom = () => {
    const navigate = useNavigate();
    const {initData} = useContext(Context);
    const [p1, setP1] = useState(0);
    const [p2, setP2] = useState(0);
    const [p3, setP3] = useState(0);
    const [list, setList] = useState([]);
    const [shishiCount, setShishiCount] = useState(null)//史诗款无聊猿的剩余数量

    useEffect(() => {
        loadData();
        getList();
    }, [])

    const loadData = async () => {
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
        if (!userInfo.clientId) {
            return
        }
        try {
            // showLoading()
            const res = await getWealth({})
            // hideLoading();
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let info = res.data?.info || {};
                let p1Tem = 0;//红色的 史诗款
                let p2Tem = 0;//砖石 碎片
                let p3Tem = 0;//蓝色 变异
                for (const item of info) {
                    if (item.type === 1) {
                        p1Tem = item.amount;
                    } else if (item.type === 2) {
                        p2Tem = item.amount;
                    } else if (item.type === 3) {
                        p3Tem = item.amount;
                    }
                }
                setP1(p1Tem);
                setP2(p2Tem);
                setP3(p3Tem);
            }
        } catch (error) {
            Toast.show(error);
            // hideLoading();
        }
    }

    const getList = async () => {
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
        if (!userInfo.clientId) {
            return
        }
        try {
            // showLoading()
            const res = await getGoodslist({preview: 1})
            // hideLoading();
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let goodsList = res.data.goodsList || [];
                let newArr = [];
                if (goodsList.length > 2) {
                    newArr = goodsList.slice(0, 2);
                } else {
                    newArr = goodsList;
                }
                for (const item of newArr) {
                    let p2Tem = 0;//砖石 碎片
                    let p3Tem = 0;//蓝色 变异
                    let p1Tem = 0;//红色的 史诗款
                    for (const item0 of item.spendItemList) {
                        if (item0.type === 1) {
                            p1Tem = item0.num;
                        } else if (item0.type === 2) {
                            p2Tem = item0.num;
                        } else if (item0.type === 3) {
                            p3Tem = item0.num;
                        }
                    }
                    item.suipian = p2Tem;
                    item.bianyi = p3Tem;
                    item.shishi = p1Tem;
                }
                setShishiCount(res.data.remainStock);
                setList(newArr);
            }
        } catch (error) {
            Toast.show(error);
            // hideLoading();
        }
    }

    const goBuyCollection = () => {
        if (initData.state !== 0) {
            return;
        }
        navigate(`/guessingActy/enshrine?collectionId=${32}`);
    }

    const duihuanShop = () => { //兑换商城
        navigate('/guessingActy/exchangeStore')
    }

    const items = () => {
        return list.map((item, index) => {
            return (
                <div className={'item'} key={index.toString()}>
                    {
                        index === 0 ? <img src={require('../imgs/shop_item0.png')} alt="" className={'item_img'}/> :
                            <img src={require('../imgs/shop_item1.png')} alt="" className={'item_img'}/>
                    }
                    <p className={'item_title'}>{item.goodsTitle}</p>
                    <div className={'item_bottom'}>
                        {
                            item.suipian ? <div className={'item_zs'}>
                                <img src={require('../imgs/icon_sp.png')} alt=""/>
                                <span>{item.suipian}</span>
                            </div> : null
                        }
                        {(item.suipian && item.bianyi) ? <span className={'item_huo'}>或</span> : null}
                        {
                            item.bianyi ? <div className={'item_zs right'}>
                                <img src={require('../imgs/icon_by_dh.png')} alt=""/>
                                <span>{item.bianyi}</span>
                            </div> : null
                        }
                        {(item.bianyi && item.shishi) ? <span className={'item_huo'}>或</span> : null}
                        {
                            item.shishi ? <div className={'item_zs right'}>
                                <img src={require('../imgs/icon_ss_dh.png')} alt=""/>
                                <span>{item.shishi}</span>
                            </div> : null
                        }
                    </div>
                </div>
            )
        })
    }


    return (
        <>
            <div className={'exchange_shop'} onClick={() => duihuanShop()}>
                <img src={require('../imgs/panel_dhsc.png')} alt="" className={'exchange_shop_bg'}/>
                <div className={'exchange_shop_container'}>
                    <div className={'ex_top'}>
                        <div className={'ex_top_0'}>
                            <img src={require('../imgs/icon_sp.png')} alt=""/>
                            <span>{p2}</span>
                        </div>
                        <div className={'ex_top_0'}>
                            <img src={require('../imgs/icon_by_dh.png')} alt=""/>
                            <span>{p3}</span>
                        </div>
                        <div className={'ex_top_0'}>
                            <img src={require('../imgs/icon_ss_dh.png')} alt=""/>
                            <span>{p1}</span>
                        </div>
                        <p className={'more_p'}>更多&gt;</p>
                    </div>
                    <div className={'item_exchange_container'}>
                        {items()}
                    </div>
                    <div className={'item_new_fuli'}>
                        <p className={'fuli_logo'}>新人福利</p>
                        <img src={require('../imgs/pic_houzi.png')} alt="" className={'fuli_logo_img'}/>
                        <div className={'fuli_right'}>
                            <p>领取史诗款无聊猿</p>
                            <p>新人首次竞猜淘汰福利</p>
                            {
                                (shishiCount >= 0 && shishiCount !== null) ? <p>剩余：{shishiCount}</p> : null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={'go_buy_container'} id={'buy_container_id'}>
                <img src={require('../imgs/panel_gm199.png')} alt="" className={'go_buy_img'}/>
                <img onClick={() => goBuyCollection()}
                     src={initData.state === 0 ? require('../imgs/btn_gm_1.png') : require('../imgs/btn_gm1_dis.png')}
                     alt=""
                     className={'buy_btn'}/>
                <p onClick={() => navigate('/myCoupon')}>我的券包&gt;</p>
            </div>
            <img src={require('../imgs/logo.png')} alt="" className={'home_logo'}/>
        </>
    )
}

export default GuessingActyHome
