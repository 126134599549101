/*
 土地藏品回收
*/

import Home from './home';
import RecoveryRecord from './recoveryRecord';
import Recycling from './recycling';

export default {
    Home,
    RecoveryRecord,
    Recycling
}