import React, { useEffect, useState } from 'react'
import styles from '../orderConfirm.module.scss'
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import weizhi from '@/assets/img/landfi/weizhi.png'
import back from '@/assets/img/landfi/back-order-3.png'
import appService from '@/utils/appService'
import clickThrottle from "@/utils/clickThrottle"

const OrderConfirm = ({ onClose, container, recordInfo = {} }) => {
  const [userAddress, setUserAddress] = useState(null)

  const isWrite = (recordInfo.address && recordInfo.province && recordInfo.city && recordInfo.area && recordInfo.street) ? '1': '0'
  
  useEffect(() => {
    if (isWrite === '1') {
      if (recordInfo.prizeType === 11) {
        getGoodsInfo()
      } else {
        setUserAddress(recordInfo)
      }
    } else {
      getDefaultAddress()
    }
  }, [])

  const getDefaultAddress = async () => {
    const res = await appService('101294', {}, true)
    console.log('defaultaddress', res)
    if (res.status > 0) {
      const list = res?.data || []
      //查找默认地址
      const defaltAddress = (list.length > 0 && (list.find(item => item.isDefaltAddress == 1) || list[0])) || {}
      setUserAddress({...recordInfo, ...defaltAddress})
    }
  }

  const getGoodsInfo = async () => {
    const result = await appService('600904', {lotterySkuId: recordInfo.skuId})
    console.log("🚀 ~ getGoodsInfo ~ result:", result)
    if (result?.status > 0) {
      setUserAddress({...recordInfo, mtImg: result?.data?.bigUrl, mtName:result?.data?.productName })
    }
  }

  const fillinAddress = async () => {
    const params = {
      type: 1,
      req: recordInfo.id,
      activityCode: recordInfo.activityCode,
      province: userAddress.provinceName,
      city: userAddress.cityName,
      area: userAddress.areaName,
      street: userAddress.streetName,
      receiverName: userAddress.consigneeName,
      receiverMobile: userAddress.consigneeNumber,
      address: userAddress.deliveryAddress
    }
    const res = await appService(101330, params)
    console.log("🚀 ~ fillinAddress ~ res:", res)
    if(res?.status > 0) {
      Toast.show({
        content: '提交成功',
        afterClose: () => handleClose(),
      })
    }
  }

  const exchangeMaoTai = async () => {
    const params = {
      exchangeId: recordInfo.id,
      province: userAddress?.provinceName,
      city: userAddress?.cityName,
      area: userAddress?.areaName,
      street: userAddress?.streetName,
      consigneeName: userAddress?.consigneeName,
      consigneeNumber: userAddress?.consigneeNumber,
      address: userAddress?.deliveryAddress
    }
    console.log("🚀 ~ exchangeMaoTai ~ params:", params)
    const res = await appService('600907', params)
    if(res?.status > 0) {
      Toast.show({
        content: '兑换成功',
        afterClose: () => handleClose(),
      })
    }
  }

  const onSubmit = () => {
    if (!clickThrottle()) return
    if (recordInfo.prizeType === 3) {
      fillinAddress()
    } else if (recordInfo.prizeType === 11) {
      exchangeMaoTai()
    }
  }

  const handleClose = () => {
    onClose()
  }

  return <div className={styles.fill_in_address}>
    <div className={styles.main_container}>
      <div className={styles.top_nav}>
        <img className={styles.back} src={back} alt="" onClick={handleClose}/>
        <span className={styles.nav_title}>{isWrite === '0' ? '确认订单': '订单详情'}</span>
        <span className={styles.back}></span>
      </div>
      <div className={styles.mid_content}>
        <div className={styles.content_left}>
          <div className={styles.goods_info}>
            <div className={styles.goods_img}><img src={(userAddress?.prizeType === 11 && userAddress?.address) ? userAddress?.mtImg: userAddress?.prizeImgUrl} alt="" /></div>
            <div className={styles.goods_name}>
              <span>{(userAddress?.prizeType === 11 && userAddress?.address) ? userAddress?.mtName: userAddress?.prizeName || ''}</span>
              <span>1件</span>
            </div>
          </div>
          {isWrite === '0' ? null: <div className={styles.order_info}>
            <div className={styles.order_info_item}>
              <span>订单编号</span>
              <span>{userAddress?.orderId || ''}</span>
            </div>
            <div className={styles.order_info_item}>
              <span>下单时间</span>
              <span>{userAddress?.createTime ? dayjs(userAddress.createTime).format('YYYY-MM-DD HH:mm:ss'): ''}</span>
            </div>
          </div>}
        </div>
        <div className={styles.content_right}>
          <div className={styles.pay_type}>
            <span>支付/配送</span>
            <span>在线支付/快递配送</span>
          </div>
          <div className={styles.address}>
            {isWrite === '0' ? <div className={styles.add_address} onClick={async () => {
              const url = `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-A/customer/receive-address-unity/index`
              const address = await window.JsBridgeNew.push("CustomWeb", { uri: url, callback: true})
              console.log('address======>', address)
              setUserAddress({...recordInfo, ...address})
            }}>
              <img src={weizhi} alt="" />
              <span className={styles.tip_text}>
                {(userAddress?.provinceName && userAddress?.cityName && userAddress?.areaName) ? userAddress.provinceName + userAddress.cityName + userAddress.areaName + (userAddress.streetName || '') + (userAddress.deliveryAddress || ''): '请添加收货地址'}
              </span>
              <span className={styles.line}></span>
              <span className={styles.add_btn}>添加</span>
            </div>: <div className={styles.address_detail}>
              <img src={weizhi} alt="" />
              <div className={styles.address_text}>
                <span>{userAddress ? userAddress.province + userAddress.city + userAddress.area + (userAddress.street || '') + (userAddress.address || ''): ''}</span>
                <div className={styles.user_info}>
                  <span>{userAddress?.consigneeName || ''}</span>
                  <span>{userAddress?.consigneeNumber || ''}</span>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
      {isWrite === '0' && <div className={styles.bottom_btn}>
        <div className={styles.sub_btn} onClick={onSubmit}>提交订单</div>
      </div>}
    </div>
  </div>
}

export default OrderConfirm