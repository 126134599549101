/**
 *  漫画升级记录
 *  route: /cartoonCollection/upgradeHistory
*/
import React, { useState, useEffect, useRef} from "react";
import {Toast} from 'antd-mobile';
import {showLoading, hideLoading} from '@/components/loading';
import { getUpgradeRecord } from "@/api/projectManager_self";
import NFTHeader from '@/components/NFTHeader';
import ListView from "@/components/AntListView";
import "./index.scss";

const test = process.env.NODE_ENV == 'development' ? [
    {
        "upgradeAfterNum": 1,
        "upgradeImg": "https://gu-nft-test1.obs.cn-east-3.myhuaweicloud.com/gu/image37.png",
        "upgradeAfterLevel": "C",
        "afterName": "共创漫画C级产品",
        "upgradeBeforeLevel": "D",
        "upgradeBeforeNum": 6,
        "beforeName": "共创漫画D级产品",
        "upgradeMaterials": "6张D级共创漫画D级产品",
        "upgradeTime": "2022-12-13 15:15:25"
    }
]:[]

const UpgradeHistory = (props) => {

    const [list,setList] = useState(test)
    const [hasMore, setHasMore] = useState(false)
    const currentPage = useRef(1);
    
    useEffect(()=>{
        const initData = async ()=> {
            showLoading()
            await loadData()
            hideLoading()
        }
        initData()
    },[])

    const loadData = async () => {
        console.log('page:',currentPage.current)
        try {
            const res = await getUpgradeRecord({current: currentPage.current, size: 10})
            const {code, msg, data} = res;
            if (code !== '000000') throw msg;
            let _list = data.list
            if(currentPage.current > 1) {
                _list = list.concat(_list)
            }
            setList(_list)
            setHasMore(data.total > _list.length ? true: false)
        } catch (error) {
            Toast.show(error);
            
        }
        
       
    }

    const loadMore = async ()=> {
        currentPage.current = currentPage.current + 1
        await loadData()
    }

    const renderItem = (item, index)=> {
        return (
            <div className="upgradeHistory-item" key={index}>
                <div className="item-top">
                    <img src={item.upgradeImg} alt="" />
                    <div className="item-info">
                        <span>{item.afterName}</span>
                        <div>
                            <div>{`${item.upgradeAfterLevel}级`}</div>
                            <div>{`${item.upgradeAfterNum}张`}</div>
                        </div>
                    </div>
                </div>
                <div className="item-bottom">
                    <div className="upgrade-info">
                        <span>{'升级使用材料'}</span>
                        <div>{item.upgradeMaterials}</div>
                    </div>
                    <div className="upgrade-info">
                        <span>{'升级时间'}</span>
                        <div>{item.upgradeTime}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="upgradeHistory-page">
             <NFTHeader midText="升级记录" bgColor={'#F1F0F5'}/>
             <div className="page-body">
                <ListView 
                    dataSource={list}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    onRefresh={()=>{
                        console.log('onRefresh')
                        currentPage.current = 1
                        loadData()
                    }}
                    onEndReached={loadMore}
                />
             </div>
        </div>
    )
}

export default UpgradeHistory;