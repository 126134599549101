import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import DropdownSelect from "./components/dropdownSelect";
import { LevelOptions, getLeaseTerm } from "../utils";
import LeaseItem from "./components/leaseItem";
import { Toast } from 'antd-mobile';
import ListView from "@/components/AntListView";
import ModalView from "./components/modalView";
import MyModal from "../jewelLand/modal";
import gem from '@/assets/img/landfi/lease-gem.png';
import { hideLoading, showLoading } from "@/components/loading";
import { landApeLeaseList, landApeLeaseDetail, getGemstoreCount, landApeLease } from "@/api/guland";
import dayjs from "dayjs";

const LandLease = () => {
  const [list, setList] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [landLevel, setLandLevel] = useState(0)
  const [leaseTerm, setLeaseTerm] = useState(0)
  const [daysOptions, setDaysOptions] = useState([]);
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const domRef = useRef(null);

  useEffect(() => {
    getDaysOptions()
  }, [])

  useEffect(() => {
    getList(true);
  }, [landLevel, leaseTerm])

  const getList = async (isRefresh, loading = true) => {
    try {
      loading && showLoading()
      if(isRefresh) {
        currentPage.current = 1;
        domRef.current?.scroll(0, 0)
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
        collectionType: 2,
        rentalPeriod: leaseTerm === 0 ? '': leaseTerm,
        blockLevel: landLevel === 0 ? '': landLevel,
      }
      const res = await landApeLeaseList(params)
      loading && hideLoading()
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setList(res?.data?.list || [])
      } else {
        setList([...list, ...res?.data?.list]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.list?.length >= pageSize.current)
    } catch (error) {
      console.log(error)
      hideLoading()
      Toast.show(error)
    }
  }

  const getDaysOptions = async () => {
    try {
      const res = await getLeaseTerm();
      setDaysOptions(res);
    } catch (error) {
      Toast.show(error);
    }
  };

  const onClickItem = (item) => {
    const ModalInstance = MyModal.show({
      content: <ConfirmLeaseLand onClose={() => ModalInstance?.close()} infoData={item} refresh={getList}/>,
    })
  }

  return <div className={styles.land_lease}>
    <div className={styles.select_box}>
      <DropdownSelect title="等级" options={LevelOptions} onChange={(item) => setLandLevel(item.value)}/>
      <DropdownSelect title="租期" options={daysOptions} onChange={(item) => setLeaseTerm(item.value)}/>
    </div>
    <div className={styles.land_lease_list} ref={domRef}>
      {
        <ListView
          dataSource={list}
          hasMore={hasMore}
          style={{paddingTop: 5}}
          renderItem={(item) => <LeaseItem item={item} onClick={() => onClickItem(item)} isLand={true}/>}
          onEndReached={async () => {
            await getList(false, false);
          }}
          isLoad={isLoad}
          customStyle={{imgStyle: {width: 150, height: 150}}}
        />
      }
    </div>
  </div>
}

const ConfirmLeaseLand = ({onClose, infoData, refresh}) => {
  const [landInfo, setLandInfo] = useState({})
  const [gemstoreCounts, setGemstoreCounts] = useState(0)
  const { nftNo, collectionName, caddress, rentalPeriod, rentalFee, nftImage  } = infoData || {}

  useEffect(() => {
    getLeaseDetail()
    queryGemstoreCounts()
  }, [])

  const getLeaseDetail = async () => {
    try {
      const res = await landApeLeaseDetail(nftNo)
      if (res.code !== '000000') throw res.msg
      setLandInfo(res?.data || {})
    } catch (error) {
      console.log(error)
    }
  }

  const confirmLease = async () => {
    if (landInfo.isSelf === 1) return Toast.show('不能租赁自己的土地');
    if (gemstoreCounts < rentalFee) return Toast.show('宝石不足');
    try {
      showLoading()
      const res = await landApeLease({
        nftNo,
        rentalPeriod,
        rentalFee,
      })
      hideLoading()
      if (res.code !== '000000') throw res.msg
      onClose()
      Toast.show('租赁成功')
      refresh && refresh(true, false)
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const queryGemstoreCounts = async () => {
    try {
      const res = await getGemstoreCount()
      if (res?.code !== '000000') throw res?.msg
      setGemstoreCounts(res?.data?.gemstonesCount || 0)
    } catch (error) {
      console.log(error)
    }
  }

  return <ModalView title="确认租赁" onClose={onClose}>
    <div className={styles.confirm_lease_land}>
      <div className={styles.lease_land_top}>
        <div className={styles.land_img}><img src={nftImage} alt="" /></div>
        <div className={styles.land_info}>
          <div className={styles.info_title}>
            <span>{collectionName}</span>
            <span className={styles.common_item}>租期：{rentalPeriod === -1 ? '永久' : rentalPeriod + '天'}</span>
          </div>
          <div className={`${styles.common_item} ${styles.lease_land_no}`}>编号：{nftNo}</div>
          <div className={`${styles.common_item} ${styles.lease_person}`}>出租人：{caddress}</div>
          <div className={`${styles.common_item} ${styles.lease_land_manage}`}>
            管理费结束日期：
            {landInfo.feesExpireTime ? landInfo.feesExpireTime.includes('2099') ? '永久' : dayjs(landInfo.feesExpireTime).format('YYYY.MM.DD HH:mm:ss'): '无'}
          </div>
          <div className={styles.info_speed}>
            <span>
              加速卡情况：
              {landInfo?.cardsDetail?.days && landInfo?.cardsDetail?.times ? `${landInfo?.cardsDetail?.days} 日 ${landInfo?.cardsDetail?.times} 倍` : '无'}
            </span>
            {landInfo?.cardsDetail?.expireTime ? <span>{dayjs(landInfo?.cardsDetail?.expireTime).format('YYYY.MM.DD HH:mm')} 到期 </span>: null}
          </div>
          <div className={styles.info_speed}>
            <span>
              奖励等级领取情况：
              {landInfo.maxLevelLandExp ? `已领取到 ${landInfo.maxLevelLandExp} 级`: '无'}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.lease_land_bottom}>
        <div className={styles.gemstore_counts}>当前宝石：{gemstoreCounts}</div>
        <div className={styles.btn_box}>
          <div className={styles.cancel} onClick={onClose}><div className={styles.inner}>取消</div></div>
          <div 
            className={`${styles.confirm} ${(gemstoreCounts >= rentalFee && landInfo.isSelf === 0) ? '' : styles.disabled}`} 
            onClick={confirmLease}
          >
            <div className={styles.inner}>
              <img src={gem} alt="" />
              <span>{rentalFee} 租赁</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalView>
}

export default LandLease;