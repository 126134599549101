/**
 * @description GR-2407668康养藏品相关接口
*/
import requests from '@/utils/requests_self';
import { 
    queryDefCode, 
    getActivityTime, 
    getRecoveryActivity, 
    getRecoveryRecordv2
 } from "@/api/projectManager_self";

const mock = false;

class Service {
    // 活动码
    static activityCode = 'healthPreservation';
    // 康养藏品合成前207，合成后208
    static collectionId = 207;
    /**
     * @description 获取康养藏品初始化数据
     * @param {*} params 
     * @returns
    */
    static getInitData = async (params={})=> {
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo') ?? "{}");
        // params = { activityCode: Service.activityCode, clientId: userInfo?.clientId, ...params };
        params = { activityCode: Service.activityCode }
        if(mock) {
            return Promise.resolve({
                code: '000000',
                msg: 'success',
                data: {
                    systemTime: "2024-07-31 19:51:21",
                    startTime: '2024-07-30 10:00:00',
                    endTime: '2024-07-31 10:00:00',
                    requireCount: 140,
                    count: 100,
                    detail: {
                        //0-周，1-月，2-季
                        cycle: 1,
                        list: [
                            {pointCount: 100000, position: 30, cardName: '30日10倍加速卡', cardNum: 1}
                        ]
                    }
                }
            });
        }
        // return await getActivityTime(params);
        return await requests.post('/customer/activity/v1/initActivity', JSON.stringify(params));
    }

    /**
     * @description 获取活动规则
     * @param {*} params 
     * @returns
    */
    static getActivityRules = async ()=> {
        return await queryDefCode({codeType: 'activityRule', code: Service.activityCode});
    }

    /**
     * @description 获取用户信息，是否实名
     * @returns {ID: 存在则实名}
     */ 
    static getUserCertificateStatus = async () => {
        if (window.JsBridge.hasWebViewBridge()) {
            const res = await window.JsBridge.getDetailCurrentUserInfo()
            return res;
        } else {
            return {};
        }
    }

    /**
     * @description 权益获取记录
     * @param { * } params
     * @returns
    */
   static getRecoveryRecord = async (param={})=> {
    const params = {
        activityCode: Service.activityCode,
        ...param,
    }
    if(mock) {
        const mockData = {
            code: '000000',
            msg: 'success',
            data: {
                total: 10,
                list: [
                {
                    //发放时间
                    provideTime: '2024-01-01 12:00:00',
                    //验仓数量
                    verifyNum: '10',
                    verifyTime: '2024-01-01 12:00:00',
                    provideCycle: '2024.09',
                    speedRetarderDOList: [
                        {cardId: 'xxx', cardName: '10倍3日加速卡', cardNum: '3'},
                        {cardId: 'yyv', cardName: '10倍30日加速卡', cardNum: '2'}
                    ]
                }
              ]
            }
          }
    
        return Promise.resolve(mockData);
    }
      return await requests.post(`/customer/activity/v1/getRecord?currentPage=${params.currentPage}&pageSize=${params.pageSize}`, JSON.stringify(params));
   }

    /**
     * @description 返回事件
    */
    static onBack = () => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.back();
        } else {
            window.history.go(-1);
        }
    }
    /**
     * @description 立即购买
    */
    static onBuy = (history) => {
        const uri = `/ape/saleDetail?collectionId=${Service.collectionId}&code=${Service.activityCode}`
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.verifyRealName("请先实名认证").then(function (res) {
                if (res?.result) {
                    window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
                }
            })

        } else {
            if(history) {
                history.push ? history.push(uri) : history(uri);
            }  
        }
    }

    /**
     * @description 合成
    */
    static onMerge = (navigate,param={})=> {
        const uri = `/myCollectionDetail?nft_hash=${param.nft_hash}`
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
        } else {
            if(navigate) {
                navigate.push ? navigate.push(uri) : navigate(uri);
            }
        }
    }
}

export default Service;