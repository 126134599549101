// 是否在小程序环境内
export const isMiniprogram = () => /miniProgram/i.test(navigator.userAgent);

/**
 * wxJSSDK 引入方式：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#3
 * 先从第一个资源获取，如果失败 尝试从第二个资源获取
 * 加载过后直接使用
*/
export const loadWxJSSDK = (() => {
  let isLoaded = false;
  return () => {
    return new Promise((resolve, reject) => {
      if (isLoaded) {
        resolve(window.wx || {});
        return;
      }
      let try2 = false;
      const script = document.createElement('script');
      script.src = '//res.wx.qq.com/open/js/jweixin-1.3.2.js';
      script.onload = () => {
        isLoaded = true;
        resolve(window.wx || {});
      };
      script.onerror = () => {
        if (try2) {
          reject('load wx js sdk failed');
          return;
        }
        try2 = true;
        script.src = '//res2.wx.qq.com/open/js/jweixin-1.3.2.js';
      };
      document.body.appendChild(script);
    })
  };
})();