/**
 * @description GR-2407668康养藏品
*/

import Home from './home';
import LanHu from './lanhu';
import MergeHistory from './mergeHistory';

export default {
    Home,
    LanHu,
    MergeHistory,
}