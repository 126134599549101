import React, { useEffect, useState, useRef } from "react"
import { Mask, Toast, Button } from "antd-mobile"
import { getShareInfo } from "@/api/projectManager"
import html2canvas from "html2canvas"
import { QRCodeSVG } from "qrcode.react"
import { CloseCircleFill } from "antd-mobile-icons"
import { copyText, throttle } from "@/utils/publicFun"
import "./index.scss";
import apeWorldShareImg from '@/assets/img/ape-world/egg_haibao_canjia.png';
import waicShareImg from '@/assets/img/poster-waci.png';

const ShareMask = ({ maskVisible, shareCode, title, context, icon, shareLink='', closeMask, type }) => {
  const domRef = useRef(null)
  const shareModalRef = useRef()

  const [closeFinshed,setCloseFinshed] = useState(false)

  useEffect(()=>{
    if(maskVisible){
      setCloseFinshed(maskVisible)
    }
  },[maskVisible])

  const ImgMap = {
    '6': {bgImg: waicShareImg, shareLink: process.env.REACT_APP_SHARE_URL?.replace("inviteCode", shareCode)},
    '7': {bgImg: waicShareImg, shareLink: process.env.REACT_APP_SHARE_URL?.replace("inviteCode", shareCode)},
    '9': {bgImg: apeWorldShareImg, shareLink: process.env.REACT_APP_APE_WORLD_URL},
    // 共创漫画分享
    '10': {bgImg: require('@/assets/img/ape-world/cartoon_share_code.png'), shareLink: `${window.location.origin}/share/cartoonShare`},
    // 2024欧洲杯，无分享
    '11': {bgImg: '', shareLink: ``},
  }

  const createShareImage = () => {
    return new Promise(async (resolve) => {
        html2canvas(domRef.current, {
            allowTaint: true,
            useCORS: true,
            scrollX: 0,
            scrollY: 0
        }).then(async (canvas) => {
            const result = canvas.toDataURL('image/jpeg', 0.8);
            resolve(result);
        }).catch(() => {
            resolve(false);
        });
    })
  }

  const getShareImagePath = () => {
    return new Promise(async (resolve) => {
        if (!domRef.current) {
            resolve(false);
        }
        let base64 = await createShareImage();
        if (base64) {
            let res = await window.JsBridge.saveImgBase64(base64).catch(ex => false);
            if (!res) {
              window.JsBridge.runAction('alert', 'error', ['图片保存到本地失败']);
                resolve(false);
            } else {
                resolve(res.tempFilePath);
            }
        } else {
            resolve(false);
        }
    })
  } 

  // 微信分享
  const wxShare = throttle(async () => {
    let shareToast = Toast.show({ maskClickable: false, content: "分享中..." })
    if (window.JsBridge.hasWebViewBridge()) {
      const filePath = await getShareImagePath();
      window.JsBridge.share({
        text: context,
        icon: type === '9' ? filePath: icon,
        link: type === '9' ? '': ImgMap[type]?.shareLink ?? shareLink,
        title: title,
        platform: 1,
      }).then(function (result) {
        shareToast && shareToast.close()
        Toast.show(result.message)
      })
    }
  }, 3000)
  // 微信朋友圈分享
  const momentsShare = throttle(async () => {
    let momentsToast = Toast.show({ maskClickable: false, content: "分享中..." })
    if (window.JsBridge.hasWebViewBridge()) {
      const filePath = await getShareImagePath();
      window.JsBridge.share({
        text: context,
        icon: type === '9' ? filePath: icon,
        link: type === '9' ? '': ImgMap[type]?.shareLink ?? shareLink,
        title: title,
        platform: 2,
      }).then(function (result) {
        momentsToast && momentsToast.close()
        Toast.show(result.message)
      })
    }
  }, 3000)

  // 将dom 转换为 base64
  const createCanvas = (dom) => {
    return new Promise((resolve, reject) => {
      html2canvas(dom, { useCORS: true }).then(
        (canvasUrl) => {
          resolve(canvasUrl.toDataURL("image/png"))
        },
        (err) => {
          reject(`生成海报:${err})`)
        }
      )
    })
  }

  const returnDiv = () => {
    if (type === 1) {
      return <img className="share_box" src={require("@/assets/img/mh/sspic.png")} alt="" />
    } else if (type === 2) {
      return <img className="share_box" src={require("@/assets/img/mh/chspic.png")} alt="" />
    } else if (type === 3) {
      return <img className="share_box" src={require("@/assets/img/mh/dcpic.png")} alt="" />
    } else if (type === 4) {
      return <img className="share_box" src={require("@/assets/img/mh/mhpic.png")} alt="" />
    } else {
      return <></>
    }
  }

  // 下载图片
  const downloadImg = throttle(() => {
    let toast = Toast.show({ icon: "loading", content: "保存中..." })
    createCanvas(domRef.current).then((imgData) => {
      if (window.JsBridge.hasWebViewBridge()) {
        window.JsBridge.saveImgBase64(imgData).then(function (result) {
          toast.close()
          window.JsBridge.requestPermission("requestAlbum")
          window.JsBridge.saveImageToPhotosAlbum(result.tempFilePath).then(function (res) {
            if (res) {
              Toast.show("图片保存成功")
            } else {
              Toast.show("图片保存失败")
            }
          })
        })
      } else {
        let a = document.createElement("a")
        document.body.appendChild(a)
        a.download = `image-${new Date().getTime()}`
        a.href = imgData
        a.click()
        document.body.removeChild(a)
      }
    })
  }, 3000)

  return (
    <div className="share-mask-modal" ref={shareModalRef}>
      <Mask visible={maskVisible} 
        destroyOnClose={true} 
        color={`linear-gradient(rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 1))`}
        forceRender={true} 
        getContainer={shareModalRef.current}
        afterClose={()=>setCloseFinshed(false)}
      >
        <div className="share-body">
          <div className="share-content" ref={domRef}>
            <div className="share-avatar">
              <img src={ImgMap[type]?.bgImg} alt="avatar" />
            </div>
            {returnDiv()}
            <div className={`share-detail type-${type}`}>
              <QRCodeSVG value={ImgMap[type]?.shareLink || ''} />
            </div>
          </div>
        </div>
        <div className="opt">
          {window.JsBridge.hasWebViewBridge() && (
            <>
              <div className="wechat" onClick={wxShare}>
                <img src={require("@/assets/img/homedetail/wechat.png")} alt="" />
                微信好友
              </div>
              <div className="share" onClick={momentsShare}>
                <img src={require("@/assets/img/homedetail/share.png")} alt="" />
                朋友圈
              </div>
            </>
          )}
          <div
            className="copy"
            id="copyURL"
            onClick={() => copyText(ImgMap[type]?.shareLink?.replace("inviteCode", shareCode) || "", document.getElementById("copyURL"))}
          >
            <img src={require("@/assets/img/homedetail/copy.png")} alt="" />
            复制链接
          </div>
          <div className="download" onClick={downloadImg} style={{ marginLeft: `${window.JsBridge.hasWebViewBridge() ? "0px" : "-140px"}` }}>
            <img src={require("@/assets/img/homedetail/download.png")} alt="" />
            下载图片
          </div>
        </div>
        <div className="cancel_btn" onClick={closeMask}>
          <Button block color="primary" size="large">
            取消
          </Button>
        </div>
      </Mask>
    </div>
  )
}

export default ShareMask
