/**
 * @description 工业猴
 * @returns {route: /industrialApe/home }
 */

import React, { useEffect, useState, useRef, useMemo } from "react";
import qs from "query-string";
import NFTModal from "@/components/NFTModal";
import ActivityRules from "./activityRules";
import SynthesisType from "./synthesisType";
import NumberScroll from "../components/NumberScroll";
import { useNavigate } from "react-router-dom";
import count_bg from "@/assets/img/industrialApe/info.png";
import text from "@/assets/img/industrialApe/text.png";

import { hideLoading, showLoading } from "@/components/loading";
import { Toast } from "antd-mobile";
import Service from "../service";
import "./index.scss";

const jackpotNum = "4000000";

const Home = () => {
  const navigate = useNavigate();
  const { activityCode } = qs.parse(window.location.search);
  if (activityCode) {
    Service.activityCode = activityCode;
  }
  const [userInfo, setUserInfo] = useState({});
  const [data, setData] = useState({});

  const pageDom = useRef(null);

  useEffect(() => {
    (async () => {
      await getData();
      onDidFocus();
    })();
  }, []);

  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      getData();
      onDidFocus();
    });
  };

  // 提示信息
  const tipsMessage = useMemo(() => {
    let msg = undefined;
    const { systemTime, startTime, endTime } = data;
    const currentT = new Date(systemTime?.replace(/-/g, "/")).getTime();
    const startT = new Date(startTime?.replace(/-/g, "/")).getTime();
    const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
    if (!startTime || !endTime) {
      msg = "接口异常";
    } else if (currentT < startT) {
      msg = "活动未开始";
    } else if (currentT > endT) {
      msg = "活动已结束";
    }

    return msg;
  }, [data]);

  const onClickRules = () => {
    const ModalInstance = NFTModal.show({
      content: <ActivityRules onClose={() => ModalInstance?.close()} />,
      getContainer: pageDom.current,
    });
  };

  const buyHandle = () => {
    if (tipsMessage) {
      Toast.show(tipsMessage);
      return;
    }
    if (!userInfo?.ID) {
      Toast.show("请先实名认证");
      return;
    }
    const uri = `/ape/saleDetail?collectionId=${Service.collectionId}&code=${Service.activityCode}`;
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", {
        uri: `${window.location.origin}${uri}`,
      });
    } else {
      navigate(uri);
    }
  };

  const onSynthesisType = () => {
    if (tipsMessage) {
      Toast.show(tipsMessage);
      return;
    }
    if (!userInfo?.ID) {
      Toast.show("请先实名认证");
      return;
    }
    const ModalInstance = NFTModal.show({
      content: (
        <SynthesisType
          onClose={() => ModalInstance?.close()}
          activityCode={Service.activityCode}
          navigate={navigate}
        />
      ),
      getContainer: pageDom.current,
    });
  };

  const onBack = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.back();
    } else {
      window.history.go(-1);
    }
  };

  // const queryRecyclingRecord = () => {
  //   if (window.JsBridge.hasWebViewBridge()) {
  //     window.JsBridge.push("CustomWeb", {
  //       uri: `${window.location.origin}/collectionRecoveryLand/recoveryRecord?activityCode=${Service.activityCode}`,
  //     });
  //   } else {
  //     navigate(
  //       `/collectionRecoveryLand/recoveryRecord?activityCode=${Service.activityCode}`
  //     );
  //   }
  // };

  const getData = async () => {
    try {
      showLoading();
      const [userInfo, data] = await Promise.all([
        Service.getUserCertificateStatus(),
        Service.getActivityData(),
        // Service.getActivityConfig(),
      ]);
      hideLoading();
      setUserInfo(userInfo);
      setData(data);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  };

  return (
    <div className="industrial-ape-home" ref={pageDom}>
      <div className="industrial-ape-inner">
        <div className="back" onClick={onBack}></div>
        {!userInfo?.ID && (
          <div
            className="real_name"
            onClick={() => window.JsBridge.navigate("Verify")}
          >
            实名
          </div>
        )}
        <div
          className={!userInfo?.ID ? "activity_rules" : "activity_rules1"}
          onClick={onClickRules}
        >
          规则
        </div>
        {/* <div
          className={!userInfo?.ID ? "my_parts" : "my_parts1"}
          onClick={queryRecyclingRecord}
        >
          我的配件
        </div> */}
        <div className="count_box">
          <img src={count_bg} />
          <div className="res_count">
            <div className="res_inner">
              <div className="res_bg">
                <img src={text} />
              </div>
              <div className="res">{`剩余：${data?.remainCount || '0'}`}</div>
            </div>
          </div>
          <div className="score_count">
            <div className="Jackpot_box">
              <NumberScroll
                endValue={String(data.pool || 0).padStart(7, '0')}
                textStyle={{ fontSize: 25, textColor: "#FF5151" }}
              />
            </div>
          </div>
          <div className="tips_box">
            抽中大奖藏品，获得当前奖池所有积分，奖池积分将重新累计。
          </div>
        </div>

        <div className="info_box"></div>
        <div className="bottom_box">
          <div
            className="buy_box"
            onClick={() => {
              buyHandle();
            }}
          >
            立即购买
          </div>
          <div className="setting_box"></div>
          <div className="opt_box">
            <div className="left">
              <div className="title">合成工业猴</div>
              <div className="subTitle">工业时代专有工种，拥有工业时代技能</div>
            </div>
            <div
              className={data?.switchStatus === 1?"right":"right_disable"}
              onClick={() => {
                data?.switchStatus === 1 && onSynthesisType();
              }}
            >
              立即合成
            </div>
          </div>
          <div className="logo_box"></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
