import axios from "axios";
import { Toast, DotLoading } from "antd-mobile"
import md5 from 'js-md5';

import encryption from "@/utils/encryption"
// import '../assets/scss/requestToast.scss'

const requests = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: "4000",
});

let toast = null
requests.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
requests.interceptors.request.use(
  (config) => {
    config.headers.t = window.localStorage.getItem('token') || "";

    let timestamp = parseInt(new Date().getTime() / 1000)
    let nonce = Math.random().toString(36).substr(-10)
    config.headers.timestamp = timestamp
    config.headers.nonce = nonce
    let sign = md5(timestamp + nonce)
    config.headers.sign = sign

    if (config?.params?.page === 1 || !config?.params?.page) {
      toast = Toast.show({ icon: 'loading', content: '加载中...' })
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器;
requests.interceptors.response.use(
  (res) => {
    return new Promise((resolve, reject) => {
      toast && toast.close()
      // 登录失效--跳转到绿地登录页
      if (res.data.code === 900003 || res.data.code === 900004) {
        const encodeHref = encodeURIComponent(window.location.href)
        if (window.JsBridge.hasWebViewBridge()) {
          // app环境
          Toast.show('请先登录')
          window.location.replace(`${process.env.REACT_APP_LOGIN_URL}${encodeHref}`)
        } else {
          // 浏览器环境
          Toast.show('请先登录')
          if(process.env.NODE_ENV !== 'development') {
            window.location.replace(`${process.env.REACT_APP_LOGIN_URL}${encodeHref}`)
          }

        }
      } else if (res.data.code !== 200) {
        Toast.show(res.data.msg || res.data.code || '活动火爆，请稍后重试')
        reject(res.data.msg || res.data.code || '活动火爆，请稍后重试')
      } else {
        resolve(res.data.data)
      }
    })
  },
  (error) => {
    let errorMsg = ''
    if (/network error/gi.test(error?.message)) {
      errorMsg = "活动火爆，请稍后重试"
      Toast.show("活动火爆，请稍后重试")
    } else if (/timeout/gi.test(error?.message)) {
      errorMsg = "请求超时，请稍后重试"
      Toast.show("请求超时，请稍后重试")
    } else {
      errorMsg = "请求错误，请稍后重试"
      Toast.show('请求错误，请稍后重试')
    }
    return Promise.reject(errorMsg);
  }
);
export default requests;