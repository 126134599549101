import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { Mask } from 'antd-mobile';

const RulesModal = ({onClose, activityRules}) => {
  const [visible, setVisible] = useState(true);

  const handleClick = () => {
    setVisible(false);
    onClose && onClose();
  }

  return <Mask className='parts-recycling-rule-mask' visible={visible} onMaskClick={() => handleClick()}>
    <div className='rules-modal-common'>
      <div className="modal-container">
        <div className="modal-title">{activityRules?.codeAlias}</div>
        <div className="modal-content">
          {activityRules?.otherSign}
        </div>
        <div className="rules-btn" onClick={handleClick}>我知道了</div>
      </div>
    </div>
  </Mask>
}

RulesModal.open = (activityRules) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<RulesModal onClose={close} activityRules={activityRules}/>, div);
}

export default RulesModal;