/**
 * 共创漫画数字藏品 - 某一种漫画列表
 * route: /cartoonCollection
*/

import React, { useState, useEffect, useRef, Fragment } from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Toast} from 'antd-mobile';
import {showLoading, hideLoading} from '@/components/loading';
import { getCollection, upgradeComics } from "@/api/projectManager_self";
import {getUrlAllParams} from "@/utils/publicFun"
import NFTHeader from '@/components/NFTHeader';
import ListView from "@/components/AntListView";
import ModalAlert from "@/components/ModalAlert";
import UpgradeModal from '../component/UpgradeModal';

import "./index.scss";

const hc_animation_01 = require("@/assets/img/egg/yu_zhiwu_shu_img.gif")
const animationCount = 86
const LimitRatio = 6
//漫画等级
const Levels = ['D','C','B','A','S','SS']

const CartoonCollection = (props) => {

    const navigate = useNavigate();
    const {search} = useLocation()
    const { data='' } = getUrlAllParams(search.slice(1));
    const dataSource = JSON.parse(decodeURIComponent(data || '{}'))

    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false);
    const [total, setTotal] = useState(0)
    const [upgradeVisible, setUpgradeVisible] = useState(false)
    const currentPage = useRef(1);
    const currentRef = useRef()

    useEffect(()=>{
        const initData = async ()=> {
            showLoading()
            await loadData()
            hideLoading()
        }
        initData()
    },[])

    const loadData = async () => {
        console.log('page:',currentPage.current)
        try {
            const res = await getCollection({current: currentPage.current, size: 10, seriesId: dataSource.seriesId,collectionIds: dataSource.collectionId });
            const {code, msg, data} = res;
            if (code !== '000000') throw msg;

            let _list = data.list
            if(currentPage.current > 1) {
                _list = list.concat(_list)
            }
            setList(_list)
            setTotal(data.total)
            setHasMore(data.total > _list.length ? true: false)

        } catch (error) {
            Toast.show(error);
        }

    }

    const headerRightClick = ()=> {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/cartoonCollection/upgradeHistory`});
        } else {
            navigate(`/cartoonCollection/upgradeHistory`, {})
        }
    }

    const loadMore = async ()=> {
        currentPage.current = currentPage.current + 1
        await loadData()
    }

    // 点击升级操作
    const onUpgradeAction = ()=> {
        // 升级藏品
        setUpgradeVisible(!upgradeVisible)
    }
    //合并升级中
    const megreUpgradeing = async (count)=> {
       let finshData = undefined
       let msgError = '请求报错'

       const upgradeVideo = ()=> {
            let video = ''
            if(dataSource.nextLevel == 'C') {
                video = require('@/assets/img/ape-world/d-c_upgrade.mp4')
            }else if(dataSource.nextLevel == 'B') {
                video = require('@/assets/img/ape-world/c-b_upgrade.mp4')
            }else if(dataSource.nextLevel == 'A') {
                video = require('@/assets/img/ape-world/b-a_upgrade.mp4')
            }else if(dataSource.nextLevel == 'S') {
                video = require('@/assets/img/ape-world/a-s_upgrade.mp4')
            }else if(dataSource.nextLevel == 'SS') {
                video = require('@/assets/img/ape-world/s-ss_upgrade.mp4')
            }
            return video
       }

        ModalAlert.show({
            getContainer:currentRef.current,
            afterClose: ()=> {
                finshData ? upgradeFished(finshData): Toast.show(msgError);
           },
            content: <video id='video'
                            width="100%"
                            height="100%"
                            style={{borderRadius: 8, background: 'black'}}
                            src={upgradeVideo()}
                            autoPlay="autoplay"
                            playsInline={true}
                            muted
                    />
    //  <img src={hc_animation_01} alt="" style={{width: '100%'}} />
        })
        try {
            const beignTime = Date.now()
            const res = await upgradeComics({
                collectionId: dataSource.collectionId,
                collectionName: dataSource.collectionName,
                level: dataSource.level,
                num: count
            })
            const {code, msg, data} = res;
            if (code !== '000000') throw msg;
            finshData = data
             //按照1/40Hz的频率计算
            const time = 1 * 1000 * animationCount / 40
            const timeDiff = (Date.now() - beignTime)/1000
            const duration = time>=timeDiff ? time - timeDiff: 0
            setTimeout(()=>{
                ModalAlert.hide()
            },5000)

        } catch (error) {
            msgError = error
            ModalAlert.hide()
        }


    }
    // 升级完成
    const upgradeFished = (finshed={})=> {
        /*
        finshed =  {
            "level": "B",
            "num": 1,
            "imageBg": "https://gu-nft-test1.obs.cn-east-3.myhuaweicloud.com/gu/image37.png",
            "collectionName": "共创漫画B级产品"
        }
        */
        ModalAlert.show({
            getContainer:currentRef.current,
            title: '升级成功',
            content: (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{color:'#333', fontSize: 12}}>
                        {`${finshed.num}张${finshed.level}级${finshed.collectionName}`}
                    </span>
                    <div style={{padding: 22}}>
                        <img src={finshed.imageBg} alt="" style={{width: 144}} />
                    </div>
                </div>
            ),
            onAction: (item, index) => {
                ModalAlert.hide()
                if(item.key == 'confirm'){
                    //返回上级列表
                    if (window.JsBridge.hasWebViewBridge()) {
                        window.JsBridge.back();
                    } else {
                        navigate(-1)
                        // window.history.back()
                    }
                }else {
                    loadData()
                }
            },
            actions: [
                {
                    key: 'cancel',
                    text: '取消'
                },
                {
                    key: 'confirm',
                    text: '查看藏品',
                }
            ]
        })
    }

    const renderHeader = ()=> {
        return (
            <Fragment>
                {
                    list && list.length > 0 &&
                    <div className="tip-view">
                        <div className="tip-left">
                            <div>{`${dataSource.level}级藏品`}</div>
                            <span>{`共${total || dataSource.num}张`}</span>
                        </div>
                        {
                            dataSource.nextLevel &&
                            <span className="tip-right">{`每${LimitRatio}张可升级1张${ dataSource.nextLevel }级`}</span>
                        }

                    </div>
                }
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className="cartoon-collection-page" ref={currentRef}>
                <NFTHeader midText="共创漫画数字藏品" rightText="升级记录" bgColor={'#FFF'} rightClick={headerRightClick}/>
                <div className="page-body">
                    <ListView
                        dataSource={list}
                        hasMore={hasMore}
                        renderHeader={renderHeader}
                        renderItem={(item)=> <CartoonItem item={item}/>}
                        onRefresh={()=>{
                            console.log('onRefresh')
                            currentPage.current = 1
                            loadData()
                        }}
                        onEndReached={loadMore}
                    />

                </div>
                {
                    // 有数据且不是SS级显示底部按钮
                    list && list.length > 0 &&  dataSource.nextLevel &&
                    <>
                    {
                        list.length >= LimitRatio ?
                        <div className={`fixed-button enable`} onClick={onUpgradeAction}>
                            <div className="title">{'升级当前等级藏品'}</div>
                        </div>:
                        <div className={`fixed-button`} >
                            <div className="title">{'升级当前等级藏品'}</div>
                            <div className="subTitle">{`${LimitRatio}张藏品可升级，未满足升级条件`}</div>
                        </div>
                    }
                    </>
                }
            </div>
            <UpgradeModal visible={upgradeVisible}
                dataSource={{...dataSource, total: total}}
                onClose={()=>{
                    console.log('onClose')
                    onUpgradeAction()
                }}
                onAction={(count)=>{
                    console.log(count)
                    megreUpgradeing(count)
                }}
            />
        </Fragment>
    )
}

export default CartoonCollection;

// Item
const CartoonItem = (props) => {
    const navigate = useNavigate();
    const { item={
        collectTime: "2022-08-25 14:26:14",
        collectionGroup: null,
        collectionId: 6,
        collectionName: "《2022人工智能大会系列》-世界人工智能大会·绿地纪念款",
        collectionSource: null,
        collectionType: null,
        createdAt: null,
        currentTime: null,
        deletedAt: null,
        id: 9901,
        nftHash: "1bbdec37-cd29-4cbc-bdce-2866e51c4f70",
        nftImage: "https://gu-nft-test1.obs.cn-east-3.myhuaweicloud.com/uploads/2022-05-31/1724c36e43de494ab2801b0b86dd6f6d.png",
        nftNo: "B000377",
        orderId: null,
        sender: null,
        seriesName: "《2022人工智能大会系列》",
        state: null,
        syncStatus: null,
        transactionHash: null,
        transferId: null,
        transferStatus: 1,
        transferTime: null,
        upChainState: null,
        updateTime: null,
        updatedAt: null,
    } } = props
    //跳转藏品详情
    const onClick = ()=> {
        if(item.transferStatus == 3) {
            receiveDonatin()
        } else {
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/myCollectionDetail?nft_hash=${item.nftHash}&state=${item.transferStatus}`});
            } else {
                navigate(`/myCollectionDetail?nft_hash=${item.nftHash}&state=${item.transferStatus}`);
            }
        }

    }

    //待接收
    const receiveDonatin = () => {
        if (window.JsBridge.hasWebViewBridge()) {
          window.JsBridge.push("Web", { uri: `${window.location.origin}/receiveDonation?transferId=${item.transferId}` });
        } else {
          navigate(`/receiveDonation?transferId=${item.transferId}`);
        }
      }

    return (
        <div className="CartoonItem" onClick={onClick}>
            <div className="img-box">
                <img src={item.nftImage} alt=''/>
                {
                    /* transferStatus: 2-转赠中, 3-待接收 */
                   [2,3].includes(item.transferStatus) &&
                    <div className="mask">
                        <span>{item.transferStatus == 2 ? '转赠中' : '待接收'}</span>
                    </div>
                }
            </div>
            <div className="info-box">
                {
                    item.transferStatus == 3 ?
                    <Fragment>
                        <span className="name">
                         来自“{item.sender}”的转赠
                        </span>
                        <span className="date">
                            {item.transferTime?.slice(0, 10)}
                        </span>
                        <span className="date">
                            {`藏品编号：${item.nftNo || ''}`}
                        </span>
                    </Fragment>:
                    <Fragment>
                        <span className="name">
                            {item.collectionName}
                        </span>
                        <span className="date">
                            {`收藏于 ${item.collectTime?.slice(0, 10)}`}
                        </span>
                    </Fragment>
                }
            </div>
        </div>
    )
}
