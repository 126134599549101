/*
    底部购买操作
*/

import React, {useState, useMemo} from "react";
import {useNavigate} from "react-router-dom"
import {Button, Toast, Checkbox} from "antd-mobile"
import moment from "moment"
import {round} from "@/utils/publicFun"
import qs from 'query-string';
import "./index.scss";
import OpenAppFooter from "@/components/OpenAppFooter";
import JsBridgeNew from "@/utils/jsbridgeNew";

const kAgreementTitle = '《用户协议》'

const BottomBuy = (props) => {
    const navigate = useNavigate();
    const {dataSouce = {}} = props
    const [userAgree, setUserAgree] = useState(false);
    const [popVisable, setPopVisable] = useState(false);

    //购买操作
    const onClick = () => {
        if (userAgree === true) {
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.verifyRealName("请先实名认证").then(function (res) {
                    if (res?.result) {
                        gotoSalePay();
                    }
                })
            } else {
                setPopVisable(!popVisable);
            }
        } else {
            Toast.show(`请先勾选${kAgreementTitle}`)
        }
    }

    const gotoSalePay = async () => {
        const params = {
            activityCode: 'CARBONPOINT',
            collectionId: dataSouce.collectionId,
            points: dataSouce.carbonPoints,
            saleBatchId: dataSouce.saleBatchInfo?.saleBatchId || '',
        }

        try {
            const res = await JsBridgeNew.jKTServicesRequest({
                moduleName: 'MainPage',
                code: 600860,
                params: params
            });
            if (res.status > 0) {
                window.JsBridge.replace("Web", { uri: `${window.location.origin}/exchangeSucc` });
            } else {
                Toast.show('请求失败')
            }
        } catch (error) {
            Toast.show(error || '请求失败')
        }

    }

    const goAgreement = () => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push("Web", {uri: `${window.location.origin}/MyAgreement`})
        } else {
            navigate(`/MyAgreement`)
        }
    }

    const btnStatus = useMemo(() => {
        let status = {
            text: '已售罄',
            disabled: true
        }

        if (dataSouce.saleBatchInfo && Object.values(dataSouce).length > 0) {
            const start = new Date(dataSouce.saleBatchInfo.saleStartTime.replace(/-/g, "/")).getTime();
            const end = new Date(dataSouce.saleBatchInfo.saleEndTime.replace(/-/g, "/")).getTime();
            let time = new Date().getTime();

            if (start && time < start) {
                status.disabled = true
                status.text = moment(new Date(start)).format("MM.DD HH:mm") + "开售"

            } else if (dataSouce.saleBatchInfo.stock > 0) {
                if (end && time > end) {
                    // 发行结束
                } else {
                    status.disabled = false
                    status.text = '立即兑换'
                }
            }
        }
        return status;
    }, [dataSouce])

    return (
        <div className="BottomBuy">
            <div className="agreement_select">
                <Checkbox
                    defaultChecked={userAgree}
                    onChange={(checked) => setUserAgree(checked)}
                    style={{
                        "--icon-size": "12px",
                        "--font-size": "12px",
                        "--gap": "5px",
                    }}
                >我已阅读并同意</Checkbox>
                <span onClick={goAgreement}>{kAgreementTitle}</span>
            </div>
            <div className="buy-info">
                <div className="left">
                    <div className="price-detail">
                        <img src={require('@/assets/img/icon_tjf.png')} alt=""/>
                        <span>{dataSouce.carbonPoints/1000}kg</span>
                    </div>
                </div>
                <div className="right">
                    <Button block color="primary" shape='rounded' disabled={btnStatus.disabled} size="large"
                            onClick={onClick}>
                        {btnStatus.text}
                    </Button>
                </div>
            </div>
            <OpenAppFooter visible={popVisable} onClose={() => setPopVisable(false)}/>
        </div>
    )
}

export default BottomBuy;
