import React, {useState, useRef, useEffect} from 'react';
import styles from "./styles.module.scss";
import {hideLoading, showLoading} from "@/components/loading";
import {queryDefCode} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";


const Rule = (props) => { //规则
const [activityRules,setActivityRules]= useState('')
    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        try {
            showLoading();
            const res = await queryDefCode({codeType: 'activityRule', code: 'metaRecovery24_0116Rule'});
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            const {data} = res;
            if (data.result && data.result.defCode) {
                const otherSign = data.result.defCode.otherSign;
                setActivityRules(otherSign);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }
    return (
        <div className={styles.huiContainer}>
            <div className={styles.content}>
                <p>{activityRules}</p>
            </div>
        </div>
    )
}
export default Rule;
