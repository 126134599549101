/*
    绿地Ape World数字藏品
*/

import React, { useState, useEffect, useRef } from "react";
import "./index.scss";

const WordWhatCard = (props)=> {
    const {dataSouce={}} = props


    return (
        <div className="WordWhatCard">
            <span>{ dataSouce.title || '绿地环保主题数字藏品'}</span>
            <div className="ape_world_content">
                <div className="content_display">
                    <img src={require('@/assets/img/img_11.png')} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default WordWhatCard;
