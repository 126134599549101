import React, {useEffect, useState, useRef} from "react"
import {Mask, Toast} from "antd-mobile"
import html2canvas from "html2canvas"
import "./shopShare.scss";
import {QRCodeSVG} from "qrcode.react"
import {CopyToClipboard} from "react-copy-to-clipboard";
import {isEmptyString, throttle} from "@/utils/publicFun";
import {hideLoading, showLoading} from "@/components/loading";
import {getShareInfo, shopConvertRecord} from "@/api/projectManager_self";

let icon_share_copy = require("@/assets/img/icon_share_copy.png")
let icon_share_down = require("@/assets/img/icon_share_down.png")
let icon_share_pyp = require("@/assets/img/icon_share_pyp.png")
let icon_share_wechat = require("@/assets/img/icon_share_wechat_1.jpg")
let bgImage = require("@/assets/img/shop_pic_fenxiang.png")


const JsBridge = window.JsBridge;

const ShopShare = ({maskVisible, closeMask, userInfo}) => {
    const domRef = useRef(null)
    const activityCode = 'APEW1111';
    const linkUrlTem = `${process.env.REACT_APP_GKT_LINK}/front/inviter/registerShop/${'123456'}?activityCode=${activityCode}`;
    const [linkUrl, setLinkUrl] = useState(linkUrlTem);


    useEffect(() => {
        maskVisible && getInviteCode();
    }, [maskVisible])

    const getInviteCode = async () => { ///customer/purchase/v1/getShareConfig
        if (userInfo && userInfo.isLogin) {
            try {
                showLoading();
                const res = await getShareInfo({});
                hideLoading();
                if (res.code !== '000000') throw res.msg;
                if (res.data && res.data.code){
                    let interverCode = res.data.code;
                    const linkUrlTem1 = `${process.env.REACT_APP_GKT_LINK}/front/inviter/registerShop/${interverCode}?activityCode=${activityCode}`;
                    setLinkUrl(linkUrlTem1);
                }
            } catch (error) {
                hideLoading();
                Toast.show(error);
            }
        }
    }

    const createShareImage = () => {
        return new Promise(async (resolve) => {
            html2canvas(domRef.current, {
                allowTaint: true,
                useCORS: true,
                scrollX: 0,
                scrollY: 0
            }).then(async (canvas) => {
                const result = canvas.toDataURL('image/jpeg', 0.8);
                resolve(result);
            }).catch(() => {
                resolve(false);
            });
        })
    }
    // 生成分享图片
    const getShareImagePath = () => {
        return new Promise(async (resolve) => {
            if (!domRef.current) {
                resolve(false);
            }
            let base64 = await createShareImage();
            if (base64) {
                let res = await JsBridge.saveImgBase64(base64).catch(ex => false);
                if (!res) {
                    JsBridge.runAction('alert', 'error', ['图片保存到本地失败']);
                    resolve(false);
                } else {
                    resolve(res.tempFilePath);
                }
            } else {
                resolve(false);
            }
        })
    }

    // 微信分享
    const wxShare = throttle(async () => {
        let shareToast = Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        if (window.JsBridge.hasWebViewBridge()) {
            let filePath = await getShareImagePath();
            window.JsBridge.share({ //    const { inviteCode='', activityCode='' } = Util.url.paramsToObj();
                text: '',
                icon: filePath,
                link: '',
                title: '',
                platform: 1,
            }).then(function (result) {
                shareToast && shareToast.close()
                Toast.show(result.message)
            })
        }
    }, 3000)
    // 微信朋友圈分享
    const momentsShare = throttle(async () => {
        let shareToast = Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        if (window.JsBridge.hasWebViewBridge()) {
            let filePath = await getShareImagePath();
            window.JsBridge.share({
                text: '',
                icon: filePath,
                link: '',
                title: '',
                platform: 2,
            }).then(function (result) {
                shareToast && shareToast.close()
                Toast.show(result.message)
            })
        }
    }, 3000)

    // 将dom 转换为 base64
    const createCanvas = (dom) => {
        return new Promise((resolve, reject) => {
            html2canvas(domRef.current, {
                allowTaint: true,
                useCORS: true,
                scrollX: 0,
                scrollY: 0
            }).then(async (canvas) => {
                const result = canvas.toDataURL('image/jpeg', 0.8);
                resolve(result);
            }).catch((err) => {
                reject(`生成海报:${err})`)
            });
        })
    }

    // 下载图片
    const downloadImg = throttle(() => {
        // document.body.scrollTop = document.documentElement.scrollTop = 0;
        let toast = Toast.show({icon: "loading", content: "保存中..."})
        createCanvas(domRef.current).then((imgData) => {
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.saveImgBase64(imgData).then(function (result) {
                    toast.close()
                    window.JsBridge.requestPermission("requestAlbum")
                    window.JsBridge.saveImageToPhotosAlbum(result.tempFilePath).then(function (res) {
                        if (res) {
                            Toast.show("图片保存成功")
                        } else {
                            Toast.show("图片保存失败")
                        }
                    })
                })
            } else {
                let a = document.createElement("a")
                document.body.appendChild(a)
                a.download = `image-${new Date().getTime()}`
                a.href = imgData
                a.click()
                document.body.removeChild(a)
            }
        })
    }, 3000)


    return (
        <Mask visible={maskVisible} onMaskClick={closeMask} opacity={0.8} style={{
            overflow: 'auto',
            paddingBottom: '10px'
        }}>
            <div className={'shop_stage_share_container'}>
                <div className={'shop_share_body'}>
                    <div className={'shop_share_content'} ref={domRef}>
                        <div className={'shop_share_avatar'}>
                            <img src={bgImage}/>
                        </div>
                        <div className={'shop_share_detail'}>
                            <QRCodeSVG
                                value={linkUrl}
                            />
                        </div>
                    </div>
                </div>
                <div className={'opt'}>
                    <div className={'wechat'} onClick={wxShare}>
                        <img src={icon_share_wechat} alt=""/>
                        <span>微信好友</span>
                    </div>
                    <div className={'share'} onClick={momentsShare}>
                        <img src={icon_share_pyp} alt=""/>
                        <span>朋友圈</span>
                    </div>

                    <CopyToClipboard text={linkUrl}
                                     onCopy={() => {
                                         Toast.show('复制成功')
                                     }}>
                        <div className={'copy'}>
                            <img src={icon_share_copy} alt=""/>
                            <span>复制链接</span>
                        </div>
                    </CopyToClipboard>
                    <div className={'download'} onClick={downloadImg}>
                        <img src={icon_share_down} alt=""/>
                        <span>下载图片</span>
                    </div>
                </div>
                <div className={'cancel_btn'} onClick={closeMask}>
                    <span>取消</span>
                </div>
            </div>
        </Mask>
    )
}

export default ShopShare
