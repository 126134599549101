import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import { Tabs, Toast } from "antd-mobile";
import { showLoading, hideLoading } from '@/components/loading';
import { queryTransferRecord } from '@/api/projectManager_self';
import ListView from "@/components/ListView";
import RecordItem from './components';
import NFTHeader from '@/components/NFTHeader';
// import mockFn from './mock';

const tabs = [
  { key: "0", title: "转出" },
  { key: "1", title: "转入" },
];

const TransferRecord = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const pageSize = useRef(10);
  const currentPage = useRef(1);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.JsBridge.getStatusBarHeight().then(({height}) => {
      setHeight(height);
    });
    getRecordList(tabs[activeIndex].key);
  }, [activeIndex]);

  const getRecordList = async (type) => {
    try {
      showLoading();
      currentPage.current = 1;
      const params = {
        page: currentPage.current,
        pageSize: pageSize.current,
        type
      }
      const { code, data, msg } = await queryTransferRecord(params);
      // const {code, data, msg} = await mockFn(type);
      hideLoading();
      if(code !== '000000') throw msg;
      setList(data.list || []);
      setHasMore(data.total > data.list?.length);
    } catch (error) {
      hideLoading();
      Toast.show(error || '系统异常，请稍后再试！');
    }
  }

  const loadMore = async () => {
    try {
      currentPage.current = currentPage.current + 1;
      const params = {
        type: tabs[activeIndex].key,
        page: currentPage.current,
        pageSize: pageSize.current
      }
      const { code, data, msg } = await queryTransferRecord(params);
      // const {code, data, msg} = await mockFn(tabs[activeIndex].key);
      if(code !== '000000') throw msg;
      setList([...list, ...data.list]);
      setHasMore(data.total > [...list, ...data.list].length)
    } catch (error) {
      console.log(error);
    }
  }

  return <div className="transfer-record">
    <NFTHeader midText="转赠记录" bgColor="#F1F0F5"/>
    <div className="tabs" style={{top: 25 + height}}>
      <Tabs
        activeKey={tabs[activeIndex].key}
        onChange={(key) => {
          const index = tabs.findIndex((item) => item.key === key);
          setActiveIndex(index);
        }}
      >
        {tabs.map((item) => (
          <Tabs.Tab title={<div>{item.title}</div>} key={item.key}></Tabs.Tab>
        ))}
      </Tabs>
    </div>
    <div className="record-list">
      <ListView 
        onRefresh={() => getRecordList(tabs[activeIndex].key)} 
        list={list} 
        hasMore={hasMore} 
        loadMore={loadMore} 
        ListItem={RecordItem} 
        maxDay={tabs[activeIndex].key}
        style={{paddingTop: 100}}
      />
    </div>
  </div>
}

export default TransferRecord;