/**
 * 优惠券选择弹窗
 *
 * **/
import { useState } from 'react'
import { Popup } from 'antd-mobile'
import CouponItem from '@/view/myCoupon/CouponItem'
import Empty from '@/components/empty'
import styles from './styles.module.scss'
import clickThrottle from "@/utils/clickThrottle";

export default ({
  children,
  couponList = [],
  value = 1,
  onChange = () => null,
}) => {
  const [visible, setVisible] = useState(false)
  const onClose = () => setVisible(false)

    const onChangeValue = (checked,item)=>{
      if (!clickThrottle(2000)){return;}
        onChange(checked ? {} : item);
       let timer = setTimeout(()=>{
           clearTimeout(timer);
            onClose();
        },500);
    }

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Popup
        visible={visible}
        showCloseButton
        onClose={onClose}
        onMaskClick={onClose}
        className={styles.popup}
      >
        <div className={styles.title}>我的券包</div>
        <div className={styles.content}>
          {
            couponList.length === 0 ?
            <Empty />
            :
            couponList.map((item, index) => {
              const checked = value === item.couponCode
              return (
                <CouponItem
                  key={index}
                  name={item.couponName}
                  amount={item.discount}
                  checkable
                  checked={checked}
                  onClick={() => onChangeValue(checked,item)}
                />
              )
            })
          }
          <div style={{ height: '2rem' }} />
        </div>
      </Popup>
    </>
  )
}
