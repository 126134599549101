import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styles from './realName.module.scss'
import { Mask } from 'antd-mobile'

const RealName = ({ onClose, confirmCB }) => {
  const [visible, setVisible] = useState(true)

  const closeMask = () => {
    setVisible(false)
    onClose && onClose()
  }

  const onConfirm = () => {
    setVisible(false)
    onClose && onClose()
    confirmCB && confirmCB()
  }


  return <Mask
    className={styles.real_name_mask}
    visible={visible}
  >
    <div className={styles.real_name_container}>
      <div className={styles.header}>
        <span className={styles.title}>实名认证</span>
      </div>
      <div className={styles.confirm_tip}>
        <span>购买土地需完成实名认证</span>
      </div>
      <div className={styles.btn_box}>
        <div className={`${styles.cancel_btn} ${styles.common_btn}`} onClick={closeMask}>取消</div>
        <div className={`${styles.confirm_btn} ${styles.common_btn}`} onClick={onConfirm}>实名认证</div>
      </div>
    </div>
  </Mask>
}

RealName.open = ({confirmCB}) => {
  let div = null;
  if (!div) {
      div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
      div.remove();
  }
  ReactDOM.render(<RealName onClose={close} confirmCB={confirmCB}/>, div);
}

export default RealName