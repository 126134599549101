/**
 * @description 仿APP订单支付界面
 * @param { expired, orderNo, price } param
 * @returns {/ape/DefaultPay}
*/
import React, {useEffect, useRef, useReducer} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Button, Toast} from "antd-mobile";
import moment from "moment";
import { hideLoading, showLoading } from '@/components/loading';
import NFTHeader from "@/components/NFTHeader";
import ModalAlert from "@/components/ModalAlert";
import {getUrlAllParams} from "@/utils/publicFun";
import { payAction } from "./paySevice";
import { 
    iapValidatePayload,
} from '@/api/projectManager_self';
import appService from '@/utils/appService';
import PayTypeSelect from '../PayPage/component/PayTypeSelect';
import Styles from "./index.module.scss";

const PayObjct = {
    ALIPAY: 1,
    WXPAY: 2,
    IOS_IAP: 3
}

const DefaultPay = ()=> {
    const navigate = useNavigate();
    
    //获取参数
    const {search} = useLocation();
    const param = getUrlAllParams(search.slice(1));
    const {expired, orderNo, price=0} = param;

    const pageDom = useRef(null);

    const [state, dispath] = useReducer((state,action)=> {
        //数据处理
        if(JSON.stringify(state) != JSON.stringify(action)) {
            return {
                ...state,
                ...action
            }
        }
        return state
    }, {
        // 设置state初始值
        payItem: {payType: ''},
    });

    useEffect(()=>{
       
    },[])

    const onPay = async ()=> {
        if (!state.payItem?.payType) {
            return Toast.show("请选择支付方式")
        }
        const prePayParams = {
            payType: PayObjct[state.payItem?.payType],
        }
        if (window.JsBridge.hasWebViewBridge()){
            const res = await getOrderPrePayParams(prePayParams);
            if (res) {
                try {
                    const payResult = await payAction(state.payItem?.payType, res?.payParam);
                    if(state.payItem?.payType === 'IOS_IAP') {
                        getIapPayResult(payResult);
                    }else {
                        payCallback(payResult);
                    }
                } catch (error) {
                    Toast.show(error);
                }
            }
        }else {
            Toast.show("请到APP中支付")
        }
    }

    const payCallback = (params)=>{
        const uri = `/boxToStone/paySuccess?orderNo=${orderNo}`
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.replace("CustomWeb", { uri: `${window.location.origin}${uri}` })
        } else {
            navigate(uri, {replace: true});
        }
    }

    const getOrderPrePayParams = async (params) => {
        try {
            const userInfo = await window.JsBridge.getCurrentUserInfo();
            showLoading();
            const res = await appService('600879',{
                payType: params.payType,
                userCode: userInfo?.loginName,
                orderNo: orderNo || ''
            });
            hideLoading();
            if (!res) throw res.msg;
            return res.data;
        } catch (error) {
            hideLoading();
            error && Toast.show(error);
            return false;
        }
    }
    //获取内购的验证结果
    const getIapPayResult = (params) => { 
        try {
            showLoading();
            let timeId = setTimeout(async () => {
                const {code, msg, data} = await iapValidatePayload(params);
                hideLoading();
                if (code === '000000') {
                    if (data) {
                        // gotoSalePaySuccess(data);
                    } else {
                        // getPayResult({outTradeNo: params?.outTradeNo});
                    }
                } else {
                    Toast.show(msg);
                }
                timeId && clearTimeout(timeId);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    }

    const onBackClick = ()=> {
        const content = ()=>{
            const onAction = (key)=> {
                if(key === 'confirm') {
                    ModalAlert.hide();
                    if (window.JsBridge.hasWebViewBridge()) {
                        window.JsBridge.back();
                    } else {
                        navigate(-1);
                    }
                }else {
                    ModalAlert.hide();
                }
            }
            const btns = (
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 20}}>
                    <div style={{fontSize: 15, color: '#CAA846', padding: '10px 20px', border: '1px solid #CAA846', borderRadius: 30, marginRight: 15}} onClick={()=> onAction('cancel')}>继续支付</div>
                    <div style={{fontSize: 15, color: '#614D1A', padding: '10px 20px', borderRadius: 30, background: 'linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%)'}} onClick={()=> onAction('confirm')}>确认离开</div>
                </div>
            )
            if(expired) {
                const seconds = (expired - new Date().getTime()) / 1000;
                const hours = Math.floor(seconds / 3600);
                const minute = Math.floor((seconds % 3600) / 60);
                if(seconds <= 0) {
                    return btns
                }
                return (
                    <div>
                        <div style={{padding: '10px 0', textAlign:'center'}}>
                        {`您的订单在${hours > 0 ? `${hours}小时` : ''}${minute > 0 ? `${minute}分钟内未支付` : ''}将被取消，请尽快完成支付？`}
                        </div>
                        {btns}
                    </div>
                )
            }else {
                return btns;
            }
        }
        ModalAlert.show({
            getContainer: pageDom.current,
            title: '是否放弃本次付款？',
            content: content(),
        })
    }

    const payPrice = Number(price ?? 0).toFixed(2)

    return (
        <div className={Styles.default_pay} ref={pageDom}>
            <NFTHeader midText={`订单支付`} bgColor={'#FFFFFF'} leftClick={onBackClick}/>
            <div className={Styles.container}>
                {
                    expired ?
                    <div className={Styles.remind}>
                        <img className={Styles.remindIcon} src={require('@/assets/img/icon/remind.png')} alt="" />
                        <span>{`请在 ${moment(+expired).format('YYYY-MM-DD HH:mm:ss')} 前完成支付，否则将会自动取消`}</span>
                    </div>: null
                }
                
                <div className={Styles.priceStyle}>
                    <span>￥</span>
                    {payPrice.split('.')[0]}
                    {
                        payPrice.split('.').length > 1 &&
                        <span>.{payPrice.split('.')[1]}</span>
                    }
                </div>
                <PayTypeSelect onChange={(payItem)=>dispath({payItem: payItem})}/>
            </div>
            {
                state.payItem?.payType && 
                <div className={Styles.absButtom}>
                    <Button className={Styles.payBtn} onClick={onPay}>
                        {`${state.payItem.payName}¥${payPrice}`}
                    </Button>
                </div>
            }
            
        </div>
    )
}

export default DefaultPay;