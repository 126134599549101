/*
    编号、可信记录、存证等标志
*/

import React, { useState, useEffect, useRef } from "react";
import "./index.scss";

const MarkingCard = (props) => {

    const {dataSouce={}} = props

    return (
        <div className="MarkingCard">
            <div className="content_img_center">
                <li>
                    <img src={require("@/assets/img/homedetail/Group2.png")} alt="" />
                    <p>唯一编号</p>
                </li>
                <li>
                    <img src={require("@/assets/img/homedetail/Group1.png")} alt="" />
                    <p>可信纪录</p>
                </li>
                <li>
                    <img src={require("@/assets/img/homedetail/Group3.png")} alt="" />
                    <p>永久存证</p>
                </li>
                <li>
                    <img src={require("@/assets/img/homedetail/Group4.png")} alt="" />
                    <p>不可修改</p>
                </li>
            </div>
        </div>
    )
}

export default MarkingCard;