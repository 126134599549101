import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import styles from "./styles.module.scss";
import ListView from "@/components/AntListView";
import { Toast } from "antd-mobile";
import { ableRentalCollList } from "@/api/guland";
import { hideLoading, showLoading } from "@/components/loading";

import _ from "lodash";
const SelectList = forwardRef(
  ({ type = 1, filterData = {}, onChange }, ref) => {
    const [currentSelect, setCurrentSelect] = useState([]);
    const [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(20);
    useEffect(() => {
      getList(true);
    }, [filterData?.value]);

    const getList = async (isRefresh, loading = true) => {
      try {
        loading && showLoading();
        if (isRefresh) {
          currentPage.current = 1;
        } else {
          currentPage.current = currentPage.current + 1;
        }
        const params = {
          currentPage: currentPage.current,
          pageSize: pageSize.current,
          collectionType: type,
          blockLevel:
            type === 2 ? Number(filterData?.value) || undefined : undefined,
          monkeyType:
            type === 1 ? Number(filterData?.value) || undefined : undefined,
        };
        const res = await ableRentalCollList(params);
        loading && hideLoading();
        if (res.code !== "000000") throw res.msg;
        if (currentPage.current === 1) {
          setList(res?.data?.list || []);
        } else {
          setList([...list, ...res?.data?.list]);
        }
        isRefresh && setIsLoad(true);
        setHasMore(res?.data?.list?.length >= pageSize.current);
      } catch (error) {
        console.log(error);
        Toast.show(error);
      }
    };
    const onSelect = (item) => {
      let arr = _.cloneDeep(currentSelect);
      let newArr = _.cloneDeep(currentSelect);
      const index = _.findIndex(arr, function (o) {
        return o?.nftNo == item?.nftNo;
      });

      if (index !== -1) {
        newArr = _.remove(arr, function (n) {
          return n?.nftNo !== item?.nftNo;
        });
      } else {
        newArr.push(item);
      }
      if(newArr?.length >50){
        Toast.show('最多选择50个')
        return
      }
      setCurrentSelect(newArr);
      onChange && onChange(newArr);
    };

    useImperativeHandle(ref, () => ({
      refresh:()=>{
        currentPage.current = 1
        setCurrentSelect([])
        onChange && onChange([]);
        setHasMore(false)
        setIsLoad(false)
        getList(true)
      }
    }));

    return (
      <div className={styles.list_out}>
        {
          <ListView
          // onEndReachedThreshold={20}
            dataSource={list}
            hasMore={hasMore}
            style={{ paddingTop: 20 }}
            renderItem={(item, index) => {
              let isActive = false;
              try {
                currentSelect.forEach((_item) => {
                  if (_item?.nftNo === item?.nftNo) {
                    isActive = true;
                    throw "";
                  }
                });
              } catch (error) {}
              return (
                <div
                  key={item?.nftNo}
                  className={`${styles.list_item} ${
                    isActive ? styles.actived : ""
                  }`}
                  onClick={() => {
                    onSelect(item);
                  }}
                >
                  <div className={styles.item_img}>
                    <img src={item?.nftImage} alt="" />
                  </div>
                  <div  className={styles.item_nftNo}>{item?.nftNo}</div>
                </div>
              );
            }}
            onEndReached={async () => {
              await getList(false, false);
            }}
            isLoad={isLoad}
            customStyle={{ imgStyle: { width: 150, height: 150 } }}
          />
        }
      </div>
    );
  }
);

export default SelectList;
