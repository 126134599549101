/**
 * @description 升级兑换界面
 * @param {}
 * @returns { /apeWorld/partsBlindbox/up-exchange }
*/
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Toast } from 'antd-mobile';
import classNames from 'classnames';
import ModalAlert from "@/components/ModalAlert";
import qs from 'query-string';
import NFTHeader from '@/components/NFTHeader';
import ListView from '@/components/AntListView';
import { hideLoading, showLoading } from "@/components/loading";
import Service from '../service';
import Style from './index.module.scss';


const Tabs = [
	{ label: '普通配件', value: '0', remain: 0, limit: 30 },
	{ label: '稀有配件', value: '1', remain: 1, limit: 6 },
]

const RatioLimt = 3;

const UpExchange = () => {
	
	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState(Tabs[0]);
	const [dataSource, setDataSource] = useState([]);
	const [remain, setRemain] = useState({});
	const [selectArray, setSelectArray] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [isLoad, setIsLoad] = useState(false);
	const currentRef = useRef()
	const currentPage = useRef(1);
	const pageSize = useRef(20);

	useEffect(() => {
		loadData(true);
	}, [currentTab.value])

	const loadData = async (isLoading = false) => {
		await refreshData(isLoading);
	    await queryRemainCount();
	}

	const queryRemainCount = async ()=> {
		try {
			const res = await Service.getRemainCount({type: currentTab.remain});
			if (res.code !== '000000') throw res.msg;
			console.log(res.data);
			setRemain(res.data ?? {})
		} catch (error) {
			
		}
		
			
	}

	const refreshData = async (isLoading = false) => {
		const params = {
			currentPage: currentPage.current,
			pageSize: pageSize.current,
			bizId: currentTab.value,
		}
		try {
			isLoading && showLoading();
			const res = await Service.getRecoveryList(params);
			isLoading && hideLoading();
			if (res.code !== '000000') throw res.msg;
			const newList = res.data.list || [];
			if (currentPage.current === 1) {
				setDataSource(newList);
			} else {
				setDataSource([...dataSource, ...newList]);
			}
			setIsLoad(true)
			setHasMore(newList.length >= pageSize.current);
		} catch (error) {
			isLoading && hideLoading();
			setIsLoad(true);
			Toast.show(error);
		}

	}

	const loadMore = async () => {
		currentPage.current = currentPage.current + 1
		await refreshData()
	}

	const onRightClick = () => {
		const uri = '/apeWorld/partsBlindbox/exchangeHistory'
		if (window.JsBridge.hasWebViewBridge()) {
			window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
		} else {
			navigate(uri);
		}
	}

	const onComit = () => {
        if(selectArray.length < 1) return;
		ModalAlert.show({
			getContainer:currentRef.current,
            maskStyle:{background: `rgba(0, 0, 0, 0.6)`},
			title: '兑换盲盒',
			content: '确认将选中藏品兑换为盲盒吗',
			onAction: async (item, index) => {
				if(item.key == 'confirm') {
				  await exchangeBlindbox();
				}
				ModalAlert.hide();
			},
			actions: [
				{key: 'cancel',text: '取消'},
				{key: 'confirm',text: '确认'}
			]
		})
        
    }

	const exchangeBlindbox = async () => {
		try {
			showLoading();
			const nftNoList = selectArray.map((i) => i.nftNo);
			const res = await Service.exchangeBlindbox({
				type: currentTab.remain,
				bizId: currentTab.value,
				nftNoList: nftNoList
			});
			hideLoading();
			if (res.code !== '000000') throw res.msg;
			currentPage.current = 1;
			setSelectArray([]);
			
			Toast.show({
				icon: 'success',
				content: res.msg ?? '兑换成功',
				duration: 1000,
				afterClose: () => {
					loadData(true);
					// 对后端接口一个字服了
					const list = res.data?.userCollectionRespList ?? [];
					const collectionList = list.map(item=> {
						return {
							nftNo: item.elementId,
							collectionName: '绿地数字藏品',
							image: item.url,
							collectionId: item.collectionId
						}
					})
					const url = '/ape/PaySuccess'
					navigate(url, { 
						state: {
							collectionList: collectionList, 
							from: Service.activityCode,
							title: '兑换成功',
							showNav: 1,
						}
					});
				}
			});
		} catch (error) {
			hideLoading();
			Toast.show(error);
		}
	    
	}

	const onTabChange = (item) => {
		if (item.value === currentTab.value) return;
		currentPage.current = 1;
		setSelectArray([]);
		setCurrentTab(item);
	}

	const onSelectItem = (item) => {
		const currentT = new Date(remain.systemDate?.replace(/-/g, "/")).getTime();
        const endT = new Date(remain.endTime?.replace(/-/g, "/")).getTime();
		// 1. 活动是否结束
		if(currentT > endT) return;
		if(currentTab.value === '1' && !item.select) {
			if(remain.remainCount < RatioLimt) {
				return;
			}else if((selectArray.length + 1) * RatioLimt > remain.remainCount) {
				Toast.show('可回收次数不足');
				return;
			}
			// 单次最多选择6个
			if(selectArray.length + 1 > currentTab.limit) {
				return;
			}
		}else if(currentTab.value === '0' && !item.select) {
			if(remain.remainCount === 0) {
				return;
			}else if(selectArray.length + 1 >  remain.remainCount * RatioLimt) {
				Toast.show('可回收次数不足');
				return;
			}
			// 单次最多选择30个
			if(selectArray.length + 1 > currentTab.limit) {
				return;
			}
		}
		
		item.select = !item.select;
		if (item.select) {
			setSelectArray([...selectArray, item]);
		} else {
			const selects = selectArray.filter(item => item.select);
			setSelectArray(selects);
		}

	}

	const renderItem = (item) => {
		return (
			<div className={Style.collection_item}>
				<div className={Style.item_content} onClick={() => onSelectItem(item)}>
					<div className={classNames(Style.item_img, {
						[Style.active]: item.select,
					})}
					>
						<img src={item.nftImage} alt="" />
					</div>
					<span>#{item.nftNo}</span>
					{
						item.select ?
						<img className={Style.select_icon} src={require('@/assets/img/collectionRecoveryLand/select.png')} alt="" />
						: null
					}

				</div>
			</div>
		)
	}

	const btnStatusInfo = useMemo(() => {
		const btnInfo = {
			text: '活动已结束',
			subText: '',
			enabled: false,
		}
		const currentT = new Date(remain.systemDate?.replace(/-/g, "/")).getTime();
        const endT = new Date(remain.endTime?.replace(/-/g, "/")).getTime();
		// 1. 活动是否结束
		if(currentT > endT) {
			btnInfo.text = '活动已结束';
		}else if(currentTab.value === '1') {
			//2. 稀有配件
			if(remain.remainCount < RatioLimt) {
				// 2. 是否已兑完
				btnInfo.text = '额度已兑完';
				
			}else if(selectArray.length >= 0) {
				// 2.1. 是否有选中
				btnInfo.text = '确认兑换';
				
				if(selectArray.length > 0) {
					btnInfo.enabled = true;
					btnInfo.subText = `(已选${selectArray.length}个，可兑${selectArray.length * RatioLimt}盲盒)`
				}else {
					btnInfo.enabled = false;
					btnInfo.subText = `(已选${selectArray.length}个，再选1个可兑${RatioLimt}个盲盒)`
				}
			}
		}else {
			// 3. 普通配件
			if(remain.remainCount === 0) {
				// 3.1. 是否已兑完
				btnInfo.text = '今日额度已兑完，明天再来吧';
				
			}else if(selectArray.length >= 0) {
				// 4. 是否有选中
				btnInfo.text = '确认兑换';
				// 4.1 是否稀有配件
				if(selectArray.length % RatioLimt == 0 && selectArray.length > 0) {
					btnInfo.enabled = true;
					btnInfo.subText = `(已选${selectArray.length}个，可兑${selectArray.length/RatioLimt}盲盒)`
				}else {
					btnInfo.enabled = false;
					btnInfo.subText = `(已选${selectArray.length}个，再选${RatioLimt - selectArray.length % RatioLimt}个可兑${Math.floor(selectArray.length / RatioLimt) + 1}个盲盒)`
				}
				
			}
		}

		return btnInfo;
	}, [selectArray, remain])

	return (
		<div className={Style.up_exchange} ref={currentRef}>
			<NFTHeader midText={`兑换`} rightText={'兑换记录'} rightClick={onRightClick} />
			<div className={Style.description}>
				<div>请选择配件</div>
				<div>{`普通配件${RatioLimt}换1  稀有配件1换${RatioLimt}`}</div>
			</div>
			<div className={Style.tabs}>
				{
					Tabs.map((item, k) => {
						return (
							<div key={k}
								className={classNames(Style.tab, { [Style.tab_active]: item.value == currentTab.value })}
								onClick={() => onTabChange(item)}
							>
								<span>{item.label}</span>
							</div>
						)
					})
				}
			</div>

			<div className={Style.up_exchange_content}>
				<ListView
					dataSource={dataSource}
					renderItem={renderItem}
					hasMore={hasMore}
					isLoad={isLoad}
					onEndReached={loadMore}
					style={{ paddingTop: 50 }}
					onEndReachedThreshold={1}
				/>
			</div>
			<div className={Style.bottom_view}>
				{
					btnStatusInfo.enabled ?
						<div className={Style.outBtn} onClick={onComit}>
							<div className={Style.inBtn}>
								<div>{btnStatusInfo.text}</div>
								<span>{btnStatusInfo.subText}</span>
							</div>
						</div> :
						<div className={Style.disOutBtn}>
							<div className={Style.disInBtn}>
								<div>{btnStatusInfo.text}</div>
								<span>{btnStatusInfo.subText}</span>
							</div>
						</div>
				}

			</div>
		</div>
	)
}

export default UpExchange