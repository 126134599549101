/**
 * @description 藏品标题信息
*/
import React from "react";
import './index.scss';

const CollectionTitle = (props) => {
    const { detailList } = props;
    return (
        <div className="collection_title">
            <div className="title">{detailList?.collectionName}</div>
            <div className="message">
                <div className="company">发行方</div>
                <div className="quantity">
                    <img src={require("@/assets/img/lvdi.png")} alt="" />
                    {detailList?.owner}
                </div>
            </div>
        </div>
    )
}

/**
 * @description 藏品编号
*/
const CollectionNo = (props)=> {
    const { detailList } = props;

    return (
        <div className={`collection_no`}>
            <div className="cell">
            <span className="collection-num">藏品编号</span>
            <span className="num">#{detailList?.nftNo}</span>
            </div>
            {!!detailList?.targetNftNo && !!detailList?.parent &&
            <div className="cell">
                <span className="collection-num">
                {detailList?.parent == 1 && '共创绑定'}
                {detailList?.parent == 2 && '关联藏品'}
                </span>
                <span className="num">#{detailList?.targetNftNo}</span>
            </div>
            }
        </div>
    )
}

export {
    CollectionTitle,
    CollectionNo
};