import React, { useState, useRef, useEffect, useContext, forwardRef,useImperativeHandle } from "react";
import classNames from "classnames";
import styles from "./selectApe.module.scss";
import { Popup, Tabs, Swiper, Toast } from "antd-mobile";
import { Title } from "../../components/common";
import tabActive from "@/assets/img/landfi/tab-active.png";
import tabActive2 from "@/assets/img/landfi/landgy/tab-active.png";
import ListView from "@/components/AntListView";
import TransteToGyModel from "./../../components/myGyLands/TransteToGyApeModel";
import MyModal from "../../jewelLand/modal";
import TransOrder from '../../components/apeOrder';

import xuanzhong from "@/assets/img/landfi/xuanzhong.png";
import { dispatchWorkApe, myApeCollections,workingPermissionMsg } from "@/api/guland";
import { hideLoading, showLoading } from "@/components/loading";
import { LandDetailContext } from "../context";
import _ from 'lodash'

const SelectApe = (props) => {
  const { mode, visible, onClose, expData, selectCB, separateNo,container } = props;
  const [currentTab, setCurrentTab] = useState(0);
  const [isChoice, setIsChoice] = useState(false);
  const [isMul, setIsMul] = useState(false);
  const [selectData, setSelectData] = useState([]);

  const [selectApeInfo, setSelectApeInfo] = useState(null);
  const swiperRef = useRef(null);

  const { landInfo } = useContext(LandDetailContext);
  const [refresh, setRefresh] = useState(false);
  const listRef = useRef(null);
  useEffect(() => {
    if (!visible) {
      setCurrentTab(0);
      setIsChoice(false);
      setIsMul(false);
      visible && setRefresh(false);
    }
  }, [visible]);
  const selectChange = (data) => {
    setSelectData(data);
  };
  const onTabClick = (i) => {
    setIsMul(false);
    setSelectData([]);
    setCurrentTab(i);
    swiperRef.current.swipeTo(i);
  };
   // 获取转换信息
   const getIndLandConvertMsg = async (monkeyType) => {
    try {
      const res = await workingPermissionMsg({monkeyType});
      if (res.code !== "000000") throw res.msg;
      return res
    } catch (error) {
      console.log(error);
      Toast.show(error);
      hideLoading();
    }
  };

  const chanegeMul = (flag)=>{
    setIsMul(flag)
  }
  const onTransLand = (apeInfo,priceInfo) => {
    const ModalInstance = MyModal.show({
      content: <TransOrder 
        mode={2}
        info={{apeInfo,priceInfo}}
        onClose={() => ModalInstance?.close()} 
        paySuccessCB={() => {
          setIsMul(false);
          setSelectData([]);
          listRef.current.getList()
        }}
      />,
      getContainer: container,
    })
  }

  const getNftNo = ()=>{
    let arr = []
    selectData.forEach((item,index)=>{
      arr.push(item?.nftNo)
    })
    return arr
  }
    // 打工猴权限
    const onTransteToGy = async () => {
      const nftNoArr = getNftNo()
      const apeInfo = {
        collectionId:selectData[0]?.collectionId,
        monkeyType:expData[currentTab]?.monkeyType,
        nftNo:nftNoArr
      }
      const res = await getIndLandConvertMsg(expData[currentTab]?.monkeyType)
      const priceInfo = res?.data?.indLandConf || []
      priceInfo[0].myPoint = res?.data?.myPoint
      priceInfo[0].myGems = res?.data?.myGems
      priceInfo[0].defRepeatStr = res?.data?.defRepeatStr

      if(priceInfo[0]?.payType === 0){
        onTransLand(apeInfo,priceInfo)
        return
      }
      const content = (
        <TransteToGyModel
          container={container}
          type={2}
          mode={2}
          data={{apeInfo,priceInfo}}
          onClose={() => ModalInstance?.close()}
          successCB={() => {      
            setIsMul(false);
            setSelectData([]);
            listRef.current.getList()
          }}
        />
      );
      const ModalInstance = MyModal.show({
        content: content,
        getContainer: container,
      });
    };

    const authHandle = ()=>{
      onTransteToGy()
    }
  

  const onSelect = async (item) => {
    if (item.isLay === 1) return;
    if (item.nftNo === selectApeInfo?.nftNo) {
      setIsChoice(false);
      setSelectApeInfo(null);
    } else {
      setSelectApeInfo(item);
      setIsChoice(true);
    }
    try {
      showLoading();
      const res = await dispatchWorkApe({
        landId: landInfo.id,
        monkeyType: item.type,
        collectionId: item.collectionId || "",
        nftNo: item.nftNo || "",
        separateNo,
      });
      hideLoading();
      const { code, msg } = res;
      if (code !== "000000") throw msg;
      onClose();
      selectCB && selectCB();
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  };

  return (
    <>
      <Popup
        visible={visible}
        destroyOnClose
        position="left"
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          height: "100%",
          background: "transparent",
          padding: `${+mode === 2 ? "20" : "30"}px 0`,
        }}
      >
        <div
          className={classNames(styles.select_ape, {
            [styles.mode2]: +mode === 2,
          })}
        >
          <Title
            title="选择无聊猿"
            className={classNames(
              +mode === 2 ? styles.title_mode2 : styles.title
            )}
          />
          <div
            className={classNames(styles.close_popup, {
              [styles.mode2]: +mode === 2,
            })}
            onClick={onClose}
          ></div>
          {isMul && <div className={styles.ape_auth} onClick={()=>{authHandle()}}>开通打工权限</div>}
          <Tabs
            className={styles.tabs}
            activeKey={currentTab}
            onChange={(key) => onTabClick(Number(key))}
          >
            {expData.length > 0 &&
              expData.map((item, index) => (
                <Tabs.Tab
                  key={index}
                  title={
                    <div
                      className={classNames(styles.tab_title, {
                        [styles.tab_select]: currentTab === index,
                        [styles.mode2]: +mode === 2,
                      })}
                    >
                      <div className={styles.tab_text}>
                        {item.monkeyTypeName}
                      </div>
                      <div
                        className={classNames(styles.tab_active, {
                          [styles.mode2]: +mode === 2,
                        })}
                      >
                        {currentTab === index ? (
                          <img
                            src={+mode === 2 ? tabActive2 : tabActive}
                            alt=""
                          />
                        ) : null}
                        <span></span>
                      </div>
                    </div>
                  }
                ></Tabs.Tab>
              ))}
          </Tabs>
          {expData.length > 0 && (
            <Swiper
              direction="horizontal"
              indicator={() => null}
              ref={swiperRef}
              allowTouchMove={false}
              defaultIndex={currentTab}
            >
              {expData.map((item, index) => {
                return (
                  <Swiper.Item key={index}>
                    <ApeList
                      isMul={isMul}
                      selectData={selectData}
                      chanegeMul={chanegeMul}
                      onChange={selectChange}
                      mode={mode}
                      focusStatus={currentTab === index}
                      type={expData[currentTab]?.monkeyType}
                      isChoice={isChoice}
                      onSelect={onSelect}
                      selectApeInfo={selectApeInfo}
                      refresh={refresh}
                      ref={currentTab === index?listRef:null}
                    />
                  </Swiper.Item>
                );
              })}
            </Swiper>
          )}
        </div>
      </Popup>
    </>
  );
};

const ListItem = (props) => {
  const { item, currentSelect,mode,selectMul,onSelect, isChoice,selectApeInfo,isMul} = props;
    let isActive = false;
    try {
      currentSelect.forEach((_item) => {
        if (_item?.nftNo === item?.nftNo) {
          isActive = true;
          throw "";
        }
      });
    } catch (error) {
      console.log(error)
    }

    return (
      <div
        key={item?.nftNo}
        className={styles.list_item}
        onClick={() => {
          if (+mode === 2 && item?.workingPerm === 0) {
            // onTransteToGy(item)
            selectMul(item)
          } else {
            !isMul && onSelect(item);
          }
        }}
      >
        <div className={styles.list_item_inner}>
          {isMul && item?.workingPerm === 1  && <div className={styles.list_item_dis}></div>}
          {+mode === 2 && item?.workingPerm === 1 && <div className={styles.list_item_mark}>工业</div>}
          <div
            className={`${styles.land_img} ${
              (isChoice && selectApeInfo?.nftNo === item.nftNo) ||
              item.isLay === 1 || isActive
                ? styles.active
                : ""
            }`}
          >
            <img src={item.nftImage} alt="" />
          </div>
          <p className={styles.nftno}>#{item.nftNo}</p>
          {(isChoice && selectApeInfo?.nftNo === item.nftNo) ||
          item.isLay === 1 || isActive ? (
            <img className={styles.select_img} src={xuanzhong} alt="" />
          ) : null}
          {item.ownFlag === 1 ? <div className={styles.lease_tag}></div> : null}
        </div>
      </div>
    );
  };

const ApeList = forwardRef(({ focusStatus, type, isChoice, onSelect, selectApeInfo,mode,chanegeMul,onChange,selectData,isMul },ref) => {
  const [initStatus, setInitStatus] = useState(false);
  const [apeData, setApeData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [currentSelect, setCurrentSelect] = useState(selectData || []);

  const currentPage = useRef(1);
  const pageSize = useRef(20);

  useEffect(() => {
    if (!initStatus && focusStatus) {
      setInitStatus(true);
      getApeList();
      return;
    }
  }, [initStatus, focusStatus]);

  useEffect(() => {
   setCurrentSelect(selectData)
  }, [selectData]);

 
  useImperativeHandle(ref, () => ({
    getList:()=>{
      getApeList()
    }
  }));

  const selectMul = (item)=>{
    let arr = _.cloneDeep(currentSelect);
    let newArr = _.cloneDeep(currentSelect);
    const index = _.findIndex(arr, function (o) {
      return o?.nftNo == item?.nftNo;
    });

    if (index !== -1) {
      newArr = _.remove(arr, function (n) {
        return n?.nftNo !== item?.nftNo;
      });
    } else {
      newArr.push(item);
    }
    if(newArr?.length >50){
      Toast.show('最多选择50个')
      return
    }
    setCurrentSelect(newArr);
    if(newArr?.length>0 && item?.workingPerm === 0){
      chanegeMul(true)
    }else{
      chanegeMul(false)
    }
    onChange && onChange(newArr);
  }

  const getApeList = async (isRefresh = true) => {
    try {
      if (isRefresh) {
        currentPage.current = 1;
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        pageNum: currentPage.current,
        pageSize: pageSize.current,
        monkeyType: type,
      };
      const res = await myApeCollections(params);
      if (res.code !== "000000") throw res.msg;
      if (currentPage.current === 1) {
        setApeData(res?.data?.userCollectionRespList || []);
      } else {
        setApeData([...apeData, ...res?.data?.userCollectionRespList]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.userCollectionRespList?.length >= pageSize.current);
    } catch (error) {
      console.log(error);
      Toast.show(error);
    }
  };
  
  const listItemProps = { currentSelect,mode,selectMul,onSelect, isChoice,selectApeInfo,isMul}
  
  return (
    <div className={styles.ape_list}>
      {
      apeData?.length > 0 ? (
        <ListView
          dataSource={apeData}
          hasMore={hasMore}
          style={{ paddingTop: 5 }}
          renderItem={(item) => <ListItem key={item?.nftNo} item={item} {...listItemProps} />}
          onEndReached={async () => {
            await getApeList(false);
          }}
          isLoad={isLoad}
        />
      ) : !isLoad ? null : (
        <div className={styles.no_data}>暂无数据</div>
      )}
    </div>
  );
});

export default SelectApe;
