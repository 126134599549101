import React from "react";
import styles from './styles.module.scss';
import close from '@/assets/img/landfi/icon_del.png';

const ModalView = ({title = "确认租赁", onClose, children}) => {
  return <div className={styles.module_view}>
    <div className={styles.module_view_header}>
      <span></span>
      <span>{title}</span>
      <img src={close} alt="" onClick={onClose}/>
    </div>
    <div className={styles.module_view_content}>{children}</div>
  </div>
}

export default ModalView;