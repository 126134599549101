import React from 'react'
import { useNavigate } from "react-router-dom"
import { round } from "@/utils/publicFun"
import './OrderItem.scss'

const OrderItem = (props) => {
  const navigate = useNavigate()

  const { orderVos=[], outTradeNo } = props.collection
  
  const goOrderDetail = (outTradeNo) => {
    navigate(`/myGroupOrder/groupOrderDetail?out_trade_no=${outTradeNo}`)
  }

  return (
    <div className='order_content' onClick={() => { goOrderDetail(outTradeNo) }}>
      {
        orderVos.map((item, index) => {
          return (
            <div className='order_content__box' key={index}>
              <div className="order-img">
                <img src={item.image} alt="" />
              </div>
              <div className="order-message">
                <div className="title">{item.collectionName}</div>
                <div className="price">¥{round(item.price, 2)}</div>
                <div className="pay-detail">
                  <span className="num">x{item.num}</span>
                  <span className="status">{item.orderStatus === "SUCCESS" ? "已付款" : (item.orderStatus === 'NOTPAY' ? "待支付" : "已取消")}</span>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default OrderItem