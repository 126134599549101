/*
  藏品转增成功界面
*/
import React, {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {getUrlAllParams} from "@/utils/publicFun";
import donationSuccess from '@/assets/img/donation-success.png';
import './style.scss';

const DonationSuccess = () => {
    const navigate = useNavigate();
    const {search} = useLocation();
    const {name, type = '0'} = getUrlAllParams(search.slice(1));

    useEffect(() => {
        window.JsBridge.setTitle(type === '0' ? '转赠成功':'转赠中');
    }, []);

    return <div className='donation-success'>
        <img src={donationSuccess} alt=""/>
        <div className='title'>{type === '0' ? '转赠成功':'转赠中'}</div>
        <div className='tip'>{type === '0' ? '':'转赠中，请稍后'}  </div>
        <div className="btn"
             onClick={() => window.JsBridge.back()}>我知道了
        </div>
    </div>
}

export default DonationSuccess;
