import React from 'react';
import defaultImage from '@/assets/img/noCollection.png'
import './style.scss';

const Empty = ({image = defaultImage, description = '暂无数据', style, customStyle}) => {
  return <div className="empty-new" style={{...style}}>
    <img className='empty-img' src={customStyle?.img || image} alt="" style={{...(customStyle?.imgStyle || {})}}/>
    <span className='empty-desc' style={{...(customStyle?.textStyle || {})}}>{description}</span>
  </div>
}

export default Empty;