/**
 * @description GR-2405627 ape world 配件盲盒相关接口
*/
import requests from '@/utils/requests_self';
import { queryDefCode, getActivityTime, getRecoveryActivity, getRecoveryRecordv2, recoveryActivityExec } from "@/api/projectManager_self";
import collection from '@/assets/img/variationApe/collection-temp.png';
import moment from 'moment';

const mock = false;

class Service {
    // 活动码
    static activityCode = 'apeBindBox';

    static collectionId = 204;

    /**
     * @description 获取ape world 配件盲盒初始化数据
     * @param {*} params 
     * @returns
    */
    static getInitData = async (params = {}) => {
        const userInfo = JSON.parse(window.localStorage.getItem('userInfo') ?? "{}");
        // params = { activityCode: Service.activityCode, clientId: userInfo?.clientId, ...params };
        params = { activityCode: Service.activityCode }
        return await getActivityTime(params);

    }
    /**
     * @description 获取活动规则
     * @param {*} params 
     * @returns
    */
    static getActivityRules = async () => {
        return await queryDefCode({ codeType: 'activityRule', code: Service.activityCode });
    }

    /**
     * @description 获取合成相关的数量
     * @param {type: 0普通，1稀有; activityCode} param
     * @returns
    */
    static getRemainCount = async (param = {}) => {
        const params = {
            activityCode: Service.activityCode,
            ...param,
        }
        if (mock) {
            return Promise.resolve({
                code: '000000',
                data: {
                    remainCount: param.type == 1 ? 3: 1,
                    endTime: '2024-07-25 10:00:00',
                    systemDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                },
                msg: '成功'
            })
        }
        return await requests.post('/customer/recoveryActivity/v2/queryRemainCount', JSON.stringify(params));
    }
    /**
     * @description 查询可以回收藏品列表
     * @param {bizId: 普通"0",稀有"1"; activityCode} param
     * @returns
    */
    static getRecoveryList = async (param = {}) => {
        const params = {
            activityCode: Service.activityCode,
            ...param,
        }
        if (mock) {
            const mockData = [
                {
                    nftNo: '0ggghfdddddddff',
                    collectionId: '0',
                    nftImage: collection,
                },
                {
                    nftNo: '1',
                    collectionId: '1',
                    nftImage: collection,
                },
                {
                    nftNo: '2',
                    collectionId: '2',
                    nftImage: collection,
                },
                {
                    nftNo: '3',
                    collectionId: '3',
                    nftImage: collection,
                },
                {
                    nftNo: '4',
                    collectionId: '4',
                    nftImage: collection,
                },
            ]
            return Promise.resolve({
                code: '000000',
                data: {
                    list: mockData
                },
                msg: '成功'
            });
        }

        return await getRecoveryActivity(params);
    }
    /**
     * @description 兑换藏品盲盒
     * @param {activityCode: 活动码, bizId: 普通"0",稀有"1", nftNoList: [string]} param
     * @returns
    */
    static exchangeBlindbox = async (param = {}) => {
        const params = {
            activityCode: Service.activityCode,
            ...param,
        }
        if (mock) {
            const res = {
                "code": "000000",
                "data": {
                    "urlList": ["https://gu-nft-test1.obs.cn-east-3.myhuaweicloud.com/collection/land/S/1.png?GOLDLAND19"],
                    "userCollectionRespList":
                        [
                            {
                                url: 'https://gu-nft-test1.obs.cn-east-3.myhuaweicloud.com/collection/component/special/BF/BFCLOTH.png?BFCLOTH0378',
                                "elementId": "BFCLOTH0378",
                                "collectionId": 78
                            },
                            {
                                "url": "https://gu-nft-test1.obs.cn-east-3.myhuaweicloud.com/collection/land/S/1.png?GOLDLAND19",
                                "elementId": "IDGAC2799",
                                "collectionId": 78
                            },
                            {
                                "url": "https://gu-nft-test1.obs.cn-east-3.myhuaweicloud.com/collection/land/S/1.png?GOLDLAND19",
                                "elementId": "IDGAC2799",
                                "collectionId": 78
                            },
                            {
                                "url": "https://gu-nft-test1.obs.cn-east-3.myhuaweicloud.com/collection/land/S/1.png?GOLDLAND19",
                                "elementId": "IDGAC2799",
                                "collectionId": 78
                            },
                            {
                                "url": "https://gu-nft-test1.obs.cn-east-3.myhuaweicloud.com/collection/land/S/1.png?GOLDLAND19",
                                "elementId": "IDGAC2799",
                                "collectionId": 78
                            }
                        ]
                },
                "msg": null
            }
            return Promise.resolve(res);
        }
        return await recoveryActivityExec(params);
    }

    /**
     * @description 兑换记录
     * @param { * } params
     * @returns
    */
    static getRecoveryRecord = async (params = {}) => {
        const param = {
            activityCode: Service.activityCode,
            ...params,
        }
        if (mock) {
            const mockData = {
                code: '000000',
                msg: 'success',
                data: {
                    recoveryList: [
                        {
                            nftNo: '123455',
                            name: '绿地30周年',
                        },
                        {
                            nftNo: '123455',
                            name: '绿地30周年',
                        },
                        {
                            nftNo: '123455',
                            name: '绿地30周年',
                        },
                    ]
                }
            }

            return Promise.resolve(mockData);
        }
        return await getRecoveryRecordv2(param);
    }

}

export default Service;