import React, { useRef } from "react";
import "./index.scss";
import ListView from "@/components/AntListView";
import { InfiniteScroll, PullToRefresh, DotLoading } from "antd-mobile";
import Empty from "@/components/empty";
import noData from "@/assets/img/noCollection.png";
const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : null}
    </>
  );
};
const ScrollableTable = (props) => {
  const {
    renderHeader,
    renderItem,
    renderFooter,
    onRefresh,
    onEndReached,
    onEndReachedThreshold,
    infiniteContent,
    hasMore = false,
    emtyDesc = "暂无数据",
    style,
    isLoad,
    customStyle,
  } = props;
  const maxHeight = props.width ? props.height + "px" : "unset";
  const columns = props.columns || [];
  const dataSource = props.dataSource || [];

  let maxWidth = 0;
  if (props.width) style.width = props.width;
  if (columns.length === 0) {
    columns.push({
      dataKey: "key",
    });
  }
  columns.forEach((column) => {
    const width = column.width || 50;
    maxWidth += width;
  });

  const fixedColumns = getFixedColumns(columns);
  const leftFixedColumns = fixedColumns[0];
  const rightFixedColumns = fixedColumns[1];

  const tableBody = useRef();
  const handleScroll = (target) => {
    const scrollLeft = target.scrollLeft;
    const tableHeaders =
      target.parentElement.getElementsByClassName("st-table-header");
    if (tableHeaders.length > 0) {
      tableHeaders[0].scrollLeft = scrollLeft;
    }
  };

  return (
    <div
      className={classNames("st-table-container", props.className)}
      style={{...style, overflowY: dataSource.length === 0 ? "hidden" : "scroll",}}
    >
      <PullToRefresh
        onRefresh={onRefresh}
        disabled={onRefresh ? false : true}
        headHeight={70}
        threshold={100}
        pullingText={
          <img
             src={require('@/assets/img/new-loading.gif')}
            style={{ height: 50 }}
            alt=""
          />
        }
        refreshingText={
          <img
          src={require('@/assets/img/new-loading.gif')}
          style={{ height: 50 }}
            alt=""
          />
        }
        canReleaseText={
          <img
          src={require('@/assets/img/new-loading.gif')}
          style={{ height: 50 }}
            alt=""
          />
        }
        renderText={null}
      >
        <div
          ref={tableBody}
          className="st-table-body st-table-header"
          onScroll={(e) => handleScroll(e.currentTarget)}
          style={{
            maxHeight: maxHeight,
            overflow: dataSource.length === 0 ? "hidden" : "scroll",
          }}
        >
          <table style={{ width: maxWidth, minWidth: "100%" }}>
            <colgroup>{renderCols(columns)}</colgroup>
            <thead className="st-table-thead">
              <tr>
                {columns.map((column, index) => {
                  const align = column.align || undefined;
                  const title = column.title || "";
                  const fixed = leftFixedColumns.includes(index)
                    ? "left"
                    : rightFixedColumns.includes(index)
                    ? "right"
                    : "";
                  const fixedClassName = fixed
                    ? "st-table-cell-fix-" + fixed
                    : "";
                  return (
                    <th
                      key={index}
                      className={classNames(
                        "st-table-cell",
                        fixedClassName,
                        column.className
                      )}
                      style={{ textAlign: align }}
                    >
                      {title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody
              className="st-table-tbody"
              style={{
                overflow: dataSource.length === 0 ? "hidden" : "scroll",
              }}
            >
              {dataSource.length > 0
                ? dataSource.map((record, index) => (
                    <tr key={index} className="st-table-row">
                      {renderCells(
                        columns,
                        leftFixedColumns,
                        rightFixedColumns,
                        record,
                        index
                      )}
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
          {dataSource.length === 0 && isLoad ? (
            <Empty
              image={noData}
              description={emtyDesc}
              style={style}
              customStyle={customStyle}
            />
          ):null}
        </div>
        {onEndReached && (
          <InfiniteScroll
            loadMore={onEndReached}
            hasMore={hasMore}
            threshold={onEndReachedThreshold}
          >
            {infiniteContent ? (
              <>{hasMore ? infiniteContent() : null}</>
            ) : (
              <InfiniteScrollContent hasMore={hasMore} />
            )}
          </InfiniteScroll>
        )}
      </PullToRefresh>
    </div>
  );
};

const classNames = (...names) => {
  const currentNames = [];
  names.forEach((name) => {
    if (name) currentNames.push(name);
  });
  return currentNames.join(" ");
};

const getFixedColumns = (columns) => {
  const total = columns.length;
  const leftFixedColumns = [];
  const rightFixedColumns = [];
  if (columns[0].fixed) {
    for (let i = 0; i < total; i++) {
      if (columns[i].fixed) {
        leftFixedColumns.push(i);
      } else {
        break;
      }
    }
  }
  if (columns[total - 1].fixed) {
    for (let i = total - 1; i >= 0; i--) {
      if (columns[i].fixed) {
        if (!leftFixedColumns.includes(i)) rightFixedColumns.push(i);
      } else {
        break;
      }
    }
  }
  return [leftFixedColumns, rightFixedColumns];
};

const renderCols = (columns) => {
  return columns.map((column, index) => {
    const width = column.width || 50;
    return <col key={index} style={{ width: width, minWidth: width }} />;
  });
};

const renderCells = (
  columns,
  leftFixedColumns,
  rightFixedColumns,
  record,
  index
) => {
  return columns.map((column, index) => {
    const align = column.align || undefined;
    const fixed = leftFixedColumns.includes(index)
      ? "left"
      : rightFixedColumns.includes(index)
      ? "right"
      : "";
    const className = classNames(
      "st-table-cell",
      column.className,
      fixed ? "st-table-cell-fix-" + fixed : ""
    );
    const rawValue =
      column.dataKey && column.dataKey in record
        ? record[column.dataKey]
        : undefined;
    let value = undefined;
    if (column.render) {
      value = column.render(rawValue, record, index);
    } else {
      value =
        rawValue === undefined || rawValue === null ? "" : String(rawValue);
    }
    return (
      <td key={index} className={className} style={{ textAlign: align }}>
        {value || '-'}
      </td>
    );
  });
};

export default ScrollableTable;
