

const postParams = {method: 'setTitle', data: {}};
let actionPools = [];
window.callback = null;

const _JsBridge = window.JsBridge || {};
class JsBridgeNew extends _JsBridge {
    static lastTime = 0;

    static setActionName = () => {
        var actionName = new Date().getTime() + parseInt(Math.random() * 10000, 10);
        if (window[actionName]) {
            return this.setActionName();
        } else {
            const promise = new Promise((resolve) => {
                window[actionName] = resolve;
            });
            return {
                actionName, promise
            };
        }
    };
    static sendMessage(params) {
        if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
            window.ReactNativeWebView.postMessage(params);
            return true;
        } else {
            return false;
        }
    }

    /**
     * 获取内购的产品列表
     */
    static iapProList = (params=[]) => {
        const {actionName, promise} = this.setActionName();
        postParams.method = 'iapProList';
        postParams.data = {actionName: actionName, params: params};
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    };

    /**
     * 保存gif的图片  只针对iOS的gif图片,其他格式的不要用这个 安卓的用之前的就可以实现
     */
    static saveGifImage = (params=[]) => {
        const {actionName, promise} = this.setActionName();
        postParams.method = 'saveGifImage';
        postParams.data = {actionName: actionName, params: params};
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    };
    /**
     * 监听键盘事件
     */
    static addKeyboardEvent() {
        const {actionName, promise} = this.setActionName();
        postParams.method = 'addKeyboardEvent';
        postParams.data = {
            actionName, params: {}
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 调用jkt的接口
     */
    static jKTServicesRequest(params) {
        const {actionName, promise} = this.setActionName();
        postParams.method = 'jKTServicesRequest';
        postParams.data = {
            actionName, params: params
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * @description 设置原生跳转回调函数,目前仅支持push
     * @example const data = await JsBridge.push('CustomWeb', {uri: uri, callback: true});
     * @event window.JsBridge.callback(item)
     */
    static callback(params) {
        postParams.method = 'callback';
        postParams.data = {params};
        this.sendMessage(JSON.stringify(postParams))
    }

    static push(path = 'Web', params) {
        const {actionName, promise} = this.setActionName();
        const now = new Date().getTime();
        // eslint-disable-next-line no-undef
        if (now - this.lastTime >= 1000) {
            // eslint-disable-next-line no-undef
            const that = this;
            postParams.method = 'open';
            postParams.data = {
                actionName, path, params
            };
            that.sendMessage(JSON.stringify(postParams))
            this.lastTime = now;
        }
        return promise
    }

    /**
     *分享小程序
     */
    static shareMiniProgram = (params=[]) => {
        const {actionName, promise} = this.setActionName();
        postParams.method = 'shareMiniProgram';
        postParams.data = {actionName: actionName, params: params};
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    };
    /**
     * 返回按钮
     */
    static backHandler() {
        const {actionName, promise} = this.setActionName();
        postParams.method = 'backHandler';
        postParams.data = {
            actionName, params: {}
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }
    /**
    * @description 拉起app收银台
    * @param {
    *       orderInfo: {
    *           orderSn: '20210210003800717', //订单号
    *           serviceTimestamp: '1617326893271', //服务器时间戳
    *           expired: '1617326893271',//订单过期时间戳
    *           amount: '100', //订单金额（元）
    *           resource: 'insurance' //订单来源 电商平台：mall    保险：insurance
    *       },
    *
    *   } params
    * @param {replace: boolean; method: string; } option 
    */
    static pay(params, option={replace:true}) {
        if(option.method) {
            const methods = ['navigate', 'replace', 'open', 'push'];
            if(!methods.includes(option.method)) {
                throw new Error('method must be in [' + methods.join(',') + ']')
            }
        }
        const {actionName, promise} = this.setActionName();
        postParams.method = option.method ? option.method: option.replace ? 'replace' : 'navigate';
        postParams.data = {
            actionName, 
            path: 'OrderPayment',
            params
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * @description 竖屏设置
     * @param {lock: true竖屏, false: 横屏}
     */
    static screenRotate(lock=false) {
      const {actionName, promise} = this.setActionName();
      postParams.method = 'screenRotate';
      postParams.data = {
          actionName, params: {lock}
      };
      this.sendMessage(JSON.stringify(postParams));
      return promise;
    }

    /**
     * 隐藏状态栏
     */
    static hiddenStatusBar(hiddenStatusBar) {
      const {actionName, promise} = this.setActionName();
      postParams.method = 'hiddenStatusBar';
      postParams.data = {
          actionName, params: {hiddenStatusBar}
      };
      this.sendMessage(JSON.stringify(postParams));
      return promise;
    }
    /**
     * @description 是否横屏 (2024-6-28版本添加)
     * @param {init: true初始化页面时的屏幕方向, false: 当前的屏幕方向}
     */
    static isLandscape(init=true) {
        const {actionName, promise} = this.setActionName();
        postParams.method = 'isLandscape';
        postParams.data = {
            actionName, params: {init}
        };
        this.sendMessage(JSON.stringify(postParams));
        return this.hasWebViewBridge() ? promise: Promise.resolve(false);
    }
}

window.JsBridgeNew = JsBridgeNew;
export default JsBridgeNew;

