import React, {useState, useRef, useEffect} from 'react';
import styles from "./styles.module.scss";
import ListView from "@/components/AntListView";
import {hideLoading, showLoading} from "@/components/loading";
import {Toast} from "antd-mobile";
import HuiShouColModal from "@/view/eleCompound/components/huiShouColModal";
import {getRecoveryActivity, getRemainCountv2} from "@/api/projectManager_self";
import {metaRecovery24_0116} from "@/constant";
import {getUserCertificateStatus} from "@/view/eleCompound/utils";
import ShiMingModal from "@/view/eleCompound/components/shiMingModal";

const HuiShouEle = (props) => { //回收元素

    const [dataSource, setDataSource] = useState([])
    const [hsCount, setHsCount] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(30);
    const [selItems, setSelItems] = useState([]);//选择的藏品
    const maxCountRef = useRef(5);

    useEffect(() => {
        getHSCount();
        loadData(true);
    }, [])

    const loadData = async (isRefresh) => {
        try {
            showLoading();
            if (isRefresh) {
                currentPage.current = 1;
            }
            const res = await getRecoveryActivity({
                activityCode: metaRecovery24_0116,
                currentPage: currentPage.current,
                pageSize: pageSize.current
            });
            hideLoading();
            const {code, msg, data} = res;
            if (code !== '000000') throw msg;
            let {list = []} = data;
            if (currentPage.current === 1) {
                setDataSource(list)
            } else {
                setDataSource([...dataSource, ...list]);
            }
            setIsLoad(true);
            setHasMore(list.length >= pageSize.current);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const getHSCount = async () => {
        try {
            const res = await getRemainCountv2({activityCode: metaRecovery24_0116});
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                setHsCount(res.data || 0);
            }
        } catch (error) {
            Toast.show(error);
        }
    }

    const itemClick = (item) => {
        if (item.isChoice) { //取消选中
            item.isChoice = !item.isChoice;
        } else { //选中新的
            let selDataTem = dataSource.filter(item => item.isChoice === true);
            if (selDataTem.length >= maxCountRef.current) {
                Toast.show(`每次最多可选择${maxCountRef.current}个`);
                return;
            }
            item.isChoice = !item.isChoice;
        }
        setDataSource([...dataSource]);
        let arr = [];
        for (const item1 of dataSource) {
            if (item1.isChoice) {
                arr.push(item1);
            }
        }
        setSelItems(arr);
    }

    const clickHS = async () => {//回收得元素
        let isShiming = await getUserCertificateStatus();
        if (!isShiming) {
            ShiMingModal.open(1);
            return;
        }
        if (selItems.length > hsCount) {
            Toast.show('次数不足');
            return;
        }
        if (selItems.length) {
            HuiShouColModal.open(selItems, () => {
                setSelItems([]);
                getHSCount();
                loadData(true).then();
            });
        }
    }

    const listItem = (item) => {
        return <div className={`${styles.item} ${item.isChoice ? styles.addBoder : ''}`}
                    onClick={() => itemClick(item)}>
            <img src={item.nftImage} alt=""/>
            {
                (selItems.length >= maxCountRef.current && !item.isChoice) ? <div className={styles.toast}/> : null
            }
        </div>
    }

    return (
        <div className={styles.huiContainer}>
            <p className={styles.title}>请选择回收材料</p>
            <div className={styles.content}>
                <div className={styles.left}>
                    <ListView
                        dataSource={dataSource}
                        hasMore={hasMore}
                        style={{paddingTop: 5}}
                        renderItem={listItem}
                        onEndReached={async () => {
                            console.log('onEndReached')
                            currentPage.current += 1;
                            await loadData(false);
                        }}
                        isLoad={isLoad}
                        customStyle={{
                            imgStyle: {width: 100, height: 100},
                            textStyle: {color: '#FFFFFF', fontSize: 12},
                        }}
                    />
                </div>
                <div className={styles.right}>
                    <img src={require('../../imgs/img_ys1.png')} alt=""/>
                    <p className={styles.des}>剩余回收个数：{hsCount}</p>
                    <p className={styles.btn} onClick={() => {
                        clickHS()
                    }}>回收得元素</p>
                </div>
            </div>
        </div>
    )
}
export default HuiShouEle;
