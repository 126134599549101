/*
    商品金额总计
*/

import React, { useState, useEffect, useRef } from "react";
import {round} from "@/utils/publicFun";
import "./index.scss";

const TotalInfoBox = (props)=> {

    const {
        payDetail = {},
    } = props

    return (
        <div className="total-info-box">
            <div className="price-info">
                <span>商品金额</span>
                <div className="nft_spacebt_right">
                    ¥{round(payDetail.totalPrice || 0, 2)}
                </div>
            </div>
            <div className="nft_pricetotal">
                <div className="nft_total">总计: </div>
                <div className="nft_price_left">¥{round(payDetail.totalPrice || 0, 2)}</div>
            </div>

        </div>
    )
} 

export default TotalInfoBox;