import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { Mask } from 'antd-mobile';

const SuccessModal = ({onClose, confirmCB, type}) => {
  const [visible, setVisible] = useState(true);

  const cancel = () => {
    onClose && onClose();
    setVisible(false);
    confirmCB && confirmCB();
  }

  const confirm = () => {
    onClose && onClose();
    setVisible(false);
    confirmCB && confirmCB();
  }

  return <Mask className='recovery-success-modal' visible={visible} onMaskClick={() => cancel()}>
    <div className={`mask-content`}>
      <div>
        <p>{'回收成功'}</p>
        <span>后续奖励将在统一发放到G优账户，请在积分明细中查询。</span>
        <div className="confirm-btn" onClick={() => confirm()}>我知道了</div>
      </div>
    </div>
  </Mask>
}

SuccessModal.open = ({confirmCB, type}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<SuccessModal onClose={close} confirmCB={confirmCB} type={type}/>, div);
}

export default SuccessModal;