import {Toast} from 'antd-mobile'
import clickThrottle from "@/utils/clickThrottle";

/**
 * 返回具有防抖的函数
 * @param {function} fn 需要防抖的函数
 * @param {wait} wait 时间范围
 * @returns 防抖的函数
 */
function debounce(fn, wait) {
    let timer;
    return function () {
        let _this = this;
        let args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            fn.apply(_this, args);
        }, wait);
    };
}

// function debounce(func, wait) {
//   let timeout;
//   return function () {
//     const context = this;
//     const args = [...arguments];
//     if (timeout) clearTimeout(timeout);
//     const callNow = !timeout;
//     timeout = setTimeout(() => {
//       timeout = null;
//     }, wait)
//     if (callNow) func.apply(context, args)
//   }
// }


/**
 * 返回具有节流的函数
 * @param {function} fn 需要节流的函数
 * @param {delay} delay 时间范围
 * @returns 节流的函数
 */
function throttle(func, wait) {
    var previous = 0;
    return function () {
        let now = Date.now();
        let context = this;
        let args = arguments;
        if (now - previous > wait) {
            func.apply(context, args);
            previous = now;
        }
    }
}

/**
 * 获取保留指定精度的四舍五入结果
 * @param {number} number 目标参数
 * @param {number} precision [precision=0] round的精度
 * @returns {number} 返回保留精度的四舍五入结果
 */
function round(number, precision) {
    if (isNaN(number)) {
        return 0
    }
    precision = precision == null ? 0 : (precision >= 0 ? Math.min(precision, 292) : Math.max(precision, -292))
    if (precision) {
        let pair = `${number}e`.split('e')
        const value = Math.round(`${pair[0]}e${+pair[1] + precision}`)

        pair = `${value}e`.split('e')
        return (+`${pair[0]}e${+pair[1] - precision}`).toFixed(precision)
    }
    return Math.round(number);
}

/**
 * 返回路由参数对象
 * @param {string} params window.location的search属性值('?t=123&id=234&subId=345')
 * @returns 路由参数对象
 */
function getUrlAllParams(params) {
    const res = {};
    let arr = params.split("&");
    arr.forEach(item => {
        let key = item.split('=')[0]
        let value = item.split('=')[1]
        res[key] = value
    });
    return res;
}

function objectToQueryParams(obj) {
    if (obj && typeof obj === 'object') {
        return Object.keys(obj).map(e => {
            return `${e}=${obj[e]}`
        }).join('&');
    } else {
        return '';
    }
}

/**
 *
 * @param {string} text 需要复制的文字
 * @param {node} parentNode 插入的父节点，如果插入body会使页面focus后偏移
 */
function copyText(text, parentNode) {
    let input = document.createElement('input')
    input.id = 'copy_input'
    input.readOnly = "readOnly"  //防止ios聚焦触发键盘事件
    input.style.position = "absolute";
    input.style.left = '-1000px'
    input.style.zIndex = "-1000";
    parentNode.appendChild(input)

    input.value = text.toString();
    // ios必须先选中文字且不支持 input.select();
    selectText(input, 0, text.length);
    if (document.execCommand('copy')) {
        document.execCommand('copy');
        Toast.show("复制成功！")
    } else {
        Toast.show("复制失败，请手动复制！")
    }
    input.blur();
    parentNode.removeChild(input);

    // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
    // 选择文本。createTextRange(setSelectionRange)是input方法
    function selectText(textbox, startIndex, stopIndex) {
        if (textbox.createTextRange) {//ie
            const range = textbox.createTextRange();
            range.collapse(true);
            range.moveStart('character', startIndex);//起始光标
            range.moveEnd('character', stopIndex - startIndex);//结束光标
            range.select();//不兼容苹果
        } else {//firefox/chrome
            textbox.focus();
            textbox.setSelectionRange(startIndex, stopIndex);
        }
    }
}

/**
 * 数字运算（主要用于小数点精度问题）
 * @param {number} a 前面的值
 * @param {"+"|"-"|"*"|"/"} type 计算方式
 * @param {number} b 后面的值
 * @example
 * ```js
 * // 可链式调用
 * const res = computeNumber(1.3, "-", 1.2).next("+", 1.5).next("*", 2.3).next("/", 0.2).result;
 * console.log(res);
 * ```
 */
function computeNumber(a, type, b) {
    /**
     * 获取数字小数点的长度
     * @param {number} n 数字
     */
    function getDecimalLength(n) {
        const decimal = n.toString().split(".")[1];
        return decimal ? decimal.length : 0;
    }

    /**
     * 修正小数点
     * @description 防止出现 `33.33333*100000 = 3333332.9999999995` && `33.33*10 = 333.29999999999995` 这类情况做的处理
     * @param {number} n
     */
    const amend = (n, precision = 15) => parseFloat(Number(n).toPrecision(precision));
    const power = Math.pow(10, Math.max(getDecimalLength(a), getDecimalLength(b)));
    let result = 0;

    a = amend(a * power);
    b = amend(b * power);

    switch (type) {
        case "+":
            result = (a + b) / power;
            break;
        case "-":
            result = (a - b) / power;
            break;
        case "*":
            result = (a * b) / (power * power);
            break;
        case "/":
            result = a / b;
            break;
    }

    result = amend(result);

    return {
        /** 计算结果 */
        result,
        /**
         * 继续计算
         * @param {"+"|"-"|"*"|"/"} nextType 继续计算方式
         * @param {number} nextValue 继续计算的值
         */
        next(nextType, nextValue) {
            return computeNumber(result, nextType, nextValue);
        }
    };
}

const previewProtocol = (type) => {
    window.JsBridge.open(`${process.env.REACT_APP_PROTOCOL_URL}?type=${type}`);
}

const formatFn = (val) => {
    return val < 10 ? `0${val}` : val;
}

const getTime = (time, maxDay) => {
    if (!time || !maxDay) return;
    const nowTime = new Date(time.replace(/-/g, '/')).getTime() + maxDay * 24 * 3600 * 1000;
    const year = new Date(nowTime).getFullYear();
    const month = new Date(nowTime).getMonth() + 1;
    const date = new Date(nowTime).getDate();
    const hour = new Date(nowTime).getHours();
    const minute = new Date(nowTime).getMinutes();
    return `${formatFn(year)}年${formatFn(month)}月${formatFn(date)}日 ${formatFn(hour)}:${formatFn(minute)}`;
}


export const checkType = (e, typeStr) => {
    return Object.prototype.toString.call(e) === '[object ' + typeStr + ']';
};

export const isNumber = (e) => checkType(e, 'Number');
export const isString = (e) => checkType(e, 'String');
export const isBoolean = (e) => checkType(e, 'Boolean');
export const isObject = (e) => checkType(e, 'Object');
export const isArray = (e) => checkType(e, 'Array');
export const isFunction = (e) => checkType(e, 'Function');

export function isEmptyString(string) {
    return (string === undefined || !string || !(string.constructor === String) || string.length === 0);
}

export function isEmptyArray(array) {
    return (array === undefined || !array || !Array.isArray(array) || array.length === 0);
}

export function isEmptyObject(obj) {
    if (!obj || !isObject(obj)) return true;
    return Object.keys(obj).length === 0
}

/**
 *  保留两位小数
 * @param value
 * @returns {*}
 */
export function formatDecimal(value) {
    try {
        if (value === undefined) {
            return value;
        }
        let m_value = Math.round(value * 100) / 100;
        let s_value = m_value.toString();
        let f_value = s_value.indexOf('.');
        if (f_value < 0) {
            f_value = s_value.length;
            s_value += '.';
        }
        while (s_value.length <= f_value + 2) {
            s_value += '0';
        }
        return s_value;
    } catch (e) {
        return '0.00';
    }
}

export function isIOS() {
    var u = navigator.userAgent;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return false;
}

export function isIOSNew() {
    var u = navigator.userAgent;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return isiOS;
}

const Platform = {
    isWechat: () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('micromessenger') > -1;
    },
    isApp: () => {
        return window.JsBridge.hasWebViewBridge();
    },
    isMiniprogram: () => /miniProgram/i.test(navigator.userAgent),
}

/**
 * startDate:时间 2023-01-21 06:52:49 后台标准时间
 * type:0=以/的形式返回 1=以.的形式返回
 *目前只支持年月日
 * **/
export function timeFormat(startDate, type = 1) {
    if (isEmptyString(startDate)) {
        return '';
    }
    let startDateString = startDate.replace(/-/g, '/');
    if (startDateString.indexOf('.') > -1) {
        startDateString = (startDateString.split('.'))[0];
    }
    let date = new Date(startDateString);

    let Y = date.getFullYear(),
        M = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : `${(date.getMonth() + 1)}`,
        D = date.getDate() < 10 ? `0${(date.getDate())}` : `${(date.getDate())}`;
    if (type === 0) {
        return Y + '/' + M + '/' + D;
    } else if (type === 1) {
        return Y + '.' + M + '.' + D;
    }

}

const convertToChineseNumeral = (num) => {
    if (num === null || num === undefined) {
        return
    }
    if (num == 10) {
        return '十'
    } else if (num == 1) {
        return '一'
    }
    const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const units = ['', '十', '百', '千', '万'];
    let result = '';
    let numStr = num.toString();
    for (let i = 0; i < numStr.length; i++) {
        let digit = parseInt(numStr.charAt(i));
        let unit = units[numStr.length - i - 1];
        if (digit === 0) {
            // 当前数字为0时不需要输出汉字，但需要考虑上一个数字是否为0，避免出现连续的零
            if (result.charAt(result.length - 1) !== '零') {
                result += '';
            }
        } else {
            result += digits[digit] + unit;
        }
    }
    // 对于一些特殊的数字，如10、100等，需要在最前面加上“一”
    if (result.charAt(0) === '一') {
        result = result.substr(1, result.length);
    } else if (result.charAt(0) === '百') {
        result = '一' + result;
    } else if (result.charAt(0) === '千') {
        result = '一' + result;
    }
    return result;
};


export {
    debounce,
    throttle,
    round,
    getUrlAllParams,
    copyText,
    computeNumber,
    previewProtocol,
    getTime,
    objectToQueryParams,
    convertToChineseNumeral,
    Platform,
};

/**
 * 在URL后面添加参数，如
 * appendParam = ('/search?a=1&b=2', {c: 3, d: 4}, false) => '/search?a=1&b=2&c=3&d=4'
 * @return url
 * **/
export const appendParam = (url = '', paramMap = {}, encode = true) => {
    let resultUrl = url;
    if (encode) {
        resultUrl = decodeURIComponent(url)
    }
    const append = Object.keys(paramMap).map((key) => `${key}=${paramMap[key]}`).join('&')
    if (resultUrl.includes('?')) {
        if (resultUrl.endsWith('&')) {
            resultUrl += append
        } else {
            resultUrl += `&${append}`
        }
    } else {
        resultUrl += `?${append}`
    }
    if (encode) {
        return encodeURIComponent(resultUrl)
    }
    return resultUrl
}

export const clearAllTimer = () => { //清除所有的定时器
    let end = setInterval(function () {
    }, 3000);
    for (let i = 1; i <= end; i++) {
        i && clearInterval(i);
    }
}

export const compareTime = (currentTime, endTime) => {
    if (!currentTime || !endTime) {
        return 0;
    }
    if (currentTime.indexOf('.') > -1) {
        currentTime = (currentTime.split('.'))[0];
    }
    if (endTime.indexOf('.') > -1) {
        endTime = (endTime.split('.'))[0];
    }
    let currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'));
    let endTimeStamp = Date.parse(endTime.replace(/-/g, '/'));
    if (currentTimeStamp > endTimeStamp) { //第一个时间大于第二个时间
        return 1;
    }
    if (currentTimeStamp < endTimeStamp) { //第二个时间大于第一个时间
        return -1;
    }
    if (currentTimeStamp === endTimeStamp) { //第一个时间等于第二个时间
        return 2;
    }
}

/**
 *  通知元宇宙关闭商城的webview
 *
 */
export const notifyYYZToCloseWeb = (params) => {
    console.log('关闭元宇宙');
    window.location.href = `ulitewebview://JSSendMessageToUnityCloesView?${'type=close'}`;
};
/**
 *  关闭元宇宙并且跳到实名认证
 *
 */
export const notifyYYZToCloseWebAndJumpAuthPage = (params) => {
    console.log(' 关闭元宇宙并且跳到实名认证');
    window.location.href = `ulitewebview://JSSendMessageToUnityCertification?${'type=close'}`;
};

/**
 * H5通知元宇宙去发送支付事件
 *
 */
export const notifyGYouToPay = (params) => {
    console.log('H5通知元宇宙去发送支付事件---', JSON.stringify(params));
    window.location.href = `ulitewebview://JSSendMessageToUnityPay?${params}`;
};
/**
 * 元宇宙通知H5跳转到支付成功页面
 *
 */
export const notifyYYZToJumpPaySuccessPage = (params) => {
    console.log('通知元宇宙跳转到支付成功页面---', JSON.stringify(params));
    // let stringContext = encodeURIComponent(JSON.stringify(params));
    // Taro.redirectTo({
    //     url: `/pages/package-C/order/order-tool/order-success-unity/index?param=${stringContext}`,
    // });
};

export const loadImage = (img) => {
  return new Promise((resolve, reject) => {
    if (img.complete) {
      resolve(img);
    } else {
      img.onload = () => {
        resolve(img);
      };
    }
    img.onerror = img.onabort = (e) => {
      resolve(null);
    };
  });
}
