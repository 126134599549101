/**
 * @description 支付成功页面
 * @param { 参数通过state传递, 夸系统则通过search传递 }
 * @returns {/ape/PaySuccess}
*/
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Toast } from "antd-mobile";
import NFTHeader from '@/components/NFTHeader';
import { showLoading, hideLoading } from '@/components/loading';
import classNames from "classnames";
import { EffectCards } from "swiper";
import { getUrlAllParams } from "@/utils/publicFun";
import { getReceive } from "@/api/projectManager_self";
import { CMap } from '../SaleDetail/config';
import "swiper/css/bundle";
import "swiper/css/effect-cards";
import "./index.scss";

export default function PaySuccess() {
  const navigate = useNavigate()
  const { state = { 
    collectionList: [{ image: '', nftNo: "", collectionName: "", collectionType: 19 }],
    title: "", 
    from: undefined/* 跳转来源 */ 
  }} = useLocation()

  // 用于测试的数据
  // const state = {
  //   collectionList: [{
  //     "nftNo":"CUPGRADE——REDMER0864",
  //     "collectionName":"王小惠盲盒",
  //     "hash":"0xb25cda8424237de8147e5740382df7594c68691d444d1317ca90e1f7f2b30ba8",
  //     "image":"https://gu-nft-test1.obs.cn-east-3.myhuaweicloud.com/collection/industrial/redmer.png?CUPGRADE——REDMER0864",
  //     "collectionType":220
  //   }],
  //   title: "王小慧藏品",
  //   from: 'wxh'
  // }
  // 共创漫画开盲盒参数
  const { search } = useLocation()
  //req:流水号
  const { code, req } = getUrlAllParams(search.slice(1));

  const [hash, setHash] = useState()
  const [activeIndex, setActiveIndex] = useState(0)
  const [blinBox, setBlinBox] = useState(
    /*[{
      url: require('@/assets/img/ape-world/egg_bg_xiangqingye.png'),
      title: '开盲盒',
      nftNo: 'xxxxx'
    }]*/
  )
  const [blinBoxCount, setBlinBoxCount] = useState(0)

  const bridge = window.JsBridge

  useEffect(() => {
    setHash(state?.collectionList[0]?.hash)
    if (state?.title) {
      bridge.setTitle(state?.title)
    } else {
      bridge.setTitle("支付成功")
    }
    if (req && code) {
      bridge.setTitle("开盒中")
      getBlinBoxData()
    }
  }, [])

  const getBlinBoxData = async () => {
    try {
      showLoading()
      const res = await getReceive({ req, code })
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      if (res.data && res.data.collectionsInfo) {
        bridge.setTitle("开盒成功")
        const totalCount = res.data.totalCount || 0
        setBlinBoxCount(totalCount)
        let list = res.data.collectionsInfo || []
        list = list.map(item => {
          if (item.url && item.url.includes('?')) {
            item.url = item.url.split('?')[0]
          }
          return item
        })
        setBlinBox(list)

      } else {
        throw '开盲盒失败';
      }
    } catch (error) {
      bridge.setTitle("开盒失败")
      Toast.show(error);
    }

  }

  const gotoDetail = () => {
    let url = `/myCollectionDetail?nft_hash=${hash}`
    if (req || state.from == 'cartoon') {
      // 漫画开盲盒/漫画支付成功跳转到列表
      url = `/myCollection?code=05`
    } else if (state.from === 'boringApe') {

      url = `/myCollection?code=06&subCode=31`
    } else if (state.from === 'workapeBlindbox') {

      url = `/myCollection?code=11`
    } else if (state.from === 'spaceBeautifulAccount') {
      // 空间编号(ID)盲盒
      url = `/myCollection?initalTab=1&code=13`
    } else if (state.from === 'europeanCup') {
      // 欧洲杯
      url = `/myCollection?code=14`
    } else if (state.from === 'apeBindBox') {
      //ape world 配件盲盒，存在配件和土地，直接到藏品列表
      url = `/myCollection`
    } else if (state.from === 'industrial') {
      url = `/myCollection`
    } else if (state.from === 'healthPreservation') {
      //康养藏品
      url = `/myCollection?code=15`
    }else if (state.from === 'wxh') {
      //王小慧藏品
      url = `/myCollection?code=17`
    }
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.replace("CustomWeb", { uri: `${window.location.origin}${url}` });
    } else {
      navigate(url, { replace: true });
    }

  }

  const canGoBack = () => {
    return window.history.length > 1;
  };

  const onLeftClick = () => {
    if (canGoBack()) {
      window.history.go(-1);
    } else {
      if (window.JsBridge.hasWebViewBridge()) {
        window.JsBridge.back();
      } else {
        window.history.go(-1);
      }
    }
  }

  const slideChange = (e) => {
    setHash(state?.collectionList[e.activeIndex]?.hash)
    setActiveIndex(e.activeIndex)
  }

  const returnDiv = () => {
    // 前后左右上下的顺序
    let sources = CMap[state.from]?.sources;
    console.log('state', state)
    if (state?.collectionList[0]?.collectionType === 1) {
      sources = [
        require("@/assets/img/dh/13.png"),
        require("@/assets/img/dh/13.png"),
        require("@/assets/img/dh/12.png"),
        require("@/assets/img/dh/12.png"),
        require("@/assets/img/dh/11.png"),
        require("@/assets/img/dh/11.png"),
      ]
    } else if (state?.collectionList[0]?.collectionType === 2) {
      sources = [
        require("@/assets/img/dh/23.png"),
        require("@/assets/img/dh/23.png"),
        require("@/assets/img/dh/22.png"),
        require("@/assets/img/dh/22.png"),
        require("@/assets/img/dh/21.png"),
        require("@/assets/img/dh/21.png"),
      ]

    } else if (state?.collectionList[0]?.collectionType === 3) {
      sources = [
        require("@/assets/img/dh/33.png"),
        require("@/assets/img/dh/33.png"),
        require("@/assets/img/dh/32.png"),
        require("@/assets/img/dh/32.png"),
        require("@/assets/img/dh/31.png"),
        require("@/assets/img/dh/31.png"),
      ]

    } else if (state?.collectionList[0]?.collectionType === 4) {
      sources = [
        require("@/assets/img/dh/43.png"),
        require("@/assets/img/dh/43.png"),
        require("@/assets/img/dh/42.png"),
        require("@/assets/img/dh/42.png"),
        require("@/assets/img/dh/41.png"),
        require("@/assets/img/dh/41.png"),
      ]

    } else if (state?.collectionList[0]?.collectionType === 6) {
      sources = [
        require("@/assets/img/dh/6.png"),
        require("@/assets/img/dh/6.png"),
        require("@/assets/img/dh/7.png"),
        require("@/assets/img/dh/7.png"),
        require("@/assets/img/dh/5.png"),
        require("@/assets/img/dh/5.png"),
      ]

    } else if (state?.collectionList[0]?.collectionType === 7) {
      sources = [
        require("@/assets/img/dh/2.png"),
        require("@/assets/img/dh/2.png"),
        require("@/assets/img/dh/3.png"),
        require("@/assets/img/dh/3.png"),
        require("@/assets/img/dh/1.png"),
        require("@/assets/img/dh/1.png"),
      ]

    } else if (state?.collectionList[0]?.collectionType === 9) {
      sources = [
        require("@/assets/img/ape-world/box2.png"),
        require("@/assets/img/ape-world/box2.png"),
        require("@/assets/img/ape-world/box56.png"),
        require("@/assets/img/ape-world/box56.png"),
        require("@/assets/img/ape-world/box3.png"),
        require("@/assets/img/ape-world/box3.png"),
      ]

    } else if ((blinBox && blinBox.length > 0) || state?.collectionList[0]?.collectionType === 19) {
      // 开盲盒/漫画支付成功
      sources = [
        require("@/assets/img/ape-world/cartoon_front.png"),
        require("@/assets/img/ape-world/cartoon_front.png"),
        require("@/assets/img/ape-world/cartoon_left.png"),
        require("@/assets/img/ape-world/cartoon_left.png"),
        require("@/assets/img/ape-world/cartoon_top.png"),
        require("@/assets/img/ape-world/cartoon_top.png"),
      ]

    } else if (state?.collectionList[0]?.collectionType === 31) {
      // 矿工猴盲盒支付成功
      sources = [
        require("@/assets/img/variationApe/blind-front-back.png"),
        require("@/assets/img/variationApe/blind-front-back.png"),
        require("@/assets/img/variationApe/blind-left-right.png"),
        require("@/assets/img/variationApe/blind-left-right.png"),
        require("@/assets/img/variationApe/blind-up-down.png"),
        require("@/assets/img/variationApe/blind-up-down.png"),
      ]

    } else if ([73, 74].includes(state?.collectionList[0]?.collectionType)) {
      sources = [
        require("@/assets/img/workapeBlindboxImg/2.png"),
        require("@/assets/img/workapeBlindboxImg/2.png"),
        require("@/assets/img/workapeBlindboxImg/3.png"),
        require("@/assets/img/workapeBlindboxImg/3.png"),
        require("@/assets/img/workapeBlindboxImg/1.png"),
        require("@/assets/img/workapeBlindboxImg/1.png"),
      ]

    } else if ([78, 79, 80].includes(state?.collectionList[0]?.collectionType)) {
      sources = [
        require("@/assets/img/spaceBeautifulAccount/box-front.png"),
        require("@/assets/img/spaceBeautifulAccount/box-front.png"),
        require("@/assets/img/spaceBeautifulAccount/box-left.png"),
        require("@/assets/img/spaceBeautifulAccount/box-left.png"),
        require("@/assets/img/spaceBeautifulAccount/box-up.png"),
        require("@/assets/img/spaceBeautifulAccount/box-up.png"),
      ]

    } else if ([210, 211, 212, 213, 217, 218, 219, 220, 221, 222].includes(state?.collectionList[0]?.collectionType)) {
      sources = [
        require("@/assets/img/industrialApe/front.jpg"),
        require("@/assets/img/industrialApe/front.jpg"),
        require("@/assets/img/industrialApe/left.png"),
        require("@/assets/img/industrialApe/left.png"),
        require("@/assets/img/industrialApe/up.png"),
        require("@/assets/img/industrialApe/up.png"),
      ]

    }

    if (sources) {
      return (
        <>
          {
            sources.map((item, i) => {
              const directions = ['front', 'rear', 'left', 'right', 'up', 'down'];
              return (
                <div className={classNames('face', directions[i])} key={i}>
                  <img src={item} alt="" />
                </div>
              )
            })
          }
        </>
      )
    }
    return null;
  }

  //设置背景图
  const setbgimg = () => {
    let className = ''
    if (state?.collectionList[0]?.collectionType === 9) {
      className = 'ape_world_bg'
    } else if (blinBox && blinBox.length > 0) {
      //漫画开盲盒背景
      className = 'blinBox'
    } else if (state?.collectionList[0]?.collectionType === 19) {
      // 购买漫画支付成功背景
      className = 'blinBox'
    } else if (state?.collectionList[0]?.collectionType === 31) {
      className = 'variationApe'
    } else if ([73, 74].includes(state?.collectionList[0]?.collectionType)) {
      className = 'workapeBlindbox'
    } else if ([78, 79, 80].includes(state?.collectionList[0]?.collectionType)) {
      className = 'spaceBeautifulAccount'
    } else if ([210, 211, 212, 213, 217, 218, 219, 220, 221, 222].includes(state?.collectionList[0]?.collectionType)) {
      className = 'industrial'
    } else if (state?.from) {
      className = state?.from
    }
    return className
  }
  //设置背景
  const setOutbgimg = () => {
    let className = ''
    if ([210, 211, 212, 213, 217, 218, 219, 220, 221, 222].includes(state?.collectionList[0]?.collectionType)) {
      className = 'white_bg'
    }
    return className
  }

  return (
    <div className={classNames('pay_success', setOutbgimg())}>
      {+state?.showNav === 1 && <NFTHeader midText={state.title} bgColor={'#FFFFFF'} />}
      <div className={classNames('pay_success_bg', setbgimg())}>
        <div className="banner">
          <div className="stage">{returnDiv()}</div>
          <div className={classNames("collectionList", { 'fade-in': CMap[state.from]?.sources ? true : false })}>
            {
              state?.collectionList &&
              <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]} className="mySwiper" onSlideChange={slideChange}>
                {state?.collectionList &&
                  state?.collectionList.map((item, index) => (
                    <SwiperSlide key={index}>
                      {
                        item.collectionType == 19 ?
                          <div className="swiper_item">
                            <img src={item.image} alt="" className="img_19" />
                            <span className={index === activeIndex ? "show_text_19" : "hidden_text"}>
                              <span className="blod_text">{item.collectionName}</span>
                              <br />
                              <span>藏品编号：{item.nftNo}</span>
                            </span>
                          </div> :
                          <div className="swiper_item">
                            <img src={item.image} alt="" />
                            <span className={
                              index === activeIndex ?
                                classNames("text_style", {
                                  text_style__dark: ['europeanCup', 'apeBindBox', 'healthPreservation', 'wxh'].includes(state.from)
                                })
                                : "hidden_text"
                            }>
                              <span className={`blod_text`}>{item.collectionName}</span>
                              <br />
                              <span className={'num_style'}>#{item.nftNo}</span>
                            </span>
                          </div>
                      }
                    </SwiperSlide>
                  ))}
              </Swiper>
            }
            {/* 漫画开盲盒 */}
            {
              blinBox && blinBox.length > 0 &&
              <>
                <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]} className="blinBox_Swiper" onSlideChange={slideChange}>
                  {
                    blinBox.map((item, index) => (
                      <SwiperSlide key={index}>
                        <img src={item.url} alt="" />
                        <span className={index === activeIndex ? "show_text" : "hidden_text"}>
                          <span className="blod_text">{item.title}</span>
                          <br />
                          <span>藏品编号：{item.nftNo}</span>
                        </span>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
                <div className="blinbox_count">共{blinBoxCount || 0}个藏品</div>
              </>
            }
          </div>
        </div>
        <div className="bottom-btns">
          {
            // CMap[state.from]?.collectionId == 223 &&
            // <Button size="large" className="detail_btn" onClick={gotoDetail}>
            //   填写收货地址
            // </Button>
          }
          <Button size="large" className="detail_btn" onClick={gotoDetail}>
            查看藏品详情
          </Button>
        </div>
      </div>
    </div>
  )
}
