import React, {useEffect, useState, useRef} from "react"
import {Mask, Toast} from "antd-mobile"
import "./shopStageRuleDialog.scss"
import {hideLoading, showLoading} from "@/components/loading";
import {getPurchaseList, queryDefCode} from "@/api/projectManager_self";
import {isArray} from "@/utils/publicFun";

let img_sure = require("@/assets/img/egg_bt_lijibaoming.png")

const ShopStageRuleDialog = ({maskVisible, setMaskVisible, type}) => {
    const [title, setRuleTitle] = useState();
    const [content, setContent] = useState();

    const btnClick = () => {
        setMaskVisible(!maskVisible);
    }

    useEffect(() => {
        maskVisible && getProtocol();
    }, [maskVisible]);
    const getProtocol = async () => { //获取协议 // queryDefCode
        try {
            showLoading();
            let params = {
                codeType: 'activityRule',
                code: 'accessoriesSalesRule',
            };
            const res = await queryDefCode(params);
            hideLoading();
            // alert(JSON.stringify(res))
            if (res.code !== '000000') throw res.msg;
            const {data} = res
            if (data.result && data.result.defCode) {
                let defCode = data.result.defCode;
                setRuleTitle(defCode.codeAlias)
                setContent(defCode.otherSign)
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    return (
        <div className={'shop_stage_conatner0'}>
            <Mask visible={maskVisible} opacity={0.8}>
                <div className={'shop_stage_rule_container'}>
                    <div className={'shop_stage_share_container_content'}>
                        <div className={'shop_stage_title'}>{title}</div>
                        <div className={'shop_stage_rule_content1'}>{content}</div>
                        <div className={'shop_stage_rule_sure_btn_father'} onClick={() => btnClick()}>
                            <img src={img_sure}/>
                            <div className={'sure_btn_txt'}>
                                <span>我知道了</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Mask>
        </div>
    )
}

export default ShopStageRuleDialog;
