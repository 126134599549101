import React, {useEffect, useRef, useState} from 'react';
import "./index.scss"
import clickThrottle from "@/utils/clickThrottle";
import ListView from "@/components/AntListView";
import {hideLoading, showLoading} from "@/components/loading";
import {quizCouponMainInit} from "@/api/projectManager_self";
import {isEmptyString} from "@/utils/publicFun";
import {Toast} from "antd-mobile";
import NoticeUtil from "@/utils/NoticeUtil";
import ExchangeGuessBondDialog from "@/view/guessingActy/components/dialog/exchangeGuessBondDialog";
import {guess_getBond_success} from "@/constant";
import {useNavigate} from "react-router-dom";


const kVoteCount = 'kVoteCount';
const EpicBoringApe = (props) => {
    const navigate = useNavigate();
    const [dataList, setDataList] = useState([])
    const [selData, setSelData] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const currentPage = useRef(1);
    const maxCountRef = useRef(10);

    useEffect(() => {
        loadData();
        NoticeUtil.on(guess_getBond_success, goSession);
        return () => {
            NoticeUtil.removeListener(guess_getBond_success, goSession);
        }
    }, []);

    const goSession = ({type}) => {
        if (type == 1) {
            navigate(`/guessingActy/sessionDetail?whichFrom=1`, {state: {session: props.session}})
        } else {
            currentPage.current = 1;
            loadData();
        }
    }

    const loadData = async () => {
        try {
            showLoading()
            let params = {currentPage: currentPage.current, pageSize: 20};
            const res = await quizCouponMainInit(params);
            hideLoading();
            setIsLoad(true);
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let dataTem = res.data;
                let record = dataTem.record || [];
                let voteCount = dataTem.voteCount || 0;
                if (currentPage.current > 1) {
                    setDataList([...dataList, ...record]);
                } else {
                    setDataList(record);
                    setSelData([]);
                    NoticeUtil.emit(kVoteCount, {voteCount: voteCount})
                }
                setHasMore(record.length >= 20);
            }
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const loadMore = async () => {
        currentPage.current = currentPage.current + 1
        await loadData()
    }

    const itemClick = (item) => {
        // if (!clickThrottle(1000)) {
        //     return
        // }
        if (item.isSel) { //取消选中
            item.isSel = !item.isSel;
        } else { //选中新的
            let selDataTem = dataList.filter(item => item.isSel === true);
            if (selDataTem.length >= maxCountRef.current) {
                Toast.show(`每次最多可选择${maxCountRef.current}个兑换`);
                return;
            }
            item.isSel = !item.isSel;
        }
        setDataList([...dataList]);
        let arr = [];
        for (const item1 of dataList) {
            if (item1.isSel) {
                arr.push(item1);
            }
        }
        setSelData(arr);
    }

    const btnClick = () => {
        if (selData.length > 0) {
            ExchangeGuessBondDialog.open(selData)
        }
    }

    const renderItem = (item) => {
        return (
            <div className={'list_item'} onClick={() => itemClick(item)}>
                {item.isSel ? (
                    <>
                        <img src={require('../../../imgs/icon_fram_sel.png')} alt="" className={'monkey_img_sel'}/>
                        <img src={item.nftImage} alt="" className={'monkey_img'}/>
                    </>
                ) : (
                    selData.length >= maxCountRef.current ? <>
                        <div className={'monkey_img_zhezhao'}/>
                        <img src={item.nftImage} alt="" className={'monkey_img'}/>
                    </> : <img src={item.nftImage} alt="" className={'monkey_img'}/>
                )
                }
                <p>{item.nftNo}</p>
            </div>
        )
    }

    return (
        <div className={'epic_boring_ape_container'}>
            <ListView
                dataSource={dataList}
                renderItem={renderItem}
                onRefresh={() => {
                    currentPage.current = 1;
                    loadData()
                }}
                hasMore={hasMore}
                onEndReached={loadMore}
                style={{paddingTop: 50}}
                isLoad={isLoad}
            />
            {
                dataList.length ? <div className={'duihuan_btn_container'}>
                    <div className={'duihuan_btn'}
                         onClick={() => btnClick()}>{`兑换入场券（已选${selData.length}）`}</div>
                </div> : null
            }

        </div>
    )
}


export default EpicBoringApe;
