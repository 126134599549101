/**
 * 描述： 我的订单
 * 同myOrder, 不同之处每个可能有多个商品
*/
import React, { useState, useEffect, useRef } from "react"
import OrderItem from "./components/OrderItem"
import "./index.scss"
import { getGroupOrderList } from '@/api/projectManager_self';
import { showLoading, hideLoading } from '@/components/loading';
import { Tabs, Toast } from "antd-mobile"
import ListView from "@/components/ListView";
import mockFn from "./mock";
import emptyImg from '@/assets/img/pic_dindan.png';

const tabItems = [
  { key: "ALL", title: "全部" },
  { key: "NOTPAY", title: "待付款" },
  { key: "SUCCESS", title: "已付款" },
  { key: "CANCEL", title: "已取消" },
]

function MyGroupOrder(props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [orderList,  setOrderList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(10);

  const bridge = window.JsBridge
  
  useEffect(() => {
    bridge.setTitle("我的订单");
    queryOrderList(tabItems[activeIndex].key === 'ALL' ? '': tabItems[activeIndex].key);
  }, [activeIndex])

  const queryOrderList = async (type) => {
    try {
      showLoading();
      currentPage.current = 1;
      const params = {
        orderStatus: type,
        page: currentPage.current,
        pageSize: pageSize.current
      }
      const { code, msg, data } = await getGroupOrderList(params);
      
      // const { code, msg, data } = await mockFn('ALL');
      hideLoading();
      if(code !== '000000') throw msg;
      setOrderList(data.list || []);
      setHasMore(data.total > data.list?.length);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const loadMore = async () => {
    try {
      currentPage.current = currentPage.current + 1;
      const params = {
        orderStatus: tabItems[activeIndex].key === 'ALL' ? '': tabItems[activeIndex].key,
        page: currentPage.current,
        pageSize: pageSize.current
      }
      const { code, msg, data } = await getGroupOrderList(params);
      // const { code, msg, data } = await mockFn(tabItems[activeIndex].key);
      if(code !== '000000') throw msg;
      setOrderList([...orderList, ...data.list]);
      setHasMore(data.total > [...orderList, ...data.list].length)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="order-page">
      <div className="tab_sticky">
        <Tabs
          activeKey={tabItems[activeIndex].key}
          onChange={(key) => {
            const index = tabItems.findIndex((item) => item.key === key)
            setActiveIndex(index)
          }}
        >
          {tabItems.map((item) => (
            <Tabs.Tab title={<div>{item.title}</div>} key={item.key}></Tabs.Tab>
          ))}
        </Tabs>
      </div>
      <ListView 
        onRefresh={() => queryOrderList(tabItems[activeIndex].key === 'ALL' ? '': tabItems[activeIndex].key)} 
        list={orderList} 
        hasMore={hasMore} 
        loadMore={loadMore} 
        ListItem={OrderItem}
        style={{paddingTop: 200}}
      />
    </div>
  )
}

export default MyGroupOrder
