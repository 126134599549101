/**
 * @description 批量转化
 * @returns {/boxToStone/batchTransform}
*/
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, Radio, Toast } from "antd-mobile";
import ModalAlert from "@/components/ModalAlert";
import NFTHeader from '@/components/NFTHeader';
import { hideLoading, showLoading } from '@/components/loading';
import { getUrlAllParams } from "@/utils/publicFun";
import Service from '../service';
import Styles from './index.module.scss';
import JsBridgeNew from '@/utils/jsbridgeNew';

const mockData = [
    { productName: '这是实物商品名称1', conversionNumber: 90, id: 1}, { productName: '这是实物商品名称2', conversionNumber: 90, id: 2 },
    { productName: '这是实物商品名称3', conversionNumber: 90, id: 3 }, { productName: '这是实物商品名称4', conversionNumber: 90, id: 4 },
];

const BatchTransform = () => {
    const navigate = useNavigate();
    const {search} = useLocation()
    const param = getUrlAllParams(search.slice(1));
    const {orderNo} = param;

    const [dataSource, setDataSource] = useState(process.env.NODE_ENV !== 'development' ? mockData : []);
    const [checkAll, setCheckAll] = useState(false);
    const pageDom = useRef(null);
    useEffect(() => {
        getData(true);
    }, []);
    const getData = async (loading = false) => {
        try {
            if (loading) showLoading();
            const res = await Service.getOrderInfo({orderId: orderNo});
            if (loading) hideLoading();
            if (!res) throw res.msg;
            setDataSource(res.data);
        } catch (error) {
            hideLoading();
            error && Toast.show(error);
        }
    }

    const onRadioChange = (item) => {
        const list = dataSource.map(data => {
            if(data.id === item.id) {
                data.checked = !data.checked
            }
            return data;
        })
        setDataSource(list)
        const all = dataSource.every(item => item.checked)
        setCheckAll(all)
    }
    const onSelectAll = (e) => {
        const list = dataSource.map(item => {
            item.checked = e;
            return item
        })
        setDataSource(list);
        setCheckAll(e)
    }

    const onTransformClick = ()=> {
        if(totalCount > 0) {
            const content = (
                <div style={{padding: '10px 0', textAlign:'center'}}>
                    {`确认将选中商品转化，获得${totalCount}宝石？选择转化后视为放弃实物商品`}
                </div>
            )
            ModalAlert.show({
                getContainer: pageDom.current,
                title: '转化为宝石',
                content: content,
                onAction: async (btn, index) => {
                    if(btn.key === 'confirm') {
                        const ids = dataSource.filter(item => item.checked).map(item => item.id);
                        // 调用合成接口
                        showLoading();
                        const res = await Service.conversionStone({
                            orderId: orderNo,
                            ids:ids,
                            conversionNum: totalCount 
                        });
                        hideLoading();
                        if(res) {
                            ModalAlert.hide();
                            if(JsBridgeNew.hasWebViewBridge()){
                                JsBridgeNew.runAction('alert', 'error', ['转化成功,请至guland查看']);
                                JsBridgeNew.back();
                            }else {
                                Toast.show({
                                    icon: 'success',
                                    content: '转化成功,请至guland查看',
                                    afterClose: () => {
                                        // 返回
                                        navigate(-2);
                                    }
                                });
                            }
                            
                        }else {
                            Toast.show({
                                icon: 'fail',
                                content: '转化失败'
                            });
                        }
                    }else {
                        ModalAlert.hide();
                    }
                   
                },
                actions: [
                    { key: 'cancel', text: '取消' },
                    { key: 'confirm', text: '确认转化' }
                ]
            })
        }else {
            Toast.show('请选择要转化的商品');
        }
    }
    
    const totalCount = useMemo(() => {
        const count = dataSource.filter(item => item.checked).reduce((pre, cur) => pre + cur.conversionNumber, 0);
        return count;
    },[dataSource])

    return (
        <div className={Styles.batchTransform} ref={pageDom}>
            <NFTHeader midText={`批量转化`} bgColor={'#FFFFFF'} />
            <div className={Styles.batchContent}>
                <img className={Styles.bgimg} src={require('@/assets/img/ape/buy_bg.png')} alt="" />
                <div className={Styles.batchList}>
                    {
                        dataSource?.map((item, index) => {
                            return (
                                <div className={Styles.batch_item} key={index}>
                                    <div className={Styles.check}>
                                        <Radio style={{
                                            '--icon-size': '18px',
                                            '--font-size': '14px',
                                            '--gap': '6px',
                                        }}
                                            checked={item.checked}
                                            onChange={()=>onRadioChange(item)}
                                        />
                                    </div>
                                    <div className={Styles.item_content}>
                                        <img className={Styles.itemImg} src={item.productImgUrl} alt=''></img>
                                        <div>
                                            <span>{item.productName}</span>
                                            <span>{`${item.conversionNumber ?? 0} 宝石`}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        dataSource?.length === 0 &&
                        <div className={Styles.empty}>
                            <img src={require('@/assets/img/noCollection.png')} alt="" />
                            <span>已经没有可以转化的商品了</span>
                        </div>
                    }
                </div>
            </div>
            {
                dataSource.length > 0 &&
                <div className={Styles.bottom_btn}>
                    <Radio style={{
                        '--icon-size': '18px',
                        '--font-size': '14px',
                        '--gap': '6px',
                    }}
                        checked={checkAll}
                        onChange={onSelectAll}
                    >全部</Radio>
                    <div className={Styles.btn} onClick={onTransformClick}>
                        <span>确认转化宝石</span>
                    </div>
                    <span>{`当前已选可转化 ${totalCount} 个宝石`}</span>
                </div>
            }
            
        </div>
    )
}

export default BatchTransform;