/**
 * @description: 我的藏品详情
 * @info 接口比较乱，修改需谨慎
*/
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { Toast } from "antd-mobile";
import {
  queryCollectionDetail,
  selRarityList,
  getswitchStatus,
  getMergeSwitchStatus,
  itCanBeSynthesized
} from '@/api/projectManager_self';
import { spaceNiceNoUse, cancelUseSpaceNiceNo, hasSpaceNiceNoUsed } from '@/api/activity';
import { getUrlAllParams, isArray } from "@/utils/publicFun";
import Error from "@/view/Error/index";
import { showLoading, hideLoading } from '@/components/loading';
import NFTHeader from "@/components/NFTHeader";
import confirmModal from "./components/confirmModal";
import submitModal from "./components/submitModal";
import CollectionHeader from './components/collectionHeader';
import {CollectionTitle, CollectionNo} from './components/collectionTitle';
import RarityView from './components/rarityView';
import { CollectorInfo } from './components/collectorInfo';
import CollectionMsg from './components/collectionMsg';
import { TechnologyInfo } from './components/technologyInfo';
import { Footer } from './components/footer';
import "./index.scss";

const CodeMap = {
  207: 'healthPreservation', //康养藏品
}

const ratio = window.screen.width / 375;

function MyCollectionDetail() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { nft_hash = '', isAll = false, clientId = '', userImage = "" } = getUrlAllParams(search.slice(1));

  const [detailList, setDetailList] = useState(null)
  const [errorContext, seterrorContext] = useState("")
  const [rarityVOS, setRarityVOS] = useState([]);//稀有度的列表
  const [hcStatus, setHcStatus] = useState({status: 0});//合成按钮状态 1可以 其他不行
  const colInfo = useRef({});
  const [spHeChengInfo, setSpHeChengInfo] = useState(false);//碎片是否能合成
  const pageDom = useRef(null);

  useEffect(() => {
    window.scroll(0, 0);
    window.JsBridge.setTitle("藏品详情");
    if (!nft_hash) {
      Toast.show("参数错误")
      seterrorContext("参数错误")
      return;
    }
    loadData();

  }, []);

  const loadData = ()=> {
    queryDetail().then(() => {
      suiPianHeCheng();
      if(colInfo.current?.collectionId === 207) {
        getMergeSwitchStatusInfo();
      }else {
        getSwitchStatus();
      }
    });
    queryRarityVOS();
  }

  const queryDetail = async () => {
    try {
      showLoading();
      const res = await queryCollectionDetail({ nftHash: nft_hash, clientId: (isAll == "true") ? clientId : '' });
      // const res = await mockFn();
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      setDetailList(res.data.info);
      colInfo.current = res.data.info;
    } catch (error) {
      hideLoading();
      seterrorContext(error);
      Toast.show(error);
    }
  }

  const queryRarityVOS = async () => {
    try {
      showLoading();
      const res = await selRarityList({ nftHash: nft_hash, clientId: (isAll == "true") ? clientId : '' });
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      if (res.data && res.data.rarityVOS && isArray(res.data.rarityVOS) && res.data.rarityVOS.length > 0) {
        setRarityVOS(res.data.rarityVOS)
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const getMergeSwitchStatusInfo = async () => {
    try {
      showLoading();
      const res = await getMergeSwitchStatus({ 
        activityCode: CodeMap[colInfo.current?.collectionId || ''],
        collectionId: colInfo.current?.collectionId || '',
      });
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      setHcStatus({...hcStatus, ...res.data});
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const getSwitchStatus = async () => {
    try {
      showLoading();
      const res = await getswitchStatus({ code: 'COMPOSE' });
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      setHcStatus({...hcStatus, status: res.data});
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const suiPianHeCheng = async () => {
    console.log('colInfo.current---', colInfo.current)
    try {
      const params = {
        petNo: 'debrisSynthesisBefore',
        collectionId: colInfo.current?.collectionId || '',
      }
      const res = await itCanBeSynthesized(params);
      if (res.code !== '000000') throw res.msg;
      setSpHeChengInfo(res.data);
    } catch (error) {
      Toast.show(error);
    }
  }

  const beautifulNumUse = async () => {
    try {
      showLoading()
      const res = await spaceNiceNoUse({
        collectionId: detailList?.collectionId,
        nftNo: detailList?.nftNo,
      })
      hideLoading()
      if (res.code !== '000000') throw res.msg;
      Toast.show({
        content: '使用成功',
        afterClose: async () => {
          await queryDetail();
        }
      });
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const cancelUseBeautifulNum = async () => {
    try {
      showLoading()
      const res = await cancelUseSpaceNiceNo({ spaceNiceNo: detailList?.nftNo })
      hideLoading()
      if (res.code !== '000000') throw res.msg;
      Toast.show({
        content: '取消成功',
        afterClose: async () => {
          await queryDetail();
        }
      });
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const onUseBeautifulNumBtn = async () => {
    if (detailList?.useStatus === 2) {
      return
    }
    if (detailList?.useStatus === 0) {
      try {
        showLoading()
        const res = await hasSpaceNiceNoUsed({
          collectionId: detailList?.collectionId,
          nftNo: detailList?.nftNo,
        })
        hideLoading()
        if (res.code !== '000000') throw res.msg;
        if (res.data.used) {
          replaceBeautifulNum()
        } else {
          if (detailList?.collectionId === 80) {
            inputNumModal()
          } else {
            handleBeautifulNumUse()
          }
        }
      } catch (error) {
        hideLoading()
        Toast.show(error)
      }
    } else if (detailList?.useStatus === 1) {
      cancelUseSpaceNiceNum()
    } else if (detailList?.useStatus === 3) {
      inputNumModal()
    }
  }

  const replaceBeautifulNum = async () => {
    confirmModal({
      pageDom: pageDom.current,
      title: '使用编号',
      content: '同一时间不可叠加使用，确认替换当前编号吗',
      leftBtn: {
        text: '取消',
        onClick: () => {
          console.log('quxiao')
        }
      },
      rightBtn: {
        text: '确认替换',
        onClick: () => {
          beautifulNumUse()
        }
      }
    })
  }

  const cancelUseSpaceNiceNum = () => {
    confirmModal({
      pageDom: pageDom.current,
      title: '取消使用',
      content: '取消使用后编号恢复为原编号',
      leftBtn: {
        text: '取消',
        onClick: () => {
          console.log('quxiao')
        }
      },
      rightBtn: {
        text: '取消使用',
        onClick: () => {
          cancelUseBeautifulNum()
        }
      }
    })
  }

  const handleBeautifulNumUse = () => {
    confirmModal({
      pageDom: pageDom.current,
      title: '使用编号',
      content: '使用后空间id及区块链地址统一为该编号，取消使用/转赠后编号恢复为原编号',
      leftBtn: {
        text: '取消',
        onClick: () => {
          console.log('quxiao')
        }
      },
      rightBtn: {
        text: '确认使用',
        onClick: () => {
          beautifulNumUse()
        }
      }
    })
  }

  const inputNumModal = () => {
    submitModal({
      pageDom: pageDom.current,
      detailInfo: detailList,
      leftBtn: () => console.log('cancel'),
      rightBtn: async () => {
        await queryDetail()
      },
    })
  }

  let styleDistance = { paddingBottom: 12 * ratio * 10 }
  if (isAll == "true") {
    styleDistance = { paddingBottom: 10 }
  }

  return (
    <div className="collectioin_detail" ref={pageDom}>
      <NFTHeader midText={'藏品详情'} bgColor={'white'} />
      {!errorContext ? (
        <>
          <CollectionHeader detailList={detailList} />
          <div className="collection-detail-content" style={styleDistance}>
            <CollectionTitle detailList={detailList}/>
            {
              rarityVOS.length > 0 && 
              <RarityView rarityVOS={rarityVOS}/>
            }
            <CollectionNo detailList={detailList}/>
            {
              isAll == "true" &&
              <CollectorInfo userImage={userImage} detailList={detailList} />
            }
            <CollectionMsg detailList={detailList} />
            {
              !!detailList?.targetNftNo && detailList?.parent == 2 &&
              <div className="technology-message">
                <div className="head" onClick={() => {
                  navigate(`/secondCreationAgreement`)
                }}>
                  <span className="bar">二次创作及商业使用权说明</span>
                  <span className="icon">
                    <img src={require("@/assets/img/collection/goto.png")} alt="avatar" />
                  </span>
                </div>
              </div>
            }
            {
              !(isAll == "true") &&
              <TechnologyInfo detailList={detailList} />
            }
          </div>
          {
            !(isAll === "true") && !(!!detailList && !!detailList?.targetNftNo && detailList?.parent == 2) && (detailList?.collectionId !== 60) &&
            <Footer nft_hash={nft_hash} detailList={detailList}
              spHeChengInfo={spHeChengInfo} hcStatus={hcStatus}
              onRefesh={queryDetail}
              onUseBeautifulNumBtn={onUseBeautifulNumBtn}
            />
          }
        </>
      ) : (
        <Error context={errorContext} />
      )}
    </div>
  )
}

export default MyCollectionDetail
