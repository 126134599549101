import React from "react"
import { InfiniteScroll, PullToRefresh, DotLoading } from "antd-mobile"
import "./index.scss";
import Empty from '../empty';
import noData from '@/assets/img/noCollection.png';

const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : null}
    </>
  )
}

const ListView = ({ListItem, loadMore, hasMore, list, onRefresh, maxDay, emptyImg, style}) => {
  return (
    <div style={{ overflowY: "scroll"}}>
      <div className="refresh">
        <PullToRefresh 
          onRefresh={onRefresh}
          headHeight={70}
          threshold={100}
          pullingText={
            <img 
              src={require('@/assets/img/new-loading.gif')}
              style={{height: 50}} alt=''
            />
          }
          refreshingText={
            <img
              src={require('@/assets/img/new-loading.gif')}
              style={{height: 50}} alt=''
            />
          }
          canReleaseText={
            <img
              src={require('@/assets/img/new-loading.gif')}
              style={{height: 50}} alt=''
              />
            }
          renderText={null}
        >
          {list?.length ? (
            list.map((item, index) => <ListItem key={index} collection={item} maxDay={maxDay}></ListItem>)
          ) : (
            <Empty image={emptyImg || noData} description="暂无数据" style={style} />
          )}
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </PullToRefresh>
      </div>
    </div>
  )
}

export default ListView
