/**
 * 订单商品价格
 * **/
import { useState, useMemo, useEffect } from 'react'
import { round, computeNumber } from "@/utils/publicFun"
import { getUserInfo } from '@/api/projectManager_self';
import CouponModal from '../CouponModal'
import icon_radio from '@/assets/img/icon/icon-radio.png'
import icon_radio_checked from '@/assets/img/icon/icon-radio-checked.png'
import styles from './styles.module.scss'

export default ({
  originalPrice = 199,
  couponList = [],
  usePoint = false, // 使用积分
  pointRatio = 100,
  maxPointDiscount = 2000,
  onChange = () => {},
  count = 1
}) => {
  const [coupon, setCoupon] = useState({})
  const [pointDiscount, setPointDisCount] = useState(0) // 积分折扣
  const [usePointChecked, setUsePointChecked] = useState(false) // 积分折扣选中
  const [myPoint, setMyPoint] = useState(0) // 我的积分
  const totalPrice = useMemo(() => {
    const deductionPrice = computeNumber(pointDiscount, "/", pointRatio || 1).result;
    return Math.max(0, originalPrice - deductionPrice - (coupon?.discount || 0))
  },
  [originalPrice, coupon, pointDiscount])

  useEffect(() => {
    if (usePoint) {
      getUserInfo().then(({ data }) => setMyPoint(data?.point || 0))
    }
  }, [usePoint])

  useEffect(() => {
    if (usePointChecked) {
      const canUsedMax = Math.min(myPoint, maxPointDiscount * count)
      // 如果按照整数抵扣 Math.floor(canUsedMax / pointRatio) * pointRatio;
      setPointDisCount(canUsedMax)
      onChange('point', canUsedMax);
    }
  }, [count])

  const onCouponChange = (val) => {
    onChange('coupon', val);
    setCoupon(val);
  }
  const toggleUsePoint = () => {
    if (usePointChecked) {
      setUsePointChecked(false)
      setPointDisCount(0)
      onChange('point', 0);
    } else {
      setUsePointChecked(true)
      const canUsedMax = Math.min(myPoint, maxPointDiscount * count)
      // 如果按照整数抵扣 Math.floor(canUsedMax / pointRatio) * pointRatio;
      setPointDisCount(canUsedMax)
      onChange('point', canUsedMax);
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div>商品金额</div>
        <div>¥{round(originalPrice, 2)}</div>
      </div>
      {
        !usePoint && (
          <CouponModal couponList={couponList} onChange={onCouponChange} value={coupon.couponCode || -1}>
            <div className={styles.discountBox}>
              <div className={styles.row}>
                <div>优惠券</div>
                <img className={styles.icon} src={!coupon.couponCode ? icon_radio : icon_radio_checked} />
              </div>
              {
                coupon.couponCode && <div className={styles.greyText}> {coupon.discount}元优惠券 </div>
              }
            </div>
          </CouponModal>
        )
      }
      {
        usePoint && (
          <div className={styles.discountBox} onClick={toggleUsePoint}>
            <div className={styles.row}>
              <div>积分抵扣 <span className={styles.smaller}>（最多抵扣{maxPointDiscount * count}积分）</span></div>
              <img className={styles.icon} src={usePointChecked ? icon_radio_checked :  icon_radio} />
            </div>
            <div className={styles.greyText}> 我的积分: {myPoint} ({pointRatio}积分可抵扣1元) </div>
          </div>
        )
      }
      <div className={styles.footer}>
        <div>总计：
          {
            (usePoint && usePointChecked) && <span className={styles.greyText}>积分抵扣：{pointDiscount} (-¥{computeNumber(pointDiscount, "/", pointRatio || 1).result})</span>
          }
          {
            coupon.couponCode && <span className={styles.greyText}>优惠券抵扣：{coupon.discount} (-¥{round(coupon.discount, 2)})</span>
          }
        </div>
        <div>¥{round(totalPrice, 2)}</div>
      </div>
    </div>
  )
}