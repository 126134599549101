/**
 * @description GR-2407658盲盒换宝石相关接口
 * 
*/
import appService from '@/utils/appService';
import requests from '@/utils/requests_self';
import { 
    getActivityTime,
    queryDefCode, 
 } from "@/api/projectManager_self";
import JsBridgeNew from '@/utils/jsbridgeNew';
class Service {

    static activityCode = 'blindBoxPro';

    /**
     * @description 获取活动规则
     * @param {*} params 
     * @returns
    */
    static getActivityRules = async ()=> {
        // const res = await appService('101193', {
        //     activityCode: Service.activityCode,
        //     codeType: 'activityRule',
        //     code: Service.activityCode,
        // });
        // return res;
        return await queryDefCode({codeType: 'activityRule', code: Service.activityCode});
    }

    /**
     * @description 获取用户信息，是否实名
     * @returns {ID: 存在则实名}
     */ 
    static getUserCertificateStatus = async () => {
        if (window.JsBridge.hasWebViewBridge()) {
            const res = await window.JsBridge.getDetailCurrentUserInfo()
            return res;
        } else {
            return {};
        }
    }

    /**
     * @description 获取盲盒换宝石活动信息
     * @param {Object} params
     * @code 600828
     * @returns {}
    */
    static getInitData = async (params) => {
        // const code = '600828';
        // const param = {
        //     activityCode: Service.activityCode,
        //     ...params,
        // }
        // const res = await appService(code, param)
        // return res;
        params = { activityCode: Service.activityCode }
        return await getActivityTime(params);
    }

    /**
     * @description 获取购买记录
     * @param {activityCode, pageNum, pageSize, userCode} params
     * @code 600881
     * @returns {}
    */
    static getBuyRecord = async (params={})=> {
        const code = '600881';
        const userInfo = await JsBridgeNew.getCurrentUserInfo();
        const param = {
            activityCode: Service.activityCode,
            userCode: userInfo?.loginName,
            ...params,
        }
        const res = await appService(code, param)
        return res;
    }
    /**
     * @description 获取盲盒商品详情
     * @param {activityCode} params
     * @code 600884
     * @returns {}
    */
    static getProductInfo = async (params={})=> {
        const code = '600884';
        const param = {
            activityCode: Service.activityCode,
            ...params,
        }
        const res = await appService(code, param);
        return res;
    }
    /**
     * @description 提交订单
     * @param {activityCode, userCode, totalPrice, addressInfoRequest:{},productItemRequests:[{price, num, productId}] } params
     * @code 600880
     * @returns {}
    */
    static commitOrder = async (params={})=> {
        const userInfo = await JsBridgeNew.getCurrentUserInfo();
        const code = '600880';
        const param = {
            activityCode: Service.activityCode,
            userCode: userInfo?.loginName,
            ...params,
        }
        const res = await appService(code, param)
        return res;
    }
    /**
     * @description 获取订单商品列表
     * @param {activityCode, orderId, userCode} params
     * @code 600882
     * @returns {}
    */
    static getOrderInfo = async (params={})=> {
        const userInfo = await JsBridgeNew.getCurrentUserInfo();
        const code = '600882';
        const param = {
            activityCode: Service.activityCode,
            userCode: userInfo?.loginName,
            ...params,
        }
        const res = await appService(code, param)
        return res;
    }
    /**
     * @description 转换宝石接口
     * @param {orderId, userCode, conversionNum, ids:[]} params
     * @code 600883
     * @returns {}
    */
    static conversionStone = async (params={})=> {
        const userInfo = await JsBridgeNew.getCurrentUserInfo();
        const code = '600883';
        const param = {
            activityCode: Service.activityCode,
            userCode: userInfo?.loginName,
            ...params,
        }
        const res = await appService(code, param)
        return res;
    }

    /**
     * @description 返回事件
    */
    static onBack = () => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.back();
        } else {
            window.history.go(-1);
        }
    }
}

export default Service;