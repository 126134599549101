import React, {useState, useEffect, useRef, useContext} from 'react';
import styles from './countDown.module.scss';
import { residue12Hours } from '../utils';
import { LandDetailContext } from "../landDetail/context";

const CountDown = ({sysTime, placeTime, refreshData, isTomorrow}) => {
    const initalTime = {hour: '00', minute: '00',};
    const [countTime, setCountTime] = useState(initalTime);
    const timeStampCurrent = useRef(null);
    const timerCurrent = useRef(null);
    const totalTime = 12*60*60*1000;
    const { refreshExp } = useContext(LandDetailContext)

    const uploadExp = async () => {
      console.log('更新经验值')
      await refreshExp()
    }

    const countDown = (currentTime, placeTime, callBack) => {
      if (!currentTime || !placeTime) {
        return initalTime;
      }
      const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))
      if (timeStampCurrent.current == null) {
        timeStampCurrent.current = currentTimeStamp
      }
      let distanceStamp = 0
      const placeTimeStamp = Date.parse(placeTime.replace(/-/g, '/'))
      if (isTomorrow) {
        distanceStamp = residue12Hours(timeStampCurrent.current)
      } else {
        distanceStamp = totalTime - (timeStampCurrent.current - placeTimeStamp);
      }
      if (distanceStamp <= 0) {
        callBack && callBack(distanceStamp);
        return initalTime;
      }
      let hour = Math.floor((distanceStamp / 1000 / 60 / 60));
      let minute = Math.ceil((distanceStamp / 1000 / 60) % 60);
      hour = minute === 60 ? hour + 1: hour
      minute = minute === 60 ? 0: minute
      return {hour: hour, minute: minute};
    }

    const timerStart = () => {
      timerCurrent.current && clearInterval(timerCurrent.current);
      timerCurrent.current = setInterval(() => {
        if (timeStampCurrent.current) {
          timeStampCurrent.current = timeStampCurrent.current + 1000;
        }
        let obj = countDown(sysTime, placeTime, (distanceStamp) => {
          timerCurrent.current && clearInterval(timerCurrent.current);
          if (distanceStamp === 0) {
              uploadExp()
              refreshData && refreshData();
          }
        });
        setCountTime(obj);
      }, 1000)
    }

    useEffect(() => {
      timeStampCurrent.current = null;
      timerStart();
      return () => {
        timerCurrent.current && clearInterval(timerCurrent.current); //先清空之前的定时器
      }
    }, [sysTime, placeTime])
    const {hour, minute} = countTime;

    if (hour === '00' && minute === '00') {
      return null
    }
    return (
      <div className={styles.land_count_down}>{hour > 0 ? `${hour}小时`: ''}{minute > 0 ? `${minute}分钟`: ''}</div>
    )
}

export default CountDown;
