import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import "./index.scss"
import { round, getUrlAllParams, throttle } from "@/utils/publicFun"
import { Button, Toast, Checkbox } from "antd-mobile"
import qs from 'query-string';
import descEgg from '@/assets/img/ape-world/egg_img_xiangqing.png';
import xqEgg from '@/assets/img/ape-world/egg_img_xiangqing1.png';
import ShareMask from "@/components/ShareMask/index"

//currentState: 0=活动未开始 1=报名阶段 2=报名截止,未开始第一轮抽签 3=第一轮抽签 4=第一轮抽签结束,未开始第二轮抽签, 5=第二轮抽签 6第二轮抽签结束,未开始公开售 7=公开售阶段 8=活动结束
//currentOperate: 0:未报名，1:已报名 2:未中签，3:中签未支付，4:中签已支付, 5:超时未购买
//stock 公开售是否售罄

function ApeWorldSaleDetail() {
  const navigate = useNavigate();
  const [userAgree, setUserAgree] = useState(false);
  const { search } = useLocation();
  const [maskVisible, setMaskVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [btnText, setBtnText] = useState('');
  const bridge = window.JsBridge;
  window.history.scrollRestoration = "manual";

  const { currentState='', currentOperate='', remainingPurch='', stock='', isLogin='' } = getUrlAllParams(search.slice(1));

  useEffect(() => {
    if(isLogin === '0') {
      if(currentState === '7') {
        setIsDisabled(false);
        setBtnText('立即购买');
      } else if(currentState === '8') {
        setIsDisabled(true);
        setBtnText('活动已结束');
      } else {
        setIsDisabled(true);
        setBtnText('中签购买');
      }
    } else {
      if(currentState === '8') {
        setIsDisabled(true);
        setBtnText('活动已结束');
      } else if(currentState === '7') {
        if(Number(stock) > 0) {
          setIsDisabled(false);
          setBtnText('立即购买');
        } else {
          setIsDisabled(true);
          setBtnText('已售罄');
        }
      } else if (['3', '5'].includes(currentState)) {
        if(currentOperate === '3') {
          setIsDisabled(false);
          setBtnText('立即购买');
        } else if (currentOperate === '4') {
          if(remainingPurch > 0) {
            setIsDisabled(false);
          } else {
            setIsDisabled(true);
          }
          setBtnText('立即购买');
        } else if(currentOperate === '5') {
          setIsDisabled(true);
          setBtnText('立即购买');
        } else if(currentOperate === '100'){
          setIsDisabled(false);
          setBtnText('立即领取');
        } else {
          setIsDisabled(true);
          setBtnText('中签购买');
        }
      } else if (['4', '6'].includes(currentState)) {
        setIsDisabled(true);
        setBtnText('立即购买');
      } else {
        setIsDisabled(true);
        setBtnText('中签购买');
      }
    }
  }, []);

  const gotoSalePay = () => {
    const params = {
      currentState,
      currentOperate,
      remainingPurch,
      stock
    }
    if (bridge.hasWebViewBridge()) {
      bridge.push("Web", { uri: `${window.location.origin}/apeWorldSalePay?${qs.stringify(params)}` });
    } else {
      navigate(`/apeWorldSalePay?${qs.stringify(params)}`);
    }
  }
  const goAgreement = () => {
    if (bridge.hasWebViewBridge()) {
      bridge.push("Web", { uri: `${window.location.origin}/MyAgreement` })
    } else {
      navigate(`/MyAgreement`)
    }
  }

  const snapUp = () => {
    if(isDisabled) return;
    if (userAgree === true) {
      if (bridge.hasWebViewBridge()) {
        bridge.verifyRealName("请先实名认证").then(function (res) {
          if (res?.result) {
            gotoSalePay();
          }
        })
      } else {
        Toast.show("请至APP内购买")
      }
    } else {
      Toast.show("请先勾选《绿地数字藏品许可及服务协议》")
    }
  }

  const goBack = () => {
    if (bridge.hasWebViewBridge()) {
      bridge.back()
    } else {
      navigate(-1)
    }
  }

  const share = async () => {
    setMaskVisible(true);
  }

  const throttleShare = throttle(share, 3000)

  const returnDiv = () => {
    return (
      <>
        <div className="content_display display_ape_world">
          <div className="stage">
            <div className="face front">
              <img src={require("@/assets/img/ape-world/box2.png")} alt="" />
            </div>
            <div className="face rear">
              <img src={require("@/assets/img/ape-world/box2.png")} alt="" />
            </div>
            <div className="face left">
              <img src={require("@/assets/img/ape-world/box56.png")} alt="" />
            </div>
            <div className="face rightface">
              <img src={require("@/assets/img/ape-world/box56.png")} alt="" />
            </div>
            <div className="face up">
              <img src={require("@/assets/img/ape-world/box3.png")} alt="" />
            </div>
            <div className="face down">
              <img src={require("@/assets/img/ape-world/box3.png")} alt="" />
            </div>
          </div>
        </div>
      </>
    )
  }
  return (
    <div className="detail_b">
      <ShareMask
        maskVisible={maskVisible}
        closeMask={() => setMaskVisible(false)}
        shareCode=''
        title=''
        context=''
        icon=''
        type='9'
      />
      <div className="detail_bc"></div>
      <div className="back_btn" onClick={goBack}></div>
      <div className="right_btn" onClick={throttleShare}></div>
      <div className="detail_banner">
        <div className="detail_main">
          <div className="content content_opcity">
            <div className="content_title">绿地Ape World数字藏品</div>
            <div className="content_banner">
              <div className="stage"></div>
              {returnDiv()}
              <div className="content_series"></div>
              {
                currentOperate === '100' ? <p className="content_text">
                <span className="label">权益①:</span>
                <span>转赠5天减时卡×6</span>
                </p>: <>
                  <p className="content_text">
                    <span className="label">权益①:</span>
                    <span>绿地G优会员成长值3000</span>
                  </p>
                  <p className="content_text">
                    <span className="label">权益②:</span>
                    <span>返1000积分</span>
                  </p>
                  <p className="content_text">
                    <span className="label">权益③:</span>
                    <span>转赠5天减时卡×6</span>
                  </p>
                </>
              }
            </div>
          </div>
          <div className="content">
            <div className="content_title">发售详情</div>
            <div className="content_spacebt">
              <span>发行价格</span>
              <span>¥{round(299, 2)}</span>
            </div>
            <p className="content_radio">可以使用积分抵扣</p>
            <div className="content_spacebt">
              <span>公开售时间</span>
              <span>10.12 11:00 - 10.16 20:00</span>
            </div>
            <div className="content_spacebt">
              <span>发行数量</span>
              <span>6000份</span>
            </div>
            <div className="content_spacebt">
              <span>发行方</span>
              <div className="content_home">
                <div></div>
                绿地集团
              </div>
            </div>
          </div>

          <div className="content">
            <div className="content_title">藏品介绍</div>
            <p>叮咚~Ape World系列数字藏品空降绿地无聊猿俱乐部啦！独创的玩法，让您体验到孵化独一无二的专属形象的过程。您在此次发售中购买的Ape World“蛋”系列藏品首先将会作为空投信标，将会在后续活动中不断获得以单独数字藏品形式空投的外在配件。在完成孵化阶段后，Ape World社群将共同创建3种动物的官方名称及形象，并作为新的数字藏品空投至持有相应动物的用户账户内，同时，用户也会获得该款最终形象的商业使用权及二次创作权。
            </p>
            <img className="desc-img" src={xqEgg} alt="" />
          </div>
          <div className="content">
            <div className="content_title">无限可能  共创社群</div>
            <div className="content_title">Ape World社群数字藏品</div>
            <p>每一种动物对应着三种蛋的样式，其中一款会以神秘形象出现，占到本物种蛋的10%。其对应的最终动物形象会有稀有的外观。每一只动物的本体都已经做到了独一无二的，哪怕是未来大家使用同样的外部配件，也能够做出区分。</p>
            <div className="content_img_center">
              <img className="desc-img" src={descEgg} alt="" />
            </div>
          </div>


          <div className="content ape_content">
            <div className="content_img_center">
              <li>
                <img src={require("@/assets/img/homedetail/Group2.png")} alt="" />
                <p>唯一编号</p>
              </li>
              <li>
                <img src={require("@/assets/img/homedetail/Group1.png")} alt="" />
                <p>可信纪录</p>
              </li>
              <li>
                <img src={require("@/assets/img/homedetail/Group3.png")} alt="" />
                <p>永久存证</p>
              </li>
              <li>
                <img src={require("@/assets/img/homedetail/Group4.png")} alt="" />
                <p>不可修改</p>
              </li>
            </div>
          </div>
          <div className="content">
            <div className="content_title">购买须知</div>
            <p>
              1.数字藏品为虚拟数字商品，而非实物，仅限实名认证为年满18周岁的中国大陆用户购买。本商品一经售出，不支持退换。本商品源文件不支持本地下载。请勿对数字藏品进行炒作、场外交易、欺诈、与虚拟货币或金融资产关联，或以任何其他非法方式进行使用。
              <br />
              <br />
              2.数字藏品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字藏品用于任何非法商业用途。对于数字藏品及其衍生品，如用户需要对该数字艺术品做出复制、出租、改编、放映、广播或进行网络传播等涉及作品著作权授权的行为，应获得著作权人的相应授权，并订立相关的协议。
              <br />
              <br />
              3.购买数字藏品行为即同意本平台《绿地数字藏品许可及服务协议》等规则，同意平台进行相关实名认证，用户应妥善保护好交易账号和密码，不得进行洗钱等违法违规活动，警惕和远离非法集资等相关非法金融活动，切实维护自身财产安全。
              <br />
              <br />
              4.请注意：请您于5分钟内完成订单支付，超时后订单将被自动取消。
              <br />
            </p>
            <br />
          </div>
          <div className="content_space"></div>
        </div>
      </div>
      <div className="detail_bottom">
        <div className="detail_bottom_top">
          <Checkbox
            defaultChecked={userAgree}
            onChange={(checked) => setUserAgree(checked)}
            style={{
              "--icon-size": "14px",
              "--font-size": "14px",
              "--gap": "6px",
            }}
          ></Checkbox>
          <p>
            我已阅读并同意<span onClick={goAgreement}>《绿地数字藏品许可及服务协议》</span>
          </p>
        </div>
        {currentOperate !== '100' ?<div className="detail_bottom_bt">
          <div className="left">
            {currentState === '8' ? <div className="end-price">¥299.00</div>: <>
              {currentState === '7' ? null: <div className="sign-tag">中签价</div>}
              <div className="price-detail">
                <div>
                  <span>¥</span>
                  <span className="price">{currentState === '7' ? '299.00': '199.00'}</span>
                </div>
                {currentState !== '7' && <div className="line-price">¥{round(299, 2)}</div>}
              </div>
            </>}
          </div>
          <div className={`sale ${isDisabled ? 'disabled': ''}`} onClick={snapUp}>
            <Button block color="primary" size="large">{btnText}</Button>
          </div>
        </div>:<div className="detail_bottom_bt">
          <div className="left">
            <div className="price-detail">
              <div>
                <span>¥</span>
                <span className="price">0.00</span>
              </div>
              <div className="line-price">¥{round(299, 2)}</div>
            </div>
          </div>
          <div className='sale' onClick={snapUp}>
            <Button block color="primary" size="large">立即领取</Button>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default ApeWorldSaleDetail;
