import React from 'react';
import styles from './styles.module.scss';
import NFTModal from '@/components/NFTModal';

const ConfirmModal = ({onClose, title, content, leftBtn, rightBtn}) => {
  const onLeftBtn = () => {
    onClose();
    leftBtn?.onClick();
  }

  const onRightBtn = () => {
    onClose();
    rightBtn?.onClick();
  }

  return <div className={styles.confirm_modal}>
    <div className={styles.modal_title}>{title}</div>
    <div className={styles.modal_content}>{content}</div>
    <div className={styles.modal_footer}>
      {leftBtn && <div className={styles.cancel_btn} onClick={onLeftBtn}>{leftBtn?.text}</div>}
      {rightBtn && <div className={styles.confirm_btn} onClick={onRightBtn}>{rightBtn?.text}</div>}
    </div>
  </div>
}

const confirmModal = ({ pageDom, title, content, leftBtn, rightBtn }) => {
  const ModalInstance = NFTModal.show({
    content: <ConfirmModal 
      onClose={() => ModalInstance?.close()}
      title={title}
      content={content}
      leftBtn={leftBtn}
      rightBtn={rightBtn}
    />,
    getContainer: pageDom,
  });
}

export default confirmModal;