import {hideLoading, showLoading} from "@/components/loading";
import {getActivityTime, getElementCountList, getUserCertificate} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";
import {metaRecovery24_0116} from "@/constant";
import dayjs from "dayjs";

export const getEleList = async () => {
    console.log('elelist')
    try {
        showLoading()
        const res = await getElementCountList({})
        hideLoading();
        if (res.code !== '000000') throw res.msg;
        let {baseInfo = [], userEInfo = []} = res.data;
        let arr = [
            {groupId: 5, title: 'Ape world', list: [], child: [], key: '0'},
            {groupId: 2, title: '建筑', list: [], child: [], key: '1'},
            {groupId: 4, title: '龙', list: [], child: [], key: '2'},
            {groupId: 3, title: '无聊猿', list: [], child: [], key: '3'},
        ];
        for (const item of baseInfo) {
            for (const item1 of arr) {
                if (item.groupId === item1.groupId) {
                    item1.list.push(item);
                }
            }
        }
        for (const item of arr) {
            for (const item0 of userEInfo) {
                if (item.groupId === item0.elementGroup) {
                    for (const item1 of item.list) {
                        if (item0.elementId === item1.elementId) {
                            item0.elementUrl = item1.elementUrl;
                            item0.sort = item1.sort;
                        }
                    }
                    if (item0.elementCount > 0) {
                        item.child.push(item0);
                    }
                }
            }
        }
        return arr;
    } catch (error) {
        hideLoading();
        Toast.show(error);
        return [];
    }
}
//获取用户实名状态
export const getUserCertificateStatus = async () => {
    try {
        const res = await getUserCertificate({})
        if (res.code !== '000000') throw res.msg;
        return res.data;
    } catch (error) {
        Toast.show(error);
        return false;
    }
}
//获取用户实名状态
export const getTimeConfig = async () => {
    try {
        const res = await getActivityTime({activityCode: metaRecovery24_0116})
        if (res.code !== '000000') throw res.msg;
        if (res.data && res.data) {
            const {startTime = '', endTime = '', currentTime = ''} = res.data;
            const startTimeday = dayjs(startTime);
            const endTimeday = dayjs(endTime);
            const currentTimeday = dayjs(currentTime);
            if (currentTimeday.isBefore(startTimeday)) {
                console.log('未开始')
                return -1;
            } else if (endTimeday.isBefore(currentTimeday)) {
                console.log('结束了')
                return 1;
            } else {
                console.log('进行中')
                return 0;
            }
        }
    } catch (error) {
        Toast.show(error);
        return 0;
    }
}
