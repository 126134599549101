/*
	发行详情
*/

import React, { useState, useEffect, useRef } from "react";
import { round } from "@/utils/publicFun"
import "./index.scss";

const DetailCard = (props) => {

	const {dataSouce={}} = props

	return (
		<div className="DetailCard">
			<div className="content_title">发售详情</div>
			{/*<div className="content_spacebt">*/}
			{/*	<span>发售价格</span>*/}
			{/*	<span>¥{round(dataSouce.price, 2)}</span>*/}
			{/*</div>*/}
			{/*<p className="content_radio">可使用专属优惠券，不可使用积分抵扣</p>*/}
			{/*<div className="content_spacebt">*/}
			{/*	<span>发售时间</span>*/}
			{/*	<span>{dataSouce.subtitle || ''}</span>*/}
			{/*</div>*/}
			{/*<div className="content_spacebt">*/}
			{/*	<span>发售数量</span>*/}
			{/*	<span>{dataSouce.supply || 0}份</span>*/}
			{/*</div>*/}
			{/*{*/}
			{/*	dataSouce.rarity &&*/}
			{/*	<div className="content_spacebt">*/}
			{/*		<span>稀有度</span>*/}
			{/*		<span style={{color: '#CEBF7E'}}>{dataSouce.rarity}</span>*/}
			{/*	</div>*/}
			{/*}*/}
			<div className="content_spacebt">
				<span>发行方</span>
				<div className="content_home">
					<div></div>
					{dataSouce.owner || '绿地集团'}
				</div>
			</div>
		</div>
	)
}

export default DetailCard;
