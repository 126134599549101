/**
 * @description: 确认订单
 * @returns {/boxToStone/confirmOrder}
*/
import React, {useEffect, useMemo, useRef, useState } from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import { Toast } from 'antd-mobile';
import qs from 'query-string';
import NFTHeader from '@/components/NFTHeader';
import { getUrlAllParams } from "@/utils/publicFun";
import { hideLoading, showLoading } from '@/components/loading';
import Stepper from "@/components/Stepper";
import Service from '../service';
import AddressBox from '../components/addressBox';
import Styles from './index.module.scss';
import JsBridgeNew from '@/utils/jsbridgeNew';

const MAXCount = 10;

const ConfirmOrder = () => {
    const navigate = useNavigate();
    const {search} = useLocation()
    const param = getUrlAllParams(search.slice(1));
    const {goods = ''} = param;
    const dataSource = JSON.parse(decodeURIComponent(goods || '{}'))
  
    const [count, setCount] = useState(1);
    const addressRef = useRef(null);

    useEffect(()=>{
        
    },[])

    const onSubmitOrder = async () => {
        if(!addressRef.current){
            return Toast.show('请选择收货地址')
        }
        try {
            showLoading();
            // 获取支付信息
            const res = await Service.commitOrder({
                totalPrice: Number(totalPrice),
                addressInfoRequest: {
                    province: addressRef.current.provinceName,
                    city: addressRef.current.cityName,
                    area: addressRef.current.areaName,
                    address: addressRef.current.deliveryAddress,
                    street: addressRef.current.streetName,
                    consigneeName: addressRef.current.consigneeName,
                    consigneeNumber: addressRef.current.consigneeNumber
                },
                productItemRequests: [
                    {price: Number(price), num: count, productId: dataSource.goodsId}
                ]
            }); 
            hideLoading();
            if (res) {
                // 跳转收银台
                const uri = `/ape/DefaultPay?${qs.stringify(res.data ?? {orderNo: '', price: ''})}`
                navigate(uri);
            }
            
        } catch (error) {
            hideLoading();
            error && Toast.show(error);
        }
        
    }

    const onCallAddress = (address)=>{
        console.log(address)
        addressRef.current = address;
    }
    const onCountChange = (count)=>{
        setCount(count)
    }
    const onWillChange = (res)=>{
        if(res > MAXCount){
            Toast.show(`一次最多可购买${MAXCount}件`)
            return false
        }
        return true;
    }

    const price = useMemo(()=>{
        return Number(dataSource.desc ?? 0).toFixed(2)
    },[dataSource])

    const totalPrice = useMemo(()=>{
        return Number((dataSource.desc ?? 0) * count).toFixed(2)
    },[count])

    return (
        <div className={Styles.confirmOrder}>
            <NFTHeader midText={`确认订单`} bgColor={'#FFFFFF'}/>
            <div className={Styles.container}>
                <AddressBox onCallAddress={onCallAddress}/>
                <div className={Styles.goodsBox}>
                    <div className={Styles.goodsStore}>
                        <div>自营</div>
                        <div>G优精选</div>
                    </div>
                    <div className={Styles.goodsInfo}>
                        <img className={Styles.goodsImg} src={dataSource.imgUrl} alt='' />
                        <div className={Styles.goodsDetail}>
                            <div className={Styles.goodsName}>{dataSource.lotteryName ?? '--'}</div>
                            <div className={Styles.priceCount}>
                                <div className={Styles.goodsPrice}>
                                    <span>￥</span>
                                    {price.split('.')[0]}
                                    {
                                        price.split('.').length > 1 &&
                                        <span>.{price.split('.')[1]}</span>
                                    }
                                </div>
                                <Stepper  
                                    maxValue={MAXCount} 
                                    defaultValue={count} 
                                    minValue={1}
                                    onWillChange={onWillChange}
                                    onChange={onCountChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={Styles.deliveryInfo}>
                    <span>支付/配送</span>
                    <span>在线支付/快递配送</span>
                </div>
                <div className={Styles.orderInfo}>
                    <div className={Styles.betweenStyle}>
                        <p>订单总额</p>
                        <span className={Styles.organer}>{`¥${totalPrice}`}</span>
                    </div>
                    <div className={Styles.betweenStyle}>
                        <p>商品金额</p>
                        <span>{`¥${totalPrice ?? 0}`}</span>
                    </div>
                    <div className={Styles.betweenStyle}>
                        <p>配送费用</p>
                        <span>¥0.00</span>
                    </div>
                </div>
            </div>
            <div className={Styles.bottom}>
                <div className={Styles.bottomLeft}>
                    <span>合计：</span>
                    <div>
                        <span>¥</span>
                        {totalPrice.split('.')[0]}
                        {
                            totalPrice.split('.').length > 1 &&
                            <span>.{totalPrice.split('.')[1]}</span>
                        }
                    </div>
                </div>
                <div className={Styles.bottomRight} onClick={onSubmitOrder}>
                    <span>提交订单</span>
                </div>
            </div>
        </div>
    )
}

export default ConfirmOrder;