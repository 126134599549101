import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import back from '@/assets/img/collection/back.png';
import icon_research from '@/assets/img/collection/icon_shaixuan.png';
import {SearchBar, Button} from 'antd-mobile'
const ratio = window.screen.width / 375;
const NFTSearchHeader = ({onSearchClick,valueChanged,initText='',onSwitchOnClick,firstTitle='',secondTitle='',totalCount=0,currentTitle1=1}) => {
  const [height, setHeight] = useState(0);
  const [value, setValue] = useState(initText);
  const [visibleButton, setVisibleButton] = useState(false);
  const [currentAllState, setCurrentAllState] = useState(true)
  const searchRef = useRef(null)

  useEffect(() => {
    window.JsBridge.getStatusBarHeight().then(({height}) => {
      setHeight(height);
    });
  }, []);

  const onBackClick = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.back();
    } else {
      window.history.go(-1);
    }
  }
  const handleSearchClick = () => {
    if (onSearchClick) {
      onSearchClick(value);
    }
  };

  const rightComponentClick = () => {
    setVisibleButton(!visibleButton)
  }

  const onClickSwitchBtn = ()=>{
    setCurrentAllState(!currentAllState)
    if (onSwitchOnClick){
      onSwitchOnClick(!currentAllState)
    }
  }

  const onValueChanged = (val)=> {
    setValue(val)
    if (valueChanged){
      valueChanged(val)
    }
  }

  const getResearchButton = () => {

    return <div className='right' onClick={() => rightComponentClick()}>
      <img src={icon_research} alt=""/>
      <div className='researchTitle'>{currentAllState ? "全部" : "我的藏品"}</div>
      {visibleButton ? <div className='fixed-button'>
        <Button
          onClick={() => onClickSwitchBtn()}
        >
          {currentAllState ? "我的藏品" : "全部"}
        </Button>
      </div> : null}
    </div>
  }

  const getTitle1 = () => {
    return <>
      <div className='searchTitle1'>
        <div className='titleContainer'>
          <div className='title'>
            {firstTitle}
          </div>
          <div className='subTitle'>
            {secondTitle}
          </div>
        </div>
        {getResearchButton()}
      </div>
      <div className='totalCount'>藏品总个数:<span>{totalCount}</span></div>
    </>
  }

  const getTitle2 = () => {
    return <>
      <div className='searchTitle2'>
        <div className='totalCount'>藏品总个数:<span>{totalCount}</span></div>
        {getResearchButton()}
      </div>
    </>
  }

  return <div className='nft-search-header'>
    <div className="container" style={{paddingTop: 10 + height}}>
      <div className="left" onClick={() => onBackClick()}>
        <img src={back} alt=""/>
      </div>
      <SearchBar
        onSearch={() => handleSearchClick()}
        style={{
          '--border-radius': `${30 * ratio}px`,
          '--background': '#FFFFFF',
          '--height': `${30 * ratio}px`,
          '--padding-left': '12px',
          '--opacity': '0',
        }}
        ref={searchRef}
        value={value}
        onChange={(val) => onValueChanged(val)}
        className="middle" placeholder='请输入藏品系列、编号、名称'/>
      <div className="rightButton" onClick={() => handleSearchClick()}>搜索</div>
    </div>
    {currentTitle1==1 && getTitle1()}
    {currentTitle1==2 && getTitle2()}
  </div>
}

export default NFTSearchHeader;
