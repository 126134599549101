/**
 * @description 收藏者信息
*/
import React from "react";
import {useNavigate} from "react-router";
import './index.scss';

const CollectorInfo = (props={}) => {
    const navigate = useNavigate();
    const { detailList, userImage } = props;

    const onAction = ()=> {
        const uri = `/blockChainColInfo?transactionHash=${detailList?.transactionHash}`;
        if(window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push("Web", {uri: `${window.location.origin}${uri}`});
        }else {
            navigate(uri);
        }
        
    }

    return (
        <div className='collector_info'>
            <div className='collector-name-container'>
                <img src={userImage} alt=""/>
                <div className='collector-name'>
                    收藏者
                </div>
            </div>
            <div className='collector-time-container'>
                <div className='collector-time-name'>获取时间</div>
                <div className='collector-time'>{detailList?.collectTime.slice(0, 10)}</div>
            </div>
            <div className='collector-hash-container'>
                <div className='collector-hash-name'>交易哈希</div>
                <div className='collector-hash' onClick={onAction}>{detailList?.transactionHash}</div>
            </div>
        </div>
    )
}

export {
    CollectorInfo
};