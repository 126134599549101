import React, { useState, useCallback, useEffect } from 'react'
import styles from './portal.module.scss'
import { Mask, Input, Toast } from 'antd-mobile'
import close from '@/assets/img/landfi/icon_del.png'

const Portal = ({visible, onClose, confirmCB, initData}) => {
  const [spaceId, setSpaceId] = useState('')

  useEffect(() => {
    if (!visible) {
      setSpaceId('')
    }
  }, [visible])

  const onConfirmTransmit = () => {
    if (!spaceId) {
      Toast.show('请输入空间ID')
      return
    }
    if (initData?.spaceNo === spaceId) {
      Toast.show('你已经在这里了哦~')
      return
    }
    closeMask()
    confirmCB && confirmCB(spaceId)
  }

  const closeMask = () => {
    onClose()
  }

  return <Mask className={styles.portal_mask} visible={visible} destroyOnClose>
    <div className={styles.portal_container}>
      <div className={styles.header}>
        <span className={styles.close_portal_mask}></span>
        <span className={styles.title}>传送门</span>
        <img className={styles.close_portal_mask} src={close} alt="" onClick={closeMask} />
      </div>
      <div className={styles.portal_input}>
        <Input value={spaceId} onChange={setSpaceId} className={styles.input_id} placeholder='请输入对方的空间ID'/>
      </div>
      <div className={styles.confirm_btn} onClick={onConfirmTransmit}>确认传送</div>
    </div>
  </Mask>
}

export const usePortal = ({ initData, confirmCB }={}) => {
  const [visible, setVisible] = useState(false)

  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  const onOpen = useCallback(() => {
    setVisible(true)
  }, [])

  const view = (
    <Portal visible={visible} onClose={onClose} confirmCB={confirmCB} initData={initData}/>
  )

  return [onOpen, view]
}