import React, {useState, useEffect, useRef} from "react";
import ReactDOM from 'react-dom';
import gift1 from '../../imgs/pic_jifen.png';
import gift2 from '../../imgs/pic_pj.png';
import guajiangTi from '../../imgs/guajiang-title.png';
import tuceng from '../../imgs/pic_gjq.png';
import './style.scss';
import GuaGuaKa from "../guaguaka";
import {hideLoading, showLoading} from "@/components/loading";
import { Toast } from 'antd-mobile';
import { openScratchCard } from "@/api/projectManager_self";
import ScratchModal from "../scratchModal";
import mockApi from '../../home/mock';

const ScratchView = ({cardInfo, refresh}) => {
  const [cardMaskStatus, setCardMaskStatus] = useState(true);
  const [prizeInfo, setPrizeInfo] = useState({});
  const prizeInfoRef = useRef(null);
  const { drawTimes, drawTime, drawEndTime, date, editTime } = cardInfo || {};

  const startT = new Date(drawTime?.replace(/-/g, "/")).getTime();
  const endT = new Date(drawEndTime?.replace(/-/g, "/")).getTime();
  const currentT = new Date(date?.replace(/-/g, "/")).getTime();
  const statusFn = () => {
    let cardStatus = 0;
    if(currentT < startT) {
      cardStatus = 0;
    } else if(currentT >= startT && currentT < endT) {
      cardStatus = 1;
    } else if(currentT >= endT) {
      cardStatus = 2;
    }
    return cardStatus;
  }

  useEffect(() => {
    setPrizeInfo({});
    !cardMaskStatus && setCardMaskStatus(true);
  }, [cardInfo])

  const rerenderDom = (prizeInfo, callBack) => {
    ReactDOM.render(
      <GuaGuaKa
        color="#808080"
        img={tuceng}
        round={[10,20,10,20]}
        size={30}
        percentage={60}
        clear={true}
        mode="move"
        onSuccess={onSuccess}
      >
        <div className={`s1 ${prizeInfo?.type === '3' ? 'price-02': 'price-01'}`}>
          <span className="price-des">{prizeInfo?.type === '3' ? `${prizeInfo.value}积分+一箱申花啤酒`: `恭喜中奖${prizeInfo.value}积分`}</span>
        </div>
      </GuaGuaKa>,
      document.getElementsByClassName('gua-gua-ka-box')[0],
      () => {
        callBack && callBack()
      }
    )
  }

  const onSuccess = async () => {
    setTimeout(() => {
      ScratchModal.open({
        leftCB: () => {
          ReactDOM.unmountComponentAtNode(document.getElementsByClassName('gua-gua-ka-box')[0]);
          refresh();
        },
        rightCB: () => {
          ReactDOM.unmountComponentAtNode(document.getElementsByClassName('gua-gua-ka-box')[0]);
          window.JsBridge.push('CustomWeb', { uri:`${process.env.REACT_APP_GKT_LINK}/front/guapp/activityOrder?activityCode=recovery202305&idf=goddessFes&productId=${prizeInfoRef.current.skuId}&req=${prizeInfoRef.current.reqId}&isChangeName=1`});
        },
        prizeInfo: prizeInfoRef.current
      });
    }, 1000);
  }

  const handleOpen = async () => {
    if(statusFn() !== 1 || drawTimes <= 0) return;
    try {
      showLoading();
      const res = await openScratchCard(cardInfo.remark);
      // const res = await mockApi.getCardInfoMock();
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      setPrizeInfo({...res.data, editTime});
      prizeInfoRef.current = {...res.data, editTime};
      rerenderDom({...res.data, editTime}, () => setCardMaskStatus(false));
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  return <div className='scratch-view'>
    <div className="scratch-view-title">
      <img src={guajiangTi} alt="" />
    </div>
    <div className="scratch-card-bg-box">
      <div className="gua-gua-ka-box"></div>
      {cardMaskStatus && <div className="scratch-card-top" onClick={handleOpen}>
        <div className="inner-bg">
          {statusFn() === 0 && <div className="scratch-btn gray-bg">未开始</div>}
          {statusFn() === 1 && <div className={`scratch-btn ${Number(drawTimes) === 0 ? 'gray-bg': ''}`}>点我刮奖</div>}
          {statusFn() === 2 && <div className="scratch-btn gray-bg">活动结束</div>}
          {statusFn() === 2 ? null: <div className="scratch-count">剩余{drawTimes|| 0}次</div>}
        </div>
      </div>}
    </div>
    <div className="scratch-des">
      <span>100%中奖！大奖等你拿</span>
      <span
        onClick={() => {
          window.JsBridge.push('Web', {uri: `${window.location.origin}/partsRecycling/partsRecovery`});
        }}
      >我的奖品&gt;</span>
    </div>
    <div className="gifts-box">
      <img src={gift1} alt="" />
      <img src={gift2} alt="" />
    </div>
  </div>
}

export default ScratchView;
