import React, {useEffect, useRef, useState,} from "react"
import "./index.scss";
import {TextArea, Toast} from "antd-mobile";
import NFTHeader from "@/components/NFTHeader";
import {getUrlAllParams} from "@/utils/publicFun";
import {useLocation} from "react-router";
import NoticeUtil from "@/utils/NoticeUtil";
import {useNavigate} from "react-router-dom";
import clickThrottle from "@/utils/clickThrottle";

const kSuggestClick = 'kSuggestClick';

const EditSuggest = (props) => {
    const {search} = useLocation()
    const {content = ''} = getUrlAllParams(search.slice(1));
    const [enable, setEnable] = useState(false)
    const inputRef = useRef(null);
    let currentValue = '';
    const navigate = useNavigate();
    useEffect(() => {
        currentValue = decodeURIComponent(content);
    }, [])

    const onChange = (e) => {
        currentValue = e;
    }

    const submit = () => {
        if (!clickThrottle()) {
            return
        }
        localStorage.setItem(kSuggestClick, JSON.stringify({currentValue: currentValue, type: 1}));
        setTimeout(() => {
            window.JsBridge.back();
        }, 500)
    }

    return (
        <div className="collectionvote_suggest_page">
            <NFTHeader midText={'意见反馈'} bgColor={'#FFF'}
                // leftClick={() => submit()}
            />
            <div className={'suggest_text_area_father'}>
                <TextArea maxLength={200}
                          rows={6}
                          ref={inputRef}
                          id={'input'}
                          showCount
                          defaultValue={decodeURIComponent(content)}
                          placeholder={'请输入您的意见：'}
                          onChange={(e) => onChange(e)}
                />
            </div>
            <div className={'suggest_submit_father'} onClick={() => submit()}>提交</div>
        </div>
    )
}
export default EditSuggest;
