import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {hideLoading, showLoading} from "@/components/loading";
import { queryDefCode } from "@/api/projectManager_self"
import { activityCode } from '../../home';
import { Toast } from 'antd-mobile';

const ActivityRule = () => {
  const [rules, setRules] = useState(null)

  useEffect(() => {
    getActivityRules()
  }, [])
  const getActivityRules = async () => {
    try {
      showLoading();
      const res = await queryDefCode({codeType: 'activityRule', code: activityCode});
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      const {data} = res;
      if (data.result && data.result.defCode) {
        const defCode = data.result.defCode;
        setRules(defCode);
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }
  return <div className={styles.unity_activity_rule}>
    {rules?.otherSign}
  </div>
}

export default ActivityRule;