import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom"
import NFTHeader from "@/components/NFTHeader"
import styles from './styles.module.scss'
import {Toast} from 'antd-mobile';
import qs from 'query-string';
import appService from '@/utils/appService';

export default () => {
    const {id} = qs.parse(window.location.search);
    const [orderDetail, setOrderDetail] = useState({});

    useEffect(() => {
        queryOrderDetail();
    }, [])

    const queryOrderDetail = async () => {
        try {
            const result = await appService('600814', {recordId: id});
            console.log('result=====>', result);
            if (result.status > 0) {
                setOrderDetail(result.data.record);
            }
        } catch (error) {
            Toast.show(error || '请求失败');
        }
    }

    const hejiString = (item) => {
        if (item.remark) {
            const remark = JSON.parse(item.remark);
            const spendItemNum = remark.spendItemNum || '';
            const spendItemType = remark.spendItemType || '';
            const spendItemUnit = remark.spendItemUnit || '';
            let typeString = '史诗款无聊猿兑换券'
            if (spendItemType == '1') {
                typeString = '史诗款兑换券'
            } else if (spendItemType == '2') {
                typeString = '碎片'
            } else if (spendItemType == '3') {
                typeString = '变异无聊猿Lite兑换券'
            }
            return `${spendItemNum}${spendItemUnit}${typeString}`
        }
        return null
    }

    return (
        <div className={styles.container}>
            <NFTHeader midText="订单详情" backType={2} bgColor={'#fff'}/>
            <div className={styles.status}>交易成功</div>
            <div className={styles.address_item}>
                <img src={require('@/assets/img/address_icon.png')} alt=""/>

                <div className={styles.address_center}>
                    <div className={styles.user_info}>
                        <span>{orderDetail.consigneeName || ''}</span>
                        <span>{orderDetail.consigneeNumber || ''}</span>
                    </div>
                    <div className={styles.address_info}>
                        {orderDetail.province + orderDetail.city + orderDetail.area + (orderDetail.street || '') + (orderDetail.address || '')}
                    </div>
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemTop}>
                    <img src={orderDetail.prizeImgUrl}/>
                    <div className={styles.righter}>
                        <div className={styles.top}>
                            <div className={styles.title}>{orderDetail.prizeName}</div>
                            {orderDetail.desc ? <p>x{orderDetail.desc}</p> : null}
                        </div>

                        {
                            hejiString(orderDetail) ? <div className={styles.desc}>
                                合计：<strong>{hejiString(orderDetail)}</strong>
                            </div> : null
                        }
                    </div>

                </div>
                <div className={styles.item_bottom}>
                    <div>配送服务</div>
                    <span>快递免邮</span>
                </div>
            </div>
            <div className={styles.info}>
                <div className={styles.row}>
                    <span>订单编号：</span>
                    <span style={{color: '#000000'}}>{orderDetail.orderId}</span>
                </div>
                <div className={styles.row}>
                    <span>兑换时间：</span>
                    <span style={{color: '#000000'}}>{orderDetail.createTime}</span>
                </div>

                {hejiString(orderDetail) ? <div className={styles.row}>
                    <span>兑换方式：</span>
                    <span style={{color: '#000000'}}>{hejiString(orderDetail)}</span>
                </div> : null}
            </div>

        </div>
    )
}
