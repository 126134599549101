import {useRoutes} from "react-router-dom";
import routes from "./router/routes";
import {useEffect, useState} from "react";
import "./index.css";
import "./assets/scss/adm.scss";
import './assets/scss/variable.scss'
import {getUrlAllParams} from "@/utils/publicFun";
import {getUserInfo, getNftToken} from '@/api/projectManager_self';
import NoticeUtil from "@/utils/NoticeUtil";
import {unity_noty_pay_result} from "@/constant";

export default function App() {
    const element = useRoutes(routes);
    const [pageStatus, setPageStatus] = useState(false);
    useEffect(() => {
        window.addEventListener('message', async (event) => {
            console.log('收到unity发来的消息---',event)
            if (typeof event.data == 'string' && event.data == 'UnityFinshed') {
                if (event.data === 'UnityFinshed') {
                    if (window.Unity) {
                        const nftToken = window.Unity.message?.nftToken;
                        console.log('window.Unity.message---', window.Unity.message)
                        if (nftToken) {
                            window.localStorage.setItem("token", nftToken);
                            window.localStorage.removeItem('userInfo')
                            getUserInfo()
                                .then(res => {
                                    console.log('请求成功')
                                    window.localStorage.setItem("userInfo", JSON.stringify(res.data || {}));
                                    setPageStatus(true);
                                })
                                .catch((error) => {
                                    console.log('请求失败')
                                    setPageStatus(true);
                                })
                        }
                    }
                }
            } else if (event.data && event.data.msgName && event.data.msgName == 'SendMessageToJsPayResult') {//支付结果
                NoticeUtil.emit(unity_noty_pay_result,event.data.msg);
            }
        }, false);


        if (window.location.search) {
            let params = getUrlAllParams(window.location.search.slice(1))
            console.log('params---', params)
            // t=nft的token
            if (params['t']) {
                console.log('有t')
                window.localStorage.setItem("token", params['t']);
                getUserInfo()
                    .then(res => {
                        console.log('请求成功')
                        window.localStorage.setItem("userInfo", JSON.stringify(res.data || {}));
                        setPageStatus(true);
                    })
                    .catch((error) => {
                        console.log('请求成功')
                        setPageStatus(true);
                    })
            }else if(params?.userInfo) {
                console.log('有userInfo:', params.userInfo);
                getNftToken({source: 'mini', token: params.userInfo?.token})
                .then(res => {
                    const {token:nftToken} = res.data;
                    window.localStorage.setItem("token", nftToken);
                    getUserInfo()
                    .then(res => {
                        console.log('请求成功')
                        window.localStorage.setItem("userInfo", JSON.stringify(res.data || {}));
                        setPageStatus(true);
                    })
                    .catch((error) => {
                        console.log('请求成功')
                        setPageStatus(true);
                    })
                })
                .catch((error) => {
                    console.log(error)
                    setPageStatus(true);
                })
            }else {
                console.log('没有t--', params)
                if (!params['isUnity']) {
                    setPageStatus(true);
                }
            }
        } else {
            console.log('没有参数')
            setPageStatus(true);
        }
    }, []);
    if (!pageStatus) return null;
    return (
        <div style={{height: '100vh', width: '100vw'}}>
            <div style={{height: '100vh', width: '100vw'}}>{element}</div>
        </div>
    );
}
