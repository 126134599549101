import React, {useEffect, useState, useRef} from "react"
import {Mask, Toast} from "antd-mobile"
import "./voteRuleDialog.scss"
import {hideLoading, showLoading} from "@/components/loading";
import {queryDefCode} from "@/api/projectManager_self";

const VoteRuleDialog = ({maskVisible, setMaskVisible}) => {
    const [title, setRuleTitle] = useState();
    const [content, setContent] = useState();

    const btnClick = () => {
        setMaskVisible(!maskVisible);
    }

    useEffect(() => {
        maskVisible && getProtocol();
    }, [maskVisible]);
    const getProtocol = async () => { //获取协议
        try {
            showLoading();
            let params = {
                codeType: 'activityRule',
                code: 'cocreationVote',
            };
            const res = await queryDefCode(params);
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            const {data} = res
            if (data.result && data.result.defCode) {
                let defCode = data.result.defCode;
                setRuleTitle(defCode.codeAlias)
                setContent(defCode.otherSign)
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    return (
        <div className={'vote_rule_conatner0'}>
            <Mask visible={maskVisible} opacity={0.8}>
                <div className={'vote_rule_container'}>
                    <div className={'vote_container_content'}>
                        <div className={'vote_rule_title'}>{title}</div>
                        <div className={'vote_rule_content1'}>{content}</div>
                        <div className={'vote_rule_sure_btn_father'} onClick={() => btnClick()}>
                            <div className={'sure_btn_txt'}>
                                <span>我知道了</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Mask>
        </div>
    )
}

export default VoteRuleDialog;
