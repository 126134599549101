/*
    底部购买操作
*/

import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom"
import { Button, Toast, Checkbox } from "antd-mobile"
import moment from "moment"
import { round, throttle, getUrlAllParams} from "@/utils/publicFun"
import qs from 'query-string';
import "./index.scss";

const kAgreementTitle = '《用户协议》'

const BottomBuy = (props)=> {
    const navigate = useNavigate();

    const {dataSouce={}} = props

    const [userAgree, setUserAgree] = useState(false);

    //购买操作
    const onClick = ()=> {
        if (userAgree === true) {
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.verifyRealName("请先实名认证").then(function (res) {
                if (res?.result) {
                  gotoSalePay();
                }
              })
            } else {
              if(process.env.NODE_ENV == 'development'){
                  gotoSalePay();
              }else {
                  Toast.show("请至APP内购买")
              }
              
            }
          } else {
            Toast.show(`请先勾选${kAgreementTitle}`)
          }
    }

    const gotoSalePay = () => {
        const params = {
          collectionId:dataSouce.collectionId,
          saleBatchId:dataSouce.saleBatchInfo?.saleBatchId || '',
          code: 'CR2023'
        }
        
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push("Web", { uri: `${window.location.origin}/apeWorld/PayPage?${qs.stringify(params)}` });
        } else {
          navigate(`/apeWorld/PayPage?${qs.stringify(params)}`);
        }
        
      }

    const goAgreement = () => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push("Web", { uri: `${window.location.origin}/MyAgreement` })
        } else {
          navigate(`/MyAgreement`)
        }
    }

    const btnStatus = useMemo(()=>{
      let status = {
        text: '已售罄',
        disabled: true
      }
      if (dataSouce.saleBatchInfo && Object.values(dataSouce).length > 0) {
        
        const start = new Date(dataSouce.saleBatchInfo.saleStartTime.replace(/-/g, "/")).getTime();
        const end = new Date(dataSouce.saleBatchInfo.saleEndTime.replace(/-/g, "/")).getTime();
        let time = new Date().getTime();

        if (start && time < start) {
            status.disabled = true
            status.text = moment(new Date(start)).format("MM.DD HH:mm") + "开售"

        }else if (dataSouce.saleBatchInfo.stock > 0) {
            if(end && time > end) {
              // 发行结束
            }else {
              status.disabled = false
              status.text = '立即购买'
            }
            
        }
        
      } 

      return status;

    },[dataSouce]) 

    return (
        <div className="BottomBuy">
            <div className="agreement_select">
                <Checkbox
                    defaultChecked={userAgree}
                    onChange={(checked) => setUserAgree(checked)}
                    style={{
                    "--icon-size": "12px",
                    "--font-size": "12px",
                    "--gap": "5px",
                    }}
                >我已阅读并同意</Checkbox>
                <span onClick={goAgreement}>{kAgreementTitle}</span>
            </div>
            <div className="buy-info">
                <div className="left">
                    <div className="price-detail">
                        <span>¥</span>
                        <span>{round(dataSouce.price,2)}</span>
                    </div>
                </div>
                <div className="right">
                    <Button block color="primary" shape='rounded' disabled={btnStatus.disabled} size="large" onClick={onClick}>
                      {btnStatus.text}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default BottomBuy;