import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import { Toast } from "antd-mobile";
import { hideLoading, showLoading } from "@/components/loading";
import close from "@/assets/img/landfi/close-circle-fill.png";
import { queryDefCode } from "@/api/projectManager_self";

const Rules = ({ onClose }) => {
  const [activityRules, setActivityRules] = useState("");
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    try {
      showLoading();
      const res = await queryDefCode({
        codeType: "activityRule",
        code: "btcGuess",
      });
      hideLoading();
      if (res.code !== "000000") throw res.msg;
      const { data } = res;
      if (data.result && data.result.defCode) {
        const otherSign = data.result.defCode.otherSign;
        setActivityRules(otherSign);
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  };
  return (
    <div className={styles.detail_out_mask}>
      <div
        className={styles.detail_out}
        onClick={() => {
          onClose();
        }}
      >
        <div className={styles.title_box}>
          <div className={styles.close_box}>
            <img
              className={styles.close}
              src={close}
              alt=""
              onClick={() => onClose()}
            />
          </div>
          <div className={styles.title}>活动规则</div>
        </div>

        <div className={styles.box}>{activityRules}</div>
      </div>
    </div>
  );
};

export default Rules;
