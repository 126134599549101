import React, {useState} from "react";
import './shiMingDialog.scss'
import {Mask} from "antd-mobile";

const ShiMingDialog = ({maskVisible, setMaskVisible,goShiMing}) => {

    const btnClick = (index) => {
        setMaskVisible(!maskVisible);
        if (index === 1) {
            goShiMing && goShiMing();
        }
    }

    return (
        <Mask visible={maskVisible} opacity={0.8}>
            <div className={'share_container'}>
                <div className={'share_container_content'}>
                    <div className={'title'}>{'友情提示'}</div>
                    <div className={'line'}/>
                    <div className={'content'}>{'您还未实名认证，无法完整使用数字藏品相关功能。'}</div>
                    <div className={'btn_container'}>
                        <div className={`sure_btn cancel`} onClick={() => btnClick(0)}>取消</div>
                        <div className={`sure_btn sure`} onClick={() => btnClick(1)}>去实名</div>
                    </div>

                </div>
            </div>
        </Mask>
    )
}

export default ShiMingDialog;
