import React, { memo } from "react";
import "./index.scss";

const RynthesisModal = (props) => {
  const {
    type = "",
    level = "",
    selectInfo = {},
    selectArray = [],
    onConfirm,
    onCancel,
  } = props;

  return (
    <div className="synthesis__modal-body">
      <div className="content">
        <div className="title">{"合成藏品"}</div>
        {type === "single" ? (
          <span>{`合成可得1个${level}级工业猴 确认将当前选择的藏品合成吗？`}</span>
        ) : (
          <span>{`合成可得10个${level}级工业猴 确认随机选择藏品合成吗？`}</span>
        )}

        <div className="btn-group">
          <div className="cancel" onClick={onCancel}>
            取消
          </div>
          <div className="confirm" onClick={()=>{onConfirm(type)}}>
            确认合成
          </div>
        </div>
        <img
          src={require("@/assets/img/collectionRecoveryLand/close_x.png")}
          alt=""
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

export default memo(RynthesisModal);
