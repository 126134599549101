import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import deleteImg from '../imgs/delete.png';
import addSign from '../imgs/add-sign.png';
import collection from '@/assets/img/variationApe/collection-temp.png';
import material from '@/assets/img/variationApe/material.png';
import { Toast } from 'antd-mobile';
import {hideLoading, showLoading} from "@/components/loading";
import ListView from '@/components/AntListView';
import RecoveryModal from '../components/recoveryModal';
import SuccessModal from '../components/successModal';
import { queryPartsList, recyclingParts } from '@/api/projectManager_self';
import {throttle} from '@/utils/publicFun';
import qs from 'query-string';

const mock = {
  code: '000000',
  msg: 'success',
  data: {
    list: [
      {
        nftNo: '0ggghfdddddddff',
        collectionId: '0',
        nftImage: collection,
      },
      {
        nftNo: '1',
        collectionId: '1',
        nftImage: collection,
      },
      {
        nftNo: '2',
        collectionId: '2',
        nftImage: collection,
      },
      {
        nftNo: '3',
        collectionId: '3',
        nftImage: collection,
      },
      {
        nftNo: '4',
        collectionId: '4',
        nftImage: collection,
      },
    ]
  }
}

const fetch = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(
        {
          code: '000000', 
          msg: 'error', 
          data: {
            nftImage: material,
            nftNo: '888888'
          }
        }
      )
    }, 2000);
  })
}

const chooseList = [
  {
    // nftNo: '',
    nftImage: '',
  },
  {
    // nftNo: '',
    nftImage: '',
  },
  {
    // nftNo: '',
    nftImage: '',
  },
]

const fn = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(mock)
    }, 1000);
  })
}

const RecoveryParts = () => {
  const { type } = qs.parse(window.location.search);
  const [choosedMedicineList, setChooseMedicineList] = useState(chooseList);
  const [epicCollectionList, setEpicCollectionList] = useState([]);
  const [chooseNum, setChooseNum] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const currentRef = useRef(null);

  useEffect(() => {
    window.JsBridge.setTitle('回收配件');
    getEpicFundCollectionList();
  }, [])

  useEffect(() => {
    const idArr = choosedMedicineList.map((i) => i.nftNo);
    const newEpicList = epicCollectionList.map((i) => {
      return {...i, isChoosed: idArr.includes(i.nftNo) ? true: false};
    })
    setEpicCollectionList(newEpicList);
  }, [choosedMedicineList])

  const getEpicFundCollectionList = async (isRefresh=true, isLoading=true) => {
    try {
      if(isRefresh) {
        currentPage.current = 1;
        setChooseMedicineList(chooseList);
        setChooseNum(0);
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
      }
      isLoading && showLoading();
      const res = await queryPartsList(params);
      // const res = await fn();
      isLoading && hideLoading();
      if (res.code !== '000000') throw res.msg;
      const newList = res.data.list || [];
      if(currentPage.current === 1) {
        setEpicCollectionList(newList);
      } else {
        setEpicCollectionList([...epicCollectionList, ...newList]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(newList.length >= pageSize.current);
    } catch (error) {
      isLoading && hideLoading();
      Toast.show(error);
    }
  }

  const chooseRecyclingMedicine = (item) => {
    const newList = [...choosedMedicineList];
    const chooseCount = newList.filter((i) => i.nftNo != undefined);
    if(chooseCount.length >= 3 && !item.isChoosed) return;
    const index = newList.findIndex((i) => i.nftNo === item.nftNo);
    if(index < 0) {
      newList.splice(chooseCount.length, 1, {...item, isChoosed: true});
    } else {
      newList.splice(index, 1, {nftImage: ''});
    }
    let newArr = [];
    newList.forEach((i) => {
      if(i.nftNo != undefined) {
        newArr.push(i);
      }
    })
    if(newArr.length === 0) {
      newArr.push(...[{nftImage: ''}, {nftImage: ''}, {nftImage: ''}]);
    }
    if(newArr.length === 1) {
      newArr.push(...[{nftImage: ''}, {nftImage: ''}]);
    }
    if(newArr.length === 2) {
      newArr.push({nftImage: ''});
    }
    setChooseMedicineList(newArr);
    setChooseNum(newArr.filter((i) => i.nftNo != undefined).length);
  }

  const handleRecycling = throttle( async () => {
    try {
      showLoading();
      const nftNoList = choosedMedicineList.map((i) => i.nftNo);
      const res = await recyclingParts({type: Number(type), nftNoList: nftNoList});
      // const res = await fetch();
      hideLoading();
      if(res.code != '000000') {
        throw res.msg;
      }
      SuccessModal.open({confirmCB, type})
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }, 2000)

  const confirmCB = () => {
    window.JsBridge.back();
  }

  const RenderItem = (item) => {
    return <div className='parts-item'>
      <div 
        className={`epic-funds-item ${item.isChoosed ? 'active': chooseNum >= 3 && choosedMedicineList.findIndex((i) => i.nftNo === item.nftNo) < 0 ? 'disabled': ''}`} 
        onClick={() => chooseRecyclingMedicine(item)}
      >
        <img src={item.nftImage} alt="" />
      </div>
      <div className="epic-num">#{item.nftNo}</div>
    </div>
  }

  return <div className='recovery-parts-page' ref={currentRef}>
    <div className="recovery-material-container">
      <div className="material-box">
        <div className="img-box">
          <div className="equal-sign-left">
            {choosedMedicineList.map((item, index) => {
              return <div className='material-item' key={`item-${index}`}>
                <div className="material-collection-img">
                  {item.nftImage ? <img className='material-img' src={item.nftImage} alt="" />:<img className='add-sign' src={addSign} alt="" />}
                  {item.nftImage ? <img className='delete-img' src={deleteImg} alt="" onClick={() => chooseRecyclingMedicine(item)}/>: null}
                </div>
              </div>
            })}
          </div>
        </div>
        <div className="text-box">
          <div className="equal-sign-left">
            {choosedMedicineList.map((item, index) => {
              return <div className='material-item' key={`item-${index}`}>
                {item.nftNo ? <div className='num-img-box'>
                  <div className="collection-num">#{item.nftNo}</div>
                </div>: null}
              </div>
            })}
          </div>
        </div>
      </div>
      <div 
        className={`recycling-btn ${chooseNum >= 3 ? 'available': ''}`} 
        onClick={() => {
          if(chooseNum < 3) return;
          RecoveryModal.open({confirmCB: handleRecycling, materialList: choosedMedicineList, type});
        }}
      >
        {type === '1' ? '回收获取500积分': '回收并押注'}
      </div>
    </div>
    <div className="epic-funds-collection">
      <div className="choose-tip">请选择合成材料</div>
      <div className="epic-funds-list">
        <ListView 
          dataSource={epicCollectionList}
          renderItem={RenderItem}
          hasMore={hasMore}
          onEndReached={() => getEpicFundCollectionList(false, false)}
          style={{paddingTop: 50}}
          isLoad={isLoad}
        />
      </div>
    </div>
  </div>
}

export default RecoveryParts;