/**
 * @description 中奖号码
*/
import React, { useEffect, useState } from 'react';
import Styles from './index.module.scss';

const WinningNumbers = ({ onClose, data=[] }) => {
    
    return (
        <div className={Styles.winningNumbers}>
            <div className={Styles.container}>
                {/* <div className={Styles.title}>中奖号码</div> */}
                <div className={Styles.content}>
                    {
                        data?.map((group, index) => {
                            return (
                                <div className={Styles.group} key={index}>
                                    <div className={Styles.group_title}>{`${group.prizeName}(${group.count})`}</div>
                                    <div className={Styles.group_numbers}>
                                        {
                                            group?.nftNoList?.map((number, index) => {
                                                return (
                                                    <div className={Styles.number} key={index}>{number || '---'}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={Styles.close} onClick={onClose}></div>
        </div>
    )
}

export default WinningNumbers;