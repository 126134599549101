import React, {useState, } from 'react';
import clickThrottle from "@/utils/clickThrottle";
import styles from "./styles.module.scss";

const ItemSwitch = (props) => {
    const [data, setData] = useState([
        {title: '收集元素', isChoice: true, id: 0},
        {title: '元素合成', isChoice: false, id: 1},
        {title: '活动规则', isChoice: false, id: 2},
    ]);

    // useEffect(() => {
    //     for (let i = 0; i < data.length; i++) {
    //         let item = data[i];
    //         if (i === props.initalIndex) {
    //             item.isChoice = true;
    //         } else {
    //             item.isChoice = false;
    //         }
    //     }
    //     setData([...data]);
    //     setTimeout(() => {
    //         props.switchItem && props.switchItem(props.initalIndex);
    //     }, 500)
    // }, [props.initalIndex])


    const itemClick = (item) => {
        if (item.isChoice || !clickThrottle(1000)) {
            return;
        }
        for (const item0 of data) {
            if (item0.id === item.id) {
                item0.isChoice = true;
            } else {
                item0.isChoice = false;
            }
        }
        setData([...data]);
        setTimeout(() => {
            props.switchItem && props.switchItem(item.id);
        }, 500)
    }

    const items = () => {
        return data.map((item, index) => {
            return <div key={index.toString()} className={`${styles.item} ${item.isChoice ? styles.isChoiceItem : ''}`}
                        onClick={() => itemClick(item)}>
                <p >{item.title}</p>
            </div>
        })
    }

    return (
        <div className={styles.switch_container}>
            {items()}
        </div>
    )
}

export default ItemSwitch;
