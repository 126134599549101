import React, {useEffect, useState, useRef} from "react"
import {List, Mask, Toast} from "antd-mobile"
import "./eggComplexView.scss"
import img_sure from '@/assets/img/egg/btn_fd_hecheng.png'
import img_cancel from '@/assets/img/egg/btn_fd_quxiao.png'

const JsBridge = window.JsBridge;

const EggComplexView = (props={}) => {
    const {maskVisible, setMaskVisible, products, preImg, nftNo,hcImgs} = props;

    useEffect(() => {
        console.log('products:',products)
    },[products])

    const btnClick = (index) => {
        setMaskVisible(!maskVisible);
        if (index === 1) {
            let arr = [];
            for (const item of products) {
                arr.push(item.nftNo)
            }
            let obj = {};
            obj.petNo = nftNo;
            obj.nftNoList = arr;
            obj.hcImgs = hcImgs;
            obj.preImg = preImg;
            window.localStorage.setItem("egg_hc_info", JSON.stringify(obj));
            JsBridge.push("Web", {uri: `${window.location.origin}/EggHatchProgress`})
        }
    }

    const setImgs = ()=>{
        if (hcImgs.length > 0) {
            return hcImgs.map((item, index) => {
                return <img src={item.elemFileUrl || ''} key={index.toString()} className={'egg_hc_img'}/>
            })
        }
        return <img src={preImg} className={'egg_hc_img'}/>
    }

    return (
        <div className={'egg_hc_container'}>
            <Mask visible={maskVisible} opacity={0.8} destroyOnClose>
                <div className={'egg_hc_container0'}>
                    <div className={'egg_hc_container_content'}>
                        <div className={'egg_hc_title'}>
                            { products.length > 0 ? '确认合成' : '您未选择配件，确认合成？'}
                        </div>
                        <div className={'egg_hc_img_father'}>
                            {setImgs()}
                        </div>
                        <div className={'egg_hc_mid_content'}>
                            {
                                products.length > 0 ? <div className={'egg_hc_mid_content_title'}>已选组件</div> : null
                            }
                            {
                                products.length > 0 ? 
                                <div className={'egg_hc_mid_content_list'}>
                                {
                                    products.map((item, index) => {
                                        return (
                                            <img src={item.imageBg || ''} key={index}/>
                                        )
                                    })
                                }
                                </div> : null
                            }
                            <div className={'egg_hc_mid_content_title1_father'}>
                                <div>确定合成当前搭配？合成后原藏品及</div>
                                <div>
                                    <span>当前</span>
                                    <span className={'egg_hc_mid_content_title1_red'}>选择配件将被销毁且不可撤销</span>
                                    <span>。</span>
                                </div>
                            </div>
                        </div>

                        <div className={'egg_hc_sure_cancel_btn_father'}>
                            <div className={'egg_hc_sure_btn_txt'} onClick={() => btnClick(0)}>
                                <img src={img_cancel}/>
                                <span className={'cancel'}>取消</span>
                            </div>
                            <div className={'egg_hc_sure_btn_txt'} onClick={() => btnClick(1)}>
                                <img src={img_sure}/>
                                <span>确认合成</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Mask>
        </div>
    )
}

export default EggComplexView;
