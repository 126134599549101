import React from 'react';
import hot from '@/assets/img/as_hot.png';
import './style.scss';
const HotAss = () => {
  return (
    <div className='hot-ass'>
      <img src={hot} style={{width: '53.6%', height: 'auto'}}/>
      <span>活动火爆，请稍后</span>
    </div>
  )
}

export default HotAss;