import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';

const CountDown = ({ sysTime, endTime, refreshData }) => {
  const initalTime = { day: '00', hour: '00', minute: '00', second: '00' };
  const [countTime, setCountTime] = useState(initalTime);
  const timeStampCurrent = useRef(null);
  const timerCurrent = useRef(null);

  useEffect(() => {
    timeStampCurrent.current = null;
    timerStart();
    return () => {
      timerCurrent.current && clearInterval(timerCurrent.current);
    }
  }, [sysTime, endTime])

  const countDown = (currentTime, endTime, callBack) => {
    if (!currentTime || !endTime) {
      return initalTime;
    }
    const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))
    if (timeStampCurrent.current == null) {
      timeStampCurrent.current = currentTimeStamp
    }
    const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
    const distanceStamp = endTimeStamp - timeStampCurrent.current;
    if (distanceStamp <= 0) {
      callBack && callBack(distanceStamp);
      return initalTime;
    }
    let remianAllSeconds = Math.floor(distanceStamp / 1000);
    let day = Math.floor(remianAllSeconds / (60 * 60 * 24));
    let hour = Math.floor(remianAllSeconds / (60 * 60) % 24);
    let minute = Math.floor(remianAllSeconds / 60 % 60);
    let second = Math.floor(remianAllSeconds % 60);

    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;
    return { day, hour, minute, second };
  }

  const timerStart = () => {
    timerCurrent.current && clearInterval(timerCurrent.current);
    timerCurrent.current = setInterval(() => {
      if (timeStampCurrent.current) {
        timeStampCurrent.current = timeStampCurrent.current + 1000;
      }
      let obj = countDown(sysTime, endTime, (distanceStamp) => {
        timerCurrent.current && clearInterval(timerCurrent.current);
        if (distanceStamp === 0) {
          refreshData && refreshData();
        }
      });
      setCountTime(obj);
    }, 1000)
  }

  const {day, hour, minute, second} = countTime;

  return <div className="land_ape_countdown">
    <span>开奖倒计时</span>
    <span>{day}</span>
    <span>天</span>
    <span>{hour}</span>
    <span>时</span>
    <span>{minute}</span>
    <span>分</span>
    <span>{second}</span>
    <span>秒</span>
  </div>
}

export default CountDown;
