import React, {useState, useEffect, useRef} from 'react';
import './index.scss';
import CountDown from "@/view/guessingActy/components/countDown";
import {clearAllTimer, isEmptyArray} from "@/utils/publicFun";
import PutGuessBondDialog from "@/view/guessingActy/components/dialog/putGuessBondDialog";
import {hideLoading, showLoading} from "@/components/loading";
import {getSignNum} from "@/api/projectManager_self";
import GetGuessBondDialog from "@/view/guessingActy/components/dialog/getGuessBondDialog";
import {Toast} from "antd-mobile";
import {useNavigate} from "react-router-dom";

let btn_white_a = require('../../imgs/btn_white_a.png');//未竞猜a
let btn_white_b = require('../../imgs/btn_white_b.png');//未竞猜b
let btn_jc_a = require('../../imgs/btn_jc_a.png');//已竞猜a
let btn_jc_b = require('../../imgs/btn_jc_b.png');//已竞猜b
let btn_grey_a = require('../../imgs/btn_grey_a.png');//不可竞猜a
let btn_grey_b = require('../../imgs/btn_grey_b.png');//不可竞猜b

export const SessionViewEnum = {
    NotStartView: 'NotStartView',//没有开始
    NotEndWheelGuessCanGuessView: 'NotEndWheelGuessCanGuessView',//本轮没有结束竞猜-可以参与
    HasGuessNotResultView: 'HasGuessNotResultView',//已竞猜 未出结果
    HasGuessHasResultWinView: 'HasGuessHasResultWinView',//已竞猜 已出结果 赢了(赢了显示票数)
    HasGuessHasResultLoseView: 'HasGuessHasResultLoseView',//已竞猜 已出结果 输了(输了不显示我投的票数) 备注:输了也要显示
    LastGuessLoseOrNotJoinNotEndView: 'LastGuessLoseOrNotJoinNotEndView',//上一轮输了 或者上一轮没有参与 本轮不可参与 没有结束 正在进行中 不可竞猜
    LastGuessLoseOrNotJoinHasEndView: 'LastGuessLoseOrNotJoinHasEndView',//上一轮输了 或者上一轮没有参与 本轮不可参与 已经结束 已出结果
}

const SessionCpt = (props) => { //各个场次
    const navigate = useNavigate();

    const sessionViewEnumRef = useRef(SessionViewEnum.NotStartView);
    const [session, setSession] = useState(0);//场次
    const [round, setRound] = useState(0);//轮次
    const [quizCouponCount, setQuizCouponCount] = useState(0);//竞猜卷的数量
    const [sessionTitle, setSessionTitle] = useState('');//题目
    const [answerId, setAnswerId] = useState('');//正确答案的id 空未出结果 -2没人投 -1 平局排除（0：0）
    const [questionA, setQuestionA] = useState({});//问题A
    const [questionB, setQuestionB] = useState({});//问题B
    const [lastResult, setLastResult] = useState(0);//上一场 1赢，0非赢
    const [lastAnswerId, setLastAnswerId] = useState(0);//上一场空未出结果 -2没人投 -1 平局排除（0：0）
    const currentTime = useRef('');//当前时间
    const guessInfoRef = useRef({});//竞猜信息
    const [curRoundStatus, setCurRoundStatus] = useState(-1);//当前轮的状态 0=未开始 1=进行中 2=已结束


    useEffect(() => {
        loadData();
    }, [props])

    const loadData = () => {

        clearAllTimer();
        currentTime.current = '';
        guessInfoRef.current = {};
        let data = props.data || {};
        let roundTem = props.round || 0;
        setQuizCouponCount(data?.quizCouponCount);
        currentTime.current = data?.currentTime;
        if (data && data.guessingQuestionList && !isEmptyArray(data.guessingQuestionList) && data.guessingQuestionList.length > roundTem) {
            let guessInfo = data.guessingQuestionList[roundTem];
            guessInfoRef.current = guessInfo;
            setSession(guessInfo.session);
            // guessInfo.round = 2;
            setRound(guessInfo.round);
            setSessionTitle(guessInfo.title);
            setAnswerId(guessInfo.answerId);
            setLastAnswerId(guessInfo.lastAnswerId);
            setLastResult(guessInfo.lastResult);

            getSessionType();


            let exerciseProblemsList = guessInfo.exerciseProblemsList;
            if (!isEmptyArray(exerciseProblemsList)) {
                let itemA = exerciseProblemsList[0] || {};
                itemA.question = (itemA.answerOption || '') + '.' + (itemA.answerContent || '');
                // itemA.amount=10;
                setQuestionA(itemA);
                if (exerciseProblemsList.length >= 1) {
                    let itemB = exerciseProblemsList[1] || {};
                    itemB.question = (itemB.answerOption || '') + '.' + (itemB.answerContent || '');
                    // itemB.amount=9;
                    setQuestionB(itemB);
                }
            }
        }
    }

    const refreshData = () => {
        let timer = setTimeout(() => {
            clearTimeout(timer);
            props.refreshData && props.refreshData();
        }, 1500);
    }

    const getSessionType = () => {//获取当前的时间段
        let startTime = guessInfoRef.current.startTime;
        let endTime = guessInfoRef.current.endTime;
        // let startTime = '2023-07-02 20:35:29';
        // let endTime = '2023-07-30 23:35:32';
        if (!currentTime.current || !startTime || !endTime) {
            return
        }
        const currentTimeStamp = Date.parse(currentTime.current.replace(/-/g, '/'))
        const startTimeStamp = Date.parse(startTime.replace(/-/g, '/'));
        const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))

        let status = 0;//0=未开始 1=进行中 2=已结束
        if (currentTimeStamp > endTimeStamp) { //已结束
            status = 2;
        } else if (currentTimeStamp < endTimeStamp && currentTimeStamp > startTimeStamp) { //进行中
            status = 1;
        } else {
            status = 0;
        }
        setCurRoundStatus(status);
    }


    const SessionProgressFooter = () => {//底部进度条

        return (
            <>
                <div className={'proportion_container'}>
                    <img src={require('../../imgs/Ibl_a.png')} alt=""/>
                    <img src={require('../../imgs/Ibl_b.png')} alt=""/>
                </div>
                <div className={'proportion_press'}>
                    <div className={`proportion_press_left ${getProgress() > 99 ? 'all_round' : ''}`}
                         style={{width: `${getProgress()}%`}}/>
                </div>
                <div className={'proportion_press_count'}>
                    <p>{questionA.totalAmount || 0}劵</p>
                    <p>{questionB.totalAmount || 0}劵</p>
                </div>
            </>
        )
    }

    const getProgress = () => { //获取进度
        let totalAmountA = questionA.totalAmount || 0;
        let totalAmountB = questionB.totalAmount || 0;
        if (totalAmountA === totalAmountB) {
            return 50;
        }
        let total = totalAmountA + totalAmountB;
        return (totalAmountA / total) * 100;
    }

    const choiceAorB = (type) => { //选择A或者B
        handleJuanCount();
    }

    const handleJuanCount = async () => {//判断票数
        try {
            showLoading()
            const res = await getSignNum({session: session, round: round});
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            let allowNum = res.data?.allowNum || 0;
            if (allowNum > 0) {
                PutGuessBondDialog.open(guessInfoRef.current, (type) => {
                    if (type === 1) {//已经投票
                        refreshData();
                    } else {
                        navigate(`/guessingActy/getCoupon?session=${session}`)
                    }
                })
            } else {
                if (round !== 1) {
                    Toast.show('您本场可用入场劵已使用完,当前不可竞猜');
                    return;
                }


                GetGuessBondDialog.open((type) => {//0=去兑换 1=去购买
                    // console.log('type---',type)
                    if (type === 0) {
                        navigate(`/guessingActy/getCoupon?session=${session}`);
                    } else {
                        navigate(`/guessingActy/getCoupon?currentIndex=1&session=${session}`);
                    }
                });
            }
        } catch (error) {
            Toast.show(error);
        }
    }


    const NotStartView = () => { //没有开始

        return (
            <>
                <div className={'session-item-info-container-header'}>
                    <p>{`第${session}场 第${round}轮`}</p>
                    <div style={{marginLeft: '10px'}}>
                        {
                            curRoundStatus === 2 ? null :
                                <CountDown type={1} endTitle={'开始'} sysTime={currentTime.current}
                                           endTime={guessInfoRef.current.startTime} refreshData={refreshData}/>
                        }
                    </div>
                </div>
            </>
        )
    }

    const NotEndWheelGuessCanGuessView = () => { //本轮没有结束竞猜-可以参与 进行中

        return (
            <>
                <div className={'session-item-info-container-header'}>
                    <p>{`第${session}场 第${round}轮`}</p>
                    <div style={{marginLeft: '10px'}}>
                        {
                            curRoundStatus === 2 ? null :
                                <CountDown type={1} endTitle={'结束'} sysTime={currentTime.current}
                                           endTime={guessInfoRef.current.endTime} refreshData={refreshData}/>
                        }
                    </div>
                </div>
                <p className={'session-item-title'}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={btn_white_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB()}>
                            <p className={'p1 blue0'}>{questionA.question}</p>
                        </div>

                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={btn_white_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB()}>
                            <p className={'p1 blue0'}>{questionB.question}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const HasGuessNotResultView = () => { //已竞猜 未出结果 进行中

        return (
            <>
                <div className={'session-item-info-container-header'}>
                    <p>{`第${session}场 第${round}轮`}</p>
                    <div style={{marginLeft: '10px'}}>
                        {
                            curRoundStatus === 2 ? null :
                                <CountDown type={1} endTitle={'结束'} sysTime={currentTime.current}
                                           endTime={guessInfoRef.current.endTime} refreshData={refreshData}/>
                        }
                    </div>
                </div>
                <p className={'session-item-title'}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={questionA.amount > 0 ? btn_jc_a : btn_white_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB()}>
                            <p className={`p1 ${questionA.amount > 0 ? 'white0' : 'blue0'}`}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}
                        </div>

                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={questionB.amount > 0 ? btn_jc_b : btn_white_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'} onClick={() => choiceAorB()}>
                            <p className={`p1 ${questionB.amount > 0 ? 'white0' : 'blue0'}`}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }


    const HasGuessHasResultWinView = () => { //已竞猜已出结果 已结束(赢了显示票数)

        return (
            <>
                <div className={'session-item-info-container-header'}>
                    <p>{`第${session}场 第${round}轮 结果`}</p>
                </div>
                <p className={'session-item-title'}>{sessionTitle}</p>
                <div className={'question_container'} style={{width: '90%'}}>
                    <div className={`question_a ${questionA.amount > 0 ? 'bg_div_red' : 'bg_div_white'}`}>
                        <div className={'question_ab_container '}>
                            <p className={'p1 white0'}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}
                        </div>

                    </div>
                    <div className={`question_a ${questionB.amount > 0 ? 'bg_div_red' : 'bg_div_white'}`}>
                        <div className={'question_ab_container '}>
                            <p className={'p1 white0'}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
                <SessionProgressFooter/>
            </>
        )
    }

    const HasGuessHasResultLoseView = () => { //已竞猜已出结果输了(输了显示我投的票数)

        return (
            <>
                <div className={'session-item-info-container-header'}>
                    <p>{`第${session}场 第${round}轮 结果`}</p>
                </div>
                <p className={'session-item-title'}>{sessionTitle}</p>
                <div className={'question_container'} style={{width: '90%'}}>
                    <div className={`question_a ${questionA.amount > 0 ? 'bg_div_red' : 'bg_div_white'}`}>
                        <div className={'question_ab_container '}>
                            <p className={'p1 white0'}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}
                        </div>

                    </div>
                    <div className={`question_a ${questionB.amount > 0 ? 'bg_div_red' : 'bg_div_white'}`}>
                        <div className={'question_ab_container '}>
                            <p className={'p1 white0'}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
                <SessionProgressFooter/>
            </>
        )
    }

    const LastGuessLoseOrNotJoinNotEndView = () => { //上一轮输了 或者上一轮没有参与 本轮不可参与 没有结束 正在进行中 不可竞猜

        return (
            <>
                <div className={'session-item-info-container-header'}>
                    <p>{`第${session}场 第${round}轮`}</p>
                    <div style={{marginLeft: '10px'}}>
                        {
                            curRoundStatus === 2 ? null :
                                <CountDown type={1} endTitle={'结束'} sysTime={currentTime.current}
                                           endTime={guessInfoRef.current.endTime} refreshData={refreshData}/>
                        }
                    </div>
                </div>
                <p className={'session-item-title'}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a left'}>
                        <img src={btn_grey_a} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'}>
                            <p className={'p1 white0'}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}
                        </div>

                    </div>
                    <img src={require('../../imgs/pic_vs.png')} alt="" className={'pk_logo'}/>
                    <div className={'question_a right'}>
                        <img src={btn_grey_b} alt="" className={'pk_a_img'}/>
                        <div className={'question_ab_container'}>
                            <p className={'p1 white0'}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }


    const LastGuessLoseOrNotJoinHasEndView = () => { //上一轮输了 或者上一轮没有参与 本轮不可参与 已经结束 已出结果

        return (
            <>
                <div className={'session-item-info-container-header'}>
                    <p>{`第${session}场 第${round}轮 结果`}</p>
                </div>
                <p className={'session-item-title'}>{sessionTitle}</p>
                <div className={'question_container'}>
                    <div className={'question_a bg_div_gray'}>
                        <div className={'question_ab_container'}>
                            <p className={'p1 white0'}>{questionA.question}</p>
                            {questionA.amount > 0 ? <p className={'p2'}>我投了{questionA.amount}</p> : null}
                        </div>

                    </div>
                    <div className={'question_a bg_div_gray'}>
                        <div className={'question_ab_container'}>
                            <p className={'p1 white0'}>{questionB.question}</p>
                            {questionB.amount > 0 ? <p className={'p2'}>我投了{questionB.amount}</p> : null}
                        </div>
                    </div>
                </div>
                <SessionProgressFooter/>
            </>
        )
    }

    const returnContentView = () => {
        if (curRoundStatus === -1) {
            return
        }
        // console.log('curRoundStatus---', curRoundStatus);
        // console.log('round---', round);
        // console.log('answerId---', answerId);
        // console.log('questionA---', JSON.stringify(questionA));
        // console.log('questionB---', JSON.stringify(questionB));

        if (curRoundStatus === 0) {  //0=未开始 1=进行中 2=已结束
            sessionViewEnumRef.current = SessionViewEnum.NotStartView;
        } else if (curRoundStatus === 1) { //进行中  用户能否参与
            if (round === 1) { //第一轮 能参与
                if (!questionA.amount && !questionB.amount) {//用户没有参与
                    sessionViewEnumRef.current = SessionViewEnum.NotEndWheelGuessCanGuessView;
                } else { //用户参与了,未出结果
                    sessionViewEnumRef.current = SessionViewEnum.HasGuessNotResultView;
                }
            } else { //第二三轮 要判断上一轮是否赢了
                if (lastResult === 1) { //赢了
                    if (!questionA.amount && !questionB.amount) {//用户没有参与
                        sessionViewEnumRef.current = SessionViewEnum.NotEndWheelGuessCanGuessView;
                    } else { //用户参与了,未出结果
                        sessionViewEnumRef.current = SessionViewEnum.HasGuessNotResultView;
                    }
                } else { //没赢 不可参与
                    sessionViewEnumRef.current = SessionViewEnum.LastGuessLoseOrNotJoinNotEndView;
                }
            }
        } else if (curRoundStatus === 2) {//已结束 HasGuessHasResultWinView
            // if (lastResult === 0 && (lastAnswerId === -2 || lastAnswerId === -1)) { //0非赢 第二轮平局
            //     return <ThreeRoundedWithTwoRoundDraw/>
            // }
            if (answerId === questionA.answerId) {
                if (questionA.amount > 0) { //用户选择了A是正确答案
                    sessionViewEnumRef.current = SessionViewEnum.HasGuessHasResultWinView;
                } else { //回答错误
                    sessionViewEnumRef.current = SessionViewEnum.LastGuessLoseOrNotJoinHasEndView;
                }
            }

            if (answerId === questionB.answerId) {
                if (questionB.amount > 0) { //用户选择了B是正确答案
                    sessionViewEnumRef.current = SessionViewEnum.HasGuessHasResultWinView;
                } else { //回答错误
                    sessionViewEnumRef.current = SessionViewEnum.LastGuessLoseOrNotJoinHasEndView;
                }
            }
            if (!answerId || answerId < 0) {
                sessionViewEnumRef.current = SessionViewEnum.LastGuessLoseOrNotJoinHasEndView;
            }
        }

        // console.log('sessionViewEnum---', sessionViewEnumRef.current)
        // console.log('-----------------------------------------')
        // sessionViewEnumRef.current = SessionViewEnum.HasGuessNotResultView;//可用作测试
        // return  <LastGuessLoseOrNotJoinNotEndView/>
        if (sessionViewEnumRef.current === SessionViewEnum.NotStartView) {//ok
            return <NotStartView/>;
        } else if (sessionViewEnumRef.current === SessionViewEnum.NotEndWheelGuessCanGuessView) {//ok
            return <NotEndWheelGuessCanGuessView/>
        } else if (sessionViewEnumRef.current === SessionViewEnum.HasGuessNotResultView) { //ok
            return <HasGuessNotResultView/>
        } else if (sessionViewEnumRef.current === SessionViewEnum.HasGuessHasResultWinView) {//ok
            return <HasGuessHasResultWinView/>
        } else if (sessionViewEnumRef.current === SessionViewEnum.HasGuessHasResultLoseView) {//ok
            return <HasGuessHasResultLoseView/>
        } else if (sessionViewEnumRef.current === SessionViewEnum.LastGuessLoseOrNotJoinNotEndView) { //ok
            return <LastGuessLoseOrNotJoinNotEndView/>
        } else if (sessionViewEnumRef.current === SessionViewEnum.LastGuessLoseOrNotJoinHasEndView) {
            return <LastGuessLoseOrNotJoinHasEndView/>
        }

    }

    return (
        <div className='session-item-component'>
            <div className={'session-item_container'}>
                <div className={'session-item-info'}>
                    <div className={'session-item-info-container'}>
                        {returnContentView()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SessionCpt;
