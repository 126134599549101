import React from "react"
import {InfiniteScroll, PullToRefresh, DotLoading, Empty} from "antd-mobile"
import "./index.scss"
import styles from './index.scss';

let no_data = require("@/assets/img/noCollection.png");


const InfiniteScrollContent = ({hasMore}) => {
    return (
        <>
            {hasMore ? (
                <>
                    <span>Loading</span>
                    <DotLoading/>
                </>
            ) : null}
        </>
    )
}

const ListView = ({ListItem, loadMore, hasMore, list, onRefresh, isLoad, isNeedRefresh = true}) => {
    return (
        <div className={styles.list1_contenter}>
            <div>
                {
                    isNeedRefresh ? <PullToRefresh 
                      onRefresh={onRefresh} 
                      style={{display: 'block'}}
                      headHeight={70}
                      threshold={100}
                      pullingText={
                        <img 
                          src={require('@/assets/img/new-loading.gif')}
                          style={{height: 50}} alt=''
                        />
                      }
                      refreshingText={
                        <img
                          src={require('@/assets/img/new-loading.gif')}
                          style={{height: 50}} alt=''
                        />
                      }
                      canReleaseText={
                        <img
                          src={require('@/assets/img/new-loading.gif')}
                          style={{height: 50}} alt=''
                          />
                        }
                      renderText={null}
                    >
                        {list?.length ? (
                            list.map((item, index) => <ListItem key={index} item={item}/>)
                        ) : (
                            isLoad ?
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Empty style={{width: "100%",height:'30vh'}}
                                           image={<img src={''} />}
                                           // imageStyle={{marginBottom:'20px'}}
                                           description="~暂无数据~"/>

                                </div> : null
                        )
                        }
                        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50}>
                            <InfiniteScrollContent hasMore={hasMore}/>
                        </InfiniteScroll>
                    </PullToRefresh> : <div>  {list?.length ? (
                        list.map((item, index) => <ListItem key={index} item={item}/>)
                    ) : (
                        isLoad ? <Empty style={{width: "100%"}}
                                        image={<img src={''}/>}
                                        description="~暂无数据~"/> : null
                    )
                    }</div>
                }


            </div>
        </div>
    )
}

export default ListView
