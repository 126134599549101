import React from 'react'
import styles from './styles.module.scss'
import back from '../../imgs/icon_return.png'

const CommonView = ({children, hideBack=false, onClose, title, ...props}) => {
  return <div className={styles.common_view} {...props}>
    <div className={styles.view_header}>
      {hideBack ? <span></span>: <img onClick={onClose} src={back} alt="" />}
      <h3>{title}</h3>
      <span></span>
    </div>
    {children}
  </div>
}

export default CommonView