import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { Mask } from 'antd-mobile';

const ComposeModal = ({onClose, materialList, confirmCB, type}) => {
  const [visible, setVisible] = useState(true);

  const cancel = () => {
    setVisible(false);
    onClose && onClose();
  }

  const confirm = () => {
    confirmCB && confirmCB();
    setVisible(false);
    onClose && onClose();
  }

  return <Mask className='recovery-mask' visible={visible} onMaskClick={() => cancel()}>
    <div className="mask-out-view">
      <div className="mask-content">
        <div className="title">回收配件</div>
        <div className="tip">
          {type === '2' ? '确定将以下藏品回收并押注吗？确认后以下藏品将被回收，且操作不可逆': '确定将以下藏品回收并获得500积分吗？确认后以下藏品被回收，且操作不可逆。'}
        </div>
        <div className="recovery-collection">
          {materialList.map((item, index) => {
            return <div className='material-list-item' key={`material-${index}`}>
              <img src={item.nftImage} alt="" />
              <span>#{item.nftNo}</span>
            </div>
          })}
        </div>
        <div className="btn-box">
          <div className="cancel-btn common" onClick={() => cancel()}>取消</div>
          <div className="confirm-btn common" onClick={() => confirm()}>确认回收</div>
        </div>
      </div>
    </div>
  </Mask>
}

ComposeModal.open = ({materialList, confirmCB, type}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<ComposeModal onClose={close} materialList={materialList} confirmCB={confirmCB} type={type}/>, div);
}

export default ComposeModal;