import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import deleteImg from '@/assets/img/variationApe/delete-icon.png';
import addSign from '@/assets/img/variationApe/add-sign.png';
import equalSign from '@/assets/img/variationApe/equal-sign.png';
import whiteAdd from '@/assets/img/variationApe/white-add-sign.png';
import composeResult from '@/assets/img/variationApe/compose-result-tem.png';
import MedicineList from './medicineList';
import LegendApeList from './legendApeList';
import { Swiper, Toast } from 'antd-mobile';
import ComposeModal from '../components/composeModal';
import SuccessModal from '../components/successModal';
import {throttle} from '@/utils/publicFun';
import {hideLoading, showLoading} from "@/components/loading";
import ModalAlert from "@/components/ModalAlert";
import { variatonApeCompose } from '@/api/projectManager_self';
import { mockFetch } from './mock';

const ApeCompose = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [choosedMedicine, setChoosedMedicine] = useState({nftNo: '', imageBg: ''});
  const [choosedLegendApe, setChoosedLegendApe] = useState({nftNo: '', imageBg: ''});
  const [choosedCount, setChoosedCount] = useState(0);
  const [choosedArr, setChoosedArr] = useState([]);
  const ref = useRef(null);
  const legendRef = useRef(null);
  const swiperRef = useRef(null);
  const composeInfo = useRef(null);
  const currentRef = useRef(null);
  const domRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.JsBridge.setTitle('变异无聊猿');
    onDidFocus();
    if(domRef.current) {
      setHeight(domRef.current.offsetHeight);
    }
  }, [])

  useEffect(() => {
    const newArr = [choosedMedicine, choosedLegendApe];
    const choosedCount = newArr.filter((i) => i.id != undefined);
    setChoosedCount(choosedCount.length);
    setChoosedArr(newArr);
  }, [choosedLegendApe, choosedMedicine])

  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      refreshPage();
      onDidFocus();
    });
  }

  const onTabClick = (i) => {
    setTabIndex(i);
    swiperRef.current.swipeTo(i);
  }

  const deleteMedicine = () => {
    ref.current.chosseMedicine && ref.current.chosseMedicine(choosedMedicine);
    setChoosedMedicine({nftNo: '', imageBg: ''});
  }
  
  const deleteLegendApe = () => {
    legendRef.current.chosseLegendApe && legendRef.current.chosseLegendApe(choosedLegendApe);
    setChoosedLegendApe({nftNo: '', imageBg: ''});
  }

  const refreshPage = () => {
    ref.current.getMedicineList && ref.current.getMedicineList(true, false);
    legendRef.current.getLegengApeList && legendRef.current.getLegengApeList(true, false);
    deleteMedicine();
    deleteLegendApe();
  }

  const animationStart = () => {
    ModalAlert.show({
      getContainer: currentRef.current,
      afterClose: ()=> {
        SuccessModal.open({
          collectionInfo: composeInfo.current, 
          leftCB: refreshPage,
          rightCB: () => {
            window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/myCollection?code=06&subCode=31` });
          }
        });
      },
      afterShow: () => {
        animationEnd();
      },
      content: <video 
                id='video'
                width="100%"
                height="100%"
                style={{background: '#000000'}}
                src={require('@/assets/img/variationApe/compose-ape.mp4')}
                autoPlay="autoplay"
                playsInline={true}
                muted
              />
    });
  }

  const animationEnd = () => {
    const videoDOM = document.getElementById('video');
    videoDOM.addEventListener('ended', () => {
      ModalAlert.hide();
    })
  }

  const confirmCompose = throttle(async () => {
    try {
      showLoading();
      const nftNoList = choosedArr.map((i) => i.nftNo);
      const res = await variatonApeCompose({activeCoding: 2, nftNoList: nftNoList});
      // const res = await mockFetch();
      hideLoading();
      if(res.code != '000000') {
        composeInfo.current = null;
        throw res.msg;
      }
      composeInfo.current = res.data;
      animationStart();
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }, 2000)

  return <div className='variation_ape_compose' ref={currentRef}>
    <div className='compose_ape_container'>
      <div className='compose_material_box'>
        <div className="top-img-box">
          <div className='material_img_box'>
            {choosedMedicine.imageBg ? <img className='image-bg' src={choosedMedicine.imageBg} alt="" />: <img className='add-sign' src={addSign} alt="" />}
          </div>
          <div className='white_add_sign'><img src={whiteAdd} alt="" /></div>
          <div className='material_img_box'>
            {choosedLegendApe.imageBg ? <img className='image-bg' src={choosedLegendApe.imageBg} alt="" />: <img className='add-sign' src={addSign} alt="" />}
          </div>
          <div className='equal_sign_box'><img src={equalSign} alt="" /></div>
          <div className='compose_result_img'>
            <img src={composeResult} alt="" />
          </div>
        </div>
        <div className="bottom-text-box">
          <div className='material_img_box'>
            {choosedMedicine.nftNo ? <div className='delete-choose'>
              <span>#{choosedMedicine.nftNo}</span>
              <img src={deleteImg} alt="" onClick={deleteMedicine}/>
            </div>: null}
          </div>
          <div className='white_add_sign'></div>
          <div className='material_img_box'>
            {choosedLegendApe.nftNo ? <div className='delete-choose'>
              <span>#{choosedLegendApe.nftNo}</span>
              <img src={deleteImg} alt="" onClick={deleteLegendApe}/>
            </div>: null}
          </div>
          <div className='equal_sign_box'></div>
          <div className='compose_result_img'>
            <span>绿地变异无聊猿x1</span>
          </div>
        </div>
      </div>
      <div 
        className={`compose-ape-btn ${choosedCount >= 2 ? 'available': ''}`}
        onClick={() => {
          if(choosedCount < 2) return;
          ComposeModal.open({confirmCB: confirmCompose, materialList: choosedArr, type: '无聊猿'});
        }}
      >
          合成变异无聊猿
      </div>
    </div>
    <div className="material-list-box">
      <div className="list-box">
        <div className="title">请选择合成材料</div>
        <div className="tab-box">
          <span 
            className={`tab-left ${tabIndex === 0 ? 'active': ''}`} 
            onClick={() => onTabClick(0)}
          >
            变异药水
          </span>
          <span className='tab-middle'></span>
          <span 
            className={`tab-right ${tabIndex === 1 ? 'active': ''}`}
            onClick={() => onTabClick(1)}
          >
            传说款无聊猿
          </span>
        </div>
      </div>
      <div className="list-content" ref={domRef}>
        <Swiper
          ref={swiperRef}
          defaultIndex={tabIndex}
          indicator={() => null}
          onIndexChange={(i) => setTabIndex(i)}
        >
          <Swiper.Item style={{height: `${height}px`}}>
            <MedicineList ref={ref} renderStatus={tabIndex === 0}  getMedicine={(item) => setChoosedMedicine(item)}/>
          </Swiper.Item>
          <Swiper.Item style={{height: `${height}px`}}>
            <LegendApeList ref={legendRef} renderStatus={tabIndex === 1} getLegendApe={(item) => setChoosedLegendApe(item)}/>
          </Swiper.Item>
        </Swiper>
      </div>
    </div>
  </div>
}

export default ApeCompose;