import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import {Mask} from 'antd-mobile';
import collection from "@/assets/img/variationApe/collection-temp.png";
import NoticeUtil from "@/utils/NoticeUtil";

const RecycleSuccessModal = ({onClose, leftCB, rightCB, list = [], type}) => {
    const [visible, setVisible] = useState(true);

    const cancel = () => {
        onClose && onClose();
        setVisible(false);
        leftCB && leftCB();
    }

    const confirm = () => {
        onClose && onClose();
        setVisible(false);
        rightCB && rightCB();

        NoticeUtil.emit('hecheng-success')
    }

    const items = () => {
        if (type === 0) {
            return list.map((item, index) => {
                return <div key={index.toString()} className={'recycle-col-item'}>
                    <img src={item.nftImage} alt=""/>
                    <p>{item.nftNo}</p>
                </div>
            })
        } else if (type === 1) {
            return <img src={require('../../imgs/img_gj.png')} alt="" className={'gj-img'}/>
        }
    }

    let des = '';
    if (type === 0) {
        des = '恭喜获得史诗款无聊猿，请在 g 优“我的藏品”查看'
    } else if (type === 1) {
        des = '恭喜押注1500积分并获得刮奖券x1，请在“刮奖拿豪礼”中进行刮奖';
    }
    return <Mask className='unity-acratch-success-mask' visible={visible} >
        <div className='mask-content'>
            <div className="mask-inner">
                <div className='points-content-unity'>
                    <div className='title'>回收成功</div>
                    <div className='points-unity'>{des}</div>
                    <div className={'content-unity'}>
                        {items()}
                    </div>
                </div>
                <div className='btn-box'>
                    <div className="address-btn-success" onClick={() => confirm()}>确认</div>
                </div>
            </div>
        </div>
    </Mask>
}

RecycleSuccessModal.open = ({leftCB, rightCB, list, type}) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<RecycleSuccessModal onClose={close} leftCB={leftCB} rightCB={rightCB} list={list}
                                         type={type}/>, div);
}

export default RecycleSuccessModal;
