import React, { useRef, useEffect, useState } from "react";
import { showLoading, hideLoading } from "@/components/loading";
import {
  confirmSimpleOrder,
  queryPayResult,
  getPayParams,
  getUserInfo,
} from "@/api/projectManager_self";
import { BackBtn, GreenBtn ,BackBtnMode2} from "./button";
import { AliPayIcon, WxPayIcon } from "./common";
import { Radio, Input, Toast, Switch } from "antd-mobile";
import { throttle, computeNumber, round } from "@/utils/publicFun";
import defaultCrad from "@/assets/img/landfi/space-order-img.png";
import defaultCrad2 from "@/assets/img/landfi/landgy/space-order-img.png";

import styles from "./landOrder.module.scss";
import appService from "@/utils/appService";
import { purchasedLandSpaceInit } from "@/api/guland";
import classNames from "classnames";
const ConfirmOrder = ({ onClose, paySuccessCB, spaceLevel,mode }) => {
  const [reqInfo, setReqInfo] = useState({});
  const [payMethod, setPayMethod] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [purchaseNum, setPurchaseNum] = useState(1);
  const [payTypeList, setPayTypeList] = useState([]);
  const [needScore, setNeedScore] = useState(false);
  const [score, setScore] = useState("");
  const [totalCanUsePoints, setTotalCanUsePoints] = useState(0)
  const installedWX = useRef();

  const userPoints = reqInfo?.point || 0
  const canUseMaxPoints = reqInfo.maxPointUse || 0

  useEffect(() => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.nativeModules("UMShareModule", "isInstalledWx").then(
        function (res) {
          installedWX.current = res;
        }
      );
    }
  }, []);

  useEffect(() => {
    getPayTypeList();
    getSureOrderInfo();
  }, []);

  useEffect(() => {
    let allPrice = computeNumber(reqInfo?.price || 0, '*', purchaseNum).result
    if (needScore) {
      allPrice = computeNumber(allPrice, '-', computeNumber(Number(score), '/', (reqInfo?.pointRatio || 100)).result).result
    }
    const canUseMaxTotalPoints = computeNumber(reqInfo.maxPointUse || 0, '*', purchaseNum).result
    const canUseTotalPoints = canUseMaxTotalPoints > userPoints ? userPoints: canUseMaxTotalPoints
    !needScore && setScore('')
    setTotalPrice(allPrice)
    setTotalCanUsePoints(canUseTotalPoints)
  }, [needScore, score, purchaseNum, reqInfo])

  const getPayTypeList = async () => {
    const result = await appService("500001", { resource: "mall" }, false);
    if (result.status > 0) {
      console.log("result=====>", result);
      setPayTypeList(result?.data?.reverse() || []);
    }
  };

  const getPayResult = (params) => {
    try {
      showLoading();
      let timeId = setTimeout(async () => {
        const { code, msg } = await queryPayResult(params);
        hideLoading();
        if (code === "000000") {
          Toast.show({
            content: "支付成功",
            maskClickable: false,
            afterClose: () => {
              onClose();
              paySuccessCB && paySuccessCB();
            },
          });
        } else {
          Toast.show(msg);
        }
        timeId && clearTimeout(timeId);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  //获取用户信息
  const queryUserInfo = async () => {
    const {code, msg, data} = await getUserInfo();
    if (code !== '000000') throw msg;
    return data;
  }

  const getGoodsInfo = async () => {
    const res = await purchasedLandSpaceInit(
      {
      spaceLevel,
      spaceType: +mode === 2 ? 1 : 0,//空间类型 0农业  1工业
    }
    );
    if (res?.code !== "000000") throw res?.msg;
    return res?.data;
  };

  const getSureOrderInfo = async () => {
    try {
      showLoading();
      const [goodsInfo, userInfo] = await Promise.all([
        getGoodsInfo(),
        queryUserInfo(),
      ]);
      hideLoading();
      setReqInfo({ ...goodsInfo, ...userInfo });
    } catch (error) {
      hideLoading();
      Toast.show(typeof error === "string" ? error : error.msg);
    }
  };

  const pay = async (params) => {
    try {
      showLoading();
      const orderInfo = await confirmSimpleOrder(params);
      if (orderInfo.code !== "000000") throw orderInfo.msg;
      if (totalPrice === 0) {
        hideLoading();
        return orderInfo.data;
      }
      const paramsInfo = await getPayParams({
        outTradeNo: orderInfo?.data?.outTradeNo || "",
      });
      hideLoading();
      if (paramsInfo.code !== "000000") throw paramsInfo.msg;
      return { ...paramsInfo.data, ...orderInfo.data };
    } catch (error) {
      hideLoading();
      Toast.show(error);
      return false;
    }
  };

  const onSubmit = async () => {
    // check app-env
    if (!window.JsBridge?.hasWebViewBridge()) {
      Toast.show("请到APP中支付");
      return;
    }
    if (!payMethod && totalPrice > 0) {
      Toast.show("请选择支付方式");
      return;
    }
    if (reqInfo.notPay) {
      Toast.show("您有待付款的订单，请先完成上次订单");
      return;
    }
    const params = {
      totalNum: purchaseNum,
      totalUsePoint: score || 0,
      totalUseCash: totalPrice,
      payType:totalPrice === 0 ? 'POINT': payMethod.toUpperCase(),
      source: "app",
      uid: reqInfo?.defRepeatStr,
      activityCode: "BUY_LAND_SPACE",
      type: "8",
      productId: reqInfo?.productId,
      spaceType: +mode === 2 ? 1 : 0,//空间类型 0农业  1工业
    };
    try {
      const res = await pay(params);
      if (!res) {
        return;
      }
      if (totalPrice === 0) {
        getPayResult({ outTradeNo: res.outTradeNo });
        return;
      }
      // TODO 微信支付
      if (params.payType === "WXPAY") {
        if (!installedWX.current) {
          Toast.show("请先安装微信");
          return;
        }
        const result = await window.JsBridge.wxPay({
          partnerId: res.partnerId || "",
          prepayId: res.prepayId || "",
          nonceStr: res.nonceStr || "",
          timeStamp: res.timeStamp || "",
          sign: res.sign || "",
          appId: res.appId,
        });
        // 支付失败
        if (result.errCode === -2) {
          Toast.show({
            content: "支付取消",
            maskClickable: false,
            afterClose: () => {
              onClose();
            },
          });
        } else if (result.errCode === 0) {
          // 支付回调
          let payParams = {
            errStr: result?.errStr,
            errCode: result?.errCode,
            outTradeNo: res.outTradeNo,
            source: "wxpay",
          };
          getPayResult(payParams);
        } else {
          Toast.show("支付发生错误  code: " + (result?.errCode || " "));
        }
      }
      // TODO 支付宝支付
      if (params.payType === "ALIPAY") {
        // 支付宝支付
        const payResult = res.payResult;
        const result = await window.JsBridge.aliPay(payResult);
        // 支付失败
        if (result.resultStatus == 6001) {
          Toast.show({
            content: "支付取消",
            maskClickable: false,
            afterClose: () => {
              onClose();
            },
          });
        } else if (result.resultStatus == 62000) {
          Toast.show("客户端未安装支付通道依赖的服务");
        } else if (result.resultStatus == 9000) {
          // 支付回调
          let resFmt = JSON.parse(result.result || "{}");
          let payParams = {
            errStr: resFmt?.alipay_trade_app_pay_response?.msg || "",
            errCode: resFmt?.alipay_trade_app_pay_response?.code || "",
            outTradeNo: res.outTradeNo || "",
            source: "alipay",
          };
          getPayResult(payParams);
        } else {
          Toast.show("支付发生错误  code: " + (result?.resultStatus || " "));
        }
      }
    } catch (error) {
      console.log(error.msg || error);
      return;
    }
  };

  const onScore = (val) => {
    if (val === score) {
      return;
    }
    if (!/^[1-9]\d*$/.test(val)) {
      setScore("");
    } else {
      if (Number(val) > totalCanUsePoints) {
        Toast.show(`最多可使用${totalCanUsePoints}积分`)
      }
      const newVal = Number(val) > totalCanUsePoints ? totalCanUsePoints: val;
      setScore(newVal);
    }
  };

  const throttlePayment = throttle(onSubmit, 3000);

  const maxUsePoints = () => {
    return round(computeNumber(totalCanUsePoints, '/', (reqInfo?.pointRatio || 100)).result, 2)
  }

  return (
    <div className={classNames(styles.land_confirm_order,{[styles.land_confirm_order_mode2]: mode==2})}>
      <div className={styles.land_order__title}>确认订单</div>
      <div className={styles.order_content}>
        <div className={styles.order_content_left}>
          <div className={styles.order_info}>
            <div>
              <img src={mode == 2?defaultCrad2:defaultCrad} alt="" />
            </div>
            <div>
              <h1>空间{spaceLevel}</h1>
              <h2>¥{round(reqInfo.price, 2)}</h2>
            </div>
          </div>
          {canUseMaxPoints > 0 ? <div className={styles.use_points}>
            <div>
              <div>使用积分</div>
              <div>
                <Switch value={needScore} onChange={setNeedScore}></Switch>
              </div>
            </div>
            {needScore ? (
              <>
                <div>
                  <div>
                    共 {reqInfo?.point} 积分, 最多可抵扣
                    {maxUsePoints()}元
                  </div>
                  <span>-<span>￥</span>{(score / 100)?.toFixed(2)}</span>
                </div>
                <div>
                  <Input
                    value={score}
                    type="number"
                    disabled={reqInfo?.point <= 0}
                    onChange={onScore}
                    placeholder="请输入使用积分"
                    className={styles.input_point}
                  ></Input>
                </div>
              </>
            ): null}
          </div>: null}
        </div>
        <div className={styles.order_content_right}>
          <div className={styles.price_info}>
            <div>
              <div>商品总价</div>
              <div>
                <span>￥</span>
                {round(reqInfo.price, 2)}
              </div>
            </div>
            {canUseMaxPoints > 0 ? <div>
              <div>积分抵扣</div>
              <div>-<span>￥</span>{round((score / 100), 2)}</div>
            </div>: null}
            <div>
              <div>合计应付</div>
              <div>
                <span>￥</span>
                {round(totalPrice, 2)}
              </div>
            </div>
          </div>

          <div className={styles.pay_type}>
            <h1>选择支付方式</h1>
            <Radio.Group value={payMethod} onChange={setPayMethod}>
              {payTypeList.length > 0 &&
                payTypeList.map((payItem, index) => {
                  return (
                    <div
                      className={styles.speed_card_order__selectpay}
                      key={index}
                    >
                      {payItem.payCode === "Alipay" && (
                        <div>
                          <label htmlFor="alipay">
                            <AliPayIcon></AliPayIcon>
                            <span>支付宝</span>
                          </label>
                          <Radio id="alipay" value="alipay"></Radio>
                        </div>
                      )}
                      {payItem.payCode === "WeChat" && (
                        <div>
                          <label htmlFor="wxpay">
                            <WxPayIcon></WxPayIcon>
                            <span>微信</span>
                          </label>
                          <Radio id="wxpay" value="wxpay"></Radio>
                        </div>
                      )}
                    </div>
                  );
                })}
            </Radio.Group>
          </div>
        </div>
      </div>
      <div className={styles.order_foot}>
        <p>
          <span>合计</span>
          <span>￥</span>
          <span>{round(totalPrice, 2)}</span>
        </p>
        <GreenBtn onClick={throttlePayment}>提交订单</GreenBtn>
      </div>
      {mode == 2 ?<BackBtnMode2 onClick={onClose}></BackBtnMode2>: <BackBtn onClick={onClose}></BackBtn>}
    </div>
  );
};

export default ConfirmOrder;
