import React, { useState, useEffect, useRef } from "react";
import LevelInfo from "./components/levelInfo";
import LandUpgradation from "./components/landUpgradation";
import ExperienceLevel from "./components/experienceLevel";
import WorkDispatch from "./components/workDispatch";
import { BaseBtn } from "../components/button";
import classnames from "classnames";
import { Tabs, Toast } from "antd-mobile";
import { CloseBtn } from "../components/button";
import { LandDetailContext } from "./context";
import Order from "./components/order";
import { getLandLevel } from "../utils";
import { useMyPackage } from "../acceleratorCard/myPackage";
import { useSpeedCard } from "../acceleratorCard/myAcceleratorCard";

import { expGemstores, uploadLandExp } from "@/api/guland";
import "./index.scss";

const EmptyView = ({ children }) => <>{children}</>;

const LandDetail = (props) => {
  const { ctrl, upgradeCallBack, pageInit, mode,container } = props;
  const [currentTab, setCurrentTab] = useState("0");
  const [info, setInfo] = useState({});
  const [show, setShow] = useState(false);
  const [orderShow, setOrderShow] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  const [expGemInfo, setExpGemInfo] = useState({});
  const landUpRef = useRef(null);
  const workRef = useRef(null);
  const [onOpen, SpeedCard] = useSpeedCard();
  const [onMyPackageOpen, PropView] = useMyPackage({
    mode,
    from: "landDetail",
  });

  useEffect(() => {
    ctrl.current = {
      show(info, tabIndex) {
        const tab = info.landType?(info.landType === 1?"0":"1"):(info?.mode == 2?"1":"0")
        info.currentLevel = getLandLevel(info.collectionId);
        info.landId = info.nftNo;
        info.setFlag = info.setFlag;
        setInfo(info);
        console.log('info',info,mode,tabIndex,tab)
        setCurrentTab(tabIndex || tab);
        setShow(true);
      },
    };
  }, []);

  useEffect(() => {
    show && getExpGemstores();
  }, [show]);

  const upLandInfo = (upLandInfo) => {
    info.currentLevel = getLandLevel(upLandInfo.collectionId);
    info.landId = upLandInfo.nftNo;
    info.nftImage = upLandInfo.nftImage;
    setInfo(info);
    pageInit();
    landUpRef.current &&
      landUpRef.current.getUpgradeInfo(getLandLevel(upLandInfo.collectionId));
  };

  const getExpGemstores = async () => {
    try {
      const res = await expGemstores({
        landId: info.id,
        collectionId: info.collectionId,
        landNftNo: info.landId,
        spaceType: +mode === 2 ? 1 : 0,//空间类型 0农业  1工业
      });
      const { code, msg, data } = res || {};
      if (code !== "000000") throw msg;
      setExpGemInfo(data);
    } catch (error) {
      Toast.show(error);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <LandDetailContext.Provider
      value={{
        mode: mode,
        landInfo: info,
        setCurrentTab,
        upLandInfo,
        closeLandDetail: () => setShow(false),
        upgradeCallBack: upgradeCallBack,
        refreshUpgradeInfo:
          landUpRef.current && landUpRef.current.getUpgradeInfo,
        refreshExp: () => getExpGemstores(),
        refreshDailyExp: workRef.current && workRef.current.refreshDailyExp,
        pageInit: pageInit,
        currentTab,
        expGemInfo,
        openOrder(args) {
          setOrderShow(true);
          setOrderInfo(args);
        },
      }}
    >
      <div className="land-detail-page land-detail">
        <div
          className={classnames("land-detail-container", {
            hide: orderShow,
            mode2: mode == 2,
          })}
        >
          <LevelInfo
            mode={mode}
            onSpeed={() => {
              mode == 2 ? onMyPackageOpen() : onOpen();
            }}
            expGemInfo={expGemInfo}
          />
          <div className="land-detail-view">
            <div
              className={classnames("land-detail-btns", { mode2: mode == 2 })}
            >
              {["土地升级", "经验等级", "打工派遣"].map((text, i) => {
                let isShow = true
                if(+mode === 2 && i == 0 && info.landType === 2){
                  isShow = false
                }else{
                  isShow = true
                }
                if(!isShow){
                  return null
                }
               return(
                <EmptyView key={i}>
                  <div className="mt-24"></div>
                  <BaseBtn
                    onClick={() => setCurrentTab(i.toString())}
                    active={i == currentTab}
                  >
                    {text}
                  </BaseBtn>
                </EmptyView>
               ) 
              })}
            </div>
            <div style={{ flex: 1, position: "relative" }}>
              <Tabs activeKey={currentTab} onChange={setCurrentTab}>
                {((mode == 2 && info.landType !== 2 ) || (mode!=2))&&<Tabs.Tab title="土地升级" key="0">
                  <LandUpgradation ref={landUpRef} />
                </Tabs.Tab>}
                <Tabs.Tab title="经验等级" key="1">
                  <ExperienceLevel />
                </Tabs.Tab>
                <Tabs.Tab title="打工派遣" key="2">
                  <WorkDispatch ref={workRef} pageInit={pageInit} mode={mode} container={container}/>
                </Tabs.Tab>
              </Tabs>
            </div>
          </div>

          <CloseBtn
            mode={mode}
            onClick={() => {
              setShow(false);
              setExpGemInfo({});
            }}
          ></CloseBtn>
        </div>

        {orderShow && (
          <Order
            onClose={() => {
              setOrderShow(false);
            }}
            info={orderInfo}
          ></Order>
        )}
        {SpeedCard}
        {PropView}
      </div>
    </LandDetailContext.Provider>
  );
};

export default LandDetail;
