import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { Mask } from 'antd-mobile';

const ComposeModal = ({onClose, materialList, confirmCB, type}) => {
  const [visible, setVisible] = useState(true);

  const cancel = () => {
    setVisible(false);
    onClose && onClose();
  }

  const confirm = () => {
    confirmCB && confirmCB();
    setVisible(false);
    onClose && onClose();
  }

  return <Mask className='compose-mask' visible={visible} onMaskClick={() => cancel()}>
    <div className="mask-content">
      <div className="title">合成变异{type}</div>
      <div className="tip">确定将以下藏品合成为变异{type}吗？合成后以下产品将被回收，且操作不可逆</div>
      <div className="compose-collection">
        {materialList.map((item, index) => {
          return <div className='material-list-item' key={`material-${index}`}>
            <img src={item.imageBg} alt="" />
            <span>#{item.nftNo}</span>
          </div>
        })}
      </div>
      <div className="btn-box">
        <div className="cancel-btn common" onClick={() => cancel()}>取消</div>
        <div className="confirm-btn common" onClick={() => confirm()}>确认合成</div>
      </div>
    </div>
  </Mask>
}

ComposeModal.open = ({materialList, confirmCB, type}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<ComposeModal onClose={close} materialList={materialList} confirmCB={confirmCB} type={type}/>, div);
}

export default ComposeModal;