import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import ListView from "@/components/AntListView";
import {hideLoading, showLoading} from "@/components/loading";
import {getQuizCouponList,} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";


const BuyCollection = (props) => {
    const [dataList, setDataList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const currentPage = useRef(1);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            showLoading()
            const res = await getQuizCouponList({currentPage: currentPage.current, pageSize: 10, type: 2})
            hideLoading();
            setIsLoad(true);
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let dataTem = res.data;
                let record = dataTem.record || [];
                if (currentPage.current > 1) {
                    setDataList([...dataList, ...record]);
                } else {
                    setDataList(record);
                }
                setHasMore(record.length >= 10);
            }
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const loadMore = async () => {
        currentPage.current = currentPage.current + 1
        await loadData()
    }

    const renderItem = (item, index) => {
        return (

            <div key={index.toString()} className={styles.item}>
                <div className={styles.rowSup}>
                    <div>购买时间</div>
                    <div className={styles.strong}>{item.orderTime}</div>
                </div>
                <div className={styles.row}>
                    <span>张数</span>
                    <div className={styles.strong}>{item.num}张</div>
                </div>
                <div className={styles.row}>
                    <span>总价</span>
                    <div className={styles.strong}>¥{item.totalPrice}</div>
                </div>
                <div className={styles.row}>
                    <span>积分抵扣</span>
                    <div className={styles.strong} >{`${item.usePoint}积分(¥${item.usePoint / 100})`}</div>
                </div>
                <div className={styles.row}>
                    <span>实付</span>
                    <div className={styles.strong}>¥{item.useCash}</div>
                </div>
                <div className={styles.rowSub}>
                    <div>订单编号：{item.outTradeNo}</div>
                    <div className={styles.red}>获得{item.totalCount}张入场券</div>
                </div>
            </div>
        )
    }


    return (
        <div className={styles.epic_boring_ape_container}>
            <ListView
                dataSource={dataList}
                renderItem={renderItem}
                onRefresh={() => {
                    currentPage.current = 1;
                    loadData()
                }}
                hasMore={hasMore}
                onEndReached={loadMore}
                style={{paddingTop: 50}}
                isLoad={isLoad}
            />

        </div>
    )
}


export default BuyCollection;
