/*  */

export const AliPayIcon = () => {
  return <div className="land-game-icon land-game-icon__alipay"></div>;
};

export const WxPayIcon = () => {
  return <div className="land-game-icon land-game-icon__wxpay"></div>;
};

export const ExpIcon = () => {
  return <div className="land-game-icon land-game-icon__exp"></div>;
};

export const DiamondIcon = () => {
  return <div className="land-game-icon land-game-icon__diamond"></div>;
};

export const TreasureIcon = () => {
  return <div className="land-game-icon land-game-icon__treasure"></div>;
};

export const Title = ({title, className, ...props}) => {
  return <div className={`land-game-title ${className || ''}`} {...props}>{title}</div>
}
