import React from 'react'
import styles from './style.module.scss'

const WinLotteryModal = ({onClose, seePrize, onLottery, prizes}) => {

  const onBtnClick = (i) => {
    if (i === 1) {
      console.log('收下啦')
    } else if (i === 2) {
      console.log('填写地址')
      seePrize && seePrize()
    } else if (i === 3) {
      console.log('去兑换')
      seePrize && seePrize()
    } else if (i === 4) {
      console.log('再抽1次')
      onLottery('single')
    } else if (i === 5) {
      console.log('再抽10次')
      onLottery('mutiple')
    }
    onClose && onClose()
  }

  return <div className={styles.win_lottery_modal}>
    <div className={styles.win_title}></div>
    {Array.isArray(prizes) ? <div className={styles.win_prizes}>
      {
        prizes.map((item, index) => {
          return <div className={styles.win_prizes_item}>
            <img key={index} src={item.imgUrl} alt="" />
            <span>{item.value}</span>
          </div>
        })
      }
    </div>:<div className={styles.win_prizes_1}>
      <img src={prizes.imgUrl} alt="" />
      <span>{prizes.value}</span>
    </div>}
    <div className={styles.btn_box}>
      <div className={styles.btn_box_left}>
        <div className={styles.btn_style} onClick={() => onBtnClick(1)}>收下啦</div>
        {
          Array.isArray(prizes) ? <div className={styles.address_btn_box}>
            {prizes?.some((item) => item.type === 3) ? <div className={styles.btn_style} onClick={() => onBtnClick(2)}>填写地址</div>: null}
            {prizes.some((item) => item.type === 11) ? <div className={styles.btn_style} onClick={() => onBtnClick(3)}>去兑换</div>: null}
          </div>: <div className={styles.address_btn_box}>
            {prizes?.type === 3 ? <div className={styles.btn_style} onClick={() => onBtnClick(2)}>填写地址</div>: null}
            {prizes?.type === 11 ? <div className={styles.btn_style} onClick={() => onBtnClick(3)}>去兑换</div>: null}
          </div>
        }
      </div>
      <div className={styles.btn_box_left}>
        <div className={`${styles.btn_style} ${styles.btn_style_single}`} onClick={() => onBtnClick(4)}>再抽1次</div>
        <div className={`${styles.btn_style} ${styles.btn_style_mutiple}`} onClick={() => onBtnClick(5)}>再抽10次</div>
      </div>
    </div>
  </div>
}

export default WinLotteryModal