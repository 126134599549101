/*
    绿地Ape World数字藏品 
*/

import React from "react";
import Rotate3D from "@/components/Rotate3D";
import "./index.scss";

const WordWhatCard = (props)=> {
    const {dataSouce={}, imgSources} = props

    return (
        <div className="WordWhatCard">
            <span>{ dataSouce.title || '绿地变异无聊猿数字藏品'}</span>
            <div className="ape_world_content">
                <div className="content_display">
                    <Rotate3D sources={imgSources} />
                </div>
            </div>
        </div>
    )
}

export default WordWhatCard;