/** 
 * @description 商品信息展示 + 数量修改
*/

import React, { useState, useEffect, useRef } from "react";
import Stepper from "@/components/Stepper";
import "./index.scss";
import { round, computeNumber } from "@/utils/publicFun";

const ShopEditBox = (props)=> {

    const {
        payDetail = {},
        maxCount,
        showPurchaseMax
    } = props
    
    const stepperRef = useRef(null)
    const stepLength = 1
    const [salePrice, setSalePrice] = useState(0)
    const [count, setCount] = useState(maxCount);

    useEffect(()=>{
        if(payDetail.price && stepperRef?.current) {
            const diffCount = payDetail.saleBatchInfo?.stock - payDetail.saleBatchInfo?.soldDisplay;
            setCount(diffCount >= maxCount ? maxCount: diffCount);
            onCountChange(stepperRef.current.value)
        }
    },[payDetail])

    const onCountChange = (ct)=> {
        const basePrice = payDetail.price || 0
        //价格处理
        let totalPricce = basePrice
        if(stepLength > 1) {
            totalPricce = ct > 1 ? computeNumber(ct - 1, '*', basePrice).next('*', stepLength).result: computeNumber(ct, '*', basePrice).result
        }else {
            totalPricce = computeNumber(ct, '*', basePrice).result
        }
         
        setSalePrice(totalPricce)
        props.onPriceChange && props.onPriceChange(totalPricce,ct)
    }

    return (
        <div className="shop-edit-box">
            <div className="sale_display_img">
                <img src={payDetail.imageBg} alt=""/>
            </div>
            <div className="shop_sale_content">
                {/* 藏品名称 */}
                <p>{payDetail.collectionName }</p>
                <div className="shop_sale_price">
                    <span>¥{round(payDetail.price || 0,2)}</span>
                    <div className="stepper-content">
                        {showPurchaseMax ? <span>最多可购买{count}个</span>: null}
                        <Stepper ref={stepperRef} maxValue={count} 
                            defaultValue={1} 
                            minValue={1}
                            onChange={onCountChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
} 

export default ShopEditBox;