/**
 * @description 农业用地转工业用地/工业打工权限开通弹窗内容
 */
import React, { useRef, useEffect, useState } from "react";
import { showLoading, hideLoading } from "@/components/loading";
import img1 from "@/assets/img/landfi/landgy/img_land_a.png";
import img2 from "@/assets/img/landfi/landgy/img_land_b.png";
import img3 from "@/assets/img/landfi/landgy/img_land_c1.png";
import {
  confirmSimpleOrder,
  queryPayResult,
  getPayParams,
  getUserInfo,
} from "@/api/projectManager_self";
import { getTransType } from "../../../utils";
import { throttle } from "@/utils/publicFun";
import { Toast } from "antd-mobile";
import { sureOrderInfo } from "@/api/guland";

import "./index.scss";

const IType = {
  converter: 1, // 农业转工业
  authOpen: 2, // 打工权限开通
};
const PayType = {
    1: 'POINT', // 积分
    2: 'GEMSTONES', // 宝石购买
  };

export default (props) => {
  const { data = {}, onClose, successCB, type, container, mode } = props;
  const { landInfo, priceInfo } = data;
  const [reqInfo, setReqInfo] = useState({});
  const [score, setScore] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);


  const img = {
    1: img1,
    2: img2,
    3: img3,
  }[landInfo?.blockLevel];

  useEffect(() => {
    getSureOrderInfo();
  }, []);

  const getPayResult = (params) => {
    try {
      showLoading();
      let timeId = setTimeout(async () => {
        const { code, msg } = await queryPayResult(params);
        hideLoading();
        if (code === "000000") {
          Toast.show({
            content: "支付成功",
            maskClickable: false,
            afterClose: () => {
              onClose();
              successCB && successCB();
            },
          });
        } else {
          Toast.show(msg);
        }
        timeId && clearTimeout(timeId);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

   //获取用户信息
   const queryUserInfo = async () => {
    const { code, msg, data } = await getUserInfo();
    if (code !== "000000") throw msg;
    return data;
  };

  const getSureOrderInfo = async () => {
    try {
      showLoading();
      // TODO  获取前置参数
      const upgradeType = getTransType(landInfo?.blockLevel, landInfo?.blockLevel);
      const res = await sureOrderInfo({
        spaceType:1,
        upgradeType,
        collectionId: landInfo.collectionId,
      });
      const {
        maxPointUse,
        collectionId,
        saleBatchId,
      } = res.data || {};
      const userInfo = await queryUserInfo()
      setReqInfo({
        maxPointUse,
        saleBatchId,
        collectionId,
        upgradeType,
        userInfo,
        defRepeatStr:priceInfo[0]?.defRepeatStr
      });
      hideLoading();
    } catch (error) {
      hideLoading();
      console.log(error)
      Toast.show(typeof error === "string" ? error : error.msg);
    }
  };

  const pay = async (params) => {
    try {
      showLoading();
      const orderInfo = await confirmSimpleOrder(params);
      if (orderInfo.code !== "000000") throw orderInfo.msg;
      if (totalPrice === 0) {
        hideLoading();
        return orderInfo.data;
      }
      const paramsInfo = await getPayParams({
        outTradeNo: orderInfo?.data?.outTradeNo || "",
      });
      hideLoading();
      if (paramsInfo.code !== "000000") throw paramsInfo.msg;
      return { ...paramsInfo.data, ...orderInfo.data };
    } catch (error) {
      hideLoading();
      Toast.show(error);
      return false;
    }
  };

  const onSubmit = async () => {
    // check app-env
    if (reqInfo.notPay) {
      Toast.show("您有待付款的订单，请先完成上次订单");
      return;
    }
    if (!reqInfo.saleBatchId) {
      Toast.show("系统异常");
      return;
    }
    const params = {
      saleBatchId: reqInfo.saleBatchId || "",
    //   collectionId: reqInfo.collectionId,
      payType: PayType[priceInfo[0]?.payType],
      source: "app",
      totalUsePoint:0,
      totalUseCash:totalPrice,
      uid: reqInfo?.defRepeatStr,
      totalNum: 1,
      type: '16',
      upgradeType: reqInfo?.upgradeType,
      nftNo: [landInfo.nftNo],
      activityCode:'indLand',
      productId:reqInfo.collectionId
    };
    if(priceInfo[0]?.payType === 1){
        params.totalUsePoint = score || priceInfo[0]?.upgradePrice
    } else if(priceInfo[0]?.payType === 0){
        params.totalUseCash = totalPrice
    } else{
        params.gemstonesNum = priceInfo[0]?.upgradePrice
    }
    try {
      const res = await pay(params);
      if (!res) {
        return;
      }
      if (totalPrice === 0) {
        getPayResult({ outTradeNo: res.outTradeNo });
        return;
      }
    
    } catch (error) {
      console.log(error.msg || error);
      return;
    }
  };

  const throttlePayment = throttle(onSubmit, 3000);

  return (
    <div className="transteToGyModel">
      <img
        src={require("@/assets/img/landfi/landgy/icon_del.png")}
        alt=""
        className="model_close"
        onClick={onClose}
      />
      <h1 className="model_title">
        {type == IType.authOpen ? "工业打工权限" : "工业工地"}
      </h1>
      <div className="count_show">
        <span>{`${
          {
            1: `积分：${priceInfo[0]?.myPoint}`,
            2: `宝石：${priceInfo[0]?.myGems}`,
          }[priceInfo[0]?.payType]
        }`}</span>
      </div>
      <div className="model_conent">
        <img src={img} alt="" />
        <span>{`共需消耗${priceInfo[0]?.upgradePrice}${
          {
            1: "积分",
            2: "宝石",
          }[priceInfo[0]?.payType]
        }`}</span>
      </div>
      <div className="model_btns">
        <div className="btn cancel" onClick={onClose} />
        <div
          className={`btn ${type == IType.authOpen ? "confirm2" : "confirm1"}`}
          onClick={throttlePayment}
        />
      </div>
    </div>
  );
};
