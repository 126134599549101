/** 
 * @description 绿地康养数字藏品
 * @author 本界面由蓝湖设计稿生成
 * @returns { /kangyang-nft/lanhu }
*/

import React, { Component } from "react";
import { NavBar, Toast } from "antd-mobile";
import NFTModal from '@/components/NFTModal';
import { hideLoading, showLoading } from '@/components/loading';
import ActivityRules from '../components/ActivityRules';
import Service from '../service';

import "./common.scss";
import "./index.scss";

class LanHu extends Component {
    pageDom = null;
    state = {
        userInfo: {},
        dataSource: {}
    };

    componentDidMount() {
        // this.getData(true);
    }
    getData = async (loading = false) => {
        try {
            if (loading) showLoading();
            if (loading) {
                const userInfo = await Service.getUserCertificateStatus();
                this.setState({userInfo})
            }
            const res = await Service.getInitData();
            if (loading) hideLoading();
            if (res.code !== '000000') throw res.msg;
            this.setState({
                dataSource: res.data
            })
        } catch (error) {
            hideLoading();
            Toast.show(error);

        }
    }

    onRightBtnClick = (item={}) => {
        if(item.key ===1) {
            const ModalInstance = NFTModal.show({
                content: <ActivityRules onClose={() => ModalInstance?.close()} />,
                getContainer: this.pageDom,
            });
        }else if(item.key ===2) {
            window.JsBridge.navigate('Verify');
        }else if(item.key ===3) {
            const uri = `/kangyang-nft/mergeHistory`
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
            } else {
                this.props.history.push(uri);
            }
        }
        
    }

    render() {
        const { userInfo, dataSource } = this.state;
        return (
            <div className="lanhu_page flex-col" ref={ref=> this.pageDom=ref}>
                <div className="group_1 flex-col">
                    <div className="section_1 flex-col">
                        <span className="text_1">绿地首款RWA藏品</span>
                        <div className="text-wrapper_1 flex-col">
                            <span className="paragraph_1">
                                ¥10000
                                <br />
                            </span>
                        </div>
                    </div>
                    <div className="section_2 flex-col">
                        <div className="text-wrapper_2">
                            <span className="text_2">合成藏品</span>
                            <span className="paragraph_2">
                                <br />
                                能换"房"
                            </span>
                        </div>
                    </div>
                    <div className="section_3 flex-col" />
                    <div className="section_4 flex-col" />
                </div>
                <div className="group_2 flex-col">
                    <div className="group_3 flex-row">
                    </div>
                    <div className="group_4 flex-row">
                        <NavBar
                            className="nav-bar_1 NavBar"
                            backArrow={false}
                            onBack={Service.onBack}
                            left={
                                <img
                                    alt=''
                                    className="label_3"
                                    src={
                                        "https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG66e876510b5892cc1741710c71ad8a72.png"
                                    }
                                />
                            }
                        ></NavBar>
                    </div>
                    <div className="group_5 flex-row">
                        <button
                            className="button_1 flex-col"
                            onClick={()=>this.onRightBtnClick({key:1})}
                        >
                            <span className="paragraph_3">
                                规<br />则
                            </span>
                        </button>
                    </div>
                    <div className="group_6 flex-row">
                        <button
                            className="button_2 flex-col"
                            onClick={()=>this.onRightBtnClick({key:2})}
                        >
                            <span className="paragraph_4">
                                实<br />名
                            </span>
                        </button>
                    </div>
                    <div className="group_7 flex-row justify-between">
                        <div className="text-wrapper_3 flex-col" onClick={()=>this.onRightBtnClick({key:3})}>
                            <span className="paragraph_5">
                                权<br />益<br />获<br />取<br />记<br />录
                            </span>
                        </div>
                        <span className="text_5">输入文本</span>
                    </div>
                    <div className="group_8 flex-row">
                        <button
                            className="button_3 flex-col"
                            onClick={()=>Service.onBuy(this.props.history)}
                        >
                            <span className="text_6">立即购买</span>
                        </button>
                    </div>
                    <div className="group_9 flex-row">
                        <div className="box_1 flex-row">
                            <div className="text-group_1 flex-col justify-between">
                                <span className="text_7">{`持有${dataSource.requireCount ?? 0}个可合成`}</span>
                                <div className="text-wrapper_4">
                                    <span className="text_8">当前持有:</span>
                                    <span className="text_9">{dataSource.count ?? 0}</span>
                                </div>
                            </div>
                            {
                                dataSource.count >= dataSource.requireCount ?
                                <button
                                    className="button_4 flex-col"
                                    onClick={Service.onMerge}
                                >
                                    <span className="text_10">合成</span>
                                </button>:
                                <div className="wrapper_4 flex-col" >
                                    <span className="text_10">合成</span>
                                </div>
                            }
                            
                        </div>
                    </div>
                    <div className="group_10 flex-row">
                        <div className="text-group_2 flex-col justify-between">
                            {
                                dataSource.detail?.list?.map((item,key)=> {
                                    return (
                                        <div className="text-wrapper_5" key={key}>
                                            <span className="text_11">持有</span>
                                            <span className="text_12">{`${item.position}个`}</span>
                                            <span className="text_13">康养藏品每月可得</span>
                                            <span className="paragraph_6">
                                                <br />
                                                {`${item.pointCount}积分`}
                                            </span>
                                            <span className="text_14">+</span>
                                            <span className="text_15">{`${item.cardName}x${item.cardNum}`}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="text-wrapper_7 flex-row">
                        <span className="text_21">最终解释权归绿地G优所有</span>
                    </div>
                    <div className="image-wrapper_1 flex-row justify-between">
                        <img 
                            alt=''
                            className="image_1"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNGd24bc01efd472ba931a9deb876e470df.png"}
                        />
                        <img
                            alt=''
                            className="image_2"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG74dc96af31ed5fce023ee45a2a797910.png"}
                        />
                    </div>
                    <div className="image-wrapper_2 flex-row">
                        <img
                            alt=''
                            className="thumbnail_1"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG1656af0bd64282a67b72147bf6208827.png"}
                        />
                        <img
                            alt=''
                            className="thumbnail_2"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG06be60810f4fdacb7d32442c0953fb86.png"}
                        />
                        <img
                            alt=''
                            className="thumbnail_3"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG57df52c976ae68f0e2dacbe3529be97d.png"}
                        />
                        <img
                            alt=''
                            className="image_3"
                            src={
                                "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/aedcfa67cac843e0b73465708b022c37_mergeImage.png"
                            }
                        />
                        <img
                            alt=''
                            className="thumbnail_4"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG86eb93c126d8e92aeb7105d67a5670e0.png"}
                        />
                        <img
                            alt=''
                            className="thumbnail_5"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG23dfecb9ba406e251a2f570f0c15e52d.png"}
                        />
                        <img
                            alt=''
                            className="thumbnail_6"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNGba3befb458ffa24d4d48eca4966a8c22.png"}
                        />
                        <img
                            alt=''
                            className="thumbnail_7"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG2c40de61f79dfc815772ef8b29b99d3f.png"}
                        />
                        <img
                            alt=''
                            className="thumbnail_8"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNGc3f2ada6d953183f19c15e3c1f338035.png"}
                        />
                        <img
                            alt=''
                            className="thumbnail_9"
                            src={"https://lanhu.oss-cn-beijing.aliyuncs.com/MasterDDSSlicePNG1c8b64698839900384fed28ed4fc6bc9.png"}
                        />
                    </div>
                    <div className="group_11 flex-row">
                    </div>
                </div>
            </div>
        );
    }
}
export default LanHu;
