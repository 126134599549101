import React, {useEffect, useState, useRef} from 'react';
import styles from './styles.module.scss'
import ListView from "@/components/AntListView";
import {hideLoading, showLoading} from "@/components/loading";
import {Popup, Toast} from "antd-mobile";
import {getRecoveryRecord} from "@/api/projectManager_self";
import {activityCode} from "@/view/partsRecyclingUnity/home";

const mock = {
    code: '000000',
    msg: 'success',
    data: {
        list: [
            {
                time: '2022.04.22 12:22:30',
                award: '获得史诗猿x1',
                isUp: true,
                list: [{title: '无聊猿铜牌 #C123456'}],
            },
            {
                time: '2022.04.22 12:22:30',
                award: '获得史诗猿x1',
                isUp: true,
                list: [{title: '无聊猿铜牌 #C123456'}, {title: '无聊猿铜牌 #C123456'}, {title: '无聊猿铜牌 #C123456'}, {title: '无聊猿铜牌 #C123456'}, {title: '无聊猿铜牌 #C123456'}, {title: '无聊猿铜牌 #C123456'}, {title: '无聊猿铜牌 #C123456'}, {title: '无聊猿铜牌 #C123456'}, {title: '无聊猿铜牌 #C123456'}],
            },
            {
                time: '2022.04.22 12:22:30',
                award: '获得史诗猿x1',
                isUp: true,
                list: [{title: '无聊猿铜牌 #C123456'}],
            },
        ]
    },
};

//回收记录
const RecycleRecord = ({maskVisible, setMaskVisible,}) => {

    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);
    const currentRef = useRef(null);

    useEffect(() => {
        maskVisible && loadData(true);
    }, [maskVisible])

    const loadData = async (isRefresh) => {
        try {
            if (isRefresh) {
                currentPage.current = 1;
            } else {
                currentPage.current = currentPage.current + 1;
            }
            const params = {
                currentPage: currentPage.current,
                pageSize: pageSize.current,
            }
            const params1 = {
                activityCode: activityCode,
            }
            showLoading();
            const res = await getRecoveryRecord(params,params1);
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            const newList = res.data.record || [];
            if (currentPage.current === 1) {
                setData(newList);
            } else {
                setData([...data, ...newList]);
            }
            isRefresh && setIsLoad(true);
            setHasMore(newList.length >= pageSize.current);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const upOrDown = (item) => {
        item.isUp = !item.isUp;
        setData([...data]);
    }

    const items = (item) => {
        if (item.isUp) {
            if (item.recoveryList.length > 0) {
                let item0 = item.recoveryList[0];
                return <p>{item0.name}  {item0.nftNo}</p>
            } else {
                return null;
            }
        }

        return item.recoveryList.map((item1, index) => {
            return <p key={index.toString()}>{item1.name}  {item1.nftNo}</p>
        })
    }

    const RenderItem = (item) => {
        return <div className={styles.item}>
            <div className={styles.top}>
                <p>回收时间</p>
                <p>{item.recoveryTime || ''}</p>
            </div>

            <div className={styles.middle}>
                {items(item)}
            </div>

            <div className={styles.bottom}>
                <img src={item.isUp ? require('../../imgs/icon_shang.png') : require('../../imgs/icon_xia.png')} alt=""
                     onClick={() => upOrDown(item)}/>
                <p>{item.prizeType === '4' ? `${item.prizeName}x1`: item.prizeName}</p>
            </div>
        </div>
    }


    return <Popup
        visible={maskVisible}
        position='right'
        bodyStyle={{
            backgroundColor: "transparent", height: '100%', width: '100%',
            padding: 0,
        }}>
        <div className={styles.recycle_record}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <img src={require('../../imgs/btn_return.png')} alt=""
                         onClick={() => setMaskVisible(false)}/>
                    <p>回收记录</p>
                    <p/>
                    <div className={styles.line}/>
                </div>
                <div className={styles.list}>
                    <ListView
                        dataSource={data}
                        renderItem={RenderItem}
                        hasMore={hasMore}
                        onRefresh={() => {
                            loadData(true)
                        }}
                        onEndReached={() => loadData(false)}
                        style={{paddingTop: 15}}
                        isLoad={isLoad}
                    />

                </div>
            </div>
        </div>
    </Popup>

}


export default RecycleRecord;
