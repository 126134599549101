/**
 * 描述： 我的订单详情界面
 * 与myOrderDetail界面布局稍有不同
*/
import { Toast, Button } from "antd-mobile"
import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { round, getUrlAllParams, copyText, throttle } from "@/utils/publicFun"
import Error from "@/view/Error/index"
import moment from "moment"
import "./index.scss";
import {getGroupOrderDetail, cancelOrder, getPayParams, queryPayResult, iapValidatePayload} from '@/api/projectManager_self';
import { showLoading, hideLoading } from '@/components/loading';

function GroupOrderDetail(props) {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [orderInfo, setOrderInfo] = useState({})
  const [errorContext, seterrorContext] = useState("")
  const [countdown, setCountdown] = useState()
  const [installedWX, setInstalledWX] = useState(true)
  let { out_trade_no = '' } = getUrlAllParams(search.slice(1))
  const bridge = window.JsBridge
  bridge.setTitle("订单详情")

  useEffect(() => {
    if (!out_trade_no) {
      Toast.show("参数错误")
      seterrorContext("参数错误")
    } else {
      bridge.nativeModules("UMShareModule", "isInstalledWx").then(function (res) {
        setInstalledWX(res)
      })
      getDetal();
    }
  }, [])

  useEffect(() => {
    if (orderInfo.orderStatus === "NOTPAY") {
      const end = new Date(orderInfo?.payDeadline?.replace(/-/g, "/")).getTime()
      const now = new Date().getTime()
      let downTime = moment.utc(end - now)
      setCountdown(downTime.format("mm:ss"))
      let timer = setInterval(() => {
        if (downTime.valueOf() < 1000) {
          Toast.show("订单已超时, 即将返回...")
          setTimeout(() => {
            navigate(-1)
          }, 1500)
        } else {
          downTime.subtract(1, "s")
          setCountdown(downTime.format("mm:ss"))
        }
      }, 1000)
      return () => {
        clearInterval(timer)
      }
    }
  }, [orderInfo])

  const getDetal = async () => {
    try {
      showLoading();
      const { code, msg, data } = await getGroupOrderDetail({outTradeNo: out_trade_no});
      hideLoading();
      if(code !== '000000') throw msg;
      setOrderInfo(data.info);
    } catch (error) {
      hideLoading();
      seterrorContext(error);
      Toast.show(error);
    }
  }

  const gotoSalePaySuccess = (list) => {
    // 此处没有活动码，跳转无法确定藏品3d模型，故直接刷新数据
    const code = undefined
    if(code) {
      navigate(`/ape/PaySuccess`, { 
        state: { collectionList: list, from: ''}, 
        replace: true 
      })
    }else {
      getDetal();
    }
    
  }

  // 取消订单
  const cancel = async () => {
    try {
      showLoading();
      const { code, msg, data } = await cancelOrder({outTradeNo: out_trade_no});
      hideLoading();
      if(code !== '000000') throw msg;
      navigate(-1);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const payOrder = async () => {
    try {
      // const endTime = new Date(orderInfo?.payDeadline?.replace(/-/g, "/")).getTime()
      // const nowTime = new Date().getTime()
      // if (nowTime > endTime) {
      //   Toast.show("已超过购买时间")
      //   return false
      // }

      showLoading();
      const { code, msg, data } = await getPayParams({outTradeNo: out_trade_no});
      hideLoading();
      if(code !== '000000') throw msg;
      if(bridge.hasWebViewBridge()) {
        if (orderInfo?.payType === "wxpay") {
          if (!installedWX) {
            Toast.show("请先安装微信")
            return false
          }
          bridge
            .wxPay({
              partnerId: data.partnerId,
              prepayId: data.prepayId,
              nonceStr: data.nonceStr,
              timeStamp: data.timeStamp,
              sign: data.sign,
              appId: data.appId,
            })
            .then(function (result) {
              // 支付失败
              if (result.errCode === -2) {
                Toast.show("支付未完成")
              } else {
                // 支付回调
                let payParams = {
                  errStr: result?.errStr,
                  errCode: result?.errCode,
                  outTradeNo: out_trade_no || '',
                  source: "wxpay",
                }
                getPayResult(payParams);
              }
            })
        }else if (orderInfo?.payType === "iosIAP") {
          let productInfo = data?.productInfo;
          if (productInfo){
            productInfo.outTradeNo = out_trade_no;
            let payDeadline = data?.payDeadline || '5分钟';
            if (payDeadline){
              Toast.show(`请在${payDeadline}时间内完成支付`)
              let timer = setTimeout(()=>{
                clearTimeout(timer);
                let productId =  JSON.stringify(productInfo);
                bridge
                    .iapPay(productId).then(function (result){
                  if (result?.code === '200'){ //成功
                    let receiptDataObj = JSON.parse(result.receiptData);
                    if (receiptDataObj){
                      getIapPayResult(receiptDataObj);
                    }else {
                      Toast.show("获取苹果参数失败");
                    }
                  }else { //支付失败
                    Toast.show("支付失败  code: " + (result?.code || " "))
                  }
                })
              },2000);
            }
          }
        }else {
          // 支付宝支付
          bridge.aliPay(data.payResult).then(function (result) {
            // 支付失败
            if (result.resultStatus == 6001) {
              Toast.show("支付未完成")
            } else {
              // 支付回调
              let resFmt = JSON.parse(result.result || "{}")
              let payParams = {
                errStr: resFmt?.alipay_trade_app_pay_response?.msg || "",
                errCode: resFmt?.alipay_trade_app_pay_response?.code || "",
                outTradeNo: out_trade_no || "",
                source: "alipay",
              }
              getPayResult(payParams);
            }
          })
        }
      } else {
        Toast.show("请到APP中支付")
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const getPayResult = (params) => {
    try {
      showLoading();
      let timeId = setTimeout(async () => {
        const { code, msg, data } = await queryPayResult(params);
        hideLoading();
        if(code === '000000') {
          if (orderInfo?.orderDetailListVOS?.[0]?.apeOrderType === 3) {
            navigate(-1)
            return
          }
          if(data) {
            gotoSalePaySuccess(data);
          } else {
            Toast.show({
              content: '已支付，请到“我的-我的藏品”查看',
              afterClose: () => {
                navigate(-1);
              }
            });
          }
        } else {
          Toast.show(msg);
        }
        timeId && clearTimeout(timeId);
      }, 2000);
    } catch (error) {
    }
  }

  const getIapPayResult = (params) => { //获取内购的验证结果
    try {
      showLoading();
      let timeId = setTimeout(async () => {
        const { code, msg, data } = await iapValidatePayload(params);
        hideLoading();
        if(code === '000000') {
          if(data) {
            gotoSalePaySuccess(data);
          } else {
            Toast.show('已支付，请到“我的-我的藏品”查看');
         let timer = setTimeout(()=>{
              navigate(-1);
              clearTimeout(timer)
            },2000)
          }
        } else {
          Toast.show(msg);
        }
        timeId && clearTimeout(timeId);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  }


  const throttlePayment = throttle(payOrder, 3000)

  return (
    <div className="order-detail-page">
      {!errorContext ? (
        <>
          <div className="order-status">
            <div className="detail">
              <div className="status">
                {orderInfo.orderStatus === "SUCCESS" ? "已付款" : orderInfo.orderStatus === "NOTPAY" ? "待付款" : "已取消"}
              </div>
              {orderInfo.orderStatus === "SUCCESS" ? (
                <div className="bar">恭喜您成为{orderInfo.collectionName || '绿地'}收藏者</div>
              ) : orderInfo.orderStatus === "NOTPAY" ? (
                <div className="nbar">
                  <img className="timer" src={require("@/assets/img/order-detail/timer.png")} alt="" />
                  剩余支付时间：{countdown}
                </div>
              ) : (
                <div className="bar">已取消订单</div>
              )}
            </div>
            {orderInfo.orderStatus === "SUCCESS" ? (
              <div className="icon">
                <img src={require("@/assets/img/order-detail/success.png")} alt="" />
              </div>
            ) : orderInfo.orderStatus === "CANCEL" ? (
              <div className="icon">
                <img src={require("@/assets/img/order-detail/cancel.png")} alt="" />
              </div>
            ) : null}
          </div>
          <div className="order-content">
            {
              (orderInfo.orderDetailListVOS || []).map((item, index) => {
                return (
                  <div className="order_content__box" key={index}>
                    <div className="order-img">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="order-message">
                      <div className="title">{item.collectionName}</div>
                      <div className="price">{round(item.price, 2)}</div>
                      <div className="pay-detail">
                        <span className="num">x{item.num}</span>
                        <span className="status">
                          {item.orderStatus === "SUCCESS" ? "已付款" : item.orderStatus === "NOTPAY" ? "待付款" : "已取消"}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            
          </div>
          <div className="price-detail">
            <div className="price ">
              <span className="text">商品金额</span>
              <span className="num">{round(orderInfo.totalPrice, 2)}</span>
            </div>
            <div className="price" style={{display:'none'}}>
              <span className="text">积分抵扣</span>
              <span className="num">{orderInfo.usePoint === 0 ? 0 : round(orderInfo.usePoint, 2)}</span>
            </div>
            <div className="a"></div>
            <div className="line"></div>
            <div className="credit">
              <span className="total">总计：</span>
              {!orderInfo.usePoint === 0 && (
                <span className="point">
                  积分抵扣：{orderInfo.usePoint * 100} (-¥{orderInfo.usePoint === 0 ? 0 : round(orderInfo.usePoint, 2)})
                </span>
              )}
              <span className="price"> ¥{round(orderInfo.useCash, 2)}</span>
            </div>
          </div>
          <div className="order-message-other">
            <div className="title">订单信息</div>
            <div className="message">
              <span className="text">订单编号：</span>
              <div className="ordernumber" id="orderNum">
                {orderInfo.outTradeNo && (
                  <>
                    <div className="copy" onClick={() => copyText(orderInfo.outTradeNo, document.getElementById("orderNum"))}></div>
                    <span className="number" id="copy">
                      {orderInfo.outTradeNo || ""}
                    </span>
                  </>
                )}
              </div>
            </div>

            <div className="message">
              <span className="text">创建时间：</span>
              <span className="number">{orderInfo.createdAt || ""}</span>
            </div>
            <div className="message">
              <span className="text">支付方式：</span>
              <span className="number">
                {orderInfo.payType === "wxpay" ? "微信支付" : orderInfo.payType === "alipay" ? "支付宝支付" : orderInfo.payType === "iosIAP" ? "苹果支付" : "积分支付"}
              </span>
            </div>
          </div>
          {orderInfo.orderStatus === "NOTPAY" ? (
            <div className="notPay_footer">
              <Button className="cancel_order" onClick={cancel}>
                取消订单
              </Button>
              <Button className="pay" onClick={throttlePayment}>
                付款
              </Button>
            </div>
          ) : null}
        </>
      ) : (
        <Error context={errorContext} />
      )}
    </div>
  )
}

export default GroupOrderDetail
