/*
 * 订单支付底部按钮
*/
import { Button } from "antd-mobile"
import { round, throttle } from "@/utils/publicFun";
import styles from "./styles.module.scss";

export default ({ totalPrice, onClick = () => null }) => {
    const throttlePayment = throttle(onClick, 3000);
    return (
        <div className={styles.container}>
            <div className="bottom-left">
                <span>总计：</span>
                <div className="price-text">
                    <div>
                      <span>¥</span>
                      <span>{round(totalPrice,2)}</span>
                    </div>
                    {
                        //隐藏划线
                        // payDetail.salePrice > payDetail.totalPrice &&
                        // <div>¥<span>{round(payDetail.totalPrice,2)}</span></div>
                    }
                </div>
            </div>
            <div className="bottom-right">
                <Button onClick={throttlePayment}>立即支付</Button>
            </div>
        </div>
    )
}