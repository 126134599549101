import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import ListView from "@/components/AntListView";
import { Toast } from "antd-mobile";
import { userGemStonesDetail } from "@/api/guland";
import { hideLoading, showLoading } from "@/components/loading";
import close from "@/assets/img/landfi/close-circle-fill.png";
import ScrollableTable from "../components/ScrollTable";
import { guessRecord } from "@/api/activity";

const Record = ({ onClose }) => {
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const columns = [
    {
      dataKey: "sessions",
      title: "场次",
      width: 140,
      align: "center",
    },
    {
      dataKey: "guessResult",
      title: "选项",
      width: 80,
      align: "center",
      render: (value, record) => {
        const val = {
          0: "看涨",
          1: "看平",
          2: "看跌",
        }[value];
        return (
          <div>
            <span>{val}</span>
          </div>
        );
      },
    },
    {
      dataKey: "gemsNum",
      title: "投入宝石",
      width: 100,
      align: "center",
      className: "amount",
    },
    {
      dataKey: "investTime",
      title: "投入时间",
      width: 140,
      align: "center",
    },
    {
      dataKey: "previousPrice",
      title: "上场价格",
      align: "center",
      width: 80,
    },
    {
      dataKey: "thisPrice",
      title: "本场价格",
      align: "center",
      width: 80,
    },
    {
      dataKey: "outcome",
      title: "竞猜结果",
      width: 140,
      align: "center",
      fixed: true,
      render: (value, record) => {
        if (value === null) {
          return "等待中";
        }
        const val = {
          0: "胜利",
          1: "失败",
        }[value];
        return (
          <div className="result">
            <span>
              {val}
              {value === 0 && <span className="winNum">+{record?.winNum}</span>}
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getList(true);
  }, []);
  const getList = async (isRefresh, loading = true) => {
    try {
      loading && showLoading();
      if (isRefresh) {
        currentPage.current = 1;
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
      };
      const res = await guessRecord(params);
      loading && hideLoading();
      if (res.code !== "000000") throw res.msg;
      if (currentPage.current === 1) {
        setList(res?.data?.recordList || []);
      } else {
        setList([...list, ...res?.data?.recordList]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.recordList?.length >= pageSize.current);
    } catch (error) {
      console.log(error);
      Toast.show(error);
    }
  };
  return (
    <div className={styles.detail_out_mask}>
      <div className={styles.detail_out}>
        <div className={styles.title_box}>
          <div className={styles.close_box}>
            <img
              className={styles.close}
              src={close}
              alt=""
              onClick={() => onClose()}
            />
          </div>
          <div className={styles.title}>竞猜记录</div>
        </div>

        <div className={styles.box}>
          <div className={styles.box_inner} id="box-scroll">
            <ScrollableTable
              columns={columns}
              dataSource={list}
              hasMore={hasMore}
              getList={getList}
              isLoad={isLoad}
              onEndReached={async () => {
                await getList(false, false);
              }}
              customStyle={{ imgStyle: { width: 150, height: 150 } }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Record;
