/**
 * @description 统一发售详情页
 * @returns {route: /ape/saleDetail}
*/
import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import classNames from 'classnames';
import { hideLoading, showLoading } from '@/components/loading';
import NFTHeader from '@/components/NFTHeader';
import { getUrlAllParams } from "@/utils/publicFun";
import { getGoodsDetail} from "@/api/projectManager_self";
import { Toast } from 'antd-mobile';
import ShareMask from "@/components/ShareMask/index";
import WhatCard from './components/WhatCard';
import WordWhatCard from './components/WordWhatCard';
import DetailCard from './components/DetailCard';
import IntroduceCard from './components/IntroduceCard';
import MarkingCard from './components/MarkingCard';
import NoteBuyCard from './components/NoteBuyCard';
import BottomBuy from './components/BottomBuy';
import { CMap } from './config';
import './index.scss';

const SaleDetail = () => {
    const {search} = useLocation();
    //活动码code
    const {collectionId='82', code='europeanCup'} = getUrlAllParams(search.slice(1));

    const [maskVisible,setMaskVisible] = useState(false)

    const [detailData, setDetailData] = useState({})

    useEffect(()=>{
        const getData = async () => {
            try {
                showLoading()
                const res = await getGoodsDetail({collectionId: collectionId})
                hideLoading();
                if (res.code !== '000000') throw res.msg;
                if (res.data && res.data.info) {
                    setDetailData(res.data.info)
                }
            } catch (error) {
                Toast.show(error);
            }
           
        }
        getData()
    },[])
  return (
    <div className="ape-sale-detail">
        {
            CMap[code]?.share ?
            <NFTHeader leftImg={require('@/assets/img/icon/go_back.png')} 
                rightNode={()=><img style={{height: 20}} src={require('@/assets/img/ape-world/egg_bt_fenxiang.png')}/>}
                rightClick={()=>setMaskVisible(true)}
            />
            : <NFTHeader leftImg={require('@/assets/img/icon/go_back.png')} />
        }
        <div className="page-body">
            <div className="page-body--content">
                <div className="top-bg">
                    <img src={CMap[code]?.adBg}/>
                </div>
                <div className={classNames(`body-content`,code)}>
                    {
                        CMap[code]?.sources ?
                        <WordWhatCard dataSouce={detailData} 
                            sources={CMap[code].sources}
                            sourceBg={CMap[code].sourceBg}
                        />
                        :<WhatCard dataSouce={detailData}/>
                    }
                    <DetailCard dataSouce={detailData} config={CMap[code]}/>
                    <IntroduceCard dataSouce={detailData}/>
                    <MarkingCard dataSouce={detailData}/>
                    <NoteBuyCard dataSouce={detailData}/>
                </div>
            </div>
            <div className="bottom">
                <BottomBuy dataSouce={{...detailData, code: code}}/>
            </div>
        </div> 
        <ShareMask
            maskVisible={maskVisible}
            closeMask={() => setMaskVisible(false)}
            shareCode=''
            title=''
            context=''
            icon=''
            shareLink=''
            type='11'
        />
    </div>
  )
}

export default SaleDetail;