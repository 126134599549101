import React, {useEffect, useRef, useState,} from "react"
import "./index.scss";
import NFTHeader from "@/components/NFTHeader";
import {useNavigate} from "react-router-dom";
import {getCode} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";


const CreateCommunity = (props) => {
  const navigate = useNavigate();

  const [ visible, setVisible] = useState(false)

  const onClickItem = (id)=>{
    if (id == 0){
      window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/myCollectionVote/collectionRaffle`});
    } else {
      window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/myCollectionVote/collectionList`});
    }
  }

  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    try {
      const {code, msg, data} = await getCode();
      console.log('code, msg, data',code, msg, data)
      if (data=="1" || data==1){
        //显示
        setVisible(true)
      }else {
        setVisible(false)
      }
    } catch (error) {
      Toast.show(error);
    }
  }



  return (<div className="create_community_page">
    <NFTHeader midText={'Ape World共创社区'} bgColor={'#FFF'}/>
    {visible && <div className='item_container' onClick={()=>onClickItem(0)}>
      <div className='icon_title'>
        <img className='img_icon' src={require('../img/icon_create_community.png')} alt=''/>
        <span className='title'>积分抽奖</span>
      </div>
      <img className='img_arrow' src={require('@/assets/img/collection/icon_jingru.png')} alt=''/>
    </div>}
    <div className='item_container' onClick={()=>onClickItem(1)}>
      <div className='icon_title'>
        <img className='img_icon' src={require('../img/icon_create_community.png')} alt=''/>
        <span className='title'>共创意见征集</span>
      </div>
      <img className='img_arrow' src={require('@/assets/img/collection/icon_jingru.png')} alt=''/>
    </div>
  </div>)
}
export default CreateCommunity;
