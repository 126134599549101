import React, {useEffect} from 'react';
import styles from './styles.module.scss';


const ExchangeSucc = () => {

    useEffect(()=>{
        window.JsBridge.setTitle('兑换成功')
    },[])

    return (

        <div className={styles.container}>
            <img src={require('@/assets/img/icon_shop_gou.png')} alt="" className={styles.icon}/>
            <p className={styles.title}>兑换成功</p>
            <p className={styles.des}>稍后你可以去【我的数字资产】查看藏品</p>
            <p className={styles.btn} onClick={()=>{
                window.JsBridge.back();
            }}>确认</p>
        </div>

    )
}


export default ExchangeSucc;
