import React, { useEffect, useState, useMemo } from "react";
import styles from "./styles.module.scss";
import { Toast } from "antd-mobile";
import { hideLoading, showLoading } from "@/components/loading";
import weizhi from "@/assets/img/landfi/weizhi.png";
import close from "@/assets/img/landfi/icon_del.png";
import close2 from "@/assets/img/landfi/landgy/icon_del.png";

import { ScoreXBtn, EnergyXBtn } from "../../../components/button";
import appService from "@/utils/appService";
import clickThrottle from "@/utils/clickThrottle";
import classNames from "classnames";
const Exchange = ({
  onClose,
  mode,
  gemstore,
  energyStore,
  nextTime,
  recordInfo = {},
}) => {
  const [isWrite, setIsWrite] = useState("0");
  const [userAddress, setUserAddress] = useState({});
  const [num, setNum] = useState(1);

  useEffect(() => {
    const isWriteTemp =
      recordInfo?.address &&
      recordInfo?.province &&
      recordInfo?.city &&
      recordInfo?.area &&
      recordInfo?.street
        ? "1"
        : "0";
    setIsWrite(isWriteTemp);
  }, [recordInfo]);
  const numHandle = (type) => {
    if (type === "add") {
      setNum(num + 1);
    } else {
      if (num - 1 === 0) {
        Toast.show(`数量不能小于1`);
      } else {
        setNum(num - 1);
      }
    }
  };
  const countNumGem = useMemo(() => {
    const gemNum = recordInfo?.gems || 0;
    return gemNum * num;
  }, [num, recordInfo]);

  const countNumEnergy = useMemo(() => {
    const energyNum = recordInfo?.energy || 0;
    return energyNum * num;
  }, [num, recordInfo]);

  const getDefaultAddress = async () => {
    const res = await appService("101294", {}, true);
    console.log("defaultaddress", res);
    if (res.status > 0) {
      const list = res?.data || [];
      //查找默认地址
      const defaltAddress =
        (list.length > 0 &&
          (list.find((item) => item.isDefaltAddress == 1) || list[0])) ||
        {};
      setUserAddress({ ...recordInfo, ...defaltAddress });
    }
  };

  const getGoodsInfo = async () => {
    const result = await appService("600904", {
      lotterySkuId: recordInfo?.skuId,
    });
    console.log("🚀 ~ getGoodsInfo ~ result:", result);
    if (result?.status > 0) {
      setUserAddress({
        ...recordInfo,
        mtImg: result?.data?.bigUrl,
        mtName: result?.data?.productName,
      });
    }
  };
  useEffect(() => {
    if (isWrite === "1") {
      if (recordInfo?.giftType === 11) {
        getGoodsInfo();
      } else {
        setUserAddress(recordInfo);
      }
    } else {
      recordInfo?.giftType === 3 && getDefaultAddress();
    }
  }, []);

  const submitHandle = async () => {
    const params = {
      activityCode: "indLand",
      exchangeId: recordInfo?.id,
      exchangeNum: num,
      province: userAddress.provinceName,
      city: userAddress.cityName,
      area: userAddress.areaName,
      street: userAddress.streetName,
      consigneeName: userAddress.consigneeName,
      consigneeNumber: userAddress.consigneeNumber,
      address: userAddress.deliveryAddress,
    };
    const res = await appService("600909", params, true);
    if (res.status > 0) {
      Toast.show({
        content: "兑换成功",
        afterClose: () => {
          handleClose(true);
        },
      });
    }
  };
  const confirmHandle = () => {
    if (countNumGem > gemstore) {
      Toast.show("宝石数量不足");
      return;
    }
    if (countNumEnergy > energyStore) {
      Toast.show("能量数量不足");
      return;
    }
    if (!clickThrottle()) return;
    submitHandle();
  };

  const handleClose = (flag) => {
    onClose && onClose(flag);
  };

  return (
    <div
      className={classNames(styles.modal_box, {
        [styles.modal_box_2]: mode == 2,
      })}
    >
      <div className={styles.modal_header}>
        <div className={styles.modal_title}>兑换</div>
        <div className={styles.top_btns}>
          <div>
            <ScoreXBtn style={{ position: "static" }} gemCount={gemstore} />
          </div>
          <div>
            <EnergyXBtn style={{ position: "static" }} gemCount={energyStore} />
          </div>
        </div>
        <img
          className={styles.close}
          src={mode == 2 ? close2 : close}
          alt=""
          onClick={() => {
            handleClose(true);
          }}
        />
      </div>
      <div className={styles.modal_content}>
        <div className={styles.info}>
         {recordInfo?.giftType === 3 && <div className={styles.address}>
            {isWrite === "0" ? (
              <div
                className={styles.add_address}
                onClick={async () => {
                  const url = `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-A/customer/receive-address-unity/index`;
                  const address = await window.JsBridgeNew.push("CustomWeb", {
                    uri: url,
                    callback: true,
                  });
                  console.log("address======>", address);
                  setUserAddress({ ...recordInfo, ...address });
                }}
              >
                <img src={weizhi} alt="" />
                <span className={styles.tip_text}>
                  {userAddress?.provinceName &&
                  userAddress?.cityName &&
                  userAddress?.areaName
                    ? userAddress.provinceName +
                      userAddress.cityName +
                      userAddress.areaName +
                      (userAddress.streetName || "") +
                      (userAddress.deliveryAddress || "")
                    : "请添加收货地址"}
                </span>
                <span className={styles.line}></span>
                <span className={styles.add_btn}>添加</span>
              </div>
            ) : (
              <div className={styles.address_detail}>
                <img src={weizhi} alt="" />
                <div className={styles.address_text}>
                  <span>
                    {userAddress
                      ? userAddress.province +
                        userAddress.city +
                        userAddress.area +
                        (userAddress.street || "") +
                        (userAddress.address || "")
                      : ""}
                  </span>
                  <div className={styles.user_info}>
                    <span>{userAddress?.consigneeName || ""}</span>
                    <span>{userAddress?.consigneeNumber || ""}</span>
                  </div>
                </div>
              </div>
            )}
          </div>}
          <div className={classNames(styles.goods_info,{[styles.goods_info_2]:recordInfo?.giftType !== 3 })}>
            <div className={styles.goods_info_img}>
              <img src={recordInfo?.imageUrl} alt="" />
            </div>
            <div className={styles.goods_info_box}>
              <div
                className={styles.goods_info_title}
              >{`${recordInfo?.lotteryName}`}</div>
              <div className={styles.goods_info_number}>
                {/* <div
                  className={styles.goods_info_number_sub}
                  onClick={() => {
                    numHandle("sub");
                  }}
                >
                  -
                </div>
                <div className={styles.goods_info_number_dig}>{num}</div>
                <div
                  className={styles.goods_info_number_add}
                  onClick={() => {
                    numHandle("add");
                  }}
                >
                  +
                </div> */}
                <div
                  className={styles.goods_info_numAmount}
                >{`共需消耗${countNumGem}宝石 ${countNumEnergy}能量`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.modal_opt}>
        <div
          className={styles.modal_opt_cancel}
          onClick={() => {
            handleClose(true);
          }}
        ></div>
        <div
          className={styles.modal_opt_confirm}
          onClick={() => {
            confirmHandle();
          }}
        ></div>
      </div>
    </div>
  );
};

export default Exchange;
