import React, { useState, useCallback, useRef, useEffect } from 'react'
import styles from './style.module.scss'
import { Mask, Toast } from 'antd-mobile'
import { BackBtn } from '../components/button'
import ListView from "@/components/AntListView"
import dayjs from 'dayjs'
import { purchaseRecords } from '@/api/guland'
import { round } from "@/utils/publicFun"
import { showLoading, hideLoading } from '@/components/loading'

const PurchaseRecords  = ({visible, onClose}) => {
  const [recordsData, setRecordsData] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const currentPage = useRef(1);
  const pageSize = useRef(20);

  useEffect(() => {
    if (visible) {
      getRecordsList()
    }
  }, [visible])

  const getRecordsList = async (isRefresh=true, loading=true) => {
    try {
      loading && showLoading()
      if(isRefresh) {
        currentPage.current = 1;
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        pageNum: currentPage.current,
        pageSize: pageSize.current,
      }
      const res = await purchaseRecords(params)
      loading && hideLoading()
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setRecordsData(res?.data?.orderRespList || []);
      } else {
        setRecordsData([...recordsData, ...res?.data?.orderRespList]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.orderRespList.length >= pageSize.current);
    } catch (error) {
      console.log(error)
      loading && hideLoading()
      Toast.show(error)
    }
  }

  return <Mask 
    visible={visible} 
    destroyOnClose
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0, 0, 0, .8)'
    }}>
    <div className={styles.purchase_records_container}>
      <BackBtn onClick={onClose}/>
      <h1>购买记录</h1>
      <div className={styles.records_list}>
        {
          recordsData?.length > 0 ? <ListView
            dataSource={recordsData}
            hasMore={hasMore}
            style={{paddingTop: 5}}
            renderItem={RecordsItem}
            onEndReached={async () => {
              await getRecordsList(false, false);
            }}
            isLoad={isLoad}
          />: !isLoad ? null: <div className={styles.no_data}>暂无购买记录</div>
        }
      </div>
    </div>
  </Mask>
}

const RecordsItem = (item) => {
  const { outTradeNo, payTime, num, totalPrice, pointToCash, useCash } = item || {}
  return <div className={styles.records_item}>
    <div className={styles.item_top}>
      <div className={styles.order_time}>
        <span>购买时间：</span>
        <span>{payTime ? dayjs(payTime).format('YYYY-MM-DD HH:mm:ss'): ''}</span>
      </div>
      <div className={styles.order_info}>
        <span>订单编号：</span>
        <span>{outTradeNo}</span>
      </div>
    </div>
    <div className={styles.item_bottom}>
      <div>张数 <span>{num}</span></div>
      <div>总价 <span>¥{round(totalPrice, 2)}</span></div>
      <div>积分抵扣 <span>-¥{round(pointToCash, 2)}</span></div>
      <div>实付：<span>¥{round(useCash, 2)}</span></div>
    </div>
  </div>
}

export const usePurchaseRecords = () => {
  const [visible, setVisible] = useState(false)

  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  const onOpen = useCallback(() => {
    setVisible(true)
  }, [])

  const cardView = (
    <PurchaseRecords visible={visible} onClose={onClose}/>
  )

  return [onOpen, cardView]
}