import React, { useEffect, useState } from 'react';
import './index.scss';
import { hideLoading, showLoading } from "@/components/loading";
import Service from "../../service";
import { Toast } from "antd-mobile";

const ActivityRules = ({ onClose }) => {
  const [activityRules, setActivityRules] = useState({});

  useEffect(() => {
    getActivityRules();
  }, []);

  const handleClick = () => {
    onClose && onClose();
  }

  const getActivityRules = async () => {
    try {
      showLoading();
      const res = await Service.getActivityRules();
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      const {data} = res;
      if (data.result && data.result.defCode) {
        const defCode = data.result.defCode;
        setActivityRules(defCode);
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  };

  return <div className='kangyanng-rules'>
    <div className='rules-outer'>
      <div className="rules-title">{activityRules?.codeAlias ?? '活动规则'}</div>
      <div className="rules-content">
        {activityRules?.otherSign}
      </div>
      <div className="rules-btn" onClick={handleClick}>我知道了</div>
    </div>
  </div>
}

export default ActivityRules;