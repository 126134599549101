import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import receiveSuccess from '@/assets/img/receive-success.png';
import './style.scss';

const ReceiveResult = () => {
  const { state, number, name, nftImg, nftHash, collectionName } = qs.parse(window.location.search);
  const navigate = useNavigate();

  useEffect(() => {
    window.JsBridge.setTitle(`${state === 'success'? '接收成功': '接收失败'}`);
  }, []);

  const DescMap = {
    "refuse": `您已拒绝接收${name}向您赠送的数字藏品`,
    "success": '恭喜您成功获得该藏品！',
    "overtime": `${name}向您赠送了数字藏品已超过24小时未领取，该藏品已退回至原持有账户。`,
  }

  const onConfirm = () => {
    if(state === 'success') {
      navigate(`/myCollectionDetail?nft_hash=${nftHash}`, {replace: true});
    } else {
      window.JsBridge.replace("CustomWeb", { uri: `${window.location.origin}/myCollection` });
    }
  }

  return <div className='receive-result'>
    <div className="img-box">
      <img src={nftImg} alt="" />
    </div>
    <div className="title">{collectionName}</div>
    <div className="number">藏品编号：#{number}</div>
    <div className={`desc ${state === 'overtime' ? 'overtime': ''}`}>
      {state === 'success' && <img src={receiveSuccess} className="receive-success"/>}
      {DescMap[state]}
    </div>
    <div className="btn" onClick={onConfirm}>{state === 'success' ? '接收成功，查看详情': '我知道了'}</div>
  </div>
}

export default ReceiveResult;