import React, {useEffect, useState, useRef} from "react"
import "./index.scss"
import NFTHeader from "@/components/NFTHeader";
import {hideLoading, showLoading} from "@/components/loading";
import {getSessionList} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {convertToChineseNumeral, isEmptyArray, isEmptyString} from "@/utils/publicFun";

let offsetTop = 0;
const AllSession = () => {//全部场次
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const allSessionCountRef = useRef(0);//总场次
    const [height, setHeight] = useState(0);

    useEffect(() => {
        getData();
        // const dom = document.getElementsByClassName('all-session-item-box')[0];
        // dom.addEventListener('scroll', listenScroll);
        return () => {
            // dom.removeEventListener('scroll', listenScroll);
        }
    }, []);

    const listenScroll = (e) => {
        offsetTop = e.target.scrollTop;
    }

    const getData = async () => {
        try {
            showLoading()
            const res = await getSessionList({})
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let data = res.data;
                let currentTime = data.currentTime;
                let list = data.list || [];
                const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))
                allSessionCountRef.current = list.length;
                let titles = [];
                for (let i = 0; i < list.length; i++) {
                    let item = list[i];
                    let startTime = item.startTime;
                    let endTime = item.endTime;
                    if (!startTime || !endTime) {
                        continue
                    }


                    const startTimeStamp = Date.parse(startTime.replace(/-/g, '/'));
                    const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
                    let startDateTem = new Date(startTimeStamp);
                    let endDateTem = new Date(endTimeStamp);

                    let startYear = startDateTem.getFullYear();
                    let startMonth = startDateTem.getMonth() + 1;
                    let startDay = startDateTem.getDate();
                    let endMinutes = endDateTem.getMinutes();
                    endMinutes = endMinutes >= 10 ? endMinutes : '0' + endMinutes;
                    let key = `${startYear}年${startMonth}月${startDay}日`;
                    item.key = key;
                }
                list.map((item, index) => {
                    if (!isEmptyString(item.key)) {
                        if (titles.indexOf(item.key) === -1) {//不存在
                            titles.push(item.key);
                        }
                    }
                });
                let obj = [];
                for (const title of titles) {
                    obj.push({title: title, list: [], list1: []})
                }
                for (let i = 0; i < list.length; i++) {
                    let item = list[i];
                    for (let j = 0; j < obj.length; j++) {
                        let item1 = obj[j];
                        if (item.key === item1.title) {
                            item1.list.push(item);
                        }
                    }
                }

                for (let i = 0; i < obj.length; i++) {
                    let item = obj[i];
                    let list = obj[i].list;
                    let objTem = {};
                    list.forEach((item, index) => {
                        let session = item.session;
                        if (objTem[session]) {
                            objTem[session].push(item)
                        } else { //不存在
                            objTem[session] = [item];
                        }
                    })
                    for (const index in objTem) {
                        let item2;
                        let listTem = objTem[index];
                        if (!isEmptyArray(listTem)) {
                            let item0 = listTem[0];
                            let item1 = listTem[listTem.length - 1];
                            item2 = item0;
                            item2.endTime = item1.endTime;
                        }
                        if (item2) {
                            let startTime = item2.startTime;
                            let endTime = item2.endTime;
                            if (!startTime || !endTime) {
                                continue
                            }

                            const startTimeStamp = Date.parse(startTime.replace(/-/g, '/'));
                            const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
                            let startDateTem = new Date(startTimeStamp);
                            let endDateTem = new Date(endTimeStamp);


                            let startHours = startDateTem.getHours();
                            let startMinutes = startDateTem.getMinutes();
                            startMinutes = startMinutes >= 10 ? startMinutes : '0' + startMinutes;
                            let endHours = endDateTem.getHours();
                            let endMinutes = endDateTem.getMinutes();
                            endMinutes = endMinutes >= 10 ? endMinutes : '0' + endMinutes;
                            item2.time = `${startHours}:${startMinutes}-${endHours}:${endMinutes}`;


                            let status = 0;//0=未开始 1=进行中 2=已结束
                            let statusString = '(未开始)';//0=未开始 1=进行中 2=已结束
                            if (currentTimeStamp > endTimeStamp) { //已结束
                                status = 2;
                                statusString = '(已结束)';
                            } else if (currentTimeStamp < endTimeStamp && currentTimeStamp > startTimeStamp) { //进行中
                                status = 1;
                                statusString = '(进行中)';
                            } else {
                                status = 0;
                                statusString = '(未开始)';
                            }
                            item2.status = status;
                            item2.statusString = statusString;
                            // item2.session = 20;
                            let sessionStringTem = convertToChineseNumeral(item2.session);
                            item2.sessionString = `第${sessionStringTem}场`;
                            item.list1.push(item2)
                        }
                    }
                }
                // console.log(obj)
                setData(obj);
                // const dom = document.getElementsByClassName('all-session-item-box')[0];
                // dom && dom.scrollTo(0, Number(offsetTop));

            }
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }


    const itemClick = (item) => {
        item.allSession = allSessionCountRef.current;
        navigate('/guessingActy/sessionDetail', {state: item});
    }


    const childComponents = (item1) => {
        if (!isEmptyArray(item1.list1)) { //let status = 0;//0=未开始 1=进行中 2=已结束
            return item1.list1.map((item, index) => {
                    let bgColor = '#EEEEEE';
                    let txtColor = '#767676';
                    if (item.status === 0) {
                        bgColor = '#fff';
                        txtColor = '#2E63F6';
                    } else if (item.status === 1) {
                        bgColor = 'linear-gradient(119deg, #38A7FF -18%, #2B61F5 81%)';
                        txtColor = '#fff';
                    } else if (item.status === 2) {
                        bgColor = '#EEEEEE';
                        txtColor = '#767676';
                    }


                    return (
                        <div className={`all-session-item`} key={index.toString()}
                             style={{background: bgColor}} onClick={() => itemClick(item)}>
                            <p style={{color: txtColor}}>{item.sessionString}</p>
                            <p style={{color: txtColor}}>{item.time}</p>
                            <p style={{color: txtColor}}> {item.statusString}</p>
                        </div>
                    )
                }
            )
        }
    }

    const items = () => {
        return data.map((item, index) => {
            return (
                <div key={index.toString()}>
                    <p className={'all-session-item-title'}>{item.title}</p>
                    <div className={'all-session-item-container'}>
                        {childComponents(item)}
                    </div>
                </div>
            )
        });
    }


    return (
        <div className="all-session-container">
            <NFTHeader midText={'全部场次'} bgColor={'#fff'} leftClick={() => {
                navigate(-1);
                offsetTop = 0;
            }} getHeight={(h) => {
                setHeight(h);
            }}/>
            <div style={{flex: 1, overflow: 'scroll', marginTop: height}} className={'all-session-item-box'}>
                {items()}
            </div>
        </div>
    )
}

export default AllSession
