import React from 'react';
import { Toast } from 'antd-mobile';
let toast = null;

const isInApp = window.JsBridge.hasWebViewBridge();

export const showLoading = () => {
  if (isInApp) {
    window.JsBridge.runAction('loading', 'start', [false]);
  } else {
    toast = Toast.show({ icon: 'loading', content: '加载中...', duration: 0, maskClickable: false })
  }
}

export const hideLoading = () => {
  if (isInApp) {
    window.JsBridge.runAction('loading', 'end', [false]);
  } else {
    toast && toast.close();
  }
}