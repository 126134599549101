import {useEffect, useRef, useState} from "react";
import './pwdSetting.scss'
import {Input, Toast} from 'antd-mobile'
import {isEmptyString} from "@/utils/publicFun";
import clickThrottle from "@/utils/clickThrottle";
import {hideLoading, showLoading} from "@/components/loading";
import JsBridgeNew from "@/utils/jsbridgeNew";
import {checkUserPassword, settingPassword} from "@/api/projectManager_self";

export const PwdResert = () => { //重置密码

    const [eye0, setEye0] = useState(false);
    const [canClickBtn, setCanClickBtn] = useState(false);
    const pwd = useRef('');

    useEffect(() => {
        window.JsBridge.setTitle('重置密码')
    }, [])

    const eyeOpenOrClose = (type) => {
        if (type === 0) {
            setEye0(!eye0)
        }
    }

    const onChangeText = (text, type) => {
        if (type === 0) {
            pwd.current = text;
        }
        if (!isEmptyString(pwd.current) && pwd.current.length === 6) {
            setCanClickBtn(true)
        } else {
            setCanClickBtn(false)
        }
    }

    const wrapString = (path) => {
        const prefix = path.startsWith('content://') ? 'RNFetchBlob-content://' : 'RNFetchBlob-file://';
        return prefix + path
    };

    const inVivoIdentification = async (livenessResult) => { //活体识别
        let realName = ''
        let userInfo0 = await window.JsBridge.getCurrentUserInfo();
        if (userInfo0 && userInfo0.clientId) { //登录了
            let userInfo1 = await window.JsBridge.getDetailCurrentUserInfo();
            if (!isEmptyString(userInfo1.ID)) { //实名过了
                realName = userInfo1.realName;
            }
        }

        let param = {
            file: {
                name: 'motionLivenessResult',
                filename: 'motionLivenessResult',
                data: wrapString(livenessResult.livenessFile.replace('file:///', '')),
                type: 'multipart/form-data'
            },
            supplier: '1',
            name: realName,
        };
        JsBridgeNew.jKTServicesRequest({
            moduleName: 'UserLogin',
            code: '101162',
            method: 'postFile',
            params: param
        }).then((res) => {
            if (res.status > 0) {
                window.JsBridge.replace("Web", {uri: `${window.location.origin}/pwdSetting?type=${1}`});
            } else {
                // Toast.show('认证失败')
            }
        })
    }

    const forgetPwd = async () => {
        if (!clickThrottle()) return;
        const res = await window.JsBridge.startLiveness('sense');
        if (res.livenessId !== '-1') {
            inVivoIdentification(res)
        } else {
            window.JsBridge.runAction('alert', 'error', [res.msg]);
        }
    }

    const done = async () => {
        if (!canClickBtn) {
            return;
        }
        if (!clickThrottle()) return;
        try {
            showLoading();
            const res = await checkUserPassword({tradePasswd: pwd.current});
            hideLoading();
            const {code, msg} = res;
            if (code !== '000000') throw msg;
            window.JsBridge.replace("Web", {uri: `${window.location.origin}/pwdSetting?type=${1}`});
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    return (
        <div className={'pwd_setting_container'}>
            <p className={'pwd_des'} style={{lineHeight: '20px', marginTop: '20px'}}>请先进行身份验证</p>
            <div className={'pwd_setting_content'} style={{minHeight: '30px', marginTop: '10px'}}>
                <div className={'item_0'}>
                    <Input placeholder='请输入旧密码' clearable maxLength={6} type={eye0 ? 'text' : 'password'}
                           onChange={(e) => onChangeText(e, 0)}
                           style={{
                               '--color': '#000000',
                               '--font-size': '14px',
                               paddingLeft: '10px',
                               marginRight: '6px'
                           }}/>
                    <img src={eye0 ? require('@/assets/img/icon_eye_on.png') : require('@/assets/img/icon_eye_off.png')}
                         alt="" onClick={() => eyeOpenOrClose(0)}/>
                </div>
            </div>
            <div className={'forget_pwd'}>
                <p style={{width: '80px'}}
                   onClick={() => forgetPwd()}>忘记密码?</p>
            </div>
            <div className={`done_btn  ${canClickBtn ? 'btn_opacity' : ''}`} onClick={() => done()}>确定</div>
        </div>
    )
}
