import GuessingActyHome from './home';
import GetCoupon from './getCoupon'
import GuessingActyRule from './rule';
import GuessingExchangeRecord from './exchangeRecord';
import GuessingExchangeStore from './exchangeStore';
import GuessingExchangeOrder from './exchangeOrder';
import GuessingExchangeOrderDetails from './exchangeOrderDetails';
import GuessingEnshrineDetails from './enshrine';
import SessionDetail from './sessionDetail'
import AllSession from './allSession'
import OrderConfirmVirtual from './exchangeStore/component/orderConfirmVirtual';
import OrderConfirmReal from './exchangeStore/component/orderConfirmReal';
import GuessingExchangeRealOrderDetails from './exchangeRealOrderDetails'; //实物订单详情


export default {
    GuessingActyHome,
    GetCoupon,
    GuessingActyRule,
    GuessingExchangeRecord,
    GuessingExchangeStore,
    GuessingExchangeOrder,
    GuessingExchangeOrderDetails,
    GuessingEnshrineDetails,
    SessionDetail,
    AllSession,
    OrderConfirmVirtual,
    OrderConfirmReal,
    GuessingExchangeRealOrderDetails,
}
