import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import ListView from "@/components/AntListView";
import {hideLoading, showLoading} from "@/components/loading";
import {getCouponList,} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";
import CouponItem from "@/view/myCoupon/CouponItem";
import NoticeUtil from "@/utils/NoticeUtil";
import {guess_juanbao_count} from "@/constant";


const CouponList = (props) => {
    const [dataList, setDataList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const currentPage = useRef(1);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            showLoading()
            const res = await getCouponList({currentPage: currentPage.current, pageSize: 10, type: props.type})
            hideLoading();
            setIsLoad(true);
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let dataTem = res.data;
                let record = dataTem.records || [];
                let total = dataTem.total || 0;
                if (currentPage.current > 1) {
                    setDataList([...dataList, ...record]);
                } else {
                    setDataList(record);
                    NoticeUtil.emit(guess_juanbao_count, {type: props.type, total: total})
                }
                setHasMore(record.length >= 10);
            }
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const loadMore = async () => {
        currentPage.current = currentPage.current + 1
        await loadData()
    }

    const renderItem = (item, index) => {
        return (
            <CouponItem
                key={index.toString()}
                name={item.couponName}
                amount={item.discount}
                type={props.type}
                // isExpired={item.couponType === 2}
                // isUsed={item.couponType === 1}
            />
        )
    }

    return (
        <div className={styles.epic_boring_ape_container}>
            <ListView
                dataSource={dataList}
                renderItem={renderItem}
                onRefresh={() => {
                    currentPage.current = 1;
                    loadData()
                }}
                hasMore={hasMore}
                onEndReached={loadMore}
                style={{paddingTop: 50}}
                isLoad={isLoad}
            />
        </div>
    )
}


export default CouponList;
