import {Button, Checkbox, Radio, Toast, Stepper} from "antd-mobile"
import React, {useEffect, useState} from "react"
import Error from "@/view/Error/index"
import "./index.scss"
import {
    getPrepayDetail,
    createOrder,
    queryPayResult,
    checkNotpay,
    getUserInfo,
    getPayParams, iapValidatePayload, getShopPrepayDetail, shopConfirmOrder, getProductList,
} from '@/api/projectManager_self';
import {useNavigate, useLocation,} from "react-router-dom"
import {round, throttle, getUrlAllParams, computeNumber, isArray} from "@/utils/publicFun";
import {showLoading, hideLoading} from '@/components/loading';
import NFTHeader from "@/components/NFTHeader";
import ArrayUtils from "@/utils/ArrayUtils";
import qs from "query-string";
import JsBridgeNew from "@/utils/jsbridgeNew";

const wxpayString = 'wxpay';
const alipayString = 'alipay';
const iosIAPString = 'iosIAP';

export default function ShopCartSalePay() {
    const navigate = useNavigate()


    // const {state = {shopParams: {}, title: ""}} = useLocation();

    const [saleInfo, setSaleInfo] = useState({totalPrice: 0, collectionName: "藏品"})
    const [point, setPoint] = useState(0)
    const [deductionPrice, setDeductionPrice] = useState(0)
    const [count, setCount] = useState(1)
    // 最大购买数量
    // const [maxCount, setMaxCount] = useState(1)
    const [deduction, setDeduction] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [payWay, setPayWay] = useState("1")
    const [errorContext, setErrorContext] = useState("")
    const [verifyRealName, setVerifyRealName] = useState(true)
    const [notPay, setNotPay] = useState(false)
    const [installedWX, setInstalledWX] = useState(true)
    const [winningLotsDeduction, setWinningLotsDeduction] = useState(0);
    const [userInfo, setUserInfo] = useState({})
    const [payConfig, setPayConfig] = useState({ //支付方式
        wxpay: false,
        alipay: false,
        iosIAP: false,
        point: false,
    })
    const bridge = window.JsBridge;
    const {search} = useLocation()
    let {} = getUrlAllParams(search.slice(1));
    // bridge.setTitle("订单支付")

    const queryUserInfo = async () => {
        const {code, msg, data} = await getUserInfo();
        if (code !== '000000') throw msg;
        setUserInfo(data)
        return data;
    }

    const getPayDetail = async () => {
        let shopParamsString = window.localStorage.getItem('shop_cart_info');
        if (shopParamsString) {
            window.localStorage.setItem("shop_cart_info", '');
            let shopParams = JSON.parse(shopParamsString);
            const {code, msg, data} = await getShopPrepayDetail(shopParams);
            if (code !== '000000') throw msg;
            return data;
        } else {
            return {};
        }
    }

    const checkIsHasNotPay = async (collectionType) => { //有无待支付订单
        const {code, msg, data} = await checkNotpay({});
        if (code !== '000000') throw msg;
        if (data?.notPayOrderCnt) {
            Toast.show("您有待付款的订单，请先完成上次订单")
            setNotPay(true)
        }
    }

    const pageInit = async () => {
        try {
            showLoading();
            bridge.nativeModules("UMShareModule", "isInstalledWx").then(function (res) {
                setInstalledWX(res)
            })
            const [userInfo, payDetail] = await Promise.all([queryUserInfo(), getPayDetail()]);
            await checkIsHasNotPay(payDetail?.collectionType);
            hideLoading();
            setPoint(userInfo.point || 0);
            const {totalPrice = 0, pointRatio = 0, purchaseNum} = payDetail;
            const deductionPrice = computeNumber(totalPrice, "*", pointRatio).result >= userInfo.point ? computeNumber(userInfo.point, "/", pointRatio).result : totalPrice;
            setSaleInfo(payDetail);
            setTotalPrice(computeNumber(totalPrice, "*", 1).result);
            setDeductionPrice(computeNumber(deductionPrice, "*", 1).result);
        } catch (error) {
            hideLoading();
            if(process.env.NODE_ENV !== 'development'){
                setErrorContext(error);
            }
            Toast.show(error);
        }
    }

    const getPayConfig = () => {  //获取支付配置 iosIAP weixinPay  aliPay
        bridge.getStorage({key: 'tabConfig'}).then((res) => {
            setPayConfig({
                wxpay: res?.result?.wxpay || false,
                alipay: res?.result?.alipay || false,
                iosIAP: res?.result?.iosIAP || false,
                point: res?.result?.pointDeduct || false,
            })
            if (res?.result?.iosIAP) { //打开了内购按钮
                getProductList({}).then((result) => {
                    if (result.code === '000000') {
                        if (result.data && isArray(result.data) && result.data.length > 0) {
                            JsBridgeNew.iapProList(JSON.stringify(result.data));
                        }else {
                            Toast.show('获取苹果支付商品失败');
                        }
                    } else {
                        Toast.show(result?.msg);
                    }
                });
            }
        })
    }

    useEffect(() => {
        window.scroll(0, 0)
        // if (!collectionId) {
        //     Toast.show("参数不合法");
        //     setErrorContext("参数不合法");
        // } else {
        if (bridge.hasWebViewBridge()) {
            bridge.verifyRealName("请先实名认证").then(function (res) {
                setVerifyRealName(!!res?.result);
                // alert(JSON.stringify(res))
            })
        }
        pageInit();
        getPayConfig();
        // }
    }, [])

    useEffect(() => {
        let allNeedPrice = computeNumber(saleInfo.totalPrice, "*", count).result;
        setWinningLotsDeduction(computeNumber(100, "*", count).result);
        if (deduction) {
            let allNeedPoint = computeNumber(allNeedPrice, "*", saleInfo.pointRatio || 100).result

            let deductionPrice2 = allNeedPoint >= point ? computeNumber(point, "/", saleInfo.pointRatio || 100).result : allNeedPrice
            setDeductionPrice(computeNumber(deductionPrice2, "*", 1).result)
            setTotalPrice(computeNumber(allNeedPrice, "-", deductionPrice2).result)
        } else {
            setTotalPrice(allNeedPrice)
        }
    }, [count, deduction])

    const gotoSalePaySuccess = () => {
        navigate(`/shopPaySuccess`, {replace: true})
    }

    const gotoOrderList = () => {
        // navigate(`/myGroupOrder`, {replace: true})
        bridge.push("Web", {uri: `${window.location.origin}/myGroupOrder`})
    }

    const pay = async (params) => {
        try {
            showLoading();
            const orderInfo = await shopConfirmOrder(params);
            if (orderInfo.code !== '000000') throw orderInfo.msg;
            if (totalPrice === 0) {
                hideLoading();
                return orderInfo.data;
            }
            const paramsInfo = await getPayParams({outTradeNo: orderInfo?.data?.outTradeNo || ''});
            hideLoading();
            if (paramsInfo.code !== '000000') throw paramsInfo.msg;
            return {...paramsInfo.data, ...orderInfo.data};
        } catch (error) {
            hideLoading();
            Toast.show(error);
            return false;
        }
    }

    const getPayResult = (params) => {
        try {
            showLoading();
            let timeId = setTimeout(async () => {
                const {code, msg, data} = await queryPayResult(params);
                hideLoading();
                if (code === '000000') {
                    gotoSalePaySuccess(data);
                    // if (data) {
                    //     gotoSalePaySuccess(data);
                    // } else {
                    //     Toast.show('已支付，请到“我的-我的藏品”查看');
                    // }
                } else {
                    Toast.show(msg);
                }
                timeId && clearTimeout(timeId);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    }

    const getIapPayResult = (params) => { //获取内购的验证结果
        try {
            showLoading();
            let timeId = setTimeout(async () => {
                const {code, msg, data} = await iapValidatePayload(params);
                hideLoading();
                if (code === '000000') {
                    gotoSalePaySuccess(data);
                    // if (data) {
                    //     gotoSalePaySuccess(data);
                    // } else {
                    //     getPayResult({outTradeNo: params?.outTradeNo});
                    // }
                } else {
                    Toast.show(msg);
                }
                timeId && clearTimeout(timeId);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    }

    const mobilePayment = async () => { //支付
        if (notPay) {
            Toast.show("您有待付款的订单，请先完成上次订单")
            return false
        }
        let payTypeTem = ''
        if (totalPrice === 0) {
            payTypeTem = 'POINT'
        } else {
            if (payWay === wxpayString) {
                payTypeTem = 'WXPAY'
            } else if (payWay === alipayString) {
                payTypeTem = 'ALIPAY'
            } else if (payWay === iosIAPString) {
                payTypeTem = 'IOS_IAP'
            }
        }


        if (!payTypeTem) {
            Toast.show('请选择支付方式!')
            return
        }

        let iOrderDTOList = [];
        if (ArrayUtils.isArray(saleInfo.pendingListVOS)) {
            let pendingListVOS = saleInfo.pendingListVOS
            for (let i = 0; i < pendingListVOS.length; i++) {
                let item = pendingListVOS[i];
                let item0 = {};
                item0.saleBatchId = item.saleBatchInfo?.saleBatchId || '';
                item0.collectionId = item.id;
                item0.num = item.purchaseNum;
                iOrderDTOList.push(item0);
            }
        }

        let params = {
            clientId: userInfo?.clientId || '',
            totalNum: saleInfo?.totalNum || '',
            totalUsePoint: deduction ? computeNumber(deductionPrice, "*", saleInfo.pointRatio || 100).result : 0,
            totalUseCash: totalPrice,
            payType: payTypeTem,
            source: "app",
            uid: saleInfo?.defRepeatStr || '',
            iOrderDTOList: iOrderDTOList,
            type: '0',
        }

        if (bridge.hasWebViewBridge()) {
            if (!verifyRealName) {
                Toast.show("请先实名认证")
                return false
            }
            if (payWay === wxpayString) {
                if (!installedWX) {
                    Toast.show("请先安装微信")
                    return false
                }
            }

            pay(params).then((res) => {
                // NOTE: 如果是支付金额为0，全部使用积分抵扣，则不继续调用Jsbridge方法
                if (totalPrice === 0 && res) {
                    getPayResult({outTradeNo: res.outTradeNo});
                } else {
                    if (!res) return;
                    if (payWay === wxpayString) {
                        bridge
                            .wxPay({
                                partnerId: res.partnerId || '',
                                prepayId: res.prepayId || '',
                                nonceStr: res.nonceStr || '',
                                timeStamp: res.timeStamp || '',
                                sign: res.sign || '',
                                appId: res.appId,
                            })
                            .then(function (result) {
                                // 支付失败
                                if (result.errCode === -2) {
                                    Toast.show("支付取消")
                                    gotoOrderList()
                                } else if (result.errCode === 0) {
                                    // 支付回调
                                    let payParams = {
                                        errStr: result?.errStr,
                                        errCode: result?.errCode,
                                        outTradeNo: res.outTradeNo,
                                        source: "wxpay",
                                    }
                                    getPayResult(payParams);
                                } else {
                                    Toast.show("支付发生错误  code: " + (result?.errCode || " "))
                                }
                            })
                    } else if (payWay === alipayString) {
                        // 支付宝支付
                        const payResult = res.payResult
                        bridge.aliPay(payResult).then(function (result) {
                            // 支付失败
                            if (result.resultStatus == 6001) {
                                Toast.show("支付取消")
                                gotoOrderList()
                            } else if (result.resultStatus == 62000) {
                                Toast.show("客户端未安装支付通道依赖的服务")
                            } else if (result.resultStatus == 9000) {
                                // 支付回调
                                let resFmt = JSON.parse(result.result || "{}")
                                let payParams = {
                                    errStr: resFmt?.alipay_trade_app_pay_response?.msg || "",
                                    errCode: resFmt?.alipay_trade_app_pay_response?.code || "",
                                    outTradeNo: res.outTradeNo || "",
                                    source: "alipay",
                                }
                                getPayResult(payParams);
                            } else {
                                Toast.show("支付发生错误  code: " + (result?.resultStatus || " "))
                            }
                        })
                    } else if (payWay === iosIAPString) { //内购
                        let productInfo = res?.productInfo;
                        if (productInfo) {
                            productInfo.outTradeNo = res.outTradeNo;
                            // productInfo.productId ='com.gktapp.chuanshuokuan'
                            let payDeadline = res?.payDeadline || '5分钟';
                            if (payDeadline) {
                                Toast.show(`请在${payDeadline}时间内完成支付`)
                                let timer = setTimeout(() => {
                                    clearTimeout(timer)
                                    let productId = JSON.stringify(productInfo);
                                    bridge
                                        .iapPay(productId).then(function (result) {
                                        if (result?.code === '200') { //成功
                                            let receiptDataObj = JSON.parse(result.receiptData);
                                            if (receiptDataObj) {
                                                getIapPayResult(receiptDataObj);
                                            } else {
                                                Toast.show("获取苹果参数失败");
                                            }
                                        } else { //支付失败
                                            Toast.show("支付失败  code: " + (result?.code || " "))
                                        }
                                    })

                                }, 2000)
                            }
                        }
                    }
                }
            })
        } else {
            Toast.show("请到APP中支付")
        }
    }

    const choicePayType = (type) => {
        if (type === iosIAPString) {
            setDeduction(false)
        }
        setPayWay(type)
    }

    const throttlePayment = throttle(mobilePayment, 3000);


    const itemViews = (array) => {
        if (!ArrayUtils.isArray(array)) {
            return
        }

        return array.map((item, index) => {
            return (
                <div className="nft_sale" key={index.toString()}>
                    <div className="nft_sale_top">
                        <div className="nft_sale_display">
                            <img src={item?.imageBg} alt=""/>
                        </div>
                        <div className="nft_sale_content">
                            <p>{item?.collectionName}</p>
                            <div className="nft_sale_price">
                                <span>¥{item?.price && round(item?.showPrice, 2)}</span>
                                <div className="purch_num">x{item.purchaseNum}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="orderpay">
            <NFTHeader midText="订单支付" bgColor="white"/>
            {!errorContext ? (
                <>
                    {itemViews(saleInfo.pendingListVOS)}
                    <div className={`nft_saleprice ${deduction ? 'bottom-style' : ''}`}>
                        <div className="nft_spacebt flex">
                            <span>商品金额</span>
                            <div
                                className="nft_spacebt_right">¥{saleInfo?.totalPrice && round(saleInfo?.totalShowPrice * count, 2)}</div>
                        </div>
                        <br/>

                        <>
                            {(payWay !== iosIAPString && payConfig.point) && <div>
                                <div className="nft_spacebt">
                                    <div className="nft_spacebt_right">
                                        <span>积分抵扣</span>
                                        <div className="right">
                                            <Checkbox
                                                defaultChecked={deduction}
                                                onChange={(checked) => setDeduction(checked)}
                                                style={{
                                                    "--icon-size": "18px",
                                                    "--font-size": "14px",
                                                    "--gap": "6px",
                                                }}
                                            ></Checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div className="poinsintroduce">
                                    我的积分: {point} ({saleInfo?.pointRatio || 100}积分可抵扣1元)
                                </div>
                            </div>}
                        </>
                        <div className="nft_pricetotal">
                            <div className="nft_total">总计: &nbsp;</div>
                            <div className="middle">
                                {deduction && (
                                    <div className={`nft_deduction 'point_top'`}>
                                        积分抵扣: {round(deductionPrice * (saleInfo?.pointRatio || 100), 0)}(-￥{round(deductionPrice, 2)}) &nbsp;
                                    </div>
                                )}
                            </div>
                            <div className="nft_price">¥{round(totalPrice, 2)}</div>
                        </div>
                    </div>
                    <div className="nft_sale">
                        <div className="nft_spacebt">
                            <div className="pay_way">选择支付方式</div>
                            <ul className="pay-type">
                                <Radio.Group value={payWay} onChange={(val) => choicePayType(val)}>
                                    {payConfig.wxpay && <li>
                                        <div className="apy_way_info">
                                            <img src={require("@/assets/img/orderpay/wx.png")} alt=""/>
                                            <div className="type">微信</div>
                                        </div>
                                        <Radio value={wxpayString} style={{"--icon-size": "18px"}}></Radio>
                                    </li>}
                                    {payConfig.alipay && <li>
                                        <div className="apy_way_info">
                                            <img src={require("@/assets/img/orderpay/zfb.png")} alt=""/>
                                            <div className="type">支付宝</div>
                                        </div>
                                        <Radio value={alipayString} style={{"--icon-size": "18px"}}></Radio>
                                    </li>}
                                    {payConfig.iosIAP && <li>
                                        <div className="apy_way_info">
                                            <img src={require("@/assets/img/orderpay/apple_pay.png")} alt=""/>
                                            <div className="type">苹果支付</div>
                                        </div>
                                        <Radio value={iosIAPString} style={{"--icon-size": "18px"}}></Radio>
                                    </li>}
                                </Radio.Group>
                            </ul>
                        </div>
                    </div>
                    <div className="orderpay_buttom">
                        <div className="left">
                            <span className="total">总计:</span>
                            <div className="price-detail">
                                <div className="price">¥{round(totalPrice, 2)}</div>
                            </div>
                        </div>
                        <div className="pay">
                            <Button onClick={throttlePayment}>立即支付</Button>
                        </div>
                    </div>
                </>
            ) : (
                <Error context={errorContext}/>
            )}
        </div>
    )
}
