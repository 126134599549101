import { Button, Checkbox, Radio, Toast, Stepper } from "antd-mobile"
import React, { useEffect, useState } from "react"
import Error from "@/view/Error/index"
import "./index.scss"
import { getMysteryNum } from "@/api/projectManager";
import { 
  getPrepayDetail, 
  createOrder, 
  queryPayResult, 
  checkNotpay, 
  getUserInfo, 
  getPayParams, 
  exchangeWic
} from '@/api/projectManager_self';
import { useNavigate, useLocation, use } from "react-router-dom"
import { round, throttle, getUrlAllParams, computeNumber } from "@/utils/publicFun";
import { showLoading, hideLoading } from '@/components/loading';

export default function SalePay() {
  const navigate = useNavigate()
  const [saleInfo, setSaleInfo] = useState({ price: 0, collectionName: "藏品" })
  const [point, setPoint] = useState(0)
  const [deductionPrice, setDeductionPrice] = useState(0)
  const [count, setCount] = useState(1)
  // 最大购买数量
  const [maxCount, setMaxCount] = useState(1)
  const [deduction, setDeduction] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)
  const [payWay, setPayWay] = useState("1")
  const [errorContext, setErrorContext] = useState("")
  const [verifyRealName, setVerifyRealName] = useState(true)
  const [notPay, setNotPay] = useState(false)
  const [installedWX, setInstalledWX] = useState(true)
  const bridge = window.JsBridge

  const { search } = useLocation()
  let { collection_id = '', classify = "", code: cardNo = '', saleBatchId = ''  } = getUrlAllParams(search.slice(1))

  bridge.setTitle("订单支付")

  const queryUserInfo = async () => {
    const { code, msg, data } = await getUserInfo();
    if(code !== '000000') throw msg;
    return data;
  }

  const getPayDetail = async () => {
    const params = { collectionId: collection_id, saleBatchId };
    const { code, msg, data } = await getPrepayDetail(params);
    if(code !== '000000') throw msg;
    return data;
  }

  const checkIsHasNotPay = async (collectionType) => {
    const { code, msg, data } = await checkNotpay({collectionType: collectionType || ''});
    if(code !== '000000') throw msg;
    if (data?.notPayOrderCnt) {
      Toast.show("您有待付款的订单，请先完成上次订单")
      setNotPay(true)
    }
  }

  const pageInit = async () => {
    try {
      showLoading();
      bridge.nativeModules("UMShareModule", "isInstalledWx").then(function (res) {
        setInstalledWX(res)
      })
      const [userInfo, payDetail] = await Promise.all([queryUserInfo(), getPayDetail()]);
      await checkIsHasNotPay(payDetail?.collectionType);
      hideLoading();
      setPoint(userInfo.point || 0);
      const { collectionType, price = 0, pointRatio = 0, saleBatchInfo } = payDetail;
      const deductionPrice = computeNumber(price, "*", pointRatio).result >= userInfo.point ? computeNumber(userInfo.point, "/", pointRatio).result : price;
      setSaleInfo(payDetail);
      setTotalPrice(computeNumber(price, "*", 1).result);
      setDeductionPrice(computeNumber(deductionPrice, "*", 1).result);
      // 盲盒购买，获取购买次数
      if (collectionType === 4) {
        getMysteryNum().then((res2) => setMaxCount(res2.blindBuyNum));
      } else {
        let maxCount = saleBatchInfo?.stock - saleBatchInfo?.soldDisplay;
        setMaxCount(maxCount >= 5 ? 5 : maxCount || 0);
      }
    } catch (error) {
      hideLoading();
      setErrorContext(error);
      Toast.show(error);
    }
  }

  useEffect(() => {
    window.scroll(0, 0)
    if (!collection_id) {
      Toast.show("参数不合法")
      if(process.env.NODE_ENV !== 'development') {
        setErrorContext("参数不合法")
      }
    } else {
      if (bridge.hasWebViewBridge()) {
        bridge.verifyRealName("请先实名认证").then(function (res) {
          setVerifyRealName(!!res?.result)
        })
      }
      pageInit();
    }
  }, [])

  useEffect(() => {
    let allNeedPrice = computeNumber(saleInfo.price, "*", count).result
    if (deduction) {
      let allNeedPoint = computeNumber(allNeedPrice, "*", saleInfo.pointRatio).result

      let deductionPrice2 = allNeedPoint >= point ? computeNumber(point, "/", saleInfo.pointRatio).result : allNeedPrice
      setDeductionPrice(computeNumber(deductionPrice2, "*", 1).result)
      setTotalPrice(computeNumber(allNeedPrice, "-", deductionPrice2).result)
    } else {
      setTotalPrice(allNeedPrice)
    }
  }, [count, deduction])

  const gotoSalePaySuccess = (list) => {
    navigate(`/ape/PaySuccess`, { state: { collectionList: list || [] }, replace: true })
  }

  const gotoOrderList = () => {
    navigate(`/myGroupOrder`, { replace: true })
  }

  const pay = async (params) => {
    try {
      showLoading();
      const orderInfo = await createOrder(params);
      if(orderInfo.code !== '000000') throw orderInfo.msg;
      if(totalPrice === 0) {
        hideLoading();
        return orderInfo.data;
      }
      const paramsInfo = await getPayParams({outTradeNo: orderInfo?.data?.outTradeNo || ''});
      hideLoading();
      if(paramsInfo.code !== '000000') throw paramsInfo.msg;
      return {...paramsInfo.data, ...orderInfo.data};
    } catch (error) {
      hideLoading();
      Toast.show(error);
      return false;
    }
  }

  const getPayResult = (params) => {
    try {
      showLoading();
      let timeId = setTimeout(async () => {
        const { code, msg, data } = await queryPayResult(params);
        hideLoading();
        if(code === '000000') {
          if(data) {
            gotoSalePaySuccess(data);
          } else {
            Toast.show('已支付，请到“我的-我的藏品”查看');
          }
        } else {
          Toast.show(msg);
        }
        timeId && clearTimeout(timeId);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  }

  const receive = async () => {
    try {
      showLoading();
      const { code, msg, data } = await exchangeWic({cardNo});
      hideLoading();
      if(code !== '000000') throw msg;
      gotoSalePaySuccess(data);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const mobilePayment = async () => {
    const startTime = new Date(saleInfo?.saleBatchInfo?.saleStartTime?.replace(/-/g, "/")).getTime()
    const endTime = new Date(saleInfo?.saleBatchInfo?.saleEndTime?.replace(/-/g, "/")).getTime()
    const nowTime = new Date().getTime()

    if (nowTime > endTime) {
      Toast.show("已超过购买时间")
      return false
    } else if (nowTime < startTime) {
      Toast.show("未到购买时间")
      return false
    }

    // 盲盒购买且无购买次数
    if (saleInfo.collectionType === 4 && maxCount <= 0) {
      Toast.show("无盲盒购买次数")
      return false
    }
    if (notPay) {
      Toast.show("您有待付款的订单，请先完成上次订单")
      return false
    }

    let params = {
      saleBatchId: saleInfo?.saleBatchInfo?.saleBatchId || '',
      collectionId: collection_id || '',
      usePoint: deduction ? computeNumber(deductionPrice, "*", saleInfo.pointRatio).result : 0,
      useCash: totalPrice,
      payType: payWay === "1" ? "WXPAY" : "ALIPAY",
      source: "app",
      num: count,
      uid: saleInfo.defRepeatStr
    }
    if (bridge.hasWebViewBridge()) {
      if (!verifyRealName) {
        Toast.show("请先实名认证")
        return false
      }
      if (payWay === "1") {
        if (!installedWX) {
          Toast.show("请先安装微信")
          return false
        }
      }

      pay(params).then((res) => {
        // NOTE: 如果是支付金额为0，全部使用积分抵扣，则不继续调用Jsbridge方法
        if (totalPrice === 0 && res) {
          getPayResult({outTradeNo: res.outTradeNo});
        } else {
          if(!res) return;
          if (payWay === "1") {
            bridge
              .wxPay({
                partnerId: res.partnerId || '',
                prepayId: res.prepayId || '',
                nonceStr: res.nonceStr || '',
                timeStamp: res.timeStamp || '',
                sign: res.sign || '',
                appId: res.appId,
              })
              .then(function (result) {
                // 支付失败
                if (result.errCode === -2) {
                  Toast.show("支付取消")
                  gotoOrderList()
                } else if (result.errCode === 0) {
                  // 支付回调
                  let payParams = {
                    errStr: result?.errStr,
                    errCode: result?.errCode,
                    outTradeNo: res.outTradeNo,
                    source: "wxpay",
                  }
                  getPayResult(payParams);
                } else {
                  Toast.show("支付发生错误  code: " + (result?.errCode || " "))
                }
              })
          } else {
            // 支付宝支付
            const payResult = res.payResult
            bridge.aliPay(payResult).then(function (result) {
              // 支付失败
              if (result.resultStatus == 6001) {
                Toast.show("支付取消")
                gotoOrderList()
              } else if (result.resultStatus == 62000) {
                Toast.show("客户端未安装支付通道依赖的服务")
              } else if (result.resultStatus == 9000) {
                // 支付回调
                let resFmt = JSON.parse(result.result || "{}")
                let payParams = {
                  errStr: resFmt?.alipay_trade_app_pay_response?.msg || "",
                  errCode: resFmt?.alipay_trade_app_pay_response?.code || "",
                  outTradeNo: res.outTradeNo || "",
                  source: "alipay",
                }
                getPayResult(payParams);
              } else {
                Toast.show("支付发生错误  code: " + (result?.resultStatus || " "))
              }
            })
          }
        }
      })
    } else {
      Toast.show("请到APP中支付")
    }
  }

  const throttlePayment = throttle(mobilePayment, 3000);
  const throttleReceive = throttle(receive, 3000);

  return (
    <div className="orderpay">
      {!errorContext ? (
        <>
          <div className="nft_sale">
            <div className="nft_sale_top">
              <div className="nft_sale_display">
                <img src={saleInfo?.imageBg} alt="" />
              </div>
              <div className="nft_sale_content">
                <p>{saleInfo?.collectionName}</p>
                <div className="nft_sale_price">
                  <span>¥{saleInfo?.price && round(saleInfo?.price, 2)}</span>
                  <Stepper 
                    defaultValue={1} 
                    onChange={(val) => setCount(val)} min={1} max={maxCount} digits={0} allowEmpty={false}
                    disabled={classify === '488_product' ? true: false}
                  ></Stepper>
                </div>
              </div>
            </div>

            {saleInfo?.collectionType === 4 ? (
              <></>
            ) : (
              <>
                <div className="nft_sale_bottom">
                  {saleInfo?.vipLevel && (
                    <p className="content_text">
                      <span className="label">权益①:</span>
                      <span>{saleInfo?.vipLevel}</span>
                    </p>
                  )}
                  {saleInfo?.rewardPoints ? (
                    <p className="content_text">
                      <span className="label">权益②:</span>
                      <span>返{saleInfo?.rewardPoints}积分</span>
                    </p>
                  ): null}
                  {saleInfo?.prizeBlindBoxTimesStr || saleInfo?.blindRight ? (
                    <p className="content_text">
                      <span className="label">权益③:</span>
                      <span>
                        {saleInfo?.prizeBlindBoxTimesStr === 0 ? "" : saleInfo?.prizeBlindBoxTimesStr}
                        {saleInfo?.blindRight}
                      </span>
                    </p>
                  ) : null}
                  {saleInfo?.right4 ? (
                    <p className="content_text">
                      <span className="label">权益④:</span>
                      <span>{saleInfo?.right4}</span>
                    </p>
                  ) : null}
                  <p className="content_textlast"></p>
                </div>
              </>
            )}
          </div>

          {classify !== '488_product' ? <div className="nft_saleprice">
            <div className="nft_spacebt flex">
              <span>商品金额</span>
              <div className="nft_spacebt_right">¥{saleInfo?.price && round(saleInfo?.price * count, 2)}</div>
            </div>
            <br />
            {saleInfo?.pointRatio > 0 && (
              <div>
                <div className="nft_spacebt">
                  <div className="nft_spacebt_right">
                    <span>积分抵扣</span>
                    <div className="right">
                      <Checkbox
                        defaultChecked={deduction}
                        onChange={(checked) => setDeduction(checked)}
                        style={{
                          "--icon-size": "18px",
                          "--font-size": "14px",
                          "--gap": "6px",
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                </div>
                <div className="poinsintroduce">
                  我的积分: {point} ({saleInfo?.pointRatio}积分可抵扣1元)
                </div>
              </div>
            )}
            <div className="nft_pricetotal">
              <div className="nft_total">总计: &nbsp;</div>
              {deduction && (
                <div className="nft_deduction">
                  积分抵扣: {round(deductionPrice * saleInfo?.pointRatio, 0)}(-￥{round(deductionPrice, 2)}) &nbsp;
                </div>
              )}
              <div className={`nft_price ${deduction ? 'price_left': ''}`}>¥{round(totalPrice, 2)}</div>
            </div>
          </div>: <div className="nft_saleprice">
            <div className="nft_spacebt flex">
              <span>商品金额</span>
              <div className="nft_spacebt_right">¥{saleInfo?.price && round(saleInfo?.price * count, 2)}</div>
            </div>
            <br />
            <div>
              <div className="nft_spacebt">
                <div className="nft_spacebt_right">
                  <span>兑换码</span>
                  <div className="right">
                    <Checkbox
                      defaultChecked={true}
                      disabled
                      style={{
                        "--icon-size": "18px",
                        "--font-size": "14px",
                        "--gap": "6px",
                      }}
                    ></Checkbox>
                  </div>
                </div>
              </div>
              <div className="poinsintroduce">{cardNo}</div>
            </div>
            <div className="nft_pricetotal">
              <div className="nft_total">总计: &nbsp;</div>
              <div className="nft_deduction">
                兑换抵扣:&nbsp;&nbsp; -￥{round(totalPrice, 2)}
              </div>
              <div className="nft_price price_left">¥0</div>
            </div>
          </div>}
          {classify !== '488_product' && <div className="nft_sale">
            <div className="nft_spacebt">
              <div className="pay_way">选择支付方式</div>
              <ul className="pay-type">
                <Radio.Group value={payWay} onChange={(val) => setPayWay(val)}>
                  <li>
                    <div className="apy_way_info">
                      <img src={require("@/assets/img/orderpay/wx.png")} alt="" />
                      <div className="type">微信</div>
                    </div>
                    <Radio value="1" style={{ "--icon-size": "18px" }}></Radio>
                  </li>
                  <li>
                    <div className="apy_way_info">
                      <img src={require("@/assets/img/orderpay/zfb.png")} alt="" />
                      <div className="type">支付宝</div>
                    </div>
                    <Radio value="2" style={{ "--icon-size": "18px" }}></Radio>
                  </li>
                </Radio.Group>
              </ul>
            </div>
          </div>}
          {classify !== '488_product' ? <div className="orderpay_buttom">
            <div className="left">
              <span className="total">总计:</span>
              <div className="price-detail">
                <div className="price">¥{round(totalPrice, 2)}</div>
                {deduction && <del className="original-price">¥{round(deductionPrice, 2)}</del>}
              </div>
            </div>
            <div className="pay">
              <Button onClick={throttlePayment}>立即支付</Button>
            </div>
          </div>: <div className="orderpay_buttom">
            <div className="left">
              <span className="total">总计:</span>
              <div className="price-detail">
                <div className="price">¥0.00</div>
                <del className="original-price">¥{round(totalPrice, 2)}</del>
              </div>
            </div>
            <div className="pay">
              <Button onClick={throttleReceive}>立即领取</Button>
            </div>
          </div>}
        </>
      ) : (
        <Error context={errorContext} />
      )}
    </div>
  )
}
