import React, {useEffect, useRef, useState, useContext, createContext} from 'react';
import {DotLoading, Empty, Toast} from "antd-mobile";
import {getUrlAllParams, isAndroidOrIOS, isArray, isEmptyString, isIOS} from "@/utils/publicFun";
import {
    getComponentList, hcImageList, previewImage,
} from "@/api/projectManager_self";
import {hideLoading, showLoading} from "@/components/loading";
import {Swiper} from 'antd-mobile'

import {InfiniteScroll, PullToRefresh, List} from "antd-mobile";
import {sleep} from "antd-mobile/es/utils/sleep";
import NoticeUtil from "@/utils/NoticeUtil";
import EggComplexView from "@/view/eggHatch/views/eggComplexView";
import ImageBrowerView from "@/view/eggHatch/views/imageBrowerView";
import clickThrottle from "@/utils/clickThrottle";
import './eggHatch.scss';

const icon_tag_dis = require("@/assets/img/icon_shop_miaoban.png") //未选中的样式
const icon_tag_sel = require("@/assets/img/icon_shop_nor.png") //选中的样式
const item_sel = require("@/assets/img/egg/icon_gou.png") //选中的样式

const icon_my = require("@/assets/img/egg/icon_wode.png")
const icon_shop = require("@/assets/img/egg/icon_gouwuche.png")

const Context = createContext(null);
const JsBridge = window.JsBridge;


const kClickHeaderTitle = 'kClickHeaderTitle'; //点击了头部的事件
const kClickPro = 'kClickPro'; //点击商品
const kClickSwitch = 'kClickSwitch'; //切换了我的和商城

const {nftNo, collectionGroup = ''} = getUrlAllParams(window.location.search.slice(1))


const myTitles = [
    {title: '身体', isSel: true, id: 0, partsCode: '01', secondId: 0},
    {title: '头部', isSel: false, id: 1, partsCode: '02', secondId: 1},
    {title: '饰品', isSel: false, id: 2, partsCode: '03', secondId: 2},
    {title: '套装', isSel: false, id: 3, partsCode: '04', secondId: 3}];

const shopTitles = [
    {title: '推荐', isSel: true, id: 0, partsCode: '', secondId: 4},
    {title: '身体', isSel: false, id: 1, partsCode: '01', secondId: 5},
    {title: '头部', isSel: false, id: 2, partsCode: '02', secondId: 6},
    {title: '饰品', isSel: false, id: 3, partsCode: '03', secondId: 7},
    {title: '套装', isSel: false, id: 4, partsCode: '04', secondId: 8}];
let currentArray = [0, 4];
let choiceProItems = [];
let preImg = ''  //刚开始进来的初始值
let hcImgs = []; //选择完的图片的合集
let currentMySecondId = 0;
let currentShopSecondId = 0;
export const EggHatch = () => {
    const [index, setIndex] = useState(1);

    useEffect(() => {
        window.localStorage.setItem("shop_cart_info_item", '');
    }, [])

    const refreshData = () => {

    }
    const changeIndex = (index) => {
        setIndex(index)
        NoticeUtil.emit(kClickSwitch, {type: index})
    }

    return (
        <Context.Provider value={{}}>
            <div className={'egg_2_container'}>
                <HeaderComponent refreshData={refreshData}/>
                <div style={{display: index === 1 ? 'block' : 'none'}}>
                    <ContentMyComponent refreshData={refreshData}/>
                </div>
                <div style={{display: index === 0 ? 'block' : 'none'}}>
                    <ContentShopComponent refreshData={refreshData}/>
                </div>
                <ContentSwitchComponent changeIndex={(index) => changeIndex(index)}/>
                {
                    index === 1 ? <HcComponent/> : null
                }

            </div>
        </Context.Provider>
    )
}

const ContentSwitchComponent = (props) => {
    const [index, setIndex] = useState(1);

    const clickIndex = (index) => {
        setIndex(index);
        let timer = setTimeout(() => {
            props.changeIndex && props.changeIndex(index);
            clearTimeout(timer);
        }, 500);
    }

    return (
        <div className={'egg_2_content_switch_container'}>
            <div className={'egg_2_shop_txt'}>商城</div>
            <div className={'egg_2_my_txt'}>我的</div>
            <div className={'egg_2_content_switch_btn_container'}>
                {
                    index === 0 ? <div className={'egg_2_shop_btn_shop'}>
                        <img src={icon_shop} alt=""/>
                        <span>商城</span>
                    </div> : <div className={'egg_2_shop_btn_shop'} style={{background: 'transparent'}}
                                  onClick={() => clickIndex(0)}/>
                }
                {
                    index === 1 ? <div className={'egg_2_shop_btn_shop'}>
                        <img src={icon_my} alt=""/>
                        <span>我的</span>
                    </div> : <div className={'egg_2_shop_btn_shop'} style={{background: 'transparent'}}
                                  onClick={() => clickIndex(1)}/>
                }
            </div>
        </div>
    )
}
const HcComponent = (props) => {
    const [maskVisible, setMaskVisible] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        NoticeUtil.addListener(kClickPro, addListenerClickPro)

        return () => {
            NoticeUtil.removeListener(kClickPro, addListenerClickPro);
        }
    }, [])

    const addListenerClickPro = () => {
        setProducts(JSON.parse(JSON.stringify(choiceProItems)))
    }

    const hcClick = async () => {
        let isExitShop = false;
        if (choiceProItems.length > 0) {
            for (let i = 0; i < choiceProItems.length; i++) {
                let item = choiceProItems[i];
                if (isEmptyString(item.nftNo)) {
                    isExitShop = true;
                    break
                }
            }
        }
        if (isExitShop) {
            Toast.show('当前选择合成配件中包括未购买的,请先在商城购买')
            return
        }
        setMaskVisible(!maskVisible)
    }

    return (
        <>
            <div className={`hc_container`} onClick={() => hcClick()}>孵化合成 </div>
            <EggComplexView maskVisible={maskVisible}
                setMaskVisible={setMaskVisible}
                products={products}
                preImg={preImg}
                nftNo={decodeURIComponent(nftNo)}
                hcImgs={hcImgs}
            />
        </>
    )
}
// 头部视图
const HeaderComponent = (props) => {

    const [preImage, setPreImage] = useState('')
    const [imgs, setImgs] = useState([])
    const [showBigImg, setShowBigImg] = useState(false);
    useEffect(() => {
        NoticeUtil.addListener(kClickPro, browerImages)
        loadPreImage().then();
        return () => {
            NoticeUtil.removeListener(kClickPro, browerImages)
        }
    }, [])

    const browerImages = async () => {
        let collectionIds = [];
        for (const item of choiceProItems) {
            collectionIds.push(item.collectionId);
        }

        try {
            showLoading();
            const {code, msg, data} = await hcImageList({
                petNo: decodeURIComponent(nftNo),
                collectionIds: collectionIds
            });
            hideLoading();
            if (code === '000000') {
                if (data && data.elemFiles && isArray(data.elemFiles) && data.elemFiles.length > 0) {
                    let elemFiles = data.elemFiles;
                    hcImgs = elemFiles;
                    setImgs(elemFiles);
                }
            } else {
                setTimeout(() => {
                    Toast.show(msg);
                }, 100);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const loadPreImage = async () => {
        try {
            showLoading();
            const {code, msg, data} = await previewImage({nftNo: nftNo});
            hideLoading();
            if (code === '000000') {
                if (data && data.uploadPath) {
                    setPreImage(data.uploadPath)
                    preImg = data.uploadPath;
                }
            } else {
                setTimeout(() => {
                    Toast.show(msg);
                }, 100);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const preBigImg = () => { //查看大图
        setShowBigImg(!showBigImg)
    }

    const goBack = () => {
        JsBridge.back()
    }

    const getImgs = () => {
        if (imgs.length > 0) {
            return imgs.map((item, index) => {
                return <img src={item.elemFileUrl || ''} key={index.toString()}/>
            })
        }
        return <img src={preImage}/>
    }

    return (
        <>
            <div className={'egg_header_2_component'}>
                <div className={'shop_back_btn'} onClick={() => goBack()}/>
                <div className={'shop_pre_big_img'} onClick={() => preBigImg()}/>
                {getImgs()}
                <ImageBrowerView showBigImg={showBigImg} setShowBigImg={setShowBigImg}
                                 imgs={imgs.length > 0 ? imgs : [{elemFileUrl: preImage}]}/>
            </div>
        </>
    )
}

const ContentMyComponent = (props) => {
    const swiperRef = useRef(null);

    const lists = () => {
        return myTitles.map((item, index) => {
            return (
                <Swiper.Item key={index.toString()}>
                    {MyList(item)}
                </Swiper.Item>
            )
        })
    }

    const changeTitle = (item) => {
        swiperRef.current?.swipeTo(item.id);
        currentMySecondId = item.secondId;
        NoticeUtil.emit(kClickHeaderTitle, item)
    }

    return (
        <div className={'shop_stage_2_content_container'}>
            <div className={'shop_stage_2_header_btn_second_father0'}>
                <MyHeader changeTitle={(item) => changeTitle(item)}/>
            </div>
            <div className={'shop_stage_2_list'}>
                <Swiper
                    direction='horizontal'
                    indicator={() => null}
                    ref={swiperRef}
                    allowTouchMove={false}
                    defaultIndex={0}
                >
                    {lists()}
                </Swiper>
            </div>
        </div>
    )
}

//我的头部
const MyHeader = (props) => {
    const [titles, setTitles] = useState(myTitles)
    const clickTitle = (item) => {
        if (item.isSel) {
            return
        }
        let titlesTem = JSON.parse(JSON.stringify(titles))
        for (let i = 0; i < titlesTem.length; i++) {
            let itemTem = titlesTem[i];
            if (itemTem.id === item.id) {
                itemTem.isSel = true;
            } else {
                itemTem.isSel = false;
            }
        }

        let timer = setTimeout(() => {
            clearTimeout(timer);
            setTitles(titlesTem);
        }, 100)

        props.changeTitle && props.changeTitle(item)
    }

    const secondHeader = () => {
        return titles.map((item, index) => {
            return (
                <div className={'header_btn_2_second_father'}
                     onClick={() => clickTitle(item)} key={index.toString()}
                     style={{marginLeft: index > 0 ? "2vw" : "0"}}>
                    <img src={item.isSel ? icon_tag_sel : icon_tag_dis} className={'header_btn_second_img'}/>
                    <div className={'header_btn_second_title'}
                         style={{
                             color: item.isSel ? '#092010' : '#8EF4B1',
                             fontSize: '12px',
                         }}>{item.title}</div>
                </div>
            )
        })
    }
    return (
        <>
            {secondHeader()}
        </>
    )
}

const MyList = (initalItem) => {
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const pageSize = 10;
    const pageNum = useRef(1);
    const currentPage = useRef(0);
    const currentId = useRef(initalItem.id);
    const [data, setData] = useState([]);
    let dataTem0 = useRef([]);

    useEffect(() => {
        NoticeUtil.addListener(kClickHeaderTitle, addClickTitleListener)
        NoticeUtil.addListener(kClickSwitch, addClickSwitchMyOrShop)
        currentPage.current = 0;
        pageNum.current = 1;
        loadData().then();
        return () => {
            NoticeUtil.removeListener(kClickHeaderTitle, addClickTitleListener);
            NoticeUtil.removeListener(kClickSwitch, addClickSwitchMyOrShop);
        }
    }, [])

    const addClickTitleListener = (res) => {
        if (res.secondId === initalItem.secondId) {
            if (!currentArray.includes(res.secondId)) {
                currentArray.push(res.secondId)
                loadData().then();
            } else {
                changeChoiceData();
            }
        }
    }

    const addClickSwitchMyOrShop = (res) => {
        let type = res.type;
        if (type === 1) { //我的
            if (currentMySecondId === initalItem.secondId) {
                changeChoiceData();
            }
        } else { //商城
            if (currentShopSecondId === initalItem.secondId) {
                changeChoiceData();
            }
        }
    }


    const changeChoiceData = () => {
        if (choiceProItems.length === 0) {
            return
        }
        if (dataTem0.current.length) {
            let dataTem = JSON.parse(JSON.stringify(dataTem0.current));
            for (const item of dataTem) {
                item.isSel = false;
                for (const pro of choiceProItems) {
                    if (pro.id === item.id) {
                        item.isSel = true;
                    }
                }
            }
            setData(dataTem);
            dataTem0.current = dataTem
        }
    }

    const loadData = async (isRefresh) => {
        if (!currentArray.includes(initalItem.secondId)) {
            return
        }

        if (currentId.current !== initalItem.id) {
            return
        }
        if (currentPage.current === pageNum.current) {
            return
        }
        try {
            showLoading();
            let animalCode = '90';
            if (collectionGroup === '1' || collectionGroup === '2' || collectionGroup === '3') { //蝙蝠
                animalCode = '91'
            } else if (collectionGroup === '4' || collectionGroup === '5' || collectionGroup === '6') { //鱼
                animalCode = '93'
            } else if (collectionGroup === '7' || collectionGroup === '8' || collectionGroup === '9') {//鹿
                animalCode = '92'
            }

            let params = {
                partsCode: initalItem.partsCode,
                seriesId: '04',
                current: pageNum.current,
                size: pageSize,
                isLogin: 1,
                secondId: initalItem.secondId,//测试用
                animalCode: animalCode,
            };
            if (initalItem.secondId < 4) {
                const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
                if (userInfo && userInfo.clientId) {
                    params.clientId = userInfo.clientId;
                }
            }
            currentPage.current = pageNum.current;
            const res = await getComponentList(params);
            hideLoading();
            if (res.code !== '000000') {
                setTimeout(() => {
                    Toast.show(res.msg);
                }, 500)
                return
            }

            if (res.data && res.data.nftComponentVO && isArray(res.data.nftComponentVO)) {
                let totalArr = [];
                if (pageNum.current > 1) {
                    totalArr = data.concat(res.data.nftComponentVO);
                } else {
                    totalArr = res.data.nftComponentVO;
                }
                for (const item of totalArr) {
                    item.isSel = false;
                    for (const pro of choiceProItems) {
                        if (pro.id === item.id) {
                            item.isSel = true;
                        }
                    }
                }
                setData(totalArr);
                dataTem0.current = totalArr;
                let totalSize = parseInt(res.data.total) || 0;
                setHasMore(totalArr.length < totalSize)
            } else {
                setData([]);
                dataTem0.current = [];
                setHasMore(false)
            }
            setIsLoad(true);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const loadMore = async () => {
        if (currentId.current !== initalItem.id) {
            return
        }
        pageNum.current++;
        await loadData();
    }

    const onChoicePro = (item) => {
        let timer = setTimeout(() => {
            let dataTem = JSON.parse(JSON.stringify(data));
            for (const model of dataTem) {
                if (model.code === item.code) {
                    model.isSel = false;
                }
                if (model.id === item.id) {
                    model.isSel = !item.isSel;
                }
                if (model.isSel) {
                    let proIndex = choiceProItems.findIndex(pro => {
                        return pro.code === model.code
                    })
                    if (proIndex >= 0) { //存在相同产品 删除之前的 添加最新的
                        if (choiceProItems.length > proIndex) {
                            choiceProItems.splice(proIndex, 1);
                            choiceProItems.push(model)
                        }
                    } else { //不存在
                        choiceProItems.push(model)
                    }
                } else { //非选中状态
                    let proIndex = choiceProItems.findIndex(pro => {
                        return pro.id === model.id
                    })
                    if (proIndex >= 0) { //取消选中,之后要移除
                        if (choiceProItems.length > proIndex) {
                            choiceProItems.splice(proIndex, 1);
                        }
                    }
                }
            }

            NoticeUtil.emit(kClickPro);
            setData(dataTem);
            dataTem0.current = dataTem;
            clearTimeout(timer);
        }, 100);
    }

    const gobuy = (item) => { //购买
        if (!clickThrottle()) {
            return
        }
        if (item.collectionId) {
            window.localStorage.setItem("shop_cart_info_item", JSON.stringify(item));
            let timer = setTimeout(() => {
                clearTimeout(timer);
                JsBridge.push("CustomWeb", {uri: `${window.location.origin}/eggHatchProductDetail`})
            }, 500);
        }
    }

    const listItem = (item) => {
        return (
            <div className={`shop_stage_1_item_container1 ${initalItem.secondId < 4 ? 'no_money_buy' : ''}`}
                 key={item.id.toString()}>
                <div className={`item_img_father_1`} onClick={() => onChoicePro(item)}>
                    <img src={item.imageBg} className={'item_img'}/>
                    {item.isSel ? <img src={item_sel} className={'item_choice_img'}/> : null}
                    {
                        (item.hasNum && item.hasNum > 0) ? <div className={'item_has_father'}>
                            <span>已拥有</span>
                            <span className={'item_has_count_span'}>{item.hasNum}</span>
                            <span>款</span>
                        </div> : null
                    }
                    {
                        item.isSel ? <div className={'item_img_father_1_padding'}/> : null
                    }
                </div>
                <div className={'item_name_2'}>{item.collectionName}</div>
                {
                    initalItem.secondId < 4 ? null : <div className={'item_price_add_father_2'}>
                        <span className={'price_span'}>¥{item.price}</span>
                        <div className={'add_buy_father'} onClick={() => gobuy(item)}>
                            <span className={'buy_span'}>购买</span>
                        </div>
                    </div>
                }
                {
                    !isEmptyString(item.collectionIdf) ?
                        <div className={'song_pkp_logo'}>{item.collectionIdf}</div> : null
                }
            </div>
        )
    }

    return (
        <div style={{overflowY: 'scroll', background: 'transparent'}} key={initalItem.secondId.toString()}>
            <PullToRefresh
                key={initalItem.secondId}
                onRefresh={async () => {
                    await sleep(500);
                    currentPage.current = 0;
                    pageNum.current = 1;
                    await loadData(true)
                }}
            >
                {
                    data.length ? <List style={{background: 'transparent'}}>
                        {data.map((item, index) => (
                            listItem(item)
                        ))}
                    </List> : isLoad ? <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Empty style={{width: "100%", height: '40vh'}}
                               image={<img src={''}/>}
                               description="~暂无数据~"/>
                    </div> : null
                }
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50}>
                    {hasMore ? (
                        <>
                            <span>Loading</span>
                            <DotLoading/>
                        </>
                    ) : (
                        <span/>
                    )}
                </InfiniteScroll>
            </PullToRefresh>
        </div>
    )
}


const ContentShopComponent = (props) => {
    const swiperRef = useRef(null);

    const lists = () => {
        return shopTitles.map((item, index) => {
            return (
                <Swiper.Item key={index.toString()}>
                    {MyList(item)}
                </Swiper.Item>
            )
        })
    }

    const changeTitle = (item) => {
        swiperRef.current?.swipeTo(item.id);
        currentShopSecondId = item.secondId;
        NoticeUtil.emit(kClickHeaderTitle, item);
    }

    return (
        <div className={'shop_stage_2_content_container'}>
            <div className={'shop_stage_2_header_btn_second_father0'}>
                <ShopHeader changeTitle={(item) => changeTitle(item)}/>
            </div>
            <div className={'shop_stage_2_list'}>
                <Swiper
                    direction='horizontal'
                    indicator={() => null}
                    ref={swiperRef}
                    allowTouchMove={false}
                    defaultIndex={0}>
                    {lists()}
                </Swiper>
            </div>
        </div>
    )
}

//商城头部
const ShopHeader = (props) => {
    const [titles, setTitles] = useState(shopTitles)
    const clickTitle = (item) => {
        if (item.isSel) {
            return
        }

        let titlesTem = JSON.parse(JSON.stringify(titles))
        for (let i = 0; i < titlesTem.length; i++) {
            let itemTem = titlesTem[i];
            if (itemTem.id === item.id) {
                itemTem.isSel = true;
            } else {
                itemTem.isSel = false;
            }
        }
        let timer = setTimeout(() => {
            clearTimeout(timer);
            setTitles(titlesTem);
        }, 100)

        props.changeTitle && props.changeTitle(item)
    }

    const secondHeader = () => {
        return titles.map((item, index) => {
            return (
                <div className={'header_btn_2_second_father'}
                     onClick={() => clickTitle(item)} key={index.toString()}
                     style={{marginLeft: index > 0 ? "2vw" : "0"}}>
                    <img src={item.isSel ? icon_tag_sel : icon_tag_dis} className={'header_btn_second_img'}/>
                    <div className={'header_btn_second_title'}
                         style={{
                             color: item.isSel ? '#092010' : '#8EF4B1',
                             fontSize: '12px',
                         }}>{item.title}</div>
                </div>
            )
        })
    }
    return (
        <>
            {secondHeader()}
        </>
    )
}






