import React, { useState, useCallback, useEffect, useRef } from 'react'
import styles from './style.module.scss'
import { Mask, Toast } from 'antd-mobile'
import { Title } from '../components/common'
import close from '@/assets/img/landfi/icon_del.png'
import { useConfirmOrder } from './order'
import { usePurchaseRecords } from './purchaseRecords'
import ListView from "@/components/AntListView"
import { purchaseAccelerationCardList } from '@/api/guland'
import { hideLoading, showLoading } from '@/components/loading'

const PurchaseAsseleratorCard = ({visible, onClose, from, refreshMyCard}) => {
  const [cardList, setCardList] = useState([])
  const [cardInfo, setCardInfo] = useState({})
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const domRef = useRef(null)

  const [onOrderOpen, ConfirmOrder] = useConfirmOrder({
    info: {
      ...cardInfo, 
      refreshCardList: () => getSpeedCardList(false, true),
      from,
      refreshMyCard,
      onClosePurchase: () => onClose(),
    }
  })
  const [onRecordsOpen, PurchaseRecords] = usePurchaseRecords()

  useEffect(() => {
    if (visible) {
      getSpeedCardList(true)
    } else {
      setCardInfo({})
    }
  }, [visible])

  const getSpeedCardList = async (loading=false, isRefresh=true) => {
    try {
      loading && showLoading()
      if(isRefresh) {
        currentPage.current = 1;
        domRef.current?.scroll(0, 0)
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        pageNum: currentPage.current,
        pageSize: pageSize.current,
      }
      const res = await purchaseAccelerationCardList(params)
      loading && hideLoading()
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setCardList(res?.data?.accelerationCardsDOList || []);
      } else {
        setCardList([...cardList, ...res?.data?.accelerationCardsDOList]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.accelerationCardsDOList.length >= pageSize.current);
    } catch (error) {
      console.log(error)
      loading && hideLoading()
      Toast.show(error)
    }
  }

  const onImmediateUse = (item) => {
    setCardInfo(item)
    onOrderOpen()
  }

  const PurchaseCardItem = (item) => {
    const { amount, times, cardName, image } = item || {}
    return <div className={styles.speed_card_item} onClick={() => onImmediateUse(item)}>
      <div className={styles.multiple}><span>{times}倍!</span></div>
      <div className={`${styles.middle_img} ${styles.middle_img_purchase}`} style={{backgroundImage: image ? `url(${image})` : undefined}}></div>
      <div className={styles.day_exp}>{cardName}</div>
      <div className={styles.use_btn}>¥{amount}</div>
    </div>
  }

  return <Mask 
    visible={visible} 
    destroyOnClose
    className={styles.speed_card_mask}
    style={{background: 'rgba(0, 0, 0, .8)'}}
  >
    <div className={`${styles.my_asselerator_card} ${styles.purchase_card}`}>
      <Title title="购买加速卡" className={styles.title}/>
      <img src={close} alt="" onClick={onClose} className={styles.close_btn}/>
      <div className={styles.points_use_tip}>可使用积分抵扣哦～</div>
      <div className={styles.purchase_more} onClick={onRecordsOpen}>购买记录&gt;&gt;</div>
      <div className={styles.card_list} ref={domRef}>
        {
          cardList?.length > 0 ? <ListView
            dataSource={cardList}
            hasMore={hasMore}
            style={{paddingTop: 5}}
            renderItem={PurchaseCardItem}
            onEndReached={async () => {
              await getSpeedCardList(false, false);
            }}
            isLoad={isLoad}
          />: !isLoad ? null: <div className={styles.no_data}>暂无数据</div>
        }
      </div>
      {ConfirmOrder}
      {PurchaseRecords}
    </div>
  </Mask>
}

export const usePurchaseCard = ({from, refreshMyCard}={}) => {
  const [visible, setVisible] = useState(false)

  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  const onOpen = useCallback(() => {
    setVisible(true)
  }, [])

  const cardView = (
    <PurchaseAsseleratorCard visible={visible} onClose={onClose} from={from} refreshMyCard={refreshMyCard}/>
  )

  return [onOpen, cardView]
}