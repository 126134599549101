import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import './style.scss';
import { Toast } from 'antd-mobile';
import {hideLoading, showLoading} from "@/components/loading";
import ListView from '@/components/AntListView';
import { queryAppointTypeCollection } from '@/api/projectManager_self';
import { mockFn } from './mock';

const LegendApeList = forwardRef((props, ref) => {
  const { getLegendApe, renderStatus } = props;
  const [legendApeList, setLegendApeList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [initStatus, setInitStatus] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const choosedLegendApeRef = useRef({nftNo: '', imageBg: ''});
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

  useImperativeHandle(ref, () => {
    return {
      chosseLegendApe: chosseLegendApe,
      getLegengApeList: getLegengApeList
    }
  })

  useEffect(() => {
    if (!initStatus && renderStatus) {
      setInitStatus(true);
      getLegengApeList();
      return;
    }
  }, [initStatus, renderStatus]);

  const chosseLegendApe = (item) => {
    const newList = legendApeList.map((i) => {
      if(i.id === item.id) {
        return {...i, isChoosed: !i.isChoosed}
      } else {
        return {...i, isChoosed: false};
      }
    })
    if(choosedLegendApeRef.current.id === item.id) {
      choosedLegendApeRef.current = {nftNo: '', imageBg: ''};
    } else {
      choosedLegendApeRef.current = item;
    }
    setLegendApeList(newList);
    getLegendApe(choosedLegendApeRef.current);
  }

  const getLegengApeList = async (isRefresh=true, isLoading=true) => {
    try {
      if(isRefresh) {
        currentPage.current = 1;
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        current: currentPage.current,
        size: pageSize.current,
        collectionType: 2,
        clientId: userInfo?.clientId
      }
      isLoading && showLoading();
      const res = await queryAppointTypeCollection(params);
      // const res = await mockFn(2);
      isLoading && hideLoading();
      if (res.code !== '000000') throw res.msg;
      if(currentPage.current === 1) {
        setLegendApeList(res.data.nftComponentVO);
      } else {
        setLegendApeList([...legendApeList, ...res.data.nftComponentVO]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res.data.total > [...legendApeList, ...res.data.nftComponentVO].length);
    } catch (error) {
      isLoading && hideLoading();
      Toast.show(error);
    }
  }

  const RenderItem = (item) => {
    return <div 
      className={`container-item ${item.isChoosed ? 'active': ''}`} 
      onClick={() => chosseLegendApe(item)}
    >
      <span className='collection-num'>#{item.nftNo}</span>
      <img src={item.imageBg} alt="" />
    </div>
  }

  return <div className='legend-ape-list-container' ref={ref}>
    <ListView 
      dataSource={legendApeList}
      renderItem={RenderItem}
      hasMore={hasMore}
      onEndReached={() => getLegengApeList(false, false)}
      style={{paddingTop: 30}}
      isLoad={isLoad}
    />
  </div>
})

export default LegendApeList;