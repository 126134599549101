/**
 * @description GR-2408675王小慧盲盒相关接口
 * 
*/
import appService from '@/utils/appService';
import requests from '@/utils/requests_self';
import { 
    queryDefCode,
    getActivityNftPool,
 } from "@/api/projectManager_self";
import JsBridgeNew from '@/utils/jsbridgeNew';
class Service {

    // 活动码
    static activityCode = 'wxh';
    // 盲盒ID
    static collectionId = 223;

    /**
     * @description 获取活动规则
     * @param {*} params 
     * @returns
    */
    static getActivityRules = async ()=> {
        // const res = await appService('101193', {
        //     activityCode: Service.activityCode,
        //     codeType: 'activityRule',
        //     code: Service.activityCode,
        // });
        // return res;
        return await queryDefCode({codeType: 'activityRule', code: Service.activityCode});
    }

    /**
     * @description 获取用户信息，是否实名
     * @returns {ID: 存在则实名}
     */ 
    static getUserCertificateStatus = async () => {
        if (window.JsBridge.hasWebViewBridge()) {
            const res = await window.JsBridge.getDetailCurrentUserInfo()
            return res;
        } else {
            return {};
        }
    }

    /**
     * @description 获取盲盒换宝石活动信息
     * @param {Object} params
     * @code 600828
     * @returns {}
    */
    static getInitData = async (params) => {
        params = { activityCode: Service.activityCode }
        return requests.post('/customer/activity/v1/initActivity', JSON.stringify(params));
    }

    /**
     * @description 获取奖池编号
     * @param {activityCode, type: A} params
     * @returns {}
    */
    static getActivityPool = async (params={})=> {
        const param = {
            activityCode: Service.activityCode,
            type: 'A',
            ...params,
        }
        const res = await getActivityNftPool(param)
        return res;
    }

    /**
     * @description 查询中奖记录
     * @param {activityCode}
     * @code 101329
    */

    static getReceiveList = async (params={})=> {
        const code = '101329';
        const param = {
            activityCode: Service.activityCode,
            ...params,
        }
        const res = await appService(code, param)
        return res;
    }
    
    
}

export default Service;