import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import close from '@/assets/img/icon/close.png';
import {hideLoading, showLoading} from "@/components/loading";
import {Toast, Popup} from 'antd-mobile';
import ListView from '@/components/AntListView';
import {getInviteRecord} from '@/api/projectManager_self';
import styles from './styles.module.scss';

const mockData = {
    code: '000000',
    msg: 'success',
    data: {
        list: [
            {
                createTime: '2023-03-20 09:00:00',
                inviteStatus: '邀请购买成功',
                loginName: '13236510099',
                reason: '',
            },
            {
                createTime: '2023-03-20 09:00:00',
                inviteStatus: '邀请失败',
                loginName: '13236510099',
                reason: '被邀请人设别重复登录',
            },
        ]
    }
}

const mockFetch = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(mockData);
        }, 2000);
    })
}

const PopupModal = ({onClose, activityCode, visible}) => {
    const [hasMore, setHasMore] = useState(false);
    const [recordList, setRecordList] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [_visible, setVisible] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);

    useEffect(() => {
        if (visible) {
            getRecordList();
            setVisible(true)
        } else {
            onCancel();
        }
    }, [visible]);

    const getRecordList = async (isRefresh = true, isLoading = true) => {
        try {
            currentPage.current = isRefresh ? 1 : currentPage.current + 1;
            const params = {
                pageNum: currentPage.current,
                pageSize: pageSize.current,
                activityCode: activityCode
            }
            isLoading && showLoading();
            const res = await getInviteRecord(params);
            // const res = await mockFetch();
            isLoading && hideLoading();
            if (res.code !== '000000') throw res.msg;
            // console.log('res---', res)
            if (currentPage.current > 1) {
                setRecordList([...recordList, ...res.data.list]);
            } else {
                setRecordList(res.data.list);
            }
            setIsLoad(true);
            setHasMore(res.data.list.length >= pageSize.current);
        } catch (error) {
            isLoading && hideLoading();
            Toast.show(error);
        }
    }

    const onCancel = () => {
        setVisible(false);
        setTimeout(onClose, 1000);
    }

    const RenderItem = ({loginName, createTime, inviteStatus, reason, point}) => {
        return <div className="variation-item">
            <div className="item-left">
                <span>{loginName}</span>
                <span>{createTime}</span>
            </div>
            <div className="item-right">
                <span>{inviteStatus}</span>
                {(point && point > 0) ? <span>积分+{point}</span> : (reason ? <span>{reason}</span> : null)}
            </div>
        </div>
    }

    return <Popup
        visible={_visible}
        onMaskClick={onCancel}
        bodyStyle={{
            borderRadius: "9px 9px 0 0",
            background: 'linear-gradient(270deg, rgba(52,51,65,0.76) 0%, rgba(52,51,65,0.69) 100%)',
            backdropFilter: 'blur(11px)',
            boxShadow: 'inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42)'
        }}
    >
        <div className={styles.container}>
            <div className="invite-title">
                <span>邀新记录</span>
                {/* <img onClick={onCancel} src={close} alt="" /> */}
            </div>
            <div className="variation-invite-list">
                <ListView
                    dataSource={recordList}
                    renderItem={RenderItem}
                    hasMore={hasMore}
                    onRefresh={() => getRecordList(true, false)}
                    onEndReached={() => getRecordList(false, false)}
                    style={{paddingTop: 60}}
                    isLoad={isLoad}
                />
            </div>
        </div>
    </Popup>
}

PopupModal.open = (activityCode) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => div.remove();
    ReactDOM.render(<PopupModal
        onClose={close}
        activityCode={activityCode}
        visible
    />, div);
}

export default PopupModal;
