import React, { useEffect, useRef, useState } from "react";
import DropdownSelect from "./../components/dropdownSelect";
import SelectList from "./components/selectList";
import SelectInfo from "./components/selectInfo";
import { useLocation,useNavigate } from "react-router-dom";
import { Toast } from "antd-mobile";
import close from "@/assets/img/landfi/icon_del.png";

import { LevelOptions, getApeCate } from "./../../utils";
import styles from "./styles.module.scss";

const RentOut = () => {
  const navigate = useNavigate();

  const listRef = useRef(null);
  const infoRef = useRef(null);

  const { state } = useLocation();
  const { type } = state;
  const [selectData, setSelectData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [apeCate, setApeCate] = useState([]);

  useEffect(() => {
    getApeCateOptions();
  }, []);
  const selectChange = (data) => {
    setSelectData(data);
  };
  const levelChange = (data) => {
    setFilterData(data);
  };
  const refresh = () => {
    listRef.current.refresh();
    infoRef.current.refresh();
  };

  const getApeCateOptions = async () => {
    try {
      const res = await getApeCate();
      setApeCate(res)
    } catch (error) {
      Toast.show(error);
    }
  };

  return (
    <div className={styles.land_rent_detail_out}>
       <img
          className={styles.close}
          src={close}
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />
      <div className={styles.left}>
        <div className={styles.select_box}>
          <DropdownSelect
            title="等级"
            options={type === 1 ? apeCate : LevelOptions}
            onChange={levelChange}
          />
        </div>
        <div className={styles.list_box}>
          <SelectList
            type={type}
            ref={listRef}
            filterData={filterData}
            onChange={selectChange}
          />
        </div>
      </div>
      <div className={styles.right}>
        <SelectInfo
          ref={infoRef}
          title="等级"
          type={type}
          selectData={selectData}
          refresh={refresh}
        />
      </div>
    </div>
  );
};

export default RentOut;
