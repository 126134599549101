import React, {useEffect, useState, useRef, createContext, useContext} from "react"
import './index.scss'
import icon_fuzhi from '@/assets/img/icon_fuzhi.png';
import {Toast} from "antd-mobile";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {Input} from 'antd-mobile'
import {getUrlAllParams, isEmptyString, isIOSNew, timeFormat} from "@/utils/publicFun";
import JsBridgeNew from "@/utils/jsbridgeNew";
import {hideLoading, showLoading} from "@/components/loading";
import {getBlockChainAssets, getBlockChainTransactions, getMyWalletAddress} from "@/api/projectManager_self";
import Empty from "@/components/empty";
import {useLocation} from "react-router";

const Context = createContext(null);
export const BlockChainAdrInfo = () => {
    const {search} = useLocation()
    const {walletAddress1 = ''} = getUrlAllParams(search.slice(1));
    const [walletAddress, setWalletAddress] = useState(walletAddress1);


    useEffect(() => {
        window.JsBridge.setTitle("区块链地址信息");
        if (!isIOSNew()) {
            JsBridgeNew.addKeyboardEvent();
        }
    }, [])

    const refreshData = (adress) => {
        // console.log('add---', adress)
        if (!isEmptyString(adress)) {
            setWalletAddress(adress)
        }
    }

    return (
        <Context.Provider value={{walletAddress}}>
            <div className={'block_chain_info_container'}>
                <AccountMsgComponent/>
                <TADelComponent refreshData={(adress) => refreshData(adress)}/>
                <TABalanceComponent/>
            </div>
        </Context.Provider>
    )
}

const AccountMsgComponent = () => {
    const {walletAddress,} = useContext(Context);

    return (
        <div className={'account_msg_container'}>
            <p className={'account_msg_title'}>区块链查证信息概览</p>
            <p className={'account_msg_title1'}>账户信息</p>
            <div className={'account_msg_content'}>
                <p className={'account_msg_content_title'}>区块链地址</p>
                <CopyToClipboard text={walletAddress}
                                 onCopy={async () => {
                                     Toast.show('复制成功');
                                 }}>
                    <div className={'account_address_container'}>
                        <span
                            className={'account_address'}>{walletAddress}</span><img
                        src={icon_fuzhi}/>
                    </div>
                </CopyToClipboard>
            </div>
        </div>
    )
}

const TADelComponent = (props) => {//他的交易
    const {walletAddress,} = useContext(Context);
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const sizeRef = useRef(5);
    const currentPageRef = useRef(1);
    const isLoadRef = useRef(false);
    const [toalPage, setToalPage] = useState(0);
    const [isHide, setIsHide] = useState(false);

    useEffect(() => {
        currentPageRef.current = 1;
        !isEmptyString(walletAddress) && getTransactions();
    }, [walletAddress])

    const getTransactions = async () => {
        try {
            showLoading();
            const res = await getBlockChainTransactions({
                // userWalletAddress: '0xbf8ec7812da02a97be07ec388d6c5e6fe60ca866',
                userWalletAddress: walletAddress,
                current: currentPageRef.current,
                size: sizeRef.current,
            });
            hideLoading();
            isLoadRef.current = true;

            if (res.code !== '000000') throw res.msg;
            let totalTem = res.data?.total || 0;
            setTotal(res.data?.total || 0)
            setList(res.data?.list || [])
            setIsHide(res?.data?.isHide || false)
            if (totalTem % sizeRef.current === 0) {
                let pageTem = totalTem / sizeRef.current;
                setToalPage(pageTem);
            } else {
                let pageTem = parseInt(`${totalTem / sizeRef.current}`) + 1;
                setToalPage(pageTem);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const handleSelIndex = (index) => {
        currentPageRef.current = index;
        getTransactions();
    }

    const remarkState = (item) => { //交易类型：0发行，1转移，2升级，3回收
        if (item.remark === null) {
            return '---'
        } else if (item.remark === 0) {
            return '发行';
        } else if (item.remark === 1) {
            return '转移';
        } else if (item.remark === 2) {
            return '升级';
        } else if (item.remark === 3) {
            return '回收';
        }
    }

    const delList = () => {
        return list.map((item, index) => {
            return (
                <tbody key={index.toString()}>
                <tr>
                    <td style={{width: '13%'}}>{timeFormat(item.updatedTime)}</td>
                    <td style={{color: '#0066FF', width: '20%'}}
                        onClick={() => props.refreshData && props.refreshData(item.address)}>{item.address}</td>
                    <td style={{color: '#0066FF', width: '20%', paddingLeft: '20px'}}
                        onClick={() => props.refreshData && props.refreshData(item.toAddress)}>{item.toAddress}</td>
                    <td style={{color: '#0066FF', width: '20%', paddingLeft: '20px'}} onClick={() => {
                        window.JsBridge.push("Web", {uri: `${window.location.origin}/blockChainColInfo?transactionHash=${item.transactionHash}`});
                    }}>{item.transactionHash}</td>
                    <td style={{width: '13%', paddingLeft: '20px'}}>{remarkState(item)}</td>
                    <td style={{width: '13%'}}>成功</td>
                </tr>
                </tbody>
            )
        })
    }

    const tables = () => {
        return (
            <table className={'del_tab'}>
                <tbody>
                <tr>
                    <th style={{width: '13%'}}>时间</th>
                    <th style={{width: '20%'}}>发送方</th>
                    <th style={{width: '20%', paddingLeft: '20px'}}>接收方</th>
                    <th style={{width: '20%', paddingLeft: '20px'}}>交易哈希</th>
                    <th style={{width: '13%', paddingLeft: '20px'}}>交易类型</th>
                    <th style={{width: '13%'}}>交易状态</th>
                </tr>
                </tbody>
                {delList()}
            </table>
        )
    }

    return (
        <div className={'account_del_container'}>
            <span className={'account_del_title'}>TA的交易</span>
            <span className={'account_del_count'}>{total}</span>
            {
                (isLoadRef.current && total > 0) ? <>
                    <div className={'tab_container'}>
                        {tables()}
                    </div>
                    <div className={'tabIndex_container'}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <p className={'tabIndex_container_total'}>{`第${currentPageRef.current}页   共${toalPage}页`}</p>
                            {/*<p style={{marginRight:'5px'}}>共{toalPage}页</p>*/}
                            {/*<p className={'tabIndex_container_total'}>{`共${total}条记录 第${(currentPageRef.current - 1) * sizeRef.current + 1}-${(currentPageRef.current - 1) * sizeRef.current + sizeRef.current}条  第${currentPageRef.current}页`}</p>*/}
                            {/*<p style={{marginRight:'5px'}}>共{toalPage}页</p>*/}
                        </div>

                        {
                            total > 5 ? <TabIndexs handleSelIndex={(index) => handleSelIndex(index)} total={total}
                                                   size={sizeRef.current}/> : null
                        }
                    </div>
                </> : <Empty image={require('@/assets/img/noCollection.png')} description={isHide?'对方隐藏交易内容': '暂无数据~'}/>
            }
        </div>
    )
}


const TABalanceComponent = () => {//他的资产
    const {walletAddress,} = useContext(Context);
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const sizeRef = useRef(5);
    const currentPageRef = useRef(1);
    const isLoadRef = useRef(false);
    const [toalPage, setToalPage] = useState(0);
    const [isHide, setIsHide] = useState(false);

    useEffect(() => {
        currentPageRef.current = 1;
        !isEmptyString(walletAddress) && getBlAssets();
    }, [walletAddress])

    const handleSelIndex = (index) => {
        currentPageRef.current = index;
        !isEmptyString(walletAddress) && getBlAssets();
    }

    const getBlAssets = async () => {
        try {
            showLoading();
            const res = await getBlockChainAssets({
                // userWalletAddress: '0x80f417c631e86b8f8674f53a5d3e41439273a47f',
                userWalletAddress: walletAddress,
                current: currentPageRef.current,
                size: sizeRef.current,
            });

            hideLoading();
            isLoadRef.current = true;
            if (res.code !== '000000') throw res.msg;
            let totalTem = res.data?.total || 0;
            setTotal(totalTem)
            setList(res.data?.list || [])
            setIsHide(res?.data?.isHide || false)
            if (totalTem % sizeRef.current === 0) {
                let pageTem = totalTem / sizeRef.current;
                setToalPage(pageTem);
            } else {
                let pageTem = parseInt(`${totalTem / sizeRef.current}`) + 1;
                setToalPage(pageTem);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }


    const delList = () => {
        return list.map((item, index) => {
            return (
                <tbody key={index.toString()}>
                <tr>
                    <td style={{width: '13%',}}>{timeFormat(item.updatedAt)}</td>
                    <td style={{minWidth: '20%', maxWidth: '50%',}}>{item.collectionName}</td>
                    <td style={{width: '30%', paddingLeft: '20px'}}>{item.nftNo}</td>
                </tr>
                </tbody>
            )
        })
    }

    const tables = () => {
        return (
            <table className={'del_tab'} style={{width: '130%'}}>
                <tbody>
                <tr>
                    <th style={{width: '13%',}}>时间</th>
                    <th style={{minWidth: '20%', maxWidth: '50%'}}>资产名称</th>
                    <th style={{width: '30%', paddingLeft: '20px'}}>资产ID</th>
                </tr>
                </tbody>
                {delList()}
            </table>
        )
    }


    return (
        <div className={'account_del_container'}>
            <span className={'account_del_title'}>TA的资产</span>
            <span className={'account_del_count'}>{total}</span>

            {(isLoadRef.current && total > 0) ?
                <>
                    <div className={'tab_container'}>
                        {tables()}
                    </div>
                    <div className={'tabIndex_container'}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <p className={'tabIndex_container_total'}>{`第${currentPageRef.current}页   共${toalPage}页`}</p>
                            {/*<p className={'tabIndex_container_total'}>{`共${total}条记录 第${(currentPageRef.current - 1) * sizeRef.current + 1}-${(currentPageRef.current - 1) * sizeRef.current + sizeRef.current}条  第${currentPageRef.current}页`} </p>*/}
                            {/*<p style={{marginRight:'5px'}}>共{toalPage}页</p>*/}
                        </div>
                        {total > 5 ? <TabIndexs handleSelIndex={(index) => handleSelIndex(index)} total={total}
                                                size={sizeRef.current}/> : null}
                    </div>
                </> : <Empty image={require('@/assets/img/noCollection.png')} description={isHide?'对方隐藏交易内容': '暂无数据~'}/>
            }
            <div style={{width: '100%', height: '50px'}}/>
        </div>
    )
}

const TabIndexs = (props) => {
    const [selIndex, setSelIndex] = useState(1);
    // const pageRef = useRef(0);
    const inputValueRef = useRef('');
    const [toalPage, setToalPage] = useState(0);
    const [value, setValue] = useState(1);

    useEffect(() => {
        if (props.total % props.size === 0) {
            let pageTem = props.total / props.size;
            setToalPage(pageTem);
        } else {
            let pageTem = parseInt(`${props.total / props.size}`) + 1;
            setToalPage(pageTem);
        }
        setSelIndex(1);
        setValue(1);
        inputValueRef.current = '1';
    }, [props.total, props.size]);

    const selIndexClick = (index) => {
        if (selIndex !== index) {
            setSelIndex(index);
            setValue(index + '');
            props.handleSelIndex && props.handleSelIndex(index);
        }
    }

    const clickRightOrLeft = (type) => {
        if (type === 0) {//left
            if (selIndex === 1) {
                return;
            }
            let selIndexTem = selIndex - 1;
            selIndexClick(selIndexTem);
        } else if (type === 1) {//right
            if (selIndex >= toalPage) {
                return;
            }
            let selIndexTem = selIndex + 1;
            selIndexClick(selIndexTem);
        }
    }

    const onBlur = () => {
        if (isIOSNew()){
            pageChange();
        }
    }

    const enterPress = () => {
        if (!isIOSNew()){
            pageChange();
        }
    }

    const pageChange = () => {
        let pages = parseInt(inputValueRef.current) || 0;
        // console.log(pages);
        if (pages === 0) {
            return;
        }
        if (toalPage < pages) {
            Toast.show('已超出最大页')
            return;
        }
        selIndexClick(pages);
    }


    const indexs = () => {
        let arr = [];
        if (toalPage === 2) {
            arr = [1, 2];
        } else if (toalPage === 3) {
            arr = [1, 2, 3];
        } else if (toalPage === 4) {
            arr = [1, 2, 3, 4];
        } else if (toalPage >= 5) {
            arr = [1, 2, 3, 4, 5];
        }

        return arr.map((item, index) => {
            return (
                <p style={{color: selIndex === item ? '#000000' : '#9C9C9C'}} key={index.toString()}
                   onClick={() => selIndexClick(item)}>{item}</p>
            )
        })
    }

    let percentage = '40%';
    if (toalPage === 2) {
        percentage = '40%';
    } else if (toalPage === 3) {
        percentage = '50%';
    } else if (toalPage === 4) {
        percentage = '60%';
    } else if (toalPage >= 5) {
        percentage = '70%';
    }

    return (
        <div className={'tabIndex'}>
            <div className={'tabIndex_left'} style={{width: percentage}}>
                <img src={require('@/assets/img/icon_left.png')} onClick={() => clickRightOrLeft(0)}
                     style={{paddingLeft: '0px', paddingRight: '8px'}}/>
                {indexs()}
                <img src={require('@/assets/img/icon_right.png')} onClick={() => clickRightOrLeft(1)}
                />
            </div>
            <div className={'tabIndex_right'}>
                <p>跳转到</p>
                <Input
                    onChange={(e) => {
                        inputValueRef.current = e;
                        setValue(e);
                    }}
                    onBlur={() => onBlur()}
                    onEnterPress={() => enterPress()}
                    style={{
                        width: '40px',
                        height: '20px',
                        '--text-align': 'center',
                        borderRadius: '5px',
                        border: '1px solid #000',
                        marginLeft: '5px',
                    }}
                    defaultValue={'1'}
                    value={value + ''}
                    type={'number'}
                />
            </div>
        </div>
    )
}

