import React, {useState, useEffect, useRef} from 'react';
import styles from './styles.module.scss';
import {Mask, Swiper, Toast} from "antd-mobile";
import ListView from "@/components/AntListView";
import CompoundDialog from "@/view/colCompound/components/compoundDialog";
import {getImageList} from "@/api/projectManager_self";
import {hideLoading, showLoading} from "@/components/loading";
import NFTHeader from "@/components/NFTHeader";


const ColCompoundHome = ({maskVisible = false, setMaskVisible, spHeChengInfo = null}) => {
    const [data, setData] = useState([]);
    const [clickItem, setClickItem] = useState(null);
    const [selData, setSelData] = useState([]);

    useEffect(() => {
        maskVisible && loadData();
    }, [maskVisible])

    const loadData = () => {
        if (spHeChengInfo && spHeChengInfo.synthesizeInitVOS && spHeChengInfo.synthesizeInitVOS.length) {
            let arr = [];
            for (const item of spHeChengInfo.synthesizeInitVOS) {
                arr.push({imageBg: item.imageBg, id: item.collectionId, isHasVal: false, nftNo: ''})
            }
            setData([...arr])
        }
    }

    const topItemClick = (item) => {
        setClickItem(item);
    }

    const choiceItem = (item) => {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
            let item0 = data[i];
            if (item0.id === item.id) {
                item0.isHasVal = true;
                item0.imageBg = item.imageBg;
                item0.nftNo = item.nftNo;
            }
            if (item0.isHasVal) {
                arr.push(item0);
            }
        }
        setData([...data]);
        setSelData([...arr]);
    }

    const topView = () => {
        return data.map((item, index) => {
            return <div className={styles.top_item} key={index.toString()}
                        onClick={() => topItemClick(item)}>
                <img src={item.imageBg} alt=""/>
                {
                    !item.isHasVal ? <div className={styles.top_item_toast}>
                        <img src={require('../imgs/icon_increase.png')} alt=""/>
                        <p>待添加</p>
                    </div> : null
                }
            </div>
        })
    }

    if (!maskVisible) return null

    return (
        <Mask visible={maskVisible}>
            <div className={styles.container}>
                <NFTHeader midText={'藏品合成'} bgColor={'white'} leftClick={() => {
                    setMaskVisible(!maskVisible)
                }}/>
                <div className={styles.top}>{topView()}</div>
                <p className={`${styles.hc_btn} ${selData.length === 4 ? styles.can : ''}`} 
                    onClick={() => selData.length === 4 ? CompoundDialog.open(selData,spHeChengInfo.owningGroup) : null
                }>合成藏品</p>
                <div className={styles.bottom}>
                    <p className={styles.bottom_title}>请选择合成材料</p>
                    <SwiperComponent clickItem={clickItem} choiceItem={choiceItem} data={data}/>
                </div>
            </div>
        </Mask>
    )
}

const SwiperComponent = (props) => {
    const [titles, setTitles] = useState([
        // {title: '碎片1', id: 36, isChoice: true},
        // {title: '碎片2', id: 37, isChoice: false},
        // {title: '碎片3', id: 38, isChoice: false},
        // {title: '碎片4', id: 39, isChoice: false}
    ]);
    const swiperRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (props.data && !titles.length) {
            let arr = [];
            for (let i = 0; i < props.data.length; i++) {
                let item = props.data[i];
                arr.push({title: `碎片${i + 1}`, id: item.id, isChoice: i === 0, index: i})
            }
            setTitles([...arr]);
        }
    }, [props.data])

    useEffect(() => {
        if (props.clickItem) {
            for (const item of titles) {
                if (item.id === props.clickItem.id) {
                    titleClick(item)
                    break;
                }
            }
        }
    }, [props.clickItem])

    const titleClick = (item) => {
        if (item.isChoice) {
            return;
        }
        for (let i = 0; i < titles.length; i++) {
            let itemTem = titles[i];
            if (itemTem.id === item.id) {
                itemTem.isChoice = true;
                setCurrentIndex(itemTem.index)
            } else {
                itemTem.isChoice = false;
            }
        }
        setTitles([...titles]);
        setTimeout(() => {
            swiperRef.current?.swipeTo(item.index);
        }, 500);
    }

    const choiceItem = (item) => {
        props.choiceItem && props.choiceItem(item)
    }

    const getTitles = () => {
        return titles.map((item, index) => {
            return <div key={item.id}
                        className={`${styles.title_item} ${(index === titles.length - 1) ? '' : styles.borderright}`}
                        onClick={() => titleClick(item)}>
                <p className={item.isChoice ? styles.isChoice : ''}>{item.title}</p>
            </div>
        })
    }

    const getSwiperItems = () => {
        return titles.map((item, index) => {
            return <Swiper.Item key={item.id}>
                <MaterialsList focusStatus={currentIndex === index} id={item.id} choiceItem={choiceItem}/>
            </Swiper.Item>
        })
    }

    return <>
        <div className={styles.titles}>{getTitles()}</div>
        <Swiper
            style={{flex: 1}}
            direction='horizontal'
            indicator={() => null}
            ref={swiperRef}
            allowTouchMove={false}
            defaultIndex={0}>
            {getSwiperItems()}
        </Swiper>
    </>
}


const MaterialsList = (props) => {
    const {focusStatus} = props;
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const currentPage = useRef(1);
    const oldCurPage = useRef(0)
    const pageSize = useRef(15);

    useEffect(async () => {
        focusStatus && await loadData();
    }, [focusStatus])

    const loadData = async () => {
        if (oldCurPage.current === currentPage.current) {
            return;
        }
        oldCurPage.current = currentPage.current
        console.log('load--', focusStatus, props.index);
        try {
            showLoading();
            // const res = await getData();
            const res = await getImageList({
                collectionIds: [props.id],
                petNo: 'debrisSynthesisBefore',
                size: pageSize.current,
                current: currentPage.current,
            });
            hideLoading();
            const {code, msg, data} = res;
            if (code !== '000000') throw msg;
            let _list = data;
            if (currentPage.current > 1) {
                _list = list.concat(_list)
            }
            setList(_list)
            setIsLoad(true);
            setHasMore(data?.length >= pageSize.current);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const getData = () => {
        return new Promise((resolve, reject) => {
            resolve(mock)
        });
    }

    const itemClick = (item) => {
        if (item.isChoice) {
            return;
        }
        let selItem = null;
        for (let i = 0; i < list.length; i++) {
            let itemTem = list[i];
            if (itemTem.nftNo === item.nftNo) {
                itemTem.isChoice = true;
                selItem = itemTem;
            } else {
                itemTem.isChoice = false;
            }
        }
        setList([...list]);
        setTimeout(() => {
            if (selItem) {
                props.choiceItem && props.choiceItem({id: props.id, ...selItem});
            }
        }, 500)
    }

    const listItem = (item) => {
        return <div className={`${styles.item} ${item.isChoice ? styles.isChoiceItem : ''}`}
                    onClick={() => itemClick(item)}>
            <img src={item.imageBg} alt=""/>
            <p>{item.nftNo}</p>
        </div>
    }


    return <div className={styles.list}>
        <ListView
            dataSource={list}
            hasMore={hasMore}
            style={{paddingTop: 5}}
            renderItem={listItem}
            // onRefresh={() => {
            //     console.log('onRefresh')
            //     oldCurPage.current = 0;
            //     currentPage.current = 1;
            //     loadData(true);
            // }}
            onEndReached={async () => {
                currentPage.current += 1;
                await loadData(false);
            }}
            isLoad={isLoad}
        />
    </div>
}

const mock = {
    code: '000000',
    msg: 'success',
    data1: [],
    data: [
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 0,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 1,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 2,
        }, {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 3,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 4,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 5,
        }, {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 6,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 7,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 8,
        }, {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 6,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 7,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 8,
        },

    ]

}

export default ColCompoundHome;
