import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import CountDown from '../components/countDown';
import { Swiper, Toast } from 'antd-mobile';
import back from '@/assets/img/icon/back.png';
import share from '@/assets/img/icon/share.png';
import mainImg from '../imgs/huishou_bg.png';
import logo from '../imgs/logo.png';
import shareImg from '../imgs/parts-share.png';
import tipAnima from '../imgs/animate.gif';
import ScratchView from '../components/scratchView';
import GoodsList from '../components/goodsList';
import RulesModal from '../components/ruleModal';
import ShareModal from '../components/shareModal';
import {hideLoading, showLoading} from "@/components/loading";
import { queryDefCode, partsRecyclingInit, queryJackpotPoint } from "@/api/projectManager_self";
import appService from '@/utils/appService';
import mockApi from './mock.js';

const COUNT_ARR = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const shareUrl = `${window.location.origin}/partsRecycling/share`;

const PartsRecyclingHome = () => {
    const [userInfo, setUserInfo] = useState({});
    const [activityRules, setActivityRules] = useState(null);
    const [activityInfo, setActivityInfo] = useState({});
    const [goodsList, setGoodsList] = useState([]);
    const [pageStatus, setPageStatus] = useState('init');

    useEffect(() => {
        onDidFocus();
        getUserInfo();
        pageInit();
        getGoodsList();
    }, [])

    const getUserInfo = () => { //获取用户信息
        window.JsBridge.getDetailCurrentUserInfo().then((res) => {
            setUserInfo(res);
        })
    }

    const onDidFocus = () => {
        window.JsBridge.addListener().then(() => {
            getUserInfo();
            pageInit();
            onDidFocus();
        });
    }

    const realNameAuthen = () => {
        window.JsBridge.navigate('Verify');
    }

    // 获取活动规则
    const getActivityRules = async () => {
        try {
            if(activityRules) {
                RulesModal.open(activityRules);
                return;
            }
            showLoading();
            const res = await queryDefCode({codeType: 'activityRule', code: 'recovery202305Rule'});
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            const {data} = res;
            if (data.result && data.result.defCode) {
                const defCode = data.result.defCode;
                setActivityRules(defCode);
                RulesModal.open(defCode);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    // 活动初始信息
    const pageInit = async () => {
        try {
            showLoading();
            const res = await partsRecyclingInit();
            // const res = await mockApi.initMock();
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            setActivityInfo(res.data);
            setPageStatus('finished');
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const getGoodsList = async () => {
        try {
            const result = await appService('101342', {activityCode: 'recovery202305'});
            console.log('result=====>', result);
            if (result.status > 0) {
                setGoodsList(result.data);
            }
        } catch (error) {
            Toast.show(error || '请求失败');
        }
    }

    const jumpToRecord = () => {
        // if(!userInfo.ID || recyclingCount === 0) return;
        window.JsBridge.push("Web", { uri: `${window.location.origin}/partsRecycling/record` });
        clearAllTimer();
    }

    // 回收领500积分
    const recyclingPoints = () => {
        if(!isCanClick) return;
        window.JsBridge.push("Web", { uri: `${window.location.origin}/partsRecycling/recovery?type=1` });
        clearAllTimer();
    }

    // 押注2000积分
    const recyclingStake = () => {
        if(!isCanClick) return;
        window.JsBridge.push("Web", { uri: `${window.location.origin}/partsRecycling/recovery?type=2` });
        clearAllTimer();
    }

    const {
        date,
        drawTime,
        startTime,
        endTime,
        count,
        totalReTimes,
        recycledTimes,
    } = activityInfo;

    const currentTime = new Date(date?.replace(/-/g, "/")).getTime();
    const startT = new Date(startTime?.replace(/-/g, "/")).getTime();
    const endT = new Date(drawTime?.replace(/-/g, "/")).getTime();
    const recyclingCount = Number(totalReTimes)-Number(recycledTimes) >= 0 ? Number(totalReTimes)-Number(recycledTimes): 0;
    const isCanClick = !!userInfo.ID && recyclingCount > 0 && ((currentTime >= startT) && (currentTime < endT));

    if(process.env.NODE_ENV !== 'development' && pageStatus != 'finished') return null;

    return <div className='parts-recycling-home'>
        <div className="back-share">
            <img className='back-img' src={back} alt="" onClick={() => window.JsBridge.back()} />
            <img className='share-img'
                 src={share}
                 alt=""
                 onClick={() => {
                     ShareModal.open({shareUrl, posterImg: shareImg});
                 }}
            />
        </div>
        <div className="rules-authen">
            {userInfo.ID ? <div className="name-authen-no"></div>: <div className="name-authen" onClick={realNameAuthen}>实名认证</div>}
            <div className="activity-rules" onClick={() => getActivityRules()}>活动规则</div>
        </div>
        <div className="main-img"><img src={mainImg} alt="" /></div>
        <div className="game-container">
            <div className="count-down-record">
                <CountDown sysTime={date} endTime={drawTime} refreshData={pageInit}/>
                <div className="recycling-record" onClick={jumpToRecord}>
                    <span>回收记录</span>
                    <span>{'>'}</span>
                </div>
            </div>
            <NumberSwipers activityInfo={activityInfo}/>
            <div className="points-allocation-rules">
                <span className="allocation-left">20张奖券瓜分80%奖池积分</span>
                <span className="allocation-right">剩余奖券瓜分20%</span>
            </div>
            <div className="user-parts-info">
                <div className="parts-info">
                    <span>可回收配件:</span>
                    <span>{count}</span>
                    <span>个</span>
                </div>
                <div className="parts-info">
                    <span>剩余回收次数:</span>
                    <span>{recyclingCount}</span>
                    <span>次</span>
                </div>
                <div className="add-beer">加赠50箱申花啤酒</div>
            </div>
            {
                currentTime < endT ? 
                <div className="recycling-btn">
                    <div className={`recycling-points-btn ${isCanClick ? '': 'unavailable'}`} onClick={() => recyclingPoints()}></div>
                    <div className={`recycling-stake-btn ${isCanClick ? '': 'unavailable'}`} onClick={() => recyclingStake()}>
                        <img className='tip-animate' src={tipAnima} alt="" />
                    </div>
                </div>
                : <div className="recycling-end-btn"></div>
            }
        </div>
        <div className="scratch-goods-view">
            <ScratchView cardInfo={activityInfo} refresh={pageInit} userInfo={userInfo}/>
            <GoodsList goodsList={goodsList}/>
            <div className="parts-logo"><img src={logo} alt="" /></div>
        </div>
    </div>
}

const NumberSwipers = (props) => {
    const swiperArray = useRef([
        {obj: Swiper, id: 0, swiperRef: useRef(null)},
        {obj: Swiper, id: 1, swiperRef: useRef(null)},
        {obj: Swiper, id: 2, swiperRef: useRef(null)},
        {obj: Swiper, id: 3, swiperRef: useRef(null)},
        {obj: Swiper, id: 4, swiperRef: useRef(null)},
        {obj: Swiper, id: 5, swiperRef: useRef(null)},
        {obj: Swiper, id: 6, swiperRef: useRef(null)}]
    )
    const numRef = useRef();
    const timer = useRef()

    useEffect(() => {
        getPointsNum();
        startTimer();
        return () => {
            timer.current && clearInterval(timer.current);
        }
    }, [props.activityInfo])

    useEffect(() => {
        window.addEventListener('message', function (event) {
            const data = typeof event.data == 'string' ? JSON.parse(event.data) : event.data
            // console.log('---00', data)
            handleTimer(data);
        });
        // 这个监听的名字只能叫message
        document.addEventListener('message', function (event) {
            const data = typeof event.data == 'string' ? JSON.parse(event.data) : event.data
            // console.log('---11', data)
            handleTimer(data);
        });
    }, [])

    const getPointsNum = async () => {
      try {
        const res = await queryJackpotPoint();
        if(res.code != '000000') throw res.msg;
        let numTem = res.data.prizePool + '';
        numTem = numTem.replace(/\s+/g, '');
        if (numTem.length < 7) {
          let difValue = 7 - numTem.length;
          let bu0 = '';
          for (let i = 0; i < difValue; i++) {
              bu0 += '0';
          }
          numTem = bu0+numTem;
        }

        // window.JsBridge.print('numTem之后')
        console.log('numTem之后---', numTem);
        if (numRef.current === numTem){
            console.log('前后值一样,return');
            return
        }
        numRef.current = numTem;
        let data = [];
        for (let i = 0; i < numTem.length; i += 1) {
            data.push(Number(numTem.charAt(i)));
        }
        if (data.length === 7) {
            for (let i = 0; i < swiperArray.current.length; i++) {
                let item = swiperArray.current[i];
                let swiper = item.swiperRef;
                if (swiper) {
                    let num = data[i];
                    swiper.current.swipeTo(num);
                }
            }
        }
      } catch (error) {
        console.log(error);
      }
    }

    const handleTimer = (data) => {
        let isBackGround = data.isBackGround;
        if (!isBackGround) { //前台
            startTimer();
        } else { //后台
            timer.current && clearInterval(timer.current);
        }
    }

    const startTimer = () => {
        timer.current && clearInterval(timer.current);
        timer.current = setInterval(() => {
            getPointsNum();
        }, 10000);
    }

    return (
        <>
            <div className='jackpot-point'>
                <div className={'swiper-container'}>
                    {
                        swiperArray.current.map((item) => {
                            return <item.obj
                                ref={item.swiperRef}
                                key={item.id.toString()}
                                defaultIndex={0}
                                allowTouchMove={false}
                                indicator={() => null}
                                loop={true}
                                direction='vertical'>
                                {COUNT_ARR.map((item1) => <Swiper.Item
                                        key={item1.toString()} style={{marginLeft: item.id <= 5 ? '1px' : ''}}>
                                        <div className='point-item'>{item1}</div>
                                    </Swiper.Item>
                                )}
                            </item.obj>
                        })
                    }
                </div>
            </div>
        </>
    )
}

const clearAllTimer = () => { //清除所有的定时器
    let end = setInterval(function () {
    }, 3000);
    for (let i = 1; i <= end; i++) {
        i && clearInterval(i);
    }
}


export default PartsRecyclingHome;
