import { previewProtocol } from "@/utils/publicFun";
import React from "react"
import "./index.scss"

const SecondCreationAgreement = () => {
  window.JsBridge.setTitle("二次创作及商业使用权说明");
  window.scroll(0, 0)

  return (
    <div className="secondCreationAgreement">
      <div className="cell">
        <div className="title"></div>
        <div className="part">
          {'1. '}
          <span className="subTitle">
            {' 持有人转让（转赠）数字藏品后不得再进行二次创作，但不影响此前已经发布的二创作品的继续传播。'}
          </span>
        </div>
        <div className="part">
          {'2. '}
          <span className="subTitle">
            {' 二次创作应符合法律法规、国家政策及传播平台规则，不得有损社会公序良俗或第三方的合法权益，因二次创作产生的一切纠纷、损失及责任均由持有人负责。'}
          </span>
        </div>
        <div className="part">
          {'3. '}
          <span className="subTitle">
            {' 商业使用权指将此IP用于实体产品的印刷，或是宣传作用。为了保证IP的发展，若您需要进行商业使用，请联系绿地G优官方及Ape World社群，经过同意后才可行使权力。'}
          </span>
        </div>
        <div className="title" style={{ marginTop: '50px' }}>{'特别提醒'}</div>
        <div className="part">
          <span className="tipTitle">
            {'您还应确认并遵守 '}
            {<span style={{ color: '#CDAC5B', }} onClick={() => {
              previewProtocol(4)
            }}>
              {'《绿地数字藏品转增须知》'}
            </span>}
            {'、'}
            {<span style={{ color: '#CDAC5B', }} onClick={() => {
              previewProtocol(5)
            }}>
              {'《绿地数字藏品许可及服务协议》'}
            </span>}
          </span>
        </div>
      </div>
    </div>

  )
}
export default SecondCreationAgreement;
