import React, {useEffect, useState, useRef} from 'react'
import styles from './styles.module.scss'
import {Mask, Toast} from 'antd-mobile'
import CommonView from '../commonView'
import ListView from "@/components/AntListView"
import dayjs from 'dayjs'
import {metaRecovery24_0116} from "@/constant";
import {getPurchaseRecord} from "@/api/projectManager_self";
import {hideLoading, showLoading} from "@/components/loading";
//购买记录
const ListItem = (item) => {
    return <div className={styles.list_item}>
        <div className={styles.time}>
            <span>购买时间</span>
            <span>{item.createdAt ? dayjs(item.createdAt).format('YYYY.MM.DD HH:mm:ss') : ''}</span>
        </div>
        <div className={styles.recyc_ele}>
            <span>张数</span>
            <span>{item.num}</span>
            <span>实付</span>
            <span>￥{item.userCash}</span>
        </div>
        <div className={styles.order_info}>
            <span>订单编号：{item.outTradeNo}</span>
            <span>获取元素x{item.num}</span>
        </div>
    </div>
}

const RecoredList = ({visible}) => {
    const [listData, setListData] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const currentPage = useRef(1);
    const pageSize = useRef(10);

    useEffect(() => {
        visible && getRecoredList()
    }, [visible])

    const getRecoredList = async (isRefresh = true) => {
        try {
            showLoading();
            if (isRefresh) {
                currentPage.current = 1;
            } else {
                currentPage.current = currentPage.current + 1;
            }
            const params = {
                currentPage: currentPage.current,
                pageSize: pageSize.current,
                activityCode: metaRecovery24_0116,
            }
            console.log(params)
            const res = await getPurchaseRecord(params)
            hideLoading();
            if (res.code !== '000000') throw res.msg
            let {records = []} = res?.data;
            if (currentPage.current === 1) {
                setListData(records)
            } else {
                setListData([...listData, ...records]);
            }
            setIsLoad(true);
            setHasMore(records.length >= pageSize.current);
        } catch (error) {
            hideLoading();
            console.log(error)
            Toast.show(error)
        }
    }

    return <div className={styles.list_container}>
        <ListView
            dataSource={listData}
            hasMore={hasMore}
            renderItem={ListItem}
            onEndReached={async () => {
                await getRecoredList(false);
            }}
            isLoad={isLoad}
            customStyle={{
                imgStyle: {width: 140, height: 140},
                textStyle: {color: '#FFFFFF', fontSize: 14},
            }}
        />
    </div>
}

const PurchaseRecords = ({onClose, visible}) => {
    return <Mask className={styles.purchase_mask} visible={visible}>
        <CommonView onClose={onClose} title="购买记录"><RecoredList visible={visible}/></CommonView>
    </Mask>
}

export default PurchaseRecords
