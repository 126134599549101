/*
    绿地Ape World数字藏品
*/

import React, { useState, useEffect, useRef } from "react";
import Rotate3D from "@/components/Rotate3D";
import "./index.scss";

const WordWhatCard = (props)=> {

    const {dataSouce={}} = props

    const sources = [
        {
            source:require("@/assets/img/variationApe/guess-front-back.png"),
            direction: 'front'
        },
        {
            source:require("@/assets/img/variationApe/guess-front-back.png"),
            direction: 'back'
        },
        {
            source:require("@/assets/img/variationApe/guess-left-right.png"),
            direction: 'left'
        },
        {
            source:require("@/assets/img/variationApe/guess-left-right.png"),
            direction: 'right'
        },
        {
            source:require("@/assets/img/variationApe/guess-up-down.png"),
            direction: 'up'
        },
        {
            source:require("@/assets/img/variationApe/guess-up-down.png"),
            direction: 'down'
        }
    ]

    return (
        <div className="WordWhatCard">
            <span>{ dataSouce.title || '绿地变异无聊猿数字藏品'}</span>
            <div className="ape_world_content">
                <div className="content_display">
                    <Rotate3D sources={sources} />
                </div>
            </div>
        </div>
    )
}

export default WordWhatCard;
