import React, {useState, useEffect, useRef} from 'react'
import NFTHeader from "@/components/NFTHeader"
import styles from './styles.module.scss'
import {Toast} from "antd-mobile";
import clickThrottle from "@/utils/clickThrottle";
import ListView from "@/components/AntListView";
import {hideLoading, showLoading} from "@/components/loading";
import {queryAdvertisementByType} from "@/api/projectManager_self";


const ScannerModal = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        props.scannerVisible && loadData();
    }, [props.scannerVisible])

    const loadData = async () => {
        try {
            const res = await queryAdvertisementByType({type: 23});
            if (res.code !== '000000') throw res.msg;
            console.log('res---',JSON.stringify(res));
            setData(res.data.tinyPicUrl || {});
        } catch (error) {
            Toast.show(error);
        }
    }

    return (
        <div className={styles.container}>
            <NFTHeader midText="扫码进群" bgColor="white" leftClick={() => props.leftClick()}/>
            <div className={styles.container1} style={{paddingTop:'0px'}}>
                <img src={require('../../imgs/bg_scanner.png')} alt="" className={styles.bg_img}/>
                <div className={styles.content}>
                    <img src={data} alt=""/>
                    <p>截图保存，识别二维码加入</p>
                </div>
            </div>

        </div>
    )
}


export default ScannerModal;
