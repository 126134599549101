import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { Tabs, Toast } from 'antd-mobile';
import DropdownSelect from "./components/dropdownSelect";
import { LevelOptions, CateOptions, getApeCate, getLeaseTerm } from "../utils";
import ListView from "@/components/AntListView";
import LeaseItem from "./components/leaseItem";
import { hideLoading, showLoading } from "@/components/loading";
import MyModal from "../jewelLand/modal";
import ConfirmCancelLease from './components/confirmCancelLease';
import LeaseOutDetail from "./components/leaseOutDetail";
import { userRentalList, cancelAbleRental } from '@/api/guland';

const MyLeaseOut = () => {
  const [list, setList] = useState([])
  const [currentTab, setCurrentTab] = useState('0')
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [categorary, setCategorary] = useState(0)
  const [landLevel, setLandLevel] = useState(0)
  const [leaseTerm, setLeaseTerm] = useState(0)
  const [batchManage, setBatchManage] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [daysOptions, setDaysOptions] = useState([])
  const [apeCate, setApeCate] = useState([])
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const tipRef = useRef(null);
  const domRef = useRef(null);

  useEffect(() => {
    getDaysOptions()
    getApeCateOptions()
  }, [])

  useEffect(() => {
    setBatchManage(false);
    setSelectedList([]);
    getList(true);
  }, [categorary, leaseTerm, landLevel, currentTab])

  useEffect(() => {
    getDaysOptions()
    setCategorary(0);
    setLandLevel(0);
    setLeaseTerm(0);
    setList([])
  }, [currentTab])

  const getList = async (isRefresh, loading = true) => {
    try {
      loading && showLoading()
      if(isRefresh) {
        currentPage.current = 1;
        domRef.current?.scroll(0, 0)
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
        blockLevel: landLevel === 0 ? '': landLevel,
        rentalPeriod: leaseTerm === 0 ? '': leaseTerm,
        collectionType: categorary,
        monkeyType: landLevel === 0 ? '': landLevel,
        type: Number(currentTab),
        rentalType: 1,
      }
      const res = await userRentalList(params)
      loading && hideLoading()
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setList(res?.data?.list || [])
      } else {
        setList([...list, ...res?.data?.list]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.list?.length >= pageSize.current)
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const getDaysOptions = async () => {
    try {
      const res = await getLeaseTerm();
      if (currentTab === '1') {
        const newList = res?.filter(item => item.label !== "永久")
        setDaysOptions(newList);
      } else {
        setDaysOptions(res);
      }
    } catch (error) {
      Toast.show(error);
    }
  };

  const getApeCateOptions = async () => {
    try {
      const res = await getApeCate();
      setApeCate(res)
    } catch (error) {
      Toast.show(error);
    }
  };

  const onSelect = (item) => {
    const newList = [...selectedList];
    const selectId = newList.findIndex(i => i.nftNo === item.nftNo)
    if(selectId > -1) {
      newList.splice(selectId, 1);
    } else {
      newList.push(item);
    }
    console.log('newList', newList)
    setSelectedList(newList);
  }

  const cancelBatchManege = () => {
    setBatchManage(false);
    setSelectedList([]);
  }

  const onBatchBtn = () => {
    if (!batchManage) {
      setBatchManage(true);
      return
    }
    if (selectedList.length === 0) {
      Toast.show('请选择要操作的选项')
      return
    }
    cancelTip()
  }

  const cancelTip = (instance, item) => {
    tipRef.current = MyModal.show({
      content: <ConfirmCancelLease 
        onClose={() => tipRef.current?.close()} 
        onConfirm={async () => {
          await cancelLease(instance, item)
        }}
      />,
    })
  }

  const cancelLease = async (instance, item) => {
    try {
      showLoading()
      const list = instance ? [item.nftNo] : selectedList.map(i => i.nftNo)
      const res = await cancelAbleRental({nftNoList: list})
      hideLoading()
      if (res.code === '000000') {
        instance && instance.close()
        tipRef.current?.close()
        cancelBatchManege()
        Toast.show('取消成功')
        await getList(true, false)
      } else if (res.code === '800025') {
        cancelBatchManege()
        instance && instance.close()
        tipRef.current?.close()
        Toast.show(res.msg)
        await getList(true, false)
      } else {
        throw res.msg
      }
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const onItemClick = (item) => {
    const ModalInstance = MyModal.show({
      content: <LeaseOutDetail 
        onClose={() => ModalInstance?.close()} 
        onCancel={() => cancelTip(ModalInstance, item)}
        showInfo={currentTab === '1'}
        infoData={item}
        onEdit={() => getList(true, false)}
      />,
    })
  }

  const onCateClick = (item) => {
    setCategorary(item.value)
    setLandLevel(0)
  }

  return <div className={styles.my_lease_out}>
    <div className={styles.tabs_box}>
      <Tabs
        style={{
          '--active-line-color': '#3AA700',
          '--active-title-color': '#3AA700',
          fontFamily: 'YueGenYouShangYueBoHei',
          color: '#874D2B',
        }}
        onChange={setCurrentTab}
      >
        <Tabs.Tab title="未出租" key="0"></Tabs.Tab>
        <Tabs.Tab title="已出租" key="1"></Tabs.Tab>
      </Tabs>
    </div>
    <div className={styles.select_box}>
      <div className={styles.selects}>
        <DropdownSelect 
          title="分类" 
          defaultValue={categorary} 
          options={CateOptions} 
          onChange={onCateClick}
        />
        {categorary !== 0 ? <DropdownSelect 
          title="等级" 
          options={categorary === 1 ? apeCate: LevelOptions} 
          onChange={(item) => setLandLevel(item.value)}
          defaultValue={landLevel}
        />: null}
        <DropdownSelect 
          title="租期" 
          options={daysOptions} 
          onChange={(item) => setLeaseTerm(item.value)}
          defaultValue={leaseTerm}
        />
      </div>
      <div className={`${styles.batch_manage_box} ${currentTab === '0' ? styles.show : styles.hide}`}>
        {batchManage && <div className={styles.cancel_batch_manage} onClick={cancelBatchManege}>退出</div>}
        <div className={`${styles.batch_manage} ${batchManage ? styles.cancel_lease : ''}`} onClick={onBatchBtn}></div>
      </div>
    </div>
    <div className={styles.lease_list} ref={domRef}>
      {
        <ListView
          dataSource={list}
          hasMore={hasMore}
          style={{paddingTop: 5}}
          renderItem={(item) => <LeaseItem 
            item={item} 
            showChoose={batchManage}
            showTime={currentTab === '1'}
            onClick={() => onItemClick(item)}
            onSelect={onSelect}
            isLand={item.type === 2}
          />}
          onEndReached={async () => {
            await getList(false, false);
          }}
          isLoad={isLoad}
          customStyle={{imgStyle: {width: 150, height: 150}}}
        />
      }
    </div>
  </div>
}

export default MyLeaseOut;