import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import './putGuessBondDialog.scss';
import {Mask, Toast} from 'antd-mobile';
import {hideLoading, showLoading} from "@/components/loading";
import {getSignNum, participateQuizzes} from "@/api/projectManager_self";

import btnJian from '../../imgs/btn_jian_nor.png';
import btnJia from '../../imgs/btn_jia_nor.png';
import btnJianDis from '../../imgs/btn_jian_dis.png'
import btnJiaDis from '../../imgs/btn_jia_dis.png'

const PutGuessBondDialog = ({onClose, data,callback}) => {
    const [visible, setVisible] = useState(true);
    const [allowNum, setAllowNum] = useState(0);
    const [optionACount, setOptionACount] = useState(0);
    const [optionBCount, setOptionBCount] = useState(0);

    useEffect(() => {
        loadData();
    }, []);

    const getRuChaneJuan = () => {//获取入场券
        setVisible(false);
        onClose && onClose();
        setTimeout(()=>{
            callback && callback()
        },1000)
    }

    const loadData = async () => {
        try {
            showLoading()
            const res = await getSignNum({session: data.session, round: data.round});
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            setAllowNum(res.data?.allowNum);
        } catch (error) {
            Toast.show(error);
        }
    }

    const handleClick = async (type) => {
        if (type === 0) {
            setVisible(false);
            onClose && onClose();
        } else if (type === 1) {
            try {
                if (optionACount === 0 && optionBCount === 0) {
                    return
                }

                showLoading()
                let optionListTem = [];
                if (optionACount > 0 && data?.exerciseProblemsList[0]) {
                    optionListTem.push({amount: optionACount, option: data?.exerciseProblemsList[0].answerId})
                }
                if (optionBCount > 0 && data?.exerciseProblemsList[1]) {
                    optionListTem.push({amount: optionBCount, option: data?.exerciseProblemsList[1].answerId})
                }

                const res = await participateQuizzes({
                    "session": data.session,
                    "round": data.round,
                    "optionList": optionListTem,
                })
                hideLoading();
                if (res.code !== '000000') throw res.msg;
                setTimeout(()=>{
                    Toast.show('已参与竞猜');
                },100)
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    setVisible(false);
                    onClose && onClose();
                    callback && callback(1)
                }, 2500)
            } catch (error) {
                Toast.show(error);
            }
        }
    }

    const reduce = (index) => {
        if (index === 0) { //A
            if (optionACount <= 0) {
                return;
            }
            let optionACountTem = optionACount - 1;
            setOptionACount(optionACountTem)
        } else if (index === 1) { //B
            if (optionBCount <= 0) {
                return;
            }
            let optionBCountTem = optionBCount - 1;
            setOptionBCount(optionBCountTem)
        }
    }

    const add = (index) => {
        if ((optionBCount + optionACount) >= allowNum) {
            return;
        }
        if (index === 0) { //A
            let optionACountTem = optionACount + 1;
            setOptionACount(optionACountTem)
        } else if (index === 1) { //B
            let optionBCountTem = optionBCount + 1;
            setOptionBCount(optionBCountTem)
        }
    }

    return <Mask className='put-guess-bond-mask' visible={visible} onMaskClick={() => handleClick(0)}>
        <div className='put-modal-common'>
            <div className={'put-modal-container'}>
                <p className={'put-guess-title'}>投入券</p>
                <div className={'juan_container'}>
                    <span>入场券：</span>
                    <span>{allowNum || 0}</span>
                    <span onClick={() => getRuChaneJuan()}>获取入场券</span>
                </div>

                <div className={'option_a'}>
                    <p>A</p>
                    <div className={'option_a_a'}>
                        <span>投</span>
                        <img src={optionACount > 0 ? btnJian : btnJianDis} alt="" onClick={() => reduce(0)}/>
                        <span>{optionACount}</span>
                        <img src={((optionACount + optionBCount) >= allowNum) ? btnJiaDis : btnJia}
                             alt="" onClick={() => add(0)}/>
                        <span>张</span>
                    </div>
                </div>
                <div className={'option_a'}>
                    <p>B</p>
                    <div className={'option_a_a'}>
                        <span>投</span>
                        <img src={optionBCount > 0 ? btnJian : btnJianDis} alt="" onClick={() => reduce(1)}/>
                        <span>{optionBCount}</span>
                        <img src={((optionACount + optionBCount) >= allowNum) ? btnJiaDis : btnJia} alt=""
                             onClick={() => add(1)}/>
                        <span>张</span>
                    </div>
                </div>
                <div className={'guess_btn_container'}>
                    <p onClick={() => handleClick(0)}>取消</p>
                    <p onClick={() => handleClick(1)}>确认</p>
                </div>
            </div>
        </div>
    </Mask>
}

PutGuessBondDialog.open = (data,callback) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<PutGuessBondDialog onClose={close} data={data} callback={callback}/>, div);
}

export default PutGuessBondDialog;
