import React, { useState, useEffect, useRef } from 'react';
import { Popup, Toast, Swiper } from "antd-mobile";
import styles from "./spaceExtend.module.scss";
import close from '@/assets/img/landfi/icon_del.png';
import mode2_close from '@/assets/img/landfi/landgy/icon_del.png';
import receivedIcon from '@/assets/img/landfi/received-icon.png';
import MyModal from '../jewelLand/modal';
import ConfirmOrder from './spaceOrder';
import { spaceExtendInfo } from '@/api/guland';
import { hideLoading, showLoading } from '@/components/loading';
import classNames from 'classnames';

const SpaceExtendModal = (props) => {
  const {visible, mode, onClose, container, refresh} = props;
  const [spaceData, setSpaceData] = useState({})
  const [currentSpace, setCurrentSpace] = useState({spaceLevel: 1})
  const bigRef = useRef(null)
  const litterRef = useRef(null)
  const lineRef = useRef(null)
  const swiperRef = useRef(null)

  useEffect(() => {
    if (!visible) return
    setCurrentSpace({spaceLevel: 1})
    getSpaceExtendInfo()
  }, [visible])

  const getSpaceExtendInfo = async () => {
    try {
      showLoading()
      const params = {
        spaceType: +mode === 2 ? 1 : 0,//空间类型 0农业  1工业
      }
      const res = await spaceExtendInfo(params)
      hideLoading()
      if (res.code !== '000000') throw res.msg
      setSpaceData(res.data || {})
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const onExtendSpace = () => {
    if (currentSpace.spaceLevel <= spaceData.hasSpaceLevel) return
    if (currentSpace.spaceLevel - spaceData.hasSpaceLevel > 1) return Toast.show('请先扩展前置空间')
    const ModalInstance = MyModal.show({
      content: <ConfirmOrder 
        mode={mode}
        onClose={() => ModalInstance?.close()} 
        spaceLevel={currentSpace.spaceLevel}
        paySuccessCB={refresh}
      />,
      getContainer: container,
    })
    setTimeout(() => {
      onClose()
    }, 500);
  }

  return <Popup
    visible={visible}
    position='right'
    destroyOnClose
    maskStyle={{background: 'transparent'}}
    bodyStyle={{
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      height: '100%',
      background: 'transparent',
      paddingLeft: 0,
    }}
  >
    <div className={classNames(styles.space_extend)}>
      <div className={styles.space_extend_title}>
        <span></span>
        <span>空间等阶</span>
        <img src={mode == 2 ? mode2_close: close} alt="" onClick={onClose}/>
      </div>
      <div className={styles.swiper_list}>
        <Swiper 
          indicator={() => null} 
          ref={swiperRef}
          defaultIndex={0}
          onIndexChange={(index) => {
            const item = spaceData?.spaceList[index]
            console.log(currentSpace)
            setCurrentSpace(item)
          }}
        >
          {
            spaceData?.spaceList?.length > 0 && spaceData?.spaceList.map((item, index) => (
              <Swiper.Item key={index}>
                <div className={styles.space_item}>
                  {currentSpace.spaceLevel === item.spaceLevel ? <div className={styles.space_item_big} ref={bigRef}>
                    <div className={styles.space_item_out}>
                      <div className={classNames(styles.connect_line, mode==2 ? styles.mode2: '')} ref={lineRef}></div>
                      <div className={classNames(styles.space_item_container, mode==2 ? styles.mode2: '')}>
                        <div className={classNames(styles.space_item_inner, mode==2 ? styles.mode2: '')}>{item.spaceLevel}</div>
                      </div>
                      <div className={classNames(styles.connect_line, mode==2 ? styles.mode2: '')} ref={lineRef}></div>
                    </div>
                    {item.spaceLevel <= spaceData.hasSpaceLevel ? <div className={styles.received_tag_big}></div>: null}
                  </div>: <div className={styles.space_item_litter} ref={litterRef}>
                    <div className={styles.space_item_out}>
                      <div className={classNames(styles.connect_line, mode==2 ? styles.mode2: '')} ref={lineRef}></div>
                      <div className={classNames(styles.space_item_container, mode==2 ? styles.mode2: '')}>
                        <div className={classNames(styles.space_item_inner, mode==2 ? styles.mode2: '')}>{item.spaceLevel}</div>
                      </div>
                      <div className={classNames(styles.connect_line, mode==2 ? styles.mode2: '')} ref={lineRef}></div>
                    </div>
                    {item.spaceLevel <= spaceData.hasSpaceLevel ? <div className={styles.received_tag_litter}></div>: null}
                  </div>}
                </div>
              </Swiper.Item>
            ))
          }
        </Swiper>
      </div>
      <div className={classNames(styles.space_land_max, mode==2 ? styles.mode2: '')}>土地放置上限：{currentSpace.spaceLevel*9}</div>
      <div className={styles.btn_box}>
        {currentSpace.spaceLevel <= spaceData.hasSpaceLevel ? <div className={styles.received_btn}>
          <img src={receivedIcon} alt="" />
          <span>已扩展</span>
        </div>:<div className={styles.pay_btn} onClick={onExtendSpace}>
          <span style={{fontFamily: 'PingFang SC'}}>¥</span>
          {currentSpace.spacePrice} 扩展空间
        </div>}
      </div>
    </div>
  </Popup>
}

export default SpaceExtendModal;