import React, {Fragment,useState} from 'react'
import styles from './styles.module.scss'
import {Mask} from 'antd-mobile'
import ReactDOM from "react-dom";
import MyElements from "@/view/eleCompound/components/myElements";


const PaySuccModal = ({onClose, data = [], }) => {
    const [visible, setVisible] = useState(true);

    const returnDiv = () => {
        return (
            <>
                <div className={`${styles.face} ${styles.front}`}>
                    <img src={require("../../imgs/el_front.png")} alt=""/>
                </div>
                <div className={`${styles.face} ${styles.rear}`}>
                    <img src={require("../../imgs/el_front.png")} alt=""/>
                </div>
                <div className={`${styles.face} ${styles.left}`}>
                    <img src={require("../../imgs/el_right.png")} alt=""/>
                </div>
                <div className={`${styles.face} ${styles.right}`}>
                    <img src={require("../../imgs/el_right.png")} alt=""/>
                </div>
                <div className={`${styles.face} ${styles.up}`}>
                    <img src={require("../../imgs/el_top.png")} alt=""/>
                </div>
                <div className={`${styles.face} ${styles.down}`}>
                    <img src={require("../../imgs/el_top.png")} alt=""/>
                </div>
            </>
        )
    }

    return <Mask className={styles.recycling_mask} destroyOnClose={true} visible={visible}>
        <div className={styles.container}>
            <div className={styles.view_header}>
                <span/>
                <h3>支付成功</h3>
                <span/>
            </div>
            <div className={styles.stage}>{returnDiv()}</div>
            <div className={styles.animation}>
                <div className={styles.imgs}>
                    <div className={`${styles.images1} ${data.length < 8 ? styles.isCenter : ''}`}>
                        {data &&
                            data.map((item, index) => (
                                <img src={item} alt="" key={index.toString()}/>
                            ))}
                    </div>
                </div>
                <p className={styles.btn} onClick={
                    () => {
                        onClose();
                        setVisible(false);
                        console.log('onClose')
                        let timer = setTimeout(() => {
                            clearTimeout(timer);
                            MyElements.open();
                        }, 1000)
                    }}>查看元素</p>
            </div>
        </div>
    </Mask>
}

PaySuccModal.open = (data) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => div.remove();
    ReactDOM.render(<PaySuccModal
        data={data}
        onClose={close}
    />, div);
}

export default PaySuccModal
