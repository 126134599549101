import React, {useState, useEffect, useRef} from "react";
import {Input, TextArea, Toast} from "antd-mobile";
import DonationModal from "@/components/donationModal";
import {useNavigate} from "react-router-dom";
import {
    checkDonationInfo,
    checkName,
    checkWalletAddress,
    getVerificationCode,
    queryUserMobile
} from '@/api/projectManager_self';
import qs from 'query-string';
import "./style.scss";
import {showLoading, hideLoading} from '@/components/loading';
import gouXuan from '@/assets/img/collection/gou-xuan.png';
import weiGouXuan from '@/assets/img/collection/wei-gou-xuan.png';
import {isArray, isEmptyString, isString, previewProtocol} from '@/utils/publicFun';
import {Swiper, SwiperSlide} from "swiper/react"
import {EffectCards} from "swiper"
import "swiper/css/bundle"
import "swiper/css/effect-cards"
import NFTHeader from "@/components/NFTHeader";
import PwdModal from "@/view/donationInfo/component/pwdModal";
import DonationSuccess from "@/view/donationSuccess";


const DonationInfo = () => {
    let {nftNo, nftImage, targetNftNo, imageDetailList,} = qs.parse(window.location.search);
    if (!!imageDetailList) {
        imageDetailList = JSON.parse(imageDetailList)
    }
    const navigate = useNavigate();
    const [blockAddress, setBlockAddress] = useState('');

    const [modalVisible, setModalVisible] = useState(false);

    const [kbVisible, setKbVisible] = useState(false);

    const [errorShow, setErrorShow] = useState(false);
    const [checkProtocol, setCheckProtocol] = useState(false);

    const surname = useRef('');
    const uuid = useRef('');
    const [swiperIndex,setSwiperIndex] = useState(0);

    const isAvailable = !isEmptyString(blockAddress)  && checkProtocol;

    const confirmDonation = async () => {
        try {
            if (!isAvailable) return;
            showLoading();
            const {code, msg, data} = await checkWalletAddress({
                userWalletAddress: blockAddress,
            });
            hideLoading();
            if (code !== '000000') throw msg;
            uuid.current = data.uuid;
            surname.current = data.userName;
            setModalVisible(true);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const onChange = (text) => {
        setBlockAddress(text)
    }

    const onSlideChange = (swiper)=>{
        setSwiperIndex(swiper.activeIndex)
    }

    const getNftNo = ()=>{
        if (imageDetailList.length>=swiperIndex){
            return imageDetailList[swiperIndex].nftNo
        }
        return  '';
    }

    return (
        <div className="donation-info">
            <NFTHeader midText={'转赠信息填写'} bgColor={'white'}/>
            {!!imageDetailList && isArray(imageDetailList) && !!imageDetailList?.length &&
                <div className="img-box">
                    <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]} className="mySwiper"
                    onSlideChange={(swiper)=>onSlideChange(swiper)}>
                        {imageDetailList?.map((item, index) => (
                            <div  key={index.toString()} >
                                <SwiperSlide style={{background:'#F8F8F8'}}>
                                    <img src={item.imageUrl}/>
                                </SwiperSlide>
                            </div>
                        ))}
                    </Swiper>
                    <p className={'img_des'}>{getNftNo()}</p>
                </div>
            }

            <div className={'textArea_container'}>
                <TextArea
                    placeholder='请输入/粘贴被转赠者区块链地址'
                    autoSize={{minRows: 4, maxRows: 6}}
                    style={{'--font-size': '15px', '--placeholder-color': '#9E9E9E'}}
                    onChange={(e) => onChange(e)}/>
            </div>

            <div className={`confirm-btn ${isAvailable ? 'available' : ''}`} onClick={confirmDonation}>确认赠与</div>
            <div className="protocal-box">
                <div className="check" onClick={() => setCheckProtocol((pre) => !pre)}><img
                    src={checkProtocol ? gouXuan : weiGouXuan} alt=""/></div>
                <div className="protocol-name">
                    <span>我已阅读并同意</span>
                    <span onClick={() => previewProtocol(4)}>《绿地数字藏品转赠须知》</span>
                    <span onClick={() => previewProtocol(5)}>《绿地数字藏品许可及服务协议》</span>
                </div>
            </div>
            <DonationModal
                title="确认转赠"
                content="转赠后该藏品所有权益将一并转移且不可撤销，确认转赠当前藏品？"
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                leftBtnText={'取消'}
                rightBtnText={'确认转赠'}
                rightBtnCB={()=>setKbVisible(true)}
                errorShow={errorShow}
            />
            <PwdModal
                visible={kbVisible}
                uuid={uuid.current}
                nftNo={nftNo}
                onClose={() => setKbVisible(false)}/>
        </div>
    );
};
export default DonationInfo;
