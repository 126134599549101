/*
    3D水平旋转动画
    direction: 'front'|'back'|'left'|'right'|'up'|'down'
*/
import React, { useEffect, useState } from "react";
import "./index.scss";

const Directions = ['front', 'back', 'left', 'right', 'up', 'down']

const Rotate3D = (props)=> {
    // 支持以下两种方式
    const {
        sources = [
            require("@/assets/img/ape-world/box2.png"),
            require("@/assets/img/ape-world/box2.png"),
            require("@/assets/img/ape-world/box56.png"),
            require("@/assets/img/ape-world/box56.png"),
            require("@/assets/img/ape-world/box3.png"),
            require("@/assets/img/ape-world/box3.png"),
        ],
        /*
        sources = [
            {
                source:require("@/assets/img/ape-world/box2.png"),
                direction: 'front',
                style: {}
            },
            {
                source:require("@/assets/img/ape-world/box2.png"),
                direction: 'back'
            },
            {
                source:require("@/assets/img/ape-world/box56.png"),
                direction: 'left'
            },
            {
                source:require("@/assets/img/ape-world/box56.png"),
                direction: 'right'
            },
            {
                source:require("@/assets/img/ape-world/box3.png"),
                direction: 'up'
            },
            {
                source:require("@/assets/img/ape-world/box3.png"),
                direction: 'down'
            }
        ]
        */
    } = props

    const [list,setList] = useState([])

    useEffect(()=>{
        const items = sources.map((item,index)=> {
            if(Directions.length > index) {
                if(typeof item == 'object') {
                    return item
                }
                return {
                    source: item,
                    direction:Directions[index]
                }
            }
            return null
            
        })
        setList(items)
    },[sources])

    return (
        <div className="rotate-3d" style={props.style}>
            {
                list.map((item,index) => {
                    return (
                        <div className={`face-6 ${item.direction}`} 
                            style={item.style?item.style:{}} 
                            key={index}
                        >
                            {
                                typeof item.source == 'string' ?
                                <img src={item.source} alt="" />:
                                typeof item.source == 'function' ?
                                item.source():
                                item.source
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Rotate3D;