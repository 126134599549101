import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import "./index.scss"
import {guess_getBond_show} from "@/constant";

const Buy30Stock = (props) => {
    const navigate = useNavigate()

    useEffect(() => {
        console.log('props.session---',props.session);
    }, [])

    const handleClick = () => {
        const paySuccessPage = `/guessingActy/getCoupon?currentIndex=1&session=${props.session}`
        navigate(`/pay?productId=1&activityCode=CCL&paySuccessPage=${encodeURIComponent(paySuccessPage)}`);
        localStorage.setItem(guess_getBond_show, '-1');
    }

    return (
        <div className={'buy_30_container'}>
            <img src={require('../../../imgs/img_rcq2.png')} alt=""/>
            <p>{'新用户首次购买入场券买一送一\n参与竞猜，首次淘汰可得史诗款无聊猿！'}</p>
            <div className={'buy_30_duihuan_btn'} onClick={handleClick}>购买入场券</div>
        </div>
    )
}

export default Buy30Stock;
