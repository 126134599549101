import React from "react"
import "./index.scss";
import error from '@/assets/img/as_hot.png';

const Error = ({ context }) => {
  const bridge = window.JsBridge

  if (window.location.pathname === "/serverErr") {
    bridge.setTitle("稍后重试")
  }
  return (
    <div className="error">
      <img src={error} alt="" />
      <span className="error_context">{context || "404"}</span>
    </div>
  )
}

export default Error
