import React, {useState, useRef, useEffect} from 'react';
import styles from "./styles.module.scss";
import {Swiper, Tabs} from "antd-mobile";
import EleHCModal from "@/view/eleCompound/components/eleHCModal";
import Empty from "@/components/empty";
import {getEleList, getTimeConfig} from "@/view/eleCompound/utils";

const EleList = ({focusStatus, data = [], selectChildImg}) => {
    const [list, setList] = useState(data);
    const itemClick = (item) => {
        for (const item0 of list) {
            if (item.elementId === item0.elementId) {
                item0.isChoice = !item0.isChoice;
                if (item0.isChoice) {
                    selectChildImg && selectChildImg(item0, 'add')
                } else {
                    selectChildImg && selectChildImg(item0, 'reduce')
                }
            } else {
                item0.isChoice = false;
            }
        }
        setList([...list])
    }

    if (!focusStatus) {
        return null
    }
    return <div className={styles.list_container}>
        {
            list?.length > 0 ? list.map((item, index) => {
                return <div className={`${styles.ele_list_item} ${item.isChoice ? styles.isChoice : ''}`} key={index}
                            onClick={() => itemClick(item)}>
                    <img src={item.elementUrl} alt=""/>
                    <span>{item.elementCount}</span>
                </div>
            }) : <Empty customStyle={{
                imgStyle: {width: 80, height: 80},
                textStyle: {color: '#FFFFFF', fontSize: 10},
            }}/>
        }
    </div>
}

const EleHc = (props) => { //元素合成
    const [currentTab, setCurrentTab] = useState('0')
    const swiperRef = useRef(null)
    const [eleList, setEleList] = useState([]);
    const preImgsCur = useRef([])
    const [preImages, setPreImages] = useState([]);
    const [actyStatus, setActyStatus] = useState(0);//0=活动进行 -1=未开始 1=已结束

    useEffect(() => {
        loadData();
        getConfig()
    }, [])

    const loadData = async () => {
        console.log('loadData')
        let arr = await getEleList();
        setEleList(arr);
    }

    const getConfig = async () => {
        let status = await getTimeConfig();
        setActyStatus(status);
    }

    const onClickTab = (key) => {
        setCurrentTab(key)
        swiperRef.current.swipeTo(key)
    }

    const hcClick = () => {
        if (actyStatus !== 0) {
            return;
        }
        if (!preImages.length) {
            return;
        }
        EleHCModal.open(preImages, () => {
            preImgsCur.current = [];
            setPreImages([]);
            setEleList([]);
            loadData();
        });
    }

    const getBtn1Title = () => {
        if (actyStatus === -1) {
            return '活动未开始'
        } else if (actyStatus === 1) {
            return '活动已结束'
        } else {
            return '确认合成生成藏品'
        }
    }

    const selectChildImg = (item, type) => {
        preImgsCur.current = preImgsCur.current.filter(obj => obj.elementGroup !== item.elementGroup);
        if (type === 'add') {
            preImgsCur.current.push(item)
        } else if (type === 'reduce') {
            preImgsCur.current = preImgsCur.current.filter(obj => obj.elementId !== item.elementId);
        }
        preImgsCur.current.sort((a, b) => a.elementId - b.elementId);
        setPreImages([...preImgsCur.current])
    }

    const previewImgs = () => {
        return preImages.map((item, index) => {
            return <img src={item.elementUrl} alt="" key={index.toString()} className={styles.pre}/>
        })
    }

    return (
        <div className={styles.huiContainer}>
            <div className={styles.content}>
                <div className={styles.left}>
                    <p className={styles.title}>藏品预览</p>
                    <div className={styles.imgContainer}>
                        <img src={require('../../imgs/img_bg.png')} alt=""/>
                        {previewImgs()}
                    </div>
                    <p className={styles.btn} onClick={hcClick}>{getBtn1Title()}</p>
                </div>
                <div className={styles.right}>
                    <p className={styles.title} style={{width: "100%", marginLeft: '5%'}}>选择元素</p>
                    <div className={styles.listCon}>
                        <div className={styles.ele_list}>
                            <Tabs className={styles.tabs} activeKey={currentTab} onChange={onClickTab}>
                                {eleList.map((item, index) => <Tabs.Tab
                                    key={index}
                                    title={<div
                                        className={`${styles.tab_title} ${currentTab === item.key ? styles.tab_select : ''}`}>{item.title}</div>}>
                                </Tabs.Tab>)}
                            </Tabs>
                            <Swiper
                                direction='horizontal'
                                indicator={() => null}
                                ref={swiperRef}
                                allowTouchMove={false}
                                defaultIndex={currentTab}
                            >
                                {
                                    eleList.map((item, index) => {
                                        return <Swiper.Item style={{display: 'flex'}} key={index}>
                                            <EleList focusStatus={currentTab === item.key}
                                                     data={eleList[index]['child']} selectChildImg={selectChildImg}/>
                                        </Swiper.Item>
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EleHc;
