/**
 * @description 土地藏品回收回收操作页
 * @returns {route: /collectionRecoveryLand/recycling}
*/

import React, {useEffect, useState, useRef} from 'react';
import { Toast } from 'antd-mobile';
import qs from 'query-string';
import NFTHeader from '@/components/NFTHeader';
import {hideLoading, showLoading} from "@/components/loading";
import ListView from '@/components/AntListView';
import ModalAlert from "@/components/ModalAlert";
import {throttle} from '@/utils/publicFun';
import SuccessModal from '../components/successModal';
import RecyclingModal from '../components/recyclingModal';
import { getRecoveryActivityList, recoveryActivitySimpleRdo } from '@/api/projectManager_self';
import './index.scss';
import collection from '@/assets/img/variationApe/collection-temp.png';

const ActivityType = {
    '1-A': 1,
    '1-B': 2,
    '1-C': 3,
    '2-A': 4,
    '2-B': 5,
    '2-C': 6
};

const mock = [
    {
      nftNo: '0ggghfdddddddff',
      collectionId: '0',
      nftImage: collection,
    },
    {
      nftNo: '1',
      collectionId: '1',
      nftImage: collection,
    },
    {
      nftNo: '2',
      collectionId: '2',
      nftImage: collection,
    },
    {
      nftNo: '3',
      collectionId: '3',
      nftImage: collection,
    },
    {
      nftNo: '4',
      collectionId: '4',
      nftImage: collection,
    },
  ]

const Recycling = () => {
    const { type, level, activityCode } = qs.parse(window.location.search);
    const [dataSource, setDataSource] = useState([]);
    const [selectArray, setSelectArray] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const currentRef = useRef()
    const currentPage = useRef(1);
    const pageSize = useRef(20);

    useEffect(() => {
        loadData(true);
      }, [])

    const loadData = async (isLoading=false)=> {
        const params = {
            currentPage: currentPage.current,
            pageSize: pageSize.current,
            activityCode: activityCode,
            bizId: 'land'+level,
        }
        try {
            isLoading && showLoading();
            const res = await getRecoveryActivityList(params);
            isLoading && hideLoading();
            if (res.code !== '000000') throw res.msg;
            const newList = res.data.list || [];
            if(currentPage.current === 1) {
                setDataSource(newList);
            } else {
                setDataSource([...dataSource, ...newList]);
            }
            setIsLoad(true)
            setHasMore(newList.length >= pageSize.current);
        } catch (error) {
            isLoading && hideLoading();
            setIsLoad(true);
            Toast.show(error);
        }
        
    }

    const loadMore = async ()=> {
        currentPage.current = currentPage.current + 1
        await loadData()
    }

    const onComit = () => {
        if(selectArray.length < 1) return;
        ModalAlert.show({
            getContainer:currentRef.current,
            maskStyle:{background: `rgba(0, 0, 0, 0.6)`},
            content: <RecyclingModal selectArray={selectArray} onConfirm={onConfirm} onCancel={()=>{ModalAlert.hide()}}/>
        })
    }

    const onConfirm = throttle(async () => {
        ModalAlert.hide();
        try {
          showLoading();
          const nftNoList = selectArray.map((i) => i.nftNo);
          const res = await recoveryActivitySimpleRdo({
                type: ActivityType[`${type}-${level}`], 
                nftNoList: nftNoList,
                activityCode: activityCode, 
                bizId: 'land'+level 
            });
          hideLoading();
          if(res.code != '000000') {
            throw res.msg;
          }
          SuccessModal.open({confirmCB, type})
        } catch (error) {
          hideLoading();
          Toast.show(error);
        }
    }, 2000)

    const confirmCB = ()=> {
        setSelectArray([]);
        loadData(true);
    }

    const onDelete = (item) => {
        item.select = !item.select;
        const selects = selectArray.filter(item => item.select);
        setSelectArray(selects);
    }

    const onSelectItem = (item) => {
        if(selectArray.length >= 3 && !item.select) return;
        item.select = !item.select;
        if(item.select) {
            setSelectArray([...selectArray, item]);
        }else {
            const selects = selectArray.filter(item => item.select);
            setSelectArray(selects);
        }
        
    }

    const renderItem = (item)=> {
        return (
            <div className='collection-item'>
                <div className='collection-item-content' onClick={()=>onSelectItem(item)}>
                    <div className={`item-img${item.select ? ' active' : selectArray.length >= 3 ? ' disabled':''}`}>
                        <img src={item.nftImage} alt="" />
                    </div>
                    <span>#{item.nftNo}</span>
                    {
                        item.select ?
                        <img className='select-icon' src={require('@/assets/img/collectionRecoveryLand/select.png')} alt="" />
                        :null
                    }
                    
                </div>
            </div>
        )
    }

    return (
        <div className="recycling" ref={currentRef}>
            <NFTHeader midText={`回收${level ?? 'A'}级藏品`}/>
            <div className='recycling-body'>
                <div className='select-preview'>
                    <div className='preview-list'>
                        {
                            selectArray.map((item, i)=>{
                                return (
                                    <div className='preview-item' key={i}>
                                        <img className='preview-img' src={item.nftImage} alt="" />
                                        <img className='close-icon' 
                                            onClick={()=>onDelete(item)}
                                            src={require('@/assets/img/collectionRecoveryLand/close.png')} 
                                            alt="" 
                                        />
                                        <span>#{item.nftNo}</span>
                                    </div>
                                )
                            })  
                        }
                        {
                            Array(3 - selectArray.length).fill(1).map((_,k) => {
                                return (
                                    <div className='preview-emty' key={k}>
                                        <img src={require('@/assets/img/collectionRecoveryLand/add.png')} alt="" />
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                    <div className={`recycling-btn${selectArray.length < 1 ? ' disable':''}`} onClick={onComit}>
                        <span>确认回收</span>
                    </div>
                </div>
                <div className='collection-list'>
                    <div className="list-tip">请选择回收藏品</div>
                    <ListView 
                        dataSource={dataSource}
                        renderItem={renderItem}
                        hasMore={hasMore}
                        isLoad={isLoad}
                        onEndReached={loadMore}
                        style={{paddingTop: 50}}
                        onEndReachedThreshold={1}
                    />
                </div>
            </div>
        </div>
    )
}

export default Recycling;