import React, { useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import { Tabs, Swiper } from 'antd-mobile'
import close from '@/assets/img/landfi/close-circle-fill.png'
import appService from '@/utils/appService'
import { queryDefCode } from "@/api/projectManager_self"

const TabList = [
  {key: 0, text: '奖励清单'},
  {key: 1, text: 'up奖池'},
  {key: 2, text: '详情说明'},
]

const RewardOverview = ({onClose}) => {
  const [currentTab, setCurrentTab] = useState(0)
  const [reward, setReward] = useState([])
  const [activityRules, setActivityRules] = useState({})
  const swiperRef = useRef(null)
  const domRef = useRef(null)

  useEffect(() => {
    getRewardData()
    getActivityRules()
  }, [])

  const onTabClick = (key) => {
    setCurrentTab(key)
    swiperRef.current.swipeTo(key)
  }

  const getRewardData = async () => {
    const res = await appService('600903', {activityCode: 'gemstoneLottery2403'}, true)
    console.log('result=====>', res)
    if (res.status > 0) {
      const { list = [], upList = [] } = res?.data || {}
      setReward([list, upList])
    }
  }

  const getActivityRules = async () => {
    try {
      const res = await queryDefCode({codeType: 'activityRule', code: 'gemstoneLottery202403Rule'})
      if (res.code !== '000000') throw res.msg
      const { data } = res;
      if (data?.result && data?.result?.defCode) {
        const defCode = data.result.defCode
        setActivityRules(defCode)
      }
    } catch (error) {
    }
}

  return <div className={styles.reward_overview}>
    <img className={styles.close} src={close} onClick={onClose} alt="" />
    <div className={styles.reward_title}>奖励概览</div>
    <Tabs className={styles.tabs} activeKey={currentTab} onChange={(key) => onTabClick(Number(key))}>
      {
        TabList.map((item, index) => {
          return <Tabs.Tab 
            key={index} 
            title={<div className={`${styles.tab_title} ${currentTab === index ? styles.tab_select: ''}`}>{item.text}</div>}>
          </Tabs.Tab>
        })
      }
    </Tabs>
    <div className={styles.tabs_content} ref={domRef}>
      <Swiper
        direction='horizontal'
        indicator={() => null}
        ref={swiperRef}
        allowTouchMove={false}
        defaultIndex={currentTab}
      >
        {
          TabList.map((item, index) => {
            return <Swiper.Item key={index}>
              {
                item.key === 2 ? <div className={styles.lottery_rules} style={{height: domRef.current?.offsetHeight}}>
                  {activityRules?.otherSign}
                </div>: <div className={styles.prize_list} style={{height: domRef.current?.offsetHeight}}>
                  {
                    reward?.[index]?.length > 0 ? reward?.[index]?.map((item, index) => {
                    return <div className={styles.prize_list_item} key={index}>
                      <img src={item.imageUrl} alt="" />
                    </div>}): <div className={styles.list_empty}>暂无数据</div>
                  }
                </div>
              }
            </Swiper.Item>
          })
        }
      </Swiper>
    </div>
  </div>
}

export default RewardOverview