import React, {useEffect, useState, useRef} from 'react'
import styles from './styles.module.scss'
import CommonView from '../commonView'
import {Mask, Tabs, Swiper, Toast} from 'antd-mobile'
import yu from '../../imgs/yu.png'
import ReactDOM from "react-dom";
import {getElementCountList} from "@/api/projectManager_self";
import {hideLoading, showLoading} from "@/components/loading";
import Empty from "@/components/empty";
import {getEleList} from "@/view/eleCompound/utils";

/**
 * groupId:
 * 1背景
 * 2建筑
 * 3无聊猿
 * 4龙
 * 5Ape world
 * */
const EleList = ({focusStatus, data = []}) => {

    if (!focusStatus){
        return null
    }
    return <div className={styles.list_container}>
        {
            data?.length > 0 ? data.map((item, index) => {
                return <div className={styles.ele_list_item} key={index}>
                    <img src={item.elementUrl} alt=""/>
                    <span>{item.elementCount}</span>
                </div>
            }) : <Empty customStyle={{
                imgStyle: {width: 140, height: 140},
                textStyle: {color: '#FFFFFF', fontSize: 14},
            }}/>
        }
    </div>
}

const MyElements = ({onClose}) => {
    const [currentTab, setCurrentTab] = useState('0')
    const swiperRef = useRef(null);
    const [eleList, setEleList] = useState([]);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        console.log('loadData')
        let arr = await getEleList();
        setEleList(arr);
    }

    const onClickTab = (key) => {
        setCurrentTab(key)
        swiperRef.current.swipeTo(key)
    }

    return <Mask className={styles.my_elements_mask}>
        <CommonView title="我的元素" onClose={onClose}>
            <div className={styles.ele_list}>
                <Tabs className={styles.tabs} activeKey={currentTab} onChange={onClickTab}>
                    {eleList.length && eleList.map((item, index) => <Tabs.Tab
                        key={index}
                        title={<div
                            className={`${styles.tab_title} ${currentTab === item.key ? styles.tab_select : ''}`}>{item.title}</div>}>
                    </Tabs.Tab>)}
                </Tabs>
                <Swiper
                    direction='horizontal'
                    indicator={() => null}
                    ref={swiperRef}
                    allowTouchMove={false}
                    defaultIndex={currentTab}
                >
                    {
                        eleList.length && eleList.map((item, index) => {
                            return <Swiper.Item style={{display: 'flex'}} key={index}>
                                <EleList focusStatus={currentTab === item.key} data={eleList[index]['child']}/>
                            </Swiper.Item>
                        })
                    }
                </Swiper>
            </div>
        </CommonView>
    </Mask>
}

MyElements.open = () => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => div.remove();
    ReactDOM.render(<MyElements
        onClose={close}
        visible
    />, div);
}

export default MyElements
