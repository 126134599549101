/**
 * @description 技术信息
*/
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Popup, Toast } from "antd-mobile";
import { copyText } from "@/utils/publicFun";
import './index.scss';

const TechnologyInfo = (props) => {
    const { detailList } = props;

    const navigate = useNavigate();

    const [visible, setVisible] = useState(false);
    const domRef = useRef(null);

    const onHashClick = ()=> {
        const uri = `/blockChainColInfo?transactionHash=${detailList?.transactionHash}`;
        if(window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push("Web", { uri: `${window.location.origin}${uri}`});
        }else {
            navigate(uri);
        }
        
    }

    return (
        <div className="technology-info" ref={domRef}>
            <div className="head" onClick={() => setVisible(true)}>
                <span className="bar">技术信息</span>
                <span className="icon">
                    <img src={require("@/assets/img/collection/goto.png")} alt="avatar" />
                </span>
            </div>
            <div className="detail-message">
                <span className="title">唯一编号</span>
                <span className="detail">{detailList?.nftHash}</span>
            </div>
            <div className="detail-message" onClick={onHashClick}>
                <span className="title">交易 Hash</span>
                <span className="detail" style={{ color: '#0066FF' }}>{detailList?.transactionHash}</span>
            </div>
            <div className="detail-message">
                <span className="title">认证时间</span>
                <span className="detail">{detailList?.collectTime}</span>
            </div>
            <Popup visible={visible} bodyStyle={{ borderRadius: "6px 6px 0 0" }} getContainer={domRef.current}>
                <div className="technical-information">
                    <span className="title">技术信息</span>
                    <span className="icon" onClick={() => setVisible(false)}>
                        <img src={require("@/assets/img/collection/close.png")} alt="" />
                    </span>
                </div>
                <div className="piece">
                    <div className="id">唯一编号</div>
                    <div className="intro">该数字艺术藏品唯一的身份标识</div>
                    <div className="detail" id="nftHash">
                        <span>{detailList?.nftHash}</span>
                        <img
                            className="copy_icon"
                            src={require("@/assets/img/order-detail/copy.png")}
                            onClick={() => copyText(detailList?.nftHash, document.getElementById("nftHash"))}
                            alt=""
                        />
                    </div>
                </div>
                <div className="piece">
                    <div className="id">交易 Hash</div>
                    <div className="intro">此次交易行为的数字存证</div>
                    <div className="detail" id="transactionHash">
                        <span>{detailList?.transactionHash}</span>
                        <img
                            className="copy_icon"
                            src={require("@/assets/img/order-detail/copy.png")}
                            onClick={() => copyText(detailList?.transactionHash, document.getElementById("transactionHash"))}
                            alt=""
                        />
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export {
    TechnologyInfo
} 