/**
 * 我的数字藏品种类列表
 * route: /myCollectionVote/collectionList
 */
import React, {useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom";
import {List, Toast} from 'antd-mobile'
import NFTHeader from '@/components/NFTHeader';
import "./index.scss"
import {hideLoading, showLoading} from "@/components/loading";
import {queryDefCode, voteInit, voteWeightList} from "@/api/projectManager_self";
import {isArray, isEmptyObject, isEmptyString} from "@/utils/publicFun";
import clickThrottle from "@/utils/clickThrottle";

import VoteRuleDialog from "@/view/myCollectionVote/view/voteRuleDialog";

const CollectionList = (props = {}) => {
    const listTem = [
        {title: 'Ape World 蝠-共创意见征集', id: 0},
        {title: 'Ape World 鹿-共创意见征集', id: 1},
        {title: 'Ape World 鱼-共创意见征集', id: 2},
    ];
    const [list, setList] = useState([])
    const [isLoad, setIsLoad] = useState(false);
    const [maskVisible, setMaskVisible] = useState(false);
    const currentTimeRef = useRef('');
    const startTimeRef = useRef('');

    useEffect(() => {
        loaData();
    }, [])

    const loaData = async () => {
        try {
            showLoading();
            const res = await voteWeightList({});
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            let voteWeightListTem = res.data?.voteWeightList;
            if (voteWeightListTem && isArray(voteWeightListTem) && voteWeightListTem.length > 0) {
                let newArr = [];
                for (const item of voteWeightListTem) {
                    if (item.type === '0') { //蝙蝠
                        if (item.weight > 0) {
                            newArr.push(listTem[0]);
                        }
                    } else if (item.type === '1') { //鹿
                        if (item.weight > 0) {
                            newArr.push(listTem[1]);
                        }
                    } else if (item.type === '2') { //鱼
                        if (item.weight > 0) {
                            newArr.push(listTem[2]);
                        }
                    }
                }
                setList([...newArr]);
            }
            setIsLoad(true);
            currentTimeRef.current = res.data?.currentTime || '';
            startTimeRef.current = res.data?.startTime || '';

        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }


    const navigate = useNavigate();
    const headerRightClick = () => {
        if (!clickThrottle()) {
            return;
        }
        setMaskVisible(!maskVisible);
    }

    const onItemClick = (item) => {
        if (!clickThrottle()) {
            return;
        }
        if (!isEmptyString(currentTimeRef.current) && !isEmptyString(startTimeRef.current)) {
            const currentTimeStamp = Date.parse(currentTimeRef.current.replace(/-/g, '/'))
            const startTimeStamp = Date.parse(startTimeRef.current.replace(/-/g, '/'))

            if (currentTimeStamp >= startTimeStamp) { //已经开始
                if (window.JsBridge.hasWebViewBridge()) {
                    window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/myCollectionVote/collectionVote?aniType=${item.id}`});
                } else {
                    navigate(`/myCollectionVote/collectionVote?aniType=${item.id}`, {})
                }
            } else {
                var data = new Date(startTimeStamp);
                let month = data.getMonth() + 1;
                let day = data.getDate();
                let hour = data.getHours();
                let minute = data.getMinutes();

                month = month >= 10 ? month : '0' + month;
                day = day >= 10 ? day : '0' + day;
                hour = hour >= 10 ? hour : '0' + hour;
                minute = minute >= 10 ? minute : '0' + minute;
                let msg = `投票开放时间为${month}月${day}号${hour}:${minute}`
                window.JsBridge.runAction('alert', 'error', [msg]);
            }
        }
    }

    return (
        <div className="collectionlist_page">
            <NFTHeader midText="共创意见征集" rightText="活动规则" bgColor={'#FFF'} rightClick={headerRightClick}/>
            <div className="listpage_body">
                {
                    list.length > 0 ?
                        <List>
                            {
                                list.map((item, index) => {
                                    return (
                                        <List.Item key={index}>
                                            <div className="list-item-box" onClick={() => onItemClick(item)}>
                                                <span>{item.title}</span>
                                                <img style={{height: 15}}
                                                     src={require('@/assets/img/collection/icon_jingru.png')} alt=""/>
                                            </div>
                                        </List.Item>
                                    )
                                })
                            }

                        </List>
                        :
                        isLoad && <div className="emty-view">
                            <img src={require('@/assets/img/noCollection.png')} alt=""/>
                            <span>{'由于您未拥有Ape World数字藏品，所以您无法参与共创意见征集投票。'}</span>
                        </div>
                }
            </div>
            <VoteRuleDialog maskVisible={maskVisible} setMaskVisible={setMaskVisible}/>
        </div>
    )
}

export default CollectionList;
