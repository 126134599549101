import React, {useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Mask, Toast} from "antd-mobile";
import html2canvas from "html2canvas";
import {throttle} from '@/utils/publicFun';
import "./style.scss";
import QRCode from "qrcode.react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import icon_share_copy from "@/assets/img/icon_share_copy.png";
import icon_share_down from "@/assets/img/icon_share_down.png";
import icon_share_pyp from "@/assets/img/icon_share_pyp.png";
import icon_share_wechat from "@/assets/img/icon_share_wechat_1.jpg";
import {hideLoading, showLoading} from "@/components/loading";
import JsBridgeNew from "@/utils/jsbridgeNew";

const JsBridge = window.JsBridge;

const activityCode = 'secondaryVariantApe';
const ShareModal = ({closeMask, shareUrl, posterImg, inviteCode}) => {
    const [visible, setVisible] = useState(true);
    const domRef = useRef(null);

    const createShareImage = () => {
        return new Promise(async (resolve) => {
            html2canvas(domRef.current, {
                allowTaint: true,
                useCORS: true,
                scrollX: 0,
                scrollY: 0,
            }).then(async (canvas) => {
                const result = canvas.toDataURL('image/jpeg', 0.8);
                resolve(result);
            }).catch(() => {
                resolve(false);
            });
        })
    }

    // 生成分享图片
    const getShareImagePath = () => {
        return new Promise(async (resolve) => {
            if (!domRef.current) {
                resolve(false);
            }
            let base64 = await createShareImage();
            if (base64) {
                let res = await JsBridge.saveImgBase64(base64).catch(ex => false);
                if (!res) {
                    JsBridge.runAction('alert', 'error', ['图片保存到本地失败']);
                    resolve(false);
                } else {
                    resolve(res.tempFilePath);
                }
            } else {
                resolve(false);
            }
        })
    }

    // 微信分享
    const wxShare = throttle(async () => {
        if (window.JsBridge.hasWebViewBridge()) {

            //
            //     let filePath = await getShareImagePath();
            //     window.JsBridge.share({
            //         text: '',
            //         icon: filePath,
            //         link: '',
            //         title: '',
            //         platform: 1,
            //     }).then(() => {
            //     })
            //
            //
            // return;

            let miniProgramType = 0;
            let appid = 'wxe87950ee4eecd239';
            if (process.env.REACT_APP_GKT_LINK === 'https://gu-h5.gkewang.com') {//生产
                miniProgramType = 0;
                // miniProgramType = 2;
                appid = 'wxe87950ee4eecd239';
            }else {
                miniProgramType = 2;
                appid = 'wxf3cee239dd075161';
            }

            let obj = {
                "platformType": 1,
                "title": '参与竞猜,兑换变异无聊猿!更有精美周边等你拿!',
                "desc": '参与竞猜,兑换变异无聊猿!更有精美周边等你拿!',
                "thumImage": 'https://icon-test.gkewang.com/jkt/advertisement/b05e6f9a-07b8-49f7-9e0d-776a15666027.jpg',
                "webpageUrl": 'https://app.gkewang.com/app',
                // "userName":'gh_b92b33dbf9ca', //G优生活
                "userName": 'gh_c937730c5540', //绿地G优
                // "path":'pages/index/index?inviteCode=123',
                "path": `pages/activitys/guessingActy/home?inviteCode=${inviteCode}&activityCode=${activityCode}`,
                "miniProgramType": miniProgramType, // 2, //0:正式版 1:开发版 2:体验版
                "appid": appid, //wxe87950ee4eecd239 生产   wxf3cee239dd075161 测试
            }
            JsBridgeNew.shareMiniProgram(obj)
        }
    }, 3000)

    // 微信朋友圈分享
    const momentsShare = throttle(async () => {
        if (window.JsBridge.hasWebViewBridge()) {
            let filePath = await getShareImagePath();
            window.JsBridge.share({
                text: '',
                icon: filePath,
                link: '',
                title: '',
                platform: 2,
            }).then(() => {
            })
        }
    }, 3000);

    // 下载图片
    const downloadImg = throttle(async () => {
        // showLoading();
        // if (!clickThrottle()){re}
        let filePath = await getShareImagePath();
        if (JsBridge.hasWebViewBridge()) {
            JsBridge.requestPermission("requestAlbum");
            JsBridge.saveImageToPhotosAlbum(filePath).then(function (res) {
                hideLoading();
                if (res) {
                    Toast.show("图片保存成功");
                } else {
                    Toast.show("图片保存失败")
                }
            })
        } else {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.download = `image-${new Date().getTime()}`;
            a.href = filePath;
            a.click();
            document.body.removeChild(a);
            hideLoading();
        }
    }, 3000)

    const cancel = () => {
        setVisible(false);
        closeMask();
    }

    return (
        <Mask
            visible={visible}
            onMaskClick={cancel}
            className="guessing-invite-modal"
        >
            <div className='guessing_modal_container'>
                <div className='share_body'>
                    <div className='share_content' ref={domRef}>
                        <div className='share_img_box'>
                            <img className='share_avatar' src={posterImg} alt="avatar"/>
                        </div>
                        <div className='share_detail'>
                            <QRCode
                                value={shareUrl}
                                size={120}
                                fgColor="#000000"
                            />
                        </div>
                    </div>
                </div>
                <div className='invite-opt'>
                    <div className='wechat' onClick={wxShare}>
                        <img src={icon_share_wechat} alt=""/>
                        <span>微信好友</span>
                    </div>
                    <div className='share' onClick={momentsShare}>
                        <img src={icon_share_pyp} alt=""/>
                        <span>朋友圈</span>
                    </div>
                    <CopyToClipboard text={shareUrl}
                                     onCopy={async () => {
                                         Toast.show('复制成功');
                                     }}>
                        <div className='copy'>
                            <img src={icon_share_copy} alt=""/>
                            <span>复制链接</span>
                        </div>
                    </CopyToClipboard>
                    <div className='download' onClick={downloadImg}>
                        <img src={icon_share_down} alt=""/>
                        <span>下载图片</span>
                    </div>
                </div>
                <div className='cancel_btn' onClick={cancel}>
                    <span>取消</span>
                </div>
            </div>
        </Mask>
    )
}

ShareModal.open = ({shareUrl, posterImg, inviteCode}) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<ShareModal closeMask={close} shareUrl={shareUrl} posterImg={posterImg}
                                inviteCode={inviteCode}/>, div);
}

export default ShareModal;
