import React, {useEffect, useState, useRef} from "react"
import {useNavigate} from 'react-router-dom'
import bg from '../img/bg.png'
import "./index.scss"
import {isArray, objectToQueryParams, Platform} from "@/utils/publicFun";
import {hideLoading, showLoading} from "@/components/loading";
import {queryDefCode, voteWeightList} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";
import appService from '@/utils/appService';
import {mockData} from "@/view/myCollectionVote/collectionRaffle/mock";
import {ProgressBar} from 'antd-mobile'
import WinnerSwiper from "@/view/myCollectionVote/collectionRaffle/winnerSwiper";
import RulesModal from "@/view/myCollectionVote/collectionRaffle/ruleModal/rulesModal";
import shareModal from "@/view/myCollectionVote/collectionRaffle/shareModal";
import shareImg from '../img/parts-share.png';
import {Swiper} from 'antd-mobile'
import qs from 'query-string';

const shareUrl = `${window.location.origin}/myCollectionVote/share`;

const CollectionRaffle = () => {

    const [result, setResult] = useState({});
    const [prizeIndex, setPrizeIndex] = useState(0);
    const [progress, setProgress] = useState(0)
    const [activityCode, setActivityCode] = useState(undefined)
    const [activityRules, setActivityRules] = useState(null);
    const swiper = useRef('');


    useEffect(() => {
        getActivityCode();
    }, []);

    useEffect(() => {
        if (activityCode) {
            loadData();
        }
    }, [activityCode]);

    // 报名
    const toSignUp = async () => {
        try {
            showLoading();
            const res = await appService('600806', {"activityCode": activityCode});
            hideLoading();
            if (res) {
                loadData()
            }
        } catch (error) {
        }
    }
    // 获取活动规则
    const getActivityCode = async () => {
        try {
            showLoading();
            const res = await appService('600807', {});
            hideLoading();
            if (res.data) {
                setActivityCode(res.data)
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }
    // 获取活动规则
    const getActivityRules = async () => {
        try {
            if (activityRules) {
                RulesModal.open(activityRules);
                return;
            }
            showLoading();
            const res = await queryDefCode({codeType: 'activityRule', code: activityCode});
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            const {data} = res;
            if (data.result && data.result.defCode) {
                const defCode = data.result.defCode;
                setActivityRules(defCode);
                RulesModal.open(defCode);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const loadData = async () => {
        try {
            showLoading();
            const res = await appService('600808', {"activityCode": activityCode});
            hideLoading()
            setResult(res.data)
            // const {code, msg, result} = await mockData();
            // const res = {
            //   data: result
            // }
            // setResult(result)
            if (res.data?.collectionActivityConfig) {
                const d1 = new Date(res.data?.collectionActivityConfig?.activityStartTime.replace(/-/g, '/')).getTime();
                const d2 = new Date(res.data?.collectionActivityConfig?.activityEndTime.replace(/-/g, '/')).getTime();
                const d3 = new Date(res.data?.systemTime.replace(/-/g, '/')).getTime();
                if (d3 <= d1) {
                    setProgress(0)
                } else if (d3 >= d2) {
                    setProgress(100)
                } else {
                    // {/*0:活动未开始，0:活动未开始，1：报名结束，2：报名中，3：已报名，等待公布结果 4:已公布中奖结果，5：活动结束*/}
                    if (res.data?.status == 0) {
                        setProgress(0)
                    } else if (res.data?.status == 2) {
                        setProgress(20)
                    } else if (res.data?.status == 3) {
                        setProgress(40)
                    } else if (res.data?.status == 1) {
                        setProgress(50)
                    } else if (res.data?.status == 4) {
                        setProgress(80)
                    } else if (res.data?.status == 5) {
                        setProgress(100)
                    }
                }
            }

        } catch (error) {
            Toast.show(error || '请求失败');
        }
    }

    const btnClick = (type) => {
        if (type === 0) {
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.back();
            } else {
                window.history.go(-1);
            }
        } else if (type === 1) {

            const param = objectToQueryParams({
                lotteryName: encodeURIComponent(result?.pointLotteries && result?.pointLotteries[prizeIndex]?.lotteryName),
                imgUrl: encodeURIComponent(result?.pointLotteries && result?.pointLotteries[prizeIndex]?.imgUrl)
            });
            shareModal.open({
                shareUrl: `${shareUrl}?${param}`,
                posterImg: shareImg,
                lotteryName: result?.pointLotteries && result?.pointLotteries[prizeIndex]?.lotteryName,
                imgUrl: result?.pointLotteries && result?.pointLotteries[prizeIndex]?.imgUrl
            });
        } else if (type === 2) {
            getActivityRules()
        }
    }

    const leftBtnClick = () => {
        if (prizeIndex > 0) {
            setPrizeIndex(prizeIndex - 1)
            swiper.current.swipeTo(prizeIndex - 1);
        }
    }

    const rightBtnClick = () => {
        console.log('result?.pointLotteries' + result?.pointLotteries)
        if (result.pointLotteries && prizeIndex < result?.pointLotteries.length - 1) {
            setPrizeIndex(prizeIndex + 1)
            swiper.current.swipeTo(prizeIndex + 1);
        }
    }

    const onItemClick = (index) => {
        setPrizeIndex(index);
        swiper.current.swipeTo(index);
    }

    const Part1 = () => {

        return (
            <div className={'part1'}>
                <img src={bg} className={'part1_bg'}/>
                <div className={'rule_shiming_bar1'}>
                    <p onClick={() => btnClick(2)}>{`规\n则`}</p>
                </div>
                <div className={'prize_title_container'}>
          <span
              className={'prize_title'}>{result?.pointLotteries && result?.pointLotteries[prizeIndex]?.lotteryName}</span>
                </div>
                <div className={'traffic_signals'}>
                    <img className={'img'} src={require('../img/gif_light.gif')} alt={''}/>
                </div>
                <div className={'big_prize_pic'}>
                    <div className={'big_prize_pic_container'}>
                        <img className={'btn_left'} onClick={() => leftBtnClick()}
                             src={require('./../img/btn_left.png')}/>
                        <div className={'btn_center_container'}>
                            <Swiper
                                indicator={() => null}
                                ref={(ref) => {
                                    swiper.current = ref
                                }}
                                defaultIndex={prizeIndex}
                                onIndexChange={i => {
                                    setPrizeIndex(i)
                                }}
                            >
                                {result?.pointLotteries && result?.pointLotteries?.map((item, index) => {
                                    return (
                                        <Swiper.Item key={index}>
                                            <img className={'btn_center'}
                                                 src={result?.pointLotteries && result?.pointLotteries[index]?.imgUrl}/>
                                        </Swiper.Item>
                                    )
                                })}
                            </Swiper>
                        </div>
                        <img className={'btn_right'} onClick={() => rightBtnClick()}
                             src={require('./../img/btn_right.png')}/>
                    </div>
                </div>
                <div className={'btn_container'}>
                    {(result?.status == 0 || result?.status == 5 || result?.status == 1) &&
                        <img className={'btn_bg_grey'} src={require('../img/btn_bg_black.png')}/>}
                    {result?.status == 2 && <img className={'btn_bg_grey'} src={require('../img/btn_bg_red.png')}/>}
                    {(result?.status == 3 || result?.status == 4) &&
                        <img className={'btn_bg_grey'} src={require('../img/btn_bg_yellow.png')}/>}
                </div>

                {/*0:活动未开始，0:活动未开始，1：报名结束，2：报名中，3：已报名 4:已公布中奖结果，5：活动结束*/}
                <div className={'btn_container_title'}>
                    {result?.status == 0 && <div className={'btn_name'}>活动未开始</div>}
                    {result?.status == 2 && <div className={'btn_name'} onClick={() => toSignUp()}>{result?.collectionActivityConfig.costPoint} 积分报名</div>}
                    {result?.status == 1 && <div className={'btn_name'}>报名结束</div>}
                    {result?.status == 3 && <div className={'btn_name'}>已报名 等待公布结果</div>}
                    {result?.status == 4 && <div className={'btn_name'}>已公布中奖结果</div>}
                    {result?.status == 5 && <div className={'btn_name'}>活动结束</div>}
                </div>

                <div className={'integration_container_title'}>
                    <div className={'integration_title'}>当前积分：<span>{result?.pointNum}</span></div>
                </div>
                {result?.pointLotteries && result?.pointLotteries.length > 1 && result?.pointLotteries.length <= 4 &&
                    <div className={'list_prize_container'}>
                        {result?.pointLotteries?.map((item, index) => {
                            return prizeIndex === index ?
                                <img onClick={() => onItemClick(index)} key={index} className={'list_prize_big'}
                                     alt={''}
                                     src={item.imgUrl}/> :
                                <img onClick={() => onItemClick(index)} key={index} className={'list_prize'} alt={''}
                                     src={item.imgUrl}/>
                        })}
                    </div>}
                {result?.pointLotteries && result?.pointLotteries.length >= 5 &&
                    <div className={'list_prize_container_2'}>
                        {result?.pointLotteries?.map((item, index) => {
                            return prizeIndex === index ?
                                <img onClick={() => onItemClick(index)} key={index} className={'list_prize_big'}
                                     alt={''}
                                     src={item.imgUrl}/> :
                                <img onClick={() => onItemClick(index)} key={index} className={'list_prize'} alt={''}
                                     src={item.imgUrl}/>
                        })}
                    </div>}
            </div>
        )
    }

    const checkMenPiao = () => { //查看门票 /myCollectionVote/moviegoerTicket  isDisable
        let params = result?.jktActivityPrizeRecord || {};
        if (params?.attendeeName && params?.attendeeCardNo && params?.attendeeContactNum) {
            params.isDisable = '1';
        } else {
            params.isDisable = '0';
        }
        window.JsBridge.push('Web', {uri: `${window.location.origin}/myCollectionVote/moviegoerTicket?${qs.stringify(params)}`});
        onDidFocus();
    }

    const prizeResult_0 = () => {
        console.log('result?.jktActivityPrizeRecord?.prizeType==9:' + result?.jktActivityPrizeRecord?.prizeType == 9)
        return (
            <div className={'prize_result_container'}>
                <div className={'prize_result_head_title'}>中奖结果</div>
                <div className={'prize_result_main_title'}>
                    谢谢参与
                </div>
                <div className={'prize_result_sub_title'}>
                    很遗憾，未中奖！
                </div>
                <div className={'prize_result_sub_title'}>
                    请再接再厉！
                </div>
            </div>
        )
    }

    const prizeResult_1 = () => { //门票
        let record = result?.jktActivityPrizeRecord;
        return (
            <div className={'prize_result2_container content-height'}>
                <div className={'prize_result_head_title'}>中奖结果</div>
                <div className={'prize_result_main_title'}>
                    恭喜中奖了
                </div>
                <div className={'prize_result_sub_title'}>
                    恭喜您获得{result?.jktActivityPrizeRecord?.prizeName}，客服会尽快与您取得联系并发放奖品。
                </div>
                <div className={'btn_receive_package'}>
                    <div className={'btn_receive_package_title'} onClick={() => {
                        checkMenPiao()
                    }}>{(record?.attendeeName && record?.attendeeCardNo && record?.attendeeContactNum) ? `查看观演人信息` : `填写观演人信息`}</div>
                </div>
            </div>
        )
    }

    const addOrChangeAddress = () => {
        const info = result?.jktActivityPrizeRecord;
        if (info.address) {
            const param = objectToQueryParams(info);
            window.JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_GKT_LINK}/front/activities/lottery/orderDetail?${param}`});
        } else {
            const param = objectToQueryParams({
                idf: 'commonSub',
                activityCode: activityCode,
                req: info.id,
                name: info.prizeName,
                imgUrl: info.prizeImgUrl,
                // productId:info.skuId,
                // isChangeName:1
            });
            window.JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_GKT_LINK}/front/guapp/activityOrder?${param}`});
            onDidFocus();
        }
    }

    const onDidFocus = () => {
        window.JsBridge.addListener().then(() => {
            loadData();
        });
    }

    const prizeResult_2 = () => {
        return (
            <div className={'prize_result2_container'}>
                <div className={'prize_result_head_title'}>中奖结果</div>
                <div className={'prize_result_main_title'}>
                    恭喜中奖了
                </div>
                <div className={'prize_result_sub_title'}>
                    恭喜您获得{result?.jktActivityPrizeRecord?.prizeName}，请 尽快填写收货地址。
                </div>
                <div className={'btn_receive_package'}>
                    <div className={'btn_receive_package_title'} onClick={() => {
                        addOrChangeAddress()
                    }}>{result?.jktActivityPrizeRecord?.address ? `查看订单` : `填写收货地址`}</div>
                </div>
            </div>
        )
    }

    const activityProcess = () => {
        return (
            <div className={'activity_process_container'}>
                <div className={'prize_result_head_title'}>活动流程</div>
                <div className={'activity_process'}>
                    <div className={'icon_style_container'}>
                        <img className={'icon_style'} src={require('../img/icon_activity_process_begin.png')} alt={''}/>
                        <div className={'text_style'}>
                            报名参与
                        </div>
                    </div>
                    <div className={'icon_style_container'}>
                        <img className={'icon_style'} src={require('../img/icon_activity_process_end.png')} alt={''}/>
                        <div className={'text_style'}>
                            公布结果
                        </div>
                    </div>
                </div>
                <div className={'progress_bar_container'}>
                    <div className="progress-bar" style={{width: `${progress}%`}} id="progress-bar"></div>
                    <div className={'time_label_container'}>
                        <div
                            className={'label_begin'}>{result?.collectionActivityConfig?.registraStartTime.slice(0, -3)}</div>
                        <div
                            className={'label_end'}>{result?.collectionActivityConfig?.settlementTime.slice(0, -3)}</div>
                    </div>
                </div>
                <div className={'tips'}>
                    中奖秘籍：藏品越多概率越高！
                </div>
            </div>
        )
    }

    const showPersonHasPrize = () => {
        return (
            <div className={'radio'}>
                <WinnerSwiper
                    records={result?.records}
                />
            </div>
        )
    }

    return (
        <div className="collection_raffle_page">

            <div className={'navigate_bar'}>
                <div className={'back_father'}>
                    <div className={'back_btn'} onClick={() => btnClick(0)}/>
                </div>
                <div className={'share_father'}>
                    <div className={'share'} onClick={() => btnClick(1)}/>
                </div>
            </div>

            <div className={'body-content'}>
                {Part1()}
                {/*{prizeResult_1()}*/}
                {(result?.status == 4 || result?.status == 5) && result?.jktActivityPrizeRecord?.prizeType == 9 && prizeResult_0()}
                {(result?.status == 4 || result?.status == 5) && result?.jktActivityPrizeRecord?.prizeType == 6 && prizeResult_1()}
                {(result?.status == 4 || result?.status == 5) && result?.jktActivityPrizeRecord?.prizeType == 3 && prizeResult_2()}
                {result?.collectionActivityConfig && activityProcess()}
                {result?.records && result?.records.length > 0 && showPersonHasPrize()}
                <div className={'bottom_empty'}/>
            </div>
        </div>
    )
}

export default CollectionRaffle
