/**
 * @description GR-2407658盲盒换宝石
 * @param {}
 * @returns {/boxToStone/home?test=1}
*/
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Toast } from 'antd-mobile';
import NFTModal from '@/components/NFTModal';
import { hideLoading, showLoading } from '@/components/loading';
import ActivityRules from '../components/ActivityRules';

import Service from '../service';
import Styles from './index.module.scss';

const Btns = [
    {title: '实名', key: 2},{title: '规则', key: 1},{title: '购买记录', key: 3},
]

const GidsList = [
    {title: '飞天茅台53度', img: require('@/assets/img/boxToStone/jiu.png')},
    {title: '进博礼盒', img: require('@/assets/img/boxToStone/hezi.png')},
    {title: '无聊猿帆布包', img: require('@/assets/img/boxToStone/bag.png')},
    {title: '无聊猿T恤', img: require('@/assets/img/boxToStone/tshift.png')},
    {title: '无聊猿棒球帽', img: require('@/assets/img/boxToStone/hat.png')},
    {title: '无聊猿扑克牌', img: require('@/assets/img/boxToStone/poker.png')},
]

const Home = () => {
    
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [dataSource, setDataSource] = useState({});
    const pageDom = useRef(null);
    
    useEffect(() => {
        getData(true);
    }, []);
    const getData = async (loading = false) => {
        try {
            if (loading) showLoading();
            if (loading) {
                const userInfo = await Service.getUserCertificateStatus();
                setUserInfo(userInfo);
            }
            const res = await Service.getProductInfo();
            if (loading) hideLoading();
            if (res.status > 0) {
                setDataSource(res.data);
            }
            
        } catch (error) {
            hideLoading();
            error && Toast.show(error);
        }
    }

    const onRightBtnClick = (item={}) => {
        if(item.key ===1) {
            const ModalInstance = NFTModal.show({
                content: <ActivityRules onClose={() => ModalInstance?.close()} />,
                getContainer: pageDom.current,
            });
        }else if(item.key ===2) {
            window.JsBridge.navigate('Verify');
        }else if(item.key ===3) {
            const uri = `/boxToStone/buyHistory`
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
            } else {
                navigate(uri);
            }
        }
        
    }
    // 立即购买
    const onBuy = () => {
        const {createTime: systemTime, collectTimeStart, collectTimeEnd} = dataSource;
        if(!collectTimeStart) {
            return Toast.show('活动查询异常');
        }
        const currentT = new Date(systemTime?.replace(/-/g, "/")).getTime();
        const startT = new Date(collectTimeStart?.replace(/-/g, "/")).getTime();
        const endT = new Date(collectTimeEnd?.replace(/-/g, "/")).getTime();
        if(currentT < startT) {
            return Toast.show('活动未开始'); 
        }else if(currentT > endT) {
           return Toast.show('活动已结束');
        }
        const uri = `/boxToStone/goodsDetail?code=${Service.activityCode}`
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.verifyRealName("请先实名认证").then(function (res) {
                if (res?.result) {
                    window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
                }
            })

        } else {
            navigate(uri);
        }
    }

    return (
        <div className={Styles.box_to_stone} ref={pageDom}>
            <div className={Styles.back} onClick={Service.onBack}></div>
            <div className={Styles.top_bg}>
                <div className={Styles.right_btns}>
                {
                    Btns.map((btn, i)=> {
                        if(btn.key === 2 && userInfo?.ID) return null;
                        return (
                            <div key={i} className={Styles.rightBtn} onClick={()=>onRightBtnClick(btn)}>{btn.title}</div>
                        )
                    })
                }
                </div>
                <div className={Styles.price}>¥<span>{Number(dataSource.desc ?? 0)}</span></div>
                <button className={Styles.buy_button} onClick={onBuy}>立即购买</button>
            </div>
            <div className={Styles.grids}>
                {
                    GidsList.map((item, i) => {
                        return (
                            <div key={i} className={Styles.grid_item}>
                                <img src={item.img} alt="" />
                                <div>{item.title}</div>
                            </div>
                        )
                    })
                }
            </div>
            <img className={Styles.buttomDesc} src={require('@/assets/img/boxToStone/dontlike.png')} alt="" />
            <div className={Styles.bottom_logo}>
                <img src={require('@/assets/img/kangYangNFT/logo_bottom.png')} alt="" />
            </div>
        </div>
    )
}

export default Home;