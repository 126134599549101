/**
 * @description: 确认订单顶部地址栏
 * @param { onCallAddress } 回调函数返回地址信息
*/

import React, {useState, useEffect} from 'react';
import {Toast} from "antd-mobile";
import appService from '@/utils/appService';
import Styles from './index.module.scss';
import JsBridgeNew from '@/utils/jsbridgeNew';

const AddressBox = (props = {}) => {
    const {onCallAddress} = props;
    const [addressItem, setAddressItem] = useState()

    useEffect(() => {
        loadDefaltAddress()
    }, [])

    const loadDefaltAddress = async () => {
        try {
            const res = await appService('101294', {});
            if (res.status > 0) {
                let list = res.data || [];
                //查找默认地址
                let defaltAddress = list.find(item => item.isDefaltAddress == 1)
                if (!defaltAddress && list.length > 0) {
                    defaltAddress = list[0]
                }
                if (defaltAddress) {
                    setAddressItem(defaltAddress);
                    onCallAddress && onCallAddress(defaltAddress);
                }
            } else {
                Toast.show('请求失败')
            }
        } catch (error) {
            Toast.show(error || '请求失败')
        }
    }

    const onChangeAddress = async () => {
        if(JsBridgeNew.hasWebViewBridge()) {
            const address = await JsBridgeNew.push('CustomWeb', {
                uri: process.env.REACT_APP_GKT_LINK + `/front/guapp/address/addressList?mode=1`,
                callback: true
            });
            console.log('address---', address)
            setAddressItem(address);
            onCallAddress && onCallAddress(address);
        }else {
            window.open(`${process.env.REACT_APP_GKT_LINK}/front/guapp/address/addressList?mode=1`)
            
        }
    }

    const consigneeNumber = addressItem && addressItem.consigneeNumber;
    const phone = consigneeNumber
      ? consigneeNumber.toString().slice(0, 3) + '****' + consigneeNumber.toString().slice(7, 11)
      : '';
    return (
        <div className={Styles.addressBox}>
            <img className={Styles.headerImg} src={require('@/assets/img/icon/address-header.png')} alt="" />
            <div className={Styles.addressContent} onClick={onChangeAddress}>
                <div className={Styles.addressInfo}>
                    {
                        addressItem && addressItem.deliveryAddress ?
                        <div className={Styles.addressName}>
                            { addressItem.provinceName + addressItem.cityName + addressItem.areaName + (addressItem.streetName || '') + (addressItem.deliveryAddress || '')}
                        </div>:
                        <div className={Styles.addressName}>
                            {'地址为空'}
                        </div>
                    }
                    
                    <div className={Styles.userInfo}>{addressItem && addressItem.deliveryAddress ? `${addressItem.consigneeName}  ${phone}` : '点击新增收货地址'}</div>
                    {
                        addressItem && addressItem.needComplete && 
                        <div className={Styles.remindTip}>
                            <img src={require('@/assets/img/icon/remind.png')} className={Styles.remindIcon} alt=''/>
                            <span className={Styles.remindText}>请完善收货地址</span>
                        </div>
                    }
                </div>
                <img className={Styles.address_arrow} src={require('@/assets/img/icon/icon_xiangqing.png')} alt="" />
            </div>
        </div>
    )
}

export default AddressBox;