/**
 * @description 商品详情
 * @returns {/boxToStone/goodsDetail}
*/

import React, { useEffect, useState } from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import NFTHeader from '@/components/NFTHeader';
import { Swiper, Toast, Button } from 'antd-mobile';
import { hideLoading, showLoading } from "@/components/loading";
import Service from '../service';
import Styles from './index.module.scss';

const GoodsDetail = () => {
    const navigate = useNavigate();

    const [dataSource, setDataSource] = useState({imgUrl: require('@/assets/img/boxToStone/boximg.png'), desc: ''});

    useEffect(() => {
        getData(true);
    }, []);
    const getData = async (loading = false) => {
        try {
            if (loading) showLoading();
            const res = await Service.getProductInfo();
            if (loading) hideLoading();
            if (res.status > 0) {
                setDataSource(res.data);
            }
        } catch (error) {
            hideLoading();
            error && Toast.show(error);

        }
    }

    const onBuyNow = () => {
        if(dataSource.goodsNum) {
            const uri = `/boxToStone/confirmOrder?goods=${encodeURIComponent(JSON.stringify(dataSource))}`
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
            } else {
                navigate(uri);
            }
        }else {
            Toast.show({
                icon: 'fail',
                content: '商品库存不足'
            });
        }
        
    }

    return (
        <div className={Styles.goods_detail}>
            <NFTHeader midText={`商品详情`} bgColor={'#FFFFFF'}/>
            <div className={Styles.goods_container}>
            <div className={Styles.detail_img}>
                <Swiper indicator={() => null}>
                {
                    [dataSource.imgUrl].map((url, index) => (
                    <Swiper.Item key={index}>
                        <div className={Styles.swiper_item}>
                            <img src={url} alt="" />
                        </div>
                    </Swiper.Item>
                    ))
                }
                </Swiper>
                <div className={Styles.goods_price}>
                    <div>
                        <span>¥</span>
                        <span>{dataSource.desc || '0.00'}</span>
                    </div>
                    <div>{dataSource.lotteryName ?? '--'}</div>
                </div>
                <div className={Styles.goods_spec}>
                    <div>
                        <span>运费规则</span>
                        <span>全国包邮</span>
                    </div>
                </div>
            </div>
            </div>
            <div className={Styles.goods_bottom}>
                <Button disabled={Number(dataSource.desc) > 0 ? false : true} className={Styles.goods_btn} onClick={onBuyNow}>立即购买</Button>
            </div>
        </div>
    )
}

export default GoodsDetail;