import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { Mask } from 'antd-mobile';
import point from '../../imgs/img_integral1.png';

const ScratchModal = ({onClose, leftCB, rightCB, prizeInfo}) => {
  const [visible, setVisible] = useState(true);

  const cancel = () => {
    onClose && onClose();
    setVisible(false);
    leftCB && leftCB();
  }

  const confirm = () => {
    onClose && onClose();
    setVisible(false);
    rightCB && rightCB();
  }

  return <Mask className='unity-acratch-success-mask' visible={visible} onMaskClick={() => cancel()}>
    <div className='mask-content'>
      <div className="mask-inner">
        <div className='title'>恭喜中奖</div>
        <div className='points-content'>
          {prizeInfo.type === 5 ? <div className='ape-box'>
            <span>恭喜获得史诗款无聊猿</span>
            <img src={prizeInfo.nftImage} alt="" />
          </div> : <div className='points'>
            <span>恭喜获得 {prizeInfo.value} 积分</span>
            <img className='points-prize' src={point} alt="" />
          </div>}
        </div>
        <div className='btn-box'>
          <div className="confirm-btn" onClick={() => cancel()}>我知道啦</div>
          <div className="address-btn" onClick={() => confirm()}>继续抽奖</div>
        </div>
      </div>
    </div>
  </Mask>
}

ScratchModal.open = ({leftCB, rightCB, prizeInfo}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<ScratchModal onClose={close} leftCB={leftCB} rightCB={rightCB} prizeInfo={prizeInfo}/>, div);
}

export default ScratchModal;