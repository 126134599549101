import {useState, useEffect, useRef} from 'react'
import {Link} from "react-router-dom"
import NFTHeader from "@/components/NFTHeader"
import styles from './styles.module.scss'
import appService from '@/utils/appService';
import {Toast} from 'antd-mobile';
import ListView from '@/components/AntListView';

export default () => {
    const [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);

    useEffect(() => {
        getData();
    }, [])

    const getData = async (isRefresh = true, isLoading = true) => {
        try {
            currentPage.current = isRefresh ? 1 : currentPage.current + 1;
            const params = {
                pageNum: currentPage.current,
                pageSize: pageSize.current,
            }
            const result = await appService('600813', params, isLoading);
            // console.log('result=====>', result);
            if (result.status > 0) {
                const list = result.data.list || [];
                if (currentPage.current > 1) {
                    setList([...result.data.list, ...list]);
                } else {
                    setList(list);
                }
                setIsLoad(true);
                setHasMore(list.length >= pageSize.current);
            }
        } catch (error) {
            Toast.show(error);
        }
    }

    const hejiString = (item) => {
        if (item.remark) {
            const remark = JSON.parse(item.remark);
            const spendItemNum = remark.spendItemNum || '';
            const spendItemType = remark.spendItemType || '';
            const spendItemUnit = remark.spendItemUnit || '';
            let typeString = '史诗款无聊猿兑换券'
            if (spendItemType == '1') {
                typeString = '史诗款兑换券'
            } else if (spendItemType == '2') {
                typeString = '碎片'
            } else if (spendItemType == '3') {
                typeString = '变异无聊猿Lite兑换券'
            }
            return `合计:${spendItemNum}${spendItemUnit}${typeString}`
        }
        return null
    }

    const getTime = (item) => {
        if (!item.createTime) {
            return null
        }
        let nowTime = item.createTime;
        const timeStamp = Date.parse(nowTime.replace(/-/g, '/'));

        const year = new Date(timeStamp).getFullYear();
        let month = new Date(timeStamp).getMonth() + 1;
        let date = new Date(timeStamp).getDate();
        if (month < 10) {
            month = `0${month}`;
        }
        if (date < 10) {
            date = `0${date}`;
        }
        return `兑换于${year}年${month}月${date}日`
    }

    const renderItem = (item, index) => (
        <div key={index} className={styles.item}>
            <div className={styles.main}>
                <img src={item.prizeImgUrl}/>
                <div className={styles.righter}>
                    <div className={styles.top}>
                        <h2>{item.prizeName}</h2>
                        {item.desc ? <p>x{item.desc}</p> : null}
                    </div>
                    <div className={styles.desc}>{hejiString(item)}</div>
                </div>
            </div>
            <div className={styles.footer}>
                <div>{getTime(item)}</div>
                {
                    item.prizeType === 3 ? <Link to={`/guessingActy/exchangeRealOrderDetails?id=${item.id}`}>查看详情</Link>
                        : <Link to={`/guessingActy/exchangeOrderDetails?id=${item.id}`}>查看详情</Link>
                }
            </div>
        </div>
    )

    return (
        <div className={styles.container}>
            <NFTHeader midText="我的订单" bgColor="#fff" backType={2}/>
            <div className={styles.list}>
                {/* {list.map(renderItem)} */}
                <ListView
                    dataSource={list}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    onRefresh={() => getData(true, false)}
                    onEndReached={() => getData(false, false)}
                    style={{paddingTop: 120}}
                    isLoad={isLoad}
                />
            </div>
        </div>
    )
}
