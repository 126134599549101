/**
 * @description 藏品信息
*/
import React from "react";
import './index.scss';

const CollectionMsg = (props={}) => {
    const { detailList } = props;
    return (
        <div className="collection-message">
            <div className="title-bar">藏品介绍</div>
            <div className="message-detail">
                <div className="message-detail" dangerouslySetInnerHTML={{
                    __html: detailList?.collectModuleList[0]?.subTitle?.replace(/\n/g, '<br/>')
                }}/>
                {!!detailList?.collectModuleList[0]?.subTitle && !!detailList?.targetNftNo && detailList?.parent == 2 &&
                    <span style={{color: '#C0A557'}}>{` 本藏品属于被关联藏品，不能单独转增。`}</span>
                }
            </div>
            <div className="circulation">藏品特征</div>
            <div className="message-detail" dangerouslySetInnerHTML={{
                    __html: detailList?.collectModuleList[1]?.subTitle?.replace(/\n/g, '<br/>')
            }}/>
        </div>
    )
}
export default CollectionMsg;