/*
    步进器
*/
import React, { useEffect, useState,useMemo, forwardRef, useImperativeHandle } from "react";

import "./index.scss";

const Stepper = forwardRef((props, myRef)=> {
    // 暴露给父组件的属性
    useImperativeHandle(myRef, () => ({
        value
    }));
    
    const {
        defaultValue = 1,
        step = 1,
        minValue = 0,
        maxValue = 100,
        unit = '', // 单位
        disabled
    } = props||{}

    const [inCount, setInCount] = useState(defaultValue)
    // const [enable, setEnable] = useState(false) // 用于样式不同时的设置

    useEffect(()=> {

    },[])

    const onWillChange = (ct) => {
        let ok = true
        if(props.onWillChange) {
            ok = props.onWillChange(ct)
        }
        return ok;
    }

    const onReduce = ()=> {
        const res = inCount - 1
        if(!onWillChange(res)) return;

        if(inCount > minValue) {
            setInCount(res)
            props.onChange && props.onChange(res)
        }else {

        }
    }

    const onAdd = () => {
        const res = inCount + 1
        if(!onWillChange(res)) return;
        if(step > 1) {
            if(inCount * step <= maxValue){
                setInCount(res)
                props.onChange && props.onChange(res)
            }else {
    
            }
        }else {
            if(inCount * step < maxValue){
                setInCount(res)
                props.onChange && props.onChange(res)
            }else {
    
            }
        }
        
    }

    const getCount = ()=> {
        let cout = 0
        if(inCount > 1 &&  step > 1) {
            cout = step * (inCount - 1)
        }else {
            cout = inCount
        }
        return cout
    }

    const value = useMemo(()=>{
        return getCount()
    },[inCount, step])

    return (
        <div className="stepper-nft">
            {
                disabled ? 
                <img src={require('@/assets/img/icon_reduce_6.png')} alt=''/>:
                <img src={require('@/assets/img/icon_reduce_6.png')} alt='' onClick={onReduce}/>
            }
            <span>{`${value}${unit}`}</span>
            {
                disabled ? 
                <img src={require('@/assets/img/icon_add_6.png')} alt=''/>:
                <img src={require('@/assets/img/icon_add_6.png')} alt='' onClick={onAdd}/>
            }
            
        </div>
    )

})


export default Stepper;