import React, {useEffect, useState, useRef} from "react"
import {List, Mask, Toast} from "antd-mobile"
import "./imageBrowerView.scss"

const JsBridge = window.JsBridge;


const ImageBrowerView = ({showBigImg, setShowBigImg, imgs}) => {


    const setImgs = () => {
        return imgs.map((item, index) => {
            return <img src={item.elemFileUrl || ''} key={index.toString()} className={'egg_hc_big_img'}/>
        })
    }
    const onMaskClick = () => {
        setShowBigImg(false)
    }



    return (
        <div className={'egg_hc_big_img_container'}>
            <Mask visible={showBigImg} opacity={0.8} onMaskClick={() => onMaskClick()}>
                <div className={'egg_hc_big_img_container0'} onClick={()=>onMaskClick()}>
                    {setImgs()}
                </div>
            </Mask>
        </div>
    )
}

export default ImageBrowerView;
