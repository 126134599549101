import React, { useState, useEffect } from 'react'
import styles from './goodsDetail.module.scss'
import { Swiper, Toast } from 'antd-mobile'
import close from '@/assets/img/landfi/x.png'
import { getUserInfo } from "@/api/projectManager_self"
import MyModal from './modal'
import OrderConfirm from './orderConfirm'
import RewardRecord from './rewardRecord'
import appService from '@/utils/appService'

export const GoodsDetail = ({ onClose, recordInfo, container }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [userInfo, setUserInfo] = useState({})
  const [goodsInfo, setGoodsInfo] = useState({})

  useEffect(() => {
    console.log(recordInfo)
    queryUserInfo()
    getGoodsInfo()
  }, [])

  const detailImg = goodsInfo.detailUrls?.split(',') || []

  //获取用户信息
  const queryUserInfo = async () => {
    try {
      const {code, msg, data} = await getUserInfo();
      if (code !== '000000') throw msg;
      setUserInfo(data)
    } catch (error) {
      Toast.show(error)
    }
  }

  const getGoodsInfo = async () => {
    const result = await appService('600904', {lotterySkuId: recordInfo.skuId})
    console.log("🚀 ~ getGoodsInfo ~ result:", result)
    if (result?.status > 0) {
      setGoodsInfo(result?.data)
    }
  }

  const pointRecharge = () => {
    onClose()
    window.JsBridgeNew.hiddenStatusBar(false)
    window.JsBridgeNew.screenRotate(true)
    window.JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_GKT_LINK}/front/member/pointRecharge/home`})
  }

  const onExchange = () => {
    if (!(userInfo.point >= goodsInfo.exchangePoint && goodsInfo.stock > 0)) {
      return
    }
    const ModalInstance = MyModal.show({
      content: <OrderConfirm 
        onClose={() => ModalInstance?.close()} 
        container={container} 
        recordInfo={{...recordInfo, prizeImgUrl: goodsInfo.bigUrl, prizeName: goodsInfo.productName}}
      />,
      getContainer: container,
    })
    onClose()
  }

  const handleClose = () => {
    onClose()
    seePrize()
  }

  const seePrize = () => {
    const ModalInstance = MyModal.show({
      content: <RewardRecord onClose={() => ModalInstance?.close()} container={container}/>,
      getContainer: container,
    })
  }

  return <div className={styles.exchange_goods_detail}>
    <img className={styles.close} src={close} onClick={handleClose} alt="" />
    <div className={styles.goods_container}>
      <div className={styles.goods_img_box}>
        <div className={styles.img_tabs}>
          <div className={`${styles.tab_item} ${tabIndex === 0 ? styles.tab_active: ''}`} onClick={() => setTabIndex(0)}>商品图</div>
          <div className={`${styles.tab_item} ${tabIndex === 1 ? styles.tab_active: ''}`} onClick={() => setTabIndex(1)}>商品详情</div>
        </div>
        <div className={styles.tab_content}>
          {tabIndex === 0 ? <div className={styles.main_img}>
            <img src={goodsInfo.bigUrl} alt="" />
          </div>: null}
          {tabIndex === 1 ? <div className={styles.detail_img}>
            <Swiper>
              {
                detailImg.map((item, index) => (
                  <Swiper.Item key={index}>
                    <div className={styles.swiper_item}>
                      <img src={item} alt="" />
                    </div>
                  </Swiper.Item>
                ))
              }
            </Swiper>
          </div>: null}
        </div>
      </div>
      <div className={styles.goods_info}>
        <div>
          <div className={styles.goods_price}>
            <div>
              <span>{goodsInfo.exchangePoint}</span>
              <span>积分</span>
            </div>
            <div>{goodsInfo.productName}</div>
          </div>
          <div className={styles.goods_spec}>
            <div>
              <div>
                <span>规格</span>
                <span>暂无</span>
              </div>
              <div><span></span><span></span><span></span></div>
            </div>
            <div>
              <span>运费</span>
              <span>订单固定运费0元</span>
            </div>
          </div>
          <div className={styles.user_points} onClick={pointRecharge}>
            <div>当前积分：{userInfo.point}</div>
            <div>积分充值&gt;</div>
          </div>
        </div>
        <div 
          className={`${styles.exchange_btn} ${(userInfo.point >= goodsInfo.exchangePoint && goodsInfo.stock > 0) ? '': styles.disable}`} 
          onClick={onExchange}
        >
          {userInfo.point < goodsInfo.exchangePoint ? '积分不足': goodsInfo.stock <= 0 ? '已兑完': '立即兑换'}
        </div>
      </div>
    </div>
  </div>
}