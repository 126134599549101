import React from "react";
import './index.scss';
import qs from 'query-string';
import choice_logo from '@/assets/img/industrialApe/choice_logo.png';

const Data = [
  {
    name: 'A级',
    img: choice_logo,
    points: 150,
    times: 10,
    count: 15,
    level: 'A',
  },
  {
    name: 'B级',
    img: choice_logo,
    points: 40,
    times: 3,
    count: 20,
    level: 'B',
  },
  {
    name: 'C级',
    img: choice_logo,
    points: 10,
    times: 1,
    count: 50,
    level: 'C',
  }
]

const SynthesisType = ({ onClose, activityCode, navigate }) => {
  
  const chooseType = (level) => {
    const params = qs.stringify({level, activityCode})
    const url = `/industrialApe/synthesis?${params}`;
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${url}`});
    } else {
      navigate(url);
    }
    onClose();
  }

  return <div className="synthesis_ape_type">
    <div className="type_inner">
      <div className="close" onClick={onClose}></div>
      <div className="choose_tip">选择要合成的等级</div>
      <div className="type_box">
        {
          Data.map((item, index) => {
            const { name, img, level } = item;
            return <div key={index} onClick={() => chooseType(level)}>
              <img src={img} alt="" />
              <div className="opt">{name}
              </div>
            </div>
          })
        }
      </div>
    </div>
  </div>
}

export default SynthesisType;