import React, {useEffect, useState,} from 'react';
import styles from "./styles.module.scss";
import MyElements from "@/view/eleCompound/components/myElements";
import RecyclingRecords from "@/view/eleCompound/components/recyclingRecords";
import PurchaseRecords from "@/view/eleCompound/components/purchaseRecords";
import {getTimeConfig} from "@/view/eleCompound/utils";

const ColEle = (props) => { //收集元素
    const [recyclingVisible, setRecyclingVisible] = useState(false);
    const [purchaseVisible, setPurchaseVisible] = useState(false);
    const [actyStatus, setActyStatus] = useState(0);//0=活动进行 -1=未开始 1=已结束

    useEffect(() => {
        getConfig();
    }, [])

    const getConfig = async () => {
        let status = await getTimeConfig();
        setActyStatus(status);
    }

    const btnClick = (type) => {
        if (type === 0) {
            setRecyclingVisible(true)
        } else if (type === 1) {
            setPurchaseVisible(true)
        } else {
            if (actyStatus === 0) {
                props.btnClick && props.btnClick(type);
            }
        }
    }

    const getBtn1Title = () => {
        if (actyStatus === -1) {
            return '活动未开始'
        } else if (actyStatus === 1) {
            return '活动已结束'
        } else {
            return '回收元旦款 得元素'
        }
    }
    const getBtn2Title = () => {
        if (actyStatus === -1) {
            return '活动未开始'
        } else if (actyStatus === 1) {
            return '活动已结束'
        } else {
            return '购买元素盲盒'
        }
    }

    return (
        <>
            <img src={require('../../imgs/bg.png')} alt="" className={styles.bgImg}/>
            <div className={styles.midContent}>
                <p className={styles.myEle} onClick={() => {
                    MyElements.open();
                }}>我的元素></p>
                <div className={styles.imgContainer}>
                    <img src={require('../../imgs/img_1.png')} alt=""/>
                </div>
                <div className={styles.items}>
                    <div className={styles.item}>
                        <div className={styles.imgs}>
                            <img src={require('../../imgs/img_f.png')} alt=""/>
                            <img src={require('../../imgs/img_y.png')} alt=""/>
                            <img src={require('../../imgs/img_l.png')} alt=""/>
                        </div>
                        <p className={actyStatus === 0 ? '' : styles.dis}
                           onClick={() => btnClick(3)}>{getBtn1Title()}</p>
                    </div>
                    <div className={styles.item}>
                        <img src={require('../../imgs/box.png')} alt="" className={styles.mhImg}/>
                        <p className={actyStatus === 0 ? '' : styles.dis}
                           onClick={() => btnClick(4)}>{getBtn2Title()}</p>
                    </div>
                </div>

                <div className={styles.btns}>
                    <p onClick={() => btnClick(0)}>回收记录></p>
                    <p onClick={() => btnClick(1)}>购买记录></p>
                </div>
            </div>
            <RecyclingRecords visible={recyclingVisible} onClose={() => setRecyclingVisible(false)}/>
            <PurchaseRecords visible={purchaseVisible} onClose={() => setPurchaseVisible(false)}/>
        </>
    )
}

export default ColEle;
