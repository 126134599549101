import requests from '@/utils/requests'
import qs from 'qs';

// home: 获取数字藏品列表(销售批次)
export async function getCollectionList(params) {
  return await requests.get('/v1/collection/sale_batch', { params })
}

//detail页 获取藏品信息
export async function getCollectionInfo(params) {
  return await requests.get('/v1/collection/sale_batch_detail', { params })
}

// 获取用户可购买盲盒数量
export async function getMysteryNum(params) {
  return await requests.get('/v1/user/blind_buy', { params })
}

//detail 获取分享配置信息
export async function getShareInfo(params) {
  return await requests.get('/v1/share_config', { params })
}

//详情页_预支付
export async function getPrepayDetail(params) {
  return await requests.get('/v1/collection/detail_prepay', { params })
}

//支付接口
export async function payApi(params) {
  return await requests.post('/v1/order/create', qs.stringify(params))
}

export async function payAliApi(params) {
  return await requests.post('/v1/order/alipay', qs.stringify(params))
}

//app返回值回调此接口
export async function payReturnApi(params) {
  return await requests.post('/v1/order/notice', qs.stringify(params))
}

//salePay: 获取用户信息
export async function getUserInfo(params) {
  return await requests.get('/third/green/user_info', { params })
}

//salePay: 个人中心页面
export async function getUserCenter(params) {
  return await requests.get('/v1/user/home', { params })
}

//藏品列表页
export async function getMyCollection(params) {
  return await requests.get('/v1/user_collection/list', { params })
}

//下单前-校验用户是否有同款未支付订单
export async function checkNotpay(params) {
  return await requests.get('/v1/order/check_notpay', { params })
}

//订单列表
export async function getMyOrder(params) {
  return await requests.get('/v1/order/list', { params })
}

// 取消订单
export async function cancelOrder(params) {
  return await requests.post('/v1/order/cancel', qs.stringify(params))
}

// 待支付-根据订单号获取支付所需参数
export async function getOrderParams(params) {
  return await requests.get('/v1/order/get_pay_pram', { params })
}

//订单详情页
export async function getMyOrderDetail(params) {
  return await requests.get('/v1/order/detail', { params })
}

// 藏品详情页
export async function getCollectionDetail(params) {
  return await requests.get('/v1/user_collection/detail', { params })
}
// 重新开盒
export async function reopenBox(params) {
  return await requests.post('/v1/user_collection/burn', qs.stringify(params))
}
