/**
 * @description 藏品详情顶部图片信息
*/
import React, {useState} from "react";
import {ImageViewer, Toast} from "antd-mobile";
import {
    previewImage,
} from '@/api/projectManager_self';
import clickThrottle from "@/utils/clickThrottle";
import {showLoading, hideLoading} from '@/components/loading';
import { isEmptyString, isIOSNew} from "@/utils/publicFun";

import './index.scss';

const CollectionHeader = (props) => {
    const { detailList } = props;

    const [browerEggShow, setBrowerEggShow] = useState(false)
    const [browerEggImg, setBrowerEggImg] = useState('')
    const [check, setCheck] = useState(false)

    const downloadImg = () => {
        if (!clickThrottle()) return;
        let img = document.getElementById("collection")
        let url = img.src
        if (isEmptyString(url)) {
            return;
        }
        let suffix = (url.substring(url.lastIndexOf('.') + 1));
        if (isEmptyString(suffix)) {
            suffix = 'jpeg'
        } else {
            let suffixTem = suffix.toLowerCase();
            console.log(suffixTem)
            if (suffixTem.indexOf('gif') >= 0) {
                suffix = 'gif'
            } else {
                suffix = 'jpeg'
            }
        }
        if (isIOSNew()) {
            suffix = 'jpeg'
        }

        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.downloadFile({
                url,
                fileType: suffix,
            }).then(function (result) {
                window.JsBridge.requestPermission("requestAlbum")
                window.JsBridge.saveImageToPhotosAlbum(result.tempFilePath).then(function (res) {
                    if (res) {
                        Toast.show("图片保存成功")
                    } else {
                        Toast.show("图片保存失败")
                    }
                })
            })
        } else {
            let a = document.createElement("a")
            let event = new MouseEvent("click")
            a.download = "数字藏品"
            a.href = url
            a.dispatchEvent(event)
            Toast.show("图片保存成功")
        }
    }

    const progressBarView0 = () => {
        let arr = ['幼年期', '成熟期'];
        return arr.map((item, index) => {
            return (
                <div className='xingxiang_view0' key={index}>
                    <span>{item}</span>
                </div>
            )
        })
    }

    const progressBarView1 = () => {
        let arr = ['span_bottom', 'span_top', 'span_bottom', 'span_top', 'span_bottom'];
        return arr.map((item, index) => {
            return <div className='span_progress_yuan' key={index}>
                <span className={item}/>
            </div>
        })
    }

    const progressBarView2 = () => {
        let arr = ['蛋', '成长期', '完全体'];
        return arr.map((item, index) => {
            return (
                <div className='xingxiang_view0' key={index}>
                    <span>{item}</span>
                </div>
            )
        })
    }

    const lookBrower = async () => { //预览
        try {
            showLoading();
            const {code, msg, data} = await previewImage({nftNo: detailList?.nftNo});
            hideLoading();
            if (code === '000000') {
                if (data && data.uploadPath) {
                    setBrowerEggImg(data.uploadPath);
                    setBrowerEggShow(true);
                }
            } else {
                Toast.show(msg);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }
    const progressBarFatherView = () => {
        // collectionGroup就是1,2,3 蝙蝠
        // 4,5,6 鱼
        // 7,8,9 鹿
        if (!detailList) {
            return null
        }
        let collectionId = parseInt(detailList?.collectionId) || 0;
        if (collectionId !== 9) {
            return null;
        }

        let collectionGroup = detailList?.collectionGroup;
        if (!collectionGroup) {
            return null
        }
        let name = '形象：蝠';
        if (collectionGroup === '1' || collectionGroup === '2' || collectionGroup === '3') {
            name = '形象：蝠';
        } else if (collectionGroup === '4' || collectionGroup === '5' || collectionGroup === '6') {
            name = '形象：鱼';
        } else if (collectionGroup === '7' || collectionGroup === '8' || collectionGroup === '9') {
            name = '形象：鹿';
        }
        // if (detailList?.collectionSource === 6) { //已经合成过了
        //     return null
        // }

        return (
            <div className='collection_avatar_progressbar_view'>
                <div className='xingxiang_view'>
                    <span>{name}</span>
                </div>
                <div className='collection_avatar_progressbar_view_content'>
                    <div className='collection_avatar_content0'>{progressBarView0()}</div>
                    <div className='collection_avatar_content1'>
                        {progressBarView1()}
                        <div className='collection_avatar_content1_line'/>
                        <div className='collection_avatar_content1_real'/>
                    </div>
                    <div className='collection_avatar_content2'>{progressBarView2()}</div>
                </div>
                <div className='xingxiang_state_view' onClick={() => lookBrower()}>
                    {/*<span>孵化中</span>*/}
                    <span>预览形象</span>
                </div>

            </div>
        )
    }

    return (
        <div className="collection-header">
            <div className="collection_top">
                <div className="bg">
                    <img src={detailList?.nftImage} alt="" />
                </div>
                <div className="bg_mask"></div>
                <div className="collection_avatar">
                    <img src={detailList?.nftImage} alt="" id="collection" />
                    {progressBarFatherView()}
                </div>
            </div>
            <div className="operate">
                <span className="check" onClick={() => setCheck(true)}>
                    <img src={require("@/assets/img/collection/check.png")} alt=""/>
                </span>
                <span className="download" onClick={downloadImg}>
                    <img src={require("@/assets/img/collection/download.png")} alt=""/>
                </span>
            </div>
            <ImageViewer
                image={detailList?.nftImage}
                visible={check}
                onClose={() => {
                    setCheck(false)
                }}
            />
            <ImageViewer
                image={browerEggImg}
                visible={browerEggShow}
                onClose={() => {
                    setBrowerEggShow(false)
                }}
            />
        </div>
    )
}

export default CollectionHeader;