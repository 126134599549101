import React, {useState, useEffect, useRef} from 'react';
import './style.scss';
import ListView from '@/components/AntListView';
import {hideLoading, showLoading} from "@/components/loading";
import { Toast } from 'antd-mobile';
import { queryComposeReocrd } from '@/api/projectManager_self';
import qs from 'query-string';

const mock = {
  code: '000000',
  msg: 'success',
  data: [
    {
      collectionName: '变异药水',
      nftNo: '123456',
      upgradeMaterials: [
        {
          nftNo: '123455',
          beforeName: '绿地30周年',
        },
        {
          nftNo: '123455',
          beforeName: '绿地30周年',
        },
        {
          nftNo: '123455',
          beforeName: '绿地30周年',
        },
      ],
      syntheticTime: '2023-03-28 09:30:50',
      transactionHash: 'shhsssk1283930',
      nftImage: '',
      beforeName: '',
      syntheticNftNo: ''
    }
  ]
}

const VariationRecord = () => {
  const { recordType='synthetic' } = qs.parse(window.location.search);
  const [hasMore, setHasMore] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    window.JsBridge.setTitle(`${recordType === 'synthetic' ? '药水合成记录': '变异记录'}`);
    getVariationRecordList();
  }, [])

  const getVariationRecordList = async (isRefresh=true, isLoading=true) => {
    try {
      currentPage.current = isRefresh ? 1: currentPage.current + 1;
      const params = {
        current: currentPage.current,
        size: pageSize.current,
        recordType,
      }
      isLoading && showLoading();
      const res = await queryComposeReocrd(params);
      // const res = mock;
      isLoading && hideLoading();
      if (res.code !== '000000') throw res.msg;
      if(currentPage.current > 1) {
        setRecordList([...recordList, ...res.data]);
      } else {
        setRecordList(res.data);
      }
      setIsLoad(true);
      setHasMore(res.data.length >= pageSize.current);
    } catch (error) {
      isLoading && hideLoading();
      Toast.show(error);
    }
  }

  return <div className='variation-record-page'>
    <ListView 
      dataSource={recordList}
      renderItem={RecordItem}
      hasMore={hasMore}
      onRefresh={() => getVariationRecordList(true, false)}
      onEndReached={() => getVariationRecordList(false, false)}
      style={{paddingTop: 120}}
      isLoad={isLoad}
    />
  </div>
}

const RecordItem = (item) => {
  const { collectionName, nftNo, upgradeMaterials=[], syntheticTime, transactionHash, nftImage } = item || {};
  return <div className="reocrd-item">
    <div className="medicine-info">
      <img src={nftImage} alt="" />
      <div className="info">
        <span>{collectionName}</span>
        <span>#{nftNo}</span>
      </div>
    </div>
    <div className="compose-material">
      <div className="left-material left">合成材料</div>
      <div className="right-material right">
        {upgradeMaterials.map((item, index) => {
          return <span key={`material-${index}`}>{item.beforeName} {item.nftNo}</span>
        })}
      </div>
    </div>
    <div className="compose-time">
      <div className="left">合成时间</div>
      <div className="right">{syntheticTime}</div>
    </div>
    <div className="business-hash">
      <div className="left">交易哈希</div>
      <div 
        className="right-hash right" onClick={() => window.JsBridge.push("Web", {uri: `${window.location.origin}/blockChainColInfo?transactionHash=${transactionHash}`})}
      >
        {transactionHash}
      </div>
    </div>
  </div>
}

export default VariationRecord;