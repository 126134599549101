/**
 * @description 发行详情-藏品介绍/藏品特征
*/

import React, { useState, useEffect, useRef } from "react";
import "./index.scss";

const IntroduceCard = (props)=> {

    const {dataSouce={}} = props

    return (
        <>
        {
            dataSouce.collectModuleList?.length > 0 ?
            dataSouce.collectModuleList.map((info,index) => {
                if(!info.subTitle) {
                    return null
                }
                return (
                    <div className="introduceCard" key={index}>
                        <div className="content_title">{info.title}</div>
                        <div className="content_desc"  dangerouslySetInnerHTML={{
                            __html: info.subTitle?.replace(/\n/g, '<br/>') ?? ''
                        }}/>
                        {
                            info.image &&
                            <img className="desc-img" src={info.image} alt="" />
                        }
                    </div>
                )
            }):
            <div className="IntroduceCard" style={{display: 'none'}}>
                <div className="content_title">藏品介绍</div>
                <p>
                叮咚~Ape World系列数字藏品空降绿地无聊猿俱乐部啦！独创的玩法，让您体验到孵化独一无二的专属宠物的过程。您在此次发售中购买的Ape World“蛋”系列藏品首先将会作为空投信标，将会在后续活动中不断获得以单独数字藏品形式空投的外在配件。在完成社群内的自由交换和搭配后，Ape World社群将共同创建3种动物的官方形象，并在公链上完成铸造，以新的空投藏品对其商业使用权以及二次创作权等进行确权。
                </p>
                <img className="desc-img" src={require('@/assets/img/ape-world/egg_img_xiangqing1.png')} alt="" />
            </div>
        }
        </>
    )
}

export default IntroduceCard;