import React, {useState, useEffect, useRef} from 'react';
import styles from './styles.module.scss';


const Test = () => {


    return (
        <div className={styles.container}>
            <p className={styles.circle}
            onClick={()=>console.log('000')}>测试</p>
        </div>
    )
}


export default Test;
