import React, {useState, useEffect, useRef} from 'react';
import './style.scss';

const CountDown = ({sysTime, endTime, refreshData, type = 0, endTitle = '开启'}) => {
    const initalTime = {day: '00', hour: '00', minute: '00', second: '00'};
    const [countTime, setCountTime] = useState(initalTime);
    const timeStampCurrent = useRef(null);
    const timerCurrent = useRef(null);
    // console.log('sysTime---', sysTime)
    // console.log('endTime---', endTime)
    const clearAllTimer = () => { //清除所有的定时器
        let end = setInterval(function () {
        }, 3000);
        for (let i = 1; i <= end; i++) {
            i && clearInterval(i);
        }
    }

    const countDown = (currentTime, endTime, callBack) => {
        if (!currentTime || !endTime) {
            return initalTime;
        }
        const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))
        if (timeStampCurrent.current == null) {
            timeStampCurrent.current = currentTimeStamp
        }
        const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
        const distanceStamp = endTimeStamp - timeStampCurrent.current;
        if (distanceStamp <= 0) {
            callBack && callBack(distanceStamp);
            return initalTime;
        }
        // let remianAllSeconds = Math.floor(distanceStamp / 1000);
        // let day = Math.floor(remianAllSeconds / (60 * 60 * 24));
        // let hour = Math.floor(remianAllSeconds / (60 * 60) % 24);
        // let minute = Math.floor(remianAllSeconds / 60 % 60);
        // let second = Math.floor(remianAllSeconds % 60);

        let hour = Math.floor((distanceStamp / 1000 / 60 / 60));
        let minute = Math.floor((distanceStamp / 1000 / 60) % 60);
        let second = Math.floor((distanceStamp / 1000) % 60);

        // day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;
        second = second >= 10 ? second : '0' + second;
        return {day: day, hour: hour, minute: minute, second: second};
    }

    const timerStart = () => {
        timerCurrent.current && clearInterval(timerCurrent.current);
        timerCurrent.current = setInterval(() => {
            // console.log('setInterval---')
            if (timeStampCurrent.current) {
                timeStampCurrent.current = timeStampCurrent.current + 1000;
            }
            let obj = countDown(sysTime, endTime, (distanceStamp) => {
                timerCurrent.current && clearInterval(timerCurrent.current);
                if (distanceStamp === 0) {
                    refreshData && refreshData();
                }
            });
            setCountTime(obj);
        }, 1000)
    }

    const visibilitychange = () => {
        document.addEventListener("visibilitychange", () => {
            if (document.hidden) {
                clearAllTimer();
            } else {
                refreshData && refreshData();
            }
        });
    }

    // const getTime=()=>{
    //     let date = new Date();
    //     let year = date.getFullYear();      //年
    //     let month = date.getMonth() + 1;    //月
    //     let strDate = date.getDate();       //日
    //     let hours = date.getHours();        //时
    //     let minutes = date.getMinutes();    //分
    //     let seconds = date.getSeconds();    //秒
    //     let weekDay = date.getDay(); //星期
    //
    //     let time = year + "年" + month + "月" + strDate + "日 " + hours + "时" + minutes + "分" + seconds + "秒" ;
    //     return time
    // }

    useEffect(() => {
        timeStampCurrent.current = null;
        // console.log('--------')
        // console.log('count--currentTime---',sysTime)
        // console.log('count---endTime---',endTime)
        // console.log('count----sysTime---',getTime());
        // console.log('timeStampCurrent.current---',timeStampCurrent.current);
        // window.JsBridge.print('0000',new Date())
        timerStart();
        return () => {
            timerCurrent.current && clearInterval(timerCurrent.current); //先清空之前的定时器
        }
    }, [sysTime, endTime])
    const {day, hour, minute, second} = countTime;
    return (
        <div className='guess_count-down-component'>
            <span className={`count ${(hour+'').length>2?'long':''}`}>{hour}</span>
            <span className='text'>时</span>
            <span className='count'>{minute}</span>
            <span className='text'>分</span>
            {
                type === 1 ? <>
                    <span className='count'>{second}</span>
                    <span className='text'>秒</span>
                </> : null
            }
            <span className='text' style={{marginLeft: '3px'}}>{endTitle}</span>
        </div>
    )
}

export default CountDown;
