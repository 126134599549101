import React, { useLayoutEffect, useState } from "react"
import { InfiniteScroll, PullToRefresh, DotLoading, Empty } from "antd-mobile"
import { sleep } from "antd-mobile/es/utils/sleep"
import { getMyOrder } from "@/api/projectManager"
import "./index.scss"

const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : null}
    </>
  )
}

const withLoadList = (ListItemComp, getListFun) => {
  return function WithHOC() {
    const requestData = getListFun()
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)

    function getList() {
      const params = { page: 1, page_size: 10, ...requestData.params }
      requestData.api(params).then((res) => {
        setPage(1)
        setTotal(res.total)
        setList(res.list)
        setHasMore(res.total > res.list.length)
      }).catch(e=>{
        console.log(e)
      })
    }

    useLayoutEffect(() => {
      getList()
    }, [])

    const loadMore = async () => {
      const params = { page: page + 1, page_size: 10, ...requestData.params }
      const res = await requestData.api(params)
      setTotal(res.total)
      setPage((pre) => pre + 1)
      setList([...list, ...res.list])
      setHasMore(res.total > [...list, ...res.list].length)
    }

    return (
      <div style={{ overflowY: "scroll" }}>
        {requestData?.setting?.showTotal && <div style={{ margin: "4vw 4vw 0vw" }}>共 {total} 件</div>}
        <div className="refresh">
          <PullToRefresh onRefresh={getList}>
            {list?.length ? (
              list.map((item, index) => <ListItemComp key={index} params={requestData.params} collection={item}></ListItemComp>)
            ) : (
              <Empty style={{ width: "100%" }} image={<img src={require("@/assets/img/noCollection.png")} />} description="暂无数据" />
            )}
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
              <InfiniteScrollContent hasMore={hasMore} />
            </InfiniteScroll>
          </PullToRefresh>
        </div>
      </div>
    )
  }
}

export default withLoadList
