import React, {useEffect, useState, useRef} from 'react'
import styles from './styles.module.scss'
import {Mask, Toast} from 'antd-mobile'
import ReactDOM from "react-dom";
import CommonView from "@/view/eleCompound/components/commonView";
import {hideLoading, showLoading} from "@/components/loading";
import {recoveryActivityCompose} from "@/api/projectManager_self";
import {metaRecovery24_0116} from "@/constant";

//元素合成弹窗确认框
const EleHCModal = ({onClose, data = [], refreshData}) => {
    const [type, setType] = useState(0)//0=回收藏品 1=回收成功
    const [imageUrl, setImageUrl] = useState('')
    const [nftNo, setNftNo] = useState('')

    const sure = async () => {
        if (!data.length) {
            return;
        }
        let elementIdList = [];
        for (const item of data) {
            elementIdList.push(item.elementId)
        }
        try {
            showLoading()
            const res = await recoveryActivityCompose({elementIdList: elementIdList, activityCode: metaRecovery24_0116})
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let {imageUrl = '', nftNo = ''} = res.data;
                setImageUrl(imageUrl);
                setNftNo(nftNo);
                setType(1);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const getImgs = () => {
        return data.map((item, index) => {
            return <img src={item.elementUrl} alt="" key={index.toString()}/>
        })
    }

    return <Mask className={styles.recycling_mask} destroyOnClose={true}>
        <CommonView hideBack={true} onClose={onClose} title={type === 0 ? '元素合成' : '合成成功'}>
            <div className={styles.container}>
                {
                    type === 0 ? <>
                        <p className={styles.title}>确定将以下元素进行合成？确认后以下元素将被组合合成为藏品，本次操作不可逆</p>
                        <div className={styles.list}>
                            {getImgs()}
                        </div>
                        <div className={styles.btns}>
                            <p onClick={onClose}>取消</p>
                            <p onClick={sure}>确认合成</p>
                        </div>
                    </> : <>
                        <p className={styles.title}></p>
                        <div className={styles.list1}>
                            <img src={imageUrl} alt=""/>
                            <p>{nftNo}</p>
                        </div>
                        <div className={styles.btn}>
                            <p onClick={() => {
                                onClose && onClose();
                                refreshData && refreshData()
                            }}>我知道了</p>
                        </div>
                    </>
                }
            </div>
        </CommonView>
    </Mask>
}

EleHCModal.open = (data,refreshData) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => div.remove();
    ReactDOM.render(<EleHCModal
        refreshData={refreshData}
        data={data}
        onClose={close}
        visible
    />, div);
}

export default EleHCModal
