import React, {useState, useEffect} from 'react';
import './style.scss';
import ReactDOM from 'react-dom';
import {Mask} from 'antd-mobile';
import close from '@/assets/img/icon/close.png';
import {getPurchaseRanking} from '@/api/projectManager_self';
import {hideLoading, showLoading} from "@/components/loading";
import { Toast } from 'antd-mobile';

const PurchaseRank = ({onClose}) => {
  const [visible, setVisible] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    visible && getRankList();
  }, [visible])

  const cancel = () => {
    setVisible(false);
    onClose && onClose();
  }

  const getRankList = async () => {
    try {
      showLoading();
      const res = await getPurchaseRanking();
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      setList(res.data);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  return <Mask className='purchase-rank-mask' visible={visible} onMaskClick={() => cancel()}>
    <div className="purchase-rank-container">
      <div className="purchase-title">
        <span></span>
        <span>购买排行</span>
        <img src={close} alt="" onClick={cancel} />
      </div>
      <div className="purchase-tip">
        <p>购买变异无聊猿排名公布</p>
        <p>前6名获得英雄联盟总决赛门票！</p>
      </div>
      <div className="purchase-rank-list">
        <div className="rank-list-title">
          <span>用户</span>
          <span>购买数量</span>
        </div>
        <div className="rank-list-content">
          {list.length && list.map(({userCode, num, imagePath}, index) => {
            return <div className="rank-item" key={`item-${index}`}>
              <div className='rank-item-left'>
                <span>{index < 6 ? index + 1: null}</span>
                <div className='rank-avatar'><img src={imagePath} alt="" /></div>
                <span>{userCode}</span>
              </div>
              <span className='rank-item-right'>{num}</span>
            </div>
          })}
        </div>
        <div className="congratulate-text">
          <p>恭喜以上前6名用户获奖</p>
          <p>我们将会及时联系发放奖品，请保持电话畅通</p>
        </div>
      </div>
    </div>
  </Mask>
}

PurchaseRank.open = () => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<PurchaseRank onClose={close}/>, div);
}

export default PurchaseRank;