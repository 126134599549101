/**
 * 订单支付 pay?key=value
 * 藏品购买传下面2个参数
 * @param collectionId 藏品id
 * @param saleBatchId
 * 入场券购买传下面2个参数
 * @param productId 藏品id
 * @param activityCode 藏品id
 *
 * @param from
 * @param paySuccessPage 支付成功页面 default /pay/success
 * **/
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Toast } from "antd-mobile"
import { useLocation, useNavigate } from 'react-router-dom'
import { getUrlAllParams, computeNumber, round, appendParam } from "@/utils/publicFun";
import NFTHeader from '@/components/NFTHeader'
import { showLoading, hideLoading } from '@/components/loading';
import { isMiniprogram, loadWxJSSDK } from '@/utils/wxJsBridge'
import {
  getPrepayDetail,
  getTicketPrepaid,
  createOrder,
  confirmSimpleOrder,
  queryPayResult,
  queryPayResultCount,
  checkNotpay,
  getPayParams,
  iapValidatePayload,
} from '@/api/projectManager_self';
import GoodsCountBox from './GoodsCountBox'
import PriceDiscountBox from './PriceDiscountBox'
import PayTypeSelect from './PayTypeSelect'
import PayButton from './PayButton'
import styles from './styles.module.scss'
import {guess_getBond_show} from "@/constant";
import qs from "query-string";
import ReturnPoints from "@/view/pay/ReturnPoints";


const isInWxMini = isMiniprogram()

export default () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isInApp = useMemo(() => window.JsBridge.hasWebViewBridge(), [])
  const params = getUrlAllParams(location.search.slice(1));
  const isBuyCollection = Boolean(params.collectionId)
  const timer = useRef(null) // 轮询定时器
  const timerCount = useRef(5) // 轮询5次
  const outTradeNo = useRef(null) // 是否已经创建订单？
  const [payInfo, setPayInfo] = useState({
    // 藏品信息 字段
    id: '',
    imageBg: '',
    price: '0',
    showPrice: '',
    collectionType: '',
    collectionName: '变异无聊猿',
    saleBatchInfo: {
      saleBatchId: 32,
      saleTitle: '',
      stock: 1,
      soldDisplay: 0,
      coverImage: '',
      saleStartTime: '',
      saleEndTime: '',
    },
    payTypeList: ['alipay', 'wxpay', 'point'],
    "vipLevel": "",
    "rewardPoints": 0,
    "blindRight": "",
    "right4": "",
    "defRepeatStr": "52d69431-bbcb-4143-afd6-a921ff6199f9",
    "numberSales": null,
    "purchaseNum": 1,
    "cardNo": null,
    "comicsSet": null,
    "pointPercentage": null,
    "couponBaseRespList": [], // 可用的优惠券
    // product ticket 入场券字段
    "productId": 1,
    "productName": "这是一张测试猜猜券",
    "price": 30,
    "maxNum": 5,
    "maxPointDed": 2000,
    "defRepeatStr": "6dab3225-b82d-470b-b1f9-fb279fa36417"
  })

  const [count, setCount] = useState(1) // 数量
  const [pointDiscount, setPointDisCount] = useState(0) // 积分折扣
  const [couponInfo, setCouponInfo] = useState({ couponCode: 0, discount: 0 }) // 优惠券折扣
  const [payType, setPayType] = useState('WXPAY') // 支付方式
  const [hasUnpaid, setHasUnpaid] = useState(false) // 有未支付的订单？
  const [realNameAuthed, setRealNameAuthed] = useState(true) // 实名认证
  // 原价
  const originalPrice = useMemo(() => {
    return payInfo.price * count;
  }, [count, payInfo.price]);
  // 总价 到手价
  const totalPrice = useMemo(() => {
    const deductionPrice = computeNumber(pointDiscount, "/", payInfo.pointRatio || 1).result;
    const total = Math.max(0, originalPrice - deductionPrice - (couponInfo?.discount || 0))
    return round(total, 2)
  }, [originalPrice, couponInfo, pointDiscount, payInfo])

  const initData = async () => {
    showLoading()
    if (isInApp && isBuyCollection) {
      window.JsBridge.verifyRealName("请先实名认证").then((res)=> {
        setRealNameAuthed(Boolean(res?.result))
      })
    }

    if (isBuyCollection) {
      // 购买藏品
      const { code, msg, data } = await getPrepayDetail({
        collectionId: params.collectionId,
        saleBatchId: params.saleBatchId,
      });
      if (code !== '000000') {
        Toast.show(msg);
        hideLoading()
        return;
      };
      setPayInfo(data);
      // 检测是否有未支付的订单
      if (data.collectionType) {
        const res = await checkNotpay({ collectionType: data.collectionType });
        if (res.code !== '000000') {
          Toast.show(msg);
          hideLoading()
          return;
        }
        if (res.data?.notPayOrderCnt) {
          Toast.show("您有待付款的订单，请先完成上次订单")
          setHasUnpaid(true)
        }
      }
    } else {
      // 购买 product
      const { code, msg, data } = await getTicketPrepaid({
        productId: params.productId || '1',
        activityCode: params.activityCode || 'CCL',
        source: isInWxMini ? 'miniProgram' : 'app'
      });
      if (code !== '000000') {
        Toast.show(msg);
        hideLoading();
        return;
      };
      setPayInfo(data.info);
    }
    hideLoading()
  }

  // 跳转到支付成功页面
  const goPaySuccessPage = (state= {}) => {
    const targetPath = !params.paySuccessPage ? '/pay/success' : decodeURIComponent(appendParam(params.paySuccessPage, { count: state.count }))
    const from = params.from || ''

    if(targetPath.indexOf('/guessingActy/getCoupon') !==-1){
      localStorage.setItem(guess_getBond_show,'0');
    }
    console.log('goPaySuccessPage---',targetPath)

    if (isInWxMini) {
      // 小程序支付跳转通过小程序 webview 控制，这里不再兼容 跳回。可能导致2次弹窗
      return
    }

    navigate(targetPath, {
      state: {
        ...state,
        from
      },
      replace: true
    })
  }

  useEffect(() => {
    initData()
    return () => clearTimeout(timer.current)
  }, [])

  // 循环获取支付结果
  const getPayResultLoop = (body) => {
    clearTimeout(timer.current)
    const getPayResult = async () => {
      // showLoading();
      if (isBuyCollection) {
        const {code, msg, data} = await queryPayResult(body)
        // console.log('queryPayResult', code, data)
        if (code === '000000') {
            if (data?.length > 0) {
              clearTimeout(timer.current)
              console.log('支付结果---',data)
              goPaySuccessPage({
                collectionList: data|| []
              })
            } else if (timerCount.current > 0) {
              timer.current = setTimeout(getPayResult, 1000);
              timerCount.current--
            }
        } else {
          Toast.show(msg);
        }
      } else {
        // 购买入场券逻辑
        const {code, msg, data} = await queryPayResultCount(body)
        console.log('queryPayResult count', code, data)
        if (code === '000000') {
            if (data?.count > 0) {
              console.log('data?.count > 0')
              clearTimeout(timer.current)
              goPaySuccessPage({
                count: data.count
              })
            } else if (timerCount.current > 0) {
              // console.log('continue getPayResult')
              timer.current = setTimeout(getPayResult, 1000);
              timerCount.current--
            }
        } else {
          Toast.show(msg);
        }
      }
      // hideLoading();
    }
    getPayResult()
  }

  const getIapPayResult = (params) => {
    try {
        // showLoading();
        let timeId = setTimeout(async () => {
            const {code, msg, data} = await iapValidatePayload(params);
            hideLoading();
            if (code === '000000') {
                if (data) {
                    // TODO 支付成功
                    // gotoSalePaySuccess(data);
                } else {
                  getPayResultLoop({outTradeNo: params?.outTradeNo});
                }
            } else {
                Toast.show(msg);
            }
            timeId && clearTimeout(timeId);
        }, 2000);
    } catch (error) {
        console.log(error);
    }
  }

  const onPriceDiscount = (type, val) => {
    if (type === 'coupon') {
      setCouponInfo(val);
    } else if (type === 'point') {
      setPointDisCount(val || 0);
    }
  };

  const submit = async () => {
    if (hasUnpaid) {
      Toast.show("您有待付款的订单，请先完成上次订单")
      return
    }
    if (!isInWxMini && !isInApp) {
      Toast.show("请到APP中支付")
      return
    }
    if(isBuyCollection && !realNameAuthed) {
      Toast.show("请先实名认证")
      return
    }
    showLoading()
    if (isBuyCollection) {
      const body = {
        saleBatchId: payInfo.saleBatchInfo?.saleBatchId,
        collectionId: payInfo?.id,
        usePoint: 0,
        useCash: totalPrice,
        payType: isInWxMini ? 'WX_MINI_PROGRAM' : payType,
        source: 'app',
        num: count,
        uid: payInfo?.defRepeatStr,
        redeemCode: payInfo?.cardNo,
        // type: currentOperate === '100' ? '1' : currentState === '7' ? '0' : '2',
        type: '0',
        couponCodeList:  couponInfo.couponCode === 0 ? [] : [couponInfo.couponCode],
      }
      // if (!outTradeNo.current) {
        // 第一次支付 创建订单，后续直接不需要
        const { msg, data, code } = await createOrder(body);
        if (code !== '000000') {
          Toast.show(msg);
          hideLoading()
          return;
        }
        outTradeNo.current = data.outTradeNo
      // }
    } else {
      const body = {
        totalNum: count,
        totalUsePoint: pointDiscount,
        totalUseCash: totalPrice,
        payType: isInWxMini ? 'WX_MINI_PROGRAM' : payType,
        source: isInWxMini ? 'miniProgram' : 'app',
        uid: payInfo?.defRepeatStr,
        activityCode: params.activityCode,
        type: '0',
        productId: payInfo?.productId
      }
      const { msg, data, code } = await confirmSimpleOrder(body);
      if (code !== '000000') {
        Toast.show(msg);
        hideLoading()
        return;
      }
      outTradeNo.current = data.outTradeNo
    }

    if (totalPrice !== 0) {
      // 需要支付
      const { code, data: payParam, msg } = await getPayParams({ outTradeNo: outTradeNo.current });
      if (code !== '000000') {
        Toast.show(msg);
        return;
      };
      if (isInWxMini) {
        const payParamString = encodeURIComponent(JSON.stringify(payParam))
        loadWxJSSDK().then(wx => {
          let url = `/pages/activitys/guessingActy/payment?payParam=${payParamString}&outTradeNo=${outTradeNo.current}`
          if (params.paySuccessPage) {
            // 买5送1
            const realCount = count + Math.floor(count / 5)
            // params.paySuccessPage 被encode 过的
            url = appendParam(
              url, {
                redirectPath: appendParam(params.paySuccessPage, {
                  // 此 count 为前端计算 不一定正确。额外传 outTradeNo 让 paySuccessPage 再去后端查一遍
                  count: realCount,
                  outTradeNo: outTradeNo.current,
                })
              },
              false
            )

            // if(params.paySuccessPage.indexOf('/guessingActy/getCoupon') !==-1){
              localStorage.setItem(guess_getBond_show,'0');
            // }
            console.log('params.paySuccessPage---',params.paySuccessPage)

          }
          wx.miniProgram.navigateTo({ url })
        })
      }
      if (payType === 'WXPAY') {
        window.JsBridge.wxPay(payParam).then(function (result) {
          console.log('wxpay result', result)
            // 支付失败
            if (result.errCode === -2) {
                Toast.show("支付取消")
                clearTimeout(timer.current)
            } else if (result.errCode === 0) {
              console.log('-getPayResultLoop--微信')
              getPayResultLoop({
                errStr: result?.errStr,
                errCode: result?.errCode,
                outTradeNo: outTradeNo.current,
                source: "wxpay",
              });
            } else {
              Toast.show("支付发生错误  code: " + (result?.errCode || " "))
            }
        })
      } else if (payType === 'ALIPAY') {
        // 支付宝支付
        window.JsBridge.aliPay(payParam.payResult).then(function (result) {
            // 支付失败
            console.log('alipay result', result)
            if (result.resultStatus == 6001) {
              Toast.show("支付取消")
              clearTimeout(timer.current)
            } else if (result.resultStatus == 62000) {
                Toast.show("客户端未安装支付通道依赖的服务")
            } else if (result.resultStatus == 9000) {
                // 支付回调
                let resFmt = JSON.parse(result.result || "{}")
              console.log('-getPayResultLoop--支付宝')
                getPayResultLoop({
                  errStr: resFmt?.alipay_trade_app_pay_response?.msg || "",
                  errCode: resFmt?.alipay_trade_app_pay_response?.code || "",
                  outTradeNo: outTradeNo.current,
                  source: "alipay",
                });
            } else {
              Toast.show("支付发生错误  code: " + (result?.resultStatus || " "))
            }
        })
      } else if (payType === 'IOS_IAP') {
        const { productInfo } = payParam;
        if (productInfo) {
            productInfo.outTradeNo = outTradeNo.current;
            // productInfo.productId ='com.gktapp.chuanshuokuan'
            const payDeadline = payParam.payDeadline || '5分钟';
            if (payDeadline) {
              Toast.show(`请在${payDeadline}时间内完成支付`)
              window.JsBridge.iapPay(JSON.stringify(productInfo)).then(function (result) {
                if (result?.code === '200') { //成功
                    let receiptDataObj = JSON.parse(result.receiptData);
                    if (receiptDataObj) {
                        getIapPayResult(receiptDataObj);
                    } else {
                        Toast.show("获取苹果参数失败");
                    }
                } else { //支付失败
                    Toast.show("支付失败  code: " + (result?.code || " "))
                }
              })
            }
        }
      }
    }
    hideLoading()
    // 总是去获取订单结果状态
    getPayResultLoop({ outTradeNo: outTradeNo.current });
  }

    const goBack = () => { //backType={params.activityCode?2:1}
        let backType = params.activityCode ? 2 : 1;
        console.log('backType---',backType)
        if (backType === 1) {
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.back();
            } else {
                window.history.go(-1);
            }
        } else if (backType === 2) {
            window.history.go(-1);
            if (isInWxMini && outTradeNo) {
                localStorage.setItem(guess_getBond_show, '-1');

            }
        }
    }

  return (
    <div className={styles.container}>
      <NFTHeader midText="订单支付" bgColor="#fff"  leftClick={()=>goBack()}/>
      <GoodsCountBox
        name={isBuyCollection? payInfo.collectionName : payInfo.productName}
        goodsPicture={payInfo.imageBg}
        price={payInfo.price}
        maxCount={isBuyCollection? payInfo.purchaseNum : payInfo.maxNum}
        onChange={setCount}
        labels={isBuyCollection ? [] : ['买五赠一', '新用户买一赠一']}
      />
      <PriceDiscountBox
        usePoint={!isBuyCollection}
        maxPointDiscount={payInfo.maxPointDed}
        count={count}
        pointRatio={payInfo.pointRatio}
        couponList={payInfo.couponBaseRespList || []}
        onChange={onPriceDiscount}
        originalPrice={originalPrice}
      />
      {
        isBuyCollection &&
          ((params.collectionId === '32' && payInfo.comicsSet && payInfo.pointPercentage) ? <ReturnPoints payDetail={{...payInfo, count: count, totalPrice: totalPrice,originalPrice:originalPrice}}/>: null)
      }
      <PayTypeSelect onChange={setPayType} />
      <PayButton totalPrice={totalPrice} onClick={submit} />
    </div>
  )
}
