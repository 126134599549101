import React, {useState, useRef, useEffect} from 'react';
import styles from "./styles.module.scss";
import Order from "@/view/eleCompound/components/order";
import PurchaseRecords from "@/view/eleCompound/components/purchaseRecords";
import {getUserCertificateStatus} from "@/view/eleCompound/utils";
import {getTicketPrepaid} from "@/api/projectManager_self";
import { getPayType } from "@/api/common";
import {hideLoading, showLoading} from "@/components/loading";
import {Toast} from "antd-mobile";
import {metaRecovery24_0116} from "@/constant";
import ShiMingModal from "@/view/eleCompound/components/shiMingModal";
import clickThrottle from "@/utils/clickThrottle";

const EleHc = (props) => { //元素合成
    const [visible, setVisible] = useState(false);
    const [buyVisible, setBuyVisible] = useState(false);
    const [data, setData] = useState({})

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        try {
            showLoading()
            const res = await getTicketPrepaid({activityCode: metaRecovery24_0116, productId: 2, source: 'app'})
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            setData(res.data?.info || {});
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const goBuy = async () => {
        if (!clickThrottle()) {
            return
        }
        let isShiming = await getUserCertificateStatus();
        if (!isShiming) {
            ShiMingModal.open();
            return;
        }
        try {
            showLoading()
            const res = await getTicketPrepaid({activityCode: metaRecovery24_0116, productId: 2, source: 'app'})
            const payRes = await getPayType();
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            const data = {...(res.data?.info || {}), payTypeConfig: payRes.data?.result || []};
            Order.open(data)
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    return (
        <div className={styles.huiContainer}>
            <div className={styles.content}>
                <div className={styles.left}>
                    <img src={require('../../imgs/buy_img.png')} alt=""/>
                    <span>{data?.price}</span>
                </div>
                <div className={styles.right}>
                    <img src={require('../../imgs/img_ys1.png')} alt=""/>
                    <p className={styles.des} onClick={() => {
                        setBuyVisible(true)
                    }}>购买记录></p>
                    <p className={styles.btn} onClick={() => {
                        goBuy();
                    }}>立即购买元素盲盒</p>
                </div>
            </div>
            {/*<Order visible={visible} onClose={() => setVisible(false)} data={data}/>*/}
            <PurchaseRecords visible={buyVisible} onClose={() => setBuyVisible(false)}/>
        </div>
    )
}
export default EleHc;
