/**
 * @description 支付方式选择公共组件
*/

import React, { useState, useEffect, useRef } from "react";
import {Radio, Toast} from "antd-mobile"
import { getProductList } from '@/api/projectManager_self';
import { getPayType } from "@/api/common";
import JsBridgeNew from "@/utils/jsbridgeNew";
import { isArray} from "@/utils/publicFun";
import "./index.scss";

const wxpayString = 'wxpay';
const alipayString = 'alipay';
const iosIAPString = 'iosIAP';
const pointString = 'point';

const PayWaysList = [
    {
        payName: '微信',
        icon: require("@/assets/img/orderpay/wx.png"),
        payCode: wxpayString,
        payType: "WXPAY"
    },
    {
        payName: '支付宝',
        icon: require("@/assets/img/orderpay/zfb.png"),
        payCode: alipayString,
        payType: "ALIPAY"
    },
    {
        payName: '苹果支付',
        icon: require("@/assets/img/orderpay/apple_pay.png"),
        payCode: iosIAPString,
        payType: "IOS_IAP"
    },
    // {
    //     payName: '积分支付',
    //     icon: require("@/assets/img/orderpay/point.png"),
    //     payCode: pointString,
    //     payType: "POINT"
    // }
]

const PayTypeSelect = (props)=> {
    //支付方式配置
    const [payConfigs, setPayConfigs] = useState(window.JsBridge.hasWebViewBridge() ? []: PayWaysList)
    const [payWay, setPayWay] = useState(null);
    const payWaysRef = useRef(window.JsBridge.hasWebViewBridge() ? []: PayWaysList);

    useEffect(()=>{
        getPayConfig2()
    },[])

    /**
     * @deprecated 使用 getPayConfig2 代替
    */
    const getPayConfig = async () => {  
        //获取支付配置 iosIAP weixinPay  aliPay
        window.JsBridge.getStorage({key: 'tabConfig'}).then((res) => {
            const config = {
                wxpay: res?.result?.wxpay || false,
                alipay: res?.result?.alipay || false,
                iosIAP: res?.result?.iosIAP || false,
                point:res?.result?.pointDeduct || false,
            }

           const list = PayWaysList.filter((item) => {
                return config[item.payCode]
            })
            payWaysRef.current = list;
            if(list.length > 0) {
                choicePayType(list[0].payType)
            }
            
            setPayConfigs(list)
            if (res?.result?.iosIAP) { //打开了内购按钮
                getProductList({}).then((result) => {
                    if (result.code === '000000') {
                        if (result.data && isArray(result.data) && result.data.length > 0) {
                            JsBridgeNew.iapProList(JSON.stringify(result.data));
                        }else {
                            Toast.show('获取苹果支付商品失败');
                        }
                    } else {
                        Toast.show(result?.msg);
                    }
                });
            }
        })
    }

    const getPayConfig2 = async () => {  
        // 未来将使用接口替换桥接中的数据
       const res = await getPayType();
       console.log('res', res)
       const list = res.data?.result;
       payWaysRef.current = list;
       if(list?.length > 0) {
            choicePayType(list[0].payType)
        }
        
        setPayConfigs(list)
        const iosIAP = list.find((item) => item.payCode === 'iosIAP')
        if (iosIAP) { //打开了内购按钮
            getProductList({}).then((result) => {
                if (result.code === '000000') {
                    if (result.data && isArray(result.data) && result.data.length > 0) {
                        JsBridgeNew.iapProList(JSON.stringify(result.data));
                    }else {
                        Toast.show('获取苹果支付商品失败');
                    }
                } else {
                    Toast.show(result?.msg);
                }
            });
        }
    }

    const choicePayType = (type) => {
        setPayWay(type)
        if(type != payWay) {
            const payItem = payWaysRef.current.find(item => item.payType === type)
            props.onChange && props.onChange({...payItem});
        }
    }

    return (
        <div className="pay-type-select">
            <div className="pay_way">选择支付方式</div>
            <ul className="pay-type">
                <Radio.Group value={payWay} onChange={(val) => choicePayType(val)}>
                    {
                        payConfigs.map((item, index) => {
                            return (
                                <li key={index}>
                                    <div className="apy_way_info">
                                        <img src={item.icon} alt=""/>
                                        <div className="type">{item.payName}</div>
                                    </div>
                                    <Radio value={item.payType} style={{"--icon-size": "18px"}}></Radio>
                                </li>
                            )
                        })
                    }
                </Radio.Group>
            </ul>
            {
                !payConfigs ?
                <div className="pay-type-error">支付方式获取失败</div>:null
            }
        </div>
    )
} 

PayTypeSelect.wxpayString = wxpayString;
PayTypeSelect.alipayString = alipayString;
PayTypeSelect.iosIAPString = iosIAPString;

export default PayTypeSelect;