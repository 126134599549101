import React, {useEffect, useState, useRef} from 'react'
import styles from './styles.module.scss'
import {Mask, Stepper, Toast} from 'antd-mobile'
import back from "@/assets/img/icon/icon_back_black.png";
import fxz from "@/assets/img/orderpay/icon_fxz.png"
import xz from "@/assets/img/orderpay/icon_xz.png"
import {notifyGYouToPay, round} from "@/utils/publicFun";
import PaySuccModal from "@/view/eleCompound/components/paySuccModal";
import {hideLoading, showLoading} from "@/components/loading";
import {
    confirmSimpleOrder,
    getPayParams,
    queryPayResult,
} from "@/api/projectManager_self";
import {metaRecovery24_0116, unity_noty_pay_result} from "@/constant";
import NoticeUtil from "@/utils/NoticeUtil";
import clickThrottle from "@/utils/clickThrottle";
import ReactDOM from "react-dom";

const Order = ({onClose, visible, data = {}}) => {
    const [sel, setSel] = useState(-1);
    const [num, setNum] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0.00);
    const priceRef = useRef(0.00);
    const [dataInfo, setDataInfo] = useState({});
    const timer = useRef(null) // 轮询定时器
    const timerCount = useRef(3) // 轮询5次
    const outTradeNoRef = useRef('');
    const isPayRef = useRef(false);

    useEffect(() => {
        if (visible) {
            setTotalPrice(data?.price)
            priceRef.current = data?.price
            setSel(-1);
            setTotalPrice(priceRef.current);
            setDataInfo(data)
        }
    }, [visible, data])

    useEffect(() => {
        NoticeUtil.on(unity_noty_pay_result, payResult);
        return () => {
            clearTimeout(timer.current);
        }
    }, [])

    // 循环获取支付结果
    const getPayResultLoop = () => {
        clearTimeout(timer.current);
        const getPayResult = async () => {
            // showLoading();
            if (!outTradeNoRef.current) {
                return
            }
            const {code, msg, data = []} = await queryPayResult({outTradeNo: outTradeNoRef.current})
            if (code === '000000') {
                if (data?.length > 0) {
                    console.log('支付结果---', data);
                    clearTimeout(timer.current);
                    isPayRef.current = true;
                    onClose();
                    PaySuccModal.open(data);
                } else if (timerCount.current > 0) {
                    timer.current = setTimeout(getPayResult, 3500);
                    timerCount.current--
                }
            } else {
                Toast.show(msg);
            }
        }
        getPayResult();
    }

    const payResult = (message) => {
        let obj = {};
        const list = message.split('|').map(item => {
            const kv = item.split('=');
            return {[kv[0]]: kv[1]}
        })
        obj = Object.assign({}, ...list);
        console.log('obj---', obj)
        if (obj.msgType && obj.msgType === 'payRes') {
            //  查询支付结果
            if (!isPayRef.current) {
                getPayResultLoop();
                isPayRef.current = true;
            }
        }
    }

    const selPayType = (type) => {
        if (type === sel) {
            return;
        }
        setSel(type)
    }

    const submit = async () => {
        if (sel === -1) {
            Toast.show('请选择支付方式!')
            return;
        }
        if (!clickThrottle(3000)) {
            return;
        }
        showLoading();
        const body = {
            totalNum: num,
            totalUsePoint: 0,
            totalUseCash: totalPrice,
            payType: sel,
            source: 'app',
            uid: dataInfo?.defRepeatStr,
            activityCode: metaRecovery24_0116,
            type: '1',
            productId: 2
        }
        const {msg, data, code} = await confirmSimpleOrder(body);
        hideLoading()
        if (code !== '000000') {
            Toast.show(msg);
            return;
        }
        showLoading();
        outTradeNoRef.current = data?.outTradeNo;
        if (totalPrice !== 0) {
            // 需要支付
            const {code, data: payParam, msg} = await getPayParams({outTradeNo: data?.outTradeNo});
            hideLoading()
            if (code !== '000000') {
                Toast.show(msg);
                return;
            }
            let string = ''
            if (sel === 'WXPAY') {
                string = `msgType=nftGoPay|payCode=WeChat|outTradeNo=${data?.outTradeNo}`;
            } else if (sel === 'ALIPAY') {
                string = `msgType=nftGoPay|payCode=ALIPAY|outTradeNo=${data?.outTradeNo}`;
            }
            notifyGYouToPay(string)
        }
    }

    return <Mask className={styles.recycling_mask} destroyOnClose={true}>
        <div className={styles.container}>
            <div className={styles.view_header}><img onClick={onClose} src={back} alt=""/>
                <h3>确认订单</h3>
                <span></span></div>
            <div className={styles.content}>
                <div className={styles.left}>
                    <div className={styles.top}>
                        <img src={require('../../imgs/buy_img.png')} alt=""/>
                        <div className={styles.titlePrice}>
                            <p>{data?.productName}</p>
                            <p>¥{round(priceRef.current, 2)}</p>
                        </div>
                    </div>
                    <div className={styles.stepContainer}>
                        <span/>
                        <div className={styles.step}>
                            <span>数量</span>
                            <Stepper
                                style={{
                                    '--border-radius': '15px',
                                    '--button-background-color': '#fff',
                                    '--border': '1px solid #E9E9E9',
                                    '--button-text-color': '#000',
                                    '--input-font-size': '16px',
                                }}
                                defaultValue={1}
                                min={1}
                                max={data?.maxNum}
                                inputReadOnly={true}
                                onChange={num => {
                                    setTotalPrice(num * priceRef.current);
                                    setNum(num);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <p className={styles.des}>选择支付方式</p>
                    {
                        data.payTypeConfig?.map((payItem, index) =>{
                            return (
                                <div className={styles.item} key={index} onClick={() => selPayType(payItem.payType)}>
                                    <img src={payItem.icon} alt=""/>
                                    <p>{payItem.payName}</p>
                                    <img src={sel === payItem.payType ? xz : fxz} alt="" className={styles.btn}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.price}>
                    <span>合计</span>
                    <span>{round(totalPrice, 2)}</span>
                </div>
                <p className={styles.submit} onClick={submit}>提交订单</p>
            </div>
        </div>
    </Mask>
}
Order.open = (data) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => div.remove();
    ReactDOM.render(<Order
        data={data}
        onClose={close}
        visible
    />, div);
}
export default Order
