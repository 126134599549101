/**
 * @description 升级页面
 * @returns {route: /workapeBlindbox/upgrade}
 */
import React, { useState, useRef, useEffect } from "react";
import styles from './styles.module.scss';
import NFTHeader from "@/components/NFTHeader";
import {hideLoading, showLoading} from "@/components/loading";
import {Toast} from "antd-mobile";
import ListView from '@/components/AntListView';
import select from '@/assets/img/collectionRecoveryLand/select.png';
import NFTModal from "@/components/NFTModal";
import { useNavigate } from "react-router-dom";
import { getWorkapeList, simpleRdo } from '@/api/activity';
import { getActivityNftCount } from '@/api/activity';

const activityCode = 'GuLandBlindBox';

const getSApeCount = async () => {
  const res = await getActivityNftCount({activityCode})
  if (res.code !== '000000') throw res.msg;
  return res.data;
}

const UpgradeS = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [selectArray, setSelectArray] = useState([]);
  const [remainCount, setRemainCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(50);
  const pageRef = useRef(null);
  const listDom = useRef(null);

  useEffect(() => {
    setSelectArray([]);
    getData(true, true);
    getCount();
    onDidFocus();
  }, [])

  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      setSelectArray([]);
      getData(true, true);
      getCount();
      onDidFocus();
    });
  }

  const getCount = async () => {
    try {
      showLoading();
      const res = await getSApeCount();
      hideLoading();
      setRemainCount(res.remainCount);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const getData = async (isLoading=false, isRefresh=false)=> {
    try {
      isLoading && showLoading();
      if(isRefresh) {
        currentPage.current = 1;
        listDom.current?.scroll(0, 0)
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
        activityCode,
      }
      const res = await getWorkapeList(params);
      isLoading && hideLoading();
      if (res.code !== '000000') throw res.msg;
      const newList = res.data.list || [];
      if(currentPage.current === 1) {
        setDataSource(newList);
      } else {
        setDataSource([...dataSource, ...newList]);
      }
      setIsLoad(true)
      setHasMore(newList.length >= pageSize.current);
    } catch (error) {
      isLoading && hideLoading();
      setIsLoad(true);
      Toast.show(error);
    }
  }

  const onSelect = (item) => {
    if(selectArray.length >= 10 && !item.select) return;
    item.select = !item.select;
    if(item.select) {
      setSelectArray([...selectArray, item]);
    } else {
      const selects = selectArray.filter(item => item.select);
      setSelectArray(selects);
    }
  }

  const upgradeTip = (e) => {
    e.stopPropagation();
    const ModalInstance = NFTModal.show({
      content: <UpgradeConfirm onClose={() => ModalInstance?.close()} confirmUpgrade={upgrade} sStock={remainCount}/>,
      getContainer: pageRef.current,
    });
  }

  const upgradeSuccess = (sInfo) => {
    const ModalInstance = NFTModal.show({
      content: <UpgradeSuccess 
        onClose={() => {
          ModalInstance?.close()
          getData(true, true)
        }} 
        navigate={navigate}
        sInfo={sInfo}
      />,
      getContainer: pageRef.current,
    });
  }

  const upgrade = async () => {
    try {
      showLoading();
      const res = await simpleRdo({
        activityCode,
        nftNoList: selectArray.map(item => item.nftNo)
      })
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      setSelectArray([]);
      upgradeSuccess(res.data[0])
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const onRight = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/workapeBlindbox/upgradeRecord` });
    } else {
      navigate(`/workapeBlindbox/upgradeRecord`);
    }
  }

  const renderItem = (item) => {
    return <div className={styles.list_item_box} onClick={() => onSelect(item)}>
      <div className={styles.list_item}>
        <div className={`${styles.img_box} ${item.select ? styles.active : selectArray.length >= 10 ? styles.disabled:''}`}>
          <img className={styles.nft_img} src={item.nftImage} alt="" />
          {item.select && <img className={styles.select_img} src={select} alt="" />}
        </div>
        <div className={styles.nft_no}>#{item.nftNo}</div>
      </div>
    </div>
  }

  return <div className={styles.upgrade_s} ref={pageRef}>
    <NFTHeader midText="升级" rightText="升级记录" rightClick={onRight}/>
    <div className={styles.upgrade_s_content}>
      <h1>请选择回收藏品</h1>
      <div className={styles.workape_list} ref={listDom}>
        <ListView 
          dataSource={dataSource}
          renderItem={renderItem}
          hasMore={hasMore}
          isLoad={isLoad}
          onEndReached={() => getData()}
          style={{paddingTop: 160}}
        />
      </div>
      <div className={`${styles.upgrade_btn} ${selectArray.length === 10 && remainCount > 0 ? '' : styles.disabled}`}>
        <div className={styles.btn_inner} onClick={upgradeTip}>确认升级</div>
      </div>
    </div>
  </div>
}

const UpgradeConfirm = ({onClose, confirmUpgrade, sStock=0}) => {
  const onConfirm = () => {
    onClose();
    confirmUpgrade && confirmUpgrade();
  }
  return <div className={styles.upgrade_confirm}>
    <div className={styles.title}>升级藏品</div>
    <div className={styles.s_stock}>当前s级库存剩余{sStock}个</div>
    <div className={styles.tip}>合成可得1个s级矿工猴<br />确认将10个a级合成吗？</div>
    <div className={styles.btn_box}>
      <div className={styles.btn_cancel} onClick={onClose}>取消</div>
      <div className={styles.btn_confirm} onClick={onConfirm}>确认升级</div>
    </div>
  </div>
}

const UpgradeSuccess = ({onClose, navigate, sInfo={}}) => {
  const queryCollection = () => {
    if(window.JsBridge.hasWebViewBridge()){
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/myCollection?code=11` })
    }else {
      navigate(`/myCollection?code=11`)
    }
    onClose();
  }
  return <div className={styles.upgrade_success}>
    <div className={styles.title}>升级成功</div>
    <img className={styles.success_img} src={sInfo.nftImageUrl} alt="" />
    <div className={styles.btn_box}>
      <div className={styles.btn_cancel} onClick={onClose}>取消</div>
      <div className={styles.btn_confirm} onClick={queryCollection}>查看藏品</div>
    </div>
  </div>
}

export default UpgradeS;