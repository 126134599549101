import React, {useState, useEffect, useRef} from 'react'
import NFTHeader from "@/components/NFTHeader"
import styles from './styles.module.scss'
import {Toast} from "antd-mobile";
import clickThrottle from "@/utils/clickThrottle";
import ListView from "@/components/AntListView";
import {hideLoading, showLoading} from "@/components/loading";
import {getRankingList} from "@/api/projectManager_self";

const RankingRecord = (props) => {
    const [dataList, setDataList] = useState([]);
    const currentPage = useRef(0);
    const [isLoad, setIsLoad] = useState(false);
    const [type, setType] = useState(0);
    const typeRef = useRef(0);
    const [info, setInfo] = useState({});
    const [isNew, setIsNew] = useState({}); //是否是新用户
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    useEffect(() => {
        props.prizeRecordVisible && loadData();
    }, [props.prizeRecordVisible])

    const loadData = async () => {
        try {
            showLoading()
            const res = await getRankingList({type: typeRef.current})
            hideLoading();
            setIsLoad(true);
            console.log('邀新res---', res);
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let dataTem = res.data;
                let list = dataTem.list || [];
                for (let i = 0; i < list.length; i++) {
                    let item = list[i];
                    item.index = i + 1;
                }
                setDataList(list);
                setInfo(dataTem.info || {});
                setIsNew(dataTem.isNew);
                setStartTime(dataTem.startTime || '');
                setEndTime(dataTem.endTime || '');
            }
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const clickItem = (item) => {
        setType(item.id);
        typeRef.current = item.id;
        loadData();
    }

    const returnImgOrText = (item) => {
        switch (item.index) {
            case 1:
                return <img src={require('../../imgs/icon_no1.png')} className={styles.list_item_img_pm}/>
                break
            case 2:
                return <img src={require('../../imgs/icon_no2.png')} className={styles.list_item_img_pm}/>
                break
            case 3:
                return <img src={require('../../imgs/icon_no3.png')} className={styles.list_item_img_pm}/>
                break
            default:
                return <div
                    className={styles.list_item_img_pm_txt}>{item.index}</div>
                break
        }
    }

    const listItem = (item) => {
        return (
            <div className={styles.list_item}>
                {returnImgOrText(item)}
                <div className={styles.img_tel_com}>
                    <img src={item.imageUrl} className={styles.list_item_img}/>
                    <div>{item.loginName}</div>
                </div>
                <div className={styles.list_item_invited_num}>{item.count}</div>
            </div>
        )
    }

    const getTitle = () => {
        if (type === 0) {
            return '邀新购买人数';
        } else if (type === 1) {
            return '新用户购买总价';
        } else if (type === 2) {
            return '老用户购买总价';
        }
    }

    const getTime = (time) => {
        if (!time) {
            return '';
        }
        const timeStamp = Date.parse(time.replace(/-/g, '/'));
        let startDateTem = new Date(timeStamp);
        let month = startDateTem.getMonth() + 1;
        let day = startDateTem.getDate();
        let hour = startDateTem.getHours();
        let minute = startDateTem.getMinutes();
        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;
        let key = `${month}月${day}号${hour}:${minute}`;
        return key;
    }

    const timeDes = () => {
        if (type === 0) {
            return `邀新排行计算时间:${getTime(startTime)}—${getTime(endTime)}`;
        } else if (type === 1) {
            return `新用户购买排行计算时间:${getTime(startTime)}—${getTime(endTime)}`;
        } else if (type === 2) {
            return `老用户购买排行计算时间:${getTime(startTime)}—${getTime(endTime)}`;
        }
    }

    return (
        <div className={styles.container}>
            <NFTHeader midText="排行榜" bgColor="transparent" leftClick={() => props.leftClick()}/>
            <img src={require('../../imgs/charts_bg.png')} alt="" className={styles.bg_img}/>
            <div className={styles.content_container}>
                <div className={styles.img_container}>
                    <img src={require('../../imgs/img_mt.png')} alt=""/>
                    <img src={require('../../imgs/img_zb.png')} alt=""/>
                </div>
                <div className={styles.des}>{timeDes()}</div>
                <Header clickItem={clickItem}/>
                <div className={styles.list_container}>
                    {
                        dataList.length ?
                            <div style={{width: '100%', background: 'white'}}>
                                <div className={styles.cartoon_header}>
                                    <span className={styles.cartoon_header_pm}>排名</span>
                                    <span className={styles.cartoon_header_yh}>用户信息</span>
                                    <span className={styles.cartoon_header_yq}>{getTitle()}</span>
                                </div>
                            </div> : null
                    }
                    <div className={styles.list}>
                        <ListView
                            dataSource={dataList}
                            renderItem={listItem}
                            onRefresh={() => {
                                currentPage.current = 1;
                                loadData()
                            }}
                            style={{paddingTop: 50}}
                            hasMore={false}
                            isLoad={isLoad}
                        />
                    </div>
                </div>
            </div>
            <InviteBottom type={type} info={info} isNew={isNew}/>
        </div>
    )
}

const Header = (props) => {
    const arr = [
        {title: '邀新排名', isSel: true, id: 0},
        {title: '新用户购买排名', isSel: false, id: 1},
        {title: '老用户购买排名', isSel: false, id: 2}];

    const [data, setData] = useState(arr);

    const itemClick = (item) => {
        if (item.isSel) {
            return
        }
        if (!clickThrottle(1000)) {
            return
        }
        for (const item1 of data) {
            if (item1.id === item.id) {
                item1.isSel = true;
            } else {
                item1.isSel = false;
            }
        }
        setData([...data]);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            props.clickItem && props.clickItem(item)
        }, 500)
    }

    const titles = () => {
        return data.map((item, index) => {
            return <p key={index.toString()} className={item.isSel ? styles.sel : ''}
                      onClick={() => itemClick(item)}>{item.title}</p>
        })
    }

    return (
        <div className={styles.header}>
            <div className={styles.header_titles}>
                {titles()}
            </div>
        </div>
    )
}

const InviteBottom = (props) => {
    const [data, setData] = useState({});
    const [type, setType] = useState(0);
    const [isNew, setIsNew] = useState({}); //是否是新用户
    useEffect(() => {
        setType(props.type);
        setData(props.info);
        setIsNew(props.isNew)
    }, [props]);

    const getTitle = () => {
        if (type === 0) {
            return '邀新购买人数';
        } else if (type === 1) {
            return '购买总价';
        } else if (type === 2) {
            return '购买总价';
        }
    }

    const rankTitle = () => { //排名
        if (!data.count || data.count === 0) {
            return '-'
        }
        if (isNew && type === 2) {//新用户
            return '-';
        }

        if (!isNew && type === 1) { //老用户
            return '-';
        }

        if (data.ranking) {
            if (data.ranking > 99) {
                return '99+';
            }
            return data.ranking;
        }
        return '-';
    }

    const buyCountTitle = () => { //购买数量/购买总价
        if (data.count == null) {
            return '-'
        }
        if (isNew && type === 2) {//新用户
            return '-';
        }

        if (!isNew && type === 1) { //老用户
            return '-';
        }
        return data.count;
    }

    return <div className={styles.cartoon_bottom}>
        <div className={styles.left}>
            <img src={data.imageUrl}/>
            <span className={styles.cartoon_bottom_tel}>{data.loginName || '-'}</span>
        </div>
        <div className={styles.cartoon_bottom_pm}>
            <span className={styles.cartoon_bottom_pm_count}>{rankTitle()}</span>
            <span>我的排名</span>
        </div>
        <div className={styles.cartoon_bottom_yq}>
            <span className={styles.cartoon_bottom_pm_count}>{buyCountTitle()}</span>
            <span>{getTitle()}</span>
        </div>
    </div>
}
export default RankingRecord;
