import React, {useEffect, useState, useRef, memo} from "react";
import './style.scss';
import close from '@/assets/img/collection/close_card.png';
import weigouxuan from '@/assets/img/collection/unchecked.png';
import disabledImg from '@/assets/img/collection/disabled.png';
import gouxuan from '@/assets/img/collection/tick.png';
import empty from '@/assets/img/collection/empty.png';
import { Popup, Toast } from "antd-mobile";

const ReduceCard = (props) => {
  const {visible, onClose, list, chooseArr, onChooseCard, reduceDays, onConfirmUse, remainDays} = props;

  const closeCardModal = () => {
    onClose && onClose();
  }

  const goTabMall = () => {
    window.JsBridge.navigate('tabMall', '3');
  }

  const showConfirmModal = () => {
    if(!chooseArr.length) {
      Toast.show('请选择卡券');
      return;
    }
    onConfirmUse && onConfirmUse();
  }

  return <>
    <Popup 
      className="card-modal"
      visible={visible} 
      bodyStyle={{ borderRadius: "6px 6px 0 0"}}
      onMaskClick={closeCardModal}
    >
      <div className="popup-title">
        <span>选择卡券</span>
        <img src={close} alt="" onClick={closeCardModal} />
      </div>
      {list?.length > 0 ? <div className="days-box">
        <span>使用后,</span>
        <span className="transfer-days">{(remainDays-reduceDays) <= 0 ? 0: (remainDays-reduceDays)}</span>
        <span>天后可转赠</span>
      </div>: null}
      {list?.length > 0 ? <div className="popup-content">
        <div className="card-list">
          {
            list?.map((item,index) => {
              return Card(item, index, onChooseCard, chooseArr);
            })
          }
        </div>
        <div className="use-btn" onClick={showConfirmModal}>使用</div>
        <div className="point-mall" onClick={goTabMall}>使用G优积分兑换更多减时卡&gt;</div>
      </div>: <div className="no-data">
        <img src={empty} alt="" />
        <span>暂无减时卡</span>
        <div className="exchange-btn" onClick={goTabMall}>去积分商城，兑换减时卡</div>
      </div>}
    </Popup>
    
  </>
}

const Card = (item, index, onChooseCard, chooseArr) => {

  return <div 
    className="card-content" 
    key={`${index}-card`}
    onClick={() => onChooseCard(item)}
  >
    <img src={item.bigUrl} alt="" className={`card-img ${item.isAvailable ? 'gray': ''}`} />
    <img src={item.isAvailable ? disabledImg:chooseArr.findIndex((i) => i.timeReduceId === item.timeReduceId) >= 0 ? gouxuan: weigouxuan} alt="" className="check-img" />
    {item.validDays <=5 && <div className="overdue-time">还有{item.validDays}天过期</div>}
  </div>
}

export default ReduceCard;
