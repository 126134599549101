import React from "react";
import './style.scss';

const DonationModal = (props) => {
  const { visible, title, subTitle, content, leftBtnText, leftBtnCB, rightBtnText, rightBtnCB, onClose } = props;
  const leftBtnClick = () => {
    leftBtnCB && leftBtnCB();
    onClose();
  }
  const rightBtnClick = () => {
    rightBtnCB && rightBtnCB();
    onClose();
  }
  if(!visible) return null;
  return (
    <div className="modal-box">
      <div className="modal-container">
        {!!subTitle
          ? <div className="subTitle">{subTitle}</div>
          : <>
            <div className="title">{title}</div>
            <div className="content">{content}</div>
          </>
        }
        <div className="btn-box">
          {leftBtnText && <div className="left-btn btn-common" onClick={leftBtnClick}>{leftBtnText}</div>}
          <div className={`rigth-btn btn-common ${leftBtnText ? 'right-btn-bg': ''}`} onClick={rightBtnClick}>{rightBtnText || '确定'}</div>
        </div>
      </div>
    </div>
  );
};
export default DonationModal;
