import React, {useEffect, useState, useRef} from "react"
import {
  getSearchCollectionSerialList
} from "@/api/projectManager_self";
import {Toast, List} from 'antd-mobile';
import NFTSearchHeader from "@/view/myCollectionSearch/component/searchHeader";
import LeftIndicator from '@/assets/img/collection/left_indicator.png'
import icon_demo from '@/assets/img/collection/icon_demo.png'
import icon_right_arrow from '@/assets/img/collection/icon_right_arrow.png'
import right_list_bg from '@/assets/img/collection/right_list_bg.png'
import "./index.scss"
import {mockTab} from './mock';
import {isEmptyString} from "@/utils/publicFun";

const MyCollectionSearchNavigation = () => {

  const [data, setData] = useState([])
  const [secondData,setSecondData] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    try {
      const {code, msg, data} = await getSearchCollectionSerialList();
      // const { code, msg, data } = await mockTab();
      console.log('code, msg, data',code, msg, data)
      if (code !== '000000') throw msg;
      setData(data)
      setSecondData(data[currentIndex].childs)
      // window.JsBridge.print('ddddd'+ JSON.stringify(data))
    } catch (error) {
      Toast.show(error);
    }
  }

  const onClickLeftItem = (index) => {
    setCurrentIndex(index)
    setSecondData(data[index].childs)
  }

  const onClickRightItem = (item,index) => {
    window.JsBridge.print('item:'+JSON.stringify(item))
    const params = {
      collectionIds: item?.code || '',
      seriesId: data[currentIndex]?.code || '',
      parentCodeName: data[currentIndex]?.codeName || '',
      childCodeName: data[currentIndex]?.childs[index]?.codeName || '',
    }
    window.JsBridge.print('params:'+JSON.stringify(params))
    window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/myCollectionSearchNavigation?params=${encodeURIComponent(JSON.stringify(params))}`});
  }

  const renderLeftItem = (item, index) => {
    // 隐藏guland
    // if(item.code == '11') return null;

    return <div key={item.codeName+index}>
      {currentIndex == index ?
        <div className='leftItemContainerSelected'  onClick={() => onClickLeftItem(index)}>
          <div className="leftIndicator">
            <img src={LeftIndicator} alt=""/>
          </div>
          <div className='leftTitleSelected'><span>{item.codeName}</span></div>
        </div> :
        <div className='leftItemContainerUnselected' onClick={() => onClickLeftItem(index)}>
          <span>{item.codeName}</span>
        </div>}
    </div>
  }

  const renderRightItem = (item, index) => {
    return <div key={item.codeName+index}>
      <div className='rightItemContainer' key={item.codeName+index} onClick={() => onClickRightItem(item,index)}>
          <div className='subTitleContainer'>
            <div className='right-title'>
              {item.codeName}
            </div>
            <img src={icon_right_arrow} alt=''/>
          </div>
        {item.viewAllStatus == "1" ? <div className='right-imag-container'>
            <img className='icon' src={right_list_bg} alt="" />
            <img className='img_bg' src={item.thumbnail} alt="" />
            <div className='img-btn'>
              <div className='img-btn-text'>查看全部</div>
            </div>
          </div> :
          <div className='right-imag-container-single'>
            <img className='icon_dd' src={item.thumbnail} alt="" />
          </div>}
      </div>
    </div>
  }

  const searchVal = useRef('')
  const valueChanged = (val)=>{
    searchVal.current = val
  }

  return (
    <div className="nft-search-page">
      <div className="top">
        <NFTSearchHeader valueChanged={(val=>valueChanged(val))} currentTitle1={0} totalCount={19} onSearchClick={() => {
          if (!isEmptyString(searchVal.current)){
            window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/myCollectionSearchResult?searchText=${encodeURIComponent(searchVal.current)}`});
          }
        }}/>
      </div>
      <div className='ListContainer'>
        <div className='leftList'>
          <List style={{'--border-bottom':'solid 0px var(--adm-border-color)'}}>
            {data?.length > 0 && data?.map((item, index) => {
              return renderLeftItem(item, index)
            })}
          </List>
        </div>
        <div className='rightList'>
          <List style={{'--border-bottom':'solid 0px var(--adm-border-color)'}}>
            {secondData?.map((item, index) => renderRightItem(item, index))}
          </List>
        </div>
      </div>
    </div>
  )
}

export default MyCollectionSearchNavigation
