/**
 * 数字藏品文件调查（投票）
 * route: /myCollectionVote/collectionVote
 */
import React, {useEffect, useState, useRef} from "react"
import {Form, Input, TextArea, Toast} from 'antd-mobile'
import NFTHeader from '@/components/NFTHeader';
import ModalAlert from '@/components/ModalAlert/index';
import {FuQuestionList, luQuestionList, sequenceKeys, YuQuestionList} from '../apiData';
import "./index.scss";
import {getUrlAllParams, isArray, isEmptyObject, isEmptyString, isIOSNew} from "@/utils/publicFun";
import {useLocation} from "react-router";
import clickThrottle from "@/utils/clickThrottle";
import {hideLoading, showLoading} from "@/components/loading";
import {queryCollectionDetail, queryDefCode, voteCommit, voteInit} from "@/api/projectManager_self";
import VoteRuleDialog from "@/view/myCollectionVote/view/voteRuleDialog";

const kSuggestClick = 'kSuggestClick';


const CollectionVote = (props) => {
    const {search} = useLocation();
    const {aniType = '0'} = getUrlAllParams(search.slice(1));
    const [list, setList] = useState([])
    const [enable, setEnable] = useState(false)
    const [btnTitle, setBtnTitle] = useState('提交')
    const [btnState, setBtnState] = useState(0) //0=不可用 1=可用
    const [endTime, setEndTime] = useState('') //结束时间

    const choiceArrRef = useRef([]);
    const advice1Ref = useRef('');
    const advice2Ref = useRef('');

    const [maskVisible, setMaskVisible] = useState(false);


    useEffect(() => {
        localStorage.setItem(kSuggestClick, '')
        if (aniType === '0') { //蝙蝠
            setList(FuQuestionList)
        } else if (aniType === '1') { //鹿
            setList(luQuestionList)
        } else if (aniType === '2') { //鱼
            setList(YuQuestionList)
        }
        loadData().then();
    }, [])

    const loadData = async () => {
        try {
            showLoading();
            const res = await voteInit({type: aniType});
            hideLoading();
            if (res.code !== '000000') throw res.msg;

            let newArr = [];

            if (aniType === '0') { //蝙蝠
                newArr = FuQuestionList;
            } else if (aniType === '1') { //鹿
                newArr = luQuestionList;
            } else if (aniType === '2') { //鱼
                newArr = YuQuestionList;
            }

            const advice1 = res.data?.advice1 || '';
            const advice2 = res.data?.advice2 || '';

            let voteList = res.data?.voteList || {};
            if (!isEmptyObject(voteList)) {
                // console.log(voteList);
                for (let i = 0; i < newArr.length; i++) {
                    let item = newArr[i];
                    if (item.type === 'img' || item.type === 'choice') {
                        let list = item.list;
                        for (let model of list) {
                            for (const key in voteList) {
                                if (model.answerId === key) {
                                    model.vote = voteList[key];
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            for (let i = 0; i < newArr.length; i++) {
                let item = newArr[i];
                if (item.id === 99) {
                    item.input = advice1;
                } else if (item.id === 100) {
                    item.input = advice2;
                }
            }

            let votedInfo = res.data?.votedInfo;
            if (votedInfo && isArray(votedInfo) && votedInfo.length > 0) {
                let votedInfoTem = [];
                for (let indexString of votedInfo) {
                    let index = parseInt(indexString) || 0;
                    votedInfoTem.push(index)
                }
                for (let i = 0; i < votedInfoTem.length; i++) {
                    let index = votedInfoTem[i];//选中的元素
                    if (i < newArr.length) {
                        let item = newArr[i];
                        if (item.type === 'img' || item.type === 'choice') {
                            let list = item.list;
                            if (index < list.length) {
                                list[index].isVote = true;
                            }
                        }
                    }
                }

                setList([...newArr]);
                setBtnTitle('已投票')
                setEnable(false)
            } else {
                setList([...newArr]);
                setEnable(true)
            }
            let endTime = res.data?.endTime || '';
            let currentTime = res.data?.currentTime || '';
            if (!isEmptyString(endTime) && !isEmptyString(currentTime)) {
                const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
                let data = new Date(endTimeStamp);
                let month = data.getMonth() + 1;
                let day = data.getDate();
                month = month >= 10 ? month : '0' + month;
                day = day >= 10 ? day : '0' + day;
                let endTimeTem = `${month}月${day}日`;
                setEndTime(endTimeTem);
                const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))


                if (currentTimeStamp >= endTimeStamp) { //已经结束
                    setBtnTitle('投票结束')
                    setEnable(false)
                }
            }


        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const onCommitAction = () => {// 提交
        if (!clickThrottle()) {
            return;
        }
        if (btnTitle === '已投票' || btnTitle === '投票结束') {
            return;
        }
        if (choiceArrRef.current.length !== 5 || isEmptyString(advice1Ref.current)) {
            window.JsBridge.runAction('alert', 'error', ['您有必选题未投票']);
            return;
        }

        if (btnState === 0) {
            return;
        }
        ModalAlert.show({
            title: '确认提交',
            content: '提交后结果将不可更改，确认提交当前选项吗？',
            onAction: async (item, index) => {
                ModalAlert.hide()
                if (item.key === 'confirm') {
                    let param = {
                        type: aniType,
                        votedInfo: choiceArrRef.current,
                        advice1: advice1Ref.current,
                        advice2: advice2Ref.current,
                    }
                    try {
                        showLoading();
                        const res = await voteCommit(param);
                        hideLoading();
                        if (res.code !== '000000') throw res.msg;
                        // Toast.show('感谢您的参与,已收到投票!');
                        window.JsBridge.runAction('alert', 'error', ['感谢您的参与,已收到投票!']);
                        let timer = setTimeout(() => {
                            clearTimeout(timer);
                            window.JsBridge.back();
                        }, 2000);
                    } catch (error) {
                        hideLoading();
                        Toast.show(error);
                    }
                }
            },
            actions: [
                {
                    key: 'cancel',
                    text: '取消'
                },
                {
                    key: 'confirm',
                    text: '确认提交',
                }
            ]
        })
    }

    const didSelectItem = () => {
        let newArr = [...list];
        setList(newArr)

        try {
            choiceArrRef.current = [];
            for (let model of newArr) {
                // if (model.require) { //必填项
                if (model.type === 'choice' || model.type === 'img') { //选择的
                    let list = model.list;
                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        if (item.isVote) {
                            choiceArrRef.current.push(i + '');
                        }
                    }
                } else if (model.type === 'input') {
                    if (model.id === 99) { //必填项1
                        advice1Ref.current = model.input;
                    } else if (model.id === 100) { //非必填项
                        advice2Ref.current = model.input;
                    }
                }
                // }
            }
            if (choiceArrRef.current.length === 5 && !isEmptyString(advice1Ref.current)) {
                setEnable(true);
                setBtnState(1);
            } else {
                setEnable(false);
                setBtnState(0);
            }
        } catch (err) {
            console.log("错误信息：" + err.message);
        }
    }

    const showRule = () => {
        if (!clickThrottle()) {
            return;
        }
        setMaskVisible(!maskVisible);
    }

    let title = 'Ape World 蝠-共创意见征集';
    if (aniType === '0') {
        title = 'Ape World 蝠-共创意见征集';
    } else if (aniType === '1') {
        title = 'Ape World 鹿-共创意见征集';
    } else if (aniType === '2') {
        title = 'Ape World 鱼-共创意见征集';
    }

    return (
        <div className="collectionvote_page">
            <NFTHeader midText={title} bgColor={'#FFF'}/>
            <div className="vote_body">
                <div className="top-info">
                    <div>
                        <img src={require('@/assets/img/collection/icon_endtime.png')}/>
                        <span>截止时间：</span>
                        <span>{endTime}</span>
                    </div>
                    <div onClick={() => showRule()}>
                        <img src={require('@/assets/img/collection/icon_rule.png')}/>
                        <span>{'活动规则 >'}</span>
                    </div>
                </div>
                <div className="question-box">
                    {
                        list.map((item, i) => {
                            return <QuestionItem item={item} num={i} key={i} didSelectItem={didSelectItem}
                                                 enable={(btnTitle === '已投票' || btnTitle === '投票结束') ? false : true}/>
                        })
                    }

                </div>
            </div>
            <div className="bottom-area">
                <div className={`commit-btn${btnState === 1 ? ' enable' : ''}`} onClick={() => {
                    onCommitAction()
                }}>
                    <span>{btnTitle}</span>
                </div>
            </div>
            <VoteRuleDialog maskVisible={maskVisible} setMaskVisible={setMaskVisible}/>
        </div>
    )
}

export default CollectionVote;

const QuestionItem = (props) => { //一组
    const {item = {}, num = 0, enable = true} = props
    const [selectItem, setSelectItem] = useState({})
    const inputRef = useRef(null);


    const onClick = (question) => {
        if (!enable) return;
        // 单选
        if (item.multiple && item.multiple === 1) {
            if (selectItem.id !== question.id) {
                question.isVote = !question.isVote
                selectItem.isVote = !selectItem.isVote
                setSelectItem(question)
            }
        }
        props.didSelectItem && props.didSelectItem()
    }

    const onChange = (text) => {
        item.input = text;
        props.didSelectItem && props.didSelectItem()
    }

    const clickInput = (item) => {
        if (!clickThrottle()) {
            return
        }
        if (!enable) {
            return;
        }
        window.JsBridge.addListener().then(() => { //点击回来
            let res = localStorage.getItem(kSuggestClick);
            if (!isEmptyString(res)) {
                res = JSON.parse(res);
                if (res.type === 1) {
                    item.input = res.currentValue;
                    props.didSelectItem && props.didSelectItem()
                    localStorage.setItem(kSuggestClick, '')
                }
            }
        });
        let content = encodeURIComponent(item.input);
        window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/myCollectionVote/editSuggest?content=${content}`});
    }

    const inputLayout = (item) => {
        return (
            <>
                {
                    item.multlines ?
                        <div className="textArea-content"
                             onClick={() => clickInput(item)}>
                            <TextArea maxLength={200}
                                      rows={4}
                                      ref={inputRef}
                                      id={'input'}
                                      showCount
                                      placeholder={'请输入：'}
                                      readOnly={true}
                                      value={item.input}
                                      autoSize={true}
                            />
                        </div>
                        :
                        <div className="input-content">
                            <TextArea maxLength={10}
                                      rows={1}
                                      showCount
                                      placeholder={'请输入：'}
                                      readOnly={!enable}
                                      onChange={(e) => onChange(e)}
                                      value={item.input}
                            />
                        </div>
                }
            </>
        )

    }

    const questionOptions = (question, index) => {
        let vote = 0;
        if (question?.vote) {
            vote = parseInt(question?.vote) || 0;
            if (vote > 99999) {
                vote = '10万+';
            }
        }

        return (
            <div className={`question-option${question.isVote ? ' select' : ''}`} onClick={() => onClick(question)}>
                <span>{sequenceKeys[index] + ' ' + question.title}</span>
                <span>{vote + '票'}</span>
            </div>
        )
    }

    return (
        <div className="question-item">
            <span className={`question-title${item.require ? ' require' : ''}`}>{`${num + 1}.${item.title}`}</span>
            {
                item.type == 'img' ?
                    <div className="question-options-wrap">
                        {
                            item.list && item.list.map((q, index) => {
                                return (
                                    <div className={`option${index % 2 == 0 ? ' left' : ''}`} key={index}>
                                        <img src={q.url} alt=""/>
                                        {questionOptions(q, index)}
                                    </div>
                                )
                            })
                        }
                    </div> :
                    item.type == 'input' ?
                        <>{inputLayout(item)}</> :
                        <>
                            {
                                item.list && item.list.map((q, index) => {
                                    return (
                                        <div className={'option'} key={index}>
                                            {questionOptions(q, index)}
                                        </div>
                                    )
                                })
                            }
                        </>
            }
        </div>
    )
}
