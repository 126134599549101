import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./collectionComp.scss";


const CollectionComp = ({ isAll, collection }) => {
  const navigate = useNavigate();
  let { collectionName, updatedAt, nftHash, nftImage,userImage, nftNo, transferStatus, state, transferId, sender, transferTime, associatedCollection, parent,clientId } = collection;

  const goCollectionDetail = (event) => {
    event.preventDefault();
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", {uri: `${window.location.origin}/myCollectionDetail?nft_hash=${nftHash}&isAll=${isAll}&clientId=${clientId}&userImage=${userImage}`});
    }
  };

  const receiveDonatin = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("Web", { uri: `${window.location.origin}/receiveDonation?transferId=${transferId}` });
    } else {
      navigate(`/receiveDonation?transferId=${transferId}`);
    }
  }
  return (
    <>
      <div className="collection_detail_search">

      <div className="content" onClick={goCollectionDetail}>
            {transferStatus === 2 && <div className="status-tag">转赠中</div>}
            <div className="avatar">
              <img className={transferStatus === 2 ? 'don-status' : ''} src={nftImage} alt="" />
            </div>
            <div className="text">
              <div className="collection-name">{collectionName}</div>
              {!isAll ?
            <div className="collection-date">
              <span>收藏于 </span>
              <span className="collection_date">
                {updatedAt?.slice(0, 10)}
              </span>
            </div>
            :
            <div className="head-content">
              <div className="head-image">
                <img src={userImage} alt="" />
              </div>
              <div className="nft-no">#{nftNo}</div>
            </div>}
            </div>

          </div>
      </div>
    </>
  );
};

export default CollectionComp;
