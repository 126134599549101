/**
 * @description ape world 配件盲盒
 * @date 2024-07-25
*/
import Home from './home';
import UpExchange from './up-exchange';
import ExchangeHistory from './exchangeHistory';

export default {
    Home,
    UpExchange,
    ExchangeHistory
}