import React from "react";
import reportWebVitals from "./reportWebVitals";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AliveScope } from 'react-activation'
import './flexible.js';
import '@/assets/font/font.css'

const rootElement = document.getElementById("root");
render(
  // <React.StrictMode>
  <BrowserRouter>
    <AliveScope>
      <App />
    </AliveScope>
  </BrowserRouter>,
  // </React.StrictMode>,
  rootElement
);
if(process.env.REACT_APP_ERUDA) {
  window.eruda.init()
}
reportWebVitals();
