/**
 * @description 获奖名单的跑马灯展示模块
*/

import React, { useState, useEffect, useMemo } from 'react';
import { Toast, Swiper } from 'antd-mobile';
import './index.scss';

const WinnerSwiper = (props)=> {
    const {
        records=[
            {loginName: '177****6789', prizeName:'艾艾贴牌艾膝贴（新）14贴/袋', prizeType: ''},
            {loginName: '137****6780', prizeName:'艾艾贴牌艾腰贴（新）14贴/袋', prizeType: ''},
            {loginName: '137****6781', prizeName:'艾艾贴牌艾膝贴（新）14贴/袋', prizeType: ''},
            {loginName: '137****6782', prizeName:'艾艾贴牌艾颈贴（新）14贴/袋', prizeType: ''},
        ],
        showCount=4,
        size=25
    } = props

    const swiperRecords = useMemo(()=>{
        // if(records.length <= showCount * 2 - 1) {
        //     return [...records,...records]
        // }
        return records
    },[records,showCount])

    return (
        <div className='winner-swiper1'>
            {
                records && records.length > 0 &&
                <div className='winner-list'>
                    <Swiper
                        style={{ '--height': (size * showCount) + 'px', paddingBottom: size * 0.5 }}
                        direction={'vertical'}
                        allowTouchMove={false}
                        stuckAtBoundary={false}
                        autoplay={records.length>4? true:false}
                        loop={true}
                        autoplayInterval={1500}
                        slideSize={ Math.floor(120/showCount)}
                        indicator={() => null}
                    >
                        {
                            swiperRecords.map(({loginName, prizeName}, index) => (
                                <Swiper.Item key={index}>
                                    <div className="winner-swiper-item">
                                        <span>恭喜用户{loginName}获得</span><span>{prizeName}</span>
                                    </div>
                                </Swiper.Item>
                            ))
                        }
                    </Swiper>
                </div>
            }

        </div>
    )
}

export default WinnerSwiper;
