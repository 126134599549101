import requests from "@/utils/requests_self";
import qs from "query-string";

// 活动初始化接口
export const activityInit = async (params = {}) => {
    return await requests.post(
        "/customer/bitcoinGuess/activityInit",
        JSON.stringify(params)
    );
};

//竞猜投注接口
export const guess = async (params = {}) => {
    return await requests.post(
        "/customer/bitcoinGuess/guess",
        JSON.stringify(params)
    );
};
//竞猜记录查询
export const guessRecord = async (params) => {
    return await requests.get(
        "/customer/bitcoinGuess/guessRecord", { params }
    );
};

// 矿工盲盒指定的编号列表
export const getActivityNftPool = async (params = {}) => {
  return await requests.post(
      "/customer/activity/v1/getActivityNftPool",
      JSON.stringify(params)
  );
};

// s级藏品数量
export const getActivityNftCount = async (params = {}) => {
  return await requests.post(
      "/customer/activity/v1/getActivityNftCount",
      JSON.stringify(params)
  );
};

// 矿工猴回收
export const simpleRdo = async (params = {}) => {
  return await requests.post(
      "/customer/recoveryActivity/v1/simpleRdo",
      JSON.stringify(params)
  );
};

// 矿工猴列表
export const getWorkapeList = async (params = {}) => {
  return await requests.post(
      `/customer/recoveryActivity/v1/list?currentPage=${params.currentPage}&pageSize=${params.pageSize}`,
      JSON.stringify(params)
  );
};

// 回收升级记录
export const upgradeRecord = async (params = {}) => {
  return await requests.post(
      "/customer/recoveryActivity/v1/getRecoveryRecord",
      JSON.stringify(params)
  );
};

// 获取活动时间
export const getActivityTime = async (params) => {
  return await requests.get(
      `/customer/recoveryActivity/v2/getActivityTime/${params.activityCode}`
  );
};

// 使用空间靓号
export const spaceNiceNoUse = async (params = {}) => {
  return await requests.post(
      "/customer/transfer/v1/useSpaceNiceNo",
      JSON.stringify(params)
  );
};

// 取消使用空间靓号
export const cancelUseSpaceNiceNo = async (params = {}) => {
  return await requests.post(`/customer/transfer/v1/cancelUseSpaceNiceNo/${params.spaceNiceNo}`);
};

// 查询是否已使用过空间靓号
export const hasSpaceNiceNoUsed = async (params = {}) => {
  return await requests.post(
      "/customer/transfer/v1/hasSpaceNiceNoUsed",
      JSON.stringify(params)
  );
};
