import React, {useState, useEffect, useRef} from "react";
import ReactDOM from 'react-dom';
import gift1 from '../../imgs/img_300.png';
import gift2 from '../../imgs/img_monkey1.png';
import tuceng from '../../imgs/pic_gjq.png';
import point from '../../imgs/img_integral1.png';
import './style.scss';
import GuaGuaKa from "../guaguaka";
import {hideLoading, showLoading} from "@/components/loading";
import { Toast } from 'antd-mobile';
import { unityOpenScratchCard } from "@/api/projectManager_self";
import ScratchModal from "../scratchModal";
import mockApi from '../../home/mock';
import { activityCode } from "../../home";

const ScratchView = ({cardInfo, refresh}) => {
  const [cardMaskStatus, setCardMaskStatus] = useState(true);
  const prizeInfoRef = useRef(null);
  const { remainDrawCount, drawTime, drawEndTime, systemTime, isCertificate } = cardInfo || {};

  const startT = new Date(drawTime?.replace(/-/g, "/")).getTime();
  const endT = new Date(drawEndTime?.replace(/-/g, "/")).getTime();
  const currentT = new Date(systemTime?.replace(/-/g, "/")).getTime();
  const statusFn = () => {
    let cardStatus = 0;
    if(currentT < startT) {
      cardStatus = 0;
    } else if(currentT >= startT && currentT < endT) {
      cardStatus = 1;
    } else if(currentT >= endT) {
      cardStatus = 2;
    }
    return cardStatus;
  }

  useEffect(() => {
    !cardMaskStatus && setCardMaskStatus(true);
  }, [cardInfo])

  const rerenderDom = (prizeInfo, callBack) => {
    ReactDOM.render(
      <GuaGuaKa
        color="#808080"
        img={tuceng}
        round={[10,20,10,20]}
        size={30}
        percentage={60}
        clear={true}
        mode="move"
        onSuccess={onSuccess}
      >
        <div className='s1'>
          {prizeInfo.type === 5 ? <div className="ape-type">
            <img src={prizeInfo.nftImage} alt="" />
            <span className="price-des">恭喜获得史诗款无聊猿</span>
          </div>:
          <div className="point-type">
            <img src={point} alt="" />
            <span className="price-des">{`恭喜中奖${prizeInfo.value}积分`}</span>
          </div>}
        </div>
      </GuaGuaKa>,
      document.getElementsByClassName('gua-gua-ka-box')[0],
      () => {
        callBack && callBack()
      }
    )
  }

  const onSuccess = async () => {
    setTimeout(() => {
      ScratchModal.open({
        leftCB: () => {
          ReactDOM.unmountComponentAtNode(document.getElementsByClassName('gua-gua-ka-box')[0]);
          refresh();
        },
        rightCB: () => {
          ReactDOM.unmountComponentAtNode(document.getElementsByClassName('gua-gua-ka-box')[0]);
          refresh();
        },
        prizeInfo: prizeInfoRef.current
      });
    }, 1000);
  }

  const handleOpen = async () => {
    if(statusFn() !== 1 || remainDrawCount <= 0) return;
    try {
      showLoading();
      const res = await unityOpenScratchCard(cardInfo.remark, {activityCode});
      // const res = await mockApi.getCardInfoMock();
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      prizeInfoRef.current = res.data;
      rerenderDom(res.data, () => setCardMaskStatus(false));
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  return <div className='unity-scratch-view'>
    <div className="scratch-card-bg-box">
      <div className="gua-gua-ka-box"></div>
      {cardMaskStatus && <div className="scratch-card-top" onClick={handleOpen}>
        <div className="inner-bg">
          {statusFn() === 0 && <div className="scratch-btn gray-bg">未开始</div>}
          {statusFn() === 1 && <div className={`scratch-btn ${Number(remainDrawCount) === 0 ? 'gray-bg': ''}`}>点我刮奖</div>}
          {statusFn() === 2 && <div className="scratch-btn gray-bg">活动结束</div>}
          {statusFn() === 2 ? null: <div className="scratch-count">剩余{isCertificate === 0 ? '-' : (remainDrawCount || 0)}次</div>}
        </div>
      </div>}
    </div>
    <div className="gifts-box">
      <img src={gift1} alt="" />
      <img src={gift2} alt="" />
    </div>
  </div>
}

export default ScratchView;
