import React, {useState, useEffect, useRef} from 'react';
import styles from './styles.module.scss'
import ListView from "@/components/AntListView";
import collection from "@/assets/img/variationApe/collection-temp.png";
import {hideLoading, showLoading} from "@/components/loading";
import {getRemainCount, queryPartsList, recoveryActivityList} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";
import RecycleModal from "@/view/partsRecyclingUnity/components/recycleModal";
import NoticeUtil from "@/utils/NoticeUtil";
import {activityCode} from "@/view/partsRecyclingUnity/home";

//回收得史诗款/押注
const RecycleEpicApeOrBet = (props) => { //type=0 史诗款 1=押注
    const [data, setData] = useState([]);
    const [remainCount, setRemainCount] = useState(0)
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);
    const currentRef = useRef(null);
    const [choiceArr, setChoiceArr] = useState([]);
    const maxChoiceCount = 3;


    useEffect(() => {
        loadData(true);
        getremindCount();
        NoticeUtil.on('hecheng-success', () => {
            loadData(true);
            getremindCount();
        })
    }, [])

    const clickItem = (item) => {
        if (item.isSel) { //取消选中
            item.isSel = !item.isSel;
        } else { //选中新的
            let selDataTem = data.filter(item => item.isSel === true);
            if (selDataTem.length >= maxChoiceCount) {
                Toast.show(`每次最多可选择${maxChoiceCount}个`);
                return;
            }
            item.isSel = !item.isSel;
        }
        setData([...data]);
        let arr = [];
        for (const item1 of data) {
            if (item1.isSel) {
                arr.push(item1);
            }
        }
        setChoiceArr(arr);
    }

    const getremindCount = async () => {
        try {
            const params = {
                activityCode: activityCode,
            }
            const res = await getRemainCount(params);
            if (res.code !== '000000') throw res.msg;
            setRemainCount(res.data?.remainCount || 0);
        } catch (error) {
            Toast.show(error);
        }
    }

    const data1 = {
        code: '000000',
        msg: 'success',
        data: {
            total: 1,
            list: [
                {
                    "nftNo": "9rI7aRBNSEk0hYIxj9Nfa4yD5f5XypuU",
                    "nftImage": require('../../imgs/icon_monkey1.png'),

                }
            ]
        }
    }

    const getMockData = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(data1);
            }, 1000);
        })
    }

    const loadData = async (isRefresh) => {
        try {
            if (isRefresh) {
                currentPage.current = 1;
                setChoiceArr([]);
            } else {
                currentPage.current = currentPage.current + 1;
            }
            const params = {
                currentPage: currentPage.current,
                pageSize: pageSize.current,
            }
            const params1 = {
                activityCode: activityCode,
            }
            showLoading();
            const res = await recoveryActivityList(params,params1);
            // const res = await getMockData();
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            const newList = res.data.list || [];
            if (currentPage.current === 1) {
                setData(newList);
            } else {
                setData([...data, ...newList]);
            }
            isRefresh && setIsLoad(true);
            setHasMore(newList.length >= pageSize.current);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const btnClick = () => { //props.type === 0 ? '回收得史诗猿' : '回收并押注'
        if (choiceArr.length === 3) {
            RecycleModal.open({type: props.type, list: choiceArr});
        }
    }

    const RenderItem = (item) => {
        return <div className={styles.parts_item}>
            <div
                className={`${styles.epic_funds_item} ${item.isSel ? `${styles.active}` : choiceArr.length >= maxChoiceCount ? `${styles.disabled}` : ''}`}
                onClick={() => clickItem(item)}>
                <img src={item.nftImage} alt=""/>
            </div>
            <div className={styles.epic_num}>#{item.nftNo}</div>
        </div>
    }

    return (
        <div className={styles.recycle_epic_ape_bet_container}>
            <div className={styles.left}>
                <div className={styles.top}>
                    <p>请选择回收材料</p>
                    <p>剩余回收次数:{remainCount}</p>
                </div>

                <div className={styles.list}>
                    <ListView
                        dataSource={data}
                        renderItem={RenderItem}
                        hasMore={hasMore}
                        // onRefresh={() => {
                        //     loadData(true)}
                        onEndReached={() => loadData(false)}
                        customStyle={{
                          imgStyle: {width: 100, height: 100},
                          textStyle: {color: '#FFFFFF', fontSize: 12}
                        }}
                        isLoad={isLoad}
                    />
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.placeholder}/>
                <div className={styles.right_container}>
                    <img
                        src={props.type === 0 ? require('../../imgs/icon_monkey1.png') : require('../../imgs/img_integral1.png')}
                        alt="" className={props.type === 0 ? styles.img0 : styles.img1}/>
                    <p className={`${styles.huishou_btn} ${choiceArr.length === 3 ? '' : styles.btn_dis}`}
                       onClick={() => btnClick()}>{props.type === 0 ? '回收得史诗猴' : '回收并押注'}</p>
                </div>
            </div>
        </div>
    )
}

export default RecycleEpicApeOrBet;
