import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import gem from '@/assets/img/landfi/lease-gem.png';
import CountDown from "../countDown";

const LeaseItem = (props) => {
  const [radioStatus, setRadioStatus] = useState(false);
  const {
    item = {}, 
    onClick = () => {}, 
    onSelect = () => {}, 
    showChoose = false, 
    showTime = false,
    showTag = false,
    isLand,
  } = props;

  useEffect(() => {
    if (!showChoose) {
      setRadioStatus(false)
    }
  }, [showChoose])

  const onItemClick = () => {
    if (showChoose) {
      setRadioStatus(!radioStatus);
      onSelect && onSelect(item, radioStatus)
      return
    }
    onClick && onClick(item)
  }

  return <div className={styles.land_lease_item} onClick={onItemClick}>
    <div className={styles.land_lease_item_box}>
      <div className={`${styles.item_out} ${showTime ? styles.item_big: ''}`}>
        {showTag && item.status === 1 ? <div className={styles.work_tag}>{item.type === 1 ? '工作中' : '已使用'}</div>: null}
        <div className={styles.item_top}>
          {showChoose ? <div className={`${styles.radio} ${radioStatus ? styles.active: ''}`}></div>: null}
          <div className={`${styles.item_left} ${isLand ? styles.land_img: ''}`}><img src={item.nftImage} alt="" /></div>
          <div className={styles.item_right}>
            <div className={styles.goods_name}>{item.collectionName}</div>
            <div className={styles.goods_lease_days}>天数：{item.rentalPeriod === -1 ? '永久' : item.rentalPeriod + '天'}</div>
            <div className={styles.goods_price}>
              <img src={gem} alt="" />
              <span>{item.rentalFee}</span>
            </div>
          </div>
        </div>
      </div>
      {showTime ? <div className={styles.lease_term_time}>
        <span>剩余</span>
        <span><CountDown endTime={item?.rentalExpireTime} sysTime={item?.currentTime}/></span>
      </div>: null}
    </div>
  </div>
}

export default LeaseItem;