import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import deleteImg from '@/assets/img/variationApe/delete-icon.png';
import addSign from '@/assets/img/variationApe/add-sign.png';
import equalSign from '@/assets/img/variationApe/equal-sign.png';
import collection from '@/assets/img/variationApe/collection-temp.png';
import material from '@/assets/img/variationApe/material.png';
import { Toast } from 'antd-mobile';
import {hideLoading, showLoading} from "@/components/loading";
import ListView from '@/components/AntListView';
import ComposeModal from '../components/composeModal';
import SuccessModal from '../components/successModal';
import { queryAppointTypeCollection, variatonApeCompose } from '@/api/projectManager_self';
import ModalAlert from "@/components/ModalAlert";
import {throttle} from '@/utils/publicFun';

const mock = {
  code: '000000',
  msg: 'success',
  data: {
    nftComponentVO: [
      {
        nftNo: '0',
        id: 0,
        collectionId: '',
        collectionName: '',
        imageBg: collection,
      },
      {
        nftNo: '1',
        id: 1,
        collectionId: '',
        collectionName: '',
        imageBg: collection,
      },
      {
        nftNo: '2',
        id: 2,
        collectionId: '',
        collectionName: '',
        imageBg: collection,
      },
      {
        nftNo: '3',
        id: 3,
        collectionId: '',
        collectionName: '',
        imageBg: collection,
      },
      {
        nftNo: '4',
        id: 4,
        collectionId: '',
        collectionName: '',
        imageBg: collection,
      },
    ]
  }
}

const fetch = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(
        {
          code: '000000', 
          msg: 'error', 
          data: {
            nftImage: material,
            nftNo: '888888'
          }
        }
      )
    }, 2000);
  })
}

const chooseList = [
  {
    nftNo: '',
    imageBg: '',
  },
  {
    nftNo: '',
    imageBg: '',
  },
  {
    nftNo: '',
    imageBg: '',
  },
]

const fn = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(mock)
    }, 1000);
  })
}

const VariationMedicine = () => {
  const [choosedMedicineList, setChooseMedicineList] = useState(chooseList);
  const [epicCollectionList, setEpicCollectionList] = useState([]);
  const [chooseNum, setChooseNum] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const currentRef = useRef(null);
  const resRef = useRef(null);
  const composeInfo = useRef(null);
  const errorRef = useRef(null);
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

  useEffect(() => {
    window.JsBridge.setTitle('变异药水');
    getEpicFundCollectionList();
    onDidFocus();
  }, [])

  useEffect(() => {
    const idArr = choosedMedicineList.map((i) => i.id);
    const newEpicList = epicCollectionList.map((i) => {
      return {...i, isChoosed: idArr.includes(i.id) ? true: false};
    })
    setEpicCollectionList(newEpicList);
  }, [choosedMedicineList])

  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      getEpicFundCollectionList();
      onDidFocus();
    });
  }

  const getEpicFundCollectionList = async (isRefresh=true, isLoading=true) => {
    try {
      if(isRefresh) {
        currentPage.current = 1;
        setChooseMedicineList(chooseList);
        setChooseNum(0);
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        current: currentPage.current,
        size: pageSize.current,
        collectionType: 1,
        clientId: userInfo?.clientId
      }
      isLoading && showLoading();
      const res = await queryAppointTypeCollection(params);
      // const res = await fn();
      isLoading && hideLoading();
      if (res.code !== '000000') throw res.msg;
      if(currentPage.current === 1) {
        setEpicCollectionList(res.data.nftComponentVO);
      } else {
        setEpicCollectionList([...epicCollectionList, ...res.data.nftComponentVO]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res.data.total > [...epicCollectionList, ...res.data.nftComponentVO].length);
    } catch (error) {
      isLoading && hideLoading();
      Toast.show(error);
    }
  }

  const chooseComposeMedicine = (item) => {
    const newList = [...choosedMedicineList];
    const chooseCount = newList.filter((i) => i.id != undefined);
    if(chooseCount.length >= 3 && !item.isChoosed) return;
    const index = newList.findIndex((i) => i.id === item.id);
    if(index < 0) {
      if(chooseCount.length >= 3) {
        newList.splice(2, 1, {...item, isChoosed: true});
      } else {
        newList.splice(chooseCount.length, 1, {...item, isChoosed: true});
      }
    } else {
      newList.splice(index, 1, {nftNo: '', imageBg: ''});
    }
    let newArr = [];
    newList.forEach((i) => {
      if(i.id != undefined) {
        newArr.push(i);
      }
    })
    if(newArr.length === 0) {
      newArr.push(...[{nftNo: '', imageBg: ''}, {nftNo: '', imageBg: ''}, {nftNo: '', imageBg: ''}]);
    }
    if(newArr.length === 1) {
      newArr.push(...[{nftNo: '', imageBg: ''}, {nftNo: '', imageBg: ''}]);
    }
    if(newArr.length === 2) {
      newArr.push({nftNo: '', imageBg: ''});
    }
    setChooseMedicineList(newArr);
    setChooseNum(newArr.filter((i) => i.id != undefined).length);
  }

  const animationStart = () => {
    ModalAlert.show({
      getContainer: currentRef.current,
      afterClose: ()=> {
        SuccessModal.open({
          collectionInfo: composeInfo.current, 
          leftCB: () => getEpicFundCollectionList(true, false),
          rightCB: () => {
            window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/myCollection?code=06&subCode=30` });
          }
        });
      },
      afterShow: () => {
        animationEnd();
      },
      content: <video 
                id='video'
                width="100%"
                height="100%"
                style={{background: '#000000'}}
                src={require('@/assets/img/variationApe/compose-video.mp4')}
                autoPlay="autoplay"
                playsInline={true}
                muted
              />
    });
  }

  const animationEnd = () => {
    const videoDOM = document.getElementById('video');
    videoDOM.addEventListener('ended', () => {
      ModalAlert.hide();
    })
  }

  const handleCompose = throttle( async () => {
    try {
      showLoading();
      resRef.current = null;
      const nftNoList = choosedMedicineList.map((i) => i.nftNo);
      const res = await variatonApeCompose({activeCoding: 1, nftNoList: nftNoList});
      // const res = await fetch();
      hideLoading();
      resRef.current = res;
      errorRef.current = res.msg;
      if(res.code != '000000') {
        composeInfo.current = null;
        throw res.msg;
      }
      composeInfo.current = res.data;
      animationStart();
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }, 2000)

  const RenderItem = (item) => {
    return <div 
      className={`epic-funds-item ${item.isChoosed ? 'active': chooseNum >= 3 && choosedMedicineList.findIndex((i) => i.id === item.id) < 0 ? 'disabled': ''}`} 
      onClick={() => chooseComposeMedicine(item)}
    >
      <div className="epic-num">#{item.nftNo}</div>
      <img src={item.imageBg} alt="" />
    </div>
  }

  return <div className='compose-medicine-page' ref={currentRef}>
    <div className="compose-material-container">
      <div className="material-box">
        <div className="img-box">
          <div className="equal-sign-left">
            {choosedMedicineList.map((item, index) => {
              return <div className='material-item' key={`item-${index}`}>
                <div className="material-collection-img">
                  {item.imageBg ? <img className='material-img' src={item.imageBg} alt="" />:<img className='add-sign' src={addSign} alt="" />}
                </div>
              </div>
            })}
          </div>
          <div className="equal-sign-middle"><img src={equalSign} alt="" /></div>
          <div className="equal-sign-right">
            <img src={material} alt="" />
          </div>
        </div>
        <div className="text-box">
          <div className="equal-sign-left">
            {choosedMedicineList.map((item, index) => {
              return <div className='material-item' key={`item-${index}`}>
                {item.nftNo ? <div className='num-img-box'>
                  <div className="collection-num">#{item.nftNo}</div>
                  <img className='delete-img' src={deleteImg} alt="" onClick={() => chooseComposeMedicine(item)}/>
                </div>: null}
              </div>
            })}
          </div>
          <div className="equal-sign-middle"></div>
          <div className="equal-sign-right">
            <span>变异药水x1</span>
          </div>
        </div>
      </div>
      <div 
        className={`compose-btn ${chooseNum >= 3 ? 'available': ''}`} 
        onClick={() => {
          if(chooseNum < 3) return;
          ComposeModal.open({confirmCB: handleCompose, materialList: choosedMedicineList, type: '药水'});
        }}
      >
          合成变异药水
      </div>
    </div>
    <div className="epic-funds-collection">
      <div className="choose-tip">请选择合成材料</div>
      <div className="epic-funds-list">
        <ListView 
          dataSource={epicCollectionList}
          renderItem={RenderItem}
          hasMore={hasMore}
          onEndReached={() => getEpicFundCollectionList(false, false)}
          style={{paddingTop: 50}}
          isLoad={isLoad}
        />
      </div>
    </div>
  </div>
}



export default VariationMedicine;