/**
 * @description 权益获得记录
 * @param {}
 * @returns { /kangyang-nft/mergeHistory }
*/

import React, {useEffect, useState, useRef} from 'react';
import { Toast } from 'antd-mobile';
import NFTHeader from '@/components/NFTHeader';
import { hideLoading, showLoading } from "@/components/loading";
import ListView from '@/components/AntListView';
import Service from '../service';
import Style from './index.module.scss';

const MergeHistory = () => {
    const [dataSource, setDataSource] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);

    useEffect(()=>{
        loadData(true)
    },[])

    const loadData = async (isRefesh=false) => {
        const param = {
            pageSize: pageSize.current,
            currentPage: currentPage.current
        }
        try {
            isRefesh && showLoading();
            const res = await Service.getRecoveryRecord(param);
            isRefesh && hideLoading();
            if (res.code !== '000000') throw res.msg;
            const newList = res.data.list || [];
            if(currentPage.current === 1) {
                setDataSource(newList);
            } else {
                setDataSource([...dataSource, ...newList]);
            }
            setHasMore(newList.length >= pageSize.current);
        } catch (error) {
            isRefesh && hideLoading();
            Toast.show(error);
        }
    }

    const loadMore = async () => {
        currentPage.current += 1;
        await loadData()
    }

    const renderItem = (item) => {
        return (
            <div className={Style.record_item}>
                <div className={Style.title}>
                    <div>{`${item.provideCycle ?? '--'}期`}</div>
                    <div>{`验仓个数：${item.verifyNum ?? 0}`}</div>
                </div>
                <div className={Style.bottom}>
                    <div>
                        <p>获得奖品</p>
                        <div>
                            <span>{`积分：${item.point ?? '--'}`}</span>
                            {
                                item.speedRetarderDOList?.length > 0 &&
                                <div>{`加速卡：`}
                                {
                                    item.speedRetarderDOList.map((card,k)=> {
                                        return (
                                            <span key={k}>{`${card.cardName} x${card.num}`}<br/></span>
                                        )
                                    })
                                }
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <p>发放时间</p>
                        <div>{item.provideTime ?? '---'}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={Style.mergeHistory}>
            <NFTHeader midText={`权益获得记录`} bgColor={'#FFFFFF'}/>
            <div className={Style.historyList}>
                <ListView 
                    dataSource={dataSource}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    isLoad={true}
                    style={{paddingTop: 50}}
                    onRefresh={()=>{
                        currentPage.current = 1;
                        loadData()
                    }}
                    onEndReached={loadMore}
                />
            </div>
        </div>
    )
}

export default MergeHistory;