import React, {useEffect, useRef, useState} from 'react';
import {Swiper} from "antd-mobile";
import clickThrottle from "@/utils/clickThrottle";
import NFTHeader from "@/components/NFTHeader";
import {Platform} from "@/utils/publicFun";
import NoticeUtil from "@/utils/NoticeUtil";
import styles from './styles.module.scss'
import CouponList from "@/view/myCoupon/couponList";
import {guess_juanbao_count} from "@/constant";


const MyCoupon = () => {

    return (
        <div className={styles.get_coupon_container}>
            <NFTHeader midText={'我的券包'} bgColor={'#fff'} backType={2}/>
            <ContentView/>
        </div>
    )
}


const ContentView = (props) => {

    const swiperRef = useRef(null);
    const clickIndex = (index) => {
        swiperRef.current && swiperRef.current.swipeTo(index);
    }

    return (
        <div className={styles.cartoon_record_com}>
            <HeaderView clickIndex={clickIndex}/>
            <div style={{
                width: '100%',
                display: 'flex',
                overflow: 'hidden',
                flex: 1,
                paddingBottom: Platform.isApp() ? 50 : 30,
            }}>
                <Swiper
                    style={{flex: 1, display: 'flex'}}
                    direction='horizontal'
                    indicator={() => null}
                    ref={swiperRef}
                    allowTouchMove={false}
                    defaultIndex={0}>
                    <Swiper.Item style={{height: '100%'}}>
                        <CouponList type={0}/>
                    </Swiper.Item>
                    <Swiper.Item style={{height: '100%'}}>
                        <CouponList type={1}/>
                    </Swiper.Item>
                    <Swiper.Item style={{height: '100%'}}>
                        <CouponList type={2}/>
                    </Swiper.Item>
                </Swiper>
            </div>
        </div>
    )
}

const HeaderView = (props) => {
    const [selIndex, setSelIndex] = useState(0);
    const currentIndex = useRef(0);
    const [total0, setTotal0] = useState(0);
    const [total1, setTotal1] = useState(0);
    const [total2, setTotal2] = useState(0);

    useEffect(() => {
        NoticeUtil.on(guess_juanbao_count, (res) => { //type total
            let type = res.type || 0;
            let total = res.total || 0;
            if (type === 0) {
                setTotal0(total);
            } else if (type === 1) {
                setTotal1(total);
            } else if (type === 2) {
                setTotal2(total);
            }
        })
    }, [])

    const clickIndex = (index) => {
        if (!clickThrottle(1000)) {
            return
        }
        if (currentIndex.current === index) {
            return;
        }
        currentIndex.current = index;
        setSelIndex(index);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            props.clickIndex && props.clickIndex(index)
        }, 100)
    }

    return (
        <div className={styles.invite_record_header_container}>
            <div className={styles.invite_record_header}>
                <div className={styles.heder_title_com}
                     onClick={() => clickIndex(0)}>
                    <div
                        className={selIndex === 0 ? styles.header_title_sel : styles.header_title_dis}>{`未使用(${total0})`}</div>
                    {selIndex === 0 ? <div className={styles.line}/> : null}

                </div>
                <div className={styles.heder_title_com}
                     onClick={() => clickIndex(1)}>
                    <div
                        className={selIndex === 1 ? styles.header_title_sel : styles.header_title_dis}>{`已使用(${total1})`}</div>
                    {selIndex === 1 ? <div className={styles.line}/> : null}
                </div>
                <div className={styles.heder_title_com}
                     onClick={() => clickIndex(2)}>
                    <div
                        className={selIndex === 2 ? styles.header_title_sel : styles.header_title_dis}>{`已过期(${total2})`}</div>
                    {selIndex === 2 ? <div className={styles.line}/> : null}
                </div>
            </div>
        </div>
    )
}

export default MyCoupon;
