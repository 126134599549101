import React, {useEffect, useState, useRef} from "react"
import "./index.scss"
import NFTHeader from "@/components/NFTHeader";
import SessionItem from "@/view/guessingActy/sessionDetail/sessionItem";
import qs from "query-string";
import {useNavigate, useLocation, useHistory} from "react-router-dom";
import {hideLoading, showLoading} from "@/components/loading";
import {getSessionRecord, secondaryVariantApeInit,} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";
import {convertToChineseNumeral, isEmptyArray} from "@/utils/publicFun";
import btn_left_nor from '../imgs/btn_switch_left_nor.png'
import btn_left_dis from '../imgs/btn_switch_left_dis.png'
import btn_right_nor from '../imgs/btn_switch_right_nor.png'
import btn_right_dis from '../imgs/btn_switch_right_dis.png'
import clickThrottle from "@/utils/clickThrottle";

const SessionDetail = () => {//竞猜详情
    const {whichFrom = ''} = qs.parse(window.location.search);
    const navigate = useNavigate();
    const location = useLocation();
    // const history = useHistory();
    const [data, setData] = useState({});
    const [prizeDes, setPrizeDes] = useState('');//中奖描述
    const curTime = useRef();
    const sessionRef = useRef();
    useEffect(() => {
        window.scroll(0, 0);
        console.log('-location---', location.state)
        sessionRef.current = location.state.session;
        loadData();
    }, []);

    const refreshData = (sessionTem) => {
        sessionRef.current = sessionTem;
        loadData();
    }

    const loadData = async () => {
        try {
            showLoading()
            const res = await getSessionRecord({session: sessionRef.current})
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                curTime.current = res.data.currentTime;
                if (res.data.roundPrize && !isEmptyArray(res.data.roundPrize)) {
                    let roundPrize = res.data.roundPrize;
                    let des = `恭喜您！第${convertToChineseNumeral(sessionRef.current)}场第${res.data.round || 0}轮获得`;
                    for (const item of roundPrize) {
                        let prize_type = item.prizeType || '0';
                        let amount = item.amount;
                        if (prize_type === '1') {
                            let t1 = `30元变异无聊猿Lite优惠券x${amount}、`;
                            des += t1;
                        } else if (prize_type === '2') {
                            let t1 = `60元变异无聊猿Lite优惠券x${amount}、`;
                            des += t1;
                        } else if (prize_type === '3') {
                            let t1 = `100元变异无聊猿Lite优惠券x${amount}、`;
                            des += t1;
                        } else if (prize_type === '4') {//碎片
                            let t1 = `变异无聊猿Lite碎片x${amount}、`;
                            des += t1;
                        } else if (prize_type === '5') {//变异无聊猿兑换券
                            let t1 = `变异无聊猿Lite兑换券x${amount}、`;
                            des += t1;
                        } else if (prize_type === '6') {//史诗款兑换券
                            let t1 = `史诗款兑换券x${amount}、`;
                            des += t1;
                        }
                    }
                    if (des.endsWith('、')) {
                        des = des.substring(0, des.length - 1);
                        setPrizeDes(des);
                    }
                } else {
                    setPrizeDes('')
                }
                setData(res.data);
            }
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const leftClick = () => {
        navigate(-1);
    }

    const rightClick = () => {
        navigate(`/guessingActy/allSession`);
    }


    return (
        <div className="session-detail-acty-container">
            <NFTHeader midText={'本 场'} bgColor={'#fff'} rightText={'全部场次'} leftClick={() => leftClick()}
                       rightClick={() => rightClick()}/>
            <Header data={location.state} refreshData={refreshData}/>
            <div className={'session-detail-title-container'}>
                <p>我的入场券：{data.allowNum || 0}</p>
                <p onClick={() => navigate('/guessingActy/getCoupon')}>获取入场券</p>
            </div>
            {
                isEmptyArray(data.guessingQuestionList) ? <div className={'session-detail-acty-no-data'}>
                    <img src={require('@/assets/img/pic_dindan.png')} alt=""/>
                    <p>暂未开始</p>
                </div> : <>
                    {prizeDes && <p className={'session-detail-con-title'}>{prizeDes}</p>}
                    <SessionItem data={data} round={0} refreshData={loadData}/>
                    <SessionItem data={data} round={1} refreshData={loadData}/>
                    <SessionItem data={data} round={2} refreshData={loadData}/>
                </>
            }


        </div>
    )
}

const Header = (props) => {
    const dataRef = useRef({});
    const [session, setSession] = useState(0);
    const totalSessionRef = useRef(0);
    useEffect(() => {
        dataRef.current = props.data || {};
        setSession((parseInt(dataRef.current.session) || 0) || 0);
        homeInit();
    }, [(props.data !== dataRef.current)]);

    const homeInit = async () => {
        try {
            const res = await secondaryVariantApeInit({})
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            let data = res.data || {};
            totalSessionRef.current = data.totalSession || 0;
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const lastSessionClick = () => {
        if (!clickThrottle()) {
            return;
        }
        if (session <= 1) {
            return;
        }
        let sessionTem = session - 1;
        setSession(sessionTem);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            props.refreshData && props.refreshData(sessionTem);
        }, 1000);
    }

    const nextSessionClick = () => {
        if (!clickThrottle()) {
            return;
        }
        if (session >=  totalSessionRef.current) {
            return;
        }
        let sessionTem = session + 1;
        setSession(sessionTem);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            props.refreshData && props.refreshData(sessionTem);
        }, 1000);
    }

    return (
        <div className={'session-detail-header'}>
            <img src={session <= 1 ? btn_left_dis : btn_left_nor} alt="" onClick={() => lastSessionClick()}/>
            <p>第{convertToChineseNumeral(session)}场</p>
            <img src={session >= dataRef.current.allSession ? btn_right_dis : btn_right_nor} alt=""
                 onClick={() => nextSessionClick()}/>
        </div>
    )
}

export default SessionDetail
