/**
 * @description 盲盒换宝石支付成功页面
 * @param {}
 * @returns {/boxToStone/paySuccess}
*/

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Button, Toast } from "antd-mobile";
import { EffectCards } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/effect-cards";
import ModalAlert from "@/components/ModalAlert";
import NFTHeader from "@/components/NFTHeader";
import { hideLoading, showLoading } from '@/components/loading';
import { getUrlAllParams } from "@/utils/publicFun";
import Service from '../service';
import Styles from "./index.module.scss";

const sources = [
    require('@/assets/img/boxToStone/front.jpg'),
    require('@/assets/img/boxToStone/front.jpg'),
    require('@/assets/img/boxToStone/left.jpg'),
    require('@/assets/img/boxToStone/left.jpg'),
    require('@/assets/img/boxToStone/top.jpg'),
    require('@/assets/img/boxToStone/top.jpg'),
]

const Bridge = window.JsBridge
export default function PaySuccess() {
    const navigate = useNavigate();
    const {search} = useLocation()
    const param = getUrlAllParams(search.slice(1));
    const {orderNo} = param; //"1821786764361072640dZ"

    const pageDom = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [blinBox, setBlinBox] = useState(
        [{
            productImgUrl: require('@/assets/img/ape-world/egg_bg_xiangqingye.png'),
            productName: '开盲盒1',
            productId: 'xxxxx0'
        }, {
            productImgUrl: require('@/assets/img/ape-world/egg_bg_xiangqingye.png'),
            productName: '开盲盒2',
            productId: 'xxxxx1'
        }]
    )
    useEffect(() => {
        getData(true);
    }, []);
    const getData = async (loading = false) => {
        try {
            if (loading) showLoading();
            const res = await Service.getOrderInfo({ orderId: orderNo });
            if (loading) hideLoading();
            if (!res) throw res.msg;
            setBlinBox(res.data);
        } catch (error) {
            hideLoading();
            error && Toast.show(error);
        }
    }
    const onGoBack = () => {
        const content = (
            <div style={{ padding: '10px 0', textAlign: 'center' }}>
                返回视为放弃转化为宝石，默认发货
            </div>
        )
        ModalAlert.show({
            getContainer: pageDom.current,
            title: '温馨提示',
            content: content,
            onAction: async (btn, index) => {
                if (btn.key === 'confirm') {
                    ModalAlert.hide();
                    if (Bridge.hasWebViewBridge()) {
                        Bridge.back();
                    } else {
                        navigate(-1)
                    }
                } else {
                    ModalAlert.hide();
                }

            },
            actions: [
                { key: 'cancel', text: '取消' },
                { key: 'confirm', text: '确认返回' }
            ]
        })

    }
    const onTransformStone = () => {
        const uri = `/boxToStone/batchTransform?orderNo=${orderNo}`
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.replace("CustomWeb", { uri: `${window.location.origin}${uri}` })
        } else {
            navigate(uri, {replace: true});
        }
    }

    const slideChange = (e) => {
        setActiveIndex(e.activeIndex)
    }

    return (
        <div className={Styles.pay_success} ref={pageDom}>
            <NFTHeader midText="支付成功" bgColor="white" />
            <div className={Styles.pay_success_bg}>
                <div className={Styles.banner}>
                    <div className={Styles.stage}>
                        {
                            sources.map((item, i) => {
                                const directions = ['front', 'rear', 'left', 'right', 'up', 'down'];
                                return (
                                    <div className={classNames(Styles.face, Styles[directions[i]])} key={i}>
                                        <img src={item} alt="" />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={classNames(Styles.collectionList, Styles.fade_in)}>
                        <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]}
                            className={Styles.blinBox_Swiper} onSlideChange={slideChange}
                        >
                            {
                                blinBox.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <img src={item.productImgUrl} alt="" />
                                        <span className={classNames(Styles.show_text, { [Styles.hidden_text]: index !== activeIndex })}>
                                            {item.productName}
                                        </span>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                        <div className={Styles.btnGroup} >
                            <Button onClick={onGoBack}>返回</Button>
                            <Button onClick={onTransformStone}>转化为宝石</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
