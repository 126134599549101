import icon_expired from './icon-expired.png'
import icon_used from './icon-used.png'
import icon_radio from '@/assets/img/icon/icon-radio.png'
import icon_radio_checked from '@/assets/img/icon/icon-radio-checked.png'
import styles from './styles.module.scss'

export default ({
                    amount = 100, // 金额
                    name = '变异无聊猿专用优惠券', // 优惠券名称
                    // isExpired = false, // 已过期
                    // isUsed = false, // 已使用
                    type = 0,//0=未使用 1=已使用 2=已过期
                    checkable = false, // 是否可选择
                    checked = false, // 是否选中
                    className = '',
                    onClick = () => null,
                }) => {
    // const disabled = isExpired || isUsed;
    const disabled = (type === 1 || type === 2);
    let isExpired = (type === 2);
    let isUsed = (type === 1);

    const wrapClass = `${styles.container} ${className} ${disabled ? styles.disabled : ''}`;
    return (
        <div className={wrapClass} onClick={onClick}>
            <div className={styles.ticket}>
                <div className={styles.amountWrap}>
                    <span>¥</span>
                    <span className={styles.amount}>{amount}</span>
                    {
                        isUsed ? <img src={icon_used} className={styles.seal}/> : (
                            isExpired && <img src={icon_expired} className={styles.seal}/>
                        )
                    }
                </div>
                <div className={styles.name}>{name}</div>
            </div>
            {
                checkable && (
                    <img className={styles.radio} src={checked ? icon_radio_checked : icon_radio}/>
                )
            }
        </div>
    )
}
