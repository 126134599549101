import dayjs from "dayjs";
import { getAbleRentalPeriod, getMonkeyTypeAndName } from '@/api/guland';
import { getUserCertificate } from "@/api/projectManager_self";

export const getLandLevel = (collectionId) => {
  switch (collectionId) {
    case 48:
      return "a";
    case 63:
    case 205:
    case 234:
      return "a";
    case 47:
    case 235: 
      return "b";
    default:
      return "c";
  }
};

export const getLandIndex = (level) => {
  switch (level) {
    case "a":
      return 2;
    case "b":
      return 1;
    default:
      return 0;
  }
};

export const getUpgradeType = (current, next) => {
  const str = `${current.toUpperCase()}_${next.toUpperCase()}`;

  console.log(str)
  return {
    C_B: 1,
    C_A: 2,
    B_A: 3,
  }[str];
};

export const getTransType = (current, next) => {
  const str = `${current}_${next}`;
  return {
    '3_3': 4,//C级转换C级
    '2_2': 5,//B级转换B级
    '1_1': 6,//A级转换A级
  }[str];
};

export const residueHours = (time) => {
  const now = dayjs(time || '')
  const remainingHours = now.startOf('day').add(24, 'hour');
  const hours = Math.floor((remainingHours - now)/1000/3600)
  console.log(`今天还有 ${hours} 小时`);
  return hours
}

export const residue12Hours = (time) => {
  const now = dayjs(time);
  const noonToday = now.startOf('day').add(12, 'hour');
  const hoursRemaining = noonToday - now;
  return hoursRemaining
}

export const numberToBig = (number) => {
  const numbers = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const units = ['', '十', '百', '千'];
  let text = '';
  
  if (number === 0) {
    return '零';
  }
  let num = number;
  let digit = 0;
  
  while (num > 0) {
    const current = num % 10;
    const unit = units[digit % 4];
    
    if (current !== 0) {
      text = numbers[current] + unit + text;
    }
    
    num = Math.floor(num / 10);
    digit++;
  }
  
  return text;
}

export const LevelOptions = [
  { label: "全部", value: 0 },
  { label: "A级", value: 1 },
  { label: "B级", value: 2 },
  { label: "C级", value: 3 },
]

export const ApeCateOptions = [
  { label: "全部", value: 0 },
  { label: "史诗款", value: 1 },
  { label: "传说款", value: 2 },
  { label: "隐藏款", value: 3 },
  { label: "典藏款", value: 4 },
  { label: "C级钳工", value: 5 },
  { label: "B级挖掘机", value: 6 },
  { label: "A级工程师", value: 7 },
]

export const CateOptions = [
  { label: "全部", value: 0 },
  { label: "土地", value: 2 },
  { label: "无聊猿", value: 1 },
]

export const getLeaseTerm = async () => {
  const res = await getAbleRentalPeriod();
  if (res.code !== "000000") throw res.msg;
  const arr = res.data?.split(",");
  const listTemp = [{label: '全部', value: 0}];
  arr.forEach((item) => {
    if (item === '-1') {
      listTemp.push({ label: "永久", value: Number(item) });
    } else {
      listTemp.push({ label: item + "天", value: Number(item) });
    }
  });
  return listTemp;
};

export const getApeCate = async () => {
  const res = await getMonkeyTypeAndName();
  if (res.code !== '000000') throw res.msg;
  const newArr = res.data.map((item) => ({label: item[Object.keys(item)[0]], value: Number(Object.keys(item)[0])}));
  return [{label: '全部', value: 0}, ...newArr];
};

//获取用户实名状态
export const getUserCertificateStatus = async () => {
  const res = await getUserCertificate({})
  if (res.code !== '000000') throw res.msg;
  return res.data;
}

