import React, { useEffect, useState, Fragment } from "react"
import "./styles.scss"
import { useNavigate, useLocation } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Button, Toast } from "antd-mobile"
import {showLoading, hideLoading} from '@/components/loading';
import { EffectCards } from "swiper"
import { getUrlAllParams } from "@/utils/publicFun";
import { getReceive } from "@/api/projectManager_self";
import "swiper/css/bundle"
import "swiper/css/effect-cards"
import NFTHeader from "@/components/NFTHeader";

export default function SalePaySuccess() {
  const navigate = useNavigate()
  const [hash, setHash] = useState()
  const [activeIndex, setActiveIndex] = useState(0)
  const [blinBox, setBlinBox] = useState(
    /*[{
      url: require('@/assets/img/ape-world/egg_bg_xiangqingye.png'),
      title: '开盲盒',
      nftNo: 'xxxxx'
    }]*/
  )
  const [blinBoxCount, setBlinBoxCount] = useState(0)
  // const { state = { collectionList: [{ image: '', nftNo: "", collectionName: "", collectionType: 32 }], title: "", from: undefined/* 跳转来源 */ } } = useLocation()
  const state = { collectionList: [{ image: require('@/assets/img/ape-world/egg_bg_xiangqingye.png'), nftNo: "xxxx", collectionName: "漫画支付", collectionType: 32 }], title: "", from: undefined }
  // 共创漫画开盲盒参数
  const { search } = useLocation()
  //req:流水号
  const {code,req} = getUrlAllParams(search.slice(1));

  const bridge = window.JsBridge

  useEffect(() => {
    setHash(state?.collectionList[0]?.hash)
    if (state?.title) {
      bridge.setTitle(state?.title)
    } else {
      bridge.setTitle("支付成功")
    }
    if(req && code) {
      bridge.setTitle("开盒中")
      const getData = async () => {
          try {
              showLoading()
              const res = await getReceive({req, code})
              hideLoading();
              if (res.code !== '000000') throw res.msg;
              if (res.data && res.data.collectionsInfo) {
                 bridge.setTitle("开盒成功")
                 const totalCount = res.data.totalCount || 0
                 setBlinBoxCount(totalCount)
                 let list = res.data.collectionsInfo || []
                 list = list.map(item => {
                  if(item.url && item.url.includes('?')){
                    item.url = item.url.split('?')[0]
                  }
                  return item
                 })
                 setBlinBox(list)

              }else {
                throw '开盲盒失败';
              }
          } catch (error) {
              bridge.setTitle("开盒失败")
              Toast.show(error);
          }

      }
      getData()

    }
  }, [])
  const gotoDetail = () => {
      if (window.JsBridge.hasWebViewBridge()) {
          window.JsBridge.replace("CustomWeb", { uri: `${window.location.origin}/myCollection`});
      }


  }
  const slideChange = (e) => {
    setHash(state?.collectionList[e.activeIndex]?.hash)
    setActiveIndex(e.activeIndex)
  }

  const returnDiv = () => {
    if (state?.collectionList[0]?.collectionType === 1) {
      return (
        <>
          <div className="face front">
            <img src={require("@/assets/img/dh/13.png")} alt="" />
          </div>
          <div className="face rear">
            <img src={require("@/assets/img/dh/13.png")} alt="" />
          </div>
          <div className="face left">
            <img src={require("@/assets/img/dh/12.png")} alt="" />
          </div>
          <div className="face right">
            <img src={require("@/assets/img/dh/12.png")} alt="" />
          </div>
          <div className="face up">
            <img src={require("@/assets/img/dh/11.png")} alt="" />
          </div>
          <div className="face down">
            <img src={require("@/assets/img/dh/11.png")} alt="" />
          </div>
        </>
      )
    } else if (state?.collectionList[0]?.collectionType === 2) {
      return (
        <>
          <div className="face front">
            <img src={require("@/assets/img/dh/23.png")} alt="" />
          </div>
          <div className="face rear">
            <img src={require("@/assets/img/dh/23.png")} alt="" />
          </div>
          <div className="face left">
            <img src={require("@/assets/img/dh/22.png")} alt="" />
          </div>
          <div className="face right">
            <img src={require("@/assets/img/dh/22.png")} alt="" />
          </div>
          <div className="face up">
            <img src={require("@/assets/img/dh/21.png")} alt="" />
          </div>
          <div className="face down">
            <img src={require("@/assets/img/dh/21.png")} alt="" />
          </div>
        </>
      )
    } else if (state?.collectionList[0]?.collectionType === 3) {
      return (
        <>
          <div className="face front">
            <img src={require("@/assets/img/dh/33.png")} alt="" />
          </div>
          <div className="face rear">
            <img src={require("@/assets/img/dh/33.png")} alt="" />
          </div>
          <div className="face left">
            <img src={require("@/assets/img/dh/32.png")} alt="" />
          </div>
          <div className="face right">
            <img src={require("@/assets/img/dh/32.png")} alt="" />
          </div>
          <div className="face up">
            <img src={require("@/assets/img/dh/31.png")} alt="" />
          </div>
          <div className="face down">
            <img src={require("@/assets/img/dh/31.png")} alt="" />
          </div>
        </>
      )
    } else if (state?.collectionList[0]?.collectionType === 4) {
      return (
        <>
          <div className="face front">
            <img src={require("@/assets/img/dh/43.png")} alt="" />
          </div>
          <div className="face rear">
            <img src={require("@/assets/img/dh/43.png")} alt="" />
          </div>
          <div className="face left">
            <img src={require("@/assets/img/dh/42.png")} alt="" />
          </div>
          <div className="face right">
            <img src={require("@/assets/img/dh/42.png")} alt="" />
          </div>
          <div className="face up">
            <img src={require("@/assets/img/dh/41.png")} alt="" />
          </div>
          <div className="face down">
            <img src={require("@/assets/img/dh/41.png")} alt="" />
          </div>
        </>
      )
    } else if (state?.collectionList[0]?.collectionType === 6) {
      return (
        <>
          <div className="face front">
            <img src={require("@/assets/img/dh/6.png")} alt="" />
          </div>
          <div className="face rear">
            <img src={require("@/assets/img/dh/6.png")} alt="" />
          </div>
          <div className="face left">
            <img src={require("@/assets/img/dh/7.png")} alt="" />
          </div>
          <div className="face right">
            <img src={require("@/assets/img/dh/7.png")} alt="" />
          </div>
          <div className="face up">
            <img src={require("@/assets/img/dh/5.png")} alt="" />
          </div>
          <div className="face down">
            <img src={require("@/assets/img/dh/5.png")} alt="" />
          </div>
        </>
      )
    } else if (state?.collectionList[0]?.collectionType === 7) {
      return (
        <>
          <div className="face front">
            <img src={require("@/assets/img/dh/2.png")} alt="" />
          </div>
          <div className="face rear">
            <img src={require("@/assets/img/dh/2.png")} alt="" />
          </div>
          <div className="face left">
            <img src={require("@/assets/img/dh/3.png")} alt="" />
          </div>
          <div className="face right">
            <img src={require("@/assets/img/dh/3.png")} alt="" />
          </div>
          <div className="face up">
            <img src={require("@/assets/img/dh/1.png")} alt="" />
          </div>
          <div className="face down">
            <img src={require("@/assets/img/dh/1.png")} alt="" />
          </div>
        </>
      )
    } else if (state?.collectionList[0]?.collectionType === 9) {
      return (
        <>
          <div className="face front">
            <img src={require("@/assets/img/ape-world/box2.png")} alt="" />
          </div>
          <div className="face rear">
            <img src={require("@/assets/img/ape-world/box2.png")} alt="" />
          </div>
          <div className="face left">
            <img src={require("@/assets/img/ape-world/box56.png")} alt="" />
          </div>
          <div className="face right">
            <img src={require("@/assets/img/ape-world/box56.png")} alt="" />
          </div>
          <div className="face up">
            <img src={require("@/assets/img/ape-world/box3.png")} alt="" />
          </div>
          <div className="face down">
            <img src={require("@/assets/img/ape-world/box3.png")} alt="" />
          </div>
        </>
      )
    } else if((blinBox && blinBox.length > 0) || state?.collectionList[0]?.collectionType === 19) {
      // 开盲盒/漫画支付成功
      return (
        <>
          <div className="face front">
            <img src={require("@/assets/img/ape-world/cartoon_front.png")} alt="" />
          </div>
          <div className="face rear">
            <img src={require("@/assets/img/ape-world/cartoon_front.png")} alt="" />
          </div>
          <div className="face left">
            <img src={require("@/assets/img/ape-world/cartoon_left.png")} alt="" />
          </div>
          <div className="face right">
            <img src={require("@/assets/img/ape-world/cartoon_left.png")} alt="" />
          </div>
          <div className="face up">
            <img src={require("@/assets/img/ape-world/cartoon_top.png")} alt="" />
          </div>
          <div className="face down">
            <img src={require("@/assets/img/ape-world/cartoon_top.png")} alt="" />
          </div>
        </>
      )
    } else if(state?.collectionList[0]?.collectionType === 31) {
      // 开盲盒/漫画支付成功
      return (
        <>
          <div className="face front">
            <img src={require("@/assets/img/variationApe/blind-front-back.png")} alt="" />
          </div>
          <div className="face rear">
            <img src={require("@/assets/img/variationApe/blind-front-back.png")} alt="" />
          </div>
          <div className="face left">
            <img src={require("@/assets/img/variationApe/blind-left-right.png")} alt="" />
          </div>
          <div className="face right">
            <img src={require("@/assets/img/variationApe/blind-left-right.png")} alt="" />
          </div>
          <div className="face up">
            <img src={require("@/assets/img/variationApe/blind-up-down.png")} alt="" />
          </div>
          <div className="face down">
            <img src={require("@/assets/img/variationApe/blind-up-down.png")} alt="" />
          </div>
        </>
      )
    }else if(state?.collectionList[0]?.collectionType === 32) {
        // 开盲盒/漫画支付成功
        return (
            <>
                <div className="face front">
                    <img src={require("@/assets/img/variationApe/guess-front-back.png")} alt="" />
                </div>
                <div className="face rear">
                    <img src={require("@/assets/img/variationApe/guess-front-back.png")} alt="" />
                </div>
                <div className="face left">
                    <img src={require("@/assets/img/variationApe/guess-left-right.png")} alt="" />
                </div>
                <div className="face right">
                    <img src={require("@/assets/img/variationApe/guess-left-right.png")} alt="" />
                </div>
                <div className="face up">
                    <img src={require("@/assets/img/variationApe/guess-up-down.png")} alt="" />
                </div>
                <div className="face down">
                    <img src={require("@/assets/img/variationApe/guess-up-down.png")} alt="" />
                </div>
            </>
        )
    }else {
      return <></>
    }
  }

  //设置背景图
  const setbgimg = ()=> {
    let className = ''
    if(state?.collectionList[0]?.collectionType === 9) {
      className = 'ape_world_bg'
    }else if(blinBox && blinBox.length > 0) {
      //漫画开盲盒背景
      className = 'blinBox'
    }else if(state?.collectionList[0]?.collectionType === 19) {
      // 购买漫画支付成功背景
      className = 'blinBox'
    }else if(state?.collectionList[0]?.collectionType === 31) {
      className = 'variationApe'
    } else if (state?.collectionList[0]?.collectionType === 32) {
      className = 'bg32'
    }
    return className
  }

  return (
    <div className="paysuccess">
        <NFTHeader midText={'支付成功'} bgColor={'#fff'}/>
      <div className={`paysuccess_bg ${setbgimg()}`}>
        <div className="banner">
          <div className="stage">{returnDiv()}</div>
          <div className="animation">
            {
              state?.collectionList &&
              <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]} className="mySwiper" onSlideChange={slideChange}>
                {state?.collectionList &&
                  state?.collectionList.map((item, index) => (
                    <SwiperSlide key={index}>
                      {
                        item.collectionType == 19 ?
                        <Fragment>
                          <img src={item.image} alt="" className="img_19"/>
                          <span className={index === activeIndex ? "show_text_19" : "hidden_text"}>
                            <span className="blod_text">{item.collectionName}</span>
                            <br />
                            <span>藏品编号：{item.nftNo}</span>
                          </span>
                        </Fragment>:
                        <Fragment>
                          <img src={item.image} alt="" />
                          <span className={index === activeIndex ? "show_text" : "hidden_text"}>
                            <span className={`blod_text ${item.collectionType === 31 ? 'variation-style': ''}`}>{item.collectionName}</span>
                            <br />
                            <span className={item.collectionType === 31 ? 'num_style': ''}>#{item.nftNo}</span>
                          </span>
                        </Fragment>
                      }
                    </SwiperSlide>
                  ))}
              </Swiper>
            }
            {/* 漫画开盲盒 */}
            {
              blinBox && blinBox.length > 0 &&
              <>
              <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]} className="blinBox_Swiper" onSlideChange={slideChange}>
                  {
                    blinBox.map((item, index) => (
                      <SwiperSlide key={index}>
                        <img src={item.url} alt="" />
                        <span className={index === activeIndex ? "show_text" : "hidden_text"}>
                          <span className="blod_text">{item.title}</span>
                          <br />
                          <span>藏品编号：{item.nftNo}</span>
                        </span>
                      </SwiperSlide>
                    ))
                  }
              </Swiper>
              <div className="blinbox_count">共{blinBoxCount || 0}个藏品</div>
              </>
            }

            <Button size="large" className="view_btn" onClick={gotoDetail}>
              查看藏品详情
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
