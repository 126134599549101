import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import {Mask, Toast} from 'antd-mobile';
import RecycleSuccessModal from "@/view/partsRecyclingUnity/components/recycleSuccessModal";
import {hideLoading, showLoading} from "@/components/loading";
import {recoveryActivityRdo} from "@/api/projectManager_self";

export const activityCode = 'metaRecovery1027';
const RecycleModal = ({onClose, leftCB, rightCB, list = [

], type}) => {
    const [visible, setVisible] = useState(true);

    const cancel = () => {
        onClose && onClose();
        setVisible(false);
        leftCB && leftCB();
    }

    const confirm = () => {
        onClose && onClose();
        setVisible(false);
        rightCB && rightCB();
        recoveryRdo();
    }

    const recoveryRdo = async () => {
        if (!list.length) {
            return
        }
        let nftNoList = [];
        list.map((item) => {
            nftNoList.push(item.nftNo);
        })
        let typeTem = 3;
        if (type === 0) { //type=0 史诗款 1=押注
            typeTem = 3;
        } else if (type === 1) {
            typeTem = 4;
        }
        try {
            showLoading();
            const res = await recoveryActivityRdo({nftNoList: nftNoList, activityCode: activityCode, type: typeTem});
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            let data = res.data;
            let list = [{nftNo:data?.nftNo,nftImage:data?.nftImage}];
            RecycleSuccessModal.open({type: type,list:list});
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const items = () => {
        return list.map((item, index) => {
            return <div key={index.toString()} className={'recycle-col-item'}>
                <img src={item.nftImage} alt=""/>
                <p>{item.nftNo}</p>
            </div>
        })
    }

    let des = '';
    if (type === 0) {
        des = '确定将以下藏品回收得史诗猿吗？确认后以下藏品将被回收，\n' +
            '获得1个史诗无聊猿，本次操作不可逆'
    } else if (type === 1) {
        des = '确定将以下藏品回收并押注吗？确认后以下藏品将被回收，\n' +
            '获得1张刮奖券，本次操作不可逆';
    }
    return <Mask className='unity-acratch-success-mask' visible={visible} >
        <div className='mask-content'>
            <div className="mask-inner">
                <div className='points-content-unity'>
                    <div className='title'>回收纪念款藏品</div>
                    <div className='points-unity'>{des}</div>
                    <div className={'content-unity'}>
                        {items()}
                    </div>
                </div>
                <div className='btn-box'>
                    <div className="confirm-btn" onClick={() => cancel()}>取消</div>
                    <div className="address-btn" onClick={() => confirm()}>确认回收</div>
                </div>
            </div>
        </div>
    </Mask>
}

RecycleModal.open = ({leftCB, rightCB, list, type}) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<RecycleModal onClose={close} leftCB={leftCB} rightCB={rightCB} list={list} type={type}/>, div);
}

export default RecycleModal;
