/*
    订单支付底部操作
*/

import React, { useState, useEffect, useRef } from "react";
import {Button, Toast} from "antd-mobile";
import ModalAlert from "@/components/ModalAlert";
import {round, throttle, computeNumber} from "@/utils/publicFun";
import {showLoading, hideLoading} from '@/components/loading';
import { CMap } from "@/view/ape/SaleDetail/config";
import PayTypeSelect from "../PayTypeSelect";
import {
    createOrder,
    queryPayResult,
    getPayParams, 
    iapValidatePayload,
} from '@/api/projectManager_self';
import "./index.scss";

/**
 * @deprecated 该数据将在之后版本废弃，CMap代替
*/
const TypeMap = {
  72: 9,
  77: 10,
}

const BottomBtn = (props) => {

    const {
        notPayOrder, 
        verifyRealName,
        param = {}, // 界面传递的参数
        payDetail={},
        userInfo={},
        payWay, //支付方式
        callback, //支付结束回调
        waitPay = true, //是否等待支付结果
    } = props

    const installedWX = useRef();
    const currentRef = useRef();
    const timeId = useRef(null);
    const timeCount = useRef(5);

    useEffect(()=>{
        if(window.JsBridge.hasWebViewBridge()){
            window.JsBridge.nativeModules("UMShareModule", "isInstalledWx").then(function (res) {
                installedWX.current = res
            })
        }
       
    },[])

    const getPayResult = (params) => {
        try {
            showLoading();
            let timeId = setTimeout(async () => {
                const {code, msg, data} = await queryPayResult(params);
                hideLoading();
                if (code === '000000') {
                    if (data) {
                        callback && callback({
                            code: 'success',
                            data: data
                        })
                        // gotoSalePaySuccess(data);
                    } else {
                        Toast.show('已支付，请到“我的-我的藏品”查看');
                    }
                } else {
                    Toast.show(msg);
                }
                timeId && clearTimeout(timeId);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    }

    const getPayResultLoop = (params) => {
      showLoading();
      timeId.current && clearTimeout(timeId.current);
      const getResult = async () => {
        try {
          const {code, msg, data} = await queryPayResult(params);
          if (code === '000000') {
            if (data) {
              hideLoading();
              timeId.current && clearTimeout(timeId.current);
              callback && callback({
                code: 'success',
                data: data
              })
            } else if (timeCount.current > 0) {
              timeId.current = setTimeout(getResult, 2000);
              timeCount.current--;
            } else {
                hideLoading();
                timeId.current && clearTimeout(timeId.current);
                ModalAlert.show({
                    getContainer:currentRef.current,
                    maskStyle:{background: `rgba(0, 0, 0, 0.6)`},
                    title: '支付状态',
                    content: '已支付，请到“我的-我的藏品”查看',
                    onAction: async (item, index) => {
                        ModalAlert.hide();
                        if(window.JsBridge.hasWebViewBridge()) {
                            window.JsBridge.back();
                        }
                    },
                    actions: [
                        {key: 'confirm',text: '好的', style:{color: '#CAA846'}}
                    ]
                })
                // Toast.show('已支付，请到“我的-我的藏品”查看');
            }
          } else {
            hideLoading();
            Toast.show(msg);
          }
        } catch (error) {
          hideLoading();
          console.log(error);
        }
      }
      getResult()
    }

    //获取内购的验证结果
    const getIapPayResult = (params) => { 
        try {
            showLoading();
            let timeId = setTimeout(async () => {
                const {code, msg, data} = await iapValidatePayload(params);
                hideLoading();
                if (code === '000000') {
                    if (data) {
                        callback && callback({
                            code: 'success',
                            data: data
                        })
                        // gotoSalePaySuccess(data);
                    } else {
                        getPayResult({outTradeNo: params?.outTradeNo});
                    }
                } else {
                    Toast.show(msg);
                }
                timeId && clearTimeout(timeId);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    }

    const payFunc = async (params) => {
        try {
            showLoading();
            const orderInfo = await createOrder(params);
            if (orderInfo.code !== '000000') throw orderInfo.msg;
            if (payDetail.totalPrice === 0) {
                hideLoading();
                return orderInfo.data;
            }
            const paramsInfo = await getPayParams({outTradeNo: orderInfo?.data?.outTradeNo || ''});
            hideLoading();
            if (paramsInfo.code !== '000000') throw paramsInfo.msg;
            return {...paramsInfo.data, ...orderInfo.data};
        } catch (error) {
            hideLoading();
            Toast.show(error);
            return false;
        }
    }


    const mobilePayment = ()=> {
        if (notPayOrder) {
            Toast.show("您有待付款的订单，请先完成上次订单")
            return false
        }
        
        let payTypeTem = ''
        if (param.currentOperate && param.currentOperate === '100') { //孵蛋第一阶段
            payTypeTem = 'EXCHANGE_CODE'
        } else {
            if (payDetail.totalPrice === 0) {
                payTypeTem = 'POINT'
            } else {
                payTypeTem = payWay;
            }
        }
        if (!payTypeTem) {
            return Toast.show("请选择支付方式")
        }

        const params = {
            saleBatchId: payDetail.saleBatchInfo?.saleBatchId || '',
            collectionId: payDetail?.id || '',
            usePoint: 0,
            useCash: payDetail.totalPrice,
            payType: payTypeTem,
            source: "app",
            num: param.currentOperate === '100' ? payDetail.purchaseNum : payDetail.count,
            uid: payDetail.defRepeatStr,
            redeemCode: payDetail.cardNo || '',
            type: CMap[param.code]?.orderType || TypeMap[payDetail.collectionType] || '0',
            // type: TypeMap[payDetail.collectionType] || '0',
        }
        if (window.JsBridge.hasWebViewBridge()) {
            if (!verifyRealName) {
                Toast.show("请先实名认证")
                return false
            }
            if (payWay === PayTypeSelect.wxpayString) {
                if (!installedWX) {
                    Toast.show("请先安装微信")
                    return false
                }
            }

            payFunc(params).then((res) => {
                // NOTE: 如果是支付金额为0，全部使用积分抵扣，则不继续调用Jsbridge方法
                if (payDetail.totalPrice === 0 && res) {
                  getPayResultLoop({outTradeNo: res.outTradeNo});
                } else {
                    if (!res) return;
                    if (payWay === 'WXPAY') {
                        window.JsBridge
                            .wxPay({
                                partnerId: res.partnerId || '',
                                prepayId: res.prepayId || '',
                                nonceStr: res.nonceStr || '',
                                timeStamp: res.timeStamp || '',
                                sign: res.sign || '',
                                appId: res.appId,
                            })
                            .then(function (result) {
                                // 支付失败
                                if (result.errCode === -2) {
                                    Toast.show("支付取消")
                                    // gotoOrderList()
                                    props.callback && props.callback({
                                        code: 'cancal',
                                        msg: '支付取消'
                                    })
                                } else if (result.errCode === 0) {
                                    // 支付回调
                                    const payParams = {
                                        errStr: result?.errStr,
                                        errCode: result?.errCode,
                                        outTradeNo: res.outTradeNo,
                                        source: "wxpay",
                                    }
                                    if(waitPay) {
                                        getPayResultLoop(payParams);
                                    }else {
                                        callback && callback({
                                            code: 'pending',
                                            data: payParams
                                        })
                                    }
                                    
                                } else {
                                    Toast.show("支付发生错误  code: " + (result?.errCode || " "))
                                }
                            })
                    } else if (payWay === 'ALIPAY') {
                        // 支付宝支付
                        const payResult = res.payResult
                        window.JsBridge.aliPay(payResult).then(function (result) {
                            // 支付失败
                            if (result.resultStatus == 6001) {
                                Toast.show("支付取消")
                                props.callback && props.callback({
                                    code: 'cancal',
                                    msg: '支付取消'
                                })
                                // gotoOrderList()
                            } else if (result.resultStatus == 62000) {
                                Toast.show("客户端未安装支付通道依赖的服务")
                            } else if (result.resultStatus == 9000) {
                                // 支付回调
                                const resFmt = JSON.parse(result.result || "{}")
                                const payParams = {
                                    errStr: resFmt?.alipay_trade_app_pay_response?.msg || "",
                                    errCode: resFmt?.alipay_trade_app_pay_response?.code || "",
                                    outTradeNo: res.outTradeNo || "",
                                    source: "alipay",
                                }
                                if(waitPay) {
                                    getPayResultLoop(payParams);
                                }else {
                                    callback && callback({
                                        code: 'pending',
                                        data: payParams
                                    })
                                }
                                
                            } else {
                                Toast.show("支付发生错误  code: " + (result?.resultStatus || " "))
                            }
                        })
                    } else if (payWay === 'IOS_IAP') { //内购
                        let productInfo = res?.productInfo;
                        if (productInfo) {
                            productInfo.outTradeNo = res.outTradeNo;
                            // productInfo.productId ='com.gktapp.chuanshuokuan'
                            let payDeadline = res?.payDeadline || '5分钟';
                            if (payDeadline){
                                Toast.show(`请在${payDeadline}时间内完成支付`)
                             let timer = setTimeout(()=>{
                                        clearTimeout(timer)
                                    const productId = JSON.stringify(productInfo);
                                    window.JsBridge
                                        .iapPay(productId).then(function (result) {
                                        if (result?.code === '200') { //成功
                                            const receiptDataObj = JSON.parse(result.receiptData);
                                            if (receiptDataObj) {
                                                getIapPayResult(receiptDataObj);
                                            } else {
                                                Toast.show("获取苹果参数失败");
                                            }
                                        } else { //支付失败
                                            Toast.show("支付失败  code: " + (result?.code || " "))
                                        }
                                    })

                                },2000)
                            }
                        }
                    }
                }
            })
        } else {
            Toast.show("请到APP中支付")
        }

    }

    const throttlePayment = throttle(mobilePayment, 3000);

    return (
        <div className="bottom-pay-btn" ref={currentRef}>
            <div className="bottom-left">
                <span>总计：</span>
                <div className="price-text">
                    <div>
                      <span>¥</span>
                      <span>{round(payDetail.totalPrice,2)}</span>
                    </div>
                    {
                        //隐藏划线
                        // payDetail.salePrice > payDetail.totalPrice &&
                        // <div>¥<span>{round(payDetail.totalPrice,2)}</span></div>
                    }
                </div>
            </div>
            <div className="bottom-right">
                <Button onClick={throttlePayment} disabled={!payWay}>立即支付</Button>
            </div>
        </div>
    )
}

export default BottomBtn;