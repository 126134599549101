/**
 * @description 支付操作处理
*/
import {Toast} from "antd-mobile";
// import appService from '@/utils/appService';
/**
 * @description 1. 创建预订单
 * @param {Object} params
 */ 
const createPreOrder = () => {
    
}
/**
 * @description 2. 获取支付签名
 * @param {Object} params
 */ 
const getPaySign = () => {
    
}

const installedWx = ()=>{
    return new Promise((resolve, reject) => {
        if(window.JsBridge.hasWebViewBridge()){
            window.JsBridge.nativeModules("UMShareModule", "isInstalledWx").then(res=>  {
                resolve(res)
            })
        }else {
            resolve(true);
        }
        
    })
}

/**
 * @description 3. 支付操作
 * @param {'WXPAY'|'ALIPAY'|'IOS_IAP'} payType
 * @param {Object|string} payParam
 */ 
const payAction = (payType, payParam) => {
    return new Promise(async (resolve, reject) => {
        if (window.JsBridge.hasWebViewBridge()){
            if (payType === 'WXPAY') {
               const installedWX = await installedWx();
               if(!installedWX){
                    return reject("请先安装微信");
               }
                window.JsBridge.wxPay({
                    partnerId: payParam.partnerId || '',
                    prepayId: payParam.prepayId || '',
                    nonceStr: payParam.nonceStr || '',
                    timeStamp: payParam.timeStamp || '',
                    sign: payParam.sign || '',
                    appId: payParam.appId,
                })
                .then(function (result) {
                    // 支付失败
                    if (result.errCode === -2) {
                        reject("支付取消")
                    } else if (result.errCode === 0) {
                        // 支付回调
                        const payRes = {
                            errStr: result?.errStr,
                            errCode: result?.errCode,
                            outTradeNo: payParam.outTradeNo,
                            source: "wxpay",
                        }
                        resolve(payRes);
                    } else {
                        reject("支付发生错误  code: " + (result?.errCode || " "))
                    }
                })
            }else if (payType === 'ALIPAY') {
                // 支付宝支付
                const payResult = payParam;
                window.JsBridge.aliPay(payResult).then(function (result) {
                    // 支付失败
                    if (result.resultStatus == 6001) {
                        reject("支付取消")
                        
                    } else if (result.resultStatus == 62000) {
                        reject("客户端未安装支付通道依赖的服务")
                    } else if (result.resultStatus == 9000) {
                        // 支付回调
                        const resFmt = JSON.parse(result.result || "{}")
                        const payRes = {
                            errStr: resFmt?.alipay_trade_app_pay_response?.msg || "",
                            errCode: resFmt?.alipay_trade_app_pay_response?.code || "",
                            outTradeNo: payParam.outTradeNo || "",
                            source: "alipay",
                        }
                        resolve(payRes);
                    } else {
                        reject("支付发生错误  code: " + (result?.resultStatus || " "))
                    }
                })
            }else if (payType === 'IOS_IAP') {
                //ios内购
                const productInfo = payParam.productInfo;
                if (productInfo) {
                    productInfo.outTradeNo = payParam.outTradeNo;
                    const payDeadline = payParam.payDeadline || '5分钟';
                    Toast.show({
                        content: `请在${payDeadline}内完成支付`,
                        afterClose: () => {
                            const productId = JSON.stringify(productInfo);
                            window.JsBridge.iapPay(productId)
                            .then((result) => {
                                if (result?.code === '200') { //成功
                                    const receiptDataObj = JSON.parse(result.receiptData);
                                    if (receiptDataObj) {
                                        resolve(receiptDataObj);
                                    } else {
                                        reject("获取iap支付参数失败");
                                    }
                                } else { 
                                    //支付失败
                                    reject("支付失败  code: " + (result?.code || " "))
                                }
                            })
                        }
                    })
                }else {
                    reject("缺少支付参数");
                }
            }
            else {
                reject("支付方式参数错误");
            }
        }else {
            reject("请在APP内进行支付");
        }
    
    })
    
}

export {
    payAction,
    createPreOrder,
    getPaySign,
}