import React from 'react';
import './style.scss';
import {isEmptyString} from "@/utils/publicFun";

const TextMap = {
    0: {success: '转赠成功', failed: '转赠失败'},
    1: {success: '接收成功', failed: '接收失败'},
}

const RecordItem = ({collection, maxDay}) => {


    const hashClick = (hash) => {
        if (!isEmptyString(hash)) {
            window.JsBridge.push("Web", {uri: `${window.location.origin}/blockChainColInfo?transactionHash=${hash}`});
        }
    }

    const blockMsgClick=(address)=>{
        if (!isEmptyString(address)){
            window.JsBridge.push("Web", {uri: `${window.location.origin}/blockChainAdrInfo?walletAddress1=${address}`});
        }
    }


    const {
        collectionName,
        createdAt,
        updatedAt,
        nftNo,
        transactionHash,
        state,
        image,
        uname,
        rname,
        address
    } = collection;

    const status = state === 'COMPLETE';
    const isOut = maxDay === '0';

    return <>
        <div className="list-item">
            <div className="header">
                <img src={image} alt=""/>
                <div className="title">
                    <p>{collectionName}</p>
                    <p>#{nftNo}</p>
                </div>
            </div>
            <div className="content-item">
                <span className='key'>{isOut ? '接收者' : '转赠者'}</span>
                <span className='value hash'
                onClick={()=>blockMsgClick(address)}>{address}</span>
            </div>
            <div className="content-item">
                <span className='key'>转赠时间</span>
                <span className='value'>{createdAt.replace(/-/g, '.')}</span>
            </div>
            {status && <div className="content-item">
                <span className='key'>交易Hash</span>
                <span className='value hash' onClick={() => hashClick(transactionHash)}>{transactionHash}</span>
            </div>}
        </div>
    </>
}

export default RecordItem;
