import PartsRecyclingHome from './home';
import RecyclingRecord from './recyclingRecord';
import RecoveryParts from './recoveryParts';
import RecyclingShareSuccess from './recyclingShare';
import PartsRecovery from './components/partsRecovery'

export default {
  PartsRecyclingHome,
  RecyclingRecord,
  RecoveryParts,
  RecyclingShareSuccess,
  PartsRecovery,
}
