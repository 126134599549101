/**
 * @description 藏品名称及图片展示卡片
*/

import React, { useState, useEffect, useRef } from "react";
import "./index.scss";

const WhatCard = (props)=> {

    const {dataSouce={}} = props

    return (
        <div className="whatCard">
            <span>{ dataSouce.title || '---'}</span>
            <div className="eurocup_content">
                <div className="content_display">
                    <img src={dataSouce.imageBg}/>
                </div>
            </div>
        </div>
    )
}

export default WhatCard;