/**
 * @description 我的工业土地
 */

import React, { useState, useRef, useEffect ,useMemo} from "react";
import styles from "./index.module.scss";
import { Mask, Tabs, Toast } from "antd-mobile";
import ListView from "@/components/AntListView";
import ReceiveLand from "../receiveLand";
import RealName from "../realName";
import noData from "@/assets/img/landfi/no-data.png";
import close from "@/assets/img/landfi/landgy/icon_del.png";
import used from "@/assets/img/landfi/used.png";
import used_mode2 from "@/assets/img/landfi/landgy/used.png";
import buyLandIcon from "@/assets/img/landfi/landgy/buy_land_btn.png";
import { throttle } from "@/utils/publicFun";
import { myLandsList, confirmReceiveLands,getUserEnergy,indLandConvertMsg } from "@/api/guland";
import { hideLoading, showLoading } from "@/components/loading";
import { LandGyCredit } from "../button";
import TransOrder from '../transOrder';
import MyModal from "../../jewelLand/modal";
import ConfirmLandOrder from "../landOrder";
import TransteToGyModel from "./TransteToGyModel";
import classNames from "classnames";

const TabsList = [
  { key: "0", title: "全部" },
  { key: "1", title: "C级" },
  { key: "2", title: "B级" },
  { key: "3", title: "A级" },
];

const LandsMode = [
  { title: "工业用地", mode: 2 },
  { title: "农业用地", mode: 1 },
];

const TabMap = {
  0: "",
  1: 3,
  2: 2,
  3: 1,
};

const MyGyLands = ({ visible, onclose, userInfo, upgradeLand, container,mode,checkMode }) => {
  const [currentTab, setCurrentTab] = useState("0");
  const [currentLandTab, setCurrentLandTab] = useState(2);
  const [limit, setLimit] = useState({});

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    visible && setRefresh(false);
    visible && getUserEnergyHandle()
  }, [visible]);

  const onTabClick = (i) => {
    setCurrentTab(i);
  };

  const onReceive = () => {
    if (userInfo?.receiveNum <= 0) {
      return;
    }
    ReceiveLand.open({
      onConfirmReceive: async () => {
        if (!userInfo.ID) {
          RealName.open({
            confirmCB: () => {
              window.JsBridge.navigate("Verify");
              window.JsBridgeNew.hiddenStatusBar(false);
              window.JsBridgeNew.screenRotate(true);
            },
          });
          return;
        }
        await receiveGiveLands();
        setRefresh((pre) => !pre);
      },
    });
  };

  const buyLand = () => {
    if (!userInfo.ID) {
      RealName.open({
        confirmCB: () => {
          window.JsBridge.navigate("Verify");
          window.JsBridgeNew.hiddenStatusBar(false);
          window.JsBridgeNew.screenRotate(true);
        },
      });
      return;
    }
    const ModalInstance = MyModal.show({
      content: (
        <ConfirmLandOrder
          onClose={() => ModalInstance?.close()}
          paySuccessCB={() => setRefresh((pre) => !pre)}
        />
      ),
      getContainer: container,
    });
  };

  // 获取工业用地额度
  const getUserEnergyHandle = async () => {
    try {
      const res = await getUserEnergy();
      if (res?.code !== "000000") throw res.msg;
      const resdata = res?.data || {}
      const a = resdata?.ta || 0
      const b = resdata?.tb || 0
      const c = resdata?.tc || 0
      const data = {
        ta:a,
        tb:b,
        tc:c
      }
      setLimit(data)
    } catch (error) {
      console.log(error);
      Toast.show(error);
      hideLoading();
    }
  };
   // 获取转换信息
   const getIndLandConvertMsg = async (blockLevel) => {
    try {
      const res = await indLandConvertMsg({blockLevel});
      if (res.code !== "000000") throw res.msg;
      return res
    } catch (error) {
      console.log(error);
      Toast.show(error);
      hideLoading();
    }
  };

   // 领取土地
   const receiveGiveLands = async () => {
    try {
      showLoading();
      const res = await confirmReceiveLands();
      if (res.code !== "000000") throw res.msg;
      hideLoading();
      Toast.show("领取成功");
    } catch (error) {
      console.log(error);
      Toast.show(error);
      hideLoading();
    }
  };
  const onTransLand = (landInfo,priceInfo) => {
    const ModalInstance = MyModal.show({
      content: <TransOrder 
        mode={2}
        info={{landInfo,priceInfo}}
        onClose={() => ModalInstance?.close()} 
        paySuccessCB={() => {
          getUserEnergyHandle()
          setRefresh((pre) => !pre)
        }}
      />,
      getContainer: container,
    })
  }

  // 工业用地转化
  const onTransteToGy = async (item) => {
    const res = await getIndLandConvertMsg(item?.blockLevel)
    const priceInfo = res?.data?.indLandConf || []
    priceInfo[0].myPoint = res?.data?.myPoint
    priceInfo[0].myGems = res?.data?.myGems
    priceInfo[0].defRepeatStr = res?.data?.defRepeatStr
    if(priceInfo[0]?.payType === 0){
      onTransLand(item,priceInfo)
      return
    }
    const content = (
      <TransteToGyModel
        container={container}
        type={1}
        mode={2}
        data={{landInfo:item,priceInfo}}
        onClose={() => ModalInstance?.close()}
        successCB={() => {
          getUserEnergyHandle()
          setRefresh((pre) => !pre)
        }}
      />
    );
    const ModalInstance = MyModal.show({
      content: content,
      getContainer: container,
    });
  };

  return (
    <Mask className={styles.my_lands_mask} visible={visible} destroyOnClose>
      <div className={styles.my_lands_container}>
        <img
          className={styles.close}
          src={close}
          alt=""
          onClick={() => onclose(refresh)}
        />
        <div className={styles.my_lands_header}>
          <span className={styles.header_left}>我的土地</span>
          <div className={styles.header_right}>
            <div className={styles.gy_lands_edu}>
              <div className={styles.gy_lands_edu_title}><span>工业用地额度</span></div>
              {["C", "B", "A"].map((item, index) => {
                const num =limit[`t${item.toLocaleLowerCase() }`] || 0
                return <LandGyCredit key={index} level={item} credit={num} />;
              })}
            </div>
            <img
              src={buyLandIcon}
              className={styles.buyLandBtn}
              alt=""
              onClick={buyLand}
            />
          </div>
        </div>
        <div className={styles.my_lands_content}>
          <div className={styles.level_tabs}>
            <Tabs
              className={styles.tabs}
              activeKey={currentTab}
              onChange={(key) => onTabClick(key)}
            >
              {TabsList.map((item, index) => (
                <Tabs.Tab
                  key={index}
                  title={
                    <div
                      className={`${styles.tab_title} ${
                        currentTab === item.key ? styles.tab_select : ""
                      }`}
                    >
                      {item.title}
                    </div>
                  }
                ></Tabs.Tab>
              ))}
            </Tabs>
          </div>
          <div className={styles.lands_list_box}>
            <div className={styles.lands_tabs}>
              {LandsMode.map((item, key) => {
                return (
                  <div
                    className={styles.lands_tabs_item}
                    key={key}
                    onClick={() => {
                      if (item.mode !== currentLandTab) {
                        setCurrentLandTab(item.mode);
                      }
                    }}
                  >
                    <span
                      className={classNames(
                        styles.item_text,
                        currentLandTab == item.mode ? styles.active : ""
                      )}
                    >
                      {item.title}
                    </span>
                    {currentLandTab == item.mode && (
                      <div className={styles.unLine}></div>
                    )}
                  </div>
                );
              })}
            </div>
            <LandList
              type={TabMap[currentTab]}
              limit={limit}
              upgradeLand={upgradeLand}
              onTransteToGy={onTransteToGy}
              refresh={refresh}
              currentLandTab={currentLandTab}
              mode={mode}
              checkMode={checkMode}
              onclose={onclose}
            />
          </div>
        </div>
      </div>
    </Mask>
  );
};

const LandList = ({ type, upgradeLand, onTransteToGy, refresh, currentLandTab,limit,mode,checkMode,onclose }) => {
  const [landData, setLandData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const domRef = useRef(null);

  const limitAmount = useMemo(() => {
    const a = limit?.ta || 0
    const b = limit?.tb || 0
    const c = limit?.tc || 0

    return a+b+c
  }, [limit]);
  useEffect(() => {
    getLandList();
  }, [type, refresh, currentLandTab]);

  const getLandList = async (isRefresh = true) => {
    try {
      if (isRefresh) {
        currentPage.current = 1;
        domRef.current?.scroll(0, 0);
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        pageNum: currentPage.current,
        pageSize: pageSize.current,
        blockLevel: type,
        spaceType: +currentLandTab === 2 ? 1 : 0,//空间类型 0农业  1工业
      };
      console.log(params);
      const res = await myLandsList(params);
      console.log(res);
      if (res.code !== "000000") throw res.msg;
      if (currentPage.current === 1) {
        setLandData(res?.data?.landBlocksDTOList || []);
      } else {
        setLandData([...landData, ...res?.data?.landBlocksDTOList]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.landBlocksDTOList?.length >= pageSize.current);
    } catch (error) {
      console.log(error);
      Toast.show(error);
    }
  };    
  const throttlePayment = throttle(onTransteToGy, 3000);


  const listItem = (item) => {
    const blockLevel = {
      '1':'a',
      '2':'b',
      '3':'c',
    }[String(item?.blockLevel)]
    const limitNum = limit?.[`t${blockLevel}`] || 0
    return (
      <div className={styles.list_item}>
        <div className={styles.list_item_inner}>
          <div
            className={classNames(styles.img_bg, currentLandTab == 2 ? styles.mode2 : "")}
          >
            <img className={styles.land_img} src={item.nftImage} alt="" />
            {item.setFlag === 1 && (
              <img
                className={styles.used_mark}
                src={currentLandTab == 2 ? used_mode2 : used}
                alt=""
              />
            )}
            {item.ownFlag === 1 ? (
              <div className={styles.lease_tag}></div>
            ) : null}
          </div>
          <p>#{item.nftNo}</p>
          {
            currentLandTab == 2 ? (
              <div className={styles.land_item_btns}>
                <div
                  className={`${styles.gy_yd_detail}`}
                  onClick={() => {
                    upgradeLand({...item,landType:currentLandTab});
                    }}
                ></div>
              </div>
            ) : (
              <div className={styles.land_item_btns}>
                <div
                  className={`${styles.up_land_btn} ${
                    [48, 63, 205].includes(item.collectionId)
                      ? styles.up_disabled
                      : styles.up_active
                  }`}
                  onClick={() => {
                  if(mode != 2){
                    upgradeLand({...item,landType:currentLandTab});
                  } else{
                    onclose && onclose()
                    checkMode && checkMode()
                  }
                  }}
                />
               {item.setFlag != 1 && limitNum>0 && item.ownFlag !== 1 && <div
                  className={`${styles.gy_yd} ${(item.ownFlag === 1 || item.setFlag === 1 ||  limitNum<=0) ?styles.trans_disabled:undefined}`}
                  onClick={() => {
                    if(item.ownFlag !== 1 && limitNum>0 &&item.setFlag !== 1 ){
                      throttlePayment(item)
                    }
                  }}
                ></div>}
              </div>
            )
          }
        </div>
      </div>
    );
  };
  return (
    <div className={styles.land_list} ref={domRef}>
      {landData?.length > 0 ? (
        <ListView
          dataSource={landData}
          hasMore={hasMore}
          style={{ paddingTop: 5 }}
          renderItem={listItem}
          onEndReached={async () => {
            await getLandList(false);
          }}
          isLoad={isLoad}
        />
      ) : !isLoad ? null : (
        <div className={styles.no_data}>
          <img src={noData} alt="" />
        </div>
      )}
    </div>
  );
};

export default MyGyLands;
