/*
    购买须知
*/

import React, { useState, useEffect, useRef } from "react";
import "./index.scss";

//漫画购买须知
const NoteBuyCard = (props) => {

    const { dataSouce = {} } = props

    return (
        <div className="NoteBuyCard">
            <div className="content_title">购买须知</div>
            <div className="content_text">
                {
                   dataSouce.saleRule || `数字藏品为虚拟数字商品，而非实物，仅限实名认证为年满18周岁的中国大陆用户购买。数字藏品的版权由发行方或者原创者拥有，除另外取得版权拥有者书面同意外，用户不得将数字藏品用于任何商业用途。\n数字藏品一经出售，不支持退换，源文件不支持本地下载。请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他形式进行使用。如您对活动有任何疑问，请联系客服咨询。\n请注意：请您于5分钟内完成数字藏品支付，超时后支付，库存可能已被释放。\n`
                }
            </div>
        </div>
    )
}

export default NoteBuyCard;