import React, { useEffect, useState } from "react"
import "./index.scss"
// import { getUserCenter } from "@/api/projectManager"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { Toast } from "antd-mobile"
import { getCollectionCount } from '@/api/projectManager_self';
import { showLoading, hideLoading } from '@/components/loading';
import { previewProtocol } from '@/utils/publicFun';

function Mine() {
  const navigate = useNavigate()
  const [user, setUser] = useState()
  const bridge = window.JsBridge

  useEffect(() => {
    bridge.setTitle("我的藏品")
    // getUserCenter().then((res) => {
    //   setUser(res)
    // })
    getCount();
  }, [])

  const getCount = async () => {
    try {
      showLoading();
      const res = await getCollectionCount();
      hideLoading();
      if(res.code !== '000000') throw res.msg;
      setUser(res.data);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const goCollection = () => {
    navigate("/myCollection")
  }
  const goOrder = () => {
    navigate("/myGroupOrder")
  }
  const goBlindBox = () => {
    if (bridge.hasWebViewBridge()) {
      bridge.push("CustomWeb", {
        uri: `${window.location.origin}/myMysteryBox`,
      })
    } else {
      navigate(`/myMysteryBox`)
    }
  }
  const endTime = new Date("2022-07-15 15:00:00".replace(/-/g, "/")).getTime()
  const nowTime = new Date().getTime()
  return (
    <div className="profile">
      {nowTime <= endTime && (
        <div className="mystery_box" onClick={goBlindBox}>
          <img src={require("@/assets/img/profile/mh.png")} alt="" />
          <div className="start_time">
            <span className="time_label">中签公布时间</span>
            <span className="time">{(user?.prize_time && moment(user?.prize_time.replace(/-/g, "/")).format("MM月DD日 h:mm")) || "--"}</span>
          </div>
        </div>
      )}
      <ul className="mine">
        <li className="my" onClick={goCollection}>
          <img src={require("@/assets/img/profile/collection.png")} alt="" className="logo" />
          <div className="detail">
            <span className="detail-text">我的藏品</span>
            <br />
            <span className="num">{user?.count || 0}</span>
          </div>
        </li>
        <li className="split"></li>
        <li className="my" onClick={goOrder}>
          <img src={require("@/assets/img/profile/order.png")} alt="" className="logo" />
          <div className="detail">
            <span className="detail-text">我的订单</span>
            <br />
            <span className="num">{user?.orderTotal || 0}</span>
          </div>
        </li>
      </ul>
      <div className="mine_bottom">
        查看<span onClick={() => previewProtocol(5)}>《绿地数字藏品许可及服务协议》</span>
      </div>
    </div>
  )
}

export default Mine
