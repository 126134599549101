import React, {useEffect, useState} from "react"
import "./index.scss"
import {useNavigate, useLocation} from "react-router-dom"
import "swiper/css/bundle"
import "swiper/css/effect-cards"
import NFTHeader from "@/components/NFTHeader";

const bridge = window.JsBridge
export default function ShopPaySuccess() {
    const navigate = useNavigate()
    useEffect(() => {
        bridge.setTitle("支付成功")
    }, [])
    const lookDetail = () => {
        navigate(`/myCollection`, {replace: true})
    }
    return (
        <div className="shop_paysuccess">
            <NFTHeader midText="支付成功" bgColor="white"/>
            <div className={'shop_content'}>
                <img src={require("@/assets/img/icon_shop_gou.png")} className={'shop_img'}/>
                <div className={'shop_pay_success_des0'}>支付成功</div>
                <div className={'shop_pay_success_des1'}>配件可以在【我的藏品】查看</div>
            </div>
            <div className={'shop_go_look_father'} onClick={() => lookDetail()}>立即查看</div>
        </div>
    )
}
