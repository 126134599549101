import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styles from './receiveLand.module.scss'
import { Mask } from 'antd-mobile'
import { receiveLands } from "@/api/guland"
import { Toast } from 'antd-mobile'
import close from '@/assets/img/landfi/icon_del.png'

const ReceiveLand = ({ onClose, onConfirmReceive }) => {
  const [visible, setVisible] = useState(true)
  const [receiveData, setReceiveData] = useState({})

  useEffect(() => {
    visible && getReceiveData()
  }, [visible])

  const closeMask = () => {
    setVisible(false)
    onClose && onClose()
  }

  const onConfirm = () => {
    setVisible(false)
    onClose && onClose()
    onConfirmReceive && onConfirmReceive()
  }

  // 查询领取土地信息
  const getReceiveData = async () => {
    try {
      const res = await receiveLands()
      if (res.code !== '000000') throw res.msg
      setReceiveData(res.data || {})
    } catch (error) {
      console.log(error)
      Toast.show(error)
    }
  }


  return <Mask
    className={styles.receive_mask}
    visible={visible}
  >
    <div className={styles.confirm_container}>
      <div className={styles.header}>
        <span></span>
        <span className={styles.title}>领取土地</span>
        <img src={close} alt="" onClick={() => closeMask()}/>
      </div>
      <img className={styles.place_land_img} src={receiveData.nftImage} alt="" />
      <div className={styles.confirm_tip}>
        <span>您可领取</span>
        <span>{receiveData.receiveNum}块C级土地</span>
      </div>
      <div className={styles.confirm_btn} onClick={onConfirm}>确认领取</div>
    </div>
  </Mask>
}

ReceiveLand.open = ({onConfirmReceive}) => {
  let div = null;
  if (!div) {
      div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
      div.remove();
  }
  ReactDOM.render(<ReceiveLand onClose={close} onConfirmReceive={onConfirmReceive}/>, div);
}

export default ReceiveLand