export default {
  '31': {
    topImg: require("@/assets/img/variationApe/bg_buy.png"),
    boxImg: [
      {
        source:require("@/assets/img/variationApe/blind-front-back.png"),
        direction: 'front'
      },
      {
        source:require("@/assets/img/variationApe/blind-front-back.png"),
        direction: 'back'
      },
      {
        source:require("@/assets/img/variationApe/blind-left-right.png"),
        direction: 'left'
      },
      {
        source:require("@/assets/img/variationApe/blind-left-right.png"),
        direction: 'right'
      },
      {
        source:require("@/assets/img/variationApe/blind-up-down.png"),
        direction: 'up'
      },
      {
        source:require("@/assets/img/variationApe/blind-up-down.png"),
        direction: 'down'
      }
    ]
  },
  '72': {
    topImg: require("@/assets/img/workapeBlindboxImg/bg_xq.png"),
    boxImg: [
      {
        source:require("@/assets/img/workapeBlindboxImg/2.png"),
        direction: 'front'
      },
      {
        source:require("@/assets/img/workapeBlindboxImg/2.png"),
        direction: 'back'
      },
      {
        source:require("@/assets/img/workapeBlindboxImg/3.png"),
        direction: 'left'
      },
      {
        source:require("@/assets/img/workapeBlindboxImg/3.png"),
        direction: 'right'
      },
      {
        source:require("@/assets/img/workapeBlindboxImg/1.png"),
        direction: 'up'
      },
      {
        source:require("@/assets/img/workapeBlindboxImg/1.png"),
        direction: 'down'
      }
    ]
  },
  '77': {
    topImg: require("@/assets/img/spaceBeautifulAccount/sale-top.png"),
    boxImg: [
      {
        source:require("@/assets/img/spaceBeautifulAccount/box-front.png"),
        direction: 'front'
      },
      {
        source:require("@/assets/img/spaceBeautifulAccount/box-front.png"),
        direction: 'back'
      },
      {
        source:require("@/assets/img/spaceBeautifulAccount/box-left.png"),
        direction: 'left'
      },
      {
        source:require("@/assets/img/spaceBeautifulAccount/box-left.png"),
        direction: 'right'
      },
      {
        source:require("@/assets/img/spaceBeautifulAccount/box-up.png"),
        direction: 'up'
      },
      {
        source:require("@/assets/img/spaceBeautifulAccount/box-up.png"),
        direction: 'down'
      }
    ]
  },
}