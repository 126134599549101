/*
 土地藏品回收
*/

import Home from './home';
import SynthesisRecord from './synthesisRecord';
import Synthesis from './synthesis';

export default {
    Home,
    Synthesis,
    SynthesisRecord,
}