/**
 * @description guland入口
 * @returns {}
*/

import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';
import {
  HomeBackBtn,
  SpaceId,
  ScoreBtn
} from "../components/button";
import './index.scss';

const Lands = [
  { mode: 1, label: '农业用地' },
  { mode: 2, label: '工业用地' },
  { mode: -1, label: '敬请期待。。。' },
]

const Index = () => {
  const navigate = useNavigate()
  const [initData, setInitData] = useState({})
  const [select, setSelect] = useState(0)

  const onSelectChange = (index) => {
    setSelect(index)
  }

  const onEnter = () => {
    const item = Lands[select];
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/landGame?mode=${item.mode}` });
    } else {
      navigate(`/landGame?mode=${item.mode}`);
    }
  }

  const onScoreBtn = ()=> {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/landGame/jewelLand` });
    } else {
      navigate(`/landGame/jewelLand`);
    }
  }

  const goBack = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.back();
    } else {
      window.history.go(-1);
    }
  }

  return (
    <div className='land-main'>
      <div className='land-main__header'>
        <HomeBackBtn onClick={goBack} />
        <SpaceId spaceId={initData?.spaceNo} isCustom={initData?.customNiceNo} spaceNiceNo={initData?.spaceNiceNo} />
        <ScoreBtn
          onClick={onScoreBtn}
          storeCounts={initData?.gemstonesCount}
        ></ScoreBtn>
      </div>

      <div className='land-main__content'>
        <div className='star-box'>
          {Lands.map((item, index) => {
            return (
              <div className={classNames('item', { 'item--active': select === index })}
                key={index}
                onClick={() => onSelectChange(index)}
              >
                <div className='item__icon'></div>
                <div className='item__title'>{item.label}</div>
              </div>
            )
          })}
        </div>
        {
          select === Lands.length - 1 ?
            <div className={classNames('enter-btn', 'disabled')}>进入</div>
            : <div className={classNames('enter-btn')} onClick={onEnter}>进入</div>
        }

      </div>
    </div>
  );
}

export default Index;