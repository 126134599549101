import React, { useRef, useEffect, useState } from "react";
import { showLoading, hideLoading } from "@/components/loading";
import {
  confirmSimpleOrder,
  queryPayResult,
  getPayParams,
  checkNotpay,
} from "@/api/projectManager_self";
import { BackBtn, GreenBtn } from "./button";
import { AliPayIcon, WxPayIcon } from "./common";
import { Radio, Input, Toast } from "antd-mobile";
import { throttle, computeNumber, round } from "@/utils/publicFun";
import defaultCrad from "@/assets/img/landfi/img_land_c1.png";
import styles from "./landOrder.module.scss";
import appService from "@/utils/appService";
import { getPurchaseInfo } from "@/api/guland";
import { getUserCertificateStatus } from '../utils'

//有无待支付订单
const checkIsHasNotPay = async () => {
  const { code, msg, data } = await checkNotpay({
    collectionType: "",
  });
  if (code !== "000000") throw msg;
  if (data?.notPayOrderCnt) {
    Toast.show("您有待付款的订单，请先完成上次订单");
    return true;
  }
};

const ConfirmLandOrder = ({ onClose, paySuccessCB }) => {
  const [reqInfo, setReqInfo] = useState({});
  const [payMethod, setPayMethod] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [purchaseNum, setPurchaseNum] = useState(1);
  const [payTypeList, setPayTypeList] = useState([]);
  // const [isAdult, setIsAduit] = useState(true)
  const installedWX = useRef();

  useEffect(() => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.nativeModules("UMShareModule", "isInstalledWx").then(
        function (res) {
          installedWX.current = res;
        }
      );
    }
  }, []);

  useEffect(() => {
    getPayTypeList();
    getSureOrderInfo();
  }, []);

  useEffect(() => {
    let allPrice = computeNumber(reqInfo?.price || 0, "*", purchaseNum).result;
    setTotalPrice(allPrice);
  }, [purchaseNum, reqInfo]);

  const getPayTypeList = async () => {
    const result = await appService("500001", { resource: "mall" }, false);
    if (result.status > 0) {
      console.log("result=====>", result);
      setPayTypeList(result?.data?.reverse() || []);
    }
  };

  const getPayResult = (params) => {
    try {
      showLoading();
      let timeId = setTimeout(async () => {
        const { code, msg } = await queryPayResult(params);
        hideLoading();
        if (code === "000000") {
          Toast.show({
            content: "支付成功",
            maskClickable: false,
            afterClose: () => {
              onClose();
              paySuccessCB && paySuccessCB();
            },
          });
        } else {
          Toast.show(msg);
        }
        timeId && clearTimeout(timeId);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };

  const getGoodsInfo = async () => {
    const res = await getPurchaseInfo({
      activityCode: "landC4",
      source: "app",
    });
    if (res?.code !== "000000") throw res?.msg;
    return res?.data;
  };

  const getSureOrderInfo = async () => {
    try {
      showLoading();
      const {age} = await getUserCertificateStatus() || {}
      const [goodsInfo, notPay] = await Promise.all([
        getGoodsInfo(),
        checkIsHasNotPay(),
      ]);
      hideLoading();
      setReqInfo({ ...goodsInfo, notPay, isAdult: age < 18 });
    } catch (error) {
      hideLoading();
      Toast.show(typeof error === "string" ? error : error.msg);
    }
  };

  const pay = async (params) => {
    try {
      showLoading();
      const orderInfo = await confirmSimpleOrder(params);
      if (orderInfo.code !== "000000") throw orderInfo.msg;
      if (totalPrice === 0) {
        hideLoading();
        return orderInfo.data;
      }
      const paramsInfo = await getPayParams({
        outTradeNo: orderInfo?.data?.outTradeNo || "",
      });
      hideLoading();
      if (paramsInfo.code !== "000000") throw paramsInfo.msg;
      return { ...paramsInfo.data, ...orderInfo.data };
    } catch (error) {
      hideLoading();
      Toast.show(error);
      return false;
    }
  };

  const onSubmit = async () => {
    // check app-env
    if (!window.JsBridge?.hasWebViewBridge()) {
      Toast.show("请到APP中支付");
      return;
    }
    if (!payMethod) {
      Toast.show("请选择支付方式");
      return;
    }
    if (reqInfo.notPay) {
      Toast.show("您有待付款的订单，请先完成上次订单");
      return;
    }
    const params = {
      totalNum: purchaseNum,
      totalUsePoint: 0,
      totalUseCash: totalPrice,
      payType: payMethod.toUpperCase(),
      source: "app",
      uid: reqInfo?.uuid,
      activityCode: "landC4",
      type: "7",
      productId: 41,
    };
    try {
      const res = await pay(params);
      if (!res) {
        return;
      }
      if (totalPrice === 0) {
        getPayResult({ outTradeNo: res.outTradeNo });
        return;
      }
      // TODO 微信支付
      if (params.payType === "WXPAY") {
        if (!installedWX.current) {
          Toast.show("请先安装微信");
          return;
        }
        const result = await window.JsBridge.wxPay({
          partnerId: res.partnerId || "",
          prepayId: res.prepayId || "",
          nonceStr: res.nonceStr || "",
          timeStamp: res.timeStamp || "",
          sign: res.sign || "",
          appId: res.appId,
        });
        // 支付失败
        if (result.errCode === -2) {
          Toast.show({
            content: "支付取消",
            maskClickable: false,
            afterClose: () => {
              onClose();
            },
          });
        } else if (result.errCode === 0) {
          // 支付回调
          let payParams = {
            errStr: result?.errStr,
            errCode: result?.errCode,
            outTradeNo: res.outTradeNo,
            source: "wxpay",
          };
          getPayResult(payParams);
        } else {
          Toast.show("支付发生错误  code: " + (result?.errCode || " "));
        }
      }
      // TODO 支付宝支付
      if (params.payType === "ALIPAY") {
        // 支付宝支付
        const payResult = res.payResult;
        const result = await window.JsBridge.aliPay(payResult);
        // 支付失败
        if (result.resultStatus == 6001) {
          Toast.show({
            content: "支付取消",
            maskClickable: false,
            afterClose: () => {
              onClose();
            },
          });
        } else if (result.resultStatus == 62000) {
          Toast.show("客户端未安装支付通道依赖的服务");
        } else if (result.resultStatus == 9000) {
          // 支付回调
          let resFmt = JSON.parse(result.result || "{}");
          let payParams = {
            errStr: resFmt?.alipay_trade_app_pay_response?.msg || "",
            errCode: resFmt?.alipay_trade_app_pay_response?.code || "",
            outTradeNo: res.outTradeNo || "",
            source: "alipay",
          };
          getPayResult(payParams);
        } else {
          Toast.show("支付发生错误  code: " + (result?.resultStatus || " "));
        }
      }
    } catch (error) {
      console.log(error.msg || error);
      return;
    }
  };

  const throttlePayment = throttle(onSubmit, 3000);

  return (
    <div className={styles.land_confirm_order}>
      <div className={styles.land_order__title}>确认订单</div>
      <div className={styles.order_content}>
        <div className={styles.order_content_left}>
          <div className={styles.order_info}>
            <div>
              <img src={defaultCrad} alt="" />
            </div>
            <div>
              <h1>C级土地</h1>
              <h2>¥{round(reqInfo.price, 2)}</h2>
            </div>
          </div>
          <div className={styles.purchase_num_box}>
            <div className={styles.purchase_num}>数量</div>
            <NumberStepper
              max={10}
              min={1}
              value={purchaseNum}
              onChange={(val) => {
                if (/^\+?[1-9][0-9]*$/.test(val)) {
                  setPurchaseNum(val);
                } else {
                  setPurchaseNum(1);
                }
              }}
              setPurchaseNum={setPurchaseNum}
            />
          </div>
        </div>
        <div className={styles.order_content_right}>
          <div className={styles.price_info}>
            <div>
              <div>商品总价</div>
              <div>
                <span>￥</span>
                {round(totalPrice, 2)}
              </div>
            </div>
            <div>
              <div>合计应付</div>
              <div>
                <span>￥</span>
                {round(totalPrice, 2)}
              </div>
            </div>
          </div>

          <div className={styles.pay_type}>
            <h1>选择支付方式</h1>
            <Radio.Group value={payMethod} onChange={setPayMethod}>
              {payTypeList.length > 0 &&
                payTypeList.map((payItem, index) => {
                  return (
                    <div
                      className={styles.speed_card_order__selectpay}
                      key={index}
                    >
                      {payItem.payCode === "Alipay" && (
                        <div>
                          <label htmlFor="alipay">
                            <AliPayIcon></AliPayIcon>
                            <span>支付宝</span>
                          </label>
                          <Radio id="alipay" value="alipay"></Radio>
                        </div>
                      )}
                      {payItem.payCode === "WeChat" && (
                        <div>
                          <label htmlFor="wxpay">
                            <WxPayIcon></WxPayIcon>
                            <span>微信</span>
                          </label>
                          <Radio id="wxpay" value="wxpay"></Radio>
                        </div>
                      )}
                    </div>
                  );
                })}
            </Radio.Group>
          </div>
        </div>
      </div>
      <div className={styles.order_foot}>
        <p>
          <span>合计</span>
          <span>￥</span>
          <span>{round(totalPrice, 2)}</span>
        </p>
        <GreenBtn disabled={reqInfo.isAdult} onClick={throttlePayment}>提交订单</GreenBtn>
      </div>
      <BackBtn onClick={onClose}></BackBtn>
    </div>
  );
};

const NumberStepper = ({ max, min, value, onChange, setPurchaseNum }) => {
  const onDecrease = () => {
    setPurchaseNum((pre) => {
      if (pre >= max) {
        return max;
      } else {
        return Number(pre) + 1;
      }
    });
  };

  const onIncrease = () => {
    setPurchaseNum((pre) => {
      if (pre <= min) {
        return min;
      } else {
        return Number(pre) - 1;
      }
    });
  };
  return (
    <div className={styles.purchase_stepper}>
      <div className={styles.decrease} onClick={onIncrease}>
        -
      </div>
      <div className={styles.input_num}>
        <Input
          max={max}
          min={min}
          type="number"
          value={value}
          onChange={onChange}
          className={`${styles.input} ${styles.input_count}`}
        />
      </div>
      <div className={styles.increase} onClick={onDecrease}>
        +
      </div>
    </div>
  );
};

export default ConfirmLandOrder;
