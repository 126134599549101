import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import * as wxJsBridge from "@/utils/wxJsBridge";
import NFTHeader from "@/components/NFTHeader";
import styles from './styles.module.scss';

import icon_fragment from '../imgs/icon_fragment.png';
import icon_exchange_ticket from '../imgs/icon_exchange_ticket.png';
import icon_epic_exchange_ticket from '../imgs/icon_epic_exchange_ticket.png';

import {getGoodslist, getGoodsStock, getWealth, goodsExchange} from "@/api/projectManager_self";
import {hideLoading, showLoading} from "@/components/loading";
import {Toast} from "antd-mobile";
import {objectToQueryParams, Platform} from "@/utils/publicFun";
import clickThrottle from "@/utils/clickThrottle";
import DuiHuanModal from "@/view/guessingActy/exchangeStore/component/donationModal/duiHuanModal";
import OpenAppFooter from "@/components/OpenAppFooter";

const isMiniprogram = Platform.isMiniprogram();

export default () => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [shishiKuanCount, setShishiKuanCount] = useState(0);
    const [suiPianCount, setSuiPianCount] = useState(0);
    const [bianyiCount, setBianyiCount] = useState(0);
    const [shishiVisible, setShishiVisible] = useState(false);
    const [popVisable, setPopVisable] = useState(false);
    const curItemRef = useRef();
    useEffect(() => {
        window.scroll(0, 0);
        onDidFocus();
        if (wxJsBridge.isMiniprogram()) {
            wxJsBridge.loadWxJSSDK().then(wx => {
                console.log('wx', wx);
                wx.miniProgram.postMessage({data: {foo: 'bar'}});
            });
        }
    }, []);

    const onDidFocus = () => {
        window.JsBridge.addListener().then(() => {
            loadData();
            getList();
            onDidFocus();
        });
    }


    useEffect(() => {
        loadData();
        getList();
    }, [])

    const loadData = async () => {
        try {
            const res = await getWealth({})
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let info = res.data?.info || {};
                let p1Tem = 0;//红色的//史诗款无聊猿
                let p2Tem = 0;//砖石 碎片
                let p3Tem = 0;//蓝色 //变异无聊猿
                for (const item of info) {
                    if (item.type === 1) {
                        p1Tem = item.amount;
                    } else if (item.type === 2) {
                        p2Tem = item.amount;
                    } else if (item.type === 3) {
                        p3Tem = item.amount;
                    }
                }
                setShishiKuanCount(p1Tem);
                setSuiPianCount(p2Tem);
                setBianyiCount(p3Tem);
            }
        } catch (error) {
            Toast.show(error);
        }
    }

    const getList = async () => {
        try {
            showLoading()
            const res = await getGoodslist({preview: 0})
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let goodsList = res.data.goodsList || [];
                let newArr = goodsList;

                for (const item of newArr) {
                    let p2Tem = 0;//砖石 碎片
                    let p3Tem = 0;//蓝色 变异
                    let p1Tem = 0;//红色的 史诗款
                    for (const item0 of item.spendItemList) {
                        if (item0.type === 1) {
                            p1Tem = item0.num;
                        } else if (item0.type === 2) {
                            p2Tem = item0.num;
                        } else if (item0.type === 3) {
                            p3Tem = item0.num;
                        }
                    }
                    item.suipian = p2Tem;
                    item.bianyi = p3Tem;
                    item.shishi = p1Tem;
                }
                setList(newArr);
            }
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const duihuanShishikuan = async () => {//兑换史诗款无聊猿
        let item = curItemRef.current;
        let params = {goodsId: item.id, num: 1, spendItem: {type: 1, num: 1}};
        try {
            showLoading()
            const res = await goodsExchange(params);
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            Toast.show('兑换成功');
        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const handleClick = async (item) => { //"goodsType": 8=藏品 3=实物   //collectionId=1:史诗款  32=变异无聊猿
        if (isMiniprogram) {
            // Toast.show('小程序')
            setPopVisable(true);
            return;
        }
        if (!clickThrottle()) {
            return;
        }
        //实名认证
        let res = await window.JsBridge.verifyRealName("请先实名认证");
        if (!res?.result) {
            return;
        }

        //     .then((res)=> {
        //     verifyRealName.current = !!res?.result
        // })

        try { //判断库存
            showLoading();
            const res = await getGoodsStock({goodsId: item.id});
            hideLoading()
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let remainStock = res.data?.remainStock || 0;
                if (!remainStock) {
                    Toast.show('库存不足');
                } else {
                    handleDuihuan(item);
                }
            }
        } catch (error) {
            Toast.show(error);
            hideLoading()
        }
    };

    const handleDuihuan = (item) => {
        curItemRef.current = item;
        if (item.goodsType === 8) {//藏品
            if (item.collectionId === 1) { //史诗款
                // if (item.shishi > shishiKuanCount) {
                //     Toast.show('您的兑换券不足');
                //     return;
                // }
                setShishiVisible(true);
            } else {//变异无聊猿
                // if (item.suipian > suiPianCount && item.bianyi>bianyiCount){
                //     Toast.show('您的兑换券不足');
                //     return;
                // }
                const param = objectToQueryParams(item);
                window.JsBridge.push('CustomWeb', {
                    uri: window.location.origin + `/guessingActy/orderConfirmVirtual?${param}`,
                });
            }
        } else if (item.goodsType === 3) {//实物
            if (item.suipian > suiPianCount) {
                Toast.show('您的碎片不足')
                return;
            }
            const param = objectToQueryParams(item);
            window.JsBridge.push('CustomWeb', {
                uri: window.location.origin + `/guessingActy/orderConfirmReal?${param}`,
            });
        }
    }

    const rightBtnClick = () => {
        if (isMiniprogram) {
            // Toast.show('小程序');
            setPopVisable(true);
            return;
        }
        navigate('/guessingActy/exchangeOrder')
    }

    const btnView = (item) => {
        let buttonType = item.buttonType;
        // buttonType = 0;
//         0 立即兑换 1 限量兑换  2 未开始  3 兑换结束
        if (buttonType === 0) {
            return <button className={styles.btn} onClick={() => handleClick(item)}>立即兑换</button>
        } else if (buttonType === 1) {
            return <button className={styles.btn} onClick={() => handleClick(item)}>限量兑换</button>
        } else if (buttonType === 2) {
            return <button className={`${styles.btn} ${styles.disabled}`}>未开始</button>
        } else if (buttonType === 3) {
            return <button className={`${styles.btn} ${styles.disabled}`}>兑换结束</button>
        }
    }

    const renderItem = (item, index) => (
        <div className={styles.item} key={index}>
            <img src={item.goodsImg} alt=""/>
            <div className={styles.name}>{item.goodsName}</div>
            <div className={styles.row}>
                {
                    item.suipian ? <div className={styles.material}>
                        <img src={icon_fragment} alt=""/>
                        <span>{item.suipian}</span>
                    </div> : null
                }
                { (item.suipian && item.bianyi)?<span className={styles.item_huo}>或</span>:null}
                {item.bianyi ? <div className={styles.material}>
                    <img src={icon_exchange_ticket} alt=""/>
                    <span>{item.bianyi}</span>
                </div> : null}
                {(item.bianyi && item.shishi)?<span className={styles.item_huo}>或</span>:null}
                {item.shishi ? <div className={styles.material}>
                    <img src={icon_epic_exchange_ticket} alt=""/>
                    <span>{item.shishi}</span>
                </div> : null}
                <div className={styles.price}>¥{item.crossedPrice}</div>
            </div>
            {btnView(item)}
        </div>
    );
    return (
        <div className={styles.container}>
            <NFTHeader midText="兑换商城" rightText="我的订单" bgColor="#fff"
                       rightClick={() => rightBtnClick()} backType={2}/>
            <div className={styles.top}>
                <div className={styles.label}>
                    <img src={icon_fragment} alt=""/>
                    <span>{suiPianCount}</span>
                </div>
                <div className={styles.label}>
                    <img src={icon_exchange_ticket} alt=""/>
                    <span>{bianyiCount}</span>
                </div>
                <div className={styles.label}>
                    <img src={icon_epic_exchange_ticket} alt=""/>
                    <span>{shishiKuanCount}</span>
                </div>
            </div>
            <div className={styles.list}>
                {list.map(renderItem)}
            </div>

            <DuiHuanModal
                title={'确定要兑换史诗款无聊猿吗？'}
                visible={!!shishiVisible}
                onClose={() => setShishiVisible(false)}
                leftBtnText="取消"
                rightBtnText="确认兑换"
                rightBtnCB={() => {
                    setShishiVisible(false);
                    duihuanShishikuan();
                }}
            />
            <OpenAppFooter visible={popVisable} onClose={() => setPopVisable(false)}/>
        </div>
    )
}
