import React, { useEffect, useLayoutEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import "./index.scss"
import { round, getUrlAllParams, throttle } from "@/utils/publicFun"
import { getMysteryNum } from "@/api/projectManager";
import { getGoodsDetail, getShareInfo, checkNotpay } from '@/api/projectManager_self';
import moment from "moment"
import { Button, Toast, Checkbox } from "antd-mobile"
import ShareMask from "@/components/ShareMask/index"
import { showLoading, hideLoading } from '@/components/loading';
import qs from 'query-string';

function SaleDetail() {
  const navigate = useNavigate()
  const [maskVisible, setMaskVisible] = useState(false)
  const [collectionInfo, setCollectionInfo] = useState(null)
  const [shareParams, setShareParams] = useState()
  const [buttonName, setButtonName] = useState("Loading...")
  const [verifyRealName, setVerifyRealName] = useState(true)
  const [userAgree, setUserAgree] = useState(false)
  const { search } = useLocation()
  const bridge = window.JsBridge
  const imgUrl = process.env.REACT_APP_OBS_URL
  window.history.scrollRestoration = "manual"

  // NOTE: collection_id 和 sale_batch_id是必须参数
  let { collectionId: collection_id = '', classify = "", code: cardNo = '' } = getUrlAllParams(search.slice(1))
  const gotoSalePay = () => {
    const params = {
      collection_id,
      classify,
      code: cardNo,
      saleBatchId: collectionInfo?.saleBatchInfo?.saleBatchId
    }
    if (bridge.hasWebViewBridge()) {
      bridge.push("Web", { uri: `${window.location.origin}/salePay?${qs.stringify(params)}` });
    } else {
      navigate(`/salePay?${qs.stringify(params)}`);
    }
  }
  const goAgreement = () => {
    if (bridge.hasWebViewBridge()) {
      bridge.push("Web", { uri: `${window.location.origin}/MyAgreement` })
    } else {
      navigate(`/MyAgreement`)
    }
  }

  /**
   * 只有APP支持购买，浏览器需要引流至APP购买
   * collection_type===4说明是盲盒购买模式，需要调用盲盒购买次数接口
   */
  const snapUp = () => {
    if (userAgree === true) {
      if (bridge.hasWebViewBridge()) {
        bridge.verifyRealName("请先实名认证").then(function (res) {
          if (res?.result) {
            queryIsHasNotPay();
          }
        })
      } else {
        Toast.show("请至APP内购买")
      }
    } else {
      Toast.show("请先勾选《绿地数字藏品许可及服务协议》")
    }
  }

  const queryIsHasNotPay = async () => {
    try {
      showLoading();
      const params = { collectionType: collectionInfo?.collectionType || '' };
      const { code, msg, data } = await checkNotpay(params);
      hideLoading();
      if(code !== '000000') throw msg;
      if (data?.notPayOrderCnt) {
        Toast.show("您有待付款的订单，请先完成上次订单")
      } else {
        if (collectionInfo.collectionType !== 4) {
          gotoSalePay()
        } else {
          getMysteryNum().then((res) => {
            if (res?.blind_buy_num > 0) {
              gotoSalePay()
            } else {
              Toast.show("暂无购买次数")
            }
          })
        }
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const getGoodsInfo = async (verify) => {
    try {
      showLoading();
      const params = {collectionId: collection_id, cardNo};
      const { code, msg, data } = await getGoodsDetail(params);
      hideLoading();
      if(code !== '000000') throw msg;
      setCollectionInfo(data.info);
      bridge.setTitle(data?.info?.title);
      const start = new Date(data?.info?.saleBatchInfo?.saleStartTime.replace(/-/g, "/")).getTime();
      const end = new Date(data?.info?.saleBatchInfo?.saleEndTime.replace(/-/g, "/")).getTime();
      let time = new Date().getTime();
      if (start && time < start) {
        setButtonName(moment(new Date(start)).format("MM-DD HH:mm") + "开售");
      } else if (end && time >= end) {
        setButtonName("已售罄");
      } else {
        if (data?.info?.saleBatchInfo?.soldDisplay < data?.info?.saleBatchInfo?.stock) {
          verify ? setButtonName("购买") : setButtonName("实名后购买");
        } else {
          setButtonName("已售罄");
        }
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const initialization = () => {
    if (!collection_id) {
      Toast.show("参数不合法")
    } else {
      let verify = true
      if (bridge.hasWebViewBridge()) {
        bridge.verifyRealName("请先实名认证").then(function (res) {
          verify = !!res?.result
          setVerifyRealName(verify)
          getGoodsInfo(verify)
        })
      } else {
        getGoodsInfo(verify)
      }
    }
  }
  // 藏品详情初始化
  useLayoutEffect(() => {
    window.scroll(0, 0)
    initialization()

    bridge.addListener().then(() => {
      initialization()
    })
  }, [])

  // 定时器
  useEffect(() => {
    const start = new Date(collectionInfo?.saleBatchInfo?.saleStartTime.replace(/-/g, "/")).getTime()
    const end = new Date(collectionInfo?.saleBatchInfo?.saleEndTime.replace(/-/g, "/")).getTime()
    let now = new Date().getTime()
    let interval = null

    if (collectionInfo?.saleBatchInfo?.soldDisplay < collectionInfo?.saleBatchInfo?.stock && now < end) {
      interval = setInterval(() => {
        let time = new Date().getTime()
        if (collectionInfo?.saleBatchInfo?.soldDisplay < collectionInfo?.saleBatchInfo?.stock) {
          if (start && time < start) {
            setButtonName(moment(new Date(start)).format("MM-DD HH:mm") + "开售")
          } else if (end && time >= end) {
            setButtonName("已售罄")
          } else {
            verifyRealName ? setButtonName("购买") : setButtonName("实名后购买")
          }
        } else {
          setButtonName("已售罄")
        }
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [collectionInfo])

  const share = async () => {
    setMaskVisible(true);
    // try {
    //   showLoading();
    //   const { code, msg, data } = await getShareInfo();
    //   hideLoading();
    //   if(code !== '000000') throw msg;
    //   setShareParams(data);
    //   setMaskVisible(true);
    // } catch (error) {
    //   hideLoading();
    //   Toast.show(error);
    // }
  }

  const goBack = () => {
    if (bridge.hasWebViewBridge()) {
      bridge.back()
    } else {
      navigate(-1)
    }
  }

  const collectModuleList = collectionInfo?.collectModuleList || [];

  const returnDiv = () => {
    if (collectionInfo?.collectionType === 1) {
      return (
        <>
          <div className="content_display display_ss">
            <div className="stage">
              <div className="face front">
                <img src={require("@/assets/img/dh/13.png")} alt="" />
              </div>
              <div className="face rear">
                <img src={require("@/assets/img/dh/13.png")} alt="" />
              </div>
              <div className="face left">
                <img src={require("@/assets/img/dh/12.png")} alt="" />
              </div>
              <div className="face rightface">
                <img src={require("@/assets/img/dh/12.png")} alt="" />
              </div>
              <div className="face up">
                <img src={require("@/assets/img/dh/11.png")} alt="" />
              </div>
              <div className="face down">
                <img src={require("@/assets/img/dh/11.png")} alt="" />
              </div>
            </div>
          </div>
        </>
      )
    } else if (collectionInfo?.collectionType === 2) {
      return (
        <>
          <div className="content_display display_cs">
            <div className="stage">
              <div className="face front">
                <img src={require("@/assets/img/dh/23.png")} alt="" />
              </div>
              <div className="face rear">
                <img src={require("@/assets/img/dh/23.png")} alt="" />
              </div>
              <div className="face left">
                <img src={require("@/assets/img/dh/22.png")} alt="" />
              </div>
              <div className="face rightface">
                <img src={require("@/assets/img/dh/22.png")} alt="" />
              </div>
              <div className="face up">
                <img src={require("@/assets/img/dh/21.png")} alt="" />
              </div>
              <div className="face down">
                <img src={require("@/assets/img/dh/21.png")} alt="" />
              </div>
            </div>
          </div>
        </>
      )
    } else if (collectionInfo?.collectionType === 3) {
      return (
        <>
          <div className="content_display display_dc">
            <div className="stage">
              <div className="face front">
                <img src={require("@/assets/img/dh/33.png")} alt="" />
              </div>
              <div className="face rear">
                <img src={require("@/assets/img/dh/33.png")} alt="" />
              </div>
              <div className="face left">
                <img src={require("@/assets/img/dh/32.png")} alt="" />
              </div>
              <div className="face rightface">
                <img src={require("@/assets/img/dh/32.png")} alt="" />
              </div>
              <div className="face up">
                <img src={require("@/assets/img/dh/31.png")} alt="" />
              </div>
              <div className="face down">
                <img src={require("@/assets/img/dh/31.png")} alt="" />
              </div>
            </div>
          </div>
        </>
      )
    } else if (collectionInfo?.collectionType === 4) {
      return (
        <>
          <div className="content_display display_mh">
            <div className="stage">
              <div className="face front">
                <img src={require("@/assets/img/dh/43.png")} alt="" />
              </div>
              <div className="face rear">
                <img src={require("@/assets/img/dh/43.png")} alt="" />
              </div>
              <div className="face left">
                <img src={require("@/assets/img/dh/42.png")} alt="" />
              </div>
              <div className="face rightface">
                <img src={require("@/assets/img/dh/42.png")} alt="" />
              </div>
              <div className="face up">
                <img src={require("@/assets/img/dh/41.png")} alt="" />
              </div>
              <div className="face down">
                <img src={require("@/assets/img/dh/41.png")} alt="" />
              </div>
            </div>
          </div>
        </>
      )
    } else if (collectionInfo?.collectionType === 6) {
      return (
        <>
          <div className="content_display display_waci">
            <div className="stage">
              <div className="face front">
                <img src={require("@/assets/img/dh/6.png")} alt="" />
              </div>
              <div className="face rear">
                <img src={require("@/assets/img/dh/6.png")} alt="" />
              </div>
              <div className="face left">
                <img src={require("@/assets/img/dh/7.png")} alt="" />
              </div>
              <div className="face rightface">
                <img src={require("@/assets/img/dh/7.png")} alt="" />
              </div>
              <div className="face up">
                <img src={require("@/assets/img/dh/5.png")} alt="" />
              </div>
              <div className="face down">
                <img src={require("@/assets/img/dh/5.png")} alt="" />
              </div>
            </div>
          </div>
        </>
      ) 
      } else if (collectionInfo?.collectionType === 7) {
        return (
          <>
            <div className="content_display display_waci">
              <div className="stage">
                <div className="face front">
                  <img src={require("@/assets/img/dh/2.png")} alt="" />
                </div>
                <div className="face rear">
                  <img src={require("@/assets/img/dh/2.png")} alt="" />
                </div>
                <div className="face left">
                  <img src={require("@/assets/img/dh/3.png")} alt="" />
                </div>
                <div className="face rightface">
                  <img src={require("@/assets/img/dh/3.png")} alt="" />
                </div>
                <div className="face up">
                  <img src={require("@/assets/img/dh/1.png")} alt="" />
                </div>
                <div className="face down">
                  <img src={require("@/assets/img/dh/1.png")} alt="" />
                </div>
              </div>
            </div>
          </>
        )
      } else {
      return (
        <>
          <div className="content_display"></div>
        </>
      )
    }
  }
  const throttleShare = throttle(share, 3000)

  const returnLunBo = () => {
    if (collectionInfo?.collectionType === 4 || collectionInfo?.collectionType === 3) {
      return (
        <>
          <div className="content_lunbo">
            <ul className="luobo_conter">
              <li>
                <img src={imgUrl + "M/1.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/2.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/3.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/4.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/5.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/6.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/1.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/2.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/3.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/4.png"} alt="" />
              </li>
            </ul>
            <ul className="luobo_conter">
              <li>
                <img src={imgUrl + "M/11.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/12.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/13.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/14.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/15.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/16.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/11.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/12.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/13.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/14.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "M/15.png"} alt="" />
              </li>
            </ul>
          </div>
        </>
      )
    } else if (collectionInfo?.collectionType === 1 || collectionInfo?.collectionType === 6) {
      return (
        <>
          <div className="content_lunbo">
            <ul className="luobo_conter">
              <li>
                <img src={imgUrl + "S/1.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/2.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/3.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/4.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/5.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/6.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/1.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/2.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/3.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/4.png"} alt="" />
              </li>
            </ul>
            <ul className="luobo_conter">
              <li>
                <img src={imgUrl + "S/11.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/12.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/13.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/14.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/15.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/16.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/11.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/12.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/13.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/14.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "S/15.png"} alt="" />
              </li>
            </ul>
          </div>
        </>
      )
    } else if (collectionInfo?.collectionType === 2 || collectionInfo?.collectionType === 7) {
      return (
        <>
          <div className="content_lunbo">
            <ul className="luobo_conter">
              <li>
                <img src={imgUrl + "C/1.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/2.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/3.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/4.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/5.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/6.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/1.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/2.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/3.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/4.png"} alt="" />
              </li>
            </ul>
            <ul className="luobo_conter">
              <li>
                <img src={imgUrl + "C/11.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/12.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/13.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/14.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/15.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/16.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/11.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/12.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/13.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/14.png"} alt="" />
              </li>
              <li>
                <img src={imgUrl + "C/15.png"} alt="" />
              </li>
            </ul>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <div className="detail_b">
      <ShareMask
        maskVisible={maskVisible}
        closeMask={() => setMaskVisible(false)}
        shareCode={shareParams?.code || ""}
        title={collectModuleList[0]?.title}
        context={collectModuleList[0]?.subTitle}
        icon={collectModuleList[0]?.image}
        type={collectionInfo?.collectionType}
      />
      <div className="detail_bc"></div>
      {classify !== '488_product' && <div className="right_btn" onClick={throttleShare}></div>}
      <div className="back_btn" onClick={goBack}></div>
      <div className="detail_banner">
        <div className="detail_main">
          <div className="content content_opcity">
            <div className="content_title">{collectionInfo?.title}</div>
            <div className="content_banner">
              <div className="stage"></div>
              {returnDiv()}
              <div className="content_series"></div>
              {collectionInfo?.collectionType === 4 ? (
                <p className="mh_text">{collectionInfo?.blindRight}</p>
              ) : (
                <>
                  {collectionInfo?.vipLevel && (
                    <p className="content_text">
                      <span className="label">权益①:</span>
                      <span>{collectionInfo?.vipLevel}</span>
                    </p>
                  )}
                  {collectionInfo?.rewardPoints ? (
                    <p className="content_text">
                      <span className="label">权益②:</span>
                      <span>购买返{collectionInfo?.rewardPoints}积分</span>
                    </p>
                  ): null}
                  {collectionInfo?.prizeDisneyTimes || collectionInfo?.prizeBlindBoxTimes || collectionInfo?.blindRight ? (
                    <p className="content_text">
                      <span className="label">权益③:</span>
                      <span>
                        {collectionInfo?.prizeDisneyTimes === 0 ? "" : "迪士尼抽奖活动资格" + collectionInfo?.prizeDisneyTimes + "次,"}
                        {collectionInfo?.prizeBlindBoxTimesStr === 0 ? "" : collectionInfo?.prizeBlindBoxTimesStr}
                        {collectionInfo?.blindRight}
                      </span>
                    </p>
                  ) : null}
                  {collectionInfo?.right4 ? (
                    <p className="content_text">
                      <span className="label">权益④:</span>
                      <span>{collectionInfo?.right4}</span>
                    </p>
                  ) : null}
                  <p className="content_textlast"></p>
                </>
              )}
            </div>
          </div>

          <div className="content">
            <div className="content_title">发售详情</div>
            {collectionInfo?.collectionType === 4 && (
              <div className="content_spacebt">
                <span>发售数量</span>
                <span>
                  1000份
                  <br />
                  <p>内含隐藏款999份，典藏款1份</p>
                </span>
              </div>
            )}
            <div className="content_spacebt">
              {classify !== '488_product' ?<span>发售价格</span>: <span>
                发售价格<br/>
                <span className="receive_tip">可以使用赠送券免费领取</span>
              </span>}
              <span>¥{round(collectionInfo?.price, 2)}</span>
            </div>
            {collectionInfo?.pointRatio === 0 || classify === '488_product' ? "" : <p className="content_radio">可以使用积分抵扣（{collectionInfo?.pointRatio}积分抵扣1元）</p>}
            <div className="content_spacebt">
              <span>发售时间</span>
              <span>{collectionInfo?.subtitle}</span>
            </div>
            <div className="content_spacebt">
              <span>发售数量</span>
              <span>{collectionInfo?.numberSales}份</span>
            </div>
            <div className="content_spacebt">
              <span>发行方</span>
              <div className="content_home">
                <div></div>
                {collectionInfo?.owner}
              </div>
            </div>
          </div>

          <div className="content">
            <div className="content_title">{collectModuleList[0]?.title}</div>
            <p>{collectModuleList[0]?.subTitle}</p>
            <img className="desc-img" src={collectModuleList[0]?.image} alt="" />
          </div>
          <div className="content">
            <div className="content_title">{collectModuleList[1]?.title}</div>
            <p>{collectModuleList[1]?.subTitle}</p>
            <div className="content_img_center">
              <img className="desc-img" src={collectModuleList[1]?.image} alt="" />
            </div>
            {returnLunBo()}
          </div>
          

          <div className="content">
            {/* {collectionInfo?.collectionType === 3 && (
              <div className="content_title">
                <span>全球首发</span> {collectionInfo?.supply}份
              </div>
            )} */}
            <div className="content_img_center">
              <li>
                <img src={require("@/assets/img/homedetail/Group2.png")} alt="" />
                <p>唯一编号</p>
              </li>
              <li>
                <img src={require("@/assets/img/homedetail/Group1.png")} alt="" />
                <p>可信纪录</p>
              </li>
              <li>
                <img src={require("@/assets/img/homedetail/Group3.png")} alt="" />
                <p>永久存证</p>
              </li>
              <li>
                <img src={require("@/assets/img/homedetail/Group4.png")} alt="" />
                <p>不可修改</p>
              </li>
            </div>
          </div>
          

          {/* <div className="content">
            <div className="content_title">售卖规则</div>
            <p>{collectionInfo?.sale_rule}</p>
            <br />
          </div> */}
          <div className="content">
            <div className="content_title">购买须知</div>
            <p>
              1.数字藏品为虚拟数字商品，而非实物，仅限实名认证为年满18周岁的中国大陆用户购买。本商品一经售出，不支持退换。本商品源文件不支持本地下载。请勿对数字藏品进行炒作、场外交易、欺诈、与虚拟货币或金融资产关联，或以任何其他非法方式进行使用。
              <br />
              <br />
              2.数字藏品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字藏品用于任何非法商业用途。对于数字藏品及其衍生品，如用户需要对该数字艺术品做出复制、出租、改编、放映、广播或进行网络传播等涉及作品著作权授权的行为，应获得著作权人的相应授权，并订立相关的协议。
              <br />
              <br />
              3.购买数字藏品行为即同意本平台《绿地数字藏品许可及服务协议》等规则，同意平台进行相关实名认证，用户应妥善保护好交易账号和密码，不得进行洗钱等违法违规活动，警惕和远离非法集资等相关非法金融活动，切实维护自身财产安全。
              <br />
              <br />
              4.请注意：请您于5分钟内完成订单支付，超时后订单将被自动取消。
              <br />
            </p>
            <br />
          </div>
          <div className="content_space"></div>
        </div>
      </div>
      <div className="detail_bottom">
        <div className="detail_bottom_top">
          <Checkbox
            defaultChecked={userAgree}
            onChange={(checked) => setUserAgree(checked)}
            style={{
              "--icon-size": "14px",
              "--font-size": "14px",
              "--gap": "6px",
            }}
          ></Checkbox>
          <p>
            我已阅读并同意<span onClick={goAgreement}>《绿地数字藏品许可及服务协议》</span>
          </p>
        </div>
        {classify !== '488_product' ? <div className="detail_bottom_bt">
          <div className="left">
            {/* <span className="total">价格:</span> */}
            <div className="price-detail">
              <div className="price">¥{round(collectionInfo?.price, 2)}</div>
            </div>
          </div>
          <div
            className={buttonName === "已售罄" ? "unsale" : buttonName === "购买" && userAgree === false ? "sale_nocheck" : "sale"}
            onClick={snapUp}
          >
            <Button block color="primary" size="large" disabled={buttonName !== "购买" && buttonName !== "实名后购买"}>
              {buttonName}
            </Button>
          </div>
        </div>: <div className="detail_bottom_bt">
          <div className="left">
            <div className="price-detail">
              <div className="price">¥0</div>
              <div className="line-price">¥{round(collectionInfo?.price, 2)}</div>
            </div>
          </div>
          <div className="sale" onClick={snapUp}>
            <Button block color="primary" size="large">立即领取</Button>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default SaleDetail
