import React, {useEffect, useState} from 'react';
import './style.scss';
import { computeNumber } from "@/utils/publicFun";

const ReturnPoints = ({payDetail}) => {
  const [points, setPoints] = useState(0);

  useEffect(() => {
    if(payDetail) {
      const newPoints = computeNumber(computeNumber(payDetail.totalPrice, "*", payDetail.pointPercentage).result,"*", payDetail.pointRatio).result;
      setPoints(newPoints);
    };
  }, [payDetail])

  return <div className='return-points'>
    <span>持有{payDetail.comicsSet}漫画返{Number(payDetail.pointPercentage)*100}%积分：</span>
    <span>+{points}积分</span>
  </div>
}

export default ReturnPoints;
