import React, {useEffect, useRef, useState, useContext, createContext} from 'react';

import {Toast} from "antd-mobile";
import ShopStageRuleDialog from "./views/shopStageRuleDialog";
import ShopRecordPop from "./views/shopRecordPop";
import ShopShare from "./views/shopShare";
import {isAndroidOrIOS, isArray, isEmptyString, isIOS} from "@/utils/publicFun";
import ListView1 from "@/components/ListView1";
import './shopStageHome.scss'
import {
    addPurchase, changePurchase,
    getComponentList, getComponentListNoLogin, getShoppingNum,
    getUserInfo, shopConvertRecord,
} from "@/api/projectManager_self";
import {hideLoading, showLoading} from "@/components/loading";
import ShopProductDetail from "@/view/shopStage1/views/shopProductDetail";
import clickThrottle from "@/utils/clickThrottle";

let shop_shangcheng_headkv = require("@/assets/img/shop_shangcheng_headkv.png")//上面的大背景


let egg_bt_huodong = require("@/assets/img/egg_bt_huodong.png")
let egg_bt_shiming = require("@/assets/img/egg_bt_shiming.png")
let icon_jifen = require("@/assets/img/icon_shop_jifen.png")
let icon_shop_nor = require("@/assets/img/icon_shop_nor.png")
let icon_shop_dis = require("@/assets/img/icon_shop_dis.png")

let icon_shop_tianjia = require("@/assets/img/icon_shop_tianjia.png")
let icon_shop_gouwuche = require("@/assets/img/icon_shop_gouwuche.png")
let egg_logo = require("@/assets/img/egg_logo.png")
let icon_tag_dis = require("@/assets/img/icon_shop_miaoban.png") //未选中的样式
let icon_tag_sel = require("@/assets/img/icon_shop_nor.png") //选中的样式


const Context = createContext(null);
const JsBridge = window.JsBridge;
let animalCodeTem = '91'
let partsCodeTem = ''
let currentPage = 0;


export const ShopStageHome = () => {
    const [userInfo, setUserInfo] = useState({isLogin: false}); //用户信息  isLogin 用户已经登录 否则没有登录 ID不为空已经实名
    useEffect(() => {
        getUserInfo0();
    }, [])

    const refreshData = () => { //刷新数据 主要是登录之后要刷新
        getUserInfo0();
    }
    const getUserInfo0 = () => { //获取用户信息
        JsBridge.getCurrentUserInfo().then((userInfo0) => {
            if (userInfo0 && userInfo0.clientId) { //登录了
                JsBridge.getDetailCurrentUserInfo().then((userInfo1) => {
                    getUserInfo().then(r => {
                        if (r.code === '000000') {
                            if (r && r.data && r.data.clientId) {
                                let user = {...r.data, ...userInfo1}
                                setUserInfo(user);
                            }
                        }
                    })
                })
            }
        })
    }

    return (
        <Context.Provider value={{userInfo}}>
            <div className={'shop_stage_container'}>
                <HeaderComponent refreshData={refreshData}/>
                <ContentComponent refreshData={refreshData}/>
                <BottomLogoComponent/>
            </div>
        </Context.Provider>
    )
}

// 头部视图
const HeaderComponent = (props) => {
    const [ruleShow, setRuleShow] = useState(false);
    const [shareShow, setShareShow] = useState(false)
    const {userInfo,} = useContext(Context);
    const [isDHed, setIsDHed] = useState(false); //是否兑换过

    useEffect(() => {
        loadData();
    }, [userInfo])

    const loadData = async () => {
        if (!userInfo.isLogin) {
            return
        }
        try {
            // showLoading();
            const res = await shopConvertRecord({});
            // hideLoading();
            if (res.code !== '000000') throw res.msg;
            setIsDHed((res.data > 0))
        } catch (error) {
            // hideLoading();
            Toast.show(error);
        }
    }

    const refreshData = () => {
        if (props.refreshData) {
            props.refreshData();
        }
    }

    const goBack = () => {
        JsBridge.back()
    }

    const clickShare = () => {
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => {
                refreshData();
            });
            return;
        }
        setShareShow(!shareShow);
    }

    const huodongRule = () => {
        setRuleShow(!ruleShow)
    }

    const shiMingRenZhen = () => {
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => { //登录回来
                refreshData();
            });
            return;
        }
        JsBridge.addListener().then(() => { //实名认证回来
            refreshData();
        });
        JsBridge.navigate('Verify');
    }

    const duihuanClick = () => {
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => {
                refreshData();
            });
            return;
        }
        if (isDHed) {
            return;
        }

        JsBridge.addListener().then((res) => {
            //签到兑换回来也需要刷新
            loadData();
        });
        JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_GKT_LINK}/front/activities/dailySignin/index`});
    }

    const youqingHaoYouClick = () => {
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => {
                refreshData();
            });
            return;
        }
        setShareShow(!shareShow)
    }

    return (
        <>
            <div className={'shop_stage_header_component'}>
                <img src={shop_shangcheng_headkv} className={'shop_stage_img_bg'}/>
                <div className={'shop_stage_back_share_father'}>
                    <div className={'back_btn'} onClick={() => goBack()}></div>
                    <div className={'share'} onClick={() => clickShare()}></div>
                </div>

                <div className={'shop_stage_rule_shiming_father'}>
                    <div className={'huodong_father'} onClick={() => huodongRule()}>
                        <img src={egg_bt_huodong} alt=""/>
                        <div className={'title'}>活动规则</div>
                    </div>

                    {isEmptyString(userInfo.ID) ?
                        <div className={'huodong_father'} onClick={() => shiMingRenZhen()}>
                            <img src={egg_bt_shiming} alt=""/>
                            <div className={'title_shiming'}>实名认证</div>
                        </div> : null}
                </div>

                <div className={'jifen_qd_yx_father'}>
                    <div className={'jifen_father'}>
                        <span className={'jifen_des0'}>{'11月10日-11月15日，元宇宙进博集市下单即送限量\nApe World配件！ 先到先得！送完即止！'}</span>
                    </div>
                    <div className={'qd_yx_father'}>
                        <div className={'qd_father'}>
                            <div className={'qd_yx_title'}>签到碎片兑换</div>
                            <span className={'qd_yx_second_title'}>{`单个用户仅限兑换一次 \n(每日签到可领取碎片)`}</span>
                            <div className={'qd_btn_father'} onClick={() => duihuanClick()}>
                                <img src={isDHed ? icon_shop_dis : icon_shop_nor}/>
                                {isDHed ? <div className={'qd_btn_title_hui'}>已兑换</div> :
                                    <div className={'qd_btn_title'}>去兑换</div>
                                }
                            </div>
                        </div>
                        <div className={'yx_father'}>
                            <div className={'qd_yx_title'}>邀请好友得积分</div>
                            <span className={'qd_yx_second_title'}>成功邀请一位好友得300积分</span>
                            <div className={'qd_btn_father'} onClick={() => youqingHaoYouClick()}>
                                <img src={icon_shop_nor}/>
                                <div className={'qd_btn_title'}>邀请好友</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ShopStageRuleDialog maskVisible={ruleShow} setMaskVisible={setRuleShow} type={0}/>
            <ShopShare maskVisible={shareShow} userInfo={userInfo} closeMask={() => setShareShow(!shareShow)}/>
        </>
    )
}
let pageNum = 1;
const ContentComponent = (props) => {
    const {userInfo} = useContext(Context);
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [changeShopCount, setChangeShopCount] = useState(false);
    // let    pageNum = useRef(1).current;
    const pageSize = 10;

    // console.log('原始的---page', pageNum)


    const [headerTitles, setHeaderTitles] = useState([
        {title: '蝠', isSel: true, id: 0, animalCode: '91'},
        {title: '鹿', isSel: false, id: 1, animalCode: '92'},
        {title: '鱼', isSel: false, id: 2, animalCode: '93'},
        {title: '通用', isSel: false, id: 3, animalCode: '90'}
    ])
    const [secondTitles, setSecondTitles] = useState([
        {title: '全部', isSel: true, id: 0, partsCode: ''},
        {title: '身体', isSel: false, id: 1, partsCode: '01'},
        {title: '头部', isSel: false, id: 2, partsCode: '02'},
        {title: '饰品', isSel: false, id: 3, partsCode: '03'},
        {title: '套装', isSel: false, id: 4, partsCode: '04'}])
    const [data, setData] = useState([]);
    useEffect(() => {
        currentPage = 0;
        pageNum = 1;
        loadData();
    }, [userInfo])

    const loadData = async () => { //必须要登录
        if (currentPage === pageNum) {
            return
        }
        try {
            showLoading();
            let params = {
                animalCode: animalCodeTem,
                partsCode: partsCodeTem,
                seriesId: '04',
                current: pageNum,
                size: pageSize
            };
            currentPage = pageNum;
            const res = await getComponentListNoLogin(params);
            hideLoading();
            if (res.code !== '000000') throw res.msg;

            if (res.data && res.data.nftComponentVO && isArray(res.data.nftComponentVO)) {
                let totalArr = [];
                if (pageNum > 1) {
                    totalArr = data.concat(res.data.nftComponentVO);
                } else {
                    totalArr = res.data.nftComponentVO;
                }
                setData(totalArr);
                let totalSize = parseInt(res.data.total) || 0;
                setHasMore(totalArr.length < totalSize)
            } else {
                setData([]);
                setHasMore(false)
            }
            setIsLoad(true);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const loadMore = async () => {
        pageNum = pageNum + 1;
        // console.log('pageNum---', pageNum)
        await loadData();
    }

    const changeShopCountFun = () => {
        setChangeShopCount(!changeShopCount)
    }

    const clickHeaderTitle = (item) => {
        if (item.isSel) {
            return
        }
        animalCodeTem = item.animalCode;
        partsCodeTem = '';
        currentPage = 0;
        pageNum = 1;
        let titlesTem = JSON.parse(JSON.stringify(headerTitles))
        for (let i = 0; i < titlesTem.length; i++) {
            let itemTem = titlesTem[i];
            if (itemTem.id === item.id) {
                itemTem.isSel = true;
            } else {
                itemTem.isSel = false;
            }
        }
        let timer = setTimeout(() => {
            clearTimeout(timer);
            setHeaderTitles(titlesTem);
        }, 100);

        // 改变二级标题的状态
        let seconTitlesTem = JSON.parse(JSON.stringify(secondTitles))
        for (let i = 0; i < seconTitlesTem.length; i++) {
            let itemTem = seconTitlesTem[i];
            if (i === 0) {
                itemTem.isSel = true;
            } else {
                itemTem.isSel = false;
            }
        }
        let timer1 = setTimeout(() => {
            clearTimeout(timer1);
            setSecondTitles(seconTitlesTem);
        }, 100)
        loadData();
    }

    const header = () => {
        return headerTitles.map((item, index) => {
            return (
                <div className={'header_btn_father'}
                     onClick={() => clickHeaderTitle(item)} key={index.toString()}>
                    <img src={item.isSel ? icon_tag_sel : icon_tag_dis} className={'header_btn_img'}/>
                    <div
                        className={item.isSel ? 'header_btn_title' : 'header_btn_title_sel'}>{item.title}</div>
                </div>
            )
        })
    }
    const clickSecondTitle = (item) => {
        if (item.isSel) {
            return
        }
        partsCodeTem = item.partsCode;
        currentPage = 0;
        pageNum = 1;
        let titlesTem = JSON.parse(JSON.stringify(secondTitles))
        for (let i = 0; i < titlesTem.length; i++) {
            let itemTem = titlesTem[i];
            if (itemTem.id === item.id) {
                itemTem.isSel = true;
            } else {
                itemTem.isSel = false;
            }
        }
        let timer = setTimeout(() => {
            clearTimeout(timer);
            setSecondTitles(titlesTem);
        }, 100)
        loadData();
    }

    const secondHeader = () => {
        return secondTitles.map((item, index) => { //style={{marginLeft: index === 0 ? '3vw' : '1vw'}}
            return (
                <div className={'header_btn_second_father'}
                     onClick={() => clickSecondTitle(item)} key={index.toString()}>
                    <img src={item.isSel ? icon_tag_sel : icon_tag_dis} className={'header_btn_second_img'}/>
                    <div className={'header_btn_second_title'}
                         style={{
                             color: item.isSel ? '#092010' : '#8EF4B1',
                             fontSize: '12px',
                             // marginTop: '1px'
                         }}>{item.title}</div>
                </div>
            )
        })
    }

    const addProductClick = async (item) => { //添加商品
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => { //登录回来
                props.refreshData && props.refreshData();
            });
            return;
        }
        try {
            showLoading();
            let params = {
                clientId: userInfo.clientId,
                cllecId: item.id,
                collectionId: item.collectionId,
                type: '0'
            };
            const res = await addPurchase(params);
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            Toast.show('已加入购物车');
            //需要刷新购物车数量
            let timer = setTimeout(() => {
                clearTimeout(timer);
                changeShopCountFun();
            }, 1000);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }
    const clickPro = (item) => {
        if (!clickThrottle()) return;
        if (item.collectionId) {
            //回来之后
            JsBridge.addListener().then((result) => { //点击回来
                //需要刷新购物车数量
                changeShopCountFun();
                // props.refreshData && props.refreshData();
            });
            item.clientId = userInfo.clientId;
            window.localStorage.setItem("shop_cart_info_item", JSON.stringify(item));
            let timer = setTimeout(() => {
                clearTimeout(timer);
                JsBridge.push("CustomWeb", {uri: `${window.location.origin}/ShopProductDetail`})
            }, 500);
        }
    }

    const listItem = (item) => {
        return (
            <div className={'shop_stage_item_container1'}>
                <div className={'item_img_father'} onClick={() => clickPro(item)}>
                    <img src={item.imageBg} className={'item_img'}/>
                </div>
                <div className={'item_name'}>{item.collectionName}</div>
                <div className={'item_price_add_father'}>
                    <span className={'price_span'}>¥{item.price}</span>
                    {isIOS() ? <span/> :
                        <img src={icon_shop_tianjia} className={'img_add'} onClick={() => addProductClick(item)}/>}
                </div>
                {
                    !isEmptyString(item.collectionIdf) ?
                        <div className={'song_pkp_logo'}>{item.collectionIdf}</div> : null
                }
            </div>
        )
    }

    return (
        <div className={'shop_stage_content_container'}>
            <div className={'shop_stage_header_btn_father0'}>
                {header()}
            </div>
            <div className={'shop_stage_header_content_father'}>
                <div className={'shop_stage_header_btn_second_father0'}>
                    {secondHeader()}
                </div>
                <div className={'shop_stage_list'}>
                    <ListView1
                        onRefresh={() => loadData()}
                        list={data}
                        isNeedRefresh={true}
                        hasMore={hasMore}
                        isLoad={isLoad}
                        loadMore={loadMore}
                        ListItem={({item}) => listItem(item)}
                    />
                </div>
            </div>
            {isIOS() ? null : <ShopComponent value={{userInfo}} changeShopCount={changeShopCount}
                                             refreshData={() => props.refreshData && props.refreshData()}/>}
        </div>
    )
}

const ShopComponent = (props) => {
    const [showShop, setShowShop] = useState(false)
    const {userInfo,} = useContext(Context);
    const [shopCount, setShopCount] = useState(0)

    useEffect(() => {
        getShopCount();
    }, [userInfo, showShop, props.changeShopCount]);

    const onDidFocus0 = () => {
        JsBridge.addListener().then((result) => {
            // console.log('onDidFocus0')
            props.refreshData && props.refreshData();
        });
    }

    const shopCartClick = () => {
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => { //登录回来
                props.refreshData && props.refreshData();
            });
            return;
        }
        setShowShop(!showShop)
        // onDidFocus0();
    }
    const getShopCount = async () => {
        if (!userInfo.isLogin) {
            return;
        }
        try {
            // showLoading();
            let params = {
                clientId: userInfo.clientId,
            };
            const res = await getShoppingNum(params);
            // hideLoading();
            if (res.code !== '000000') throw res.msg;
            if (res.data >= 0) {
                setShopCount(res.data)
                // console.log('getShopCount')
            }
        } catch (error) {
            // hideLoading();
            Toast.show(error);
        }
    }

    return (
        <div className={'shop_logo_container'}>
            <img src={icon_shop_gouwuche} className={'shop_logo'} onClick={() => shopCartClick()}/>
            <div className={'shop_count'}>{shopCount}</div>
            <ShopRecordPop maskVisible={showShop} setMaskVisible={setShowShop} userInfo={userInfo}/>
        </div>
    )
}

const BottomLogoComponent = (props) => {
    return (
        <div className={'logo_father'}>
            <img src={egg_logo} className={'egg_logo'}/>
        </div>
    )
}





