import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './exchangeGuessBondDialog.scss';
import {Mask, Toast} from 'antd-mobile';
import {hideLoading, showLoading} from "@/components/loading";
import {recoveryEpic} from "@/api/projectManager_self";
import ExchangeGuessBondSuccDialog from "@/view/guessingActy/components/dialog/exchangeGuessBondSuccDialog";
import clickThrottle from "@/utils/clickThrottle";

const ExchangeGuessBondDialog = ({onClose, data = []}) => { //兑换入场劵
    const [visible, setVisible] = useState(true);
    const handleClick = (type) => {
        if (type === 0) {
            setVisible(false);
            onClose && onClose();
        } else if (type === 1) {
            exchangeJuan();
        }
    }

    const exchangeJuan = async () => {
        if (!clickThrottle()) {
            return
        }
        let nftNoListTem = [];
        for (const item of data) {
            nftNoListTem.push(item.nftNo)
        }
        try {
            showLoading()
            const res = await recoveryEpic({nftNoList: nftNoListTem})
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            setVisible(false);
            onClose && onClose();
            let timer = setTimeout(() => {
                clearTimeout(timer);
                ExchangeGuessBondSuccDialog.open(data.length);
            }, 1000)
        } catch (error) {
            Toast.show(error);
        }
    }

    const items = () => {
        return data.map((item, index) => {
            return (
                <div className={'exchange_item'} key={index.toString()}>
                    <img src={item.nftImage} alt=""/>
                    <p>{item.nftNo}</p>
                </div>
            )
        })
    }

    return <Mask className='exchange-guess-bond-mask' visible={visible} onMaskClick={() => handleClick(0)}>
        <div className={`exchange-modal-common ${data.length > 3 ? 'long_list' : ''}`}>
            <div className={'exchange-modal-container'}>
                <p className={'exchange-guess-title'}>兑换入场券</p>
                <div className={'exchange_juan_container'}>
                    <span>确定将以下</span><span>{data.length}</span>个藏品兑换为入场券吗?
                </div>
                <div className={'exchange_juan_container line2'}>
                    <span>兑换后以下产品将被回收，</span><span>且操作不可逆</span>。
                </div>
                <div className={`exchange_list ${data.length > 3 ? 'long0' : ''}`}>
                    {items()}
                </div>
                <div className={'exchange_guess_btn_container'}>
                    <p onClick={() => handleClick(0)}>取消</p>
                    <p onClick={() => handleClick(1)}>确定</p>
                </div>
            </div>
        </div>
        <img src={require('../../imgs/icon_vector.png')} alt="" className={'close_img'} onClick={()=>handleClick(0)}/>
    </Mask>
}

ExchangeGuessBondDialog.open = (data) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<ExchangeGuessBondDialog onClose={close} data={data}/>, div);
}

export default ExchangeGuessBondDialog;
