import React, {useEffect, useState, useRef} from 'react'
import styles from './styles.module.scss'
import {Mask, Toast} from 'antd-mobile'
import CommonView from '../commonView'
import ListView from "@/components/AntListView"
import dayjs from 'dayjs'
import {getRecoveryRecordv2} from "@/api/projectManager_self";
import {metaRecovery24_0116} from "@/constant";
import {hideLoading, showLoading} from "@/components/loading";
//回收记录
const ListItem = (item) => {
    return <div className={styles.list_item}>
        <div className={styles.time}>
            <span>回收时间</span>
            <span>{item.recoveryTime ? dayjs(item.recoveryTime).format('YYYY.MM.DD HH:mm:ss') : ''}</span>
        </div>
        <div className={styles.recyc_ele}>
            {
                item.recoveryList.map((eleItem, eleIndex) => {
                    return <div className={styles.recyc_ele_item} key={eleIndex}>
                        <span>{eleItem.name}</span>
                        <span>#{eleItem.nftNo}</span>
                    </div>
                })
            }
        </div>
        <div className={styles.ele_num}>获取元素x{item.recoveryList.length}</div>
    </div>
}

const RecoredList = ({visible}) => {
    const [listData, setListData] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const currentPage = useRef(1);
    const pageSize = useRef(10);

    useEffect(() => {
        visible && getRecoredList(true);
    }, [visible])

    const getRecoredList = async (isRefresh = true) => {
        try {
            showLoading();
            if (isRefresh) {
                currentPage.current = 1;
            } else {
                currentPage.current = currentPage.current + 1;
            }
            const params = {
                currentPage: currentPage.current,
                pageSize: pageSize.current,
                activityCode: metaRecovery24_0116,
            }
            const res = await getRecoveryRecordv2(params);
            hideLoading();
            if (res.code !== '000000') throw res.msg
            let {record = []} = res?.data;
            if (currentPage.current === 1) {
                setListData(record)
            } else {
                setListData([...listData, ...record]);
            }
            setIsLoad(true);
            setHasMore(record.length >= pageSize.current);
        } catch (error) {
            hideLoading();
            console.log(error)
            Toast.show(error)
        }
    }
    console.log('listData--', listData)
    return <div className={styles.list_container}>
        <ListView
            dataSource={listData}
            hasMore={hasMore}
            renderItem={ListItem}
            onEndReached={async () => {
                await getRecoredList(false);
            }}
            isLoad={isLoad}
            customStyle={{
                imgStyle: {width: 140, height: 140},
                textStyle: {color: '#FFFFFF', fontSize: 14},
            }}
        />
    </div>
}

const RecyclingRecords = ({onClose, visible}) => {
    console.log('aaa')
    return <Mask className={styles.recycling_mask} visible={visible}>
        <CommonView onClose={onClose} title="回收记录"><RecoredList visible={visible}/></CommonView>
    </Mask>
}

export default RecyclingRecords
