/**
 * @description 兑换记录
 * @param {}
 * @returns { /apeWorld/partsBlindbox/exchangeHistory }
*/
import React, {useEffect, useState, useRef} from 'react';
import { Toast } from 'antd-mobile';
import NFTHeader from '@/components/NFTHeader';
import { hideLoading, showLoading } from "@/components/loading";
import ListView from '@/components/AntListView';
import Service from '../service';
import Style from './index.module.scss';

const ExchangeHistory = () => {
    const [dataSource, setDataSource] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);

    useEffect(()=>{
        loadData(true)
    },[])

    const loadData = async (isRefesh=false) => {
        const param = {
            pageSize: pageSize.current,
            currentPage: currentPage.current
        }
        try {
            isRefesh && showLoading();
            const res = await Service.getRecoveryRecord(param);
            isRefesh && hideLoading();
            if (res.code !== '000000') throw res.msg;
            const newList = res.data.record || [];
            if(currentPage.current === 1) {
                setDataSource(newList);
            } else {
                setDataSource([...dataSource, ...newList]);
            }
            setHasMore(newList.length >= pageSize.current);
        } catch (error) {
            isRefesh && hideLoading();
            Toast.show(error);
        }
    }

    const loadMore = async () => {
        currentPage.current += 1;
        await loadData()
    }

    const renderItem = (item) => {
        return (
            <div className={Style.record_item}>
                <div className={Style.top}>
                    <img src={require('@/assets/img/partsBlindbox/ape_box.png')} alt="" />
                    <div>
                        <span>{item.prizeName ?? 'Ape world盲盒'}</span>
                        <p>{`${item.postCount ?? '-'}个`}</p>
                    </div>
                </div>
                <div className={Style.bottom}>
                    <div>
                        <p>升级使用材料</p>
                         <span>{`${item.preCount}个${item.remark == 1 ? '稀有' : '普通'}配件`}</span>
                    </div>
                    <div>
                        <p>升级时间</p>
                        <span>{item.recoveryTime}</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={Style.ExchangeHistory}>
            <NFTHeader midText={`兑换记录`} />
            <div className={Style.historyList}>
                <ListView 
                    dataSource={dataSource}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    isLoad={true}
                    style={{paddingTop: 50}}
                    onRefresh={()=>{
                        currentPage.current = 1;
                        loadData()
                    }}
                    onEndReached={loadMore}
                />
            </div>
        </div>
    )
}

export default ExchangeHistory;