
import { getAbleRecCount, getRecConfig } from "@/api/projectManager_self";

class Service {
    static activityCode = 'gulandRec';

    /**
     * @description 矿工猴藏品数量及回收次数
    */
    static getActivityData = async () => {
        const res = await getAbleRecCount({activityCode: Service.activityCode})
        if (res.code !== '000000') throw res.msg;
        return res.data;
    }
     
    
    /**
     * @description 获取活动配置
    */
    static getActivityConfig = async () => {
        const res = await getRecConfig({activityCode: Service.activityCode})
        if (res.code !== '000000') throw res.msg;
        return res.data;
    }

    /**
     * @description 获取用户信息
    */
    static getUserCertificateStatus = async () => {
        if (window.JsBridge.hasWebViewBridge()) {
          const res = await window.JsBridge.getDetailCurrentUserInfo()
          return res;
        } else {
          return {};
        }
    }

}

export default Service;