import React, { useEffect, useState, useMemo } from "react";
import styles from "./styles.module.scss";
import { Toast } from "antd-mobile";
import { hideLoading, showLoading } from "@/components/loading";
import { guess } from "@/api/activity";
import moment from "moment";

const Guess = ({ onClose, type, gemstore, nextTime,eachAdd,minGems }) => {
  const [count, setCount] = useState(eachAdd || 10);
  const [num, setNum] = useState(1);

  useEffect(() => {}, []);

  const countNum = useMemo(() => {
    return count * num;
  }, [num, count]);

  const numHandle = (type) => {
    if (type === "add") {
      setNum(num + 1);
    } else {
      if (num - 1 === 0) {
        Toast.show(`宝石数不能小于${minGems}`);
      } else {
        setNum(num - 1);
      }
    }
  };
  const guessHandle = async () => {
    try {
      showLoading();
      let nextString = moment(nextTime).format("YYYYMMDDHHmm");
      const res = await guess({
        sessions: nextString,
        guessResult: Number(type),
        gemsNum: countNum,
        activityCode: "btcGuess",
      });
      hideLoading();
      if (res.code !== "000000") throw res.msg;
      onClose(true);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  };
  const confirmHandle = () => {
    if (countNum > gemstore) {
      Toast.show("宝石数量不足");
    }
    guessHandle();
  };
  return (
    <div className={styles.detail_out_mask}>
      <div className={styles.detail_out}>
        <div className={styles.detail_out_bg}></div>
        <div className={styles.title_box}>
          <div className={styles.title}>投宝石参与竞猜</div>
        </div>

        <div className={styles.box}>
          <div className={styles.top}>
            {
              {
                0: "看涨",
                1: "看平",
                2: "看跌",
              }[type || "0"]
            }
          </div>
          <div className={styles.bottom}>
            <div className={styles.bottom_text}>投</div>
            <div
              className={styles.bottom_sub}
              onClick={() => {
                numHandle("sub");
              }}
            ></div>
            <div className={styles.bottom_num}>{countNum}</div>
            <div
              className={styles.bottom_add}
              onClick={() => {
                numHandle("add");
              }}
            ></div>
            <div className={styles.bottom_text}>宝石</div>
          </div>
          <div className={styles.opt}>
            <div className={styles.cancle} onClick={() => onClose()}></div>
            <div
              className={styles.confirm}
              onClick={() => confirmHandle()}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guess;
