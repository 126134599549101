/**
 * @description 升级记录页面
 * @returns {route: /workapeBlindbox/upgradeRecord}
 */
import React, { useState, useEffect, useRef } from "react";
import styles from './styles.module.scss';
import NFTHeader from "@/components/NFTHeader";
import { showLoading, hideLoading } from "@/components/loading";
import { Toast } from "antd-mobile";
import dayjs from "dayjs";
import { getRecoveryRecord } from '@/api/projectManager_self';
import ListView from '@/components/AntListView';

const UpgradeRecord = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    getData();
  }, [])

  const getData = async (isRefresh = true, isLoading = true) => {
    try {
      isLoading && showLoading();
      if(isRefresh) {
        currentPage.current = 1;
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
        activityCode: 'GuLandBlindBox',
      }
      const res = await getRecoveryRecord(params)
      isLoading && hideLoading();
      if (res.code !== '000000') throw res.msg;
      const newList = res?.data?.record || [];
      if(currentPage.current === 1) {
        setData(newList);
      } else {
        setData([...data, ...newList]);
      }
      setLoading(true);
      setHasMore(newList.length >= pageSize.current);
    } catch (error) {
      isLoading && hideLoading();
      Toast.show(error);
    }
  }

  const renderItem = (item) => {
    return <div className={styles.upgrade_record_content}>
      <div className={styles.s_info}>
        <img src={item?.upgradeImg} alt="" />
        <div>
          <div>{item?.afterName}</div>
          <div>s级</div>
        </div>
      </div>
      <div className={styles.upgrade_info}>
        <div className={styles.upgrade_info_item}>
          <span>升级使用材料</span>
          <span>10张a级矿工猴数字藏品</span>
        </div>
        <div className={styles.upgrade_info_item}>
          <span>升级时间</span>
          <span>{item?.recoveryTime ? dayjs(item.recoveryTime).format('YYYY.MM.DD HH:mm:ss'): ''}</span>
        </div>
      </div>
    </div>
  }

  return <div className={styles.upgrade_record}>
    <NFTHeader midText="升级记录"/>
    <div className={styles.record_list}>
      <ListView 
        dataSource={data}
        renderItem={renderItem}
        hasMore={hasMore}
        isLoad={loading}
        onEndReached={() => getData(false, false)}
        onRefresh={() => getData(false, false)}
        style={{paddingTop: 160}}
      />
    </div>
  </div>
}

export default UpgradeRecord;