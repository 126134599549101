import React, { useState, Fragment } from "react";
import ReactDOM from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import count_bg from "@/assets/img/industrialApe/choice_logo.png";
import classNames from "classnames";

import "./style.scss";
import { Mask } from "antd-mobile";

const SuccessModal = ({ onClose, confirmCB,func, type,data }) => {
  const [visible, setVisible] = useState(true);
  const collectionList = data;
  const [hash, setHash] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  const cancel = () => {
    onClose && onClose();
    setVisible(false);
    confirmCB && confirmCB();
  };

  const confirm = () => {
    onClose && onClose();
    setVisible(false);
    func && func();
  };

  const slideChange = (e) => {
    setHash(collectionList[e.activeIndex]?.hash);
    setActiveIndex(e.activeIndex);
  };

  return (
    <Mask
      className="synthesis-success-modal"
      visible={visible}
      onMaskClick={() => cancel()}
    >
      <div className={`mask-content`}>
        <div>
          <div className="title">{"合成藏品"}</div>
          <div className={classNames("synthesisList",{'fade-in': 0 ? true : false})}>
            {collectionList && collectionList.length > 0 && (
              <>
                <Swiper
                  effect={"cards"}
                  grabCursor={true}
                  modules={[EffectCards]}
                  className="synthesisList_Swiper"
                  onSlideChange={slideChange}
                >
                  {collectionList.map((item, index) => (
                    <SwiperSlide key={index}>
                      <img src={item.url || count_bg} alt="" />
                      <span
                        className={
                          index === activeIndex ? "show_text" : "hidden_text"
                        }
                      >
                        <span className="blod_text">{item?.title || ""}</span>
                        <br />
                        {/* <span>藏品编号：{item?.nftNo || index}</span> */}
                      </span>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            )}
          </div>
          <div className="btn-group">
            <div className="cancel" onClick={cancel}>
              取消
            </div>
            <div className="confirm" onClick={confirm}>
              查看藏品
            </div>
          </div>
          <img
            src={require("@/assets/img/collectionRecoveryLand/close_x.png")}
            alt=""
            onClick={cancel}
          />
        </div>
      </div>
    </Mask>
  );
};

SuccessModal.open = ({ confirmCB,func ,type, data }) => {
  let div = null;
  if (!div) {
    div = document.createElement("div");
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  };
  ReactDOM.render(
    <SuccessModal onClose={close} confirmCB={confirmCB} func={func}  type={type} data={data}/>,
    div
  );
};

export default SuccessModal;
