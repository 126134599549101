import CryptoJS from "crypto-js";

function encryption(params) {
  const key = '784115d298594839a2cd818180c36c13';
  // // Encrypt
  // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify({}), '784115d298594839a2cd818180c36c13', {
  //   // iv: '784115d298594839a2cd818180c36c13',
  //   mode: CryptoJS.mode.ECB,
  //   padding: CryptoJS.pad.Pkcs7
  // }).toString();
  // console.log(ciphertext, '+++')

  // // Decrypt
  // var bytes = CryptoJS.AES.decrypt(ciphertext, '784115d298594839a2cd818180c36c13', {
  //   mode: CryptoJS.mode.ECB,
  //   padding: CryptoJS.pad.Pkcs7
  // });
  // var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // console.log(decryptedData, '---')


  let result = CryptoJS.AES.encrypt(params + '', key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
  return result
}

export default encryption