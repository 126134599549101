/**
 * @description 购买记录
 * @returns {/boxToStone/buyHistory}
*/

import React, {useEffect, useState, useRef} from 'react';
import { Toast } from 'antd-mobile';
import NFTHeader from '@/components/NFTHeader';
import { hideLoading, showLoading } from "@/components/loading";
import ListView from '@/components/AntListView';
import Service from '../service';
import Style from './index.module.scss';

const BuyHistory = () => {
    const [dataSource, setDataSource] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);

    useEffect(()=>{
        loadData(true)
    },[])

    const loadData = async (isRefesh=false) => {
        const param = {
            pageSize: pageSize.current,
            currentPage: currentPage.current
        }
        try {
            isRefesh && showLoading();
            const res = await Service.getBuyRecord(param);
            isRefesh && hideLoading();
            if (!res) throw res.msg;
            const newList = res.data || [];
            if(currentPage.current === 1) {
                setDataSource(newList);
            } else {
                setDataSource([...dataSource, ...newList]);
            }
            setHasMore(newList.length >= pageSize.current);
        } catch (error) {
            isRefesh && hideLoading();
            error && Toast.show(error);
        }
    }

    const loadMore = async () => {
        currentPage.current += 1;
        await loadData()
    }

    const renderItem = (item={}) => {
        return (
            <div className={Style.record_item}>
                <div className={Style.title_info}>
                    <img className={Style.img} src={ item.imgUrl ?? require('@/assets/img/boxToStone/boximg.png')} alt='img'/>
                    <div>
                        <p>{item.orderName ?? '--'}</p>
                        <span>{`x${item.totalCount ?? 0}`}</span>
                    </div>
                </div>
                <div className={Style.bottom}>
                    <div>
                        <p>获得商品</p>
                        <div>
                            {
                                item.purchaseDetailsVO ?
                                item.purchaseDetailsVO.map((card,k)=> {
                                    return (
                                        <div key={k}>
                                            <span >{`${card.productName ?? '--'} x${card.goodsQuantity ?? 0}`}</span>
                                            <span>{`转化${card.conversionNumber ?? 0}`}</span>
                                        </div>
                                    )
                                })
                                : '---'
                            }
                        </div>
                    </div>
                    <div>
                        <p>购买时间</p>
                        <div>{item.orderTime ?? '---'}</div>
                    </div>
                    <div>
                        <p>转化宝石</p>
                        <div>{item.totalGems ?? '---'}</div>
                    </div>
                    <div>
                        <p>订单编号</p>
                        <div>{item.orderNo ?? '---'}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={Style.buyHistory}>
            <NFTHeader midText={`购买记录`} bgColor={'#FFFFFF'}/>
            <div className={Style.historyList}>
                <ListView 
                    dataSource={dataSource}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    isLoad={true}
                    style={{paddingTop: 50}}
                    onRefresh={()=>{
                        currentPage.current = 1;
                        loadData()
                    }}
                    onEndReached={loadMore}
                />
            </div>
        </div>
    )
}

export default BuyHistory;
