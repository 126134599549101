import React, {useState, useEffect, useRef} from 'react';
import styles from './styles.module.scss';
import { Toast } from 'antd-mobile';
import ListView from '@/components/AntListView';
import {hideLoading, showLoading} from "@/components/loading";
import noData from '../../imgs/pic_pinglun.png';
import ScratchView from '../scratchView';
import mockApi from '../../home/mock';
import { getPrizeRecord, recoveryActivityInit } from '@/api/projectManager_self';
import { activityCode } from '../../home';

//刮奖拿豪礼
const ScratchCardGift = () => {
  const [hasMore, setHasMore] = useState(false);
  const [prizeData, setPrizeData] = useState([]);
  const [cardInfo, setCardInfo] = useState({})
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    refresh()
  }, [])

  const getPrizeList = async (isRefresh=true, isLoading=true) => {
    try {
      isLoading && showLoading();
      currentPage.current = isRefresh ? 1: currentPage.current + 1;
      const res = await getPrizeRecord({activityCode}, {currentPage: currentPage.current, pageSize: pageSize.current,});
      isLoading && hideLoading();
      if (res.code !== '000000') throw res.msg;
      const list = res.data.recordList || [];
      if(currentPage.current > 1) {
        setPrizeData([...prizeData, ...list]);
      } else {
        setPrizeData(list);
      }
      setIsLoad(true);
      setHasMore(list.length >= pageSize.current);
    } catch (error) {
      isLoading && hideLoading();
    }
  }

  const getActivityInfo = async () => {
    const res = await recoveryActivityInit({activityCode});
    // const res = await mockApi.getPrizeInfo();
    if (res.code !== '000000') throw res.msg;
    setCardInfo(res.data || {});
  }

  const refresh = async () => {
    try {
      showLoading()
      await getActivityInfo(true, false);
      await getPrizeList();
      hideLoading()
    } catch (error) {
      hideLoading()
      setIsLoad(true)
      Toast.show(error);
    }
  }

  return (
    <div className={styles.scratch_card_gift_container}>
      <div className={styles.prize_list_box}>
        <ListView 
          dataSource={prizeData}
          renderItem={PrizeItem}
          hasMore={hasMore}
          // onRefresh={() => getPrizeList(true, false)}
          onEndReached={() => getPrizeList(false, false)}
          isLoad={isLoad}
          customStyle={{
            imgStyle: {width: 110, height: 110},
            textStyle: {color: '#FFFFFF', fontSize: 12},
            img: noData,
          }}
        />
      </div>
      <div className={styles.scratch_card_box}>
        <ScratchView refresh={refresh} cardInfo={cardInfo}/>
      </div>
    </div>
  )
}

const PrizeItem = (item) => {
  return <div className={styles.card_prize_item}>
    <span>{item.updatedTime?.slice(5)}</span>
    <span>{item.prizeType === 5 ? '史诗款无聊猿' : `${item.num}积分`}</span>
  </div>
}

export default ScratchCardGift;
