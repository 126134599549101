/**
 * @description 打工派遣
*/
import React, { useContext, useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { LandMap } from "../../components/land";
import { GreenBtn } from "../../components/button";
import { ExpIcon } from "../../components/common";
import classNames from "classnames";
import { LandDetailContext } from "../context";
import { workExpData, recallWorkApe, getSystemTime } from '@/api/guland';
import { hideLoading, showLoading } from "@/components/loading";
import { Toast } from 'antd-mobile';
import RecallApe from "./recallApe";
import SelectApe from "./selectApe";
import { residueHours, residue12Hours } from '../../utils';
import { MonkeyMap } from '../../components/landview';
import { useConfirmOrder } from '../../acceleratorCard/manageOrder';
import './workDispatch.scss';

const LevelMap = {
  'a': {level: 1, maxHireNum: 5},
  'b': {level: 2, maxHireNum: 3},
  'c': {level: 3, maxHireNum: 1},
}

const WorkDispatch = forwardRef((props, ref) => {
  const [selectedKey, setSelectedKey] = useState(0);
  const [expData, setExpData] = useState({})
  const [apeList, setApeList] = useState([])
  const [workApeItem, setWorkApeItem] = useState({separateNo: 0})
  const [selectVisible, setSelectVisible] = useState(false)

  const { landInfo, closeLandDetail, mode } = useContext(LandDetailContext);
  const [onOrderOpen, ManageFeesOrder] = useConfirmOrder({
    mode:mode,
    info: {from: 'landManageFees'},
    payCallback: () => closeLandDetail(),
  })

  useImperativeHandle(ref, () => {
    return {
      refreshDailyExp: getExpData,
    }
  })

  useEffect(() => {
    getExpData();
  }, [])

  const getExpData = async (isLoading = true) => {
    try {
      isLoading && showLoading();
      const res = await workExpData({
        blockLevel: LevelMap[landInfo.currentLevel]?.level,
        landId: landInfo.id,
        collectionId: landInfo.collectionId,
        spaceType: +mode === 2 ? 1 : 0,//空间类型 0农业  1工业
        landType: +mode === 2 ? 1 : 0,//空间类型 0农业  1工业
      });
      isLoading && hideLoading();
      if (res.code !== '000000') throw res.msg;
      if (landInfo.setFlag === 1) {
        const workList = Array(LevelMap[landInfo.currentLevel]?.maxHireNum).fill({}).map((item, index) => {
          if (index === workApeItem?.separateNo) {
            return {separateNo: index, isChoose: true}
          }
          return {separateNo: index}
        })
        if (res?.data?.landBlocksDTO?.lazyMonkeyLayDOList?.length > 0) {
          res?.data?.landBlocksDTO?.lazyMonkeyLayDOList?.forEach((item, index) => {
            if (item.separateNo === workApeItem?.separateNo) {
              item = {...item, isChoose: true}
            }
            const i = workList.findIndex((witem) => witem.separateNo === item.separateNo)
            workList.splice(i, 1, item)
          })
        }
        const setIndex = workList.findIndex((witem) => witem.separateNo === workApeItem?.separateNo)
        setApeList(workList)
        setWorkApeItem(workList?.[setIndex])
      }
      setExpData(res.data || {})
    } catch (error) {
      isLoading && hideLoading()
      Toast.show(typeof error === "string" ? error : error.msg)
    }
  }

  const selectPosition = (item) => {
    console.log(item)
    const newList = apeList.map((i) => {
      if (i.separateNo === item.separateNo) {
        return {...i, isChoose: true}
      } else {
        return {...i, isChoose: false}
      }
    })
    setWorkApeItem(item)
    setApeList(newList)
  }

  const btnTextFn = () => {
    let text = ''
    if (landInfo.setFlag === 0) {
      text = '请先放置土地'
    } else {
      if (expData.isNeedManagementFees === 1) {
        text = <div><span style={{fontFamily: 'PingFang SC'}}>¥</span>{`${expData.landManagementFees}土地管理费`}</div>
      } else {
        if (MonkeyMap?.[workApeItem?.monkeyType]) {
          text = '召回无聊猿'
        } else {
          text = '选择无聊猿派遣'
        }
      }
    }
    return text
  }

  const recallApe = async () => {
    try {
      showLoading()
      const res = await recallWorkApe({
        collectionId: workApeItem.collectionId, 
        nftNo: workApeItem.nftNo
      })
      hideLoading()
      if (res.code !== '000000') throw res.msg
      refreshData()
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const onGreenBtn = async () => {
    if (landInfo.setFlag === 0) {
      closeLandDetail()
    } else {
      if (expData.isNeedManagementFees === 1) {
        onOrderOpen()
        return
      }
      const res = await getSystemTime()
      const currentTime = res?.data || expData.currentTime
      const {isFirstTime, setTime} = workApeItem
      if (MonkeyMap[workApeItem?.monkeyType]) {
        if (isFirstTime === 1) {
          if (residue12Hours(setTime) >= 0) {
            const current = Date.parse(currentTime?.replace(/-/g, '/'))
            const set = Date.parse(setTime?.replace(/-/g, '/'))
            const residueTime = 12*60*60*1000 - (current - set)
            if (residueTime > 0) {
              RecallApe.open({confirmCB: () => recallApe()})
            } else {
              recallApe()
            }
          } else {
            recallApe()
          }
        } else {
          const hours = residueHours(currentTime)
          if (hours < 12) {
            recallApe()
            return
          }
          RecallApe.open({confirmCB: () => recallApe()})
        }
      } else {
        console.log('select')
        setSelectVisible(true)
      }
    }
  }

  const refreshData = () => {
    getExpData()
    props.pageInit(undefined, false)
  }

  return (
    <div className={classNames("land-game-work", {mode2: mode == 2})}>
      <div>
        <span>土地编号：#{landInfo.landId}</span>
        {React.cloneElement(LandMap[landInfo.currentLevel], {
          img: landInfo.nftImage,
          hideApe: false,
          workApeList: apeList,
          onSelect: selectPosition,
          workStyle: false,
          currentTime: expData.currentTime,
        })}
      </div>
      <div className="land-game-divide"></div>
      <div>
        <div>
          <h1>
            尊贵的{landInfo.currentLevel?.toUpperCase()}级地主
            <br />
            最多可雇佣{LevelMap[landInfo.currentLevel]?.maxHireNum}个无聊猿
          </h1>
          <div className={classNames("land-game-tabx", {mode2: mode == 2})}>
            {expData?.lazyMonkeyRespList?.length > 0 && expData?.lazyMonkeyRespList?.map((exp, i) => (
              <span
                className={classNames("land-btn", {
                  active: i === selectedKey,
                  mode2: +mode === 2,
                })}
                onClick={() => {
                  setSelectedKey(i);
                }}
                key={i}
              >
                {exp.monkeyTypeName}
              </span>
            ))}
          </div>
          <div className="land-flex-vmiddle">
            <ExpIcon></ExpIcon>
            <h6 className={classNames('text-h6',{mode2: +mode === 2})}>最多{expData?.lazyMonkeyRespList?.[selectedKey]?.exp}exp/day</h6>
          </div>
        </div>

        <div className="land-flex-hmiddle">
          {expData.dailyExp ? <div className="exp-num">预计每天可得经验：{expData.dailyExp}</div>: null}
          <GreenBtn large onClick={onGreenBtn}>
            {btnTextFn()}
          </GreenBtn>
        </div>
      </div>
      <SelectApe 
        mode={mode}
        container={props.container}
        visible={selectVisible} 
        expData={expData?.lazyMonkeyRespList || []}
        onClose={() => setSelectVisible(false)}
        selectCB={refreshData}
        separateNo={workApeItem?.separateNo}
      />
      {ManageFeesOrder}
    </div>
  );
});

export default WorkDispatch;
