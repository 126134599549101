import React, {useEffect, useRef, useState,} from 'react';
import './eggHatchProgress.scss'
import NFTHeader from "@/components/NFTHeader";
import {ProgressBar, Toast} from "antd-mobile";
import {isArray, isEmptyString} from "@/utils/publicFun";
import {getComponentList, getComposeResult, hcImageV1} from "@/api/projectManager_self";
import {hideLoading, showLoading} from "@/components/loading";
import {useNavigate} from "react-router-dom";
import qs from 'query-string';

let hc_animation_01 = require("@/assets/img/egg/hc_animation_01.gif")
const JsBridge = window.JsBridge;

const timerSlot = 10000;
export const EggHatchProgress = () => {
    const navigate = useNavigate()
    const [pregress, setPregress] = useState(10);
    const [hcImgs, setHcImgs] = useState([hc_animation_01]);
    let timerInterval = useRef(null);
    let reqRes = useRef(null);
    let loadCount = useRef(0);

    useEffect(() => {
        JsBridge.setTitle('孵化进度');
        loadData();
        addPercent();
        return () => {
            clearTimerInterval();
        }
    }, [])

    const addPercent = () => {
        clearTimerInterval();
        timerInterval.current = setInterval(() => {
            changeValue();
        }, 5000);
    }

    const changeValue = () => {
        setPregress(pre => {
            if (pre >= 80) {
                clearTimerInterval();
            }
            return pre + 10;
        })
    }

    const clearTimerInterval = () => {
        timerInterval.current && clearInterval(timerInterval.current); //先清空之前的定时器
        timerInterval.current = null;
    }

    const loadData = () => {
        let objString = window.localStorage.getItem("egg_hc_info");
        if (!isEmptyString(objString)) {
            window.localStorage.setItem('egg_hc_info', '');
            let params = JSON.parse(objString)
            if (params) {
                hcAction(params).then();
            }
        }
    }

    const hcAction = async (params) => {
        try {
            const {code, msg, data} = await hcImageV1(params);
            if (code === '000000') { //成功
                setPregress(pre => {
                    return 99;
                })
                clearTimerInterval();
                reqRes.current = data.req;
                let timer = setTimeout(() => {
                    clearTimeout(timer)
                    goSuccess();
                }, timerSlot);
            } else {
                clearTimerInterval();
                Toast.show(msg);
                let timer = setTimeout(() => {
                    clearTimeout(timer)
                    window.JsBridge.back();
                }, 3000);
            }
        } catch (error) {
            clearTimerInterval();
            Toast.show(error);
            let timer = setTimeout(() => {
                clearTimeout(timer)
                // Toast.show(error);
                window.JsBridge.back();
            }, 3000);
        }
    }

    const goSuccess = () => {
        // console.log('请求第几次', loadCount.current);
        showLoading();
        let params = {req: reqRes.current};
        getComposeResult(params).then((res) => {
            hideLoading();
            const {code, msg, data} = res;
            if (code === '000000') {
                if (data) {
                    clearTimerInterval();
                    navigate(`/eggHatchSuccess?${qs.stringify(data)}`, {replace: true})
                } else {
                    if (loadCount.current > 4) {
                        Toast.show('当前合成时间较慢,请稍后在藏品详情查看');
                        return;
                    }
                    let timer = setTimeout(() => {
                        clearTimeout(timer);
                        loadCount.current += 1;
                        goSuccess();
                    }, timerSlot);
                }
            } else {
                setTimeout(() => {
                    Toast.show(msg);
                }, 500)
            }
        });
    }

    const getImgs = () => {
        return hcImgs.map((item, index) => {
            return <img src={item || ''} key={index.toString()} className={'hc_pro_img'}/>
        })
    }

    const click = () => {
        let params = {
            collectionGroup: 8,
            seriesName: '猴子系列的',
            nftImage: '',
            nftHash: '',
            nftNo: 'Ape 111111',
        }
        // navigate(`/eggHatchSuccess?${qs.stringify(params)}`, {replace: true})
    }

    return (
        <div className={'hc_pro_container'}>
            {/*<NFTHeader midText="孵化进度" bgColor="white"/>*/}
            {getImgs()}
            <ProgressBar
                percent={pregress > 100 ? 100 : pregress}
                className={'progress_bar'}
                style={{
                    '--track-color': '#EEEEEE',
                    '--fill-color': 'linear-gradient(90deg, #CEBF7D 0%, #F8EBB8 100%)',
                    '--track-width': '13px'
                }}
            />
            <div className={'hc_pro_txt'}>{pregress}%</div>
            <div className={`hc_pro_txt ${'hc_pro_txt_title'}`}>孵化中</div>
            <div className={`hc_pro_txt_des`}>{'孵化时间约2分钟，请稍候\n' +
                '孵化成功可在【我的藏品】查看'}</div>
        </div>
    )
}








