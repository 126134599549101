import React, {useEffect, useState, useRef} from 'react'
import styles from './styles.module.scss'
import {Mask, Toast} from 'antd-mobile'
import ReactDOM from "react-dom";
import CommonView from "@/view/eleCompound/components/commonView";
import {notifyYYZToCloseWebAndJumpAuthPage} from "@/utils/publicFun";

//实名认证
const ShiMingModal = ({onClose, type}) => {


    const sure = async () => {
        notifyYYZToCloseWebAndJumpAuthPage({});
    }


    return <Mask className={styles.recycling_mask} destroyOnClose={true} opacity={'default'}>
        <div className={styles.container}>
            <p className={styles.title}>{type === 1 ? '回收元素需实名认证' : '购买元素需实名认证'}</p>
            <div className={styles.btns}>
                <p onClick={onClose}>取消</p>
                <p onClick={sure}>去实名</p>
            </div>
        </div>
    </Mask>
}

ShiMingModal.open = (type) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => div.remove();
    ReactDOM.render(<ShiMingModal
        onClose={close}
        visible
        type={type}
    />, div);
}

export default ShiMingModal
