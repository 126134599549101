import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './getGuessBondDialog.scss';
import {Mask} from 'antd-mobile';

const GetGuessBondDialog = ({onClose, callback}) => { //获取入场劵
    const [visible, setVisible] = useState(true);

    const handleClick = (type) => {//0=去兑换 1=去购买
        setVisible(false);
        onClose && onClose();
        console.log('GetGuessBondDialog-type--',type)
        setTimeout(() => {
            if (type === 0) {
                callback && callback(0);
            } else if (type === 1) {
                callback && callback(1);
            }
        }, 1000)
    }

    return <Mask className='get-guess-bond-mask' visible={visible} onMaskClick={() => handleClick()}>
        <div className='get-modal-common'>
            <div className={'get-modal-container'}>
                <p className={'get-guess-title'}>获取入场券</p>
                <div className={'get_juan_container'}>选择以下任意一种方式获得次数</div>
                <img src={require('../../imgs/pop_hqrcq.png')} alt="" className={'get_juan_img'}/>
                <div className={'get_guess_btn_container'}>
                    <p onClick={() => handleClick(0)}>去兑换</p>
                    <p onClick={() => handleClick(1)}>去购买</p>
                </div>
            </div>
        </div>
    </Mask>
}

GetGuessBondDialog.open = (callback) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<GetGuessBondDialog onClose={close} callback={callback}/>, div);
}

export default GetGuessBondDialog;
