/**
 * @description GuLand矿工猴盲盒购买首页
 * @returns {route: /workapeBlindbox/home}
 */
import React, { useRef, useEffect, useState } from "react";
import styles from './styles.module.scss';
import NFTModal from "@/components/NFTModal";
import top from '@/assets/img/workapeBlindboxImg/banner-top.png';
import bottom from '@/assets/img/workapeBlindboxImg/banner-bottom.png';
import back from '@/assets/img/icon/back.png';
import { showLoading, hideLoading } from "@/components/loading";
import { Toast } from 'antd-mobile';
import { queryDefCode } from "@/api/projectManager_self";
import { useNavigate } from "react-router-dom";
import { getActivityNftCount, getActivityNftPool} from '@/api/activity';

const activityCode = 'GuLandBlindBox';

const getSApeCount = async () => {
  const res = await getActivityNftCount({activityCode})
  if (res.code !== '000000') throw res.msg;
  return res.data;
}

const getNftNoList = async () => {
  const res = await getActivityNftPool({activityCode})
  if (res.code !== '000000') throw res.msg;
  return res.data;
}

const BlindboxHome = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [activityRules, setActivityRules] = useState(null);
  const [isHasInfo, setIsHasInfo] = useState(false);
  const pageDom = useRef();

  useEffect(() => {
    (async () => {
      await getData()
      onDidFocus();
    })();
  }, []) 

  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      getData()
      onDidFocus();
    });
  }

  const getUserCertificateStatus = async () => {
    if (window.JsBridge.hasWebViewBridge()) {
      const res = await window.JsBridge.getDetailCurrentUserInfo()
      setIsHasInfo(true);
      return res;
    } else {
      return {};
    }
  }

  const getData = async () => {
    try {
      showLoading();
      const [userInfo, apeCount] = await Promise.all([
        getUserCertificateStatus(),
        getSApeCount(),
      ])
      hideLoading();
      setData({...userInfo, ...apeCount});
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const purchaseBlindbox = () => {
    if (!data.ID) {
      Toast.show('请先实名');
      return;
    }

    const url = `/variationApe/saleDetail?collectionId=72&code=${activityCode}`
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${url}` });
    } else {
      navigate(url);
    }
  }

  const goToUpgrade = () => {
    if (!data.ID) {
      Toast.show('请先实名');
      return;
    }
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/workapeBlindbox/upgrade` });
    } else {
      navigate(`/workapeBlindbox/upgrade`);
    }
  }

  const queryNFTNo = async () => {
    try {
      showLoading();
      const res = await getNftNoList();
      hideLoading();
      const ModalInstance = NFTModal.show({
        content: <NFTNOList onClose={() => ModalInstance?.close()} nftNoList={res?.list}/>,
        getContainer: pageDom.current,
      });
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const onRules = (activityRules) => {
    const ModalInstance = NFTModal.show({
      content: <Rules onClose={() => ModalInstance?.close()} activityRules={activityRules}/>,
      getContainer: pageDom.current,
    });
  }

  const onBack = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.back();
    } else {
      window.history.go(-1);
    }
  }

  const getActivityRules = async () => {
    if (activityRules) {
      onRules(activityRules);
      return;
    }
    try {
      showLoading();
      const res = await queryDefCode({codeType: 'activityRule', code: activityCode});
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      const {data} = res;
      if (data.result && data.result.defCode) {
        const defCode = data.result.defCode;
        setActivityRules(defCode);
        onRules(defCode);
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  };

  return <div className={styles.workape_blindbox_home} ref={pageDom}>
    <div className={styles.back_box} onClick={onBack}><img src={back} alt="" /></div>
    <div className={styles.rules} onClick={getActivityRules}>规则</div>
    {!data.ID && isHasInfo && <div className={styles.real_name} onClick={() => window.JsBridge.navigate('Verify')}>实名</div>}
    <div className={styles.blindbox}>
      <div onClick={purchaseBlindbox}>购买盲盒</div>
    </div>
    <div className={styles.blindbox_no}>
      <div className={styles.blindbox_no_left}>
        <div>指定编号送A级藏品！</div>
        <div>抽到盲盒编号为XXX</div>
        <div>可获得A级矿工猴！</div>
        <div onClick={queryNFTNo}>查看藏品编号</div>
      </div>
      <img src={top} alt="" />
    </div>
    <div className={styles.upgrade_s}>
      <div className={styles.upgrade_s_left}>
        <div>满10个A级</div>
        <div>可合成1个S级星座典藏猴！</div>
        <div>限量{data.maxCount}个！剩余{data.remainCount}个</div>
        <div className={`${styles.upgrade_s_btn} ${data.remainCount <= 0 ? styles.disabled : ''}`} onClick={goToUpgrade}>
          {data.remainCount <= 0 ? '已兑完' : '去升级'}
        </div>
      </div>
      <img src={bottom} alt="" />
    </div>
  </div>
}

const NFTNOList = ({nftNoList = [], onClose}) => {
  return <div className={styles.nft_no_list}>
    <div className={styles.list_container}>
      {nftNoList.length > 0 && nftNoList.map((item, index) => {
        return <div className={styles.nft_no_item} key={index}>{item}</div>
      })}
    </div>
    <div className={styles.close} onClick={onClose}></div>
  </div>
}

const Rules = ({onClose, activityRules}) => {
  return <div className={styles.rules_container}>
    <div className={styles.title}>活动规则</div>
    <div className={styles.content}>{activityRules?.otherSign}</div>
    <div className={styles.close}><div onClick={onClose}>我知道了</div></div>
  </div>
}

export default BlindboxHome;