import Home from './home';
import GoodsDetail from './goodsDetail';
import ConfirmOrder from './confirmOrder';
import BuyHistory from './buyHistory';
import BatchTransform from './batchTransform';
import PaySuccess from './paySuccess';

export default {
  Home,
  GoodsDetail,
  ConfirmOrder,
  BuyHistory,
  BatchTransform,
  PaySuccess
}