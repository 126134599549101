import {useEffect, useState} from "react";
import "./boringApeClub.scss"
import NFTHeader from "@/components/NFTHeader";
import {useLocation} from "react-router";
import {getUrlAllParams} from "@/utils/publicFun";
import {queryAdvertisementByType} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";

export const BoringApeClub = () => {
    const {search} = useLocation()
    // const {tagUrl = ''} = getUrlAllParams(search.slice(1));
    const [bannerData, setBannerData] = useState({}); //广告位信息
    useEffect(()=>{
        queryAdvertisement()
    },[])

    const queryAdvertisement = async () => {
        try {
            const res = await queryAdvertisementByType({type: 22});
            if (res.code !== '000000') throw res.msg;
            setBannerData(res.data || {});
        } catch (error) {
            Toast.show(error);
        }
    }

    return (
        <div className={'boring_club_container'}>
            <NFTHeader midText={'dodo社区'}/>
            <img src={require('@/assets/img/bg_dodo.png')} alt="" className={'bg_img'}/>
            <img src={bannerData?.tagUrl} alt="" className={'qr_bg'}/>
            <p className={'boring_club_des'}>截图保存，识别二维码加入</p>

        </div>
    )
}
