/**
 * @description 我的奖品/中奖记录
*/

import React, { useState, useEffect, useRef } from 'react';
import ListView from '@/components/AntListView';
import {hideLoading, showLoading} from "@/components/loading";
import NFTHeader from "@/components/NFTHeader";
import {getDrawRecord} from "@/api/projectManager_self";
import Service from '../service';
import {Toast} from "antd-mobile";
import {objectToQueryParams} from "@/utils/publicFun";
import './index.scss';

const Prize = ()=> {
    const currentPage = useRef(1)
    const [dataList, setDataList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [isLoad, setIsLoad] = useState(false)

    useEffect(() => {
        window.JsBridge.setTitle('中奖记录');
        loadData();
    }, []);

    const onDidFocus = () => {
        window.JsBridge.addListener().then(() => {
        loadData();
      });
    }

    const loadData = async () => {
        currentPage.current = 1;
        try {
            showLoading();
            // const res = await getDrawRecord({currentPage: currentPage.current, pageSize: 10});
            const res = await Service.getReceiveList({currentPage: currentPage.current, pageSize: 10});
            hideLoading();
            setIsLoad(true);
            if (res?.status) {
                const { list = [] } = res.data
                setDataList(list)
                setHasMore(list.length >= 10)
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }

    }
    const loadMore = async () => {
        const res = await Service.getReceiveList({currentPage: currentPage.current+1, pageSize: 10});
        if (res?.status) {
            const { list = []} = res.data
            setDataList([...dataList, ...list])
            setHasMore(list.length >= 10)
            currentPage.current = currentPage.current + 1
        }
    }

    const addOrChangeAddress = (item) => {
        if(item.address) {
            const param = objectToQueryParams(item);
            window.JsBridge.push('CustomWeb', { uri: `${process.env.REACT_APP_GKT_LINK}/front/activities/lottery/orderDetail?${param}`});
        }else {
            const param = objectToQueryParams({
                idf: 'commonSub',
                activityCode: Service.activityCode,
                req: item.id,
                name: item.prizeName,
                imgUrl: item.prizeImgUrl,
                productId:item.skuId,
                isChangeName:0
            });
            window.JsBridge.push('CustomWeb', { uri:`${process.env.REACT_APP_GKT_LINK}/front/guapp/activityOrder?${param}`});
            onDidFocus();
        }
    }

    const renderItem = (item)=> {
        return (
            <div className={'record_itemContainer'}>
                <div className={'itemLeft'}>
                    <div className={'itemTop'}>
                        <div className={'itemTopTitle'}>
                            {`${item?.prizeName}`}
                        </div>
                    </div>
                    <div className={'itemTime'}>
                        {`${item?.time || item?.createTime}`}
                    </div>
                </div >
                {
                    item?.prizeType === 3 &&
                    <div className={'itemAddressBtn'}>
                        <div className={'itemAddressBtnTitle'}
                            onClick={() => {addOrChangeAddress(item)}}>
                            {item.address ? `查看订单` : `填写地址领取奖品`}
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (
        <div className={'prize_record_page'}>
            <NFTHeader midText={'中奖记录'} shadow/>
            <div className={'container_record_parts'}>
                <ListView
                    dataSource={dataList}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    onRefresh={() => loadData()}
                    onEndReached={() => loadMore()}
                    style={{paddingTop: 120}}
                    isLoad={isLoad}
                />
            </div>
        </div>
    )
}

export default Prize;
