/**
 * @description 空间id盲盒购买首页, 配置在发售日历需加一个参数
 * @returns {route: /spaceBeautifulAccount?test=1}
 */
import React, { useState, useEffect, useRef } from "react";
import styles from './styles.module.scss';
import left from '@/assets/img/spaceBeautifulAccount/left.png';
import right from '@/assets/img/spaceBeautifulAccount/right.png';
import back from '@/assets/img/icon/back.png';
import NFTModal from "@/components/NFTModal";
import { showLoading, hideLoading } from "@/components/loading";
import { queryDefCode } from "@/api/projectManager_self";
import { Toast } from 'antd-mobile';
import { useNavigate } from "react-router-dom";

const activityCode = 'SPACEBlindBox';

const SpaceBeautifulAccount = () => {
  const navigate = useNavigate();
  const [activityRules, setActivityRules] = useState(null);
  const [data, setData] = useState({});
  const [isHasInfo, setIsHasInfo] = useState(false);
  const pageDom = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      showLoading();
      const [userInfo] = await Promise.all([getUserCertificateStatus()])
      hideLoading();
      setData({...userInfo});
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const getUserCertificateStatus = async () => {
    if (window.JsBridge.hasWebViewBridge()) {
      const res = await window.JsBridge.getDetailCurrentUserInfo()
      setIsHasInfo(true);
      return res;
    } else {
      return {};
    }
  }

  const onBack = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.back();
    } else {
      window.history.go(-1);
    }
  }

  const getActivityRules = async () => {
    if (activityRules) {
      onRules(activityRules);
      return;
    }
    try {
      showLoading();
      const res = await queryDefCode({codeType: 'activityRule', code: activityCode});
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      const {data} = res;
      if (data.result && data.result.defCode) {
        const defCode = data.result.defCode;
        setActivityRules(defCode);
        onRules(defCode);
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  };

  const onRules = (activityRules) => {
    const ModalInstance = NFTModal.show({
      content: <Rules onClose={() => ModalInstance?.close()} activityRules={activityRules}/>,
      getContainer: pageDom.current,
    });
  }

  const purchaseBlindbox = () => {
    if (!data.ID) {
      Toast.show('请先实名');
      return;
    }
    const url = `/variationApe/saleDetail?collectionId=77&code=${activityCode}`
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${url}` });
    } else {
      navigate(url);
    }
  }

  return <div className={styles.space_beautiful_account} ref={pageDom}>
    <div className={styles.back_box} onClick={onBack}><img src={back} alt="" /></div>
    <div className={styles.rules} onClick={getActivityRules}>规则</div>
    {!data.ID && isHasInfo && <div className={styles.real_name} onClick={() => window.JsBridge.navigate('Verify')}>实名</div>}
    <div className={styles.purchase_btn} onClick={purchaseBlindbox}>立即购买</div>
    <div className={styles.id_blindbox}>
      <div className={styles.id_blindbox_item}>
        <img src={left} alt="" />
        <p>快捷靓号：使用后可输入快捷靓号进行转赠及查看空间</p>
      </div>
      <div className={styles.id_blindbox_item}>
        <img src={right} alt="" />
        <p>自定义快捷靓号：限量20个，可自定义编号</p>
      </div>
    </div>
  </div>
}

const Rules = ({onClose, activityRules}) => {
  return <div className={styles.rules_container}>
    <div className={styles.title}>活动规则</div>
    <div className={styles.content}>{activityRules?.otherSign}</div>
    <div className={styles.close}><div onClick={onClose}>我知道了</div></div>
  </div>
}

export default SpaceBeautifulAccount;