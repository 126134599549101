import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import NFTModal from '@/components/NFTModal';
import { Input, Toast } from 'antd-mobile';
import confirmModal from "../confirmModal";
import {showLoading, hideLoading} from '@/components/loading';
import { spaceNiceNoUse } from '@/api/activity';

const reg = /^[A-Z0-9]+$/

const SubmitModal = ({onClose, leftBtn, rightBtn, pageDom, detailInfo}) => {
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onLeftBtn = () => {
    onClose();
    leftBtn && leftBtn();
  }

  const beautifulNumUse = async () => {
    try {
      showLoading()
      const res = await spaceNiceNoUse({
        collectionId: detailInfo?.collectionId,
        nftNo: detailInfo?.nftNo,
        spaceNiceNo: inputValue,
      })
      hideLoading()
      if (res.code === '000000') {
        setError(false)
        onClose();
        submitNumSuccess();
      } else {
        if (res.code === '800035') {
          setError(true)
        } else {
          throw res.msg
        }
      }
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const submitNumSuccess = () => {
    confirmModal({
      pageDom,
      title: '提交成功',
      content: '提交成功，等待审核',
      rightBtn: {
        text: '我知道了',
        onClick: async () => {
          rightBtn && await rightBtn();
        }
      }
    })
  }

  const onRightBtn = async () => {
    if (inputValue.length <= 0) {
      Toast.show('请输入编号');
      return;
    }
    await beautifulNumUse();
  }

  return <div className={styles.submit_modal}>
    <div className={styles.modal_title}>使用编号</div>
    <div className={styles.modal_content}>请输入自定义编号，1-6位字母或数字，审核通过后不可更改，请输入</div>
    <Input 
      className={styles.input} 
      placeholder="请输入自定义编号"
      value={inputValue}
      maxLength={6}
      minLength={1}
      autoFocus
      onChange={(val) => {
        if (val.length > 0) {
          if (reg.test(val)) {
            setInputValue(val);
          } else {
            setInputValue(inputValue);
          }
        } else {
          setInputValue('');
        }
      }}
    />
    <div className={`${styles.error_tip} ${error ? styles.show_error : styles.hide_error}`}>编号重复</div>
    <div className={styles.modal_footer}>
      <div className={styles.cancel_btn} onClick={onLeftBtn}>取消</div>
      <div className={styles.confirm_btn} onClick={onRightBtn}>确认提交</div>
    </div>
  </div>
}

const submitModal = ({ pageDom, leftBtn, rightBtn, detailInfo }) => {
  const ModalInstance = NFTModal.show({
    content: <SubmitModal 
      onClose={() => ModalInstance?.close()}
      leftBtn={leftBtn}
      rightBtn={rightBtn}
      pageDom={pageDom}
      detailInfo={detailInfo}
    />,
    getContainer: pageDom,
  });
}

export default submitModal;