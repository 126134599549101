import React, {useEffect, useState, useRef} from 'react';
import styles from './styles.module.scss'
import clickThrottle from "@/utils/clickThrottle";
import {Swiper} from "antd-mobile";
import {getPrizePoolUnity, queryJackpotPoint} from "@/api/projectManager_self";

const COUNT_ARR = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const activityCode = 'metaRecovery1027';
//回收藏品
const RecycleCol = (props) => { //isCertificate 是否实名
    const [data, setData] = useState({});
    const numRef = useRef(0);
    const [isYZBtnCanClick, setIsYZBtnCanClick] = useState(true);
    useEffect(() => {
        numRef.current = props.data?.maxPool || 0;
        setData(props.data);
    }, [props.data])

    const btnClick = (id) => {
        if (!isCanClick) {
            return;
        }
        setTimeout(() => {
            props.switchItem && props.switchItem(id);
        }, 500)
    }

    const getColCount = () => { //藏品数量
        if (data?.isCertificate === 0) {//未实名
            return '-';
        }
        return (data?.collectionCount || 0);
    }
    const getRemainCount = () => { //回收次数
        if (data?.isCertificate === 0) {//未实名
            return '-';
        }
        return (data?.remainCount || 0);
    }

    const getPrizePool = (num) => {
        const maxPool = numRef.current;//剩余回收次数是否可点
        console.log('numRef.current---', numRef.current);
        console.log('num---', num);
        if (maxPool <= num) {
            setIsYZBtnCanClick(false)
        } else {
            setIsYZBtnCanClick(true)
        }
    }

    // console.log('data---',data);

    const currentTime = new Date(data?.systemTime?.replace(/-/g, "/")).getTime();
    const startT = new Date(data?.startTime?.replace(/-/g, "/")).getTime();
    const endT = new Date(data?.drawTime?.replace(/-/g, "/")).getTime();
    const isCanClick = (data?.isCertificate === 1) && (getRemainCount() > 0) && ((currentTime >= startT) && (currentTime < endT));
    const isLQBtnCanClick = data?.collectionPool || false;//领取按钮是否可点


    return <div className={styles.recycle_col}>
        <div className={styles.left}>
            <div className={styles.left_top}>
                <p>50张奖券瓜分80%奖池积分</p>
                <p>300张奖券获史诗款无聊猿</p>
                <p>剩余奖券瓜分20%奖池积分</p>
            </div>

            <div className={styles.items}>
                <img src={require('../../imgs/img_monkey.png')} alt=""/>
                <img src={require('../../imgs/img_integral.png')}/>
            </div>
            <div>
            </div>
        </div>
        <div className={styles.right}>
            <div className={styles.right_top}>
                <div className={styles.machine_container}>
                    <NumberSwipers getPrizePool={(num) => getPrizePool(num)}/>
                </div>
            </div>
            <div className={styles.btn_container}>
                {currentTime < endT ? <>
                        <div className={`${styles.btn} ${(isCanClick && isLQBtnCanClick) ? '' : styles.btn_dis}`}
                             onClick={() =>(isCanClick && isLQBtnCanClick)? btnClick(3):null}>
                            <p>回收得史诗猴</p>
                        </div>
                        <div className={`${styles.btn1} ${(isCanClick && isYZBtnCanClick) ? '' : styles.btn1_dis}`}
                             onClick={() =>(isCanClick && isYZBtnCanClick)? btnClick(4):null}>
                            <p>押注1500积分</p>
                        </div>
                    </> :
                    <p className={styles.btn_des}>已开奖 进入刮奖</p>
                }
            </div>

            <div className={styles.bottom}>
                <div className={styles.bottom_left}>
                    <span>可回收藏品：</span>
                    <span>{getColCount()}</span>
                    <span>个</span>
                </div>
                <div className={styles.bottom_left}>
                    <span>剩余回收次数：</span>
                    <span>{getRemainCount()}</span>
                    <span>次</span>
                </div>
            </div>

        </div>
    </div>
}

const NumberSwipers = (props) => {
    const swiperArray = useRef([
        {obj: Swiper, id: 0, swiperRef: useRef(null)},
        {obj: Swiper, id: 1, swiperRef: useRef(null)},
        {obj: Swiper, id: 2, swiperRef: useRef(null)},
        {obj: Swiper, id: 3, swiperRef: useRef(null)},
        {obj: Swiper, id: 4, swiperRef: useRef(null)},
        {obj: Swiper, id: 5, swiperRef: useRef(null)},
        {obj: Swiper, id: 6, swiperRef: useRef(null)}]
    )
    const numRef = useRef();
    const timer = useRef()

    useEffect(() => {
        getPointsNum();
        startTimer();
        return () => {
            timer.current && clearInterval(timer.current);
        }
    }, [props.activityInfo]);

    const startTimer = () => {
        timer.current && clearInterval(timer.current);
        timer.current = setInterval(() => {
            getPointsNum();
        }, 10000);
    }

    const getPointsNum = async () => {
        try {
            const res = await getPrizePoolUnity({activityCode: activityCode});
            if (res.code != '000000') throw res.msg;
            let numTem = res.data.prizePool + '';
            if (!numTem) {
                return;
            }
            props.getPrizePool && props.getPrizePool(res.data.prizePool);
            numTem = numTem.replace(/\s+/g, '');
            if (numTem.length < 7) {
                let difValue = 7 - numTem.length;
                let bu0 = '';
                for (let i = 0; i < difValue; i++) {
                    bu0 += '0';
                }
                numTem = bu0 + numTem;
            }

            // window.JsBridge.print('numTem之后')
            // console.log('numTem之后---', numTem);
            if (numRef.current === numTem) {
                // console.log('前后值一样,return');
                return
            }
            numRef.current = numTem;
            let data = [];
            for (let i = 0; i < numTem.length; i += 1) {
                data.push(Number(numTem.charAt(i)));
            }
            if (data.length === 7) {
                for (let i = 0; i < swiperArray.current.length; i++) {
                    let item = swiperArray.current[i];
                    let swiper = item.swiperRef;
                    if (swiper) {
                        let num = data[i];
                        swiper.current.swipeTo(num);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <div className={styles.swiper_container}>
                {
                    swiperArray.current.map((item) => {
                        return <item.obj
                            ref={item.swiperRef}
                            key={item.id.toString()}
                            defaultIndex={0}
                            allowTouchMove={false}
                            indicator={() => null}
                            loop={true}
                            direction='vertical'>
                            {COUNT_ARR.map((item1) => <Swiper.Item
                                    key={item1.toString()} style={{}}>
                                    <div className={styles.point_item}>{item1}</div>
                                </Swiper.Item>
                            )}
                        </item.obj>
                    })
                }
            </div>
        </>
    )
}


export default RecycleCol;
