/**
 * @description 欧洲杯活动首页
 * @returns {route: /eurocup/home?test=1}
*/
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Toast } from 'antd-mobile';
import classNames from 'classnames';
import NFTModal from '@/components/NFTModal';
import { hideLoading, showLoading } from '@/components/loading';
import ActivityRules from '../components/ActivityRules';
import NumberScroll from '@/components/NumberScroll';
import './index.scss';
import Service from '../service';

const Home = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [dataSource, setDataSource] = useState({});
  const pageDom = useRef(null);
  const timer = useRef()

  useEffect(() => {
    getData(true);
    onDidFocus();
    return () => {
      timer.current && clearInterval(timer.current);
    }
  }, []);

  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      getData(true);
      onDidFocus();
    });
  }


  // 获取用户信息，是否实名
  const getUserCertificateStatus = async () => {
    if (window.JsBridge.hasWebViewBridge()) {
      const res = await window.JsBridge.getDetailCurrentUserInfo()
      return res;
    } else {
      return {};
    }
  }

  const startTimer = (data={}) => {
    timer.current && clearInterval(timer.current);

    const {currentTime, startTime, endTime} = data;
    const currentT = new Date(currentTime?.replace(/-/g, "/")).getTime();
    const startT = new Date(startTime?.replace(/-/g, "/")).getTime();
    const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
    if(currentTime < startT) {
      // 未开始
      setTimeout(()=>{
        startTimer()
      }, startT - currentT)
      return console.log('活动未开始');
    }else if(currentT > endT) {
      // 已结束
      return console.log('活动已结束');
    }
    
    timer.current = setInterval(() => {
      getData();
    }, 10000);
  }

  const getData = async (loading=false) => {
    try {
      if(loading) showLoading();
      if(loading) {
        const userInfo = await getUserCertificateStatus();
        setUserInfo(userInfo);
      }
      const res = await Service.getInitDurocupData();
      if(loading) hideLoading();
      if (res.code !== '000000') throw res.msg;
      setDataSource(res.data);
      startTimer(res.data);
    } catch (error) {
      hideLoading();
      Toast.show(error);
      
    }
  }

  const onClickRules = () => {
    const ModalInstance = NFTModal.show({
      content: <ActivityRules onClose={() => ModalInstance?.close()} />,
      getContainer: pageDom.current,
    });
  }
  const onBack = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      timer.current && clearInterval(timer.current);
      window.JsBridge.back();
    } else {
      window.history.go(-1);
    }
  }

  const onBuyAction = (item = {}) => {
    // if(!userInfo?.ID) return Toast.show('请先实名认证');
    const uri = `/ape/saleDetail?collectionId=${item.collectionId}&code=${Service.activityCode}`
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.verifyRealName("请先实名认证").then(function (res) {
        if (res?.result) {
          timer.current && clearInterval(timer.current);
          window.JsBridge.push("CustomWeb", { uri:  `${window.location.origin}${uri}` })
        }
      })
      
    } else {
      navigate(uri);
    }
    
  }

  return (
    <div className="eurocup-home" ref={pageDom}>
      <div className="back" onClick={onBack}></div>
      <div className='top_bg'>
        <div className='ball_desc'>
          <img src={require('@/assets/img/eurocup/ball_desc.png')} alt="" />
          <img src={require('@/assets/img/eurocup/fire_ball.png')} alt="" />
        </div>

      </div>
      <div className="activity_rules" onClick={onClickRules}>规 则</div>
      {!userInfo?.ID && <div className="real_name" onClick={() => window.JsBridge.navigate('Verify')}>实 名</div>}
      <div className='body_content' >
        <div className='nums_bg'>
          <NumberScroll textStyle={{ width: 35, height: 80 }} startValue={'00000000'} endValue={dataSource.maxPool ?? '00000000'} />
        </div>
        <div className='gap_bg'>
          <img src={require('@/assets/img/eurocup/text_team.png')} alt="" />
        </div>
        <div className='team_list'>
          {
            dataSource.teamList?.map((item, i) => {
              return (
                <div className={classNames('team_item', `team_item_${i}`)} key={i}>
                  <div className='team_name'>{item.country}</div>
                  <div className='team_logo'>
                    { item.hasCount !== undefined && <span>{`持有${item.hasCount ?? 0}`}</span> }
                    <img src={item.imgUrl} alt="" />
                  </div>
                  <div className='team_sale'>已售{item.soldCount ?? 0}</div>
                  <div className='buy_btn' onClick={() => onBuyAction(item)}></div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Home;