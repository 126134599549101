/**
 * @description GR-2407668康养藏品
 * @returns { /kangyang-nft/home }
*/

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { Toast } from 'antd-mobile';
import ModalAlert from "@/components/ModalAlert";
import classNames from 'classnames';
import {
    recoveryActivityExec
} from '@/api/projectManager_self';
import NFTModal from '@/components/NFTModal';
import { hideLoading, showLoading } from '@/components/loading';
import ActivityRules from '../components/ActivityRules';
import Service from '../service';
import Styles from './index.module.scss';

const Btns = [
    {title: '规则', key: 1},{title: '实名', key: 2},{title: '权益获取记录', key: 3},
]

const KangYangNFT = ()=> {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [dataSource, setDataSource] = useState({});
    const pageDom = useRef(null);

    useEffect(() => {
        (()=>{
            getData(true);
            onDidFocus();
        })()
        
    }, []);
    const onDidFocus = () => {
        window.JsBridge.addListener().then(() => {
          getData(true)
          onDidFocus();
        });
    }
    const getData = async (loading = false) => {
        try {
            if (loading) showLoading();
            if (loading) {
                const userInfo = await Service.getUserCertificateStatus();
                setUserInfo(userInfo);
            }
            const res = await Service.getInitData();
            if (loading) hideLoading();
            if (res.code !== '000000') throw res.msg;
            setDataSource(res.data);
        } catch (error) {
            hideLoading();
            Toast.show(error);

        }
    }

    const onRightBtnClick = (item={}) => {
        if(item.key ===1) {
            const ModalInstance = NFTModal.show({
                content: <ActivityRules onClose={() => ModalInstance?.close()} />,
                getContainer: pageDom.current,
            });
        }else if(item.key ===2) {
            window.JsBridge.navigate('Verify');
        }else if(item.key ===3) {
            const uri = `/kangyang-nft/mergeHistory`
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
            } else {
                navigate(uri);
            }
        }
        
    }

    //立即购买
    const onBuy = () => {
        const {systemTime, startTime, endTime} = dataSource;
        const currentT = new Date(systemTime?.replace(/-/g, "/")).getTime();
        const startT = new Date(startTime?.replace(/-/g, "/")).getTime();
        const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
        if(currentT < startT) {
            return Toast.show('活动未开始'); 
        }else if(currentT > endT) {
           return Toast.show('活动已结束');
        }
        const uri = `/ape/saleDetail?collectionId=${Service.collectionId}&code=${Service.activityCode}`
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.verifyRealName("请先实名认证").then(function (res) {
                if (res?.result) {
                    window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
                }
            })

        } else {
            navigate(uri);
        }
    }

    // 康养合成
    const onKangyangMerge = ()=> {
        const content = (
            <div style={{padding: '20px 0', textAlign:'center'}}>
                恭喜集齐，合成后将获得 45 平米康养公寓永久使用权，客服将第一时间联系您完成线下签约，确认合成？
            </div>
        )
        ModalAlert.show({
            getContainer: pageDom.current,
            title: '确认合成',
            content: content,
            onAction: async (btn, index) => {
                if(btn.key === 'confirm') {
                    // 调用合成接口
                    showLoading();
                    const res = await recoveryActivityExec({activityCode: Service.activityCode});
                    hideLoading();
                    if(res.code === '000000') {
                        ModalAlert.hide();
                        const list = res.data?.userCollectionRespList ?? [];
                        const collectionList = list.map(item=> {
                            return {
                                nftNo: item.elementId,
                                collectionName: '康养数字藏品',
                                image: item.url,
                                hash: item.hash,
                                collectionId: item.collectionId
                            }
                        })
                        Toast.show({
                            content: res.msg ?? '合成成功',
                            afterClose: () => {
                                const url = '/ape/PaySuccess'
                                navigate(url, { 
                                    state: {
                                        collectionList: collectionList, 
                                        from: Service.activityCode,
                                        title: '合成成功',
                                        showNav: 1,
                                    }
                                });
                            }
                        });
                    }else {
                        Toast.show(res.msg);
                    }
                }else {
                    ModalAlert.hide();
                }
               
            },
            actions: [
                { key: 'cancel', text: '取消' },
                { key: 'confirm', text: '确认' }
            ]
        })
    }
    
    const btnConfig = useMemo(()=>{
        const btn = {
            enable: false,
            text: '合成'
        };

        const {systemTime, startTime, endTime} = dataSource;
        const currentT = new Date(systemTime?.replace(/-/g, "/")).getTime();
        const startT = new Date(startTime?.replace(/-/g, "/")).getTime();
        const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
        if(currentT < startT) {
            btn.text = '未开始'; 
        }else if(currentT > endT) {
           btn.text ='已结束';
        }
        if(dataSource.requireCount && dataSource.count >= dataSource.requireCount) {
            btn.enable = true;
            btn.text = '合成';
        }

        return btn;
    },[dataSource])

    return (
        <div className={Styles.KangYangNFT} ref={pageDom}>
            <div className={Styles.back} onClick={Service.onBack}></div>
            <div className={Styles.topBg}>
                <img className={Styles.bgTopImg} src={require('@/assets/img/kangYangNFT/kangyang.jpg')} alt=''/>
                <div className={Styles.right_btns}>
                {
                    Btns.map((btn, i)=> {
                        if(btn.key === 2 && userInfo?.ID) return null;
                        return (
                            <div key={i} className={Styles.rightBtn} onClick={()=>onRightBtnClick(btn)}>{btn.title}</div>
                        )
                    })
                }
                </div>
            </div>
            <div className={Styles.content}>
                <button className={Styles.buy_button} onClick={onBuy}>立即购买</button>
                <div className={Styles.card}>
                    <div className={Styles.left}>
                        <div>{`持有${dataSource.requireCount ?? '--'}个可合成`}</div>
                        <span>当前持有:<span>{dataSource.count ?? 0}</span></span>
                    </div>
                    {
                        btnConfig.enable ?
                        <div className={classNames(Styles.mergeBtn, Styles.enable)} onClick={onKangyangMerge}>合成</div>
                        :<div className={Styles.mergeBtn}>{btnConfig.text}</div>
                    }
                    
                </div>
                {
                    dataSource.detail?.list?.map((item,key)=> {
                        return (
                            <div className={Styles.configSetting} key={key}>
                                <div>持有<span>{`${item.position ?? 0}个`}</span>康养藏品每月可得</div>
                                <div>{`${item.pointCount ?? 0}积分`}<span>+</span>{`${item.cardName}x${item.cardNum ?? 1}`}</div>
                            </div>
                        )
                    })
                }
            </div>
            <div className={Styles.bottom}>
                <span>最终解释权归绿地G优所有</span>
                <img src={require('@/assets/img/kangYangNFT/logo_bottom.png')} alt="" />
            </div>
        </div>
    );
}

export default KangYangNFT;