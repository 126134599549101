/**
 * @description GR-2408675王小慧盲盒
 * @param {}
 * @returns {/wangXiaoHui/home?test=1}
*/
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Toast } from 'antd-mobile';
import NFTModal from '@/components/NFTModal';
import { hideLoading, showLoading } from '@/components/loading';
import ActivityRules from '../components/ActivityRules';
import WinningNumbers from '../components/WinningNumbers';
import Service from '../service';
import Styles from './index.module.scss';

const Btns = [
    { title: '规则', key: 1 }, { title: '实名', key: 2 },/* { title: '我的奖品', key: 3 }*/,
]

const GidsList = [
    { title: '丝巾', img: require('@/assets/img/wangXiaoHui/sijin.png'), count: 10 },
    { title: '版画', img: require('@/assets/img/wangXiaoHui/banhua.png'), count: 30 },
    { title: '扑克牌', img: require('@/assets/img/wangXiaoHui/puke.png'), count: 10 },
    { title: '糖璃', img: require('@/assets/img/wangXiaoHui/tangli.png'), count: 8 },
]

const Home = () => {

    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [dataSource, setDataSource] = useState({});
    const pageDom = useRef(null);

    useEffect(() => {
        getData(true);
    }, []);
    const getData = async (loading = false) => {
        try {
            if (loading) showLoading();
            if (loading) {
                const userInfo = await Service.getUserCertificateStatus();
                setUserInfo(userInfo);
            }
            const res = await Service.getInitData();
            const res1 = await Service.getActivityPool();
            if (loading) hideLoading();
            if (res.code !== '000000') throw res.msg;
            if (res?.data) {
                const data = {
                    ...res?.data,
                    poolDetail: res1?.data?.poolDetail ?? [],
                };
                setDataSource(data);
            }

        } catch (error) {
            hideLoading();
            error && Toast.show(error);
        }
    }

    const onRightBtnClick = (item = {}) => {
        if (item.key === 1) {
            const ModalInstance = NFTModal.show({
                content: <ActivityRules onClose={() => ModalInstance?.close()} />,
                getContainer: pageDom.current,
            });
        } else if (item.key === 2) {
            window.JsBridge.navigate('Verify');
        } else if (item.key === 3) {
            const uri = `/wangXiaoHui/prize`
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
            } else {
                navigate(uri);
            }
        }

    }
    // 立即购买
    const onBuy = () => {
        const { systemTime, startTime, endTime} = dataSource;
        if(!startTime) {
            return Toast.show('活动查询异常');
        }
        const currentT = new Date(systemTime?.replace(/-/g, "/")).getTime();
        const startT = new Date(startTime?.replace(/-/g, "/")).getTime();
        const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
        if(currentT < startT) {
            return Toast.show('活动未开始'); 
        }else if(currentT > endT) {
           return Toast.show('活动已结束');
        }
        const uri = `/ape/saleDetail?collectionId=${Service.collectionId}&code=${Service.activityCode}`
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.verifyRealName("请先实名认证").then(function (res) {
                if (res?.result) {
                    window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
                }
            })

        } else {
            navigate(uri);
        }
    }

    // 查看中奖号码
    const onLookNumers = () => {
        const ModalInstance = NFTModal.show({
            content: <WinningNumbers data={dataSource.poolDetail} onClose={() => ModalInstance?.close()} />,
            getContainer: pageDom.current,
        });
    }

    const onBack = () => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.back();
        } else {
            navigate(-1);
        }
    }

    return (
        <div className={Styles.pageBox} ref={pageDom}>
            <div className={Styles.back} onClick={onBack}></div>
            <div className={Styles.top_bg}>
                <div className={Styles.right_btns}>
                    {
                        Btns.map((btn, i) => {
                            if (btn.key === 2 && userInfo?.ID) return null;
                            return (
                                <div key={i} className={Styles.rightBtn} onClick={() => onRightBtnClick(btn)}>{btn.title}</div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={Styles.contentBox}>
                <button className={Styles.buy_button} onClick={onBuy}></button>
                <div className={Styles.bottomBox}>
                    <img src={require('@/assets/img/wangXiaoHui/title.png')} alt="" />
                    <div className={Styles.boxIn}>
                        <div className={Styles.grids}>
                            {
                                GidsList.map((item, i) => {
                                    return (
                                        <div key={i} className={Styles.grid_item}>
                                            <img src={item.img} alt="" draggable={false}/>
                                            <div>{item.title}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={Styles.boxBtn}>
                            <div className={Styles.btn} onClick={onLookNumers}>
                                <img src={require('@/assets/img/wangXiaoHui/number.png')} alt="" draggable={false}/>
                            </div>
                            <div className={Styles.btn} onClick={()=>onRightBtnClick({key:3})}>
                                <img src={require('@/assets/img/wangXiaoHui/btn2.png')} alt="" draggable={false}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={Styles.bottomBox}>
                    <img src={require('@/assets/img/wangXiaoHui/title2.png')} alt="" />
                    <div className={Styles.descBox}>
                        <p className={Styles.text}>
                        旅德艺术家王小慧，创作领域涉及摄影、影像、雕塑、装置、设计、新媒体艺术与写作等，自传《我的视觉日记》再版数十次，被《凤凰周刊》选入影响世界未来的五十位华人。拥有上海王小慧艺术中心、王小慧艺术馆、跨界学院等机构。 
                        </p>
                        <div className={Styles.imgBox}>
                            <img src={require('@/assets/img/wangXiaoHui/img1.png')} alt="" draggable={false}/>
                            <img src={require('@/assets/img/wangXiaoHui/img2.png')} alt="" draggable={false}/>
                        </div>
                        <p className={Styles.text}>
                        国内最大规模、最前卫、最多IP、最丰富多彩、最多艺术家参展的“10000条艺术大展”已经在王小慧艺术馆揭幕。
                        </p>
                        <p className={Styles.text}>
                        10000条龙艺术大展由三个维度展开：过去、现在、未来。
                        </p>
                        <p className={Styles.text}>
                        回溯先秦龙文化源头，立足当代艺术创作潮流，借助AIGC新技术赋能的翅膀，展望中国龙文化的创新与未来。
                        </p>
                        <p className={Styles.text}>
                        10000条龙艺术大展，完全不同于市场流行的传统生肖展，最大亮点是传统经典、当代艺术与最新科技的完美融合。
                        </p>
                        <p className={Styles.text}>
                        由国际著名艺术家王小慧领衔，国内外名人大伽云集，Al新秀层出不穷。由于参展作品源源不断，展览将会不断更新迭代。
                        </p>
                        <p className={Styles.text}>
                        “10000条龙艺术大展”开展以来，已经名扬海内外。其中，除了大师杰作之外，AIGC 共创的几万条龙是本次展览最重要、最活跃、最富创造性，也是最富商业价值的部分。
                        </p>
                        <p className={Styles.text}>
                        经过几轮专家评选，我们从几万条龙中评选出100条龙IP：有潮酷龙，个性张扬，时尚潮流，充满冒险、自由与独立精神。有可爱龙，天真难得，萌萌哒哒，笑容可掬，带你回到纯真年代。有科技龙，未来感科技感神秘感十足，充满创新精神。更有些龙走入我们生活，好象我们生活中的伙伴，引起人们更多的生活情趣，更加热爱生活。
                        </p>
                        <p className={Styles.text}>
                        我们期待与不同的品牌协作，传承中华优秀文化，共创艺术的当代性与丰富性。
                        </p>
                    </div>
                </div>
            </div>

            <div className={Styles.bottom_logo}>
                <img src={require('@/assets/img/kangYangNFT/logo_bottom.png')} alt="" />
            </div>
        </div>
    )
}

export default Home;