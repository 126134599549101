import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import styles from './compoundDialog.module.scss';
import {Mask, Toast} from 'antd-mobile';
import {hideLoading, showLoading} from "@/components/loading";
import {debrisCompose} from "@/api/projectManager_self";
import JsBridgeNew from "@/utils/jsbridgeNew";


const CompoundDialog = ({onClose, data,owningGroup, callback}) => {
    const [visible, setVisible] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [succData, setSuccData] = useState({});

    const handleClick = async (type) => {
        if (type === 0) {
            setVisible(false);
            onClose && onClose();
        } else if (type === 1) {
            try {
                showLoading();
                let nftNoList = [];
                for (const item of data) {
                    nftNoList.push(item.nftNo)
                }
                const res = await debrisCompose({
                    "nftNoList": nftNoList,
                    "petNo": 'debrisSynthesis',
                    owningGroup:owningGroup
                })
                hideLoading();
                if (res.code !== '000000') throw res.msg;
                console.log('res---',res);
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    setSuccData(res.data)
                    setIsSuccess(true);
                }, 500)
            } catch (error) {
                Toast.show(error);
            }
        }else if (type === 2) {
            setVisible(false);
            onClose && onClose();
            JsBridgeNew.callback && JsBridgeNew.callback({isSuccess:true});
            window.JsBridge.back();
        }
    }

    const images = () => {
        return data.map((item, index) => {
            return <img src={item.imageBg} alt="" key={index.toString()}/>
        })
    }

    const getSuccessCom = () => {
        return <div className={styles.success_div}>
            <img src={succData.nftImage} alt="" className={styles.success_img}/>
            <p className={styles.nft_name}>藏品编号</p>
            <p className={styles.nft_no}>{succData.nftNo}</p>
        </div>
    }

    return <Mask className={styles.compound_dialog} visible={visible}>
        <div className={styles.put_modal_container}>
            <p className={styles.put_guess_title}>{isSuccess ? '合成成功' : '合成藏品'}</p>
            {
                isSuccess ? null : <p className={styles.des}>确定将以下藏品合成？合成后以下藏品将被回收，且操作不可逆</p>
            }
            {
                isSuccess ? getSuccessCom() : <div className={styles.images}>{images()}</div>
            }
            {
                isSuccess ? <p className={styles.success_btn} onClick={()=>handleClick(2)}>确认</p> : <div className={styles.guess_btn_container}>
                    <p onClick={() => handleClick(0)}>取消</p>
                    <p onClick={() => handleClick(1)}>确认合成</p>
                </div>
            }
        </div>
    </Mask>
}

CompoundDialog.open = (data,owningGroup, callback) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<CompoundDialog onClose={close} data={data} callback={callback} owningGroup={owningGroup}/>, div);
}

export default CompoundDialog;
