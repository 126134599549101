/**
 * 支付方式选择
 * **/
import { useState, useEffect } from 'react'
import { Radio, Toast } from "antd-mobile"
import { isMiniprogram } from '@/utils/wxJsBridge'
import { getProductList } from '@/api/projectManager_self';
import JsBridgeNew from "@/utils/jsbridgeNew";
import { isArray} from "@/utils/publicFun";
import styles from './styles.module.scss'

const PayWays = window.JsBridge.hasWebViewBridge() ? {
  wxpay: false,
  alipay: false,
  iosIAP: false,
  point: false,
} : {
  wxpay: true,
  alipay: true,
  iosIAP: true,
  point: false,
};

const isInWxMini = isMiniprogram()

export default ({
  onChange = () => {},
}) => {
  //支付方式配置
  const [payConfig, setPayConfig] = useState(PayWays)
  const [payWay, setPayWay] = useState('WXPAY')
  // 微信小程序内只有微信支付
  if (isInWxMini) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>选择支付方式</div>
        <Radio.Group value={payWay} onChange={setPayWay}>
          <div className={styles.row}>
            <div className={styles.payWay}>
              <img src={require("@/assets/img/orderpay/wx.png")} alt=""/>
              <span>微信</span>
            </div>
            <Radio value="WXPAY" className={styles.radio}></Radio>
          </div>
        </Radio.Group>
      </div>
    )
  }

  const getPayConfig = () => {  
    // 获取支付配置 iosIAP weixinPay  aliPay
    window.JsBridge.getStorage({key: 'tabConfig'}).then((res) => {
        setPayConfig({
            wxpay: res?.result?.wxpay || false,
            alipay: res?.result?.alipay || false,
            iosIAP: res?.result?.iosIAP || false,
            point:res?.result?.pointDeduct || false,
        })
        if (res?.result?.iosIAP) { // 打开了内购按钮
            getProductList({}).then((result) => {
                if (result.code === '000000') {
                    if (result.data && isArray(result.data) && result.data.length > 0) {
                        JsBridgeNew.iapProList(JSON.stringify(result.data));
                    }else {
                        Toast.show('获取苹果支付商品失败');
                    }
                } else {
                    Toast.show(result?.msg);
                }
            });
        }
    })
  }

  useEffect(()=>{
      getPayConfig()
  },[]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(payWay)
    }
  },[payWay]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>选择支付方式</div>
      <Radio.Group value={payWay} onChange={setPayWay}>
        {payConfig.wxpay && <div className={styles.row}>
            <div className={styles.payWay}>
                <img src={require("@/assets/img/orderpay/wx.png")} alt=""/>
                <span>微信</span>
            </div>
            <Radio value="WXPAY" className={styles.radio}></Radio>
        </div>}
        {payConfig.alipay && <div className={styles.row}>
            <div className={styles.payWay}>
                <img src={require("@/assets/img/orderpay/zfb.png")} alt=""/>
                <span>支付宝</span>
            </div>
            <Radio value="ALIPAY" className={styles.radio}></Radio>
        </div>}
        {payConfig.iosIAP && <div className={styles.row}>
            <div className={styles.payWay}>
                <img src={require("@/assets/img/orderpay/apple_pay.png")} alt=""/>
                <span>苹果支付</span>
            </div>
            <Radio value="IOS_IAP" className={styles.radio}></Radio>
        </div>}
      </Radio.Group>
    </div>
  )
}