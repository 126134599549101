import React from "react";
import styles from "./styles.module.scss";
import close from '@/assets/img/landfi/icon_del.png';

const ConfirmCancelLease = ({ onClose, onConfirm }) => {
  return <div className={styles.confirm_cancel_lease}>
    <div className={styles.confirm_cancel_lease_header}>
      <span></span>
      <span>确认取消租赁</span>
      <img src={close} alt="" onClick={onClose}/>
    </div>
    <div className={styles.confirm_cancel_lease_content}>确认要取消租赁吗？</div>
    <div className={styles.confirm_cancel_lease_footer}>
      <div className={styles.cancel} onClick={onClose}></div>
      <div className={styles.confirm} onClick={onConfirm}></div>
    </div>
  </div>
}

export default ConfirmCancelLease;