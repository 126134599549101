import React, { useState, useRef, useEffect } from 'react'
import styles from './myLands.module.scss'
import { Mask, Tabs, Toast } from 'antd-mobile'
import ListView from "@/components/AntListView"
import ReceiveLand from "./receiveLand"
import RealName from "./realName"
import noData from '@/assets/img/landfi/no-data.png'
import close from '@/assets/img/landfi/icon_del.png'
import used from '@/assets/img/landfi/used.png'
import { myLandsList, confirmReceiveLands } from '@/api/guland'
import {hideLoading, showLoading} from "@/components/loading"
import MyModal from '../jewelLand/modal'
import ConfirmLandOrder from './landOrder'

const TabsList = [
  {key: '0', title: '全部'},
  {key: '1', title: 'C级'},
  {key: '2', title: 'B级'},
  {key: '3', title: 'A级'},
]

const TabMap = {
  '0': '',
  '1': 3,
  '2': 2,
  '3': 1,
}

const MyLands = ({visible, onclose, userInfo, upgradeLand, container}) => {
  const [currentTab, setCurrentTab] = useState('0')
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    visible && setRefresh(false)
  }, [visible])

  const onTabClick = (i) => {
    setCurrentTab(i)
  }

  const onReceive = () => {
    if (userInfo?.receiveNum <= 0) {
      return
    }
    ReceiveLand.open({
      onConfirmReceive: async () => {
        if (!userInfo.ID) {
          RealName.open({confirmCB: () => {
            window.JsBridge.navigate('Verify')
            window.JsBridgeNew.hiddenStatusBar(false)
            window.JsBridgeNew.screenRotate(true)
          }})
          return
        }
        await receiveGiveLands()
        setRefresh((pre) => !pre)
      }
    })
  }

  const buyLand = () => {
    if (!userInfo.ID) {
      RealName.open({confirmCB: () => {
        window.JsBridge.navigate('Verify')
        window.JsBridgeNew.hiddenStatusBar(false)
        window.JsBridgeNew.screenRotate(true)
      }})
      return
    }
    const ModalInstance = MyModal.show({
      content: <ConfirmLandOrder onClose={() => ModalInstance?.close()} paySuccessCB={() => setRefresh((pre) => !pre)}/>,
      getContainer: container,
    })
  }

  // 领取土地
  const receiveGiveLands = async () => {
    try {
      showLoading()
      const res = await confirmReceiveLands()
      if (res.code !== '000000') throw res.msg
      hideLoading()
      Toast.show('领取成功')
    } catch (error) {
      console.log(error)
      Toast.show(error)
      hideLoading()
    }
  }

  return <Mask className={styles.my_lands_mask} visible={visible} destroyOnClose>
    <div className={styles.my_lands_container}>
      <img className={styles.close} src={close} alt="" onClick={() => onclose(refresh)}/>
      <div className={styles.my_lands_header}>
        <span className={styles.header_left}>我的土地</span>
        <div className={styles.header_right} onClick={buyLand}>
          <span>购买土地</span>
          {/* <span>您可领取{userInfo?.receiveNum}块土地</span> */}
        </div>
      </div>
      <div className={styles.my_lands_content}>
        <div className={styles.lands_tabs}>
          <Tabs className={styles.tabs} activeKey={currentTab} onChange={(key) => onTabClick(key)}>
            {TabsList.map((item, index) => <Tabs.Tab 
              key={index} 
              title={<div className={`${styles.tab_title} ${currentTab === item.key ? styles.tab_select: ''}`}>{item.title}</div>}
            >
            </Tabs.Tab>)}
          </Tabs>
        </div>
        <div className={styles.lands_list_box}>
          <LandList type={TabMap[currentTab]} upgradeLand={upgradeLand} refresh={refresh}/>
        </div>
      </div>
    </div>
  </Mask>
}

const LandList = ({ type, upgradeLand, refresh }) => {
  const [landData, setLandData] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const domRef = useRef(null)

  useEffect(() => {
    getLandList();
  }, [type, refresh])

  const getLandList = async (isRefresh=true) => {
    try {
      if(isRefresh) {
        currentPage.current = 1;
        domRef.current?.scroll(0, 0)
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        pageNum: currentPage.current,
        pageSize: pageSize.current,
        blockLevel: type,
      }
      console.log(params)
      const res = await myLandsList(params)
      console.log(res)
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setLandData(res?.data?.landBlocksDTOList || []);
      } else {
        setLandData([...landData, ...res?.data?.landBlocksDTOList]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.landBlocksDTOList?.length >= pageSize.current);
    } catch (error) {
      console.log(error)
      Toast.show(error)
    }
  }

  const listItem = (item) => {
    return <div 
      className={styles.list_item}
      onClick={() => {
        upgradeLand(item)
      }}
    >
      <div className={styles.list_item_inner}>
        <div className={styles.img_bg}>
          <img className={styles.land_img} src={item.nftImage} alt=""/>
          {item.setFlag === 1 && <img className={styles.used_mark} src={used} alt="" />}
          {item.ownFlag === 1 ? <div className={styles.lease_tag}></div>: null}
        </div>
        <p>#{item.nftNo}</p>
        <div className={`${styles.up_land_btn} ${ [48, 63, 205].includes(item.collectionId) ? styles.up_disabled: styles.up_active}`}></div>
      </div>
    </div>
  }
  return <div className={styles.land_list} ref={domRef}>
    {
      landData?.length > 0 ? <ListView
        dataSource={landData}
        hasMore={hasMore}
        style={{paddingTop: 5}}
        renderItem={listItem}
        onEndReached={async () => {
          await getLandList(false);
        }}
        isLoad={isLoad}
      />: !isLoad ? null: <div className={styles.no_data}><img src={noData} alt="" /></div>
    }
  </div>
}

export default  MyLands