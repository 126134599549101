import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { Mask } from 'antd-mobile';

const SuccessModal = ({onClose, leftCB, rightCB, collectionInfo={}}) => {
  const [visible, setVisible] = useState(true);

  const cancel = () => {
    onClose && onClose();
    setVisible(false);
    leftCB && leftCB();
  }

  const confirm = () => {
    rightCB && rightCB();
    setVisible(false);
    onClose && onClose();
  }

  return <Mask className='compose-success-mask' visible={visible} onMaskClick={() => cancel()}>
    <div className="mask-content">
      <div className="title">合成成功</div>
      <div className="collection-info">
        <div className="img-box"><img src={collectionInfo?.nftImage} alt="" /></div>
        <span>藏品编号</span>
        <span>#{collectionInfo?.nftNo}</span>
      </div>
      <div className="btn-box">
        <div className="cancel-btn common" onClick={() => cancel()}>确认</div>
        <div className="confirm-btn common" onClick={() => confirm()}>查看藏品</div>
      </div>
    </div>
  </Mask>
}

SuccessModal.open = ({collectionInfo, leftCB, rightCB}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<SuccessModal onClose={close} collectionInfo={collectionInfo} leftCB={leftCB} rightCB={rightCB}/>, div);
}

export default SuccessModal;