import React from "react";
import './style.scss';
import title from '../../imgs/shop-title.png';

const GoodsList = ({goodsList=[]}) => {
  if(!goodsList.length) return null;

  const discountPrice = (item={})=> {
    const _reslut = Number(item.marketPrice || 0) * (1 - (item.discount|| 1.0));
    return Number(_reslut.toFixed(2));
  }

  const finalPrice = (item={}) => {
    const _res = Number(item.marketPrice || 0) - discountPrice(item);
    return _res < 0 ? 0 : Number(_res.toFixed(2));
  }

  return <div className="parts-recycling-shop">
    <div className="shop-title"><img src={title} alt="" /></div>
    {
      goodsList.map((item, index) => {
        if(item.cateName  === '周边好物') {
          return <div className="shop-goods-list-view" key={`item-${index}`}>
            {
              item.content?.length > 0 && item.content.map((item, index) => {
                return <div 
                  className="shop-goods-item" 
                  key={index}
                  onClick={() => {
                    window.JsBridge.push('Web', {uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${item.skuId}`})
                  }}
                >
                  <img className="goods-img" src={item.pic} alt="" />
                  <div className="goods-info">
                    <span className="goods-name">{item.goodsName}</span>
                    <div className="goods-price-info">
                      <span>￥</span>
                      <span>{finalPrice(item)}</span>
                      <span>￥</span>
                      <span>{item.marketPrice}</span>
                    </div>
                  </div>
                </div>
              })
            }
          </div>
        }
      })
    }
    
  </div>
}

export default GoodsList;