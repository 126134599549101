import React, {useEffect, useState} from 'react';
import './style.scss';
import back from '@/assets/img/collection/back.png';
import {isEmptyString} from "@/utils/publicFun";

const ratio = window.screen.width / 375;
const oriHeight = 44 * ratio; //原始的高度
const NFTHeader = ({
                       leftImg,
                       midText,
                       rightText = '',
                       rightNode,
                       rightClick,
                       bgColor,
                       leftClick,
                       getHeight,
                       backType,//1=app 2=nav
                   }) => {
    const [height, setHeight] = useState(0);
    useEffect(() => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.getStatusBarHeight().then(({height}) => {
                setHeight(height);
                getHeight && getHeight(height + oriHeight);
            });
        } else {
            getHeight && getHeight(oriHeight);
        }
    }, []);

    const onBackClick = () => {
        if (backType === 1) {
            if (window.JsBridge.hasWebViewBridge()) {
                window.JsBridge.back();
            } else {
                window.history.go(-1);
            }
        } else if (backType === 2) {
            window.history.go(-1);
        } else {
            if (leftClick) {
                leftClick();
            } else {
                if (window.JsBridge.hasWebViewBridge()) {
                    window.JsBridge.back();
                } else {
                    window.history.go(-1);
                }
            }
        }
    }

    const rightComponent = () => {
        if (!isEmptyString(rightText)) {
            return (
                <div className="right" onClick={() => {
                    rightClick && rightClick()
                }}>{rightText}</div>
            )
        }
        if (rightNode) {
            return (
                <div className="right" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}
                     onClick={() => {
                         rightClick && rightClick()
                     }}>
                    {rightNode()}
                </div>
            );
        }
        return (
            <div className="right"/>
        )
    }


    return <div className='nft-header' style={{height: 44 * ratio + height}}>
        <div className="container" style={{paddingTop: 10 + height, background: bgColor || ''}}>
            <div className="left" onClick={() => onBackClick()}>
                <img src={leftImg || back} alt=""/>
            </div>
            <div className="middle">{midText}</div>
            {rightComponent()}
        </div>
    </div>
}

export default NFTHeader;
