import {useEffect, useRef, useState} from "react";
import './pwdSetting.scss'
import {Input, Toast} from 'antd-mobile'
import {getUrlAllParams, isEmptyString} from "@/utils/publicFun";
import {useLocation} from "react-router";
import clickThrottle from "@/utils/clickThrottle";
import {hideLoading, showLoading} from "@/components/loading";
import {checkUserPassword, settingPassword, setUserIsShowTradeinfo} from "@/api/projectManager_self";

export const PwdSetting = () => {
    const {search} = useLocation()
    const {type = '0'} = getUrlAllParams(search.slice(1)); //0=密码设置 1=重置密码

    const [eye0, setEye0] = useState(false);
    const [eye1, setEye1] = useState(false);
    const [canClickBtn, setCanClickBtn] = useState(false);
    const pwd = useRef('');
    const pwdAgain = useRef('');

    useEffect(() => {
        window.JsBridge.setTitle(type === '0' ? '密码设置' : '重置密码')
    }, [])

    const eyeOpenOrClose = (type) => {
        if (type === 0) {
            setEye0(!eye0)
        } else if (type === 1) {
            setEye1(!eye1)
        }
    }

    const onChangeText = (text, type) => {
        if (type === 0) {
            pwd.current = text;
        } else if (type === 1) {
            pwdAgain.current = text;
        }
        if (!isEmptyString(pwd.current) && !isEmptyString(pwdAgain.current) && pwd.current.length === 6 && pwdAgain.current.length === 6) {
            setCanClickBtn(true)
        } else {
            setCanClickBtn(false)
        }
    }

    const done = async () => { //完成
        if (!canClickBtn) {
            return
        }
        if (!clickThrottle) {
            return
        }
        if (pwdAgain.current !== pwd.current) {
            Toast.show('密码不一致');
            return;
        }
        try {
            showLoading();
            const res = await settingPassword({tradePasswd: pwd.current, reTradePasswd: pwdAgain.current});
            hideLoading();
            const {code, msg} = res;
            if (code !== '000000') throw msg;
            Toast.show('设置成功!')
           let timer = setTimeout(()=>{
               clearTimeout(timer);
                window.JsBridge.back();
            },1500)
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    return (
        <div className={'pwd_setting_container'}>
            <div className={'pwd_setting_content'}>
                <div className={'item_0'}>
                    <Input placeholder='请输入新的密码' clearable maxLength={6} type={eye0 ? 'text' : 'password'}
                           onChange={(e) => onChangeText(e, 0)}
                           style={{
                               '--color': '#000000',
                               '--font-size': '14px',
                               paddingLeft: '10px',
                               marginRight: '6px'
                           }}/>
                    <img src={eye0 ? require('@/assets/img/icon_eye_on.png') : require('@/assets/img/icon_eye_off.png')}
                         alt="" onClick={() => eyeOpenOrClose(0)}/>
                </div>
                <div className={'pwd_setting_line'}/>
                <div className={'item_0'} style={{marginTop: '16px'}}>
                    <Input placeholder='请再次输入密码' clearable maxLength={6} type={eye1 ? 'text' : 'password'}
                           onChange={(e) => onChangeText(e, 1)}
                           style={{
                               '--color': '#000000',
                               '--font-size': '14px',
                               paddingLeft: '10px',
                               marginRight: '6px'
                           }}/>
                    <img src={eye1 ? require('@/assets/img/icon_eye_on.png') : require('@/assets/img/icon_eye_off.png')}
                         alt=""
                         onClick={() => eyeOpenOrClose(1)}
                    />
                </div>
            </div>
            <p className={'pwd_des'}>6位数字密码</p>
            <div className={`done_btn  ${canClickBtn ? 'btn_opacity' : ''}`} onClick={() => done()}>完成</div>
        </div>
    )
}
