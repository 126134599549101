import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import {Mask, Toast} from 'antd-mobile';
import {hideLoading, showLoading} from "@/components/loading";
import {queryDefCode} from "@/api/projectManager_self";

const RulesModal = ({onClose}) => {
  const [visible, setVisible] = useState(true);
  const [activityRules, setActivityRules] = useState({});

  useEffect(()=>{
    getActivityRules();
  },[])

  const getActivityRules = async () => {
    try {
      showLoading();
      const res = await queryDefCode({codeType: 'activityRule', code: 'secondaryVariantApeRule'});
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      const {data} = res;
      if (data.result && data.result.defCode) {
        const defCode = data.result.defCode;
        setActivityRules(defCode);
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const handleClick = () => {
    setVisible(false);
    onClose && onClose();
  }

  return <Mask className='guessing-rule-mask' visible={visible} onMaskClick={() => handleClick()}>
    <div className='rules-modal-common'>
      <div className="modal-container">
        <div className="modal-title">{activityRules?.codeAlias}</div>
        <div className="modal-content">
          {activityRules?.otherSign}
        </div>
        <div className="rules-btn" onClick={handleClick}>我知道了</div>
      </div>
    </div>
  </Mask>
}

RulesModal.open = () => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<RulesModal onClose={close}/>, div);
}

export default RulesModal;
