import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./collectionComp.scss";
import JsBridgeNew from "@/utils/jsbridgeNew";


const CollectionComp = ({ maxDay, collection,onRefresh }) => {
  const navigate = useNavigate();
  let { collectionName, collectTime, nftHash, nftImage, nftNo, transferStatus, state, transferId, sender, transferTime, associatedCollection, parent, } = collection;

  const goCollectionDetail = async (event) => {
    event.preventDefault();
    if (window.JsBridge.hasWebViewBridge()) {
      const data = await JsBridgeNew.push("CustomWeb", {
        uri: `${window.location.origin}/myCollectionDetail?nft_hash=${nftHash}&state=${transferStatus}`,
        callback: true
      });
      if (data?.isSuccess){
        onRefresh && onRefresh();
      }
    } else {
      navigate(`/myCollectionDetail?nft_hash=${nftHash}&state=${transferStatus}`);
    }
  };

  const receiveDonatin = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("Web", { uri: `${window.location.origin}/receiveDonation?transferId=${transferId}` });
    } else {
      navigate(`/receiveDonation?transferId=${transferId}`);
    }
  }
  return (
    <>
      <div className="collection_detail">
        {transferStatus !== 3
          ? <div className="content" onClick={goCollectionDetail}>
            {transferStatus === 2 && <div className="status-tag">转赠中</div>}
            <div className="avatar">
              <img className={transferStatus === 2 ? 'don-status' : ''} src={nftImage} alt="" />
            </div>
            <div className="text">
              <div className="collection-name">{collectionName}</div>
              <div className="collection-date">
                <span>收藏于：</span>
                <span className="collection_date">
                  {collectTime?.slice(0, 10)}
                </span>
              </div>
            </div>
          </div>
          : <div className="content" onClick={receiveDonatin}>
            <div className="status-tag  receive-tag">待接收</div>
            <div className="avatar">
              <img src={nftImage} alt="" />
            </div>
            <div className="text">
              <div className="collection-name flexWrap">
                {!!associatedCollection &&
                  <span className="collection-name-tag">{associatedCollection}</span>
                }
                {`来自“${sender || ''}”的转赠`}
              </div>
              <div className="collection-date">{transferTime?.slice(0, 10)}</div>
              {!!nftNo &&
                <div className="collection-date">藏品编号：{nftNo}</div>
              }
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default CollectionComp;
