import React, {useEffect, useRef, useState} from "react";
import ListView1 from "@/components/ListView1";
import {Dialog, Popup, Toast} from 'antd-mobile'
import './shopRecordPop.scss'
import ArrayUtils from "@/utils/ArrayUtils";
import {formatDecimal, isArray, isEmptyString} from "@/utils/publicFun";
import {hideLoading, showLoading} from "@/components/loading";
import {changePurchase, deleteAllPurchaseList, getPurchaseList} from "@/api/projectManager_self";
import {useNavigate} from "react-router-dom";

let icon_shop_lajitong = require("@/assets/img/icon_shop_lajitong.png")
let icon_shop_lajitong0 = require("@/assets/img/icon_shop_lajitong0.png")
let icon_shop_no_choice = require("@/assets/img/icon_shop_no_choice.png")// 未选中
let icon_shop_choice = require("@/assets/img/icon_shop_choice.png")// 选中
let icon_shop_jia = require("@/assets/img/icon_shop_jia.png")// 加号
let icon_shop_jian = require("@/assets/img/icon_shop_jian.png")// 减号
let icon_shop_goumai = require("@/assets/img/icon_shop_goumai.png")

const JsBridge = window.JsBridge;
const ShopRecordPop = ({maskVisible, setMaskVisible, userInfo}) => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0.00);
    const [totalCount, setTotalCount] = useState(0.00);
    let [selectedPros, setSelectedPros] = useState([]);
    let [allSel, setAllSel] = useState(false); //是否全选
    // const [proInfo, setProInfo] = useState({
    //     totalPrice: 0.00,
    //     totalCount: 0,
    //     selectedPros: new Array(),
    // })
    const clearAllProsClick = () => { //清空 0=增加 1=减
        if (data.length === 0) {
            return
        }
        Dialog.confirm({
            content: '确定清空购物车?',
            onConfirm: async () => {
                try {
                    showLoading();
                    let params = {};
                    const res = await deleteAllPurchaseList(params);
                    hideLoading();
                    if (res.code !== '000000') throw res.msg;
                    setData([]);
                    setIsLoad(true)
                    setTotalCount(0);
                    setTotalPrice(0.00);
                    setSelectedPros([]);
                } catch (error) {
                    hideLoading();
                    Toast.show(error);
                }
            },
        })
    }

    useEffect(() => {
        maskVisible && loadData();
    }, [maskVisible, userInfo]);

    const clearAllData = () => {
        setData([]);
        setTotalCount(0);
        setTotalPrice(0.00);
        setSelectedPros([]);
        setIsLoad(false)
    }

    const loadData = async () => {
        clearAllData();
        try {
            showLoading();
            let params = {
                clientId: userInfo.clientId,
            };
            const res = await getPurchaseList(params);
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data.purchaseListVO && isArray(res.data.purchaseListVO)) {
                let totalPriceTem = 0.00;
                let totalCountTem = 0;
                let selectedProsTem = [];
                for (let model of res.data.purchaseListVO) {
                    model.isSel = true;
                    if (!selectedProsTem.includes(model.id, 0)) {//不包含
                        selectedProsTem.push(model.id);
                    }
                    totalPriceTem = totalPriceTem + model.price * model.goodsNum;
                    totalCountTem = totalCountTem + model.goodsNum;
                }
                setData(res.data.purchaseListVO);
                let timer = setTimeout(() => {
                    clearTimeout(timer)
                    setTotalCount(totalCountTem);
                    setTotalPrice(formatDecimal(totalPriceTem));
                    setSelectedPros(selectedProsTem);
                }, 1000);

            } else {
                setData([]);
            }
            setIsLoad(true)
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const productSelected = (item) => { //选中和非选中商品
        if (item) {
            item.isSel = !item.isSel;
        }
        // let {selectedPros} = proInfo;
        let selectedProsTem = [];
        for (let model of data) {
            if (model.isSel) {
                if (!selectedProsTem.includes(model.id, 0)) {//不包含
                    selectedProsTem.push(model.id);
                }
            }
        }
        let totalPrice = countTotalPrice();
        let totalCount = countTotalSelectedNumber();
        setTotalCount(totalCount);
        setTotalPrice(totalPrice);
        setSelectedPros(selectedProsTem);
    }

    const reducePro = async (item) => { //减商品
        if (item.goodsNum <= 1) { //要删除
            Dialog.confirm({
                content: '确定要删除该商品吗?',
                onConfirm: async () => {
                    try {
                        showLoading();
                        let params = {
                            changeType: '1',
                            id: item.id,
                        };
                        const res = await changePurchase(params);
                        hideLoading();
                        if (res.code !== '000000') throw res.msg;
                        ArrayUtils.remove(data, item);
                        let dataTem = JSON.parse(JSON.stringify(data))
                        setData(dataTem);
                        // 删除之后要重新计算
                        let timer = setTimeout(() => {
                            clearTimeout(timer);
                            productSelected()
                        }, 500);
                    } catch (error) {
                        hideLoading();
                        Toast.show(error);
                    }
                },
            })
        } else {
            try {
                showLoading();
                let params = {
                    changeType: '1',
                    id: item.id,
                };
                const res = await changePurchase(params);
                hideLoading();
                if (res.code !== '000000') throw res.msg;
                item.goodsNum -= 1;
                let dataTem = JSON.parse(JSON.stringify(data))
                setData(dataTem);
                if (item.isSel) {
                    item.isSel = false;
                    let timer = setTimeout(() => {
                        clearTimeout(timer);
                        productSelected(item)
                    }, 500);
                }
            } catch (error) {
                hideLoading();
                Toast.show(error);
            }
        }
    }

    const delPro = (item) => { //删除
        Dialog.confirm({
            content: '确定要删除该商品吗?',
            onConfirm: async () => {
                try {
                    showLoading();
                    let params = {
                        changeType: '1',
                        id: item.id,
                    };
                    const res = await changePurchase(params);
                    hideLoading();
                    if (res.code !== '000000') throw res.msg;
                    ArrayUtils.remove(data, item);
                    let dataTem = JSON.parse(JSON.stringify(data))
                    setData(dataTem);
                    // 删除之后要重新计算
                    let timer = setTimeout(() => {
                        clearTimeout(timer);
                        productSelected()
                    }, 500);
                } catch (error) {
                    hideLoading();
                    Toast.show(error);
                }
            },
        })
    }

    const addPro = async (item) => { //加商品
        if (item.goodsNum >= 5) {
            Toast.show('最多添加五件商品');
            return;
        }
        try {
            showLoading();
            let params = {
                changeType: '0',
                id: item.id,
            };
            const res = await changePurchase(params);
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            item.goodsNum += 1;
            let dataTem = JSON.parse(JSON.stringify(data))
            setData(dataTem);
            if (item.isSel) {
                item.isSel = false;
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    productSelected(item)
                }, 500);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const countTotalSelectedNumber = () => { //计算商品被选择了数量
        let count = 0;
        data.map((item, index) => {
            if (item.isSel) {
                count = count + item.goodsNum;
            }
        })
        return count;
    };

    const countTotalPrice = () => { //计算商品的总价
        let totalPrice = 0.00;
        data.map((item, index) => {
            if (item.isSel) {
                totalPrice = totalPrice + item.price * item.goodsNum;
            }
        });
        return formatDecimal(totalPrice);
    };

    const goBuy = () => {
        if (selectedPros.length > 0) {
            if (isEmptyString(userInfo.ID)) {
                Toast.show('请先实名认证');
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    setMaskVisible(false)
                    JsBridge.navigate('Verify');
                }, 2000);
                return;
            }

            let array = [];
            for (let item of data) {
                for (let item0 of selectedPros) {
                    if (item.id === item0) {
                        array.push({
                            collectionId: item.collectionId,
                            price: item.price,
                            goodsNum: item.goodsNum,
                            saleBatchId: item.saleBatchId || '',
                        });
                    }
                }
            }
            let shopParams = {};
            shopParams.prepaidListRequestList = array;
            shopParams.totalPrice = totalPrice;
            shopParams.type = '0';
            setMaskVisible(false)
            window.localStorage.setItem("shop_cart_info", JSON.stringify(shopParams));
            let timer = setTimeout(() => {
                clearTimeout(timer);
                JsBridge.push("CustomWeb", {uri: `${window.location.origin}/shopCartSalePay`})
            }, 100)
        }
    }

    const allSelClick = () => { //全选

    }

    const listItem = (item) => {
        return (
            <div className={'item_container'}>
                <div className={'shop_choice_btn'} onClick={() => productSelected(item)}>
                    <img src={item.isSel ? icon_shop_choice : icon_shop_no_choice}/>
                </div>
                <div className={'shop_img_father'}>
                    <img src={item.imageBg} className={'shop_img'}/>
                </div>
                <div className={'title_price_father'}>
                    <div className={'pro_name'}>{item.collectionName}</div>
                    <div className={'pro_price'}>{item.price}</div>
                </div>
                <div className={'add_reduce_pro_father'}>
                    {/*<img src={icon_shop_jian} className={'add_red_img'} onClick={() => reducePro(item)}/>*/}
                    {/*<span className={'add_red_title'}>{item.goodsNum}</span>*/}
                    {/*<img src={icon_shop_jia} className={'add_red_img'} onClick={() => addPro(item)}/>*/}

                    {/*<span/>*/}
                    <span className={'add_red_title'}>x{item.goodsNum}</span>
                    <img src={icon_shop_lajitong0} className={'del_lajitong'} onClick={() => delPro(item)}/>

                </div>
            </div>
        )
    }

    const closePop = () => {
        setMaskVisible(!maskVisible)
        clearAllData();
    }

    return (
        <Popup
            visible={maskVisible}
            onMaskClick={() => closePop()}
            bodyStyle={{
                backgroundColor: "transparent", minHeight: '70vh', width: '100%',
                padding: 0,
            }}>
            <div className={'shop_record_container'} style={{overflow: data.length > 0 ? 'scroll' : 'hidden'}}>
                <div className={'header_father'}>
                    <div className={'header_title_father'}>
                        <span className={'shop_car_des'}>{`购物车 (${data.length})`}</span>
                        <div className={'del_father'} onClick={() => clearAllProsClick()}>
                            <img src={icon_shop_lajitong}/>
                            <span className={'clear_des'}>清空</span>
                        </div>
                    </div>
                </div>
                <div className={'list_father'}>
                    <ListView1
                        onRefresh={() => loadData()}
                        list={data}
                        hasMore={false}
                        isLoad={isLoad}
                        isNeedRefresh={false}
                        loadMore={false}
                        ListItem={({item}) => listItem(item)}
                    />
                </div>
                <div className={'bottom_father'}>
                    {/*<div className={'all_sel_father'} onClick={()=>allSelClick()}>*/}
                    {/*    <img src={allSel?icon_shop_choice:icon_shop_no_choice} className={'shop_total_choice_img'}/>*/}
                    {/*    <span>全选</span>*/}
                    {/*</div>*/}


                    <span className={'total_title'}>总计:</span>
                    <span className={'total_money'}>¥{totalPrice + ''}</span>
                    <div className={'gobuy_father_0'}>
                        <div className={'gobuy_father'}>
                            <img src={icon_shop_goumai} alt="" className={'img_btn'}/>
                            <div className={'pro_count_father'} onClick={() => goBuy()}>
                                <span className={'pro_des'}>购买</span>
                                <span className={'pro_des'} style={{color: '#FF5E28'}}>{totalCount}</span>
                                <span className={'pro_des'}>件商品</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default ShopRecordPop;
