/*
    共创漫画分享页
    route: /share/cartoonShare
*/
import React, {useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Platform, getUrlAllParams} from "@/utils/publicFun";
import './index.scss';

import gotoBrowserImg from '@/assets/img/goto-browser.png';
import activeImg from '@/assets/img/ape-world/cartoon_share_bg.png';

const appSchema = 'gktapp://com.gkewang';
const downloadUrl = 'https://app.gkewang.com/app';

const CartoonShare = ()=> {
    //获取参数
    const {search} = useLocation()
    const param = getUrlAllParams(search.slice(1));

    const { shareType='0', homeName, homeFlag, visitName, visitFlag, num } = param || {};
    const [maskShow, setMaskShow] = useState(false);
  
    const url = '/front/activities/cartoon/cartoonHome';
    const targetUrl = `${process.env.REACT_APP_GKT_LINK}${url}`;
    const luanchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(`${targetUrl}`)}`;
  
    const openApp = () => {
      if (Platform.isWechat()) {
        setMaskShow(true);
      } else {
        window.location.href = luanchUrl;
        setTimeout(() => {
            window.location.href = downloadUrl;
        }, 3000);
      }
    }
  
    return <div className="ape-cartoon-share">
      <img src={activeImg} alt="" />
      <div className={`load-btn ${shareType === '0' ? '': 'load-btn-1'}`} onClick={() => openApp()}>
        {'下载/打开绿地G优APP'}
      </div>
      {
        maskShow && 
        <div className='mask' onClick={() => setMaskShow(false)}>
            <img src={gotoBrowserImg} alt=""/>
        </div>
      }
    </div>
}

export default CartoonShare;