import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './exchangeGuessBondSuccDialog.scss';
import {Mask} from 'antd-mobile';
import NoticeUtil from "@/utils/NoticeUtil";
import {guess_getBond_success} from "@/constant";

const ExchangeGuessBondSuccDialog = ({onClose, data, callback, title}) => { //兑换成功
    const [visible, setVisible] = useState(true);
    const handleClick = (type) => {
        setVisible(false);
        onClose && onClose();
        if (type === 1) {//去竞猜
            NoticeUtil.emit(guess_getBond_success, {type: 1});
            callback && callback()
        } else { //我知道了
            NoticeUtil.emit(guess_getBond_success, {type: 0});
            callback && callback()
        }
    }


    return <Mask className='exchange-guess-bond-mask-succ' visible={visible} onMaskClick={() => handleClick(0)}>
        <div className={`exchange-modal-common-succ`}>
            <div className={'exchange-modal-container-succ'}>
                <p className={'exchange-guess-title-succ'}>{title ? title : '兑换'}成功</p>
                <div className={'exchange-guess-title-container-succ'}>获得<span>{data}</span>张入场券</div>
                <img src={require('../../imgs/img_pop_rcq.png')} alt=""
                     className={'exchange_guess_btn_container_img_succ'}/>
                <div className={'exchange_guess_btn_container_succ'}>
                    <p onClick={() => handleClick(0)}>我知道了</p>
                    <p onClick={() => handleClick(1)}>去竞猜</p>
                </div>
            </div>
        </div>
    </Mask>
}

ExchangeGuessBondSuccDialog.open = (data, callback, title) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<ExchangeGuessBondSuccDialog onClose={close} data={data} callback={callback} title={title}/>, div);
}

export default ExchangeGuessBondSuccDialog;
