/**
 * @description 农业用地转工业用地/工业打工权限开通弹窗内容
 */
import React, { useRef, useEffect, useState } from "react";
import { showLoading, hideLoading } from "@/components/loading";
import img1 from "@/assets/img/landfi/landgy/gem.png";
import img2 from "@/assets/img/landfi/landgy/score.png";
import {
  confirmSimpleOrder,
  getPayParams,
  getUserInfo,
} from "@/api/projectManager_self";
import { throttle } from "@/utils/publicFun";
import { Toast } from "antd-mobile";

import "./index.scss";

const IType = {
  converter: 1, // 农业转工业
  authOpen: 2, // 打工权限开通
};
const PayType = {
    1: 'POINT', // 积分
    2: 'GEMSTONES', // 宝石购买
  };

export default (props) => {
  const { data = {}, onClose, successCB, type, container, mode } = props;
  const { apeInfo, priceInfo } = data;
  const [reqInfo, setReqInfo] = useState({});
  const [score, setScore] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    getSureOrderInfo();
  }, []);

  const getPayResult = (params) => {
    try {
      hideLoading();
      let timeId = setTimeout(async () => {
        Toast.show({
          content: "支付成功",
          maskClickable: false,
          afterClose: () => {
            onClose();
            successCB && successCB();
          },
        });
        timeId && clearTimeout(timeId);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

   //获取用户信息
   const queryUserInfo = async () => {
    const { code, msg, data } = await getUserInfo();
    if (code !== "000000") throw msg;
    return data;
  };

  const getSureOrderInfo = async () => {
    try {
      showLoading();
      const userInfo = await queryUserInfo()
      setReqInfo({
        maxPointUse:0,
        // saleBatchId,
        collectionId:apeInfo?.apeInfo,
        userInfo,
        defRepeatStr:priceInfo[0]?.defRepeatStr
      });
      hideLoading();
    } catch (error) {
      hideLoading();
      console.log(error)
      Toast.show(typeof error === "string" ? error : error.msg);
    }
  };

  const pay = async (params) => {
    try {
      showLoading();
      const orderInfo = await confirmSimpleOrder(params);
      if (orderInfo.code !== "000000") throw orderInfo.msg;
      if (totalPrice === 0) {
        return orderInfo.data;
      }
      const paramsInfo = await getPayParams({
        outTradeNo: orderInfo?.data?.outTradeNo || "",
      });
      hideLoading();
      if (paramsInfo.code !== "000000") throw paramsInfo.msg;
      return { ...paramsInfo.data, ...orderInfo.data };
    } catch (error) {
      hideLoading();
      Toast.show(error);
      return false;
    }
  };

  const onSubmit = async () => {
    // check app-env
    const apeNum = apeInfo.nftNo?.length  || 0
    const price = priceInfo[0]?.upgradePrice * apeNum
    const params = {
      saleBatchId: reqInfo.saleBatchId || "",
      payType: PayType[priceInfo[0]?.payType],
      source: "app",
      totalUsePoint:0,
      totalUseCash:totalPrice,
      uid: reqInfo?.defRepeatStr,
      totalNum: apeNum,
      type: '17',
      upgradeType: reqInfo?.upgradeType,
      nftNo: apeInfo.nftNo,
      activityCode:'indLand',
      productId:apeInfo.monkeyType
    };
    if(priceInfo[0]?.payType === 1){
        params.totalUsePoint = score || price
    } else if(priceInfo[0]?.payType === 0){
        params.totalUseCash = totalPrice
    } else{
        params.gemstonesNum =price
    }
    try {
      const res = await pay(params);
      if (!res) {
        return;
      }
      if (totalPrice === 0) {
        getPayResult({ outTradeNo: res.outTradeNo });
        return;
      }
    
    } catch (error) {
      console.log(error.msg || error);
      return;
    }
  };

  const throttlePayment = throttle(onSubmit, 3000);

  return (
    <div className="transteToGyApeModel">
      <img
        src={require("@/assets/img/landfi/landgy/icon_del.png")}
        alt=""
        className="model_close"
        onClick={onClose}
      />
      <h1 className="model_title">
        {type == IType.authOpen ? "工业打工权限" : "工业工地"}
      </h1>
      <div className="count_show">
        <span>{`${
          {
            1: `积分：${priceInfo[0]?.myPoint}`,
            2: `宝石：${priceInfo[0]?.myGems}`,
          }[priceInfo[0]?.payType]
        }`}</span>
      </div>
      <div className="model_conent">
        <img src={{
            1: img2,
            2: img1,
          }[priceInfo[0]?.payType]} alt="" />
        <span>{`共需消耗${priceInfo[0]?.upgradePrice * apeInfo?.nftNo?.length}${
          {
            1: "积分",
            2: "宝石",
          }[priceInfo[0]?.payType]
        }`}</span>
      </div>
      <div className="model_btns">
        <div className="btn cancel" onClick={onClose} />
        <div
          className={`btn ${type == IType.authOpen ? "confirm2" : "confirm1"}`}
          onClick={throttlePayment}
        />
      </div>
    </div>
  );
};
