import React, {useEffect, useState, useRef, createContext, useContext} from "react"
import './index.scss'
import icon_fuzhi from '@/assets/img/icon_fuzhi.png';
import {Toast} from "antd-mobile";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {getUrlAllParams, isEmptyArray, isEmptyString} from "@/utils/publicFun";
import {hideLoading, showLoading} from "@/components/loading";
import {queryTransactionHash} from "@/api/projectManager_self";
import {useLocation} from "react-router";

const Context = createContext(null);
export const BlockChainColInfo = () => { //c1144f79-8b32-56e9-be58-247f53e724cf
    const {search} = useLocation()
    const {transactionHash = ''} = getUrlAllParams(search.slice(1));
    const [data, setData] = useState({});
    useEffect(() => {
        window.JsBridge.setTitle("区块链藏品信息");
        queryTransac();
    }, [])

    const queryTransac = async () => {
        try {
            showLoading();
            const res = await queryTransactionHash({
                // transactionHash: '8f9d9c43-9e8c-6c22-e152-738f506b0560',
                transactionHash: transactionHash,
            });
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            setData(res.data);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }


    return (
        <Context.Provider value={{data}}>
            <div className={'block_chain_col_info_container'}>
                <ColMsgComponent/>
                <DelMsgComponent/>
            </div>
        </Context.Provider>
    )
}

const ColMsgComponent = () => { //藏品信息
    const [isFold, setIsFold] = useState(false);//收费折叠
    const {data,} = useContext(Context);
    const [userCollection, setUserCollection] = useState({});

    useEffect(() => {
        if (data && data.userCollection) {
            let userCollection = JSON.parse(data.userCollection);
            console.log(userCollection.collectionRelationRequest)
            setUserCollection(userCollection);
        }
    }, [data])

    const titleClick = () => {
        setIsFold(!isFold);
    }

    const rarityView = () => {
        if (isEmptyArray(userCollection?.chainComposeInfoRequestList)) {
            return null;
        }

        return userCollection.chainComposeInfoRequestList.map((item, index) => {
            let rarity = item.rarity || '';
            let isGlod = false;
            if (!isEmptyString(rarity)) {
                let rarityFloat = parseFloat(rarity) || 0
                if (rarityFloat > 5) {
                    isGlod = false;
                } else {
                    isGlod = true;
                }
            }
            return ( //  margin-left: 4vw;
                <div className={'block_chain-num_list_item'} style={{marginLeft: (index % 4 === 0) ? '4vw' : '0vw'}}
                     key={index.toString()}>
                    <div className={'item_name_des'}>{item.classifyName || ''}</div>
                    <div className={'item_name'}>{item.eigName || ''}</div>
                    <div className={`item_name_rarity ${isGlod ? 'rarity-num_glod' : ''}`}>{rarity}</div>
                </div>

            )
        })
    }


    return (
        <div className={'col_msg_container'}>
            <div className='head-image'>
                <img src={data?.nftImage}/>
            </div>

            <div className={'col_title_container'} onClick={() => titleClick()}>
                <p>藏品信息</p>
                <img
                    src={isFold ? require('@/assets/img/col_icon_right.png') : require('@/assets/img/col_icon_bottom.png')}
                    className={isFold ? 'rightImg' : 'bottomImg'}/>
            </div>
            {
                isFold ? null : <div className={'col_msg_container_top'}>
                    {
                        !isEmptyString(userCollection?.collectionName) ? <>
                            <p className={'title'}>藏品名称</p>
                            <p className={'content'}>{userCollection?.collectionName}</p>
                        </> : null
                    }
                    {
                        !isEmptyString(userCollection?.transactionHash) ? <>
                            <p className={`title marTop`}>交易哈希</p>
                            <CopyToClipboard text={userCollection?.transactionHash}
                                             onCopy={async () => {
                                                 Toast.show('复制成功');
                                             }}>
                                <div className={'del_hash_container'}>
                        <span
                            className={'account_address'}>{userCollection?.transactionHash}</span><img
                                    src={icon_fuzhi}/>
                                </div>
                            </CopyToClipboard>
                        </> : null
                    }
                    {
                        !isEmptyString(userCollection?.nftNo) ? <>
                            <p className={`title marTop`}>藏品编号</p>
                            <p className={'content'}>{userCollection?.nftNo}</p>
                        </> : null

                    }
                    {
                        !isEmptyString(userCollection?.collectionRelationRequest?.nftNo) ? <>
                            <p className={`title marTop`}>关联藏品编号</p>
                            <p className={'content'}>{userCollection?.collectionRelationRequest?.nftNo}</p>
                        </> : null

                    }
                    {
                        !isEmptyString(userCollection?.owner) ? <>
                            <p className={`title marTop`}>发行方</p>
                            <p className={'content'}>{userCollection?.owner}</p>
                        </> : null

                    }
                    {
                        !isEmptyString(userCollection?.introduced) ? <>
                            <p className={`title marTop`}>藏品介绍</p>
                            <p className={'content'}>{userCollection?.introduced}</p>
                        </> : null

                    }
                    {
                        !isEmptyString(userCollection?.feature) ? <>
                            <p className={`title marTop`}>藏品特征</p>
                            <p className={'content'}>{userCollection?.feature}</p>
                        </> : null
                    }
                    {
                        userCollection?.chainComposeInfoRequestList?.length ? <>
                            <p className={'title marTop'}>稀有度</p>
                            <div className={'rarity_container'}>
                                {rarityView()}
                            </div>
                        </> : null
                    }

                </div>
            }
        </div>
    )
}

const DelMsgComponent = () => { //交易信息
    const [isFold, setIsFold] = useState(false);//是否折叠
    const {data,} = useContext(Context);
    const [list, setList] = useState(data.list || [])

    useEffect(() => {
        if (!isEmptyArray(data.list)) {
            setList(data.list);
        }
    }, [data])

    const titleClick = () => {
        setIsFold(!isFold);
    }

    const remarkState = (item) => { //交易类型：0发行，1转移，2升级，3回收
        if (item.remark === null) {
            return '--'
        } else if (item.remark === 0) {
            return '发行';
        } else if (item.remark === 1) {
            return '转移';
        } else if (item.remark === 2) {
            return '升级';
        } else if (item.remark === 3) {
            return '回收';
        }
    }

    const items = () => {
        return list.map((item, index) => {
            return (
                <div className={'del_msg_content_item'} key={index.toString()}>
                    {
                        list.length === 1 ? null : <div className={'yuan_quan'}/>
                    }
                    {
                        index === list.length - 1 ? <div className={'line_item'}/> : null
                    }
                    <p className={`title0`}
                       style={{color: '#000000', fontSize: '16px', fontWeight: 'bold', opacity: 1}}>区块链地址</p>
                    <CopyToClipboard text={item.toAddress}
                                     onCopy={async () => {
                                         Toast.show('复制成功');
                                     }}>
                        <div className={'del_hash_container'}>
                        <span
                            className={'account_address'}>{item.toAddress}</span><img
                            src={icon_fuzhi}/>
                        </div>
                    </CopyToClipboard>
                    <p className={'title0 marTop0'}>交易类型</p>
                    <p className={'content'}>{remarkState(item)}</p>
                    <p className={'title0 marTop0'}>交易时间</p>
                    <p className={'content'}>{item.updatedTime}</p>
                    <p className={'title0 marTop0'}>交易哈希</p>
                    <CopyToClipboard text={item.transactionHash}
                                     onCopy={async () => {
                                         Toast.show('复制成功');
                                     }}>
                        <div className={'del_hash_container'}>
                        <span
                            className={'account_address'}>{item.transactionHash}</span><img
                            src={icon_fuzhi}/>
                        </div>
                    </CopyToClipboard>
                </div>
            )
        })
    }

    return (
        <div className={'del_msg_container'}>
            <div className={'col_title_container'} onClick={() => titleClick()}>
                <p>交易信息</p>
                <img
                    src={isFold ? require('@/assets/img/col_icon_right.png') : require('@/assets/img/col_icon_bottom.png')}
                    className={isFold ? 'rightImg' : 'bottomImg'}/>
            </div>
            {
                isFold ? null : <div className={'del_msg_content'}>
                    {items()}
                    {
                        list.length > 1 ? <div className={'line'}/> : null
                    }
                </div>
            }
        </div>
    )
}
