import React, {useState, useEffect, useRef} from 'react'
import NFTHeader from "@/components/NFTHeader"
import styles from './styles.module.scss'
import ListView from "@/components/AntListView";
import {hideLoading, showLoading} from "@/components/loading";
import {getQuizCouponMainInit} from "@/api/projectManager_self";
import {Toast} from "antd-mobile";
import {convertToChineseNumeral, isEmptyArray} from "@/utils/publicFun";

const PrizeRecord = (props) => {


    const [dataList, setDataList] = useState([])
    const [selData, setSelData] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const currentPage = useRef(1);

    const getData = () => {
        // setDataList([1, 2, 3, 4, 5, 6, 7, 8, 9, 0,])
    }

    useEffect(() => {
        props.prizeRecordVisible && loadData();
    }, [props.prizeRecordVisible])


    const loadData = async () => {
        try {
            showLoading()
            const res = await getQuizCouponMainInit({currentPage: currentPage.current, pageSize: 10})
            // console.log('res---',res)
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            let dataTem = res.data || {};
            let records = dataTem?.records || [];
            if (currentPage.current > 1) {
                setDataList([...dataList, ...records]);
            } else {
                setDataList(records);
                setSelData([]);
            }
            setHasMore(records.length >= 10);
            setIsLoad(true);
        } catch (error) {
            Toast.show(error);
        }
    }

    const loadMore = async () => {
        currentPage.current = currentPage.current + 1
        await loadData()
    }

    const winString = (item) => {
        let result = item.result;
        if (result === -1) {
            return '输';
        } else if (result === 0) {
            return '平局';
        } else if (result === 1) {
            return '赢';
        } else {
            return '未开奖';
        }
    }

    const roundView = (item) => {//对应的轮次
        if (isEmptyArray(item.pquizzesBaseRecordRespList)) {
            return null
        }
        return item.pquizzesBaseRecordRespList.map((item, index) => { //结果 1赢 -1输 0平局，空未开奖
            return (
                <div key={index.toString()}>
                    <div className={styles.sessionA}>
                        <p className={styles.des}>{`${item.option}.投入${item.amount}张入场券`}</p>
                        <p className={item.result === 1 ? styles.win : styles.fail}>{winString(item)}</p>
                    </div>
                </div>
            )
        });
    }

    const sessionView = (item) => {

        if (isEmptyArray(item.pquizzesRoundRecordList)) {
            return null
        }

        return item.pquizzesRoundRecordList.map((item, index) => {
            return (
                <div className={styles.sessionContainer} key={index.toString()}>
                    <p className={styles.session}>{`第${item.round}轮`}</p>
                    {roundView(item)}
                </div>
            )
        })
    }

    const getTitle = (item) => {
        let t1 = '';
        let prize_type = item.prizeType || '0';
        let amount = item.count;
        if (prize_type === '1') {
            t1 = `30元变异无聊猿Lite优惠券x${amount}`;
        } else if (prize_type === '2') {
            t1 = `60元变异无聊猿Lite优惠券x${amount}`;
        } else if (prize_type === '3') {
            t1 = `100元变异无聊猿Lite优惠券x${amount}`;
        } else if (prize_type === '4') {//碎片
            t1 = `变异无聊猿Lite碎片x${amount}`;
        } else if (prize_type === '5') {//变异无聊猿兑换券
            t1 = `变异无聊猿Lite兑换券x${amount}`;
        } else if (prize_type === '6') {//史诗款兑换券
            t1 = `史诗款兑换券x${amount}`;
        }
        return t1;
    }

    const prizesView = (item) => {
        if (isEmptyArray(item.prizeList)) {
            return null
        }
        return item.prizeList.map((item1, index) => {
            return (
                <p key={index.toString()}>{getTitle(item1)}</p>
                // <p>变异无聊猿碎片x2</p>
                // <p>60元变异无聊猿优惠券x2</p>
            )
        })

    }

    const renderItem = (item) => {

        return (
            <div className={styles.list_item}>
                <div className={styles.top}>
                    <p>{`第${convertToChineseNumeral(item.session || 0)}场`}</p>
                    <p>{`投入${item.amount || 0}张入场券`}</p>
                </div>
                {sessionView(item)}
                <div className={styles.line}/>
                {
                    isEmptyArray(item.prizeList) ? null : <div className={styles.bottom}>
                        <p>奖品</p>
                        <div className={styles.prizes}>
                            {prizesView(item)}
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <NFTHeader midText="竞猜/奖品记录" bgColor="#fff" leftClick={() => props.leftClick()}/>
            <div className={styles.epic_boring_ape_container}>
                <ListView
                    dataSource={dataList}
                    renderItem={renderItem}
                    onRefresh={() => {
                        currentPage.current = 1;
                        loadData()
                    }}
                    style={{paddingTop: 120}}
                    hasMore={hasMore}
                    onEndReached={loadMore}
                    isLoad={isLoad}
                />
            </div>
        </div>
    )
}
export default PrizeRecord;
