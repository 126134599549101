import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import arrow from '@/assets/img/landfi/select-arrow.png';

const DropdownSelect = ({title = '等级', onChange, options = [], defaultValue = 0}) => {
  const [showContent, setShowContent] = useState(false);
  const [currentSelect, setCurrentSelect] = useState(options[0]);
  const domRef = useRef();

  useEffect(() => {
    const findItem = options.find(item => item.value === defaultValue);
    setCurrentSelect(findItem);
  }, [defaultValue])

  useEffect(() => {
    if (defaultValue) return;
    setCurrentSelect(options[0]);
  }, [options])

  const showSelect = () => {
    domRef.current?.scroll(0, 0)
    setShowContent(!showContent);
  }

  const onSelect = (item) => {
    setCurrentSelect(item);
    setShowContent(false);
    onChange && onChange(item);
  }

  return <div className={styles.dropdown_select}>
    <div className={styles.dropdown_title}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content_box}>
        <div className={styles.content_top} onClick={showSelect}>
          <span>{currentSelect?.label}</span>
          <img className={showContent ? styles.rotate: ''} src={arrow} alt="" />
        </div>
        <div className={`${styles.dropdown_content} ${showContent ? styles.show: styles.hidden}`} ref={domRef}>
          {
            options.length > 0 && options.map((item, index) => {
              return <div className={styles.dropdown_content_item} key={index} onClick={() => onSelect(item)}>{item.label}</div>
            })
          }
        </div>
      </div>
    </div>
  </div>
}

export default DropdownSelect;