import React, { useEffect, useState, useRef } from "react";
import { Mask } from "antd-mobile";
import styles from "./styles.module.scss";
import ListView from "@/components/AntListView";
import { Toast } from "antd-mobile";
import close from "@/assets/img/landfi/icon_del.png";
import { userGemStonesDetail } from "@/api/guland";
import { hideLoading, showLoading } from "@/components/loading";

const RentOut = ({ visible, onclose, container }) => {
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  useEffect(() => {
    getList(true);
  }, []);
  const getList = async (isRefresh,loading = true) => {
    try {
      loading && showLoading()
      if (isRefresh) {
        currentPage.current = 1;
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
      };
      const res = await userGemStonesDetail(params);
      loading && hideLoading()
      if (res.code !== "000000") throw res.msg;
      if (currentPage.current === 1) {
        setList(res?.data?.list || []);
      } else {
        setList([...list, ...res?.data?.list]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.list?.length >= pageSize.current);
    } catch (error) {
      console.log(error);
      Toast.show(error);
    }
  };
  return (
    <Mask
      opacity="thick"
      className={styles.gem_detail_out_mask}
      visible={visible}
      destroyOnClose
    >
      <div
        className={styles.gem_detail_out}
      >
        <img
          className={styles.close}
          src={close}
          alt=""
          onClick={() => onclose()}
        />
        <div className={styles.box}>
          {
            <ListView
              dataSource={list}
              hasMore={hasMore}
              style={{ paddingTop: 20 }}
              renderItem={(item, index) => {
                return (
                  <div key={item} className={styles.box_item}>
                    <div className={styles.title}>{item?.eventDesc}</div>
                    <div className={styles.date}>{item?.changeTime}</div>
                    <div className={styles.info}>{`${item?.changeType === 1?'+':'-'}${item?.changeCount}`}</div>
                  </div>
                );
              }}
              onEndReached={async () => {
                await getList(false,false);
              }}
              isLoad={isLoad}
              customStyle={{ imgStyle: { width: 150, height: 150 } }}
            />
          }
        </div>
      </div>
    </Mask>
  );
};

export default RentOut;
