import React, {useEffect, useState} from "react";
import "./nftSetting.scss"
import {Modal, Toast} from "antd-mobile";
import NFTHeader from "@/components/NFTHeader";
import {hideLoading, showLoading} from "@/components/loading";
import {getCollection, getUserCollectionsSetting, setUserIsShowTradeinfo} from "@/api/projectManager_self";
import DonationModal from "@/components/donationModal";
import clickThrottle from "@/utils/clickThrottle";

export const NftSetting = () => {
    const [data, setData] = useState([
        {title: '藏品交易密码设置', type: 'click', id: 0},
        {title: '区块链交易及资产隐藏', type: 'switch', id: 1},
    ])
    const [isHasPassword, setIsHasPassword] = useState(false); //是否有设置密码
    const [isShowTradeinfo, setIsShowTradeinfo] = useState(true); //是否显示交易信息
    const [modalVisible, setModalVisible] = useState(false);


    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        try {
            showLoading();
            const res = await getUserCollectionsSetting({});
            hideLoading();
            const {code, msg, data} = res;
            if (code !== '000000') throw msg;
            const {isHasPassword, isShowTradeinfo} = data;
            setIsHasPassword(isHasPassword);
            setIsShowTradeinfo(isShowTradeinfo);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const itemClick = (item) => {
        if (item.type === 'click') { ////0=密码设置 1=重置密码
            if (isHasPassword) { //重置密码
                window.JsBridge.push("Web", {uri: `${window.location.origin}/PwdResert`});
            } else {//设置密码
                window.JsBridge.push("Web", {uri: `${window.location.origin}/pwdSetting`});
            }

            window.JsBridge.addListener().then((result) => {
                loadData();
            });
        }
    }

    const setSwitch = () => {
        if (isShowTradeinfo) {
            setModalVisible(true);
        } else {
            onSwitch();
        }
    }

    const dismissModal = () => {
        setModalVisible(false)
        onSwitch();
    }

    const onSwitch = async () => {
        if (!clickThrottle) {
            return
        }
        try {
            showLoading();
            const res = await setUserIsShowTradeinfo({isShowTradeinfo: !isShowTradeinfo});
            hideLoading();
            const {code, msg} = res;
            if (code !== '000000') throw msg;
            setIsShowTradeinfo(!isShowTradeinfo);
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    const items = () => {
        return data.map((item, index) => {
            return <div className={'item_container'} key={index.toString()}
                        onClick={() => itemClick(item)}>
                <p className={'item_title'}>{(isHasPassword && item.id === 0) ? "藏品交易密码修改" : item.title}</p>
                <div className={'item_right'}>
                    {
                        item.type === 'click' ? <div className={'item_right_type_0'}>
                            {
                                isHasPassword ? '' : <p>未设置</p>
                            }
                            <img src={require('@/assets/img/icon/icon_xiangqing.png')} alt=""/>
                        </div> : <img
                            src={!isShowTradeinfo ? require('@/assets/img/icon_switch_on.png') : require('@/assets/img/icon_switch_off.png')}
                            className={'switch_item'} onClick={() => setSwitch()}/>
                    }
                </div>

                <DonationModal
                    title="友情提示"
                    content="隐藏后别人将不会通过区块链地址查询到你的交易记录及资产"
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    leftBtnText={'取消'}
                    rightBtnText={'隐藏'}
                    rightBtnCB={() => dismissModal()}
                />
            </div>
        })
    }

    return (
        <div className={'nft_setting_container'}>
            <NFTHeader midText={'数字藏品设置'} bgColor={'white'}/>
            {items()}
        </div>
    )
}
