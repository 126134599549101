import React, {useState, useEffect, useRef} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import NFTHeader from "@/components/NFTHeader";
import styles from './styles.module.scss';
import {Toast} from "antd-mobile";
import {getUrlAllParams} from "@/utils/publicFun";
import {getWealth, goodsExchange} from "@/api/projectManager_self";
import {hideLoading, showLoading} from "@/components/loading";


export default () => { //虚拟兑换
    const {search} = useLocation();
    const params = getUrlAllParams(search.slice(1));
    const goodsId = params.id;
    const goodsName = decodeURIComponent(params.goodsName);
    const goodsImg = params.goodsImg || '';
    const suipian = parseInt(params.suipian) || 0;
    const bianyi = parseInt(params.bianyi) || 0;
    const shishi = parseInt(params.shishi) || 0;
    const [count, setCount] = useState(1);
    const typeRef = useRef(0);
    const [data, setData] = useState([
        {title: '变异无聊猿Lite兑换券', des: '兑换券', isSel: false, id: 0, count: 0, isEnable: false, type: 3},
        {title: '变异无聊猿Lite碎片', des: '碎片', isSel: false, id: 1, count: 0, isEnable: false, type: 2},

    ]);
    useEffect(() => {
        loadData();
        // console.log('params---',params)
    }, []);


    const loadData = async () => {
        try {
            const res = await getWealth({})
            if (res.code !== '000000') throw res.msg;
            if (res.data && res.data) {
                let info = res.data?.info || {};
                let p2Tem = 0;//砖石 碎片
                let p3Tem = 0;//蓝色 变异
                let p1Tem = 0;//红色的 史诗款
                for (const item of info) {
                    if (item.type === 1) { //  shisi
                        p1Tem = item.amount;
                    } else if (item.type === 2) {//碎片
                        p2Tem = item.amount;
                        data[1].count = item.amount;
                        data[1].isEnable = item.amount > 0;
                    } else if (item.type === 3) {// 变异 兑换
                        p3Tem = item.amount;
                        data[0].count = item.amount;
                        data[0].isEnable = item.amount > 0;
                    }
                    setData([...data]);
                }
            }
        } catch (error) {
            Toast.show(error);
        }
    }


    const addOrReduce = (type) => {//0=减 1=加
        if (type === 1) { //加
            if (count >= 5) {
                Toast.show({content: '最多只能兑换5张', position: 'top'})
                return
            }
            setCount((prevCount) => prevCount + 1);
        } else { //减去
            if (count < 2) {
                Toast.show({content: '至少兑换一张', position: 'top'})

                return
            }
            setCount((prevCount) => prevCount - 1);
        }
    }

    const itemClick = (item) => {
        if (!item.isEnable) {
            return;
        }
        if (item.isSel) {
            return
        }
        for (let i = 0; i < data.length; i++) {
            let itemTem = data[i];
            if (item.id === itemTem.id) {
                itemTem.isSel = true;
            } else {
                itemTem.isSel = false;
            }
        }
        typeRef.current = item.type;
        setData([...data])
    }


    const duihuanClick = async () => {
        if (typeRef.current === 0) {
            return;
        }
        let params = {goodsId: goodsId, num: count, spendItem: {type: typeRef.current, num: count}};
        console.log(params)
        // 支付对象类型：2普通碎片，3变异兑换券
        try {
            showLoading()
            const res = await goodsExchange(params);
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            Toast.show('兑换成功');
            setTimeout(()=>{
                window.JsBridge.back();
            },2000)

        } catch (error) {
            Toast.show(error);
            hideLoading();
        }
    }

    const list = () => {
        return data.map((item, index) => {
            return <div className={styles.listContainer} key={index.toString()} onClick={() => itemClick(item)}>
                <p className={styles.itemDes}>{item.title}</p>
                <div className={styles.item}>
                    <span>共</span>
                    <span>{item.count}</span>
                    <span>{item.des}</span>
                </div>
                <img
                    src={item.isEnable ? (item.isSel ? require('@/assets/img/icon/icon-radio-checked.png') : require('@/assets/img/icon/icon-radio.png')) : require('@/assets/img/icon/icon_dis.png')
                    }
                    alt=""/>
            </div>
        })
    }

    let p2Tem = 0;//砖石 碎片
    let p3Tem = 0;//蓝色 变异
    let p1Tem = 0;//红色的 史诗款

    return (
        <div className={styles.container}>
            <NFTHeader midText={'确认订单'} bgColor={'#fff'}/>
            <div className={styles.topContainer}>
                <p className={styles.des}>选择兑换方式</p>
                {list()}
            </div>
            <div className={styles.middleContainer}>
                <img src={goodsImg} alt="" className={styles.proImg}/>
                <div className={styles.right}>
                    <p className={styles.title}>{goodsName}</p>
                    <div className={styles.bottom}>

                        {suipian > 0 ? <div className={styles.bottomZS}>
                            <img src={require('../../../imgs/icon_sp.png')} alt=""/>
                            <p>{suipian}</p>
                        </div> : null}

                        { (suipian && bianyi)?<span className={styles.item_huo}>或</span>:null}
                        {bianyi > 0 ? <div className={styles.bottomZS}>
                            <img src={require('../../../imgs/icon_by_dh.png')} alt="" style={{marginLeft: '1px'}}/>
                            <p>{bianyi}</p>
                        </div> : null}
                        {(bianyi && shishi)?<span className={styles.item_huo}>或</span>:null}
                        {shishi > 0 ? <div className={styles.bottomZS}>
                            <img src={require('../../../imgs/icon_ss_dh.png')} alt="" style={{marginLeft: '1px'}}/>
                            <p>{shishi}</p>
                        </div> : null}

                        <div className={styles.addContainer}>
                            <img
                                src={count <= 1 ? require('../../../imgs/btn_jian_dis.png') : require('../../../imgs/btn_jian_nor.png')}
                                alt=""
                                onClick={() => addOrReduce(0)}/>
                            <p>{count}</p>
                            <img
                                src={count >= 5 ? require('../../../imgs/btn_jia_dis.png') : require('../../../imgs/btn_jia_nor.png')}
                                alt=""
                                onClick={() => addOrReduce(1)}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.bottomBtn} onClick={() => duihuanClick()}>
                <p>立即兑换</p>
            </div>
        </div>
    )
}
