import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom'
import {Swiper} from "antd-mobile";
import clickThrottle from "@/utils/clickThrottle";
import NFTHeader from "@/components/NFTHeader";
import {Platform} from "@/utils/publicFun";
import NoticeUtil from "@/utils/NoticeUtil";
import styles from './styles.module.scss'
import HuiShouCollection from "@/view/guessingActy/exchangeRecord/component/huiShouCollection";
import BuyCollection from "@/view/guessingActy/exchangeRecord/component/buyCollection";

const ExchangeRecord = () => {

    return (
        <div className={styles.get_coupon_container}>
            <NFTHeader midText={'兑换记录'} bgColor={'#fff'} backType={2}/>
            <ContentView/>
        </div>
    )
}


const ContentView = (props) => {

    const swiperRef = useRef(null);
    const clickIndex = (index) => {
        swiperRef.current && swiperRef.current.swipeTo(index);
    }

    return (
        <div className={styles.cartoon_record_com}>
            <HeaderView clickIndex={clickIndex}/>
            <div style={{
                width: '100%',
                display: 'flex',
                overflow: 'hidden',
                flex: 1,
                paddingBottom: Platform.isApp() ? 50 : 30,
            }}>
                <Swiper
                    style={{flex: 1, display: 'flex'}}
                    direction='horizontal'
                    indicator={() => null}
                    ref={swiperRef}
                    allowTouchMove={false}
                    defaultIndex={0}>
                    <Swiper.Item style={{height: '100%'}}>
                        <HuiShouCollection/>
                    </Swiper.Item>
                    <Swiper.Item style={{height: '100%'}}>
                        <BuyCollection/>
                    </Swiper.Item>
                </Swiper>
            </div>
        </div>
    )
}

const HeaderView = (props) => {
    const [selIndex, setSelIndex] = useState(0);
    const currentIndex = useRef(0);

    useEffect(() => {
        NoticeUtil.on('currentIndexChange', (res) => {
            setSelIndex(res);
        })

    }, [])

    const clickIndex = (index) => {
        if (!clickThrottle(1000)) {
            return
        }
        if (currentIndex.current === index) {
            return;
        }
        currentIndex.current = index;
        setSelIndex(index);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            props.clickIndex && props.clickIndex(index)
        }, 100)
    }

    return (
        <div className={styles.invite_record_header_container}>
            <div className={styles.invite_record_header}>
                <div className={styles.heder_title_com}
                     onClick={() => clickIndex(0)}>
                    <div className={selIndex === 0 ? styles.header_title_sel : styles.header_title_dis}>回收藏品</div>
                </div>
                <div className={styles.heder_title_com}
                     onClick={() => clickIndex(1)}>
                    <div className={selIndex === 1 ? styles.header_title_sel : styles.header_title_dis}>购买</div>
                </div>
            </div>
        </div>

    )
}

export default ExchangeRecord;
