import React, { useRef, useEffect, useState, useContext } from "react";
import { Toast } from "antd-mobile";
import { showLoading, hideLoading } from "@/components/loading";
import {
  getPrepayDetail,
  createOrder,
  queryPayResult,
  checkNotpay,
  getUserInfo,
  getPayParams,
} from "@/api/projectManager_self";
import { BackBtn, GreenBtn } from "../../components/button";
import { AliPayIcon, WxPayIcon } from "../../components/common";
import { Radio, Switch, Input } from "antd-mobile";
import { LandMap } from "../../components/land";
import { sureOrderInfo } from "@/api/guland";
import { LandDetailContext } from "../context";
import { getUpgradeType } from "../../utils";
import { throttle, computeNumber, round } from "@/utils/publicFun";
import appService from '@/utils/appService';
import './order.scss';

//有无待支付订单
const checkIsHasNotPay = async (collectionType) => {
  const { code, msg, data } = await checkNotpay({
    collectionType: collectionType || "",
  });
  if (code !== "000000") throw msg;
  if (data?.notPayOrderCnt) {
    Toast.show("您有待付款的订单，请先完成上次订单");
    return true;
  }
};
// 预支付订单
const getPayDetail = async (collectionId, saleBatchId) => {
  const { code, msg, data } = await getPrepayDetail({
    collectionId,
    saleBatchId,
  });
  if (code !== "000000") throw msg;
  return data;
};

export default ({ onClose, info }) => {
  const { level, currentLevel, price } = info;
  const [score, setScore] = useState("");
  const [reqInfo, setReqInfo] = useState({});
  const [needScore, setNeedScore] = useState(false);
  const [payMethod, setPayMethod] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [payTypeList, setPayTypeList] = useState([])
  const { landInfo, closeLandDetail, upgradeCallBack, refreshUpgradeInfo, upLandInfo } = useContext(LandDetailContext);
  const installedWX = useRef()

  const userPoints = reqInfo?.userInfo?.point || 0
  const canUseMaxPoints = reqInfo.maxCanUseScores || 0
  const canUsePoints = canUseMaxPoints > userPoints ? userPoints: canUseMaxPoints

  useEffect(()=>{
    if(window.JsBridge.hasWebViewBridge()){
      window.JsBridge.nativeModules("UMShareModule", "isInstalledWx").then(function (res) {
        installedWX.current = res
      })
    }
  },[])

  useEffect(() => {
    getSureOrderInfo();
    getPayTypeList()
  }, []);

  useEffect(() => {
    let allPrice = price
    if (needScore) {
      allPrice = computeNumber(price, '-', computeNumber(Number(score), '/', (reqInfo?.payDetail?.pointRatio || 100)).result).result
    }
    !needScore && setScore('')
    setTotalPrice(allPrice)
  }, [needScore, score])

  const getPayTypeList = async () => {
    const result = await appService('500001', { resource: 'mall' }, false);
    if (result.status > 0) {
      console.log('result=====>', result);
      setPayTypeList(result?.data?.reverse() || [])
    }
  }

  const getPayResult = (params) => {
    try {
      showLoading();
      let timeId = setTimeout(async () => {
        const {code, msg, data} = await queryPayResult(params);
        hideLoading();
        if (code === '000000') {
          Toast.show({
            content: '支付成功',
            maskClickable: false,
            afterClose: () => {
              onClose()
              if (landInfo.from === 1) {
                closeLandDetail()
                upgradeCallBack({
                  separateNo: landInfo.separateNo,
                  collectionId: landInfo.collectionId,
                  upgradeType: reqInfo?.upgradeType,
                });
              } else if (landInfo.from === 2) {
                // refreshUpgradeInfo()
                const { nftNo, collectionType: collectionId, image: nftImage } = data?.[0] || {}
                upLandInfo({nftNo, collectionId, nftImage})
              }
            }
          })
        } else {
          Toast.show(msg);
        }
        timeId && clearTimeout(timeId);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  }

  //获取用户信息
  const queryUserInfo = async () => {
    const {code, msg, data} = await getUserInfo();
    if (code !== '000000') throw msg;
    return data;
  }

  const getSureOrderInfo = async () => {
    try {
      showLoading();
      // TODO  获取前置参数
      const upgradeType = getUpgradeType(currentLevel, level);
      const res = await sureOrderInfo({
        upgradeType,
        collectionId: landInfo.collectionId,
      });
      const {
        maxPointUse: maxCanUseScores,
        collectionId,
        saleBatchId,
      } = res.data || {};
      const userInfo = await queryUserInfo()
      const payDetail = await getPayDetail(landInfo.collectionId, saleBatchId);
      const notPay = await checkIsHasNotPay(payDetail?.collectionType);
      setReqInfo({
        maxCanUseScores,
        saleBatchId,
        collectionId,
        payDetail,
        upgradeType,
        userInfo,
        notPay,
      });
      hideLoading();
    } catch (error) {
      hideLoading();
      Toast.show(typeof error === "string" ? error : error.msg);
    }
  };

  const onScore = (val) => {
    if (val === score) {
      return;
    }
    if (!/^[1-9]\d*$/.test(val)) {
      setScore("");
    } else {
      if (Number(val) > canUsePoints) {
        Toast.show(`最多可使用${canUsePoints}积分`)
      }
      const newVal = Number(val) > canUsePoints ? canUsePoints: val;
      setScore(newVal);
    }
  };

  const pay = async (params) => {
    try {
      showLoading();
      const orderInfo = await createOrder(params);
      if (orderInfo.code !== "000000") throw orderInfo.msg;
      if (totalPrice === 0) {
        hideLoading();
        return orderInfo.data;
      }
      const paramsInfo = await getPayParams({
        outTradeNo: orderInfo?.data?.outTradeNo || "",
      });
      hideLoading();
      if (paramsInfo.code !== "000000") throw paramsInfo.msg;
      return { ...paramsInfo.data, ...orderInfo.data };
    } catch (error) {
      hideLoading();
      Toast.show(error);
      return false;
    }
  };

  const onSubmit = async () => {
    // check app-env
    if (!window.JsBridge?.hasWebViewBridge()) {
      Toast.show("请到APP中支付");
      return;
    }
    if (!payMethod && totalPrice > 0) {
      Toast.show("请选择支付方式");
      return;
    }
    if (reqInfo.notPay) {
      Toast.show("您有待付款的订单，请先完成上次订单");
      return;
    }
    if (!reqInfo.saleBatchId) {
      Toast.show("系统异常");
      return;
    }
    const params = {
      saleBatchId: reqInfo.saleBatchId || "",
      collectionId: reqInfo.collectionId,
      usePoint: score || 0,
      useCash: totalPrice,
      payType: totalPrice === 0 ? 'POINT': payMethod.toUpperCase(),
      source: "app",
      uid: reqInfo?.payDetail?.defRepeatStr,
      num: 1,
      type: 3,
      upgradeType: reqInfo?.upgradeType,
      nftNo: landInfo.landId,
    };
    try {
      const res = await pay(params);
      if (!res) {
        return;
      }
      if (totalPrice === 0) {
        getPayResult({ outTradeNo: res.outTradeNo });
        return;
      }
      // TODO 微信支付
      if (params.payType === "WXPAY") {
        if (!installedWX.current) {
          Toast.show("请先安装微信");
          return;
        }
        const result = await window.JsBridge.wxPay({
          partnerId: res.partnerId || "",
          prepayId: res.prepayId || "",
          nonceStr: res.nonceStr || "",
          timeStamp: res.timeStamp || "",
          sign: res.sign || "",
          appId: res.appId,
        });
        // 支付失败
        if (result.errCode === -2) {
          Toast.show({
            content: "支付取消",
            maskClickable: false,
            afterClose: () => {
              onClose()
            }
          });
        } else if (result.errCode === 0) {
          // 支付回调
          let payParams = {
            errStr: result?.errStr,
            errCode: result?.errCode,
            outTradeNo: res.outTradeNo,
            source: "wxpay",
          };
          getPayResult(payParams);
        } else {
          Toast.show("支付发生错误  code: " + (result?.errCode || " "));
        }
      }
      // TODO 支付宝支付
      if (params.payType === "ALIPAY") {
        // 支付宝支付
        const payResult = res.payResult;
        const result = await window.JsBridge.aliPay(payResult);
        // 支付失败
        if (result.resultStatus == 6001) {
          Toast.show({
            content: "支付取消",
            maskClickable: false,
            afterClose: () => {
              onClose()
            }
          });
        } else if (result.resultStatus == 62000) {
          Toast.show("客户端未安装支付通道依赖的服务");
        } else if (result.resultStatus == 9000) {
          // 支付回调
          let resFmt = JSON.parse(result.result || "{}");
          let payParams = {
            errStr: resFmt?.alipay_trade_app_pay_response?.msg || "",
            errCode: resFmt?.alipay_trade_app_pay_response?.code || "",
            outTradeNo: res.outTradeNo || "",
            source: "alipay",
          };
          getPayResult(payParams);
        } else {
          Toast.show("支付发生错误  code: " + (result?.resultStatus || " "));
        }
      }
    } catch (error) {
      console.log(error.msg||error)
      return;
    }
  };

  const throttlePayment = throttle(onSubmit, 3000);

  const maxUsePoints = () => {
    return round(computeNumber(canUsePoints, '/', (reqInfo?.payDetail?.pointRatio || 100)).result, 2)
  }

  return (
    <div className="land-up-order">
      <div className="land-up-order__title">确认订单</div>
      <div>
        <div>
          <div>
            <div>
              {React.cloneElement(LandMap[level], {
                scale: 1.2,
              })}
            </div>
            <div>
              <h1>
                {currentLevel.toUpperCase()}-{level.toUpperCase()}级土地
              </h1>
              <h2>¥{round(price, 2)}</h2>
            </div>
          </div>
          {reqInfo?.maxCanUseScores > 0 ? <div>
            <div>
              <div>使用积分</div>
              <div>
                <Switch value={needScore} onChange={setNeedScore}></Switch>
              </div>
            </div>
            {needScore ? (
              <>
                <div>
                  <div>
                    共 {reqInfo?.userInfo?.point} 积分, 最多可抵扣
                    {maxUsePoints()}元
                  </div>
                  <span>-<span>￥</span>{(score / 100)?.toFixed(2)}</span>
                </div>
                <div>
                  <Input
                    value={score}
                    type="number"
                    disabled={reqInfo?.userInfo?.point <= 0}
                    onChange={onScore}
                    placeholder="请输入使用积分"
                  ></Input>
                </div>
              </>
            ): null}
          </div>: null}
        </div>
        <div>
          <div>
            <div>
              <div>商品总价</div>
              <div><span>￥</span>{round(price, 2)}</div>
            </div>
            {reqInfo?.maxCanUseScores > 0 ? <div>
              <div>积分抵扣</div>
              <div>-<span>￥</span>{round((score / 100), 2)}</div>
            </div>: null}
            <div>
              <div>合计应付</div>
              <div><span>￥</span>{round(totalPrice, 2)}</div>
            </div>
          </div>

          <div>
            <h1>选择支付方式</h1>
            <Radio.Group value={payMethod} onChange={setPayMethod}>
              {
                payTypeList.length > 0 && payTypeList.map((payItem, index) => {
                  return <div className="land-up-order__selectpay" key={index}>
                    {payItem.payCode === 'Alipay' && <div>
                      <label htmlFor="alipay">
                        <AliPayIcon></AliPayIcon>
                        <span>支付宝</span>
                      </label>
                      <Radio id="alipay" value="alipay"></Radio>
                    </div>}
                    {payItem.payCode === 'WeChat' && <div>
                      <label htmlFor="wxpay">
                        <WxPayIcon></WxPayIcon>
                        <span>微信</span>
                      </label>
                      <Radio id="wxpay" value="wxpay"></Radio>
                    </div>}
                  </div>
                })
                }
            </Radio.Group>
          </div>
        </div>
      </div>
      <div>
        <p>
          <span>合计</span>
          <span>￥</span>
          <span>{round(totalPrice, 2)}</span>
        </p>
        <GreenBtn onClick={throttlePayment}>提交订单</GreenBtn>
      </div>
      <BackBtn onClick={onClose}></BackBtn>
    </div>
  );
};
