import axios from "axios";
import { Toast } from "antd-mobile"
import sha256 from 'crypto-js/sha256';
import {hideLoading} from "@/components/loading";
import qs from 'query-string';
import {isIOS} from "@/utils/publicFun";

const { isUnityHome } = qs.parse(window.location.search);

function randomString(e) {
  e = e || 36;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
  a = t.length,
  n = "";
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n
}

function hashcode(str) {
  var hash = 0, i, chr, len;
  if (str.length === 0) return hash;
  for (i = 0, len = str.length; i < len; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

const requests = axios.create({
  baseURL: process.env.REACT_APP_BASE_SELF,
  // baseURL: `https://nftapi-test.greenlandgk.cn`,
  timeout: "100000",
});

requests.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
requests.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    config.headers.token = window.localStorage.getItem('token') || "";
    config.headers.clientId = userInfo?.clientId || '';
    let timestamp = new Date().getTime()
    let nonce = randomString()
    config.headers.timestamp = timestamp
    config.headers.nonce = nonce
    const count = Math.abs(hashcode(nonce) % 36) || 8;
    let sign = sha256(config.headers.clientId + timestamp.toString() + nonce.slice(-count)).toString().toUpperCase()
    config.headers.sign = sign
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器;
requests.interceptors.response.use(
  (res) => {
    if(res.status === 200) {
      return new Promise((resolve, reject) => {
        // 登录失效--跳转到绿地登录页
        if (res.data.code === '900003' || res.data.code === '900004') {
            hideLoading();
          const encodeHref = encodeURIComponent(window.location.href)
          if (window.JsBridge.hasWebViewBridge()) {
            // app环境
            // Toast.show("请先登录") 应李龙要求取消登录提示
            if (isUnityHome === '1') {
              window.location.replace(`${process.env.REACT_APP_LOGIN_URL}${encodeHref}`)
              window.JsBridgeNew.hiddenStatusBar(false)
              window.JsBridgeNew.screenRotate(true)
              return
            }
            window.location.replace(`${process.env.REACT_APP_LOGIN_URL}${encodeHref}`)
          } else {
            // 浏览器环境
            Toast.show('请先登录')
            // if(process.env.NODE_ENV !== 'development') {
              window.location.replace(`${process.env.REACT_APP_LOGIN_URL}${encodeHref}`)
            // }
          }
        } else if (res.data.code === '600003') {
          reject('前方拥挤，请稍后再试！');
        }else if (res.data.code === '300006'){
            reject('系统升级中');
        } else if (
          (/^9/.test(res.data.code) && res.data.code !== '900001') ||
          /^3/.test(res.data.code) ||
          res.data.code === '111111'
        ) {
          reject('哎呀, 小优开小差了, 请稍后再试哦');
        } else {
          resolve(res.data);
        }
      })
    } else {
      Toast.show('请求错误，请稍后重试');
    }
  },
  (error) => {
    let errorMsg = ''
    if (/network error/gi.test(error?.message)) {
      // errorMsg = "活动火爆，请稍后重试"
      // Toast.show("活动火爆，请稍后重试")
      return Promise.resolve({code: '000000', data: {}});
    } else if (/timeout/gi.test(error?.message)) {
      errorMsg = "请求超时，请稍后重试"
      Toast.show("请求超时，请稍后重试")
    } else {
      errorMsg = "请求错误，请稍后重试"
      Toast.show('请求错误，请稍后重试')
    }
    return Promise.reject(errorMsg);
  }
);
export default requests;
