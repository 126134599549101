import React from 'react'
import './CollectionItem.scss'
import { useNavigate } from "react-router-dom"
import { round } from "@/utils/publicFun"
import moment from "moment"

const CollectionItem = (props) => {
  const navigate = useNavigate()
  const bridge = window.JsBridge

  const gotoDetail = (collection_id, sale_batch_id) => {
    if (bridge.hasWebViewBridge()) {
      bridge.push("CustomWeb", {
        uri: `${window.location.origin}/saledetail?collection_id=${collection_id}&sale_batch_id=${sale_batch_id}`
      })
    } else {
      navigate(`/saledetail?collection_id=${collection_id}&sale_batch_id=${sale_batch_id}`)
    }
  }
  const { sale_status } = props.params
  const { collection_id, cover_image, price, sale_batch_id, sale_end_time, sale_start_time, sale_title, sold_display, supply } = props.collection
  const start = new Date(sale_start_time.replace(/-/g, '/')).getTime()
  const end = new Date(sale_end_time.replace(/-/g, '/')).getTime()
  let time = new Date().getTime()

  return (
    <div className='collection_item' key={collection_id} onClick={() => gotoDetail(collection_id, sale_batch_id)}>
      <div className="avatar">
        <div className="picture">
          <img src={cover_image} alt="collection_image" />
          {
            (start && time < start) ?
              (<div className="sale-before">
                <div className="icon"></div>
                {moment(sale_start_time).format("MM-DD HH:mm") + "开售"}
              </div>)
              : ((end && time >= end) || (sold_display >= supply)) ?
                (<div className="sale-out">
                  <div className="icon"></div>
                  已售罄
                </div>) : (<div className="saleing">
                  <div className="icon"></div>
                  火热售卖中
                </div>)
          }
        </div>
      </div>
      <div className="detail-text">
        <div className="caption">{sale_title}</div>
        <div className="bt">
          <span className="num">限量: {supply}</span>
          <span className="price">￥{round(price, 2)}</span>
        </div>
      </div>
    </div>
  )
}

export default CollectionItem
