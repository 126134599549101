import React from 'react'
import styles from './modal.module.scss'
import { Modal } from 'antd-mobile'

const show = ({content = '', ...reset} = {}) => {
  return Modal.show({
    maskStyle:{background: `rgba(0, 0, 0, 0.7)`},
    content,
    bodyClassName: `${styles.my_modal}`,
    bodyStyle: {
      background: 'transparent',
      padding: 0,
      maxHeight: '100vh',
      maxWidth: '100vw',
    },
    destroyOnClose: true,
    ...reset,
  })
}

const MyModal = (props = {}) => {
  return <Modal 
    maskStyle={{background: `rgba(0, 0, 0, 0.7)`}} 
    destroyOnClose={true} 
    forceRender={false}
    {...props} 
  />
}

MyModal.show = show
MyModal.hide = Modal.clear

export default MyModal