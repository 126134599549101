import React, {useState, useEffect, useRef} from 'react';
import './style.scss';
import ListView from '@/components/AntListView';
import {hideLoading, showLoading} from "@/components/loading";
import { Toast } from 'antd-mobile';
import { partsRecyclingRecord } from '@/api/projectManager_self';

const mock = {
  code: '000000',
  msg: 'success',
  data: {
    record: [
      {
        recoveryList: [
          {
            nftNo: '123455',
            name: '绿地30周年',
          },
          {
            nftNo: '123455',
            name: '绿地30周年',
          },
          {
            nftNo: '123455',
            name: '绿地30周年',
          },
        ],
        recoveryTime: '2023-03-28 09:30:50',
        prizeType: '2',
        prizeName: '500'
      },
    ]
  }
}

const RecyclingRecord = () => {
  const [hasMore, setHasMore] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    window.JsBridge.setTitle('回收记录');
    getRecyclingRecordList();
  }, [])

  const getRecyclingRecordList = async (isRefresh=true, isLoading=true) => {
    try {
      currentPage.current = isRefresh ? 1: currentPage.current + 1;
      const params = {
        currentPage: currentPage.current,
        pageSize: pageSize.current,
      }
      isLoading && showLoading();
      const res = await partsRecyclingRecord(params);
      // const res = mock;
      isLoading && hideLoading();
      if (res.code !== '000000') throw res.msg;
      const list = res.data.record || [];
      if(currentPage.current > 1) {
        setRecordList([...recordList, ...list]);
      } else {
        setRecordList(list);
      }
      setIsLoad(true);
      setHasMore(list.length >= pageSize.current);
    } catch (error) {
      isLoading && hideLoading();
      Toast.show(error);
    }
  }

  return <div className='recycling-record-page'>
    <ListView 
      dataSource={recordList}
      renderItem={RecordItem}
      hasMore={hasMore}
      onRefresh={() => getRecyclingRecordList(true, false)}
      onEndReached={() => getRecyclingRecordList(false, false)}
      style={{paddingTop: 120}}
      isLoad={isLoad}
    />
  </div>
}

const RecordItem = (item) => {
  const { recoveryList=[], recoveryTime, prizeName, prizeType } = item || {};
  return <div className="reocrd-item">
    <div className="medicine-info">
      <span>回收时间</span>
      <span>{recoveryTime?.replace(/-/g, ".")}</span>
    </div>
    <div className="compose-material">
      <div className="right-material right">
        {recoveryList.map((item, index) => {
          return <span key={`material-${index}`}>{item.name} {item.nftNo}</span>
        })}
      </div>
    </div>
    <div className="receive-price">{prizeType === '1' ? '获得刮奖券一张': `获得${prizeName}积分`}</div>
  </div>
}

export default RecyclingRecord;