import React, {useState} from "react";
import {Platform} from "@/utils/publicFun";
import './style.scss';
import gotoBrowserImg from '@/assets/img/goto-browser.png';
import activeImg from '../img/raffle-share-bg.png';
import qs from 'query-string';

const appSchema = 'gktapp://com.gkewang';
const downloadUrl = 'https://app.gkewang.com/app';

const RaffleShare = ()=> {
    const [maskShow, setMaskShow] = useState(false);
    const { lotteryName="", imgUrl } = qs.parse(window.location.search);
  
    const url = '/myCollectionVote/collectionRaffle';
    const targetUrl = `${window.location.origin}${url}`;
    const luanchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(`${targetUrl}`)}`;
  
    const openApp = () => {
      if (Platform.isWechat()) {
        setMaskShow(true);
      } else {
        window.location.href = luanchUrl;
        setTimeout(() => {
            window.location.href = downloadUrl;
        }, 3000);
      }
    }
  
    return <div className="raffle-share">
      <img className="bg-img" src={activeImg} alt="" />
      <div className="przie-title">{decodeURIComponent(lotteryName)}</div>
      <div className="prize-img"><img src={decodeURIComponent(imgUrl)} alt="" /></div>
      <div className='load-btn' onClick={() => openApp()}>
        {'下载/打开绿地G优APP'}
      </div>
      {
        maskShow && 
        <div className='mask' onClick={() => setMaskShow(false)}>
          <img src={gotoBrowserImg} alt=""/>
        </div>
      }
    </div>
}

export default RaffleShare;