import React, {useState, useEffect, useRef, createContext, useContext} from 'react';
import styles from './styles.module.scss';
import ItemSwitch from "@/view/eleCompound/components/itemSwitch";
import ColEle from "@/view/eleCompound/components/colEle";
import HuiShouEle from "@/view/eleCompound/components/huiShouEle";
import BuyEle from "@/view/eleCompound/components/buyEle";
import EleHc from "@/view/eleCompound/components/eleHc";
import Rule from "@/view/eleCompound/components/rule";
import {notifyYYZToCloseWeb} from "@/utils/publicFun";

const Context = createContext(null);
const EleCompoundHome = () => {
    const [type, setType] = useState(0)//0=首页-收集元素  1=元素合成 2=活动规则 3=回收得元素盲盒 4=购买元素盲盒

    const closeOrBackPage = (type) => {
        if (type === 0) {//close
            notifyYYZToCloseWeb({});
        } else if (type === 1) {//back
            setType(0)
        }
    }

    const getView = () => {
        if (type === 0) {
            return <ColEle btnClick={(t) => setType(t)}/>
        } else {
            return <HuiShouBuyHeChengComponent/>
        }
    }

    return (
        <Context.Provider value={{type}}>
            <div className={styles.container}>
                <ItemSwitch switchItem={(t) => setType(t)}/>
                <div className={styles.content}>
                    <div className={styles.middle}>
                        <img src={require('../imgs/icon_del.png')} alt="" className={styles.close}
                             onClick={() => closeOrBackPage(0)}/>
                        {
                            (type === 3 || type === 4) ?
                                <img src={require('../imgs/icon_fh.png')} alt="" className={styles.back}
                                     onClick={() => closeOrBackPage(1)}/> : null
                        }
                        {getView()}
                    </div>
                </div>
            </div>
        </Context.Provider>
    )
}

const HuiShouBuyHeChengComponent = () => {//回收/购买/合成/规则的底图
    const {type} = useContext(Context);//0=首页-收集元素  1=元素合成 2=活动规则 3=回收得元素盲盒 4=购买元素盲盒

    useEffect(() => {
    }, [])

    const getChildView = () => {
        console.log('type---', type);
        if (type === 1) {
            return <EleHc/>
        } else if (type === 2) {
            return <Rule/>
        } else if (type === 3) {
            return <HuiShouEle/>
        } else if (type === 4) {
            return <BuyEle/>
        }
    }

    const getImg = () => { //0=首页-收集元素  1=元素合成 2=活动规则 3=回收得元素盲盒 4=购买元素盲盒
        if (type === 1) {
            return <img src={require('../imgs/ele_hc.png')} alt="" className={styles.yshc}/>
        } else if (type === 2) {
            return <img src={require('../imgs/ac_rule.png')} alt="" className={styles.yshc}/>
        } else if (type === 3) {
            return <img src={require('../imgs/huisdmh.png')} alt="" className={styles.hsdmh}/>
        } else if (type === 4) {
            return <img src={require('../imgs/buymh.png')} alt="" className={styles.buymh}/>
        }
    }

    return (
        <>
            <img src={require('../imgs/bg1.png')} alt="" className={styles.bgImg}/>
            {getImg()}
            <div className={styles.otherCom}>
                {getChildView()}
            </div>
        </>
    )
}


export default EleCompoundHome;
