import VariationHome from './home';
import VariationMedicine from './variationMedicine';
import VariationApeShare from './variationApeShare';
import VariationRecord from './variationRecord';
import ApeCompose from './variationApeCompose';
import VariationApeSaleDetail from './variationApeSaleDetail';
import VariationApeInviteSuccess from './variationApeInviteSuccess';

export default {
  VariationHome,
  VariationMedicine,
  VariationApeShare,
  VariationRecord,
  ApeCompose,
  VariationApeSaleDetail,
  VariationApeInviteSuccess,
}