import React, {useEffect, useState, useRef} from "react"
import './index.scss'
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {TextArea, Toast} from "antd-mobile";
import {isEmptyString} from "@/utils/publicFun";
import {hideLoading, showLoading} from "@/components/loading";
import {getChainQueryType, getMyWalletAddress} from "@/api/projectManager_self";

const BlockChainQuery = () => {

    const [opacity, setOpacity] = useState(0.3);
    const textRef = useRef('');

    useEffect(() => {
        window.JsBridge.setTitle("链上查询");
    }, [])

    const onChange = (text) => {
        if (!isEmptyString(text)) {
            setOpacity(1)
        } else {
            setOpacity(0.3)
        }
        textRef.current = text;
    }

    const submit = async () => {
        if (opacity !== 1) {
            return
        }
        try {
            showLoading();
            const res = await getChainQueryType({queryValue: textRef.current});
            hideLoading();
            if (res.code !== '000000') throw res.msg;
            let queryType = res.data.queryType;
            if (queryType === 0) { //0. 交易哈希 1.区块链地址
                window.JsBridge.push("Web", {uri: `${window.location.origin}/blockChainColInfo?transactionHash=${textRef.current}`});
            } else if (queryType === 1) {
                window.JsBridge.push("Web", {uri: `${window.location.origin}/blockChainAdrInfo?walletAddress1=${textRef.current}`});
            } else {
                window.JsBridge.runAction('alert', 'error', ['查询信息有误,请检查']);
            }
        } catch (error) {
            hideLoading();
            Toast.show(error);
        }
    }

    return (
        <div className={'block_chain_container'}>
            {/*<div className={'title_container'}>可在*/}
            {/*    <CopyToClipboard text={'hshshshhshshs'}*/}
            {/*                     onCopy={async () => {*/}
            {/*                         Toast.show('复制成功');*/}
            {/*                     }}>*/}
            {/*        <span>http://xxxxxxxxx.xxxx</span>*/}
            {/*    </CopyToClipboard>*/}
            {/*    查询*/}
            {/*</div>*/}
            <div className={'textArea_container'}>
                <TextArea
                    placeholder='请输入目标区块链地址/交易哈希'
                    autoSize={{minRows: 4, maxRows: 6}}
                    style={{'--font-size': '15px'}}
                    onChange={(e) => onChange(e)}/>
            </div>
            <div className={'next_btn'} style={{opacity: opacity}}
                 onClick={() => submit()}>查询
            </div>
        </div>
    )
}

export default BlockChainQuery
