/**
 * @description 土地藏品回收记录
 * @returns {route: /collectionRecoveryLand/recoveryRecord}
 */

import React, { useEffect, useState, useRef } from "react";
import { Toast } from "antd-mobile";
import NFTHeader from "@/components/NFTHeader";
import { hideLoading, showLoading } from "@/components/loading";
import ListView from "@/components/AntListView";
import qs from "query-string";
import count_bg from "@/assets/img/industrialApe/choice_logo.png";
import { industrialApeRecoveryRecord } from "@/api/projectManager_self";
import "./index.scss";
const RecoveryRecord = () => {
  const { activityCode } = qs.parse(window.location.search);
  const [dataSource, setDataSource] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const currentPage = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    loadData(true);
  }, []);

  const loadData = async (isRefesh = false) => {
    const param = {
      activityCode: activityCode,
      pageSize: pageSize.current,
      currentPage: currentPage.current,
    };
    try {
      isRefesh && showLoading();
      const res = await industrialApeRecoveryRecord(param);
      isRefesh && hideLoading();
      if (res.code !== "000000") throw res.msg;
      const newList = res.data.record || [];
      if (currentPage.current === 1) {
        setDataSource(newList);
      } else {
        setDataSource([...dataSource, ...newList]);
      }
      setHasMore(newList.length >= pageSize.current);
    } catch (error) {
      isRefesh && hideLoading();
      Toast.show(error);
    }
  };

  const loadMore = async () => {
    currentPage.current += 1;
    console.log("loadmore");
    await loadData();
  };
  const renderItem = (item) => {
    return (
      <div className="synthesis-record-item">
        <div className="card-header">
          <div className="left">
            <div className="img">
              <img src={count_bg} alt="" />
            </div>
            <div className="info">
              <div className="name">{item?.name || "合成工业猴"}</div>
              <div className="label">{item?.remark || "-"}级</div>
            </div>
          </div>
          <div className="right">{`x${item?.postCount || "-"}`}</div>
        </div>
        <div className="card-body">
          <div className="info">
            <div>合成使用材料</div>
            <div className="item_box">
              <div className="item">
                <div className="name">{`${item?.remark}级矿工猴`}</div>
                <div>*</div>
                <div>{`${item?.postCount}`}</div>
              </div>
              <div className="item">
                <div className="name">{`${item?.remark}级配件1`}</div>
                <div>*</div>
                <div>{`${item?.postCount}`}</div>
              </div>
              <div className="item">
                <div className="name">{`${item?.remark}级配件2`}</div>
                <div>*</div>
                <div>{`${item?.postCount}`}</div>
              </div>
              <div className="item">
                <div className="name">{`${item?.remark}级配件3`}</div>
                <div>*</div>
                <div>{`${item?.postCount}`}</div>
              </div>
            </div>
          </div>
          <div className="date">
            {`合成时间 ${item.recoveryTime?.replace(/-/g, ".")}`}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="synthesis-record">
      <NFTHeader midText={`合成记录`}/>
      <div className="synthesis-record-warper">
        <ListView
          dataSource={dataSource}
          renderItem={renderItem}
          hasMore={hasMore}
          isLoad={true}
          style={{ paddingTop: 50 }}
          onRefresh={() => {
            currentPage.current = 1;
            loadData();
          }}
          onEndReached={loadMore}
        />
      </div>
    </div>
  );
};

export default RecoveryRecord;
