import React, { useState, useEffect, useRef } from 'react';

const CountDown = ({ sysTime, endTime, refreshData }) => {
  const initalTime = { day: '00', hour: '00', minute: '00', second: '00' };
  const [countTime, setCountTime] = useState(initalTime);
  const timeStampCurrent = useRef(null);
  const timerCurrent = useRef(null);

  useEffect(() => {
    timeStampCurrent.current = null;
    timerStart();
    return () => {
      timerCurrent.current && clearInterval(timerCurrent.current);
    }
  }, [sysTime, endTime])

  const countDown = (currentTime, endTime, callBack) => {
    if (!currentTime || !endTime) {
      return initalTime;
    }
    const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))
    if (timeStampCurrent.current == null) {
      timeStampCurrent.current = currentTimeStamp
    }
    const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
    const distanceStamp = endTimeStamp - timeStampCurrent.current;
    if (distanceStamp <= 0) {
      callBack && callBack(distanceStamp);
      return initalTime;
    }
    let remianAllSeconds = Math.floor(distanceStamp / 1000);
    let day = Math.floor(remianAllSeconds / (60 * 60 * 24));
    let hour = Math.floor(remianAllSeconds / (60 * 60) % 24);
    let minute = Math.floor(remianAllSeconds / 60 % 60);
    let second = Math.floor(remianAllSeconds % 60);
    return { day, hour, minute, second };
  }

  const timerStart = () => {
    timerCurrent.current && clearInterval(timerCurrent.current);
    timerCurrent.current = setInterval(() => {
      if (timeStampCurrent.current) {
        timeStampCurrent.current = timeStampCurrent.current + 1000;
      }
      let obj = countDown(sysTime, endTime, (distanceStamp) => {
        timerCurrent.current && clearInterval(timerCurrent.current);
        if (distanceStamp === 0) {
          refreshData && refreshData();
        }
      });
      setCountTime(obj);
    }, 1000)
  }

  const {day, hour, minute, second} = countTime;

  return <div>{day + '天' + hour + '小时' + minute + '分' + second + '秒'}</div>
}

export default CountDown;
