import React, {useState, useEffect} from 'react';
import './style.scss';
import back from '@/assets/img/icon/back.png';
import share from '@/assets/img/icon/share.png';
import main from '@/assets/img/variationApe/pic_bg_new.png';
import gl from '@/assets/img/variationApe/pic_gl.png';
import xuanzhong from '@/assets/img/variationApe/tab_xuanzhon.png';
import see from '@/assets/img/collection/goto.png';
import weixuanzhong from '@/assets/img/variationApe/tab_dis.png';
import inviteImg from '@/assets/img/variationApe/invite-gift.png';
import bgImage1 from "@/assets/img/variationApe/fenxiang_bg1.png";
import bgImage2 from "@/assets/img/variationApe/fenxiang_bg2.png";
import logo from '@/assets/img/variationApe/logo.png';
import RulesModal from '@/view/variationApe/components/ruleModal/rulesModal';
import TipModal from '../components/tipModal';
import ShareModal from '../components/shareModal';
import {hideLoading, showLoading} from "@/components/loading";
import {queryDefCode} from "@/api/projectManager_self";
import { Toast } from 'antd-mobile';
import { getVariationApeActivityInfo } from '@/api/projectManager_self';
import PopupModal from '../components/recordModal';
import PurchaseRank from '../components/purchaseRank';

const mockData = {
  code: '000000',
  msg: 'success',
  data: {
    syntheticBoringApeStartTime: '2023-04-14 09:00:00',
    syntheticBoringApeEndTime: '2023-04-21 23:59:59',
    syntheticLiquidMedicineStartTime: "2023-03-31 09:00:00",
    syntheticLiquidMedicineEndTime: "2023-04-13 23:59:59",
    syntheticLiquidMedicineEndDate: '4月13日',
    syntheticLiquidMedicineStartDate: '3月31日',
    syntheticBoringApeStartDate: '4月14日',
    syntheticBoringApeEndDate: '4月21日',
    syntheticLiquidMedicineTotalAmount: 1000,
    syntheticLiquidMedicineAmount: 1,
    syntheticLiquidMedicinePerDayExchangeAmount: 5,
    epicBoringApeAmount: 100,
    syntheticBoringApeAmount: 10,
    syntheticStatus: true,
    exchangeLiquidMedicineAmount: 15,
    syntheticStatusName: '合成变异药水',
    startHour: '9:00',
    buyStatus: true,
    nowTime: '2023-04-14 13:00:00',
    totalSellAmount: 500,
    sellPrice: 599,
    currentDayExchangeAmount: 3,
    inviteCode: 'gh738839',
    activityCode: '8990766',
    legendBoringApeAmount: 10,
    invitePoints: 2000,
    inviteStatus: true,
    syntheticBoringApeStatus: true,
    syntheticBoringApeStatusName: '合成变异无聊猿',
    buyStatusName: '立即购买',
    inviteStatusName: '立即邀新',
    boringApeCollectionId: 31,
    checkRankTime: '2023-04-14 12:30:00',
    sellDiscontinueTime: '4月14日中午12点'
  }
}

const VariationHome = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [activityRules, setActivityRules] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [activityInfo, setActivityInfo] = useState({});

  const shareUrl = `${window.location.origin}/variationApe/share`;
  const inviteUrl = `${process.env.REACT_APP_GKT_LINK}/front/inviter/register/${activityInfo.inviteCode}?activityCode=${activityInfo.activityCode}`;
  const url = activityInfo.inviteStatus ? inviteUrl: shareUrl;
  const img = activityInfo.inviteStatus ? bgImage2: bgImage1;
  const current = (new Date(activityInfo.nowTime?.replace(/-/g, "/")))?.getTime();
  const checkTime = (new Date(activityInfo.checkRankTime?.replace(/-/g, "/")))?.getTime();

  useEffect(() => {
    window.JsBridge.setStatusBar('setBarStyle', ['light-content']);
    onDidFocus();
    refreshPage();
  }, [])

  const changeTab = (index) => {
    setTabIndex(index);
  }

  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      window.JsBridge.setStatusBar('setBarStyle', ['light-content']);
      refreshPage();
      onDidFocus();
    });
  }

  const refreshPage = () => {
    getUserInfo();
    pageInit();
  }

  const getUserInfo = () => { //获取用户信息
    window.JsBridge.getDetailCurrentUserInfo().then((res) => {
      setUserInfo(res);
    })
  }

  const realNameAuthen = () => {
    window.JsBridge.navigate('Verify');
  }

  const pageInit = async () => {
    try {
      showLoading();
      const res = await getVariationApeActivityInfo();
      // const res = mockData;
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      const currentTime = new Date(res.data.nowTime.replace(/-/g, "/")).getTime();
      const endTime = new Date(res.data.syntheticLiquidMedicineEndTime.replace(/-/g, "/")).getTime();
      if(currentTime >= endTime) {
        setTabIndex(1);
      }
      setActivityInfo(res.data);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const getActivityRules = async () => {
    try {
      if(activityRules) {
        RulesModal.open(activityRules);
        return;
      }
      showLoading();
      const res = await queryDefCode({codeType: 'activityRule', code: 'activitySyntheticRule'});
      hideLoading();
      if (res.code !== '000000') throw res.msg;
      const {data} = res;
      if (data.result && data.result.defCode) {
        const defCode = data.result.defCode;
        setActivityRules(defCode);
        RulesModal.open(defCode);
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }
  
  return <div className='variation-ape'>
    <div className="back-share">
      <img className='back-img' src={back} alt="" onClick={() => window.JsBridge.back()} />
      <img className='share-img' 
        src={share} 
        alt="" 
        onClick={() => {
          const currentTime = new Date(activityInfo.nowTime.replace(/-/g, "/")).getTime();
          const endTime = new Date(activityInfo.syntheticBoringApeEndTime.replace(/-/g, "/")).getTime();
          if(currentTime >= endTime) return;
          if(!userInfo.ID) {
            TipModal.open(realNameAuthen);
            return;
          }
          ShareModal.open({shareUrl: url, posterImg: img});
        }} 
      />
    </div>
    <div className="rules-authen">
      <div className="activity-rules" onClick={() => getActivityRules()}>活动规则</div>
      {userInfo.ID ? null: <div className="name-authen" onClick={realNameAuthen}>实名认证</div>}
    </div>
    <div className="main-img"><img src={main} alt="" /></div>
    {current < checkTime ? <div className="legend-ticket">
      <p>购买变异无聊猿</p>
      <p>前6名获得英雄联盟总决赛门票！</p>
      <p>活动截止公布时间：{activityInfo.sellDiscontinueTime} </p>
    </div>:<div className="purchase-ranking-list">
      <div className="ranking-left">
        <p>购买变异无聊猿排名公布</p>
        <p>前6名获得英雄联盟总决赛门票！</p>
      </div>
      <div 
        className="ranking-right" 
        onClick={() => {
          // PurchaseRank.open();
        }}
      >查看详情</div>
    </div>}
    <div className="main-gl"><img src={gl} alt="" /></div>
    <div className="tab-box">
      <div
        className={`medicine-tab tab-common ${tabIndex === 0 ? 'active-style': ''}`}
        onClick={() => changeTab(0)}
      >
        <div className='content-box'>
          <p className='time'>{activityInfo.syntheticLiquidMedicineStartDate}-{activityInfo.syntheticLiquidMedicineEndDate}</p>
          <p>合成变异药水</p>
          <img className='share-img' src={tabIndex === 0 ? xuanzhong: weixuanzhong} alt="" />
        </div>
      </div>
      <div
        className={`ape-tab tab-common ${tabIndex === 1 ? 'active-style': ''}`}
        onClick={() => changeTab(1)}
      >
        <div className='content-box'>
          <p className='time'>{activityInfo.syntheticBoringApeStartDate}-{activityInfo.syntheticBoringApeEndDate}</p>
          <p>售卖/合成变异无聊猿</p>
          <img className='share-img' src={tabIndex === 1 ? xuanzhong: weixuanzhong} alt="" />
        </div>
      </div>
    </div>
    {tabIndex === 0 ? <LeftTabContent
      userInfo={userInfo}
      activityInfo={activityInfo}
      inviteUrl={url}
      shareImg={img}
    />: <RightTabContent
      userInfo={userInfo}
      activityInfo={activityInfo}
      inviteUrl={url}
      shareImg={img}
    />}
    <div className="logo"><img src={logo} alt="" /></div>
  </div>
}

const LeftTabContent = ({userInfo, activityInfo, inviteUrl, shareImg}) => {
  const {
    syntheticLiquidMedicineTotalAmount,
    syntheticLiquidMedicineAmount,
    syntheticLiquidMedicinePerDayExchangeAmount,
    epicBoringApeAmount,
    syntheticStatus,
    syntheticStatusName,
    startHour,
    currentDayExchangeAmount,
    nowTime,
    syntheticLiquidMedicineEndTime
  } = activityInfo || {}; 

  const currentTime = new Date(nowTime?.replace(/-/g, "/")).getTime();
  const endTime = new Date(syntheticLiquidMedicineEndTime?.replace(/-/g, "/")).getTime();

  const realNameAuthen = () => {
    window.JsBridge.navigate('Verify');
  }

  const handleCompose = () => {
    if(!syntheticStatus) return;
    if(!userInfo.ID) {
      TipModal.open(realNameAuthen);
      return;
    }
    window.JsBridge.push("Web", { uri: `${window.location.origin}/variationApe/medicine` });
  }

  return <div className='left-tab-content'>
    <div className="compose-medicine">
      <div className="medicine-count">变异药水限量{syntheticLiquidMedicineTotalAmount}瓶！先到先得哦</div>
      <div className="exchange-time">
        每日{startHour}开兑，今日剩余合成数量：
        {syntheticLiquidMedicinePerDayExchangeAmount-currentDayExchangeAmount}瓶
      </div>
      <div className="compose-rule">
        <span>史诗款无聊猿x3</span>
        <span>变异药水x1</span>
      </div>
      <div className="collection-info">
        <div>
          <span>拥有史诗款：</span>
          <span className='count'>{epicBoringApeAmount}</span>
          <span>个</span>
        </div>
        <div>
          <span>拥有变异药水：</span>
          <span className='count'>{syntheticLiquidMedicineAmount}</span>
          <span>个</span>
        </div>
      </div>
      <div
        className={`compose-btn ${syntheticStatus ? '': 'disabled'}`}
        onClick={() => handleCompose()}
      >
        {syntheticStatusName}
      </div>
      <div
        className="compose-record"
        onClick={() => window.JsBridge.push("Web", { uri: `${window.location.origin}/variationApe/medicine/record?recordType=synthetic` })}
      >
        <span>合成记录</span>
        <img src={see} alt="" />
      </div>
    </div>
    <div className="invite-box">
      <img src={inviteImg} alt="" />
      <div className='text-box'>
        <p>藏品不够无法合成？</p>
        <p>邀请好友赠送！</p>
      </div>
      <div 
        className={`invite-btn ${currentTime >= endTime ? 'disabled': ''}`} 
        onClick={() => {
          if(currentTime >= endTime) return;
          ShareModal.open({shareUrl: inviteUrl, posterImg: shareImg});
        }}
      >去邀请</div>
    </div>
  </div>
}

const RightTabContent = ({userInfo, activityInfo, inviteUrl, shareImg}) => {
  const { 
    inviteStatus,
    invitePoints,
    legendBoringApeAmount,
    buyStatus,
    sellPrice,
    totalSellAmount,
    syntheticBoringApeAmount,
    syntheticLiquidMedicineAmount,
    syntheticBoringApeStatus,
    syntheticBoringApeStatusName,
    buyStatusName,
    inviteStatusName,
    boringApeCollectionId,
    activityCode,
    nowTime,
    syntheticBoringApeStartTime
  } = activityInfo || {}; 

  const currentTime = (new Date(nowTime?.replace(/-/g, "/")))?.getTime();
  const startTime = (new Date(syntheticBoringApeStartTime?.replace(/-/g, "/")))?.getTime();

  const realNameAuthen = () => {
    window.JsBridge.navigate('Verify');
  }

  const handleCompose = () => {
    if(!syntheticBoringApeStatus) return;
    if(!userInfo.ID) {
      TipModal.open(realNameAuthen);
      return;
    }
    window.JsBridge.push("Web", { uri: `${window.location.origin}/variationApe/compose` });
  }

  const purthaseBlind = () => {
    if(!buyStatus) return;
    window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/variationApe/saleDetail?collectionId=${boringApeCollectionId}&code=` });
  }

  const queryInviteRecord = () => {
    if(currentTime < startTime) return;
    PopupModal.open(activityCode);
  }

  return <div className='right-tab-content'>
    <div className="right-invite-box">
      <p>邀请新用户购买变异无聊猿返积分</p>
      <p>双方各返{invitePoints}积分！</p>
      <div
        className={`invite-btn btn-common-style ${inviteStatus ? '': 'unavailable'}`}
        onClick={() => {
          if(!inviteStatus) return;
          if(!userInfo.ID) {
            TipModal.open(realNameAuthen);
            return;
          }
          ShareModal.open({shareUrl: inviteUrl, posterImg: shareImg});
        }}
      >{inviteStatusName}</div>
      <div className="invite-record-box" onClick={queryInviteRecord}>
        <span>邀新记录</span>
        <img src={see} alt="" />
      </div>
    </div>
    <div className="blind-box">
      <p>购买绿地变异无聊猿 限量发售{totalSellAmount}份</p>
      <p>前300位购买用户赠送绿地申花限量啤酒一箱</p>
      <div 
        className={`purthase-blind btn-common-style ${buyStatus ? '': 'unavailable'}`}
        onClick={() => purthaseBlind()}
      >{buyStatusName}</div>
      <div className="blind-info">
        <div className='blind-text'>
          <span>绿地变异</span>
          <span>无聊猿盲盒</span>
        </div>
        <div className='blind-bottom'>
          <span>¥</span>
          <span>{sellPrice}</span>
        </div>
      </div>
    </div>
    <div className="compose-variation-ape">
      <p>合成绿地变异无聊猿</p>
      <div className="compose-info">
        <div className='compose-info-item'>
          <span>拥有传说款:</span>
          <span>{legendBoringApeAmount}</span>
          <span>个</span>
        </div>
        <span className='line'></span>
        <div className='compose-info-item'>
          <span>拥有变异药水:</span>
          <span>{syntheticLiquidMedicineAmount}</span>
          <span>个</span>
        </div>
        <span className='line'></span>
        <div className='compose-info-item'>
          <span>拥有变异款:</span>
          <span>{syntheticBoringApeAmount}</span>
          <span>个</span>
        </div>
      </div>
      <div
        className={`compose-variation-ape-btn btn-common-style ${syntheticBoringApeStatus ? '': 'unavailable'}`}
        onClick={() => handleCompose()}
      >{syntheticBoringApeStatusName}</div>
      <div 
        className="compose-record-box"
        onClick={() => {
          if(currentTime < startTime) return;
          window.JsBridge.push("Web", { uri: `${window.location.origin}/variationApe/medicine/record?recordType=mutational` });
        }}
      >
        <span>合成记录</span>
        <img src={see} alt="" />
      </div>
    </div>
    <div className="invite-box">
      <img src={inviteImg} alt="" />
      <div className='text-box'>
        <p>藏品不够无法合成？</p>
        <p>邀请好友赠送！</p>
      </div>
      <div
        className={`invite-btn ${inviteStatus ? '': 'disabled'}`}
        onClick={() => {
          if(!inviteStatus) return;
          if(!userInfo.ID) {
            TipModal.open(realNameAuthen);
            return;
          }
          ShareModal.open({shareUrl: inviteUrl, posterImg: shareImg});
        }}
      >去邀请</div>
    </div>
  </div>
}

export default VariationHome;
