import React, {useEffect, useState, useRef} from "react"
import "./index.scss";
import {Toast} from "antd-mobile";
import {validate} from "@/utils/validate";
import {addTicketUserInfo} from "@/api/projectManager_self";
import {hideLoading, showLoading} from "@/components/loading";
import {getUrlAllParams, previewProtocol} from "@/utils/publicFun";
import SimpleDialog from "@/components/SimpleDialog";
import JsBridgeNew from "@/utils/jsbridgeNew";
import {useLocation} from "react-router-dom";


const MoviegoerTicket = (props) => {//观影人门票

    const {search} = useLocation()
    const params = getUrlAllParams(search.slice(1));
    const {
        activityCode = '',
        clientId = '',
        id = '',
        isDisable = '0'//是否是填写过了
    } = params

    const [selPro, setSelPro] = useState(false);
    const [inputInfo, setInputInfo] = useState({attendeeName: '', attendeeCardNo: '', attendeeContactNum: '',});
    const [disabled, setDisabled] = useState(isDisable === '1');
    const [modalVisible, setModalVisible] = useState(false)

    useEffect(() => {
        window.JsBridge.setTitle('观演人信息');
        if (disabled) {
            loadData();
        }
    }, [])

    const loadData = async () => {
        let params = {
            activityCode: activityCode,
            clientId: clientId,
        }
        try {
            showLoading()
            const res = await JsBridgeNew.jKTServicesRequest({
                moduleName: 'MainPage',
                code: '600815',
                params: params
            })
            hideLoading();
            if (res.status <= 0) throw res.msg;
            setInputInfo(res.data);
        } catch (error) {
            hideLoading();
        }
    }

    const submit = () => {
        if (!inputInfo.attendeeName) {
            Toast.show('请填写姓名');
            return;
        }
        if (!inputInfo.attendeeCardNo) {
            Toast.show('请填写身份证号');
            return;
        }
        if (!inputInfo.attendeeContactNum) {
            Toast.show('请填写联系方式');
            return;
        }
        if (!validate.idcard(inputInfo.attendeeCardNo)) {
            Toast.show('请填写正确的身份证号');
            return;
        }
        if (!validate.mobile(inputInfo.attendeeContactNum)) {
            Toast.show('请填写正确的联系方式');
            return;
        }
        if (!selPro) {
            Toast.show('请阅读并同意底部协议');
            return;
        }
        setModalVisible(!modalVisible)
    }

    const finallySubmit = async () => {
        setModalVisible(false);
        let params = {
            activityCode: activityCode,
            clientId: clientId,
            attendeeCardNo: inputInfo.attendeeCardNo,
            attendeeContactNum: inputInfo.attendeeContactNum,
            attendeeName: inputInfo.attendeeName,
            id: id,
        }
        try {
            showLoading()
            const res = await JsBridgeNew.jKTServicesRequest({
                moduleName: 'MainPage',
                code: '600816',
                params: params
            })
            hideLoading();
            console.log('res---', res)
            if (res.status <= 0) throw res.msg;
            let timer1 = setTimeout(() => {
                    clearTimeout(timer1)
                    Toast.show('信息已提交')
                },
                100);
            let timer2 = setTimeout(() => {
                    clearTimeout(timer2);
                    window.JsBridge.back();
                },
                3000);
        } catch (error) {
            hideLoading();
        }
    }

    const clickPro = () => {//协议
        setSelPro(!selPro);
    }

    return (
        <div className="moviegoer-ticket-container">
            <div className={'moviegoer-warning'}>
                <img src={require('../img/icon_warning.png')} alt=""/>
                特别提示：实名制入场需本人持填写时的有效证件入场
            </div>
            <div className={'moviegoer-content'}>
                <p className={'moviegoer-title'}>实名观演人</p>
                <div className={'moviegoer-item'}>
                    <span>姓        名:</span>
                    <input type="text" placeholder={'请填写观演人姓名'} onChange={(event) => {
                        inputInfo.attendeeName = event.target.value;
                        setInputInfo({...inputInfo})
                    }} value={inputInfo.attendeeName} disabled={disabled}/>
                    {disabled ? null : <div className={'moviegoer-line'}/>}
                </div>
                <div className={'moviegoer-item'}>
                    <span>身份证号:</span>
                    <input type="text" placeholder={'请填写证件号码'} onChange={(event) => {
                        inputInfo.attendeeCardNo = event.target.value;
                        setInputInfo({...inputInfo})
                    }} value={inputInfo.attendeeCardNo} disabled={disabled}/>
                    {disabled ? null : <div className={'moviegoer-line'}/>}
                </div>
                <div className={'moviegoer-item'}>
                    <span>联系方式:</span>
                    <input type="tel" placeholder={'请填写联系方式'} onChange={(event) => {
                        inputInfo.attendeeContactNum = event.target.value;
                        setInputInfo({...inputInfo})
                    }} value={inputInfo.attendeeContactNum} disabled={disabled}/>
                    {disabled ? null : <div className={'moviegoer-line'}/>}
                </div>
                {disabled ? null : <div className={'moviegoer-pro'}>
                    <img src={selPro ? require('../img/icon_pro_sel.png') : require('../img/icon_pro_nor.png')} alt=""
                         onClick={clickPro}/>
                    我已阅读并同意
                    <span onClick={() => previewProtocol(9)}>《实名须知》</span>
                </div>}
            </div>
            {disabled ? null : <p className={'moviegoer-btn'} onClick={submit}>确认</p>}
            <SimpleDialog
                title={'确认提交当前信息？提交后不可更改'}
                rightBtnText={'确认提交'}
                rightBtnCB={() => finallySubmit()}
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
            />
        </div>
    )
}

export default MoviegoerTicket;
