/**
 * @description 工业猴合成
 * @returns {route: /industrialApe/synthesis}
 */

import React, { useEffect, useState, useRef, useMemo } from "react";
import { Toast } from "antd-mobile";
import qs from "query-string";
import { useNavigate } from "react-router-dom";
import NFTHeader from "@/components/NFTHeader";
import { hideLoading, showLoading } from "@/components/loading";
import ListView from "@/components/AntListView";
import ModalAlert from "@/components/ModalAlert";
import { throttle } from "@/utils/publicFun";
import SuccessModal from "../components/successModal";
import { cloneDeep, forOwn } from "lodash";
import SynthesisModal from "../components/synthesisModal";
import Service from "../service";
import {
  industrialApeList,
  industrialApeExec,
  industrialApeSeriesCount,
} from "@/api/projectManager_self";
import "./index.scss";
import classNames from "classnames";

const bodyStyle = {
  background: "transparent",
  padding: 0,
  maxHeight: "100vh",
  maxWidth: "100vw",
};

const Tabs = [
  { label: "矿工猴", value: "0" },
  { label: "配件", value: "1" },
];
const TabsSecond = [
  { label: "配件1", value: "0" },
  { label: "配件2", value: "1" },
  { label: "配件3", value: "2" },
];
const Synthesis = () => {
  const navigate = useNavigate();
  const { level, activityCode } = qs.parse(window.location.search);
  const [dataSource, setDataSource] = useState([]);
  const [countInfo, setCountInfo] = useState(false);

  const [selectInfo, setSelectInfo] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [currentTab, setCurrentTab] = useState(Tabs[0]);
  const [currentTabSecond, setCurrentTabSecond] = useState(TabsSecond[0]);

  const currentRef = useRef();
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const reset = () => {
    setDataSource([]);
    setSelectInfo({});
    currentPage.current = 1;
    setCurrentTab(cloneDeep(Tabs[0]));
    setCurrentTabSecond(cloneDeep(TabsSecond[0]));
  };
  useEffect(() => {
    (async () => {
      await apeSeriesCount();
      onDidFocus();
    })();
    return () => {
      reset()
    };
  }, []);

  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      apeSeriesCount();
      onDidFocus();
    });
  };

  useEffect(() => {
    loadData(true);
  }, [currentTab, currentTabSecond]);

  const flag = useMemo(() => {
    const flag =
      selectInfo["0"] && selectInfo["1"] && selectInfo["2"] && selectInfo["3"];
    return flag;
  }, [selectInfo]);
 
  const apeSeriesCount = async () => {
    try {
      reset();
      const params = {
        activityCode: activityCode,
        type: level,
      };
      const res = await industrialApeSeriesCount(params);
      if (res.code !== "000000") throw res.msg;
      const data = res?.data || [];
      console.log(data);
      let flag = true;
      data.forEach((item) => {
        if (item < 10) {
          flag = false;
        }
      });
      setCountInfo(flag);
    } catch (err) {}
  };

  const handleDataSource = (data) => {
    let selectTemp = cloneDeep(selectInfo);
    const dataSourceTemp = data;
    let type = "0";
    if (currentTab?.value === "0") {
      type = "0";
    } else {
      if (currentTabSecond?.value === "0") {
        type = "1";
      } else if (currentTabSecond?.value === "1") {
        type = "2";
      } else if (currentTabSecond?.value === "2") {
        type = "3";
      }
    }
    try {
      dataSourceTemp.forEach((element) => {
        if (element?.nftNo === selectTemp[type]?.nftNo) {
          element.select = true;
          throw new Error("条件成功，抛出错误");
        }
      });
    } catch (e) {
      console.log("foreach-err", e.message);
    }
    return dataSourceTemp;
  };
  const loadData = async (isLoading = false) => {
    let bizId = "";
    if (currentTab?.value === "0") {
      bizId = {
        A: "1",
        B: "2",
        C: "3",
      }[level];
    } else {
     switch(level){
      case "A": bizId = {
        0: "4",
        1: "5",
        2: "6",
      }[currentTabSecond?.value];break;
      case "B": bizId = {
        0: "7",
        1: "8",
        2: "9",
      }[currentTabSecond?.value];break
      case "C": bizId = {
        0: "10",
        1: "11",
        2: "12",
      }[currentTabSecond?.value];break
     }
     
    }
    const params = {
      currentPage: currentPage.current,
      pageSize: pageSize.current,
      activityCode: activityCode,
      bizId,
    };
    try {
      isLoading && showLoading();
      const res = await industrialApeList(params);
      isLoading && hideLoading();
      if (res.code !== "000000") throw res.msg;
      const newList = res.data.list || [];
      if (currentPage.current === 1) {
        const list = handleDataSource(newList);
        setDataSource(list);
      } else {
        const list = handleDataSource([...dataSource, ...newList]);
        setDataSource(list);
      }
      setIsLoad(true);
      setHasMore(newList.length >= pageSize.current);
    } catch (error) {
      console.log(error);
      isLoading && hideLoading();
      setIsLoad(true);
      Toast.show(error);
    }
  };

  const loadMore = async () => {
    currentPage.current = currentPage.current + 1;
    await loadData();
  };

  const onComit = (type) => {
    if (!flag && type === "single") return;
    const selectArray = [];
    forOwn(selectInfo, function (value, key) {
      selectArray.push(value);
    });
    ModalAlert.show({
      getContainer: currentRef.current,
      maskStyle: { background: `rgba(0, 0, 0, 0.6)` },
      bodyClassName: `nft_modal`,
      bodyStyle: bodyStyle,
      content: (
        <SynthesisModal
          type={type}
          level={level}
          selectInfo={selectInfo}
          selectArray={selectArray}
          onConfirm={onConfirm}
          onCancel={() => {
            ModalAlert?.hide();
          }}
          onClose={() => ModalAlert?.hide()}
        />
      ),
    });
  };

  const onConfirm = throttle(async (type) => {
    ModalAlert.hide();
    const selectArray = [];
    forOwn(selectInfo, function (value, key) {
      selectArray.push(value);
    });
    try {
      showLoading();
      const nftNoList = selectArray.map((i) => i.nftNo);
      const params = {
        nftNoList: type === "single" ? nftNoList : null,
        activityCode: activityCode,
        bizId: level,
      };
      const res = await industrialApeExec(params);
      hideLoading();
      if (res.code != "000000") {
        throw res.msg;
      }
      SuccessModal.open({
        confirmCB,
        func: toCheck,
        data: res?.data?.userCollectionRespList || [],
      });
    } catch (error) {
      console.log(error);
      hideLoading();
      Toast.show(error);
    }
  }, 2000);

  const confirmCB = () => {
    apeSeriesCount();
  };


  const onDelete = (type) => {
    let selectTemp = cloneDeep(selectInfo);
    const dataSourceTemp = dataSource;
    try {
      dataSourceTemp.forEach((element) => {
        if (element?.nftNo === selectTemp[type]?.nftNo) {
          element.select = false;
          throw new Error("条件成功，抛出错误");
        }
      });
    } catch (e) {
      console.log("foreach-err", e.message);
    }
    selectTemp[type] = undefined;
    setSelectInfo(selectTemp);
    setDataSource(dataSourceTemp);
  };

  const onSelectItem = (item) => {
    if (flag) return;
    let selectTemp = cloneDeep(selectInfo);
    if (currentTab?.value === "0") {
      if (!selectInfo["0"]) {
        item.select = !item.select;
        selectTemp["0"] = item;
      }
    } else {
      if (currentTabSecond?.value === "0") {
        if (!selectInfo["1"]) {
          item.select = !item.select;
          selectTemp["1"] = item;
        }
      } else if (currentTabSecond?.value === "1") {
        if (!selectInfo["2"]) {
          item.select = !item.select;
          selectTemp["2"] = item;
        }
      } else if (currentTabSecond?.value === "2") {
        if (!selectInfo["3"]) {
          item.select = !item.select;
          selectTemp["3"] = item;
        }
      }
    }
    setSelectInfo(selectTemp);
  };

  //立即购买
  const onBuy = () => {
    const uri = `/ape/saleDetail?collectionId=${Service.collectionId}&code=${Service.activityCode}`;
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.verifyRealName("请先实名认证").then(function (res) {
        if (res?.result) {
          window.JsBridge.push("CustomWeb", {
            uri: `${window.location.origin}${uri}`,
          });
        }
      });
    } else {
      navigate(uri);
    }
  };

  const renderItem = (item) => {
    return (
      <div className="collection-item">
        <div
          className={classNames(
            "collection-item-content",
            { active: item.select },
            { disabled: selectInfo.length >= 4 }
          )}
          onClick={() => onSelectItem(item)}
        >
          <div className="item-img">
            <div className="nftNo">#{item.nftNo}</div>
            <img src={item.nftImage} alt="" />
          </div>
        </div>
      </div>
    );
  };

  const onRightClick = () => {
    const params = qs.stringify({ level, activityCode });
    const uri = `/industrialApe/synthesisRecord?${params}`;
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", {
        uri: `${window.location.origin}${uri}`,
      });
    } else {
      navigate(uri);
    }
  };

  const toCheck = () => {
    const uri = `/myCollection`;
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", {
        uri: `${window.location.origin}${uri}`,
      });
    } else {
      navigate(uri);
    }
  };
  const onTabChange = (item) => {
    if (item.value === currentTab.value) return;
    currentPage.current = 1;
    setCurrentTab(item);
  };
  const onTabChangeSecond = (item) => {
    if (item.value === currentTabSecond.value) return;
    currentPage.current = 1;
    setCurrentTabSecond(item);
  };
  return (
    <div className="synthesis" ref={currentRef}>
      <NFTHeader
        midText="工业猴合成"
        rightText={"合成记录"}
        rightClick={onRightClick}
      />
      <div className="synthesis-body">
        <div className="select-preview">
          <div className="preview-list">
            {[1, 2, 3, 4].map((item, i) => {
              return selectInfo[i] ? (
                <div
                  className={classNames("preview-item", {
                    "preview-after": i !== 3,
                  })}
                  key={i}
                >
                  <img
                    className="preview-img"
                    src={selectInfo[i]?.nftImage}
                    alt=""
                  />
                  <img
                    className="close-icon"
                    onClick={() => onDelete(i)}
                    src={require("@/assets/img/industrialApe/close.png")}
                    alt=""
                  />
                  <span>#{selectInfo[i]?.nftNo}</span>
                </div>
              ) : (
                <div
                  className={classNames("preview-emty", {
                    "preview-after": i !== 3,
                  })}
                  key={i}
                >
                  <img
                    src={require("@/assets/img/industrialApe/add.png")}
                    alt=""
                  />
                </div>
              );
            })}
          </div>
          <div className="synthesis_opt">
            <div
              className={`synthesis-btn${!flag ? " disable" : ""}`}
              onClick={() => {
                onComit("single");
              }}
            >
              <span>合成工业猴</span>
            </div>
            <div
              className={`synthesis-btn${!countInfo ? " disable" : ""}`}
              onClick={() => {
                countInfo && onComit("multiple");
              }}
            >
              <span>随机合成10个</span>
            </div>
          </div>
        </div>
        <div className="collection-list">
          <div className="list-tip">请选择合成材料</div>
          <div className="tabs">
            {Tabs.map((item, k) => {
              return (
                <div
                  key={k}
                  className={classNames("tab", {
                    tab_active: item.value == currentTab.value,
                  })}
                  onClick={() => onTabChange(item)}
                >
                  <span>{item.label}</span>
                </div>
              );
            })}
          </div>
          {currentTab.value === "1" && (
            <div className="tabs2">
              {TabsSecond.map((item, k) => {
                return (
                  <div
                    key={k}
                    className={classNames("tab", {
                      tab_active: item.value == currentTabSecond.value,
                    })}
                    onClick={() => onTabChangeSecond(item)}
                  >
                    <span>{item.label}</span>
                  </div>
                );
              })}
            </div>
          )}
          <div className="list_out">
            {currentTab?.value === "1" && dataSource?.length === 0 ? (
              <div className="empty">
                <div className="tips">{`还没有${level}级配件`}</div>
                <div
                  className="buy"
                  onClick={() => {
                    onBuy();
                  }}
                >
                  去购买
                </div>
              </div>
            ) : (
              <ListView
                dataSource={dataSource}
                renderItem={renderItem}
                hasMore={hasMore}
                isLoad={isLoad}
                onEndReached={loadMore}
                style={{ paddingTop: 50 }}
                onEndReachedThreshold={1}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Synthesis;
