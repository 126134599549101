/**
 * @description GR-2405627 ape world 配件盲盒
 * @param {}
 * @returns {/apeWorld/partsBlindbox/home}
*/

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { Toast } from 'antd-mobile';
import classNames from 'classnames';
import NFTModal from '@/components/NFTModal';
import { hideLoading, showLoading } from '@/components/loading';
import ActivityRules from '../components/ActivityRules';
import Service from '../service';
import Styles from './index.module.scss';

const CardObjcs = [
    { key: 'land', name: '金色属性土地', info: '抽盲盒得配件，更有机会中免管理费A级土地！', img: require('@/assets/img/partsBlindbox/a_land.png') },
    { key: 'coupon', name: '酒旅优惠券', info: '抽盲盒得酒旅优惠券，最高得2000元', img: require('@/assets/img/partsBlindbox/coupon.png') },
]

const ActionEvents = [
    { key: 'old_new', name: '配件以旧换新！每日限量！！！', subTitle: '普通配件3换1  稀有配件1换3' },
    { key: 'package', name: '去购买酒旅套餐', subTitle: '' },
]

const Home = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [dataSource, setDataSource] = useState({});
    const pageDom = useRef(null);

    useEffect(() => {
        getData(true)
    }, []);

    const getData = async (loading = false) => {
        try {
            if (loading) showLoading();
            if (loading) {
                const userInfo = await getUserCertificateStatus();
                setUserInfo(userInfo);
            }
            const res = await Service.getInitData();
            if (loading) hideLoading();
            if (res.code !== '000000') throw res.msg;
            setDataSource(res.data);
        } catch (error) {
            hideLoading();
            Toast.show(error);

        }
    }

    // 获取用户信息，是否实名
    const getUserCertificateStatus = async () => {
        if (window.JsBridge.hasWebViewBridge()) {
            const res = await window.JsBridge.getDetailCurrentUserInfo()
            return res;
        } else {
            return {};
        }
    }
    // 提示信息
    const tipsMessage = useMemo(()=> {
        let msg = undefined;
        const {currentTime, startTime, endTime} = dataSource;
        const currentT = new Date(currentTime?.replace(/-/g, "/")).getTime();
        const startT = new Date(startTime?.replace(/-/g, "/")).getTime();
        const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
        if(!startTime || !endTime) {
            msg = '接口异常';
        }else if(currentT < startT) {
            msg = '活动未开始';
        }else if(currentT > endT) {
            msg = '活动已结束';
        }
      
        return msg;
    },[dataSource]) 

    const onClickRules = () => {
        const ModalInstance = NFTModal.show({
            content: <ActivityRules onClose={() => ModalInstance?.close()} />,
            getContainer: pageDom.current,
        });
    }
    const onBack = () => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.back();
        } else {
            window.history.go(-1);
        }
    }
    const onBuyClick = () => {
       if(tipsMessage) {
           Toast.show(tipsMessage);
           return;
       }
        const uri = `/ape/saleDetail?collectionId=${Service.collectionId}&code=${Service.activityCode}`
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.verifyRealName("请先实名认证").then(function (res) {
                if (res?.result) {
                    window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
                }
            })

        } else {
            navigate(uri);
        }

    }
    const onCardClick = (item = {}) => {
        console.log(item)
        let uri = ''
        if (item.key == 'land') {

        } else if (item.key == 'coupon') {

        } else if (item.key == 'old_new') {
            const {currentTime, endTime} = dataSource;
            const currentT = new Date(currentTime?.replace(/-/g, "/")).getTime();
            const endT = new Date(endTime?.replace(/-/g, "/")).getTime();
            uri = `/apeWorld/partsBlindbox/up-exchange?end=${currentT > endT}`;
        } else {
            let pageCode = '1693984561368';
            if((process.env.REACT_APP_MALL_LINK + '').includes('test')) {
                pageCode = '1693387609729';
            }else if((process.env.REACT_APP_MALL_LINK + '').includes('uat')) {
                pageCode = '1693552501100';
            }
            
            uri = `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/x-site/page-link/index?pageType=poster&pageCode=${pageCode}`;
        }

        if (window.JsBridge.hasWebViewBridge()) {
            if(uri.includes('/mobile/pages/')) {
                window.JsBridge.push("Web", { uri: uri })
            }else {
                window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}${uri}` })
            }
            
        } else {
            navigate(uri);
        }
    }

    return (
        <div className={Styles.home}>
            <div className={Styles.back} onClick={onBack}></div>
            <div className={Styles.right_btns}>
                <div className={Styles.activity_rules} onClick={onClickRules}>规 则</div>
                {
                    !userInfo?.ID ?
                        <div className={Styles.real_name} onClick={() => window.JsBridge.navigate('Verify')}>实 名</div>
                        : null
                }
            </div>
            
            <div className={Styles.wraper}>
                <div className={Styles.titleImg} />

                <div className={Styles.apeWorld}>
                    <div className={Styles.apeWorldImg}>
                        <img className={Styles.apeimg} src={require('@/assets/img/partsBlindbox/ape_box.png')} />
                        <img className={Styles.apetag} src={require('@/assets/img/partsBlindbox/price_img.png')} />
                    </div>
                    <div className={Styles.buy_btn}>
                        <div onClick={onBuyClick}>立即购买</div>
                    </div>
                </div>
                <div className={Styles.cardObjcs}>
                    {
                        CardObjcs.map((item, k) => {
                            return (
                                <div className={Styles.itemCard} key={k} >
                                    <img src={item.img} />
                                    <div>{item.name}</div>
                                    <span>{item.info}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={Styles.actions}>
                    {
                        ActionEvents.map((item, k) => {
                            return (
                                <div className={Styles.card_h} key={k}>
                                    <div className={Styles.leftBox}>
                                        <span>{item.name}</span>
                                        <div>{item.subTitle}</div>
                                    </div>
                                    <div className={Styles.rightBox}>
                                        {
                                            item.key == 'package' || item.key == 'old_new' ?
                                                <img className={classNames(Styles.enable, Styles[`enable${k}`])}
                                                    src={require('@/assets/img/partsBlindbox/go_btn.png')}
                                                    onClick={() => onCardClick(item)}
                                                    alt=""
                                                />
                                                : <img src={require('@/assets/img/partsBlindbox/go_gayBtn.png')} alt="" />
                                        }

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default Home;