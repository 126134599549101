import React, {useEffect, useState, useRef} from "react"
import {
  getSearchResult
} from "@/api/projectManager_self";
import {Toast, Tabs} from 'antd-mobile';
import ListView from "@/components/AntListView";
import {showLoading, hideLoading} from '@/components/loading';
import NFTSearchHeader from "@/view/myCollectionSearch/component/searchHeader";
import {useNavigate, useLocation} from "react-router-dom";
import {getUrlAllParams, isEmptyString} from "@/utils/publicFun"
import CollectionComp from "../component/collectionComp";
import "./index.scss"
import {mockTab1} from "@/view/myCollectionSearch/mock";

let timeStamp = ""
const MyCollectionSearchResult = () => {

  const {search} = useLocation()
  const {searchText = ''} = getUrlAllParams(search?.slice(1))
  const text = decodeURIComponent(searchText)
  const searchVal = useRef(text)
  const [isAll, setIsAll] = useState(true);
  const changeUI = useRef(true);
  const isLoadingFinished = useRef(false)


  //获取参数定位到指定的tab
  const {code = '', subCode = 0} = getUrlAllParams(search?.slice(1));
  const [list, setList] = useState([]);

  const [hasMore, setHasMore] = useState(false);
  const [maxDay, setMaxDay] = useState(0);
  const [total, setTotal] = useState(0);
  const currentPage = useRef(1);
  const [userInfo, setUserInfo] = useState({});
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    getCollectionList();
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    setUserInfo(userInfo);
  }, []);

  useEffect(() => {
    if (searchVal.current.length > 0) {
      currentPage.current = 1
      getCollectionList()
    }
  }, [isAll])

  const getCollectionList = async () => {
    try {
      showLoading();
      timeStamp = Date.now().toString();
      const res = await getSearchResult({
        current: 1,
        size: 10,
        searchCondition: searchVal.current,
        clientId: isAll ? "" : userInfo.clientId
      });
      // const res = await mockTab1()
      hideLoading();
      isLoadingFinished.current = true

      const {code, msg, data} = res;
      if (code !== '000000') throw msg;
      currentPage.current = 1;
      setList([...data.list]);
      setIsLoad(true);
      setMaxDay(data.maxDay);
      setTotal(data.total);
      setHasMore(data.total > data.list.length);
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }


  // let timeStamp =
  const loadMore = async () => {
    try {
      currentPage.current = currentPage.current + 1;
      const params = {
        current: currentPage.current,
        size: 10,
        searchCondition: searchVal.current,
        clientId: isAll ? "" : userInfo.clientId
      }
      const timeM = Date.now().toString();
      timeStamp = timeM;
      const {code, msg, data} = await getSearchResult(params);
      // const res = await mockTab1()
      if (timeStamp != timeM) {
        return
      }
      if (code !== '000000') throw msg;
      setList([...list, ...data.list]);
      setIsLoad(true);
      setHasMore(data.total > [...list, ...data.list].length);
    } catch (error) {
      window.JsBridge.print(error);
    }
  }

  const renderListItem = (item, index) => {
    return <CollectionComp key={index} isAll={changeUI.current} collection={item} maxDay={maxDay}/>
  }

  const onSwitchOnClick = (isAll) => {
    if (!isEmptyString(searchVal.current)) {
      changeUI.current = isAll;
    }
    setIsAll(isAll)
  }


  const valueChanged = (val) => {
    searchVal.current = val
  }

  return (
    <div className="nft-search-result-page">
      <div className="top">
        <NFTSearchHeader valueChanged={(val) => valueChanged(val)} initText={text}
                         onSwitchOnClick={(isAll) => onSwitchOnClick(isAll)} currentTitle1={2} totalCount={total}
                         onSearchClick={() => {
                           if (!isEmptyString(searchVal.current)) {
                             getCollectionList()
                           }

                         }}/>
      </div>
      {
        list.length > 0 && <div className='list'>
          <ListView
            dataSource={list}
            renderItem={renderListItem}
            hasMore={hasMore && !isEmptyString(searchVal.current)}
            onEndReached={loadMore}
            isLoad={isLoad}
            style={{paddingTop: 30}}
          />
        </div>
      }
        {
           isLoadingFinished.current && list.length==0 && <div className="no-data-container">
                <div className="no-data"></div>
            </div>
        }

    </div>
  )
}

export default MyCollectionSearchResult
