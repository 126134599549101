import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useLocation,} from 'react-router-dom'
import {Swiper} from "antd-mobile";
import "./index.scss"
import clickThrottle from "@/utils/clickThrottle";
import NFTHeader from "@/components/NFTHeader";
import EpicBoringApe from "@/view/guessingActy/getCoupon/component/epicBoringApe";
import {getUrlAllParams, Platform} from "@/utils/publicFun";
import Buy30Stock from "@/view/guessingActy/getCoupon/component/buy30Stock";
import exchangeGuessBondSuccDialog from '../components/dialog/exchangeGuessBondSuccDialog'
import NoticeUtil from "@/utils/NoticeUtil";
import {guess_getBond_show} from "@/constant";
import { queryPayResultCount } from '@/api/projectManager_self';

const kVoteCount = 'kVoteCount';
const GetCoupon = () => { //邀新记录session
    const navigate = useNavigate();

    return (
        <div className={'get_coupon_container'}>
            <NFTHeader midText={'获取入场券'} rightText={'兑换记录'} rightClick={() => navigate('/guessingActy/exchangeRecord')}
                       bgColor={'#fff'}
                       leftClick={() => {
                           if (!clickThrottle(2000)) {
                               return
                           }
                           navigate(-1);
                           let isShow = localStorage.getItem(guess_getBond_show);
                           if (isShow === '1') {
                               setTimeout(() => {
                                   navigate(-1);
                               }, 500);
                           }
                       }}
            />
            <ContentView/>
        </div>
    )
}


const ContentView = (props) => {

    const swiperRef = useRef(null);
    const {search} = useLocation();

    let {currentIndex = 0, session = '', count = 0, outTradeNo } = getUrlAllParams(search.slice(1));//当前下标
    // console.log('currentIndex---', currentIndex)
    // console.log('session---', session)

    if (currentIndex == 1) {
        let timer = setTimeout(() => {
            clearTimeout(timer);
            swiperRef.current && swiperRef.current.swipeTo(parseInt(currentIndex));
            NoticeUtil.emit('currentIndexChange', parseInt(currentIndex))
        }, 100);
    }

    useEffect(() => {
        let isShow = localStorage.getItem(guess_getBond_show);
        // console.log('isShow---',isShow);
        // console.log('count---',count)
        // console.log('outTradeNo---',outTradeNo);
        if (count > 0 && isShow === '0') {
            if (outTradeNo) { // for wx miniProgram pay back
                queryPayResultCount({ outTradeNo }).then(res => {
                    exchangeGuessBondSuccDialog.open(res.data?.count, () => {
                        localStorage.setItem(guess_getBond_show, '1');
                    },'购买');
                })
            } else {
                exchangeGuessBondSuccDialog.open(count, () => {
                    localStorage.setItem(guess_getBond_show, '1');
                },'购买');
            }
        }
    }, [])

    const clickIndex = (index) => {
        swiperRef.current && swiperRef.current.swipeTo(index);
    }

    return (
        <div className={'cartoon_record_com'}>
            <HeaderView clickIndex={clickIndex} currentIndex={currentIndex}/>
            <div style={{
                width: '100%',
                display: 'flex',
                overflow: 'hidden',
                flex: 1,
                paddingBottom: Platform.isApp() ? 50 : 30,
            }}>
                <Swiper
                    style={{flex: 1, display: 'flex'}}
                    direction='horizontal'
                    indicator={() => null}
                    ref={swiperRef}
                    allowTouchMove={false}
                    defaultIndex={0}>
                    <Swiper.Item style={{height: '100%'}}>
                        <EpicBoringApe session={session}/>
                    </Swiper.Item>
                    <Swiper.Item style={{height: '100%'}}>
                        <Buy30Stock session={session}/>
                    </Swiper.Item>
                </Swiper>
            </div>
        </div>
    )
}

const HeaderView = (props) => {
    const [selIndex, setSelIndex] = useState(0);
    const currentIndex = useRef(props.currentIndex || 0);
    const [voteCount, setVoteCount] = useState(0);

    useEffect(() => {
        NoticeUtil.on(kVoteCount, (res) => {
            setVoteCount(res.voteCount);
        })
        NoticeUtil.on('currentIndexChange', (res) => {
            setSelIndex(res);
        })

    }, [])

    const clickIndex = (index) => {
        if (!clickThrottle(1000)) {
            return
        }
        if (currentIndex.current === index) {
            return;
        }
        currentIndex.current = index;
        setSelIndex(index);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            props.clickIndex && props.clickIndex(index)
        }, 100)
    }

    return (
        <div className={'invite_record_header_container'}>
            <span className={'current_juan'}>当前入场券：</span>
            <span className={'current_juan_count'}>{voteCount || 0}</span>
            <div className={'integral_bg'}>积分可抵扣20元!</div>
            <div className={'invite_record_header'}>
                <div className={'heder_title_com'}
                     onClick={() => clickIndex(0)}>
                    <div className={selIndex === 0 ? 'header_title_sel' : 'header_title_dis'}>史诗款无聊猿兑换</div>
                </div>
                <div className={'header_line'}/>
                <div className={'heder_title_com'}
                     onClick={() => clickIndex(1)}>
                    <div className={selIndex === 1 ? 'header_title_sel' : 'header_title_dis'}>30元购买入场券</div>
                </div>
            </div>
        </div>

    )
}

export default GetCoupon;
