import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import qs from "query-string";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { ScoreXBtn, EnergyXBtn } from "../components/button";
import back from "@/assets/img/landfi/back-3.png";
import upImg from "@/assets/img/landfi/up-3.png";
import gem from "@/assets/img/landfi/icon-gem.png";
import blindboxImg from "@/assets/img/landfi/blindbox-3.png";
import lotteryGif from "@/assets/img/landfi/gemstore-lottery.gif";
import WinLotteryModal from "./winLotteryModal";
import RewardOverview from "./rewardOverview";
import RewardRecord from "./rewardRecord";
import MyModal from "./modal";
import { GemTransferView } from "./gemTransferView";
import { ProgressCircle } from "antd-mobile";
import { loadImage } from "@/utils/publicFun";
import { Toast } from "antd-mobile";
import { getGemstoreCount, gemstoreRanking } from "@/api/guland";
import { useExchangeRecords } from "./gemStoreBox/exchangeRecords";
import appService from "@/utils/appService";
import firstCrown from "@/assets/img/landfi/first-crown.png";
import secondCrown from "@/assets/img/landfi/second-crown.png";
import thirdCrown from "@/assets/img/landfi/third-crown.png";
import { hideLoading, showLoading } from "@/components/loading";
import GemDetail from "../leaseCenter/rentOut/gemDetail";
import { activityInit } from "@/api/activity";
import btn from "@/assets/img/landfi/btc-2.png";
import Rules from "./bitcoin/rules";
import Record from "./bitcoin/record";
import Guess from "./bitcoin/guess";
import Exchange from "./gemStoreBox/exchange";
import GemStoreList from "./gemStoreBox/gemStoreList";
import moment from "moment";
import { keyBy } from "lodash";

// 默认上个界面横屏
let isLandscape = true;
const tabs = [
  { text: "宝石商店" },
  { text: "盲盒" },
  { text: "福布猿排行榜" },
  { text: "猜涨跌" }
];

const RankingMap = {
  1: firstCrown,
  2: thirdCrown,
  3: secondCrown,
};

const JewelLand = () => {
  const navigate = useNavigate();
  // 跳转过来时是否竖屏
  const { isPortrait, mode = 1 } = qs.parse(window.location.search);
  const [isMutiple, setIsMutiple] = useState(false);
  const [lotteryInfo, setLotteryInfo] = useState({});
  const [lotteryConfig, setLotteryConfig] = useState({});
  const [gemstore, setGemstore] = useState(0);
  const [energyCount, setEnergyCount] = useState(0);

  const [selectData, setSelectData] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const blindboxRef = useRef(null);
  const pageRef = useRef(null);
  const loadingRef = useRef(null);
  const [gemDetailVisible, setGemDetailVisible] = useState(false);
  const bitcoinboxRef = useRef(null);
  const gemStoreBoxRef = useRef(null);
  const [onRecordsOpen, ExchangeRecords] = useExchangeRecords({
    container: pageRef.current,
  });

  useEffect(() => {
    if (isPortrait) {
      // 通过参数判断上个界面是否竖屏
      window.JsBridgeNew.hiddenStatusBar(true);
      window.JsBridgeNew.screenRotate(false);
    } else {
      // 自动判断初始化界面是否横屏
      window.JsBridgeNew.isLandscape().then((res) => {
        isLandscape = res.isLandscape;
        if (!isLandscape) {
          window.JsBridgeNew.hiddenStatusBar(true);
          window.JsBridgeNew.screenRotate(false);
        }
        console.log("res=====>", res);
      });
    }
    onDidFocus();
    getLotteryConfig();
    getLotteryInfo();
    queryGemstoreCounts();
  }, []);

  useEffect(() => {
    if (currentTab === 0) {
      getLotteryConfig();
      getLotteryInfo();
      queryGemstoreCounts();
    }
  }, [currentTab]);

  const queryGemstoreCounts = async () => {
    try {
      const res = await getGemstoreCount();
      if (res?.code !== "000000") throw res?.msg;
      setGemstore(res?.data?.gemstonesCount || 0);
      setEnergyCount(res?.data?.energyCount || 0);
    } catch (error) {
      console.log(error);
    }
  };

  const getLotteryConfig = async () => {
    const result = await appService("600902", {}, true);
    console.log("result=====>", result);
    if (result?.status > 0) {
      setLotteryConfig(result?.data || []);
    }
  };

  const getLotteryInfo = async () => {
    const result = await appService("600905", {}, false);
    console.log("result=====>", result);
    if (result?.status > 0) {
      const progress =
        Number(
          (
            Number(result?.data?.progress) /
            Number(result?.data?.targetProgress)
          ).toFixed(2)
        ) * 100 || 0;
      setLotteryInfo({
        ...result.data,
        percent: progress > 100 ? 100 : progress,
      });
    }
  };

  const onLottery = async (type) => {
    if (type === "single") {
      if (loadingRef.current) return;
      if (isMutiple) return;
      if (gemstore <= 0) {
        Toast.show("宝石数量不足");
        return;
      }
      blindboxRef.current?.startLight();
      loadingRef.current = true;
      const result = await appService("600906", { reduceTimes: 1 });
      console.log("result=====>", result);
      if (result?.status > 0) {
        blindboxRef.current?.changeSingle(true);
        setSelectData(result?.data || {});
        loadingRef.current = false;
      } else {
        loadingRef.current = false;
        blindboxRef.current?.closeLight();
      }
    } else if (type === "mutiple") {
      if (loadingRef.current) return;
      if (blindboxRef.current?.isSingle) return;
      if (gemstore < 10) {
        Toast.show("宝石数量不足");
        return;
      }
      blindboxRef.current?.startLight();
      loadingRef.current = true;
      const result = await appService("600906", { reduceTimes: 10 });
      console.log("🚀 ~ onLottery ~ result:", result);
      if (result?.status > 0) {
        setIsMutiple(true);
        setTimeout(() => {
          setIsMutiple(false);
          blindboxRef.current?.closeLight();
          loadingRef.current = false;
          getLotteryInfo();
          queryGemstoreCounts();
          selectResult(result?.data?.list || []);
        }, 1500);
      } else {
        blindboxRef.current?.closeLight();
        loadingRef.current = false;
      }
    }
  };

  const selectResult = (result) => {
    const ModalInstance = MyModal.show({
      content: (
        <WinLotteryModal
          onClose={() => ModalInstance?.close()}
          seePrize={seePrize}
          onLottery={onLottery}
          prizes={result}
        />
      ),
      getContainer: pageRef.current,
    });
  };

  const onGemstoreTransfer = () => {
    const ModalInstance = MyModal.show({
      content: (
        <GemTransferView
          onClose={() => ModalInstance?.close()}
          container={pageRef.current}
          refreshData={() => queryGemstoreCounts()}
        />
      ),
      getContainer: pageRef.current,
    });
  };

  const overviewPrize = () => {
    const ModalInstance = MyModal.show({
      content: <RewardOverview onClose={() => ModalInstance?.close()} />,
      getContainer: pageRef.current,
    });
  };

  const seePrize = () => {
    const ModalInstance = MyModal.show({
      content: (
        <RewardRecord
          onClose={() => ModalInstance?.close()}
          container={pageRef.current}
        />
      ),
      getContainer: pageRef.current,
    });
  };

  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      onDidFocus();
      window.JsBridgeNew.hiddenStatusBar(true);
      window.JsBridgeNew.screenRotate(false);
    });
  };

  return (
    <div
      className={`${styles.jewel_land} ${
        currentTab === 2 ? styles.jwel_land1 : ""
      }`}
      ref={pageRef}
    >
      <div className={styles.jeweland_left}>
        <div className={styles.back_title}>
          <div
            className={styles.back}
            onClick={() => {
              if (window.JsBridge.hasWebViewBridge()) {
                if (!isLandscape || isPortrait) {
                  window.JsBridgeNew.hiddenStatusBar(false);
                  window.JsBridgeNew.screenRotate(true);
                }
                window.JsBridge.back();
              } else {
                navigate(-1);
              }
            }}
          >
            <div className={styles.back_img_box}>
              <img src={back} alt="" />
            </div>
          </div>
          <div className={styles.title}>宝石乐园</div>
        </div>
        <div className={styles.blindbox_title}>
          {tabs.map((item, index) => {
            return (
              <div
                key={index}
                className={`${styles.title_bg} ${
                  currentTab === index ? "" : styles.title_bg_disabled
                }`}
                onClick={() => setCurrentTab(index)}
              >
                {item.text}
              </div>
            );
          })}
          <div
            className={styles.gemstore_transfer}
            onClick={onGemstoreTransfer}
          >
            宝石转赠&gt;
          </div>
        </div>
      </div>
      {currentTab === 0 ? (
        <div className={styles.gem_store_out}>
          <div className={styles.gem_store_top}>
            <div className={styles.top_title}>
              <div className={styles.title}>宝石商店</div>
              <div
                className={styles.sub_title}
                onClick={() => {
                  onRecordsOpen();
                }}
              >
                兑换记录
              </div>
            </div>
            <div className={styles.top_btns}>
              <div>
                <ScoreXBtn
                  style={{ position: "static" }}
                  gemCount={gemstore}
                  onClick={() => setGemDetailVisible(true)}
                />
              </div>
              <div>
                <EnergyXBtn
                  style={{ position: "static" }}
                  gemCount={energyCount}
                  // onClick={() => setGemDetailVisible(true)}
                />
              </div>
            </div>
          </div>
          <div className={styles.gem_store}>
            <div className={styles.gem_store_list}>
              <GemStoreBox
                mode={mode}
                pageRef={pageRef}
                ref={gemStoreBoxRef}
                gemstore={gemstore}
                energyStore={energyCount}
                refreshGem={() => {
                  queryGemstoreCounts();
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {currentTab === 1 ? (
        <div className={styles.jeweland_right}>
          <div className={styles.blindbox_area}>
            <div className={styles.prize_banner} onClick={overviewPrize}></div>
            <div className={styles.blindbox_list_container}>
              <Blindbox
                ref={blindboxRef}
                selectResult={selectResult}
                isMutiple={isMutiple}
                selectData={selectData}
                refreshGem={() => {
                  getLotteryInfo();
                  queryGemstoreCounts();
                }}
              />
            </div>
          </div>
          <div className={styles.lottery_area}>
            <ScoreXBtn
              style={{ position: "static" }}
              gemCount={gemstore}
              onClick={() => setGemDetailVisible(true)}
            />
            <div className={styles.up_jackpot_out}>
              <ProgressCircle
                percent={lotteryInfo.percent}
                style={{
                  "--track-width": "12px",
                  "--size": "90px",
                  "--track-color": "#D8D8D8",
                }}
              >
                <img className={styles.jackpot_img} src={upImg} alt="" />
                <p className={styles.jackpot_num}>
                  {lotteryInfo.progress}/{lotteryInfo.targetProgress}
                </p>
              </ProgressCircle>
              <div style={{ width: 0, height: 0 }}>
                <svg width="100%" height="100%">
                  <defs>
                    <linearGradient
                      id="write"
                      x1="0%"
                      y1="100%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" style={{ stopColor: "#ABDE63" }}></stop>
                      <stop
                        offset="100%"
                        style={{ stopColor: "#6AB8F2" }}
                      ></stop>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div
              className={styles.single_lottery_btn}
              onClick={() => onLottery("single")}
            >
              <img className={styles.gem_img} src={gem} alt="" />
              {lotteryConfig?.[0]?.discount && (
                <div className={styles.lottery_discount}>
                  <p>{lotteryConfig?.[0]?.discount}折</p>
                </div>
              )}
              <div className={styles.single_consume_gem}>
                x
                {lotteryConfig?.[0]?.discount
                  ? Math.ceil(
                      (lotteryConfig?.[0]?.gemstone *
                        lotteryConfig?.[0]?.discount) /
                        10
                    )
                  : lotteryConfig?.[0]?.gemstone}
              </div>
              <div className={styles.single_text_tip}>
                抽取{lotteryConfig?.[0]?.num || 1}次
              </div>
            </div>
            <div
              className={styles.mutiple_lottery_btn}
              onClick={() => onLottery("mutiple")}
            >
              <img className={styles.gem_img} src={gem} alt="" />
              {lotteryConfig?.[1]?.discount && (
                <div className={styles.lottery_discount}>
                  <p>{lotteryConfig?.[1]?.discount}折</p>
                </div>
              )}
              <div className={styles.mutiple_consume_gem}>
                x
                {lotteryConfig?.[1]?.discount
                  ? Math.ceil(
                      (lotteryConfig?.[1]?.gemstone *
                        lotteryConfig?.[1]?.discount) /
                        10
                    )
                  : lotteryConfig?.[1]?.gemstone}
              </div>
              <div className={styles.mutiple_text_tip}>
                <span>抽</span>
                <span>取</span>
                <span>{lotteryConfig?.[1]?.num || 10}</span>
                <span>次</span>
              </div>
            </div>
            <div className={styles.look_prizes_btn} onClick={seePrize}>
              奖品记录&gt;
            </div>
          </div>
        </div>
      ) : null}
      {currentTab === 2 ? <GemstoreRanking /> : null}
      {currentTab === 3 ? (
        <div className={styles.jeweland_btc}>
          <div className={styles.btc_area_gem}>
            <div className={styles.btc_area_gem_left}></div>
            <ScoreXBtn
              style={{ position: "static" }}
              gemCount={gemstore}
              onClick={() => setGemDetailVisible(true)}
            />
          </div>
          <div className={styles.btc_area_out}>
            <Bitcoinbox
              pageRef={pageRef}
              ref={bitcoinboxRef}
              gemstore={gemstore}
              refreshGem={() => {
                queryGemstoreCounts();
              }}
            />
          </div>
        </div>
      ) : null}

      {gemDetailVisible && (
        <GemDetail
          visible={gemDetailVisible}
          onclose={(refresh) => {
            setGemDetailVisible(false);
          }}
          container={pageRef.current}
        />
      )}
      {ExchangeRecords}
    </div>
  );
};

const Blindbox = forwardRef(
  ({ selectResult, isMutiple, refreshGem, selectData }, ref) => {
    const [showLight, setShowLight] = useState(false);
    const [selectBlindbox, setSelectBlindbox] = useState(null);
    const [isSingle, setIsSingle] = useState(false);
    const [gif, setGif] = useState("");
    const timeId = useRef(null);
    const loadingRef = useRef(null);

    useImperativeHandle(ref, () => {
      return {
        startLight,
        closeLight: () => clearInterval(timeId.current),
        isSingle,
        changeSingle: setIsSingle,
      };
    });

    useEffect(() => {
      return () => {
        clearInterval(timeId.current);
      };
    }, []);

    useEffect(() => {
      getGif();
    }, []);

    const getGif = async () => {
      const mainBgImage = new Image();
      mainBgImage.src = lotteryGif;
      await loadImage(mainBgImage);
      setGif(lotteryGif);
    };

    const startLight = async () => {
      await getGif();
      if (timeId.current) {
        clearInterval(timeId.current);
      }
      timeId.current = setInterval(() => {
        setShowLight((pre) => !pre);
      }, 500);
    };

    const onSelectBlindbox = async (index) => {
      if (loadingRef.current) return;
      if (!isSingle) return;
      loadingRef.current = true;
      setSelectBlindbox(index);
      setTimeout(() => {
        setIsSingle(false);
        setSelectBlindbox(null);
        clearInterval(timeId.current);
        loadingRef.current = false;
        refreshGem();
        selectResult(selectData);
      }, 1500);
    };

    return (
      <div
        className={`${styles.light_list_bg} ${
          showLight ? styles.light_animate : ""
        }`}
      >
        {Array(35)
          .fill("")
          .map((item, index) => {
            return (
              <div
                className={`${styles.light} ${styles[`_${index + 1}`]}`}
                key={index}
              ></div>
            );
          })}
        <div className={styles.blindbox_list_bg}>
          {Array(10)
            .fill("")
            .map((item, index) => {
              return (
                <div
                  className={styles.blindbox_list_item}
                  key={index}
                  onClick={() => onSelectBlindbox(index)}
                >
                  {selectBlindbox === index || isMutiple ? (
                    <img className={styles.lottery_gif} src={gif} alt="" />
                  ) : (
                    <img
                      className={styles.lottery_img}
                      src={blindboxImg}
                      alt=""
                    />
                  )}
                  <div
                    className={`${styles.select_blindbox_btn} ${
                      isSingle && selectBlindbox !== index
                        ? styles.show_btn
                        : styles.hidden_btn
                    }`}
                  >
                    选这盒
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
);

const GemstoreRanking = () => {
  const [rankingInfo, setRankingInfo] = useState({});

  useEffect(() => {
    getRankingInfo();
  }, []);

  const getRankingInfo = async () => {
    try {
      showLoading();
      const res = await gemstoreRanking();
      hideLoading();
      if (res?.code !== "000000") throw res?.msg;
      setRankingInfo(res?.data || {});
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  };

  const rankingText = (rank) => {
    let rankText = "";
    if (rank === -2) {
      rankText = "99+";
    } else if (rank === -1) {
      rankText = "-";
    } else {
      rankText = rank;
    }
    return rankText;
  };

  return (
    <div className={styles.gemstore_ranking}>
      <div className={styles.gemstore_ranking_inner}>
        <div className={styles.ranking_title}>宝石排行榜</div>
        <div className={styles.ranking_content}>
          {rankingInfo?.list?.length > 0 &&
            rankingInfo?.list.map((item, index) => {
              return (
                <div className={styles.ranking_item} key={index}>
                  <div className={styles.item_left}>
                    <div className={styles.ranking_num}>
                      {item.rank < 4 ? (
                        <img src={RankingMap[item.rank]} alt="" />
                      ) : (
                        <span>{item.rank}</span>
                      )}
                    </div>
                    <span>{item.mobile}</span>
                  </div>
                  <div className={styles.item_right}>
                    <img src={gem} alt="" />
                    <span>{item.count}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <div className={styles.my_ranking}>
          <div className={styles.my_ranking_left}>
            <span>我的排行</span>
            <span> {rankingText(rankingInfo?.gemstonesRankVO?.rank)}</span>
          </div>
          <div className={styles.my_ranking_right}>
            <img src={gem} alt="" />
            <span>{rankingInfo?.gemstonesRankVO?.count || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
const Bitcoinbox = forwardRef(({ refreshGem, pageRef, gemstore }, ref) => {
  const [price, setPrice] = useState("--");
  const [eachAdd, setEachAdd] = useState(10);
  const [minGems, setMinGems] = useState(10);
  const [investList, setInvestList] = useState([]);
  const [countList, setCountList] = useState([]);

  const [time, setTime] = useState(null);
  const [nextTime, setNextTime] = useState(null);
  const [current, setCurrent] = useState(null);
  const timeOut = useRef(null);
  const timeInterval = useRef(null);
  let pricesWs = useRef(null);
  const connectSocket = () => {
    pricesWs.current = new WebSocket(
      "wss://ws.coincap.io/prices?assets=bitcoin"
    );
    pricesWs.current.onmessage = (msg) => {
      if (JSON.parse(msg.data)?.bitcoin) {
        setPrice(JSON.parse(msg.data)?.bitcoin);
      }
    };
    pricesWs.current.onerror = (msg) => {
      console.log("error");
      setPrice("--");
      connectSocket();
    };
    pricesWs.current.onclose = (msg) => {
      console.log("close");
      setPrice("--");
      connectSocket();
    };
  };

  const getCurrent = (timestamp) => {
    const now = new Date(timestamp);
    // const m = now.getMinutes();
    const h = now.getHours();

    // let startHour = m >= 55 ? h : h - 1;
    let startString = moment(timestamp).format("YYYY-MM-DD ") + h + ":00:00";
    let startTimeStamp = moment(startString, "YYYY-MM-DD HH:mm:ss");
    let endTimeStamp = startTimeStamp + 1000 * 60 * 60;
    let endString = moment(endTimeStamp).format("YYYY-MM-DD HH:mm:ss");

    let nextTimeStamp = startTimeStamp + 1000 * 60 * 60 * 2;
    let nextString = moment(nextTimeStamp).format("YYYY-MM-DD HH:mm:ss");
    let nextSession = moment(nextTimeStamp).format("HH:mm");
    return {
      startTimeStamp,
      startString,
      endTimeStamp,
      endString,
      nextTimeStamp,
      nextString,
      nextSession,
    };
  };
  const getLastSession = () => {
    const timestamp = Date.now();
    const timeObj = getCurrent(timestamp);
    setTime(timestamp);
    setNextTime(timeObj?.nextTimeStamp);
    setCurrent(timeObj?.nextSession);
    console.log("切换时间啦", timeObj?.nextSession);
    console.log("切换开始时间", timeObj?.startString);
    console.log("切换参加场次", timeObj?.nextSession);
    queryActivity();
  };
  const initSession = () => {
    clearTimeout(timeOut.current);
    clearInterval(timeInterval.current);
    const timestamp = Date.now();
    const timeObj = getCurrent(timestamp);
    setTime(timestamp);
    setNextTime(timeObj?.nextTimeStamp);
    setCurrent(timeObj?.nextSession);
    console.log("开始时间", timeObj?.startString);
    console.log("参加场次", timeObj?.nextSession);
    console.log("倒计时时间", timeObj?.endTimeStamp - timestamp);
    timeOut.current = setTimeout(() => {
      getLastSession();
      timeInterval.current = setInterval(getLastSession, 1000 * 60 * 60);
    }, timeObj.endTimeStamp - timestamp);
  };
  const isToday = (timestamp) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return (
      timestamp >= today.getTime() && timestamp < today.getTime() + 86400000
    );
  };

  const queryActivity = async () => {
    try {
      showLoading();
      const params = {
        activityCode: "btcGuess",
      };
      const res = await activityInit(params);
      hideLoading();
      if (res?.code !== "000000") throw res?.msg;
      setEachAdd(res?.data?.eachAdd);
      setMinGems(res?.data?.minGems);
      setInvestList(res?.data?.investList);
      setCountList(res?.data?.countList);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    connectSocket();
    initSession();
    queryActivity();

    return () => {
      clearTimeout(timeOut.current);
      clearInterval(timeInterval.current);
      pricesWs.current?.close();
    };
  }, []);
  useImperativeHandle(ref, () => {
    return {};
  });
  const onGetRule = () => {
    const ModalInstance = MyModal.show({
      content: <Rules onClose={() => ModalInstance?.close()} />,
      getContainer: pageRef.current,
    });
  };
  const onGetRecords = () => {
    const ModalInstance = MyModal.show({
      content: <Record onClose={() => ModalInstance?.close()} />,
      getContainer: pageRef.current,
    });
  };
  const onGuess = (type) => {
    const ModalInstance = MyModal.show({
      content: (
        <Guess
          type={type}
          gemstore={gemstore}
          nextTime={nextTime}
          eachAdd={eachAdd}
          minGems={minGems}
          onClose={(flag) => {
            ModalInstance?.close();
            if (flag) {
              refreshGem();
              initSession();
              queryActivity();
            }
          }}
        />
      ),
      getContainer: pageRef.current,
    });
  };
  return (
    <div className={styles.btc_inner}>
      <div className={styles.btc_inner_left}>
        <div className={styles.btc_inner_info}></div>
      </div>
      <div className={styles.btc_inner_right}>
        <div className={styles.top}>
          <div className={styles.top_left}>
            <span>USD</span>
            <span>{price}</span>
          </div>
          <div
            className={styles.top_right}
            onClick={() => {
              onGetRule();
            }}
          >
            活动规则&gt;&gt;
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.title}>
            <div className={styles.title_left}>{`本场竞猜：${
              isToday(nextTime) ? "今天" : "明天"
            }${current}`}</div>
            <div
              className={styles.title_right}
              onClick={() => {
                onGetRecords();
              }}
            >
              竞猜记录&gt;
            </div>
          </div>
          <div className={styles.info}>
            {countList?.length !== 0 && (
              <div className={styles.gemCount}>
                {keyBy(countList, "guessType")["0"]?.investCount && (
                  <div className={styles.record}>
                    <span>共投</span>
                    <span>
                      {keyBy(countList, "guessType")["0"]?.investCount}
                    </span>
                  </div>
                )}

                <div
                  className={styles.record}
                  style={
                    !keyBy(countList, "guessType")["1"]?.investCount
                      ? { display: "none" }
                      : {}
                  }
                >
                  <span>共投</span>
                  <span>{keyBy(countList, "guessType")["1"]?.investCount}</span>
                </div>

                {keyBy(countList, "guessType")["2"]?.investCount && (
                  <div className={styles.record}>
                    <span>共投</span>
                    <span>
                      {keyBy(countList, "guessType")["2"]?.investCount}
                    </span>
                  </div>
                )}
              </div>
            )}
            <div className={styles.way_bg}>
              <img src={btn} />

              <div className={styles.way_hotArea}>
                <div
                  onClick={() => {
                    onGuess("0");
                  }}
                ></div>
                <div
                  onClick={() => {
                    onGuess("1");
                  }}
                ></div>
                <div
                  onClick={() => {
                    onGuess("2");
                  }}
                ></div>
              </div>
            </div>

            {investList?.length !== 0 && (
              <div className={styles.way}>
                <div className={styles.record}>
                  {keyBy(investList, "guessType")["0"]?.investCount && (
                    <span>已投</span>
                  )}
                  <span>
                    {keyBy(investList, "guessType")["0"]?.investCount}
                  </span>
                </div>
                <div className={styles.record}>
                  {keyBy(investList, "guessType")["1"]?.investCount && (
                    <span>已投</span>
                  )}
                  <span>
                    {keyBy(investList, "guessType")["1"]?.investCount}
                  </span>
                </div>
                <div className={styles.record}>
                  {keyBy(investList, "guessType")["2"]?.investCount && (
                    <span>已投</span>
                  )}
                  <span>
                    {keyBy(investList, "guessType")["2"]?.investCount}
                  </span>
                </div>
              </div>
            )}
            {investList?.length === 0 && (
              <div className={styles.tips}>{minGems}宝石起投，请选择</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

const GemStoreBox = forwardRef(
  ({ refreshGem, pageRef, mode = 1, gemstore, energyStore }, ref) => {
    const listRef = useRef(null);
    const [selectData, setSelectData] = useState([]);
    const selectChange = (data) => {
      setSelectData(data);
      data?.length>0 && onExchange(data);
    };

    useImperativeHandle(ref, () => {
      return {};
    });
    const onExchange = (data) => {
      const ModalInstance = MyModal.show({
        content: (
          <Exchange
            mode={2}
            recordInfo={data[0]}
            gemstore={gemstore}
            energyStore={energyStore}
            container={pageRef.current}
            onClose={(flag) => {
              ModalInstance?.close();
              if (flag) {
                refreshGem();
                selectChange([])
                listRef.current?.refresh()
              }
            }}
          />
        ),
        getContainer: pageRef.current,
      });
    };
    return (
      <div className={styles.gem_store_inner}>
        <GemStoreList
          ref={listRef}
          container={pageRef.current}
          onChange={selectChange}
        />
      </div>
    );
  }
);

export default JewelLand;
