import React from 'react'
import { useNavigate } from "react-router-dom"
import { round } from "@/utils/publicFun"
import './OrderItem.scss'

const OrderItem = (props) => {
  const navigate = useNavigate()

  const { collectionName, outTradeNo, image, num, orderStatus, totalPrice } = props.collection

  const goOrderDetail = (outTradeNo) => {
    navigate(`/myOrderDetail?out_trade_no=${outTradeNo}`)
  }

  return (
    <div className='order_content' onClick={() => { goOrderDetail(outTradeNo) }}>
      <div className="order-img">
        <img src={image} alt="" />
      </div>
      <div className="order-message">
        <div className="title">{collectionName}</div>
        <div className="price">¥{round(totalPrice, 2)}</div>
        <div className="pay-detail">
          <span className="num">x{num}</span>
          <span className="status">{orderStatus === "SUCCESS" ? "已付款" : (orderStatus === 'NOTPAY' ? "待支付" : "已取消")}</span>
        </div>
      </div>
    </div>
  )
}

export default OrderItem