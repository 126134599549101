/**
 * @description 王小慧盲盒
*/

import Home from './home';
import Prize from './prize';

export default {
  Home,
  Prize
}