import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DonationModal from "@/components/donationModal";
import { Toast } from 'antd-mobile';
import { receiveDonation, refuseDonation, receivePageInit } from "@/api/projectManager_self";
import qs from 'query-string';
import './style.scss';
import { showLoading, hideLoading } from '@/components/loading';
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectCards } from "swiper"
import "swiper/css/bundle"
import "swiper/css/effect-cards"
import { isArray } from '@/utils/publicFun';

const ReceiveDonation = () => {
  const navigate = useNavigate();
  const { transferId = '' } = qs.parse(window.location.search);
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00'
  });
  const [receiveData, setReceiveData] = useState({});
  let currentTimeStamp = useRef(null).current;
  const timeId = useRef();

  useEffect(() => {
    (async () => {
      window.JsBridge.setTitle('接收转赠');
      await pageInit();
    })();
  }, []);

  useEffect(() => {
    timeId.current = setInterval(() => {
      if (currentTimeStamp) {
          currentTimeStamp = currentTimeStamp + 1000;
      }
      let obj = countDown(receiveData.currentTime, receiveData.giftTime, () => {
        timeId.current && clearInterval(timeId.current);
      });
      setCount({
          hours: obj.hour,
          minutes: obj.minute,
          seconds: obj.second,
      });
    }, 1000)
    return () => {
        timeId.current && clearInterval(timeId.current);
    }
}, [receiveData])

  const pageInit = async () => {
    try {
      showLoading();
      const res = await receivePageInit({transferId});
      const { code, msg, data } = res;
      console.log(res);
      hideLoading();
      if(code !== '000000') throw msg;
      if(data.state === 'REJECT') {
        const params = {
          state: 'refuse', 
          number: data.nftNo, 
          name: data.giver, 
          nftImg: data.nftImage,
          collectionName: data.collectionName
        };
        myNavigate('receiveResult', params);
        return;
      } else if(data.state === 'COMPLETE') {
        const params = {
          state: 'success', 
          number: data.nftNo, 
          name: data.giver, 
          nftImg: data.nftImage, 
          nftHash: data.nftHash,
          collectionName: data.collectionName
        };
        myNavigate('receiveResult', params);
        return;
      } else if(data.state === 'TIME_OUT') {
        const params = {
          state: 'overtime', 
          number: data.nftNo, 
          name: data.giver, 
          nftImg: data.nftImage,
          collectionName: data.collectionName
        };
        myNavigate('receiveResult', params);
        return;
      } else {
        setReceiveData(data);
      }
      
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const myNavigate = (url, {state, number, name, nftImg, nftHash, collectionName}) => {
    const params = {state, number, name, nftImg, nftHash, collectionName};
    navigate(`/${url}?${qs.stringify(params)}`, {replace: true})
  }

  // 拒绝接收
  const confirm = async () => {
    try {
      showLoading();
      const res = await refuseDonation({transferId});
      hideLoading();
      if(res.code === '000000') {
        const params = {
          state: 'refuse', 
          number: receiveData.nftNo, 
          name: receiveData.giver, 
          nftImg: receiveData.nftImage,
          collectionName: receiveData.collectionName
        };
        myNavigate('receiveResult', params);
      } else if(res.code === '500008') {
        const params = {
          state: 'overtime', 
          number: receiveData.nftNo, 
          name: receiveData.giver, 
          nftImg: receiveData.nftImage,
          collectionName: receiveData.collectionName
        };
        myNavigate('receiveResult', params);
      } else {
        throw res.msg;
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }
  
  // 确认接收
  const confirmReceive = async () => {
    try {
      showLoading();
      const res = await receiveDonation({transferId});
      hideLoading();
      if(res.code === '000000') {
        const params = {
          state: 'success', 
          number: receiveData.nftNo, 
          name: receiveData.giver, 
          nftImg: receiveData.nftImage,
          nftHash: res.data.nftHash,
          collectionName: receiveData.collectionName
        };
        myNavigate('receiveResult', params);
      } else if(res.code === '500008') {
        const params = {
          state: 'overtime', 
          number: receiveData.nftNo, 
          name: receiveData.giver, 
          nftImg: receiveData.nftImage,
          collectionName: receiveData.collectionName
        };
        myNavigate('receiveResult', params);
      } else {
        throw res.msg;
      }
    } catch (error) {
      hideLoading();
      Toast.show(error);
    }
  }

  const countDown = (currentTime, startTime, callBack) => {
    if (!currentTime || !startTime) {
      callBack && callBack()
      return {
        hour: '00',
        minute: '00',
        second: '00'
      }
    };
    if (currentTimeStamp == null) {
        const current = Date.parse(currentTime.replace(/-/g, '/'))
        currentTimeStamp = current
    }
    const endTime = Date.parse(startTime.replace(/-/g, '/')) + 24 * 60 * 60 * 1000
    const distance = endTime - currentTimeStamp;
    if(distance <= 0) {
      callBack && callBack()
      return {
        hour: '00',
        minute: '00',
        second: '00'
      }
    }
    const hour = Math.floor((distance / 1000 / 60 / 60) % 24)
    const minute = Math.floor((distance / 1000 / 60) % 60)
    const second = Math.floor((distance / 1000) % 60)
    return {
      hour: hour < 10 ? `0${hour}`: hour,
      minute: minute < 10 ? `0${minute}`: minute,
      second: second < 10 ? `0${second}`: second
    }
  }

  return <>
    <div className='receive-donation'>
      <div className="count-down">
        <span className='count'>{count.hours}</span>
        <span>:</span>
        <span className='count'>{count.minutes}</span>
        <span>:</span>
        <span className='count'>{count.seconds}</span>
      </div>
      {!!receiveData?.nftImageList && isArray(receiveData?.nftImageList) && !!receiveData?.nftImageList?.length &&
        <div className="img">
          <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]} className="mySwiper">
            {receiveData?.nftImageList?.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      }
      <div className="title">{receiveData?.collectionName}</div>
      <div className="number">藏品编号：#{receiveData?.nftNo}</div>
      <div className="transferor">{receiveData?.giver}{`向你赠送了该数字藏品${receiveData?.parent == 2 ? '及关联的共创形象藏品' : ''}`}</div>
      <div className='time'>24小时内未完成领取，该藏品将退回</div>
      <div className='time'>该藏品不限转赠次数</div>
      <div className="btn-box">
        <span className='cancel-btn btn-common' onClick={() => setVisible(true)}>拒绝接收</span>
        <span className='confirem-btn btn-common' onClick={confirmReceive}>确认接收</span>
      </div>
    </div>
    <DonationModal 
      visible={visible}
      title="拒绝接收"
      content="确定要拒绝接收吗？"
      leftBtnText="取消"
      rightBtnText="确认"
      rightBtnCB={confirm}
      onClose={() => setVisible(false)}
    />
  </>
}

export default ReceiveDonation;